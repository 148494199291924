import { IStore } from 'store/configureStore';

export const selectAssignmentsToSubmit = (state: IStore) => state.components.addEntry.assignmentIds.map(
    id => state.configuration.assignmentsById[id],
);

export const selectTasksToSubmit = (state: IStore) => state.components.addEntry.taskIds.map(
    id => state.configuration.tasksById[id],
);

export const selectActivitiesByTaskIdByAssignment = (state: IStore) => state.components.addEntry.activitiesByTaskId;

import { combineReducers } from 'redux';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';

import {
    defaultInfinityScrollState,
    IInfinityScrollState,
    isLoadingReducer,
    itemsByIds,
    singleValueReducer,
    withMessageReducer,
} from 'store/reducerUtils';
import { ActionsReturnTypes } from 'store/utils';

import { offerCreate } from 'modules/offerLetter/components/CreateOffer/store/reducers';
import { offerEdit } from 'modules/offerLetter/components/EditOffer/store/reducers';
import { employeeOfferDetail } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/reducers';
import { offerRescind } from 'modules/offerLetter/components/OfferRescission/store/reducers';
import { rejectOfferLetter } from 'modules/offerLetter/components/RejectOfferLetter/store/reducers';
import { lookup } from 'modules/offerLetter/components/lookup/data/reducers';
import {
    checkEmployeeOnboardingStatus,
    checkOfferLetterPdf,
    createOffer,
    editOffer,
    getEmployeeProfiles,
    getMoreOfferLetterAction,
    getMyEmployeeProfile,
    getMyOfferLetters,
    getOfferLetter,
    getOfferLetters,
    initialLoadOfferLetters,
    OfferLetterActions,
    rescindOffer,
    setOfferLetterFilters,
    updateEmployeeProfile,
    updateOfferLetter,
    updateOfferLetterStatus,
} from 'modules/offerLetter/store/actions';
import { IOfferLetter, IOfferLetterFilters, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';

import { ItemsById } from 'shared/models/ItemsById';

import { offerLetterTemplate } from './templates/reducer';

const initialState = {
    offerLettersById: {},
    employeeProfilesByClientId: {},
    employeeProfileStatusCheckingByClientId: {},
    startDates: {},
    offerLetterFilters: {
        positionId: '',
        locationId: '',
        startDate: '',
        search: '',
        statusSlug: OfferLetterStatusSlug.Outstanding,
    },
    pdfCheck: {},
};

const getOfferLetterLoading = isLoadingReducer(getOfferLetter);
const myOfferLettersLoading = isLoadingReducer(getMyOfferLetters);

const offerLetterFilters = singleValueReducer<IOfferLetterFilters>(
    setOfferLetterFilters.action,
    initialState.offerLetterFilters,
);

export function offerLetterById(
    state: ItemsById<IOfferLetter> = initialState.offerLettersById, action: OfferLetterActions,
): ItemsById<IOfferLetter> {
    switch (action.type) {
        case getMyOfferLetters.successType:
        case getOfferLetters.successType:
            return {
                ...state,
                ...itemsByIds(action.payload),
            };
        case updateOfferLetterStatus.successType:
        case updateOfferLetter.successType:
        case getOfferLetter.successType:
        case createOffer.successType:
        case editOffer.successType:
        case rescindOffer.successType:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        default:
            return state;
    }
}

export function offerLettersTable(
    state: IInfinityScrollState<string>,
    action: OfferLetterActions,
): IInfinityScrollState<string> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setOfferLetterFilters.action:
        case initialLoadOfferLetters.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreOfferLetterAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreOfferLetterAction.successType:
            return {
                total: action.payload.total,
                items: [...state.items, ...action.payload.offerLetterIds],
                isLoading: false,
            };
        default:
            return state;
    }
}

function startDates(
    state: Record<string, string> = initialState.startDates, action: ActionsReturnTypes<typeof getOfferLetters>,
): Record<string, string> {
    switch (action.type) {
        case getOfferLetters.successType:
            return action.payload.reduce((acc, item) => ({
                ...acc,
                [item.start_date]: item.start_date,
            }), state);
        default:
            return state;
    }
}

export const pdfCheckIsLoading = isLoadingReducer(checkOfferLetterPdf, false);
export const pdfCheckResult = withMessageReducer(checkOfferLetterPdf, null);

const pdfCheck = combineReducers({
    isLoading: pdfCheckIsLoading,
    result: pdfCheckResult,
});
type PdfCheckState = ReturnType<typeof pdfCheck>;
export type PdfCheckStateById = Record<string, PdfCheckState>;

export const pdfCheckById = (
    state: PdfCheckStateById,
    action: ActionsReturnTypes<typeof checkOfferLetterPdf>,
): PdfCheckStateById => {
    const fixedState = state || initialState.pdfCheck;
    switch (action.type) {
        case checkOfferLetterPdf.initType:
        case checkOfferLetterPdf.successType:
        case checkOfferLetterPdf.errorType:
            return {
                ...fixedState,
                [action.payload.id]: {
                    ...(fixedState[action.payload.id] || {}),
                    ...pdfCheck(fixedState[action.payload.id] || {}, action),
                },
            };
        default:
            return fixedState;
    }
};

export function employeeProfilesByClientId(
    state: ItemsById<IEmployeeProfile> = initialState.employeeProfilesByClientId,
    action: OfferLetterActions,
): ItemsById<IEmployeeProfile> {
    switch (action.type) {
        case updateEmployeeProfile.successType:
        case getMyEmployeeProfile.successType:
            return {
                ...state,
                [action.payload.client_id]: action.payload,
            };
        case getEmployeeProfiles.successType:
            return {
                ...state,
                ...action.payload.reduce((acc, item) => ({
                    ...acc,
                    [item.client_id]: item,
                }), {}),
            };
        default:
            return state;
    }
}

export function employeeProfileStatusCheckingByClientId(
    state: Record<string, boolean> = initialState.employeeProfileStatusCheckingByClientId,
    action: OfferLetterActions,
): Record<string, boolean> {
    switch (action.type) {
        case checkEmployeeOnboardingStatus.initType: {
            const { profile } = action.payload;
            return {
                ...state,
                [profile.client_id]: true,
            };
        }
        case checkEmployeeOnboardingStatus.successType: {
            const profile = action.payload;
            return {
                ...state,
                [profile.client_id]: false,
            };
        }
        default:
            return state;
    }
}

export const offerLetter = combineReducers({
    offerLetterFilters,
    getOfferLetterLoading,
    myOfferLettersLoading,
    offerLetterById,
    startDates,
    lookup,
    offerCreate,
    offerEdit,
    offerRescind,
    rejectOfferLetter,
    employeeOfferDetail,
    pdfCheck: pdfCheckById,
    template: offerLetterTemplate,
    employeeProfilesByClientId,
    employeeProfileStatusCheckingByClientId,
    offerLettersTable,
});

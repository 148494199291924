/* eslint-disable react/display-name */
import { useFeature } from '@optimizely/react-sdk';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getApprovedLevelCell,
    getDetailsCell,
    getHoursAmountCell,
    getReasonRejectionCell,
    getStatusCell,
    hoursAmountCellKey,
} from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useSheetColumnDictionary } from 'shared/components/table/SheetSupervisorTable/useSheetColumnDictionary';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { getMobileItemsRowByConfig } from 'shared/components/table/utils';
import { IJobNumber } from 'shared/models/JobNumber';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { EntryType, ISheet, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { userFullName } from 'shared/utils/converters/user';
import { areaView } from 'shared/utils/formatters/areaFormatter';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { selectActiveGridStatus } from 'store/entities/appConfig/appConfigSelectors';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import {
    AvailableTableConfiguration,
    IColumnConfiguration,
    SheetColumnSlug,
} from 'store/entities/clients/clientsModel';
import { IArea } from 'store/entities/configuration/configurationModel';
import { selectTableConfiguration } from 'store/entities/clients/clientsSelectors';
import { getColumnsByConfiguration } from 'store/utils/tables';
import { FeatureSwitches } from 'utils/featureSwitches';
import { IDepartment } from 'modules/employmentInfo/models/Department';

export interface ISheetRow {
    sheet: ISheet;
    area: IArea;
    user?: IUserInfo;
    className?: string;
    approvers: IUserInfo[];
    position?: IPosition;
    location?: ILocation;
    department?: IDepartment;
    jobNumber?: IJobNumber;
    calculation?: ITimesheetCalculation;
}

export const useCells = (
    entryType: EntryType,
    isMobile: boolean,
    prefixCell?: ICellInfo<ISheetRow>,
    onDetailsClick?: (sheetInfo: ISheetClickInfo) => void,
    showStatus = false,
    rows: ISheetRow[] = [],
): Array<ICellInfo<ISheetRow>> => {
    const classes = useSubmittedTableStyles();
    const activeStatus = useSelector(selectActiveGridStatus);
    const showReason = activeStatus === StatusNames.REJECTED;
    const configuration = useSelector(selectTableConfiguration(
        entryType === EntryType.EXPENSE
            ? AvailableTableConfiguration.EmployeeExpenseSheet : AvailableTableConfiguration.EmployeeTimeSheet,
    )) as IColumnConfiguration<SheetColumnSlug>[];
    const columnDictionary = useSheetColumnDictionary(isMobile);
    const [statusCellFeatureFlag] = useFeature(FeatureSwitches.displayStatusColumnSubmitView);
    const hasFiles = useMemo(() => {
        return Boolean(rows.find(row => row.sheet?.total_files));
    }, [rows]);
    const hasHours = useMemo(() => {
        return Boolean(rows.find(row => row.sheet?.total_minutes));
    }, [rows]);

    return useMemo(() => {
        const getHoursAmountCellByTitle = getHoursAmountCell(classes, entryType, isMobile);
        const detailsCell = getDetailsCell(classes, entryType, onDetailsClick, isMobile);
        const statusCell = getStatusCell(classes, statusCellFeatureFlag, showStatus);
        const approvedLevelCell = getApprovedLevelCell(classes, true, activeStatus);
        const reasonRejectionCell = getReasonRejectionCell(showReason);
        const columnsByConfiguration = getColumnsByConfiguration(configuration, columnDictionary, classes);
        columnsByConfiguration.splice(columnsByConfiguration.length - 2, 0, ...reasonRejectionCell);

        const cells = isMobile ? [
            {
                key: 'fullEmployeeInfo',
                title: '',
                width: '2fr',
                render: ({
                    sheet,
                    area,
                    position,
                    className,
                    approvers,
                }: ISheetRow) => (
                    <MobileListCell
                        title={getMobileItemsRowByConfig(
                            [
                                {
                                    slug: SheetColumnSlug.Area,
                                    getText: () => areaView(area),
                                },
                                {
                                    slug: SheetColumnSlug.Position,
                                    getText: () => position?.name,
                                },
                            ],
                            configuration,
                        )}
                        className={className}
                        items={[
                            getPayPeriodByStartEnd(sheet.period_start, sheet.period_end),
                            approvers.map(approver => userFullName(approver)).join(', '),
                        ]}
                    />
                ),
            },
            getHoursAmountCellByTitle(entryType === EntryType.TIME ? 'hours' : 'amount'),
            detailsCell,
        ] : [
            ...columnsByConfiguration,
            ...statusCell,
            ...approvedLevelCell,
            detailsCell,
        ];
        if (entryType === EntryType.TIME && hasFiles) {
            /**
             * Patch cell header for files
             */
            const hoursAmountCell = cells.find(cell => cell.key === hoursAmountCellKey);
            const filesCellHeader = 'files';
            if (hoursAmountCell) {
                if (hasHours) {
                    hoursAmountCell['title'] = `${hoursAmountCell['title']}/${filesCellHeader}`;
                } else {
                    hoursAmountCell['title'] = filesCellHeader;
                }
            }
        }

        return prefixCell ? [prefixCell].concat(cells) : cells;
    }, [
        classes,
        isMobile,
        entryType,
        prefixCell,
        onDetailsClick,
        showStatus,
        statusCellFeatureFlag,
        configuration,
        columnDictionary,
        showReason,
        activeStatus,
        hasFiles,
        hasHours,
    ]);
};

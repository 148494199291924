import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Box, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { IStore } from 'store/configureStore';
import { ITableContainerCssProps, useApprovalTableStyles } from 'shared/components/table/TableApprovalStyles';
import { EntryType, ISheet, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import FilterAndActionControls from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControls';
import { useSheetRowDataBySheets } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import FilterAndActionMobile from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionMobile';
import ToolbarApproval from 'shared/components/toolbar/ToolbarApproval';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import { useSheetReviewerCells } from 'shared/components/table/SheetSupervisorTable/sheetSupervisorCells';
import { ISheetViewProps } from 'modules/clients/content/supervisor/SheetsView/SheetsViewModel';
import { getSheetRowIdByRow } from 'shared/components/table/SheetSupervisorTable/utils';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectManagerSubmittedSheetsFilters } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';

interface ISheetApprovalTableProps extends ISheetViewProps {
    checkboxCell?: ICellInfo<ISheetRow>;
    actionsCell?: ICellInfo<ISheetRow>;
    filteredSheets?: Array<ISheet>;
}

export default function SheetApprovalTable({
    sheets, linked, sheetType, checkboxCell, actionsCell, filteredSheets = [], isLoading,
}: ISheetApprovalTableProps) {
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const hoverStatus = useSelector((state: IStore) => state.appConfig.approvalGridHoverStatus);
    const clientId = useSelector(selectCurrentClientId);

    const classesProps: ITableContainerCssProps = useMemo(() => ({
        rowId: hoverStatus.rowId,
        color: hoverStatus.color,
    }), [hoverStatus]);

    const classes = useApprovalTableStyles(classesProps);
    const tableClasses = useSubmittedTableStyles();
    const hasFilters = isNotEmpty(useSelector(selectManagerSubmittedSheetsFilters));

    const showStatus = activeStatus === StatusNames.ALL;
    const showFilters = activeStatus !== StatusNames.ALL;

    const onSheetDetailAction = useCallback((sheetClickInfo: ISheetClickInfo) => {
        const getSheetDetailRoute = (entryType: EntryType) => {
            if (entryType === EntryType.TIME) {
                return routes.CLIENT.TIME_APPROVAL.SHEET_DETAIL;
            }
            return routes.CLIENT.EXPENSE_APPROVAL.SHEET_DETAIL;
        };
        const route = getSheetDetailRoute(sheetClickInfo.entryType);
        browserHistory.push(generatePath(route, {
            client_id: clientId || '',
            sheetId: sheetClickInfo.sheetId,
        }));
    }, [clientId]);

    const sheetsRows = useSheetRowDataBySheets(sheets);

    const cells = useSheetReviewerCells(
        sheetsRows,
        onSheetDetailAction,
        sheetType, isMobile,
        checkboxCell,
        actionsCell,
        showStatus,
    );

    const title = showFilters && hasFilters
        ? `${sheetsRows.length} ${pluralize('Result', sheetsRows.length)}.` : undefined;

    return (
        <Box className={classes.mainContainer}>
            <Hidden xsDown>
                <FilterAndActionControls
                    sheetType={sheetType}
                    linkedUsers={linked}
                    sheets={filteredSheets}
                    title={title}
                    showFilters={showFilters}
                    showActionControls={filteredSheets?.length > 0}
                />
            </Hidden>
            <Box className={clsx(classes.tableMain, classes.tableWrapper)} mb={4}>
                <ToolbarApproval classes={{ root: classes.toolbar }}>
                    <FilterAndActionMobile
                        sheetType={sheetType}
                        linkedUsers={linked}
                        sheets={filteredSheets}
                        showActionControls={filteredSheets?.length > 0}
                        showFilters={showFilters}
                        title={title}
                    />
                </ToolbarApproval>
                <GridTable
                    rowData={sheetsRows}
                    getKey={row => row.sheet.id}
                    getRowId={getSheetRowIdByRow}
                    cells={cells}
                    headerCellClassName={tableClasses.headCell}
                    bodyCellClassName={tableClasses.bodyCell}
                    isLoading={isLoading}
                    hideHeader={isMobile}
                    stickyHeader
                />
            </Box>
        </Box>
    );
}

import { IStore } from 'store/configureStore';

export const selectOnboardingStep = (state: IStore) => state.modules.offerLetter.employeeOfferDetail.onboardingStep;
export const selectOfferIsUpdating = (state: IStore) => state.modules.offerLetter.employeeOfferDetail.isOfferUpdating;
export const selectProfileIsCreated = (state: IStore) =>
    state.modules.offerLetter.employeeOfferDetail.isProfileCreated;
export const selectProfileIsUpdating = (state: IStore) =>
    state.modules.offerLetter.employeeOfferDetail.isProfileUpdating;
export const selectIsSSOLikLoading = (state: IStore) =>
    state.modules.offerLetter.employeeOfferDetail.isSSOLinkLoading;
export const selectSSOLink = (state: IStore) =>
    state.modules.offerLetter.employeeOfferDetail.SSOLink;
export const selectIsMyEmployeeProfileLoading = (state: IStore): boolean =>
    state.modules.offerLetter.employeeOfferDetail.isEmployeeProfileLoading;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { selectDepartmentById } from 'modules/employmentInfo/store/department/selectors';
import DepartmentFormWrapper
    from 'modules/settings/submodules/clients/departments/components/DepartmentForm/DepartmentFormWrapper';
import { IDepartmentFormValues } from 'modules/settings/submodules/clients/departments/components/DepartmentForm/model';
import { setEditDepartmentId, updateDepartment } from 'modules/settings/submodules/clients/departments/store/actions';
import {
    selectEditDepartmentId,
    selectIsDepartmentUpdating,
} from 'modules/settings/submodules/clients/departments/store/selectors';
import { FormMode } from 'shared/models/FormMode';

export default function EditDepartmentModal() {
    const dispatch = useDispatch();
    const modalClasses = useSettingsFormModalStyles();
    const editId = useSelector(selectEditDepartmentId);
    const isLoading = useSelector(selectIsDepartmentUpdating);
    const department = useSelector(selectDepartmentById(editId)) as IDepartment;

    const onSave = useCallback((values: IDepartmentFormValues) => {
        dispatch(updateDepartment.init({
            id: editId,
            data: values,
        }));
    }, [dispatch, editId]);
    const onClose = useCallback(() => {
        dispatch(setEditDepartmentId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Department"
            isOpened={Boolean(editId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <DepartmentFormWrapper
                    submitTitle="Save"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    department={department}
                    mode={FormMode.Edit}
                />
            </Box>
        </ModalDialog>
    );
}

import { EditClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/EditClientLogo';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MainContent from 'shared/components/common/MainContent';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { Box, Button } from '@material-ui/core';
import {
    FiltersTestIds,
} from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { ClientsTable } from 'modules/settings/submodules/clients/components/ClientsTable/ClientsTable';
import useActionButtonStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { setClientId } from 'store/entities/clients/clientsAction';
import { useStyles } from './styles';
import { NewClientButton } from 'modules/settings/submodules/clients/components/NewClientButton/NewClientButton';

export const ClientListPage = () => {
    const dispatch = useDispatch();
    const buttonClasses = useActionButtonStyles();
    const classes = useStyles();

    useEffect(() => {
        dispatch(setClientId(null));
    }, [dispatch]);

    return (
        <>
            <CommonSettingsSideMenu/>
            <MainContent whiteBackground>
                <Box display="flex" justifyContent="space-between"
                    className={classes.controls}>
                    <NewClientButton/>
                    <Button
                        classes={{ root: buttonClasses.default }}
                        data-testid={FiltersTestIds.Button}
                    >
                        <FilterSVG/>
                    </Button>
                </Box>
                <ClientsTable/>
                <EditClientLogo/>
            </MainContent>
        </>
    );
};

import React from 'react';
import { maxDollarsExpenseValue } from 'shared/models/validationSchemes/expenseEntry';
import { IFormFieldProps } from './models';
import NumberField from './NumberField';

interface IMoneyFieldProps extends IFormFieldProps {}

export default function MoneyField({
    ...props
}: IMoneyFieldProps) {
    return (
        <NumberField
            {...props}
            inputProps={{
                decimalScale: 2,
                thousandSeparator: true,
                allowNegative: false,
                fixedDecimalScale: true,
            }}
            min={0}
            max={maxDollarsExpenseValue}
        />
    );
}

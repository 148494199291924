import { addNewAttachment, AttachmentActions, removeAttachment, SET_FILE_READ } from 'store/entities/timesheet/actions/entryAttachments';
import { IEntryAttachment } from 'shared/models/Attachments';
import { combineReducers } from 'redux';
import { addExpenseEntry, ExpenseSheetActions, updateExpenseEntry } from '../actions/expenseActions';
import { addTimeEntry, TimeSheetActions, updateTimeEntry } from '../actions/timeActions';
import { isLoadingReducer } from 'store/reducerUtils';

const defaultState = {
    isUploading: false,
    attachments: [],
};

const isUploading = isLoadingReducer(addNewAttachment, defaultState.isUploading);

function setFileRead(state = defaultState.isUploading, action: AttachmentActions): boolean {
    switch (action.type) {
        case SET_FILE_READ:
            return action.payload;
        case addNewAttachment.successType:
        case removeAttachment.successType:
            return false;        
        default:
            return state;
    }
}

export function attachments(
    state: IEntryAttachment[] = defaultState.attachments,
    action: AttachmentActions | ExpenseSheetActions | TimeSheetActions,
): IEntryAttachment[] {
    switch (action.type) {
        case addNewAttachment.successType:
        {
            return [
                ...state,
                action.payload];
        }
        case addExpenseEntry.initType:
        case addTimeEntry.initType:
        case updateExpenseEntry.initType:
        case updateTimeEntry.initType: {
            return [];
        }
        case removeAttachment.successType:{
            return state.filter(entry => entry.id !== action.payload);
        }
        default:
            return state;
    }
}

export const sheetEntryAttachments = combineReducers({
    attachments, isUploading, setFileRead,
});

export type AttachmentsState = ReturnType<typeof sheetEntryAttachments>;

import React, { useState } from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { Box } from '@material-ui/core';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useSheetsSubmittedViewStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedPageStyles';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import EntryTypeSelect from 'shared/components/selects/EntryTypeSelect';
import MobileFiltersAndAmount from 'shared/components/sheetsSubmitted/filterAndActionControls/MobileFiltersAndAmount';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import { setEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import { selectEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';

interface ISheetsSubmittedMobileViewProps {
    timeCells: Array<ICellInfo<ISheetRow>>;
    expenseCells: Array<ICellInfo<ISheetRow>>;
    sheetsEntryTypes?: Record<string, EntryType>;
    timeRows: Array<ISheetRow>;
    expenseRows: Array<ISheetRow>;
    isLoading: boolean;
    showFilters?: boolean;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

export default function SheetsSubmittedMobileView({
    timeCells, expenseCells, timeRows, expenseRows, sheetsEntryTypes,
    isLoading, showFilters, availableActions: AvailableActions,
}: ISheetsSubmittedMobileViewProps) {
    const [entryType, setEntryType] = useState(EntryType.TIME);
    const classes = useSheetsSubmittedViewStyles();
    const tableClasses = useSubmittedTableStyles();

    const sheetsRows = entryType === EntryType.TIME ? timeRows : expenseRows;
    const sheets = sheetsRows.map(({ sheet }) => sheet);

    return (
        <>
            <Box className={classes.mainContainer}>
                {(showFilters ?? true) && (
                    <FiltersChips action={setEmployeeSubmittedSheetsFilters} className={classes.filtersWrapper}
                        selector={selectEmployeeSubmittedSheetsFilters}
                    />
                )}
                {AvailableActions && sheetsEntryTypes && Object.keys(sheetsEntryTypes).length !== 0 ? (
                    <AvailableActions typedSheets={sheetsEntryTypes} variant="contained"/>
                ) : (
                    <EntryTypeSelect className={classes.entryTypeSwitcher}
                        value={entryType} onChange={setEntryType}
                    />
                )}

                <Box className={tableClasses.tableMain}>
                    <GridTable rowData={entryType === EntryType.TIME ? timeRows : expenseRows}
                        headerCellClassName={tableClasses.headCell}
                        bodyCellClassName={tableClasses.bodyCell}
                        getKey={({ sheet }) => sheet.id}
                        cells={entryType === EntryType.TIME ? timeCells : expenseCells}
                        isLoading={isLoading}
                        hideHeader={true}
                    />
                </Box>
                <MobileFiltersAndAmount sheets={sheets} sheetType={entryType}/>
            </Box>
        </>
    );
}

/* eslint-disable react/display-name */
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useDispatch, useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { shortDateFormat } from 'shared/models/Dates';
import { UWSystemDisplayValues } from 'shared/models/JobNumber';
import { getUserName } from 'shared/utils/converters/user';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IJobNumberRow, useJobNumberRows } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberTable/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { getMoreJobNumbersAction, initialLoadJobNumbers, setEditJobNumberId } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { jobNumbersTableStateSelectors } from 'modules/settings/submodules/clients/jobNumber/store/selectors';
import { PaginationCounter } from 'modules/settings/submodules/components/PaginationCounter/PaginationCounter';

export const JobNumberTable = () => {
    const isMobile = useIsMobile();
    const tableClasses = useDefaultTableStyles();
    const rows = useJobNumberRows();
    const hasMoreRows = useSelector(jobNumbersTableStateSelectors.selectHasMore);
    const total = useSelector(jobNumbersTableStateSelectors.selectTotalItems);
    const isLoading = useSelector(jobNumbersTableStateSelectors.isLoading);

    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();
    useEffect(() => {
        if (clientId) {
            dispatch(initialLoadJobNumbers());
        }
    }, [dispatch, clientId]);
    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreJobNumbersAction.init());
        }
    }, [dispatch, hasMoreRows]);

    const cells = [
        {
            key: 'job_number',
            title: 'job #',
            render: ({ job_number, className }: IJobNumberRow) => (
                <PlainText className={className} value={job_number.toString()}/>
            ),
        },
        {
            key: 'deal_number',
            title: 'deal #',
            render: ({ deal, className }: IJobNumberRow) => (
                <PlainText className={className} value={deal?.deal_number?.toString()}/>
            ),
        },
        {
            key: 'user_type',
            title: 'user type',
            render: ({ className, userType }: IJobNumberRow) => (
                <PlainText className={className} value={userType?.name}/>
            ),
        },
        {
            key: 'employee',
            title: 'employee',
            render: ({ className, employee }: IJobNumberRow) => (
                <PlainText className={className} value={getUserName(employee)}/>
            ),
        },
        {
            key: 'uw_system',
            title: 'uw system',
            render: ({ className, uw_system }: IJobNumberRow) => (
                <PlainText className={className} value={UWSystemDisplayValues[uw_system]}/>
            ),
        },
        {
            key: 'start_date',
            title: 'start date',
            render: ({ className, start_date }: IJobNumberRow) => (
                <PlainText className={className} value={moment(start_date).format(shortDateFormat)}/>
            ),
        },
        {
            key: 'end_date',
            title: 'end date',
            render: ({ className, end_date }: IJobNumberRow) => (
                <PlainText className={className} value={end_date ? moment(end_date).format(shortDateFormat) : ''}/>
            ),
        },
        {
            key: 'actions',
            title: '',
            width: '54px',
            render: function DetailsCell({ className, id }: IJobNumberRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditItemButton id={id} editAction={setEditJobNumberId}/>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <InfinityGridTable
                rowData={rows}
                getKey={row => row.id}
                cells={cells}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hideHeader={isMobile}
                stickyHeader
                isLoading={isLoading}
                onLoadMore={onLoadMoreRows}
                hasMore={hasMoreRows}
            />
            <PaginationCounter
                shown={rows.length}
                total={total}
            />
        </>
    );
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { AppClient } from 'utils/constants';

interface IHeaderTagsProps {
    title: string;
}

const getTagsConfig = () => {
    if (process.env.REACT_APP_CLIENT === AppClient.RTI) {
        return {
            favicon32: 'rti_favicon.ico',
            favicon16: 'rti_favicon.ico',
        };
    }
    return {
        favicon180: 'apple-touch-icon.png',
        favicon32: 'favicon-32x32.png',
        favicon16: 'favicon-16x16.png',
    };
};

export function HeaderTags({ title }: IHeaderTagsProps) {
    const { favicon180, favicon32, favicon16 } = getTagsConfig();

    return (
        <Helmet>
            <title>{title}</title>
            {favicon180 && (
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${process.env.PUBLIC_URL}/${favicon180}`}
                />
            )}
            {favicon32 && (
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={`${process.env.PUBLIC_URL}/${favicon32}`}
                />
            )}
            {favicon16 && (
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={`${process.env.PUBLIC_URL}/${favicon16}`}
                />
            )}
        </Helmet>
    );
}

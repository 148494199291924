import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { ILocation } from 'shared/models/Location';
import { IFormFieldProps } from '../formFields/models';
import {
    selectLocations,
} from 'store/entities/configuration/configurationSelectors';
import { IFormSelect } from './model';

interface ILocationProps extends IFormFieldProps, IFormSelect {
    positionId?: string;
}

export const Location = ({ positionId, ...props }: ILocationProps) => {
    let locations = useSelector(selectLocations);
    if (positionId) {
        locations = locations.filter(
            location => location.positions?.includes(positionId),
        );
    }

    return (
        <FormSelect
            getKey={(location: ILocation) => location.id}
            getText={(location: ILocation) => location.name}
            options={locations}
            {...props}
        />
    );
};

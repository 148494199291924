import React from 'react';
import moment from 'moment';
import { DatePicker, DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useField } from 'formik';
import { backendDateFormat, shortDateFormat } from 'shared/models/Dates';
import { IFormFieldProps } from './models';

export interface IDayPickerFieldProps extends IFormFieldProps,
    Omit<Partial<DatePickerProps>, 'label' | 'name' | 'id'> {
    format?: string;
    disableToolbar?: boolean;
    withKeyboard?: boolean;
}

const DayPickerField = ({
    name,
    label,
    id = name,
    className,
    disabled = false,
    format = shortDateFormat,
    variant = 'inline',
    withKeyboard = false,
    inputVariant = 'outlined',
    ...props
}: IDayPickerFieldProps) => {
    const [field, meta, helper] = useField(name);

    const { touched, error } = meta;
    const errorMessage = typeof error === 'string' ? touched && error : null;
    const handleChange = (date: MaterialUiPickersDate) => {
        const formattedValue = date && date.isValid() ? date.format(backendDateFormat) : '';
        helper.setValue(formattedValue);
        helper.setTouched(true);
    };
    const DatePickerComponent = withKeyboard ? KeyboardDatePicker : DatePicker;
    return (
        <DatePickerComponent
            {...props}
            id={id}
            key={field.value}
            label={label}
            name={name}
            disabled={disabled}
            className={className}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            inputVariant={inputVariant}
            format={format}
            variant={variant}
            value={field.value ? moment(field.value) : null}
            onChange={handleChange}
        />
    );
};

export default DayPickerField;

import { IStore } from 'store/configureStore';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { selectClientTimeTrackingActivityTypes } from 'store/entities/clients/clientsSelectors';

export const selectDataTypes = (entryType: EntryType) => {
    return (state: IStore): QuantityType[] => {
        if (entryType === EntryType.TIME) {
            return selectClientTimeTrackingActivityTypes(state);
        }

        if (entryType === EntryType.EXPENSE){
            return [
                QuantityType.MONEY,
                QuantityType.MILES,
                QuantityType.ODOMETER,
            ];
        }

        return [];
    };
};

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { settingsRoutes } from 'modules/settings/routes';
import { DashboardLink } from 'modules/settings/submodules/components/DashboardLink/DashboardLink';
import { Permission } from 'store/components/auth/authModels';
import { FeatureSwitches } from 'utils/featureSwitches';

export const SettingsDashboard = () => {
    const items = [
        {
            title: 'CLIENTS',
            description: 'Manage, add and configure client settings.',
            link: settingsRoutes.CLIENTS.ROOT,
            permission: Permission.modifyClientSettings,
        },
        {
            title: 'Platform users',
            description: 'Manage platform users.',
            link: settingsRoutes.PLATFORM_USERS.ROOT,
            feature: FeatureSwitches.enablePlatformUsersManagement,
            permission: Permission.ManageManagerUsers,
        },
        {
            title: 'Employees',
            description: 'Manage employees.',
            link: settingsRoutes.EMPLOYEES.ROOT,
            feature: FeatureSwitches.enableEmployeeManagement,
            permission: Permission.ManageEmployeeUsers,
        },
        {
            title: 'Offer Letter Templates',
            description: 'Manage and add offer letter templates.',
            link: settingsRoutes.OFFER_LETTERS.TEMPLATES,
            feature: FeatureSwitches.enableOfferLetterTemplates,
            permission: Permission.ManageOfferLetterTemplates,
        },
        {
            title: 'Custom Fields',
            description: 'Manage and add custom fields.',
            link: settingsRoutes.CUSTOM_FIELDS.ROOT,
            feature: FeatureSwitches.enableCustomFieldsModule,
            // TODO: Check permission
        },
    ];

    return (
        <Box m={3} width="100%">
            <Grid
                container
                spacing={3}
            >
                {items.map(item => (
                    <DashboardLink
                        key={item.title}
                        {...item}
                    />
                ))}
            </Grid>
        </Box>
    );
};

import React, { useCallback, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Delete } from '@material-ui/icons';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { deleteClientWorkLocation } from 'modules/settings/submodules/clients/workLocation/store/actions';

export interface IDeleteWorkLocationButtonProps {
    locationId: string;
}

export const DeleteWorkLocationButton = ({ locationId }: IDeleteWorkLocationButtonProps) => {
    const dispatch = useDispatch();
    const [isOpenConfirmation, setOpenConfirmation] = useState(false);

    const onCancel = useCallback(() => {
        setOpenConfirmation(false);
    }, [setOpenConfirmation]);
    const onConfirm = useCallback(() => {
        dispatch(deleteClientWorkLocation.init(locationId));
        onCancel();
    }, [dispatch, locationId, onCancel]);

    return (
        <>
            <IconButton
                onClick={() => setOpenConfirmation(true)}
                color="secondary"
                data-testid="details-button"
            >
                <Delete/>
            </IconButton>
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={onConfirm}
                onCancel={onCancel}
                confirmText="Delete"
            >
                Are you sure you want to delete Work Location?
            </ConfirmationDialog>
        </>
    );
};

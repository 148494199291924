import { Box, ThemeProvider, Typography } from '@material-ui/core';
import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import ShowPayRateType from 'modules/offerLetter/components/OfferLetterForm/ShowPayRateType';
import React from 'react';
import MoneyField from 'shared/components/formFields/MoneyField';
import { sidebarDarkSectionTheme } from 'shared/components/sidebars/EntryEdit/sidebarDarkSectionTheme';
import { useStyles } from '../styles';

export interface IOfferLetterPayRateFieldProps {
    values: IOfferLetterFormValues;
}

export function OfferLetterPayRateField({
    values,
}: IOfferLetterPayRateFieldProps) {
    const classes = useStyles();
    const { position, payRateType } = values;

    return (
        <section className={classes.darkSection}>
            <ThemeProvider theme={sidebarDarkSectionTheme}>
                <Typography color="primary" variant="h6">
                    Pay Rate
                </Typography>
                <Box display="flex" mt={-1}>
                    <Box width="130px">
                        <MoneyField
                            key={position?.id}
                            name="payRate"
                            label="$"
                        />
                    </Box>
                    <Box ml={3} mt={2}>
                        <ShowPayRateType type={payRateType}/>
                    </Box>
                </Box>
            </ThemeProvider>
        </section>
    );
}

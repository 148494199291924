import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { SideMenu } from 'modules/settings/submodules/components/SideMenu/SideMenu';
import getClientSubModules from './utils/getClientSubModules';

export const ClientDetailSideMenu = () => {
    const clientId = useSelector(selectCurrentClientId);
    const items = getClientSubModules(clientId);

    return (
        <SideMenu items={items}/>
    );
};

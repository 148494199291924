import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { CheckedItems, OnCheck } from 'shared/utils/hooks/useCheckedItems';

export interface ICheckboxClasses {
    iconCell: string;
    checkbox: string;
    checkboxChecked?: string;
}

export function getCheckboxCell<IRow extends { className?: string }>(
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
    classes: ICheckboxClasses,
    isMobile: boolean,
    getId: (row: IRow) => string,
): ICellInfo<IRow> {
    return {
        key: 'checkbox',
        title: '',
        width: isMobile ? '40px' : '72px',
        headerClassName: clsx(classes.iconCell, classes.checkbox),
        renderTitle: function CheckboxCellHeader() {
            return (
                <Checkbox
                    color="primary"
                    checked={checkedAll}
                    onChange={() => onCheckAll(!checkedAll)}
                    classes={{ checked: classes.checkbox }}
                />
            );
        },
        render: function CheckboxCell(row: IRow) {
            return (
                <div className={clsx(row.className, classes.iconCell, classes.checkbox)}>
                    <Checkbox
                        color="primary"
                        checked={checkedItems[getId(row)] ?? false}
                        onChange={() => onCheck(!(checkedItems[getId(row)] ?? false), getId(row))}
                        classes={{ checked: classes.checkboxChecked }}
                    />
                </div>
            );
        },
    };
}

import baseApi from 'shared/utils/baseApi';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { ICreateDepartmentRequest } from 'modules/settings/submodules/clients/departments/store/models';
import { withClientId } from 'store/utils/withClientId';

const servicePath = 'client-users';
const departmentPath = 'departments';

export const departmentApi = {
    async createDepartment(request: ICreateDepartmentRequest): Promise<IDepartment> {
        const { data } = await baseApi.create<ICreateDepartmentRequest, IDepartment>(
            servicePath, departmentPath, withClientId(request),
        );
        return data;
    },
    async updateDepartment(
        id: string,
        request: ICreateDepartmentRequest,
    ): Promise<IDepartment> {
        const { data } = await baseApi.patch<ICreateDepartmentRequest, IDepartment>(
            `${servicePath}/${departmentPath}/${id}`, request,
        );
        return data;
    },
    async deleteDepartment(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${servicePath}/${departmentPath}`, id,
        );
        return data.status;
    },
};

import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import { ISheet } from 'shared/models/sheet/Sheet';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import {
    selectAreasById,
    selectAssignmentsById, selectJobNumbersById,
    selectLocationsById,
    selectPositionsById,
} from 'store/entities/configuration/configurationSelectors';
import {
    selectCalculationsByTimesheetId,
    selectExpensesSheetsByIds,
    selectTimeSheetsByIds,
    selectSheetsIsLoading,
} from 'store/entities/timesheet/selectors';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IUserSelfInfo } from 'store/components/auth/authModels';
import { selectUsersById } from 'store/entities/users/selectors';
import { selectEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { IEmployeeSubmittedSheets } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsModel';
import { ItemsById } from 'shared/models/ItemsById';
import { IAssignment, IManagerInfo } from 'store/entities/configuration/configurationModel';
import { IFiltersAllOptions } from 'shared/models/Filters';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { selectDepartmentsById } from 'modules/employmentInfo/store/department/selectors';
import { selectClientTimeAndPayConfiguration } from '../../../../../store/entities/clients/clientsSelectors';
import { ApproversFromFields } from '../../../../../store/entities/clients/clientsModel';

function getFilteredSheetsByStatusAndType(
    sheets: Array<ISheet>, activeStatus: StatusNames, user: IUserSelfInfo,
    filters: Partial<IEmployeeSubmittedSheets>, assignmentsById: ItemsById<IAssignment>,
): Array<ISheet> {
    const filteredSheets = sheets
        .filter(sheet => sheet.user_id === user.id)
        .filter(
            sheet => activeStatus === StatusNames.ALL ? true : sheet.status.name === activeStatus,
        );

    return isNotEmpty(filters) ? filteredSheets.filter(sheet => {
        const assignment = assignmentsById[sheet.assignment_id ?? ''];

        if (assignment && filters.position_id && filters.position_id !== assignment.position_id) {
            return false;
        }

        if (assignment && filters.location_id && filters.location_id !== assignment.location_id) {
            return false;
        }

        return true;
    }) : filteredSheets;
}

export function useSheetRowDataBySheets(
    sheets: Array<ISheet>,
): Array<ISheetRow> {
    const areasById = useSelector(selectAreasById);
    const positionsById = useSelector(selectPositionsById);
    const locationsById = useSelector(selectLocationsById);
    const departmentById = useSelector(selectDepartmentsById);
    const assignmentsById = useSelector(selectAssignmentsById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const usersById = useSelector(selectUsersById);
    const calculationsByTimesheetId = useSelector(selectCalculationsByTimesheetId);
    const timeAndPayConfiguration = useSelector(selectClientTimeAndPayConfiguration);
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;

    return sheets.map(sheet => {
        const assignment = assignmentsById[sheet.assignment_id || ''];
        const position = positionsById[assignment?.position_id || ''];
        const location = locationsById[assignment?.location_id || ''];
        const department = departmentById[assignment?.department_id || ''];
        const jobNumber = jobNumbersById[sheet.job_number_id || ''];
        const approverIds: number[] = approverFrom === ApproversFromFields.FromAssignment
            ? assignment?.managers.map((manager: IManagerInfo) => manager.user_id) ?? []
            : jobNumber?.manager_id ? [jobNumber.manager_id] : [];
        const approvers = approverIds.map(approverId => usersById[approverId]) || [];
        // Calculation required only for time sheets for define reg/ot/dt
        const calculation = calculationsByTimesheetId[sheet.id];
        const user = usersById[sheet.user_id || ''];
        return {
            sheet,
            area: areasById[sheet.area_id],
            position,
            location,
            department,
            approvers,
            jobNumber,
            user,
            calculation,
        };
    });
}

export function useSheetsData(filterData = true) {
    const user = useSelector(selectCurrentUser) as unknown as IUserSelfInfo;
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    let filters: Partial<IFiltersAllOptions> = useSelector(selectEmployeeSubmittedSheetsFilters);

    if (!filterData) {
        filters = {};
    }

    const assignmentsById = useSelector(selectAssignmentsById);
    const isLoading = useSelector(selectSheetsIsLoading);

    const timeSheets = getFilteredSheetsByStatusAndType(
        Object.values(useSelector(selectTimeSheetsByIds)),
        activeStatus,
        user,
        filters,
        assignmentsById,
    );
    const expenseSheets = getFilteredSheetsByStatusAndType(
        Object.values(useSelector(selectExpensesSheetsByIds)),
        activeStatus,
        user,
        filters,
        assignmentsById,
    );

    const timeRows = useSheetRowDataBySheets(timeSheets);
    const expenseRows = useSheetRowDataBySheets(expenseSheets);

    return {
        timeSheets,
        expenseSheets,
        timeRows,
        expenseRows,
        isLoading,
    };
}

export interface IAvailableActionsProps {
    typedSheets: SheetsEntryTypes;
    onAction?: () => void;
    variant?: 'text' | 'outlined' | 'contained';
}

import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import { IOfferLetterTemplate } from 'modules/offerLetter/store/templates/models';
import { selectActiveOfferLetterTemplatesSortedByName } from 'modules/offerLetter/store/templates/selectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

interface IOfferLetterTemplateSelectProps extends IFormFieldProps, IFormSelect {

}

interface IOfferLetterTemplateSelectStoreProps {
    offerLetterTemplates?: IOfferLetterTemplate[];
}

export function OfferLetterTemplateSelectPure({
    offerLetterTemplates,
    ...props
}: IOfferLetterTemplateSelectProps & IOfferLetterTemplateSelectStoreProps) {
    return (
        <FormSelect
            getKey={(offerLetterTemplate: IOfferLetterTemplate) => offerLetterTemplate.id}
            getText={(offerLetterTemplate: IOfferLetterTemplate) => offerLetterTemplate.name}
            options={offerLetterTemplates || []}
            {...props}
        />
    );
}

export const OfferLetterTemplateSelect = separateLogicDecorator<
IOfferLetterTemplateSelectProps,
IOfferLetterTemplateSelectStoreProps
>(() => ({
    offerLetterTemplates: useSelector(selectActiveOfferLetterTemplatesSortedByName),
}))(OfferLetterTemplateSelectPure);

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { setPlatformUsersFilter } from 'modules/settings/submodules/platformUsers/store/actions';
import { selectPlatformUserFilter } from 'modules/settings/submodules/platformUsers/store/selectors';
import { debounce } from 'ts-debounce';

export const UserNameFilter = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectPlatformUserFilter);
    const debounceTimeout = 300;
    const changeFilterName = useMemo(() =>
        debounce(
            (name: string) => {
                dispatch(setPlatformUsersFilter({
                    ...filter,
                    name,
                }));
            },
            debounceTimeout),
    [dispatch, filter],
    );
    return (
        <TextFilter onFilterValueChanged={changeFilterName}/>
    );
};

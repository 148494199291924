import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        flexWrap: 'wrap',
        margin: theme.spacing(1, -1),

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: theme.spacing(2),
        },
    },
    input: {
        width: 240,
        minWidth: 160,
        display: 'flex',
        background: colors.white,
        margin: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    headerInput: {
        width: `168px`,
        background: colors.white,
        marginRight: `16px`,
    },
}));

export const useHeaderFormStyles = makeHighPriorityStyles({
    form: {},
    input: {
        width: `168px`,
        background: colors.white,
        marginRight: `16px`,
    },
});

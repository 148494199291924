import React, { useEffect, useMemo } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@material-ui/core';
import useAddControlsStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import UpperButton from 'shared/components/buttons/UpperButton';
import ScaZone from 'shared/components/formSpecialFields/scaZone/ScaZone';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import FormField from 'shared/components/formFields/FormField';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { CommonEntryInlineFields, IBaseCommonEntryFieldsProps } from './CommonEntryInlineFields';
import { defaultValues, ITimeEntryFormValues } from './TimeEntryModel';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { AccessTime } from '@material-ui/icons';
import { ICommonEntryFormValues } from './EntryCommonFields';
import TimeDataField from '../../formSpecialFields/TimeEntryField/TimeDataField';
import CompletesQuantity from 'shared/components/formFields/CompletesQuantity';
import { showField } from '../utils';
import { TimesheetSettings } from 'shared/models/JobNumber';
import {
    isActivityFieldIsVisibleInForm,
    useJobNumberActivities,
} from 'shared/components/forms/entries/helpers/commonEntryFormHelpers';

export interface IAddTimeEntryFormProps extends FormikProps<ITimeEntryFormValues>, IBaseCommonEntryFieldsProps {
    payPeriod: IPayPeriod;
    areaId?: number;
    inputs: InputFields;
    onChangeCommonValues: (values: ICommonEntryFormValues) => void;
    disabled?: boolean;
}

export function AddTimeEntryInlineForm({
    supervisorId,
    areaId,
    payPeriod,
    setFieldValue,
    setTouched,
    handleSubmit,
    inputs,
    onChangeCommonValues,
    userId,
    disabled = false,
    ...props
}: IAddTimeEntryFormProps) {
    const classes = useAddControlsStyles();
    const {
        projectAssignment,
        activity,
        notes,
        position,
        location,
        taskId,
        jobNumber,
        entry_date,
    } = props.values;
    const assignmentId = projectAssignment?.assignment?.id;

    useEffect(() => {
        setFieldValue('scaZone', defaultValues.scaZone);
    }, [projectAssignment?.sca_zone_id, setFieldValue]);
    const jobNumberActivities = useJobNumberActivities(EntryType.TIME, jobNumber);
    useEffect(() => {
        if (jobNumber && !jobNumberActivities?.includes(activity?.id || '')){
            setFieldValue('activity', defaultValues.activity);
        }
    }, [jobNumber, jobNumberActivities, activity, setFieldValue]);

    const isActivityVisible = isActivityFieldIsVisibleInForm(inputs, position, location, projectAssignment,
        taskId, jobNumber);

    const activityNotSelectedYet = isActivityVisible && !activity;

    const isDataFieldVisibleByOtherFields = useMemo(() => (
        [
            position && location,
            projectAssignment && taskId,
            jobNumber,
        ].find(Boolean) && entry_date && !activityNotSelectedYet
    ), [position, location, projectAssignment, taskId,
        jobNumber, entry_date, activityNotSelectedYet]);

    const quantityType = useMemo(() => {
        if (jobNumber) {
            return jobNumber.timesheet_setting === TimesheetSettings.PerFile
                ? QuantityType.FILE
                : QuantityType.TIME_IN_OUT_BREAK;
        }
        return activity?.data_type;
    }, [activity, jobNumber]);

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.controlsContainer}>
                <Box className={classes.aSide}>
                    <CommonEntryInlineFields
                        values={props.values}
                        onChangeCommonValues={onChangeCommonValues}
                        setFieldValue={setFieldValue}
                        setTouched={setTouched}
                        payPeriod={payPeriod}
                        areaId={areaId}
                        entryType={EntryType.TIME}
                        inputs={inputs}
                        supervisorId={supervisorId}
                        userId={userId}
                        disabled={disabled}
                    />

                    {isDataFieldVisibleByOtherFields && (
                        <TimeDataField
                            name="data"
                            quantityType={quantityType}
                            className={classes.timeInputField}
                            disabled={disabled}
                        />
                    )}

                    {showField(inputs, EntrySlug.Notes) && (
                        <>
                            <FormField
                                name="notes"
                                label={activityHasCompletes(activity) ? 'Case Numbers' : 'Notes..'}
                                className={classes.fixedLarge}
                                disabled={disabled}
                            />
                            {activityHasCompletes(activity) && (
                                <CompletesQuantity
                                    name="completes"
                                    label="Cmpl."
                                    className={classes.selectFieldSmall}
                                    key={notes}
                                    notes={notes}
                                />
                            )}
                        </>
                    )}

                    {projectAssignment?.sca_zone_id && (
                        <ScaZone
                            name="scaZone"
                            label="SCA Zone"
                            parentScaZoneId={projectAssignment?.sca_zone_id}
                            className={classes.fixedLarge}
                            assignmentId={assignmentId}
                            disabled={disabled}
                        />
                    )}
                </Box>
                <UpperButton
                    label={(
                        <>
                            <span>
                            + Add
                            </span>
                            <AccessTime className={classes.addIcon}/>
                        </>
                    )}
                    className={classes.upperButton}
                    disabled={disabled}
                />
            </Box>
        </form>
    );
}

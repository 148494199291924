import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import { allOfferLetterTabName, OfferLetterStatusNameMap } from 'modules/offerLetter/store/model';
import { useSelector } from 'react-redux';
import { pluralize } from 'shared/utils/formatters/pluralize';
import OfferLetterFiltersChips from 'modules/offerLetter/OfferLetterHeader/OfferLetterFiltersChips';
import OfferLetterSearch from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterSearch';
import OfferLetterFilters from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterFilters';
import { useOfferLetterHeaderStyles } from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterHeaderStyles';
import { selectOfferLetterActiveTab, selectOfferLetterHasActiveFilter } from 'modules/offerLetter/store/selectors';

interface IOfferLetterHeaderProps {
    amount?: number;
}

export default function OfferLetterHeader({ amount }: IOfferLetterHeaderProps) {
    const classes = useOfferLetterHeaderStyles();
    const hasActiveFilters = useSelector(selectOfferLetterHasActiveFilter);
    const activeTab = useSelector(selectOfferLetterActiveTab);

    let resultStatusName = '';
    if (activeTab && activeTab !== allOfferLetterTabName) {
        resultStatusName = OfferLetterStatusNameMap[activeTab];
    }

    return (
        <Box display="flex" className={classes.headerWrapper}
            justifyContent="space-between" alignItems="center"
        >
            <Hidden smDown>
                {hasActiveFilters ? (
                    <Box display="flex" alignItems="center"
                        justifyContent="space-between" flexBasis="60%"
                    >
                        <Typography variant="h4">
                            {amount} {pluralize('Result', amount || 0)}.
                        </Typography>
                        <OfferLetterFiltersChips/>
                    </Box>
                ) : (
                    <Typography variant="h4">
                        All {resultStatusName} Offer Letters {amount ? `(${amount})` : ''}
                    </Typography>
                )}
            </Hidden>
            <Box display="flex" alignItems="center">
                <OfferLetterSearch/>
                <OfferLetterFilters/>
            </Box>
        </Box>
    );
}

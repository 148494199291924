import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

interface ILabelWithValueProps extends BoxProps {
    label: string;
    value?: string;
}

export const LabelWithValue = ({ label, value, ...boxProps }: ILabelWithValueProps) => {
    return (
        <Box
            {...boxProps}
            display="flex"
            flexDirection="row"
            fontWeight="fontWeightMedium"
            mt={1}
        >
            <Box
                color="primary.main"
            >
                {label}:
            </Box>
            <Box ml={1}>{value}</Box>
        </Box>
    );
};

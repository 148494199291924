import { AccessTime, AttachMoney, DescriptionOutlined, Dashboard } from '@material-ui/icons';
import { IMenuCategory, TMenuCategoryChild } from 'shared/models/Global';
import { Permission } from 'store/components/auth/authModels';
import { FeatureSwitches } from 'utils/featureSwitches';
import { generatePath } from 'react-router-dom';
import { routes } from 'shared/routes';
import { IGetClientMenuParams } from './typings';
import { MenuItemEnum } from 'shared/models/MenuItem';

export function getClientMenu({ clientId, permissions }: IGetClientMenuParams): Array<IMenuCategory> {
    const params = { client_id: clientId };
    const isManager = permissions?.includes(Permission.ApproveSheets);

    const permittedTabs: TMenuCategoryChild[] = [
        {
            title: MenuItemEnum.Dashboard,
            Icon: Dashboard,
            to: generatePath(routes.CLIENT.DASHBOARD, params),
            feature: isManager ? FeatureSwitches.enableManagerDashboard : FeatureSwitches.enableEmployeeDashboard,
        },
        {
            title: MenuItemEnum.OfferLetter,
            Icon: DescriptionOutlined,
            to: generatePath(routes.CLIENT.OFFER_LETTER.ROOT, params),
            feature: FeatureSwitches.enableOfferLetters,
            permission: Permission.ViewClientOffers,
        },
        {
            title: MenuItemEnum.TimeApproval,
            Icon: AccessTime,
            to: generatePath(routes.CLIENT.TIME_APPROVAL.ROOT, params),
            permission: Permission.ApproveSheets,
        },
        {
            title: MenuItemEnum.ExpenseApproval,
            Icon: AttachMoney,
            to: generatePath(routes.CLIENT.EXPENSE_APPROVAL.ROOT, params),
            permission: Permission.ApproveSheets,
        },
        {
            title: MenuItemEnum.TimeExpenses,
            Icon: AccessTime,
            to: generatePath(routes.CLIENT.TIME_AND_EXPENSE.ROOT, params),
            permission: Permission.SubmitSheets,
        },
    ];

    return [
        {
            id: 'Menu',
            children: permittedTabs,
        },
    ];
}

import { selectClientActiveStatus } from 'modules/settings/submodules/clients/store/selectors';
import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import {
    defaultEditClientValues,
} from 'modules/settings/submodules/clients/components/ClientEditForm/model';
import { IClientData } from 'modules/settings/submodules/clients/store/models';
import { ValidationsSchema } from 'modules/settings/submodules/clients/components/ClientEditForm/ValidationsSchema';
import { Form } from 'modules/settings/submodules/clients/components/ClientEditForm/Form';
import { IClientInfo } from 'store/entities/clients/clientsModel';

export interface ILookupFormWrapperProps {
    onSubmit: (values: IClientData) => void;
    submitTitle: string;
    mode: string;
    isLoading: boolean;
    onCancel?: () => void;
    customClasses?: Partial<Record<'form' | 'input' | 'button' | 'formBlock' | 'formBlockTitle', string>>;
    client?: IClientInfo;
}

const FormWrapper = ({
    client, onSubmit, onCancel, submitTitle, customClasses, mode, isLoading,
}: ILookupFormWrapperProps) => {
    const activeStatus = useSelector(selectClientActiveStatus);
    const initialValues = {
        ...defaultEditClientValues,
        name: client?.name || defaultEditClientValues.name,
        employer_id: client?.employer_id || defaultEditClientValues.employer_id,
        cost_center: client?.cost_center || defaultEditClientValues.cost_center,
        status: client?.status || activeStatus || defaultEditClientValues.status,
        status_date: client?.status_date || defaultEditClientValues.status_date,
        service_type: client?.service_type || defaultEditClientValues.service_type,
        manager: client?.manager || defaultEditClientValues.manager,
        payroll_rep_user: client?.payroll_rep_user || defaultEditClientValues.payroll_rep_user,
        address: client?.address || defaultEditClientValues.address,
        address2: client?.address2 || defaultEditClientValues.address2,
        city: client?.city || defaultEditClientValues.city,
        state: client?.state || defaultEditClientValues.state,
        county: client?.county || defaultEditClientValues.county,
        zip_code: client?.zip_code || defaultEditClientValues.zip_code,
        contact_name: client?.contact_name || defaultEditClientValues.contact_name,
        contact_title: client?.contact_title || defaultEditClientValues.contact_title,
        contact_email: client?.contact_email || defaultEditClientValues.contact_email,
        contact_phone: client?.contact_phone || defaultEditClientValues.contact_phone,
        prism_corporation_type: client?.prism_corporation_type || defaultEditClientValues.prism_corporation_type,
    };

    return (
        <Formik
            key={client?.name}
            initialValues={initialValues}
            validationSchema={ValidationsSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <Form
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                    customClasses={customClasses}
                    mode={mode}
                />
            )}
        </Formik>
    );
};

export default FormWrapper;

import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { useTotalStyles } from './TotalStyles';
import clsx from 'clsx';

export enum TotalTypes {
    Time = 'Time',
    Expense = 'Expense',
    Break = 'Break',
    Count = 'Count',
}

export enum TotalColor {
    Green = 'green',
    Orange = 'orange',
    Blue = 'blue',
    Black = 'black',
}

interface ITotalHoursProps {
    type: TotalTypes;
    customClasses?: string;
    value: string;
    color?: TotalColor;
    label?: string;
    classes?: {
        value?: string;
        label?: string;
        root?: string;
    };
}

const labelByType: Record<TotalTypes, string> = {
    [TotalTypes.Time]: 'total hours',
    [TotalTypes.Expense]: 'total expenses',
    [TotalTypes.Break]: 'total breaks',
    [TotalTypes.Count]: 'total count',
};

const colorByType: Record<TotalTypes, TotalColor> = {
    [TotalTypes.Time]: TotalColor.Blue,
    [TotalTypes.Expense]: TotalColor.Green,
    [TotalTypes.Break]: TotalColor.Orange,
    [TotalTypes.Count]: TotalColor.Black,
};

const getColorClass = (type: TotalColor, baseClasses: ReturnType<typeof useTotalStyles>): string => {
    switch (type) {
        case TotalColor.Blue:
            return baseClasses.blue;
        case TotalColor.Green:
            return baseClasses.green;
        case TotalColor.Orange:
            return baseClasses.orange;
        case TotalColor.Black:
            return baseClasses.black;
    }
};

const getFormattedValue = (type: TotalTypes, value: string): string => {
    if (type === TotalTypes.Expense) {
        return formatDollars(value);
    }
    return value;
};

const Total: FC<ITotalHoursProps> = ({
    type,
    value,
    label,
    color,
    classes,
    ...props
}: ITotalHoursProps,
) => {
    const baseClasses = useTotalStyles();
    const colorClass = getColorClass(color ? color : colorByType[type], baseClasses);
    return (
        <Box className={classes?.root} {...props}>
            <Typography
                classes={{ root: clsx(baseClasses.value, colorClass, classes?.value) }}
                data-testId="total-value"
            >
                {getFormattedValue(type, value)}
            </Typography>
            <Typography classes={{ root: clsx(baseClasses.label, classes?.label) }} data-testId="total-label">
                {label || labelByType[type]}
            </Typography>
        </Box>
    );
};

export default Total;

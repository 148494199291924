import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { moment } from 'utils/momentExtensions';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectOrderedPayPeriods } from 'store/entities/timesheet/selectors';

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];

export const monthNamesShort = monthNames.map(x => {
    return x.substr(0, 3);
});

export const dayOfWeekNames = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
];

export const dayOfWeekNamesShort = [
    'Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat',
];

export const monthTextDateFormat = 'MMM D, YYYY';
export const shortDateFormat = 'MM/DD/YYYY';
export const extraShortDateFormat = 'M/DD/YY';
export const backendDateFormat = 'YYYY-MM-DD';
export const DATE_FORMAT = {
    YYYY_MM_DD: 'YYYY-MM-DD',
    MM_DD_YYYY: 'MMM DD, YYYY',
};

export function isDateInPayPeriod(payPeriod: IPayPeriod, day?: string) {
    return moment(day).isBetween(payPeriod.period_start, payPeriod.period_end, undefined, '[]');
}

export function usePayPeriod(day?: string): IPayPeriod | undefined {
    const payPeriods = useSelector(selectOrderedPayPeriods);
    return useMemo(() => {
        // Return pay period for current week or latest available
        return payPeriods.find(period => {
            return isDateInPayPeriod(period, day);
        }) || payPeriods[payPeriods.length - 1];
    }, [day, payPeriods]);
}

export function useGeneratePayPeriod(currentDayOfWeek?: string): moment.Moment[] {
    const payPeriod = usePayPeriod(currentDayOfWeek);
    return useMemo(() => {
        const payPeriodRange = moment.range(moment(payPeriod?.period_start), moment(payPeriod?.period_end));
        return [...payPeriodRange.by('day')];
    }, [payPeriod]);
}

export const getFormattedPayPeriod = (payPeriod: IPayPeriod, dateFormat = shortDateFormat): string => {
    const periodStart = moment(payPeriod.period_start);
    const periodEnd = moment(payPeriod.period_end);
    return `${periodStart.format(dateFormat)} - ${periodEnd.format(dateFormat)}`;
};

export type DateBackend = string; // TODO: implement YYYY-MM-DD

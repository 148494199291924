import { call, put, takeLatest } from 'typed-redux-saga';
import { EntryType, ISheetBackend } from 'shared/models/sheet/Sheet';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { getSheetDetails } from 'store/components/sheetDetails/sheetDetailsActions';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';

function* getSheetDetailsSaga({ payload: sheetInfo }: ReturnType<typeof getSheetDetails.init>) {
    let result: ISheetBackend | null = null;
    if (sheetInfo.entryType === EntryType.TIME) {
        const results = yield* call(
            timeApi.getSheetListByPurpose,
            sheetInfo.permission,
            { sheet_ids: [sheetInfo.sheetId] },
        );
        yield* put(loadTimeSheetsWithEntries.success(results));
        result = results[0];
    } else {
        const results = yield* call(
            expenseApi.getSheetListByPurpose,
            sheetInfo.permission,
            { sheet_ids: [sheetInfo.sheetId] },
        );
        yield* put(loadExpenseSheetsWithEntries.success(results));
        result = results[0];
    }

    yield* put(getSheetDetails.success(result));
}

function* getSheetDetailsWatcher() {
    yield* takeLatest(getSheetDetails.initType, getSheetDetailsSaga);
}

export default [
    getSheetDetailsWatcher,
];

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { CheckPermissionAndFeature, ICheckPermissionAndFeature } from 'modules/settings/common/components/CheckPermissionAndFeature/CheckPermissionAndFeature';
import { useStyles } from './styles';

export interface IDashboardLink extends ICheckPermissionAndFeature {
    title: string;
    description?: string;
    link: string;
}

export const DashboardLink = ({ title, description, link, ...checkAccessProps }: IDashboardLink) => {
    const classes = useStyles();

    return (
        <CheckPermissionAndFeature {...checkAccessProps}>
            <Grid
                item
                sm={4}
                xs={12}
            >
                <NavLink to={link} className={classes.link}>
                    <Box className={classes.block}>
                        <Typography className={classes.heading}>{title}</Typography>
                        <Typography variant="body2">{description}</Typography>
                    </Box>
                </NavLink>
            </Grid>
        </CheckPermissionAndFeature>
    );
};

import { IClientUserAssociationFilter, IPlatformUsersFilter } from 'modules/settings/submodules/platformUsers/store/models';
import { IStore } from 'store/configureStore';
import { IInfinityScrollState } from 'store/reducerUtils';
import { IClientUserAssociation } from 'store/entities/users/model';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';

export const selectPlatformUsersState = (state: IStore) => {
    return state.modules.settings.platformUsers;
};

export const selectIsCreateUserModelOpened = (state: IStore) => {
    return selectPlatformUsersState(state).createUsers.isOpenCreateUserModal;
};

export const selectIsUserCreating = (state: IStore) => {
    return selectPlatformUsersState(state).createUsers.isUserCreating;
};

export const pageSize = 30;

export const selectUsers = (state: IStore) => {
    return selectPlatformUsersState(state).usersInfo.users;
};

export const selectTotalUsers = (state: IStore) => {
    return selectPlatformUsersState(state).usersInfo.totalUsers;
};

export const selectUserTableHasMoreUsers = (state: IStore) => {
    const totalUsers = selectTotalUsers(state);
    const users = selectUsers(state);

    if (totalUsers === undefined) {
        return true; 
    }

    return users.length < totalUsers;
};

export const selectUserTableNextPageToLoad = (state: IStore) => {
    const users = selectUsers(state);
    return users.length / pageSize + 1;
};

export const selectPlatformUserFilter = (state: IStore): IPlatformUsersFilter => {
    const platformUsersState = selectPlatformUsersState(state);
    return platformUsersState.platformUserFilter;
};

export const selectIsClientUsersAssociationsCreating = (state: IStore) =>
    selectPlatformUsersState(state).clientUsersAssociations.isClientUserAssociationCreating;

export const selectClientUserAssociationsState = (state: IStore): IInfinityScrollState<IClientUserAssociation> => {
    return selectPlatformUsersState(state).clientUsersAssociations.table;
};
export const clientUserAssociationSelectors = getInfinityScrollSelectors(selectClientUserAssociationsState);
export const selectClientUserAssociationFilter = (state: IStore): IClientUserAssociationFilter => {
    return selectPlatformUsersState(state).clientUsersAssociations.filter;
};

export const selectEditPlatformUserId = (state: IStore) => {
    return selectPlatformUsersState(state).editUsers.editUserId;
};
export const selectIsPlatformUserUpdating = (state: IStore) => {
    return selectPlatformUsersState(state).editUsers.isUserUpdating;
};

export const selectIsPlatformUserStatusUpdating = (state: IStore) => {
    return selectPlatformUsersState(state).isPlatformUserStatusUpdating;
};

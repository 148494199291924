import React from 'react';
import { Button } from '@material-ui/core';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';

import { useReduxModal } from 'shared/utils/hooks/useModal';
import CreateDealModal from 'modules/settings/submodules/clients/deals/components/CreateDeal/CreateDealModal';
import { setCreateDealModalState } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/actions';
import { selectIsDealModalOpen } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/selectors';

export const CreateDealButton = () => {
    const classes = useSettingsFormModalStyles();

    const { isModalOpened,
        openModal,
        closeModal } = useReduxModal(selectIsDealModalOpen, setCreateDealModalState);

    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + ADD NEW
            </Button>
            <CreateDealModal
                title="Create New Deal"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </>
    );
};

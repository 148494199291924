import { IPositionWorkLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/model';
import { createSingleAction } from 'store/utils';

export const SET_POSITION_LOCATION_ASSOCIATION_FILTER = 'SET_POSITION_LOCATION_ASSOCIATION_FILTER';
export const setPositionWorkLocationAssociationFilter = createSingleAction<
IPositionWorkLocationAssociationFilter,
typeof SET_POSITION_LOCATION_ASSOCIATION_FILTER
>(
    SET_POSITION_LOCATION_ASSOCIATION_FILTER,
);

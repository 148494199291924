import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useGroupedSheetTableStyles = makeHighPriorityStyles((theme: Theme) => ({
    timeIcon: {
        verticalAlign: 'middle',
        fill: colors.blue,
        fontSize: 14,
        marginRight: theme.spacing(0.5),
    },
    expenseIcon: {
        verticalAlign: 'middle',
        fill: colors.green,
        fontSize: 14,
        marginRight: theme.spacing(0.5),
    },
    capturedText: {
        fontSize: 11,
        lineHeight: '11px',
    },
    employeeCell: {
        alignItems: 'flex-start !important',
        lineHeight: '12px !important',
    },
    pullRight: {
        justifyContent: 'flex-end !important',
    },
    bold: {
        fontWeight: `${theme.typography.fontWeightBold} !important` as 'bold',
    },
}));

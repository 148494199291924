import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import PrintSVG from 'shared/components/icons/PrintSVG';
import useActionButtonStyles from '../FilterAndActionControlsStyles';

interface IPrintButtonProps {
    customClasses?: string;
}

const PrintButton: FC<IPrintButtonProps> = () => {
    const classes = useActionButtonStyles();
    return (
        <Button classes={{ root: classes.actionButton }}>
            <PrintSVG/>
        </Button>
    );
};

export default PrintButton;

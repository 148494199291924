import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getActivities } from 'store/entities/configuration/configurationAction';
import { FeatureSwitches } from 'utils/featureSwitches';
import { ClientActivitiesTable } from 'modules/settings/submodules/clients/activities/components/ClientActivitiesTable/ClientActivitiesTable';
import CreateActivity from 'modules/settings/submodules/clients/activities/components/CreateActivity/CreateActivity';

export const ClientActivitiesList = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (clientId) {
            dispatch(getActivities.init());
        }
    }, [dispatch, clientId]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableClientPositionsManagement}>
            <Box mb={2}>
                <CreateActivity/>
            </Box>
            <ClientActivitiesTable/>
        </FeatureSwitch>
    );
};

import { combineReducers } from 'redux';
import {
    IEmployeeSubmittedSheets,
    ISheetDetail,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsModel';
import {
    SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS, SET_RESUBMIT_SHEET,
    setEmployeeSubmittedSheetsFilters, setResubmitSheet,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';

const initialState = {
    filters: {
        location_id: '',
        position_id: '',
    },
    editSheet: null,
};

function filters(
    state: IEmployeeSubmittedSheets = initialState.filters,
    action: ReturnType<typeof setEmployeeSubmittedSheetsFilters>,
): IEmployeeSubmittedSheets {
    switch (action.type) {
        case SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

function editSheet(
    state: ISheetDetail | null = initialState.editSheet,
    action: ReturnType<typeof setResubmitSheet>,
): ISheetDetail | null {
    switch (action.type) {
        case SET_RESUBMIT_SHEET:
            return action.payload;
        default:
            return state;
    }
}

export const employeeSubmittedSheets = combineReducers({
    filters,
    editSheet,
});

import { combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createOfferLetterTemplate,
    getOfferLetterTemplateParams,
    setCreateOfferLetterTemplateModalState,
    updateOfferLetterTemplate,
    uploadOfferLetterTemplate,
} from 'modules/settings/submodules/offerLetters/store/action';
import { ActionsReturnTypes } from 'store/utils';
import { Nullable } from '../../../../../@types/types';
import { IOfferLetterTemplateParam } from './models';

const initialState = {
    offerLetterTemplateUploadedFile: '',
    offerLetterTemplateParams: null,
};

const isOpenCreateOfferLetterTemplateModal = isOpenModalReducer(setCreateOfferLetterTemplateModalState.action);
const isOfferLetterTemplateCreating = isLoadingReducer(createOfferLetterTemplate);
const isOfferLetterTemplateUpdating = isLoadingReducer(updateOfferLetterTemplate);
const isOfferLetterTemplateUploading = isLoadingReducer(uploadOfferLetterTemplate);
const isOfferLetterTemplateParamsLoading = isLoadingReducer(getOfferLetterTemplateParams);

const offerLetterTemplateUploadedFile = (
    state: string = initialState.offerLetterTemplateUploadedFile,
    action: ActionsReturnTypes<typeof uploadOfferLetterTemplate>,
): string => {
    switch (action.type) {
        case uploadOfferLetterTemplate.successType:
            return action.payload;
        default:
            return state;
    }
};

const offerLetterTemplateParams = (
    state: Nullable<IOfferLetterTemplateParam[]> = initialState.offerLetterTemplateParams,
    action: ActionsReturnTypes<typeof getOfferLetterTemplateParams>,
): Nullable<IOfferLetterTemplateParam[]> => {
    switch (action.type) {
        case getOfferLetterTemplateParams.successType:
            return action.payload;
        default:
            return state;
    }
};

export const offerLetterTemplate = combineReducers({
    isOpenCreateOfferLetterTemplateModal,
    isOfferLetterTemplateCreating,
    isOfferLetterTemplateUpdating,
    isOfferLetterTemplateUploading,
    offerLetterTemplateUploadedFile,
    isOfferLetterTemplateParamsLoading,
    offerLetterTemplateParams,
});

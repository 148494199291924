import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { IDashboardCardData } from '../../models/Dashboard';

interface IDashboardItemContentProps extends IDashboardCardData {
    isLoading: boolean;
}

const LABEL_LIMIT = 3;

export function DashboardItemContent({
    isLoading,
    Icon,
    color,
    backgroundColor,
    count,
    title,
    label,
    labels,
}: IDashboardItemContentProps) {
    const classes = useStyles();
    return (
        <>
            {Icon && (
                <Box className={classes.Icon} style={{ backgroundColor: backgroundColor }}>
                    <Icon color={color}/>
                </Box>
            )}
            <Box className={classes.Length} p={2}
                style={{ color: color }}>
                {isLoading ? (
                    <CircularProgress color="inherit"/>
                ) : (count ? count : `-`)}
            </Box>
            <Box className={classes.Title}>{title}</Box>
            {label && <Box className={classes.Label}>{label}</Box>}
            {labels && (
                <>
                    {labels.slice(0, LABEL_LIMIT).map(item => (
                        <Box key={item} className={classes.Label}>{item}</Box>
                    ))}
                    {labels.length > LABEL_LIMIT && (
                        <Box className={classes.Label}>
                            and more
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

import React from 'react';
import { Formik } from 'formik';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { DepartmentValidationsSchema } from 'modules/settings/submodules/clients/departments/components/DepartmentForm/ValidationsSchema';
import { defaultDepartmentFormValues, IDepartmentFormValues } from 'modules/settings/submodules/clients/departments/components/DepartmentForm/model';
import { DepartmentForm } from 'modules/settings/submodules/clients/departments/components/DepartmentForm/DepartmentForm';
import { FormMode } from 'shared/models/FormMode';

export interface IDepartmentFormWrapperProps {
    onSubmit: (values: IDepartmentFormValues) => void;
    submitTitle: string;
    isLoading: boolean;
    mode: FormMode;
    onCancel?: () => void;
    department?: IDepartment;
}

const DepartmentFormWrapper = ({
    onSubmit,
    onCancel,
    mode,
    submitTitle,
    isLoading,
    department,
}: IDepartmentFormWrapperProps) => {
    const initialValues = {
        name: department?.name || defaultDepartmentFormValues.name,
        code: department?.code || defaultDepartmentFormValues.code,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={DepartmentValidationsSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <DepartmentForm
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                    mode={mode}
                />
            )}
        </Formik>
    );
};

export default DepartmentFormWrapper;

import React from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { ApplicationSettingsFormWrapper } from './ApplicationSettingsFormWrapper';

export function AppConfigurationPage() {
    return (
        <FeatureSwitch feature={FeatureSwitches.enableManagementOfAppConfiguration}>
            <ApplicationSettingsFormWrapper/>
        </FeatureSwitch>
    );
}

import baseApi from 'shared/utils/baseApi';
import { IWithClientId } from 'shared/models/Api';
import { IPayPeriod, IPayPeriodResponse } from 'store/entities/timesheet/models/PayPeriod';
import { withClientId } from 'store/utils/withClientId';

export const sheetsApi = {
    async getSheetsPayPeriods(): Promise<IPayPeriod[]> {
        const response = await baseApi
            .post<IWithClientId, IPayPeriodResponse>(
            '/sheets/coordinator/sheets/periods/query',
            withClientId({}),
        );
        return response?.data?.periods;
    },
};

import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { IClient } from 'store/entities/clients/clientsModel';
import {
    ActionsReturnTypes,
    createActions,
    createRequestActions,
    createSingleAction,
    IErrorActionPayload,
    RequestType,
} from 'store/utils';
import {
    IUpdateProfileRequest,
    IOfferLetter,
    IOfferLetterFilters,
    IUpdateOfferRequest,
    OfferLetterStatusSlug,
    IOfferLetterPagePayload,
} from 'modules/offerLetter/store/model';
import { IOfferLetterRescissionFormValues } from '../components/OfferRescission/OfferLetterRescissionContent';

const SET_OFFER_LETTER_FILTERS = 'SET_OFFER_LETTER_FILTERS';
export const setOfferLetterFilters = createSingleAction<IOfferLetterFilters, typeof SET_OFFER_LETTER_FILTERS>(
    SET_OFFER_LETTER_FILTERS,
);

const INITIAL_LOAD_OFFER_LETTERS = 'offer_letter/INITIAL_LOAD_OFFER_LETTERS';
export const initialLoadOfferLetters = createSingleAction<void, typeof INITIAL_LOAD_OFFER_LETTERS>(
    INITIAL_LOAD_OFFER_LETTERS,
);

export const getMoreOfferLetterAction = createRequestActions<void, IOfferLetterPagePayload>(
    RequestType.Get,
    'MORE_OFFER_LETTERS',
    'offer_letter',
);

export const getOfferLetters = createRequestActions<void, Array<IOfferLetter>>(
    RequestType.Get,
    'OFFER_LETTERS',
    'offer_letter',
);

export const getMyOfferLetters = createRequestActions<void, Array<IOfferLetter>>(
    RequestType.Get,
    'MY_OFFER_LETTERS',
    'offer_letter',
);

export const RESET_EMPLOYEE_LOOKUP = 'RESET_EMPLOYEE_LOOKUP';
export const resetEmployeeLookup = createSingleAction<void, typeof RESET_EMPLOYEE_LOOKUP>(RESET_EMPLOYEE_LOOKUP);

const CREATE_OFFER = 'offer/CREATE_OFFER';
const CREATE_OFFER_SUCCESS = 'offer/CREATE_OFFER_SUCCESS';
const CREATE_OFFER_ERROR = 'offer/CREATE_OFFER_ERROR';

export const createOffer = createActions<IOfferLetterFormValues, IOfferLetter, any,
typeof CREATE_OFFER, typeof CREATE_OFFER_SUCCESS, typeof CREATE_OFFER_ERROR
>(
    CREATE_OFFER,
    CREATE_OFFER_SUCCESS,
    CREATE_OFFER_ERROR,
);

export const SET_CREATE_SIDEBAR_OPENED = 'offer/SET_CREATE_SIDEBAR_OPENED';
export const setCreateSidebarOpened = createSingleAction<boolean, typeof SET_CREATE_SIDEBAR_OPENED>(
    SET_CREATE_SIDEBAR_OPENED,
);

export const CREATE_OFFER_FOR_USER = 'offer/CREATE_OFFER_FOR_USER';
export const createOfferForUser = createSingleAction<string, typeof CREATE_OFFER_FOR_USER>(
    CREATE_OFFER_FOR_USER,
);

export const SET_LOOKUP_SIDEBAR_OPEN = 'offer/SET_LOOKUP_SIDEBAR_OPEN';
export const setLookupSidebarOpen = createSingleAction<boolean, typeof SET_LOOKUP_SIDEBAR_OPEN>(
    SET_LOOKUP_SIDEBAR_OPEN,
);

const EDIT_OFFER = 'offer/EDIT_OFFER';
const EDIT_OFFER_SUCCESS = 'offer/EDIT_OFFER_SUCCESS';
const EDIT_OFFER_ERROR = 'offer/EDIT_OFFER_ERROR';
export const editOffer = createActions<
{
    id: string;
    values: IOfferLetterFormValues;
},
IOfferLetter,
any,
typeof EDIT_OFFER, typeof EDIT_OFFER_SUCCESS, typeof EDIT_OFFER_ERROR
>(
    EDIT_OFFER,
    EDIT_OFFER_SUCCESS,
    EDIT_OFFER_ERROR,
);

const RESCIND_OFFER = 'offer/RESCIND_OFFER';
const RESCIND_OFFER_SUCCESS = 'offer/RESCIND_OFFER_SUCCESS';
const RESCIND_OFFER_ERROR = 'offer/RESCIND_OFFER_ERROR';
export const rescindOffer = createActions<
{
    id: string;
    values: IOfferLetterRescissionFormValues;
},
IOfferLetter,
any,
typeof RESCIND_OFFER, typeof RESCIND_OFFER_SUCCESS, typeof RESCIND_OFFER_ERROR
>(
    RESCIND_OFFER,
    RESCIND_OFFER_SUCCESS,
    RESCIND_OFFER_ERROR,
);

export const SET_EDIT_SIDEBAR_OPENED = 'offer/SET_EDIT_SIDEBAR_OPENED';
export const setEditSidebarOpened = createSingleAction<boolean, typeof SET_EDIT_SIDEBAR_OPENED>(
    SET_EDIT_SIDEBAR_OPENED,
);

export const EDIT_OFFER_FOR_USER = 'offer/EDIT_OFFER_FOR_USER';
export const editOfferForUser = createSingleAction<string, typeof EDIT_OFFER_FOR_USER>(
    EDIT_OFFER_FOR_USER,
);

const GET_OFFER_LETTER = 'GET_OFFER_LETTER';
const GET_OFFER_LETTER_SUCCESS = 'GET_OFFER_LETTER_SUCCESS';
const GET_OFFER_LETTER_ERROR = 'GET_OFFER_LETTER_ERROR';

export const getOfferLetter = createActions<string, IOfferLetter, any,
    typeof GET_OFFER_LETTER, typeof GET_OFFER_LETTER_SUCCESS, typeof GET_OFFER_LETTER_ERROR
>(
    GET_OFFER_LETTER, GET_OFFER_LETTER_SUCCESS, GET_OFFER_LETTER_ERROR,
);

export const VIEW_OFFER_LETTER = 'VIEW_OFFER_LETTER';
export const viewOfferLetter = createSingleAction<string, typeof VIEW_OFFER_LETTER>(
    VIEW_OFFER_LETTER,
);

const GET_OWN_OFFER_LETTERS = 'GET_OWN_OFFER_LETTERS';
const GET_OWN_OFFER_LETTERS_SUCCESS = 'GET_OWN_OFFER_LETTERS_SUCCESS';
const GET_OWN_OFFER_LETTERS_ERROR = 'GET_OWN_OFFER_LETTERS_ERROR';

export const getOwnOfferLetters = createActions<string, IOfferLetter, any,
    typeof GET_OWN_OFFER_LETTERS, typeof GET_OWN_OFFER_LETTERS_SUCCESS, typeof GET_OWN_OFFER_LETTERS_ERROR
>(
    GET_OWN_OFFER_LETTERS, GET_OWN_OFFER_LETTERS_SUCCESS, GET_OWN_OFFER_LETTERS_ERROR,
);

const UPDATE_OFFER_LETTER = 'UPDATE_OFFER_LETTER';
const UPDATE_OFFER_LETTER_SUCCESS = 'UPDATE_OFFER_LETTER_SUCCESS';
const UPDATE_OFFER_LETTER_ERROR = 'UPDATE_OFFER_LETTER_ERROR';

export const updateOfferLetter = createActions<
{
    id: string;
    values: Partial<IUpdateOfferRequest>;
},
IOfferLetter,
any,
typeof UPDATE_OFFER_LETTER,
typeof UPDATE_OFFER_LETTER_SUCCESS,
typeof UPDATE_OFFER_LETTER_ERROR
>(
    UPDATE_OFFER_LETTER, UPDATE_OFFER_LETTER_SUCCESS, UPDATE_OFFER_LETTER_ERROR,
);

const UPDATE_OFFER_LETTER_STATUS = 'UPDATE_OFFER_LETTER_STATUS';
const UPDATE_OFFER_LETTER_STATUS_SUCCESS = 'UPDATE_OFFER_LETTER_STATUS_SUCCESS';
const UPDATE_OFFER_LETTER_STATUS_ERROR = 'UPDATE_OFFER_LETTER_STATUS_ERROR';

export const updateOfferLetterStatus = createActions<
{
    id: string;
    status: OfferLetterStatusSlug;
    rejectionReason?: string;
},
IOfferLetter,
any,
typeof UPDATE_OFFER_LETTER_STATUS,
typeof UPDATE_OFFER_LETTER_STATUS_SUCCESS,
typeof UPDATE_OFFER_LETTER_STATUS_ERROR
>(
    UPDATE_OFFER_LETTER_STATUS, UPDATE_OFFER_LETTER_STATUS_SUCCESS, UPDATE_OFFER_LETTER_STATUS_ERROR,
);

const CREATE_EMPLOYEE_PROFILE = 'CREATE_EMPLOYEE_PROFILE';
const CREATE_EMPLOYEE_PROFILE_SUCCESS = 'CREATE_EMPLOYEE_PROFILE_SUCCESS';
const CREATE_EMPLOYEE_PROFILE_ERROR = 'CREATE_EMPLOYEE_PROFILE_ERROR';

export const updateEmployeeProfile = createActions<
{
    clientId: string;
    profile: IUpdateProfileRequest;
},
IEmployeeProfile,
any,
typeof CREATE_EMPLOYEE_PROFILE,
typeof CREATE_EMPLOYEE_PROFILE_SUCCESS,
typeof CREATE_EMPLOYEE_PROFILE_ERROR
>(
    CREATE_EMPLOYEE_PROFILE, CREATE_EMPLOYEE_PROFILE_SUCCESS, CREATE_EMPLOYEE_PROFILE_ERROR,
);

const GET_MY_EMPLOYEE_PROFILE = 'GET_MY_EMPLOYEE_PROFILE';
const GET_MY_EMPLOYEE_PROFILE_SUCCESS = 'GET_MY_EMPLOYEE_PROFILE_SUCCESS';
const GET_MY_EMPLOYEE_PROFILE_ERROR = 'GET_MY_EMPLOYEE_PROFILE_ERROR';

export const getMyEmployeeProfile = createActions<
string,
IEmployeeProfile,
any,
typeof GET_MY_EMPLOYEE_PROFILE,
typeof GET_MY_EMPLOYEE_PROFILE_SUCCESS,
typeof GET_MY_EMPLOYEE_PROFILE_ERROR
>(
    GET_MY_EMPLOYEE_PROFILE, GET_MY_EMPLOYEE_PROFILE_SUCCESS, GET_MY_EMPLOYEE_PROFILE_ERROR,
);

const GET_EMPLOYEE_PROFILES = 'GET_EMPLOYEE_PROFILES';
const GET_EMPLOYEE_PROFILES_SUCCESS = 'GET_EMPLOYEE_PROFILES_SUCCESS';
const GET_EMPLOYEE_PROFILES_ERROR = 'GET_EMPLOYEE_PROFILES_ERROR';

export const getEmployeeProfiles = createActions<
void,
IEmployeeProfile[],
any,
typeof GET_EMPLOYEE_PROFILES,
typeof GET_EMPLOYEE_PROFILES_SUCCESS,
typeof GET_EMPLOYEE_PROFILES_ERROR
>(
    GET_EMPLOYEE_PROFILES, GET_EMPLOYEE_PROFILES_SUCCESS, GET_EMPLOYEE_PROFILES_ERROR,
);

const CHECK_EMPLOYEE_ONBOARDING_STATUS = 'CHECK_EMPLOYEE_ONBOARDING_STATUS';
const CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS = 'CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS';
const CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR = 'CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR';

export const checkEmployeeOnboardingStatus = createActions<
{
    profile: IEmployeeProfile,
    client?: IClient,
},
IEmployeeProfile,
any,
typeof CHECK_EMPLOYEE_ONBOARDING_STATUS,
typeof CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS,
typeof CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR
>(
    CHECK_EMPLOYEE_ONBOARDING_STATUS, CHECK_EMPLOYEE_ONBOARDING_STATUS_SUCCESS, CHECK_EMPLOYEE_ONBOARDING_STATUS_ERROR,
);

const GET_PRISM_SSO_LINK = 'GET_PRISM_SSO_LINK';
const GET_PRISM_SSO_LINK_SUCCESS = 'GET_PRISM_SSO_LINK_SUCCESS';
const GET_PRISM_SSO_LINK_ERROR = 'GET_PRISM_SSO_LINK_ERROR';

export const getPrismSSOLink = createActions<
string,
string,
any,
typeof GET_PRISM_SSO_LINK,
typeof GET_PRISM_SSO_LINK_SUCCESS,
typeof GET_PRISM_SSO_LINK_ERROR
>(
    GET_PRISM_SSO_LINK, GET_PRISM_SSO_LINK_SUCCESS, GET_PRISM_SSO_LINK_ERROR,
);

export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const setOnboardingStep = createSingleAction<OnboardingSteps, typeof SET_ONBOARDING_STEP>(SET_ONBOARDING_STEP);

const CHECK_OFFER_LETTER_PDF = 'offer-letter/CHECK_OFFER_LETTER_PDF';
const CHECK_OFFER_LETTER_PDF_SUCCESS = 'offer-letter/CHECK_OFFER_LETTER_PDF_SUCCESS';
const CHECK_OFFER_LETTER_PDF_ERROR = 'offer-letter/CHECK_OFFER_LETTER_PDF_ERROR';

interface ICheckOfferLetterPdf {
    id: string;
}
interface ICheckOfferLetterPdfInit extends ICheckOfferLetterPdf {
    hasGlobalToaster?: boolean;
}
interface ICheckOfferLetterError extends IErrorActionPayload {
    id: string;
}
export const checkOfferLetterPdf = createActions<
ICheckOfferLetterPdfInit,
ICheckOfferLetterPdf,
ICheckOfferLetterError,
typeof CHECK_OFFER_LETTER_PDF,
typeof CHECK_OFFER_LETTER_PDF_SUCCESS,
typeof CHECK_OFFER_LETTER_PDF_ERROR
>(
    CHECK_OFFER_LETTER_PDF, CHECK_OFFER_LETTER_PDF_SUCCESS, CHECK_OFFER_LETTER_PDF_ERROR,
);

export type OfferLetterActions =
    | ActionsReturnTypes<typeof getOfferLetters>
    | ActionsReturnTypes<typeof getMoreOfferLetterAction>
    | ActionsReturnTypes<typeof createOffer>
    | ActionsReturnTypes<typeof editOffer>
    | ActionsReturnTypes<typeof rescindOffer>
    | ActionsReturnTypes<typeof getOfferLetter>
    | ActionsReturnTypes<typeof getMyOfferLetters>
    | ActionsReturnTypes<typeof getOwnOfferLetters>
    | ActionsReturnTypes<typeof updateOfferLetter>
    | ActionsReturnTypes<typeof updateOfferLetterStatus>
    | ActionsReturnTypes<typeof updateEmployeeProfile>
    | ActionsReturnTypes<typeof getMyEmployeeProfile>
    | ActionsReturnTypes<typeof getPrismSSOLink>
    | ActionsReturnTypes<typeof checkOfferLetterPdf>
    | ActionsReturnTypes<typeof getEmployeeProfiles>
    | ActionsReturnTypes<typeof checkEmployeeOnboardingStatus>
    | ReturnType<typeof initialLoadOfferLetters>
    | ReturnType<typeof setOnboardingStep>
    | ReturnType<typeof resetEmployeeLookup>
    | ReturnType<typeof setLookupSidebarOpen>
    | ReturnType<typeof setCreateSidebarOpened>
    | ReturnType<typeof createOfferForUser>
    | ReturnType<typeof setEditSidebarOpened>
    | ReturnType<typeof editOfferForUser>
    | ReturnType<typeof viewOfferLetter>
    | ReturnType<typeof setOfferLetterFilters>;

import { createSelector } from 'reselect';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { IStore } from 'store/configureStore';
import {
    ApproversFromFields,
    AvailableDetailConfiguration,
    AvailableTableConfiguration,
    AvailableTotalConfiguration,
    IClient,
    IFieldsConfiguration,
    IPaySettings,
    ISheetTotalConfiguration,
    ITimeAndPayClientConfiguration,
    ITotalConfiguration,
    SheetTotalSlug,
    TotalSlug,
} from 'store/entities/clients/clientsModel';
import { ItemsById } from 'shared/models/ItemsById';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';

export const selectCurrentClientId = (state: IStore) => state.clients.clientId;
export const selectFieldConfiguration = (id: string) => (state: IStore) =>
    state.clients.fieldsConfigurationByClientId[id];
export const selectPaySettings = (state: IStore): IPaySettings | undefined => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return undefined;
    }
    return state.clients.paySettingsByClientId[clientId];
};
export const selectIsPaySettingsLoading = (state: IStore): boolean => {
    return state.clients.isPaySettingsLoading;
};
export const selectClientTimeAndPayConfiguration = (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return undefined;
    }
    return state.clients.timeAndPayConfigurationByClientId[clientId];
};
export const selectClientIsGovernmentContract = (state: IStore): boolean => {
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    return clientTimeAndPayConfiguration?.isGovernmentContract;
};
export const selectClientTimeTrackingActivityTypes = (state: IStore): QuantityType[] => {
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    return clientTimeAndPayConfiguration?.timeTracking?.activity_types as QuantityType[] || [];
};
export const selectConfigurationThemeById = (id: string) => (state: IStore) =>
    state.clients.configurationThemeByClientId[id];
export const selectClientLogoUrlById = (id: string) => (state: IStore) =>
    selectConfigurationThemeById(id)(state)?.theme?.logo_url;
export const selectTableConfiguration = (table: AvailableTableConfiguration) => (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return [];
    }
    return state.clients.fieldsConfigurationByClientId[clientId]?.tables[table] || [];
};
export const selectDetailConfiguration = (detail: AvailableDetailConfiguration) => (state: IStore) => {
    const defaultValue = {
        title: undefined,
        info: [],
    };
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return defaultValue;
    }
    return state.clients.fieldsConfigurationByClientId[clientId]?.detail[detail] || defaultValue;
};
export const selectTotalConfiguration = (totalType: AvailableTotalConfiguration) => (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    if (!clientId) {
        return [];
    }
    return state.clients.fieldsConfigurationByClientId[clientId]?.totals?.[totalType] || [];
};

export type ITotalConfigurationByTotalSlug = Partial<Record<TotalSlug, ITotalConfiguration>>

export const selectTotalConfigurationByTotalSlug = createSelector(
    selectTotalConfiguration(AvailableTotalConfiguration.SheetsTotal),
    (totalConfiguration: ITotalConfiguration[]) => (
        totalConfiguration.reduce<ITotalConfigurationByTotalSlug>(
            (acc, field) => {
                acc[field.slug] = field;
                return acc;
            },
            {},
        )
    ),
);

export type ISheetTotalConfigurationByTotalSlug = Partial<Record<SheetTotalSlug, ISheetTotalConfiguration>>

export const selectSheetTotalConfigurationByTotalSlug = createSelector(
    selectTotalConfiguration(AvailableTotalConfiguration.DaySheetTotal),
    (totalConfiguration: ISheetTotalConfiguration[]) => (
        totalConfiguration.reduce<ISheetTotalConfigurationByTotalSlug>(
            (acc, field) => {
                acc[field.slug] = field;
                return acc;
            },
            {},
        )
    ),
);

export const selectIsLoadingFieldsConfiguration = (state: IStore) => {
    return state.clients.isLoadingFieldsConfiguration;
};
export const selectPayrollProcessing = (state: IStore) => state.clients.isPayrollProcessing;
export const selectClientApprovalLevels = (state: IStore) => {
    const clientApproversFrom = selectClientApproversFrom(state);
    if (clientApproversFrom === ApproversFromFields.FromJobNumber){
        return 1;
    }
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    return clientTimeAndPayConfiguration?.approvalLevels ?? 1;
};
export const selectClientApproversFrom = (state: IStore): ApproversFromFields | undefined => {
    const clientTimeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    if (!clientTimeAndPayConfiguration){
        return undefined;
    }
    return clientTimeAndPayConfiguration.approversFrom;
};

export const selectAvailableClients = (state: IStore) => state.clients.clientsById;
export const selectAllClientsById = (state: IStore): ItemsById<IClient> => state.clients.allClientsById;
export const selectAllClientsSortedAlphabetically = createSelectorSortedByNameFromById(selectAllClientsById);
export const selectClientById = (clientId: string) => (state: IStore) => state.clients.clientsById[clientId];
export const selectCurrentClient = (state: IStore) => {
    const clientId = selectCurrentClientId(state);
    return state.clients.clientsById[clientId];
};

export const selectClientDisplayName = (state: IStore) =>
    selectClientById(selectCurrentClientId(state) as string)(state)?.name;
export const selectCurrentClientFieldConfiguration = createSelector(
    selectCurrentClientId,
    (state: IStore) => state.clients.fieldsConfigurationByClientId,
    (clientId, configurationsById) => {
        return configurationsById[clientId || ''];
    },
);
export const selectCurrentClientInputsConfiguration = createSelector(
    selectCurrentClientFieldConfiguration,
    (configurations: IFieldsConfiguration) => {
        return configurations.inputs;
    },
);
export const selectTimeAndPayConfigurationByClientIds = (state: IStore) =>
    state.clients.timeAndPayConfigurationByClientId;
export const selectClientTimeAndPayConfigurationByClientId = (clientId: string) =>
    (state: IStore): ITimeAndPayClientConfiguration | undefined => {
        return selectTimeAndPayConfigurationByClientIds(state)[clientId];
    };
export const selectClientHasJobNumberConfiguration = (clientId: string) => (state: IStore) => {
    return selectClientTimeAndPayConfigurationByClientId(clientId)(state)?.hasJobNumberConfiguration;
};
export const selectCurrentClientHasJobNumberConfiguration = createSelector(
    selectCurrentClientId,
    selectTimeAndPayConfigurationByClientIds,
    (clientId, configurationByIds) => {
        return configurationByIds[clientId || '']?.hasJobNumberConfiguration;
    },
);

import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { PayrollFilter } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilter';
import { MobilePayrollFooter } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/MobilePayrollFooter';
import SheetSummary from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { SheetTabTitle } from 'modules/payrollProcessorHub/components/SheetTitle/SheetTabTitle';
import { PayrollSheetTable } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTable';
import { usePayrollOverdueSheetTableCells } from 'modules/payrollProcessorHub/components/PayrollSheetTable/Cells';
import { useFilteredPayrollSheetRows } from 'modules/payrollProcessorHub/store/helpers';

export const PayrollOverdueSheetsTablePage = () => {
    const filteredSheets = useFilteredPayrollSheetRows();
    const cells = usePayrollOverdueSheetTableCells(filteredSheets);

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mb={2}
            >
                <SheetTabTitle/>
                <SheetSummary/>
            </Box>
            <Hidden xsDown>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mb={2}
                >
                    <PayrollFilter/>
                </Box>
            </Hidden>
            <PayrollSheetTable
                cells={cells}
            />
            <MobilePayrollFooter/>
        </>
    );
};

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateStatus } from 'modules/settings/submodules/platformUsers/components/UpdateStatus/UpdateStatus';
import { Status } from 'shared/models/Status';
import { selectDealStatusFilter } from 'modules/settings/submodules/clients/deals/components/StatusFilter/store/selectors';
import { setDealStatusFilter } from 'modules/settings/submodules/clients/deals/components/StatusFilter/store/actions';
import {
    useBigSelectStyles,
    useDealStatusFormControlStyles,
} from 'modules/settings/submodules/clients/deals/components/StatusFilter/styles';

export const DealsStatusFilter = () => {
    const dispatch = useDispatch();
    const selectClasses = useBigSelectStyles();
    const formControlClasses = useDealStatusFormControlStyles();
    const status = useSelector(selectDealStatusFilter);
    const handleStatusChange = useCallback((newValue: Status) => {
        dispatch(setDealStatusFilter(newValue));
    }, [dispatch]);
    return (
        <UpdateStatus
            status={status}
            label="Status"
            handleStatusChange={handleStatusChange}
            selectClasses={selectClasses}
            formControlClassName={formControlClasses.root}
        />
    );
};

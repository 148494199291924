import React from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { ThemeProvider, Typography } from '@material-ui/core';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import FormField from 'shared/components/formFields/FormField';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useUploadedFiles } from 'shared/utils/hooks/useFileUpload';
import ExpenseDataField from 'shared/components/formSpecialFields/expenseData/ExpenseDataField';
import ZipCodeField from 'shared/components/formSpecialFields/zipCode/ZipCodeField';
import { sidebarDarkSectionTheme as darkTheme } from 'shared/components/sidebars/EntryEdit/sidebarDarkSectionTheme';
import EntryFilesUploader from 'shared/components/uploader/EntryFilesUploader';
import { IWithInputFields, showField, useEffectWithSkipInitialChange } from '../utils';
import { CommonEntrySidebarFields } from './CommonEntrySidebarFields';
import { defaultValues, IExpenseEntryFormValues } from './ExpenseEntryModel';
import { ICommonEntryForm } from './interfaces';
import { EntrySlug } from 'store/entities/clients/clientsModel';

export interface IExpenseEntryFormProps
    extends FormikProps<IExpenseEntryFormValues>, ICommonEntryForm, IWithInputFields {
    entryId?: string;
}

export function ExpenseEntrySidebarForm({
    supervisorId,
    areaId,
    setFieldValue,
    setTouched,
    handleSubmit,
    sheetId,
    entryId,
    inputs,
    userId,
    ...props
}: IExpenseEntryFormProps) {
    const maxFileSize = 10;

    const classes = useEntryEditStyles();
    const sidebarStyles = useSidebarStyles();
    const { projectAssignment, activity, data } = props.values;
    const assignmentId = projectAssignment?.assignment?.id;
    const activityId = activity?.id;
    const { onAttachmentChange, onAttachmentRemove } = useUploadedFiles();

    useEffectWithSkipInitialChange(() => {
        setFieldValue('zipCode', defaultValues.zipCode);
    }, [assignmentId, setFieldValue]);

    useEffectWithSkipInitialChange(() => {
        setFieldValue('data', defaultValues.data);
        setTouched({
            'data': false,
        }, false);
    }, [activityId, setFieldValue, setTouched]);

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
        >
            <CommonEntrySidebarFields
                values={props.values}
                setFieldValue={setFieldValue}
                supervisorId={supervisorId}
                areaId={areaId}
                sheetId={sheetId}
                userId={userId}
                entryType={EntryType.EXPENSE}
                inputs={inputs}
            />

            <section className={clsx(classes.entryDataEdit, classes.entityEdit)}>
                <ThemeProvider theme={darkTheme}>
                    <Typography color="primary" variant="h6">
                        {activity?.data_type === QuantityType.ODOMETER ? 'Odometer' : 'Expense Amount' }
                    </Typography>
                    <ExpenseDataField
                        name="data"
                        label="Expense"
                        key={data?.entry_type}
                        activity={activity}
                        className={classes.expenseDataEdit}
                        inputProps={{
                            showNote: true,
                        }}
                    />
                </ThemeProvider>
            </section>

            <EntryFilesUploader
                fileTypes="image/*, application/pdf"
                maxSize ={maxFileSize * 1024 * 1024}
                customClasses={classes.entryDataEdit}
                onAttachmentChange = {onAttachmentChange}
                onAttachmentRemove = {onAttachmentRemove}
                entryId={entryId}
            />

            {showField(inputs, EntrySlug.Notes) && activity?.data_type !== QuantityType.ODOMETER && (
                <FormField
                    name="notes"
                    label={inputs.notes.placeholder}
                    multiline
                    className={classes.notesInputWrapper}
                    classes={{ multiline: classes.notesInput }}
                />
            )}

            {showField(inputs, EntrySlug.ZipCode) && activity?.show_zip_code && (
                <section className={clsx(sidebarStyles.sidebarElement, classes.zipCodeWrapper)}>
                    <ZipCodeField
                        name="zipCode"
                        label={inputs.zip_code.placeholder}
                        className={classes.zipCode}
                    />
                </section>
            )}
        </form>
    );
}

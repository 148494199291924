import { Nullable } from '../../../@types/types';
import { Permission } from 'store/components/auth/authModels';

const appcuesUrl: string = process.env.REACT_APP_APPCUES_URL as string;

interface IAppcues {
    page: () => void;
    identify: (id: string, params: object) => void;
}

class Appcues {
    private userId = '';
    private name = '';
    private email = '';
    private permissions = Array<Permission>();
    private onLoad = Array<() => void>();
    private appcuesInstance: Nullable<IAppcues> = null;

    /**
     * Initialization on Appcues service
     */
    init() {
        if (appcuesUrl) {
            const script = document.createElement('script');
            script.src = appcuesUrl;
            script.id = 'appcues-script';
            document.body.appendChild(script);
            script.onload = () => {
                this.appcuesInstance = window.Appcues;
                if (this.onLoad.length) {
                    this.onLoad.forEach(onLoad => onLoad());
                }
            };
        }
    }

    /**
     * Callback function for running appcues actions after inititalization finished
     * @param callback
     * @private
     */
    private call(callback: () => void) {
        if (this.appcuesInstance) {
            callback();
        } else {
            this.onLoad.push(callback);
        }
    }

    /**
     * Send to Appcues current user and permission
     * @private
     */
    private identify() {
        if (this.userId) {
            this.call(() => {
                const params = {
                    email: this.email,
                    displayName: this.name,
                    ...(this.permissions ? { permissions: this.permissions.join(',') } : {}),
                };
                this.appcuesInstance?.identify(this.userId, params);
            });
        }
    }

    /**
     * Authorize current user in Appcues
     * @param id
     * @param email
     * @param name
     */
    user(id: string, email: string, name: string) {
        this.userId = id;
        this.email = email;
        this.name = name;
        this.identify();
    }

    /**
     * Set permissions and set them to Appcues
     * @param permissions
     */
    setPermissions(permissions: Permission[]) {
        this.permissions = permissions;
        this.identify();
    }

    /**
     * Set current page url in Appcues (SPA-routing)
     */
    view() {
        this.call(() => {
            this.appcuesInstance?.page();
        });
    }
}

export default new Appcues();

import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import {
    selectIsCreateWorkingConditionsTemplateModalOpened,
    selectIsWorkingConditionsTemplateCreating,
} from 'modules/settings/submodules/clients/workingConditions/store/selectors';
import {
    createWorkingConditionsTemplate,
    setCreateWorkingConditionsTemplateModalState,
} from 'modules/settings/submodules/clients/workingConditions/store/actions';

import TemplateFormWrapper
    from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/TemplateFormWrapper';
import { ITemplateFormValues } from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';

export default function CreateWorkingConditionsModal() {
    const dispatch = useDispatch();
    const classes = useSettingsFormModalStyles();

    const isLoading = useSelector(selectIsWorkingConditionsTemplateCreating);
    const onSave = useCallback((values: ITemplateFormValues) => {
        dispatch(createWorkingConditionsTemplate.init(values));
    }, [dispatch]);
    const isOpen = useSelector(selectIsCreateWorkingConditionsTemplateModalOpened);
    const onClose = useCallback(() => {
        dispatch(setCreateWorkingConditionsTemplateModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Add New Working Conditions Template"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <TemplateFormWrapper
                submitTitle="Add template"
                templateLabel="Working Conditions"
                onSubmit={onSave}
                isLoading={isLoading}
                onCancel={onClose}
            />
        </ModalDialog>
    );
}

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'shared/models/User';
import { setPlatformUserStatus } from 'modules/settings/submodules/platformUsers/store/actions';
import { UpdateStatus } from 'modules/settings/submodules/platformUsers/components/UpdateStatus/UpdateStatus';
import { selectIsPlatformUserStatusUpdating } from 'modules/settings/submodules/platformUsers/store/selectors';

interface IUpdateUserStatus {
    id: string;
    status: Status;
    className?: string;
}

export const UpdateUserStatus = ({ id, status, className }: IUpdateUserStatus) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsPlatformUserStatusUpdating);
    const handleStatusChange = useCallback((newValue: Status) => {
        dispatch(setPlatformUserStatus.init({
            id,
            status: newValue,
        }));
    }, [dispatch, id]);
    return (
        <UpdateStatus
            status={status}
            handleStatusChange={handleStatusChange}
            isLoading={isLoading}
            formControlClassName={className}
        />
    );
};

import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';

export const selectStatesByIds = (store: IStore) => store.scaZone.statesById;
export const selectStateCodes = createSelector(
    selectStatesByIds,
    statesByIds => {
        return Object.values(statesByIds).map(state => state.state_shortname);
    },
);

import moment from 'moment';
import { padStart } from 'lodash';

export function formatMinutes(minutesTotal: number): string {
    const duration = moment.duration(minutesTotal, 'minutes');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes() + (duration.seconds() >= 30 ? 1 : 0);
    return `${hours}:${padStart(minutes.toString(), 2, '0')}`;
}

export function formatHours(hoursTotal: number): string {
    const duration = moment.duration(hoursTotal, 'hours');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes() + (duration.seconds() >= 30 ? 1 : 0);
    return `${hours}:${padStart(minutes.toString(), 2, '0')}`;
}

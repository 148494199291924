import {
    IOfferLetter,
    OfferLetterRejectionReason,
    OfferLetterRescissionReason,
    rejectReasons,
    RescissionReasons,
} from 'modules/offerLetter/store/model';
import { useRouteMatch } from 'react-router-dom';
import { userFullName } from 'shared/utils/converters/user';
import { useSelector } from 'react-redux';
import { selectOfferLetterPdfIsAvailable, selectOfferLetterPdfIsLoading } from './selectors';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { routes } from 'shared/routes';
import pickBy from 'lodash/pickBy';
import union from 'lodash/union';

export const getRejectReasonTitle = (reasonValue?: OfferLetterRejectionReason | null) => {
    const reason = rejectReasons.find(item => item.value === reasonValue);
    return reason ? reason.label : '';
};

export const getRescissionReasonTitle = (offerLetter: IOfferLetter) => {
    if (offerLetter.rescission_reason === OfferLetterRescissionReason.Other) {
        return offerLetter.rescission_reason_custom_text;
    }
    const reason = RescissionReasons.find(item => item.value === offerLetter.rescission_reason);
    return reason ? reason.label : '';
};

export const getOfferLabel = (offerLetter: IOfferLetter): string => userFullName(offerLetter) || offerLetter.email;

export const useNeedToBeOpenPdf = (openPdf: (id: string) => void) => {
    const isDetailPage = useRouteMatch({
        path: routes.EMPLOYEE_OFFER_LETTER.DETAIL,
        exact: false,
    });
    const isPdfAvailableById = useSelector(selectOfferLetterPdfIsAvailable);
    const isPdfLoading = useSelector(selectOfferLetterPdfIsLoading);
    const [needToBeOpened, setNeedToBeOpened] = useState<string[]>([]);

    useEffect(() => {
        if (!isDetailPage) {
            setNeedToBeOpened(union(Object.keys(pickBy(isPdfLoading, isLoading => isLoading)), needToBeOpened));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPdfLoading, isDetailPage]);

    useEffect(() => {
        const readyToOpen = needToBeOpened.filter(id => isPdfAvailableById[id]);
        readyToOpen.forEach(id => openPdf(id));
        if (readyToOpen.length && !isDetailPage) {
            // Update needToBeOpened list
            setNeedToBeOpened(needToBeOpened.filter(id => !isPdfAvailableById[id]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPdf, isPdfAvailableById, isDetailPage]);
};

export const openOfferLetterPdf = (id: string) => {
    window.open(generatePath(routes.OFFER_PDF_ONLY_VIEW, { id }), '_blank');
};

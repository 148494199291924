import React from 'react';
import { useSelector } from 'react-redux';
import FormAutocomplete from 'shared/components/autocomplete/FormAutocomplete';
import { ILocation } from 'shared/models/Location';
import { IFormFieldProps } from '../formFields/models';
import {
    selectLocations,
} from 'store/entities/configuration/configurationSelectors';

interface ILocationProps extends IFormFieldProps {
    positionId?: string;
}

export const LocationAutocomplete = ({ positionId, ...props }: ILocationProps) => {
    let locations = useSelector(selectLocations);
    if (positionId) {
        locations = locations.filter(
            location => location.positions?.includes(positionId),
        );
    }

    return (
        <FormAutocomplete
            getText={(location: ILocation) => location.name}
            options={locations}
            {...props}
        />
    );
};

import { combineReducers, DeepPartial } from 'redux';
import { LOGOUT } from 'store/components/auth/authActions';
import { setClientId } from 'store/entities/clients/clientsAction';
import { menuReducer } from './entities/menu/reducers/menu';
import { appConfig } from 'store/entities/appConfig/reducer';
import { sheets } from 'store/entities/timesheet/reducers';
import { scaZone } from 'store/entities/scaZone/reducer';
import { zipCodes } from 'store/entities/zipCode/reducer';
import { auth } from 'store/components/auth/authReducer';
import { components } from 'store/components/reducers';
import { users } from 'store/entities/users/reducers';
import { sheetEntryAttachments } from './entities/timesheet/reducers/sheetEntryAttachments';
import { configuration } from 'store/entities/configuration/configurationReducer';
import { clients } from 'store/entities/clients/clientsReducer';
import { offerLetter } from 'modules/offerLetter/store/reducer';
import { notificationCenter } from 'modules/notificationCenter/store/reducer';
import { payrollProcessorHub } from 'modules/payrollProcessorHub/store/reducer';
import { home } from 'modules/home/store/reducers';
import { profile } from 'modules/profile/store/profileReducer';
import { settings } from '../modules/settings/store/reducer';
import { employment } from '../modules/employmentInfo/store/reducer';
import { timeAndExpense } from 'modules/timeAndExpense/store/reducers';

const appReducer = combineReducers({
    auth,
    configuration,
    clients,
    menu: combineReducers({
        menuState: menuReducer,
    }),
    sheets,
    users,
    scaZone,
    zipCodes,
    appConfig,
    components,
    sheetEntryAttachments,
    modules: combineReducers({
        offerLetter,
        settings,
        notificationCenter,
        payrollProcessorHub,
        profile,
        home,
        employment,
        timeAndExpense,
    }),
});

const rootReducer = (state: DeepPartial<ReturnType<typeof appReducer>> | undefined, action: any) => {
    if (action.type === LOGOUT) {
        state = {
            appConfig: {
                toastState: state?.appConfig?.toastState,
            },
        };
    }

    if (action.type === setClientId.action) {
        // Reset tenant specific state
        state = {
            auth: state?.auth,
            menu: state?.menu,
            appConfig: state?.appConfig,
            clients: state?.clients,
            modules: {
                payrollProcessorHub: state?.modules?.payrollProcessorHub,
                settings: {
                    ...state?.modules?.settings,
                    clientsSettings: {
                        ...state?.modules?.settings?.clientsSettings,
                        clients: undefined,
                    },
                },
                profile: state?.modules?.profile,
            },
        };
    }

    return appReducer(state, action);
};

export default rootReducer;

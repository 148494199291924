import React, { useEffect } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import EditSheetAfterSubmit from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EditSheetAfterSubmit';
import { setHeaderContent } from 'modules/home/header/Header';
import useSheetsInProgressStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import TimeEntryTabs from 'modules/clients/header/TimeEntryTabs';
import { useDispatch, useSelector } from 'react-redux';
import SubmitStatus from 'shared/components/submitStatus/SubmitStatus';
import { DATE_FORMAT } from 'shared/models/Dates';
import { EntryType, ISheetStatusesId } from 'shared/models/sheet/Sheet';
import { setResubmitSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectExpenseStatusIdByName, selectSheet, selectTimeStatusIdByName } from 'store/entities/timesheet/selectors';

interface ISheetsRejectedViewProps {
    sheetId: string;
    entryType: EntryType;
}

export default function SheetRejectedView({ sheetId, entryType }: ISheetsRejectedViewProps) {
    const classes = useSheetsInProgressStyles();
    const dispatch = useDispatch();

    const sheet = useSelector(selectSheet(sheetId));

    const submitStatusDue = moment(sheet?.period_end).clone().add(1, 'day')
        .format(DATE_FORMAT.MM_DD_YYYY);
    const timeStatusId = useSelector(selectTimeStatusIdByName(StatusNames.REJECTED));
    const expenseStatusId = useSelector(selectExpenseStatusIdByName(StatusNames.REJECTED));

    const statusesId: ISheetStatusesId = {
        timeStatusId,
        expenseStatusId,
    };

    useEffect(() => {
        setHeaderContent(
            <Box
                display="flex"
                alignItems="flex-end"
                ml={2}
                mr={2}
                className={classes.headerContentWrapper}
            >
                <SubmitStatus
                    customClasses={classes.statusWrapper}
                    labelClass={classes.recalledSubmit}
                    dueDate={submitStatusDue}
                    status={StatusNames.REJECTED}
                />
                <TimeEntryTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [submitStatusDue, classes]);

    const onClose = () => {
        dispatch(setResubmitSheet(null));
    };

    return (
        <EditSheetAfterSubmit
            sheetId={sheetId}
            entryType={entryType}
            onClose={onClose}
            currentSheetStatusesId={statusesId}
        />
    );
}

import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { Box, Button, CircularProgress, FormHelperText } from '@material-ui/core';
import { IRegistrationFormValues } from 'shared/components/auth/forms/models';
import { useStyles } from 'shared/components/auth/AuthStyles';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import FormField from 'shared/components/formFields/FormField';
import PasswordField from 'shared/components/formSpecialFields/password/PasswordField';
import { useDispatch } from 'react-redux';
import { cacheAuthEmail } from '../../../../store/components/auth/authActions';

export interface IRegistrationFormProps extends FormikProps<IRegistrationFormValues> {
    isLoading?: boolean;
    isError?: boolean;
    userMessage?: string;
}

const termsAndConditionsLink = '/assets/termsandconditions.pdf';

export function RegistrationForm({ isLoading, isError, userMessage, handleSubmit, values }: IRegistrationFormProps) {
    const classes = useStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        if (values.email) {
            dispatch(cacheAuthEmail(values.email));
        }
    }, [dispatch, values.email]);

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.form}
            autoComplete="off"
        >
            <FormField
                name="email"
                label="Email"
                className={classes.input}
            />

            <Box display="flex" flexDirection="row">
                <Box width="50%" mr={1}>
                    <FormField
                        name="firstName"
                        label="First Name"
                        className={classes.input}
                    />
                </Box>
                <Box width="50%" ml={1}>
                    <FormField
                        name="lastName"
                        label="Last Name"
                        className={classes.input}
                    />
                </Box>
            </Box>

            <FormField
                name="username"
                label="Username"
                className={classes.input}
            />

            <PasswordField
                name="password"
                label="Password"
                autoComplete="new-password"
                className={classes.input}
                helperText="Passwords must be at least 8 characters and include an upper case, number and special character."
                showStrengthIndicator
            />

            <PasswordField
                name="passwordConfirmation"
                label="Confirm Password"
                className={classes.input}
            />

            <CheckboxField
                name="termsAnsConditionsAgreement"
                className={clsx(classes.input, classes.agreement)}
            >
                I agree to Headway Workforce Solutions
                {' '}
                <a
                    href={termsAndConditionsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Terms & Conditions
                </a>.
            </CheckboxField>

            <Box
                mt={2}
                mb={2}
                display="flex"
                justifyContent="center"
            >
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                    className={classes.submit}
                >
                    Register
                    {isLoading && (
                        <Box ml={1} mb={-0.5}>
                            <CircularProgress color="inherit" size={16}/>
                        </Box>
                    )}
                </Button>
            </Box>
            {userMessage && (
                <FormHelperText className={classes.userMessage} error={isError}>{userMessage}</FormHelperText>
            )}
        </form>
    );
}

import * as qs from 'query-string';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Paper, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { registrationInitialValues, IRegistrationFormValues } from 'shared/components/auth/forms/models';
import AuthWrapper from 'shared/components/auth/AuthWrapper';
import { RegistrationForm } from 'shared/components/auth/forms/RegistrationForm';
import { registrationValidationSchema } from 'shared/components/auth/forms/registrationValidationSchema';
import { routes } from 'shared/routes';
import { getSignupUserInfo, resetUserRegistration, userRegistration } from 'store/components/registration/actions';
import {
    selectRegistrationIsError,
    selectRegistrationIsLoading, selectRegistrationUserInfo,
    selectRegistrationUserMessage,
} from 'store/components/registration/selectors';
import { useStyles } from './AuthStyles';
import { AuthFooter } from './components/AuthFooter';
import { selectAuthEmail } from '../../../store/components/auth/selectors';
import { Logo } from './components/Logo';
import { Maintaince } from '../alerts/Maintaince';

const Registration = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();

    const isLoading = useSelector(selectRegistrationIsLoading);
    const isError = useSelector(selectRegistrationIsError);
    const userMessage = useSelector(selectRegistrationUserMessage);
    const userInfo = useSelector(selectRegistrationUserInfo);
    const userEmail = useSelector(selectAuthEmail);

    const searchParams = qs.parse(location.search);
    const token = searchParams.token as string;

    useEffect(() => {
        if (token) {
            dispatch(getSignupUserInfo.init(token));
        }
    }, [dispatch, token]);

    useEffect(() => {
        return () => {
            dispatch(resetUserRegistration());
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onRegister = (values: IRegistrationFormValues) => {
        dispatch(userRegistration.init({
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            username: values.username,
            password: values.password,
        }));
    };

    return (
        <AuthWrapper>
            <Maintaince/>
            <Paper className={classes.block}>
                <Box className={classes.logoContainer}>
                    <Logo/>
                </Box>
                <Formik
                    key={userInfo?.email}
                    initialValues={{
                        ...registrationInitialValues,
                        firstName: userInfo?.first_name || registrationInitialValues.firstName,
                        lastName: userInfo?.last_name || registrationInitialValues.lastName,
                        email: userInfo?.email || userEmail || registrationInitialValues.email,
                    }}
                    validationSchema={registrationValidationSchema}
                    onSubmit={onRegister}
                    validateOnBlur={false}
                >
                    {props => (
                        <RegistrationForm
                            {...props}
                            isLoading={isLoading}
                            isError={isError}
                            userMessage={userMessage}
                        />
                    )}
                </Formik>
            </Paper>
            <AuthFooter label="Have an account already?" route={routes.AUTH.REGISTRATION}/>
        </AuthWrapper>
    );
};

export default Registration;

import React, { useContext, useEffect } from 'react';
import { Hidden } from '@material-ui/core';
import useAddControlsStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import { useSelector } from 'react-redux';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IgnoredAreasByType } from 'shared/utils/context/ignoredAreas';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';
import { ProjectWithAssignment } from 'shared/components/selects/ProjectWithAssignment';
import DaySelect from 'shared/components/selects/day/DaySelect';
import { Task } from 'shared/components/selects/Task';
import { selectDefaultDepartment } from 'modules/employmentInfo/store/department/selectors';
import { normalizeCommonValuesForState, useDayUpdateOnChangePayPeriod } from 'shared/utils/helpers/entries';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { defaultValues, ICommonEntryFormValues } from './EntryCommonFields';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { PositionByAssignments } from 'shared/components/selects/PositionByAssignments';
import { LocationByAssignments } from 'shared/components/selects/LocationByAssignments';
import { DepartmentsByAssignmentSelect } from 'modules/timeAndExpense/components/Selects/DepartmentsByAssignmentSelect';
import { JobNumbers, useJobNumbersForUser } from 'shared/components/selects/JobNumbers';
import { FeatureSwitches } from 'utils/featureSwitches';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { showField } from '../utils';
import { selectCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import {
    isActivityFieldIsVisibleInForm,
    useFilteredActivities,
} from './helpers/commonEntryFormHelpers';
import { FormikTouched } from 'formik/dist/types';
import { IExpenseEntryFormValues } from 'shared/components/forms/entries/ExpenseEntryModel';
import { ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { focusNextField } from 'shared/utils/helpers/focusNextField';

export interface IBaseCommonEntryFieldsProps {
    supervisorId?: string;
    userId?: string;
}

export interface ICommonEntryFieldsProps extends IBaseCommonEntryFieldsProps {
    payPeriod: IPayPeriod;
    areaId?: number;
    setFieldValue: (fieldName: string, value: any) => void;
    setTouched: (touched: FormikTouched<IExpenseEntryFormValues | ITimeEntryFormValues>,
        shouldValidate?: boolean) => void;
    values: ICommonEntryFormValues;
    onChangeCommonValues: (values: ICommonEntryFormValues) => void;
    entryType: EntryType;
    inputs: InputFields;
    disabled?: boolean;
}

const selectorsPanelFields: (keyof ICommonEntryFormValues)[] = [
    'position',
    'location',
    'jobNumber',
    'projectAssignment',
    'taskId',
];

export function CommonEntryInlineFields({
    supervisorId,
    areaId,
    payPeriod,
    setFieldValue,
    setTouched,
    values,
    onChangeCommonValues,
    entryType,
    inputs,
    userId,
    disabled = false,
}: ICommonEntryFieldsProps) {
    const classes = useAddControlsStyles();
    const {
        projectAssignment,
        taskId,
        position,
        location,
        department,
        jobNumber,
        activity,
    } = values;
    const assignmentId = projectAssignment?.assignment?.id;
    const projectId = projectAssignment?.project_id;

    const commonEntryStoreValues = useSelector(selectCommonEntryFormValues);
    useEffect(() => {
        selectorsPanelFields.forEach(field => {
            if (!commonEntryStoreValues[field]) {
                // it shouldn't call useEffect on values change
                // eslint-disable-next-line
                if (values[field]){
                    setFieldValue(field, commonEntryStoreValues[field]);
                }
            }
        });
    }, [commonEntryStoreValues, setFieldValue]);

    useEffect(() => {
        setFieldValue('taskId', defaultValues.taskId);
    }, [assignmentId, setFieldValue]);

    const activityId = activity?.id;
    useEffect(() => {
        setFieldValue('data', null);
        setTouched({
            'data': false,
        }, false);
    }, [activityId, setFieldValue, setTouched]);

    const defaultDepartment = useSelector(selectDefaultDepartment(userId));
    useEffect(() => {
        if (!department && defaultDepartment) {
            setFieldValue('department', defaultDepartment);
        }
    }, [setFieldValue, defaultDepartment, department]);

    useEffect(() => {
        onChangeCommonValues(normalizeCommonValuesForState(values));
    }, [onChangeCommonValues, values]);

    useDayUpdateOnChangePayPeriod(payPeriod, onChangeCommonValues);

    const ignoredAreasByType = useContext(IgnoredAreasByType);
    const unavailableAreasIds = ignoredAreasByType ? ignoredAreasByType[entryType] : [];
    const useTask = showField(inputs, EntrySlug.Task);
    const isActivityVisible = isActivityFieldIsVisibleInForm(inputs, position, location, projectAssignment,
        taskId, jobNumber);

    const jobNumbers = useJobNumbersForUser(userId, payPeriod);
    const filteredActivities = useFilteredActivities(
        useTask ? taskId : undefined,
        entryType,
        useTask,
        jobNumber,
    );

    useEffect(() => {
        if (jobNumbers.length === 1){
            const single = jobNumbers[0];
            setFieldValue('jobNumber', single);
        }
    }, [jobNumbers, setFieldValue]);

    useEffect(() => {
        if (jobNumber && !jobNumbers.some(jn => jn.id === jobNumber?.id)){
            setFieldValue('jobNumber', null);
        }
    }, [jobNumbers, jobNumber, setFieldValue]);

    useEffect(() => {
        if (filteredActivities.length === 1){
            const single = filteredActivities[0];
            setFieldValue('activity', single);
        }
    }, [filteredActivities, setFieldValue]);

    const jobNumberInSelectorsPanel = commonEntryStoreValues.jobNumber;
    useEffect(() => {
        if ( jobNumberInSelectorsPanel ){
            focusNextField();
        }
    }, [jobNumberInSelectorsPanel]);

    const positionInSelectorsPanel = commonEntryStoreValues.position;
    useEffect(() => {
        if ( positionInSelectorsPanel ){
            focusNextField();
        }
    }, [positionInSelectorsPanel]);

    const locationInSelectorsPanel = commonEntryStoreValues.location;
    useEffect(() => {
        if ( locationInSelectorsPanel ){
            focusNextField();
        }
    }, [locationInSelectorsPanel]);

    return (
        <>
            {showField(inputs, EntrySlug.Location) && (
                <LocationByAssignments
                    name="location"
                    label={inputs.location.placeholder}
                    className={commonEntryStoreValues.location ? classes.notDisplayed : classes.flexItem}
                    positionId={position?.id}
                    userId={userId}
                    disabled={disabled}
                />
            )}

            {showField(inputs, EntrySlug.Position) && (
                <PositionByAssignments
                    name="position"
                    label={inputs.position.placeholder}
                    className={commonEntryStoreValues.position ? classes.notDisplayed : classes.flexItem}
                    userId={userId}
                    disabled={disabled}
                />
            )}

            {showField(inputs, EntrySlug.JobNumber) && (
                <FeatureSwitch feature={FeatureSwitches.enableGenworthSheets}>
                    <JobNumbers
                        name="jobNumber"
                        label={inputs[EntrySlug.JobNumber].placeholder}
                        className={commonEntryStoreValues.jobNumber ? classes.notDisplayed : classes.flexItem}
                        disabled={disabled}
                        userId={userId}
                        payPeriod={payPeriod}
                    />
                </FeatureSwitch>
            )}

            {showField(inputs, EntrySlug.Assignment) && (
                <ProjectWithAssignment
                    name="projectAssignment"
                    label={inputs.assignment_project.placeholder}
                    className={commonEntryStoreValues.projectAssignment ? classes.notDisplayed : classes.flexItem}
                    areaId={areaId}
                    supervisorId={supervisorId}
                    unavailableAreasIds={unavailableAreasIds}
                    disabled={disabled}
                />
            )}
            {useTask && (
                <Task
                    name="taskId"
                    label={inputs.task.placeholder}
                    className={commonEntryStoreValues.taskId ? classes.notDisplayed : classes.flexItem}
                    assignmentId={assignmentId}
                    projectId={projectId}
                    disabled={disabled || !assignmentId}
                    title={!assignmentId ? 'Select Proj + Assignment first' : ''}
                    useIdValue
                />
            )}

            {showField(inputs, EntrySlug.Department) && (
                <DepartmentsByAssignmentSelect
                    name="department"
                    label={inputs.department.placeholder}
                    className={classes.flexItem}
                    userId={userId}
                />
            )}

            {showField(inputs, EntrySlug.Day) && (
                <Hidden xsDown>
                    <DaySelect
                        name="entry_date"
                        label={inputs.day.placeholder}
                        className={classes.fixedMedium}
                        payPeriod={payPeriod}
                        jobNumber={jobNumber}
                        disabled={disabled}
                    />
                </Hidden>
            )}
            {isActivityVisible && (
                <ActivitySelect
                    name="activity"
                    label={inputs.activity.placeholder}
                    disabled={disabled || (useTask && !taskId)}
                    withAssignment={useTask}
                    taskId={useTask ? taskId : undefined}
                    jobNumber={jobNumber}
                    className={classes.fixedMedium}
                    entryType={entryType}
                    title={useTask && !assignmentId ? 'Select task first' : ''}
                />
            )}
        </>
    );
}

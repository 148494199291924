import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import { selectUsersById } from 'store/entities/users/selectors';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IUserInfo } from 'shared/models/User';
import { userFullName } from 'shared/utils/converters/user';

interface IUserProps extends IFormFieldProps, IFormSelect {}

export const User = (props: IUserProps) => {
    const usersById = useSelector(selectUsersById);
    const ownUserId = useSelector(selectCurrentUser)?.id ?? '';
    const users = Object.values(usersById).filter(user => user.identity_id !== ownUserId);

    return (
        <FormSelect
            getKey={(user: IUserInfo) => user.identity_id}
            getText={(user: IUserInfo) => userFullName(user)}
            options={users}
            {...props}
        />
    );
};

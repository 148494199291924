import React from 'react';
import { useSheetsData } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import { useSelector } from 'react-redux';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectActiveGridStatus } from 'store/entities/appConfig/appConfigSelectors';
import { useOpenSheetDetail } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/useOpenSheetDetail';

export default function SheetsInfoPage() {
    const activeStatus = useSelector(selectActiveGridStatus);
    const showStatus = activeStatus === StatusNames.ALL;
    const { timeRows, expenseRows, isLoading } = useSheetsData();
    const handleSheetClick = useOpenSheetDetail();

    return (
        <>
            <SheetsSubmittedView showStatus={showStatus}
                timeRows={timeRows} expenseRows={expenseRows}
                onDetailsClick={handleSheetClick}
                isLoading={isLoading}
            />
        </>
    );
}

import { ActionsReturnTypes, createActions } from 'store/utils';
import { ISheetBackend, ISheetClickInfo } from 'shared/models/sheet/Sheet';

const GET_SHEET_DETAILS = 'GET_SHEET_DETAILS';
const GET_SHEET_DETAILS_SUCCESS = 'GET_SHEET_DETAILS_SUCCESS';
const GET_SHEET_DETAILS_ERROR = 'GET_SHEET_DETAILS_ERROR';

export const getSheetDetails = createActions<ISheetClickInfo, ISheetBackend, any,
    typeof GET_SHEET_DETAILS, typeof GET_SHEET_DETAILS_SUCCESS, typeof GET_SHEET_DETAILS_ERROR
>(
    GET_SHEET_DETAILS, GET_SHEET_DETAILS_SUCCESS, GET_SHEET_DETAILS_ERROR,
);

export type GetSheetDetailsAction = ActionsReturnTypes<typeof getSheetDetails>;

import { isLoadingReducer } from 'store/reducerUtils';
import {
    ExpensesApprovalActions,
    getExpenseReviewerSheets,
} from 'store/components/expensesApproval/expensesApprovalActions';
import { combineReducers } from 'redux';

const initialState = {
    isLoaded: false,
};

const isLoading = isLoadingReducer(getExpenseReviewerSheets);

function isLoaded(state = initialState.isLoaded, action: ExpensesApprovalActions): boolean {
    switch (action.type) {
        case getExpenseReviewerSheets.successType:
            return true;
        case getExpenseReviewerSheets.initType:
            return false;
        default:
            return state;
    }
}

export const expensesApproval = combineReducers({
    isLoading,
    isLoaded,
});

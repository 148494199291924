import { ActionCreatorKnownArgs, IActionsCreatorCommon } from 'store/utils';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeLatest } from 'typed-redux-saga';

export interface ITypedRequestActionsCreatorCommon<EntityType, RequestType> extends IActionsCreatorCommon {
    init: ActionCreatorKnownArgs<RequestType, any>;
    success: ActionCreatorKnownArgs<EntityType[], any>;
}

export function getLoadEntitiesByRequestSagaWatcher<EntityType, ActionRequestPayload>(
    action: ITypedRequestActionsCreatorCommon<EntityType, ActionRequestPayload>,
    api: (request: ActionRequestPayload) => Promise<EntityType[]>,
    entityName: string,
) {
    function* getEntitiesSaga({ payload }: ReturnType<typeof action.init>) {
        const items = yield* call(api, payload || {});
        yield put(action.success(items));
    }

    return function* getEntitiesWatcher() {
        yield takeLatest(
            action.initType,
            withBackendErrorHandler(
                getEntitiesSaga,
                action.error,
                `Unable to fetch ${entityName}`,
            ),
        );
    };
}

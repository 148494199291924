import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';

import DealFormWrapper from 'modules/settings/submodules/clients/deals/components/DealForm/DealFormWrapper';
import { IDealFormValues } from 'modules/settings/submodules/clients/deals/components/DealForm/model';
import { createDeal } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDealCreating } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/selectors';
import { ICreateDealPayload } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/models';

interface ICreateDealModalProps extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
}

export default function CreateDealModal({
    title, isOpened, onClose, ...modalProps
}: ICreateDealModalProps) {
    const dispatch = useDispatch();
    const onSave = useCallback((values: IDealFormValues) => {
        const { deal_type_id, continueJobNumberCreation, ...valuesCleared } = values;
        const payload = {
            ...valuesCleared,
            type_id: deal_type_id,
            continueJobNumberCreation,
        } as ICreateDealPayload;
        dispatch(createDeal.init(payload));
    }, [dispatch]);

    const isLoading = useSelector(selectIsDealCreating);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <DealFormWrapper
                    onSubmit={onSave}
                    onCancel={onClose}
                    submitTitle="Create"
                    submitAndGoToJobNumberTitle="Create & Add Job Number"
                    isLoading={isLoading}
                />
            </Box>
        </ModalDialog>
    );
}

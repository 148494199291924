import React from 'react';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { IFormFieldProps } from 'shared/components/formFields/models';
import TimeField from './TimeField';
import InOutTimeField from './InOutTimeField';
import useAddControlsStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import { TimeFileField } from './TimeFileField';

interface ITimeDataField extends IFormFieldProps {
    quantityType?: QuantityType | null;
    inputProps?: any;
}

export default function TimeDataField({
    quantityType,
    inputProps,
    ...props
}: ITimeDataField) {
    const classes = useAddControlsStyles();

    switch (quantityType) {
        case QuantityType.TIME_BREAK:
        case QuantityType.TIME_IN_OUT:
            return (
                <InOutTimeField
                    {...inputProps}
                    {...props}
                    classes={{ inputs: classes.timeInputField }}
                    entryType={quantityType}
                />
            );
        case QuantityType.TIME_IN_OUT_BREAK:
            return (
                <>
                    <InOutTimeField
                        {...inputProps}
                        {...props}
                        classes={{ inputs: classes.timeInputField }}
                        entryType={quantityType}
                    />
                    <TimeField
                        {...inputProps}
                        {...props}
                        label="Break hrs"
                        minutesField="minutesBreak"
                        hoursField="hoursBreak"
                        entryType={QuantityType.TIME_IN_OUT_BREAK}
                        hideError
                    />
                </>
            );
        case QuantityType.FILE:
            return (
                <TimeFileField
                    {...inputProps}
                    {...props}
                    label="Total files"
                />
            );
        default:
            return (
                <TimeField
                    {...inputProps}
                    {...props}
                    label="h:mm"
                />
            );
    }
}

import { getOfferLetterTemplates } from 'modules/offerLetter/store/templates/actions';
import {
    createOfferLetterTemplate,
    getOfferLetterTemplateParams,
    setCreateOfferLetterTemplateModalState,
    updateOfferLetterTemplate,
    uploadOfferLetterTemplate,
} from 'modules/settings/submodules/offerLetters/store/action';
import { offerLetterTemplateApi } from 'modules/settings/submodules/offerLetters/store/api';
import { call, put, takeLatest } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

function* createOfferLetterTemplateSaga({
    payload,
}: ReturnType<typeof createOfferLetterTemplate.init>) {
    const template = yield* call(offerLetterTemplateApi.createTemplate, payload);
    yield put(createOfferLetterTemplate.success());
    yield put(getOfferLetterTemplates.success([template]));
    yield put(setCreateOfferLetterTemplateModalState(false));
}

function* createOfferLetterTemplatesWatcher() {
    yield* takeLatest(
        createOfferLetterTemplate.initType,
        withBackendErrorHandler(
            createOfferLetterTemplateSaga,
            createOfferLetterTemplate.error,
            'Unable to create offer letter template',
        ),
    );
}

function* updateOfferLetterTemplateSaga({
    payload,
}: ReturnType<typeof updateOfferLetterTemplate.init>) {
    const { id, data } = payload;
    const template = yield* call(offerLetterTemplateApi.updateTemplate, id, data);
    yield put(updateOfferLetterTemplate.success());
    yield put(getOfferLetterTemplates.success([template]));
}

function* updateOfferLetterTemplatesWatcher() {
    yield* takeLatest(
        updateOfferLetterTemplate.initType,
        withBackendErrorHandler(
            updateOfferLetterTemplateSaga,
            updateOfferLetterTemplate.error,
            'Unable to update offer letter template',
        ),
    );
}

function* uploadOfferLetterTemplateSaga({
    payload,
}: ReturnType<typeof uploadOfferLetterTemplate.init>) {
    const template = yield* call(offerLetterTemplateApi.uploadTemplate, payload);
    yield put(uploadOfferLetterTemplate.success(template));
}

function* uploadOfferLetterTemplateSagaWatcher() {
    yield* takeLatest(
        uploadOfferLetterTemplate.initType,
        withBackendErrorHandler(
            uploadOfferLetterTemplateSaga,
            uploadOfferLetterTemplate.error,
            'Unable to upload offer letter template',
        ),
    );
}

function* getOfferLetterTemplateParamsSaga() {
    const templateParams = yield* call(offerLetterTemplateApi.getTemplateParams);
    yield put(getOfferLetterTemplateParams.success(templateParams));
}

function* getOfferLetterTemplateParamsSagaWatcher() {
    yield* takeLatest(
        getOfferLetterTemplateParams.initType,
        withBackendErrorHandler(
            getOfferLetterTemplateParamsSaga,
            getOfferLetterTemplateParams.error,
            'Unable to fetch offer letter template params',
        ),
    );
}

export default [
    createOfferLetterTemplatesWatcher,
    updateOfferLetterTemplatesWatcher,
    uploadOfferLetterTemplateSagaWatcher,
    getOfferLetterTemplateParamsSagaWatcher,
];

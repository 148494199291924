import { combineReducers } from 'redux';
import { initialPaginationState, IPaginationState } from 'shared/models/IPaginationResponse';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';
import {
    IGroupedSheetCalculation,
    IPayrollProcessorFilters,
    IPayrollSheetSummary,
    IPostPayroll,
    IPrePayroll,
    PayrollSheetTabs,
} from 'modules/payrollProcessorHub/store/model';
import {
    changeSheetGroupStatus,
    CLEAR_PAYROLL_REPORT,
    getGroupedSheetCalculations,
    getPayrollPayPeriods,
    getPrePayrollReport,
    getSheetEditInfo,
    getSheetSummary,
    INCREMENT_PAYROLL_PAGE,
    initSheetGroupPayroll,
    LOAD_GROUPED_SHEETS,
    PayrollProcessorActions,
    SET_POST_PAYROLL_REPORT,
    setPayrollProcessorFilter,
} from 'modules/payrollProcessorHub/store/actions';
import { downloadSheetPdfLoadingModule, sendReminderLoadingModule } from './loadingModules';

export const pphFilterDefaultValues: IPayrollProcessorFilters = {
    status: PayrollSheetTabs.APPROVED,
    payPeriodEnd: null,
    assignmentId: null,
    clientId: null,
    dealId: null,
    employeeId: null,
    jobNumber: '',
    payrollProcessorUserId: null,
    managerId: null,
};

export const initialState = sendReminderLoadingModule.withInitialValue(
    downloadSheetPdfLoadingModule.withInitialValue({
        filter: pphFilterDefaultValues,
        groupedSheets: {
            isLoading: false,
            pagination: initialPaginationState,
            groups: [],
        },
        sheetSummary: {
            isLoading: false,
            summary: null,
        },
        payRoll: {
            isProcessing: false,
            prePayrollReport: null,
            postPayrollReport: null,
        },
    }),
);

export function filter(
    state: IPayrollProcessorFilters = initialState.filter,
    action: PayrollProcessorActions,
): IPayrollProcessorFilters {
    switch (action.type) {
        case setPayrollProcessorFilter.action:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export function pagination(
    state: IPaginationState = initialState.groupedSheets.pagination,
    action: PayrollProcessorActions,
): IPaginationState {
    switch (action.type) {
        case LOAD_GROUPED_SHEETS:
        case changeSheetGroupStatus.successType:
        case getSheetEditInfo.initType:
        case setPayrollProcessorFilter.action:
            return initialState.groupedSheets.pagination;
        case getGroupedSheetCalculations.successType: {
            const { page, page_size, total_items } = action.payload;
            return {
                page,
                page_size,
                total_items,
            };
        }
        case INCREMENT_PAYROLL_PAGE:
            return {
                ...state,
                page: state.page += 1,
            };
        default:
            return state;
    }
}

export function groups(
    state: IGroupedSheetCalculation[] = initialState.groupedSheets.groups,
    action: PayrollProcessorActions,
): IGroupedSheetCalculation[] {
    switch (action.type) {
        case LOAD_GROUPED_SHEETS:
        case changeSheetGroupStatus.successType:
        case setPayrollProcessorFilter.action:
            return initialState.groupedSheets.groups;
        case getGroupedSheetCalculations.successType: {
            const { items } = action.payload;
            return [
                ...state,
                ...items,
            ];
        }
        default:
            return state;
    }
}

export function summary(
    state: IPayrollSheetSummary | null = initialState.sheetSummary.summary,
    action: PayrollProcessorActions,
): IPayrollSheetSummary | null {
    switch (action.type) {
        case LOAD_GROUPED_SHEETS:
        case setPayrollProcessorFilter.action:
            return initialState.sheetSummary.summary;
        case getSheetSummary.successType:
            return action.payload;
        default:
            return state;
    }
}

export function prePayrollReport(
    state: IPrePayroll | null = initialState.payRoll.prePayrollReport,
    action: PayrollProcessorActions,
): IPrePayroll | null {
    switch (action.type) {
        case CLEAR_PAYROLL_REPORT:
        case getPrePayrollReport.initType:
            return initialState.payRoll.prePayrollReport;
        case getPrePayrollReport.successType:
            return action.payload;
        default:
            return state;
    }
}

export function postPayrollReport(
    state: IPostPayroll | null = initialState.payRoll.postPayrollReport,
    action: PayrollProcessorActions,
): IPostPayroll | null {
    switch (action.type) {
        case CLEAR_PAYROLL_REPORT:
        case initSheetGroupPayroll.initType:
        case initSheetGroupPayroll.successType:
            return initialState.payRoll.postPayrollReport;
        case SET_POST_PAYROLL_REPORT:
            return action.payload;
        default:
            return state;
    }
}

const isLoading = isLoadingReducer(getGroupedSheetCalculations);
const isLoadingSummary = isLoadingReducer(getSheetSummary);
const isLoadingEditSheet = isLoadingReducer(getSheetEditInfo);
const isPayRollLoading = isLoadingReducer(initSheetGroupPayroll);
const isPreInitializeReportLoading = isLoadingReducer(getPrePayrollReport);
const payPeriods = singleValueReducer(getPayrollPayPeriods.successType, []);

export const payrollProcessorHub = combineReducers(
    sendReminderLoadingModule.withReducer(
        downloadSheetPdfLoadingModule.withReducer({
            filter,
            groupedSheets: combineReducers({
                isLoading,
                pagination,
                groups,
            }),
            sheetSummary: combineReducers({
                isLoading: isLoadingSummary,
                summary,
            }),
            sheetEditInfo: combineReducers({
                isLoading: isLoadingEditSheet,
            }),
            payRoll: combineReducers({
                isProcessing: isPayRollLoading,
                isPreInitializeReportLoading,
                prePayrollReport,
                postPayrollReport,
            }),
            payPeriods,
        }),
    ),
);

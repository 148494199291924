import moment from 'moment';
import {
    EntryType,
    IEntry,
    IEntryCommonBackend,
    IEntryCreateScaZone,
    IExpenseEntryBackend,
    IScaZoneFull,
    ITimeEntryBackend,
    IZipCodeFull,
    QuantityType,
    TimeEntryData,
    TimeEntryDataBackend,
} from 'shared/models/sheet/Sheet';
import { IEntryAttachment } from 'shared/models/Attachments';
import { IOdometerValue } from 'shared/utils/counters/odometer';
import {
    CLOCK_TIME_FORMAT, getMinutesByTimeUnits, parseTimeUnitsFromMinutes,
    printMinutes,
    transformBackendDateTimeToFormTime,
    transformTimeToBackendDateTime,
} from '../../../../shared/models/DateTime';

type OmitEntryFields = 'id' | 'sheet_id' | 'created_at'

interface IEntryCreate {
    project_id: string | undefined;
    user_id?: string;
    period_start: string;
    period_end: string;
}

/**
 * Type for storing common data for all entry types
 */
export type CommonEntryBackend = Omit<IEntryCommonBackend, OmitEntryFields | 'data'>

export interface IExpenseEntryCreate extends Omit<IExpenseEntryBackend, OmitEntryFields | 'zip_code'>, IEntryCreate {
    zip_code?: IZipCodeFull;
}

export interface ITimeEntryCreate extends Omit<ITimeEntryBackend, OmitEntryFields | 'sca_zone'>, IEntryCreate {
    sca_zone: IEntryCreateScaZone | null;
}

export interface IExpenseEntryUpdate extends IExpenseEntryCreate, Pick<IEntry, 'id'> {}
export interface ITimeEntryUpdate extends Partial<ITimeEntryCreate>, Pick<IEntry, 'id'> {}

export interface IEntryForm extends Pick<IEntryCommonBackend, 'entry_date' | 'activity_id' | 'task_id' | 'notes'> {
    assignment_id: string;
    dollars?: number;
    scaZone: IScaZoneFull | null;
    zipCode: IZipCodeFull | null;
    sheet_entry_attachments: IEntryAttachment[] | [];
    odometer: IOdometerValue | null;
}

export interface IEntryFormSingleTime extends IEntryForm {
    time: string;
}

export interface IEntryFormTimeSplit extends IEntryForm {
    hours?: number;
    minutes?: number;
}

export interface ICreateEntryParams {
    status_id?: string;
}

export const transformTimeDataToBackend = (
    formData: TimeEntryData,
    date: string,
): TimeEntryDataBackend => {
    switch (formData.entry_type) {
        case QuantityType.TIME:
            return {
                entry_type: formData.entry_type,
                hours: formData.hours,
                minutes: formData.minutes,
            };
        case QuantityType.TIME_IN_OUT:
        case QuantityType.TIME_BREAK:
            return {
                entry_type: formData.entry_type,
                time_in: transformTimeToBackendDateTime({ time: formData.timeIn, date }),
                time_out: transformTimeToBackendDateTime({ time: formData.timeOut, date }),
            };
        case QuantityType.TIME_IN_OUT_BREAK:
            return {
                entry_type: formData.entry_type,
                time_in: transformTimeToBackendDateTime({ time: formData.timeIn, date }),
                time_out: transformTimeToBackendDateTime({ time: formData.timeOut, date }),
                break_minutes: getMinutesByTimeUnits({
                    hours: formData.hoursBreak || 0,
                    minutes: formData.minutesBreak || 0,
                }),
            };
        case QuantityType.FILE:
            return {
                entry_type: formData.entry_type,
                files: +formData.files,
            };
    }
};

export const transformBackendTimeToTimeData = ( data: TimeEntryDataBackend ): TimeEntryData | null => {
    switch (data.entry_type) {
        case QuantityType.TIME:
            return {
                entry_type: data.entry_type,
                hours: data.hours,
                minutes: data.minutes,
            };
        case QuantityType.FILE:
            return {
                entry_type: data.entry_type,
                files: data.files,
            };
        case QuantityType.TIME_IN_OUT:
        case QuantityType.TIME_BREAK:
            return {
                entry_type: data.entry_type,
                timeIn: transformBackendDateTimeToFormTime(data.time_in),
                timeOut: transformBackendDateTimeToFormTime(data.time_out),
            };
        case QuantityType.TIME_IN_OUT_BREAK:
            return {
                entry_type: data.entry_type,
                timeIn: transformBackendDateTimeToFormTime(data.time_in),
                timeOut: transformBackendDateTimeToFormTime(data.time_out),
                ...(() => {
                    const { hours, minutes } = parseTimeUnitsFromMinutes(data.break_minutes);
                    return {
                        hoursBreak: hours,
                        minutesBreak: minutes,
                    };
                })(),
            };
    }
    return null;
};

export const isEntryAllowsTimeInTimeOut = (entry: IEntry) => {
    if (entry.entry_type !== EntryType.TIME) {
        return false;
    }
    return ([
        QuantityType.TIME_IN_OUT,
        QuantityType.TIME_BREAK,
        QuantityType.TIME_IN_OUT_BREAK,
    ].includes(entry.data?.entry_type));
};

export interface IPredefinedTimeOption {
    type: QuantityType.TIME_BREAK | QuantityType.TIME_IN_OUT | QuantityType.TIME;
    label: string;
    hours: number;
    minutes: number;
}

export const PREDEFINED_TIMES: IPredefinedTimeOption[] = [
    { hours: 1, minutes: 30 },
    { hours: 1, minutes: 45 },
    { hours: 2, minutes: 0 },
    { hours: 2, minutes: 15 },
].map(option => ({
    ...option,
    type: QuantityType.TIME,
    label: `${option.hours} hr ${printMinutes(option.minutes)} min`,
}));

export const PREDEFINED_TIME_BREAKS: IPredefinedTimeOption[] = [
    { hours: 8, minutes: 0 },
    { hours: 9, minutes: 0 },
    { hours: 10, minutes: 0 },
    { hours: 13, minutes: 0 },
].map(option => ({
    ...option,
    type: QuantityType.TIME_BREAK,
    label: moment().hours(option.hours).minutes(option.minutes)
        .format(CLOCK_TIME_FORMAT),
}));

export const PREDEFINED_TIME_IN_OUT: IPredefinedTimeOption[] = [
    { hours: 8, minutes: 0 },
    { hours: 9, minutes: 0 },
    { hours: 10, minutes: 0 },
    { hours: 13, minutes: 0 },
].map(option => ({
    ...option,
    type: QuantityType.TIME_IN_OUT,
    label: moment().hours(option.hours).minutes(option.minutes)
        .format(CLOCK_TIME_FORMAT),
}));

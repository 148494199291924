import React from 'react';
import { Box } from '@material-ui/core';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { getCostCenters } from 'store/entities/configuration/configurationAction';
import { FeatureSwitches } from 'utils/featureSwitches';
import EditCostCenterModal from 'modules/settings/submodules/clients/costCenters/components/EditCostCenter/EditCostCenterModal';
import { CostCenterTable } from 'modules/settings/submodules/clients/costCenters/components/CostCenterTable/CostCentersTable';
import { CreateCostCenterButton } from 'modules/settings/submodules/clients/costCenters/components/CreateCostCenter/CreateCostCenterButton';
import { CostCenterFilter } from 'modules/settings/submodules/clients/costCenters/components/Filter/CostCenterFilter';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

export function CostCentersPage() {
    useWithClientEffect(dispatch => dispatch(getCostCenters.init()));

    return (
        <FeatureSwitch feature={FeatureSwitches.enableGenworthSheets}>
            <Box
                display="flex"
                justifyContent="space-between"
                mb={2}
            >
                <CreateCostCenterButton/>
                <CostCenterFilter/>
            </Box>
            <CostCenterTable/>
            <EditCostCenterModal/>
        </FeatureSwitch>
    );
}

import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Checkbox, FormControlLabel, Typography, Box, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import ToolbarEntry from 'shared/components/toolbar/ToolbarEntry';
import DayOfWeekDate from 'shared/components/toolbar/dayOfWeekDate/dayOfWeekDate';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { patchEntriesDateSpecificBySheetId } from 'store/entities/timesheet/actions/timeActions';
import { EntriesTableHeaderTotal } from './EntriesTableHeaderTotal';
import { SheetTotalSlug } from 'store/entities/clients/clientsModel';
import {
    ISheetTotalConfigurationByTotalSlug,
    selectSheetTotalConfigurationByTotalSlug,
} from 'store/entities/clients/clientsSelectors';
import { selectPatchingSheetsByIds } from 'store/entities/timesheet/selectors';

interface IEntriesTableHeaderProps {
    date: string;
    entries: Array<IEntry>;
    entryFilter?: EntryType;
    isPerDiem?: boolean;
    isHoliday?: boolean;
    timeSheetIds?: string[];
    collapsed?: boolean;
    isCollapsible?: boolean;
    isFileView?: boolean;
    actionsEnabled?: boolean;
    onCollapse?: () => void;
}

interface IEntriesTableHeaderStoreProps {
    perDiemChangeHandler?: (evt: SyntheticEvent) => void;
    holidayChangeHandler?: (evt: SyntheticEvent) => void;
    fieldsBySlug?: ISheetTotalConfigurationByTotalSlug;
    isHolidaysSaving?: boolean;
    isPerDiemSaving?: boolean;
}

export function EntriesTableHeaderPure({
    date,
    entries,
    entryFilter,
    timeSheetIds,
    collapsed = false,
    isCollapsible = false,
    onCollapse,
    fieldsBySlug,
    perDiemChangeHandler,
    holidayChangeHandler,
    isFileView = false,
    isPerDiem = false,
    isHoliday = false,
    isHolidaysSaving = false,
    isPerDiemSaving = false,
    actionsEnabled = false,
}: IEntriesTableHeaderProps & IEntriesTableHeaderStoreProps) {
    const classes = useEntriesTableStyles();

    const [isHolidayChecked, setIsHolidayChecked] = useState(isHoliday);
    const [isPerDiemChecked, setIsPerDiemChecked] = useState(isPerDiem);

    const onPerDiemChange = useCallback(evt => {
        setIsPerDiemChecked(Boolean(evt.target.checked));
        typeof perDiemChangeHandler === 'function' && perDiemChangeHandler(evt);
    }, [perDiemChangeHandler]);
    const onHolidayChange = useCallback(evt => {
        setIsHolidayChecked(Boolean(evt.target.checked));
        typeof holidayChangeHandler === 'function' && holidayChangeHandler(evt);
    }, [holidayChangeHandler]);

    const hasPerDiem = actionsEnabled && Boolean(fieldsBySlug?.[SheetTotalSlug.PerDiem]);
    const hasHolidays = actionsEnabled && Boolean(fieldsBySlug?.[SheetTotalSlug.HolidayTime]);
    const isHolidayCheckboxVisible = hasHolidays && !isFileView;
    const hasTimeSheets = timeSheetIds && !!timeSheetIds.length;

    return (
        <ToolbarEntry classes={{ root: clsx(classes.toolbar, { [classes.toolbarCollapsible]: isCollapsible }) }}>
            <Box className={classes.toolbarContent}>
                {isCollapsible && (
                    <IconButton size="small" onClick={onCollapse}>
                        {collapsed ? (<KeyboardArrowRight fontSize="small"/>) : (<KeyboardArrowDown fontSize="small"/>)}
                    </IconButton>
                )}
                <DayOfWeekDate date={moment(date)}/>
                <Box className={classes.toolbarInfo}>
                    <Box className={classes.dayHeaderActions}>
                        {hasPerDiem && (
                            <FormControlLabel
                                classes={{ root: classes.headerCheckboxBlock }}
                                control={(
                                    <Checkbox
                                        name={name}
                                        onChange={onPerDiemChange}
                                        size="small"
                                        checked={isPerDiemChecked}
                                    />
                                )}
                                label={(<Typography className={classes.headerCheckbox}>Per diem</Typography>)}
                                disabled={!hasTimeSheets || isPerDiemSaving}
                                title={hasTimeSheets ? 'Set per diem time' : ''}
                            />
                        )}
                        {isHolidayCheckboxVisible && (
                            <FormControlLabel
                                classes={{ root: classes.headerCheckboxBlock }}
                                control={(
                                    <Checkbox
                                        name={name}
                                        onChange={onHolidayChange}
                                        size="small"
                                        checked={isHolidayChecked}
                                    />
                                )}
                                label={(<Typography className={classes.headerCheckbox}>Holiday time</Typography>)}
                                disabled={!hasTimeSheets || isHolidaysSaving}
                                title={hasTimeSheets ? 'Set holiday time' : ''}
                            />
                        )}
                    </Box>
                    <EntriesTableHeaderTotal
                        useTotalsLoading={actionsEnabled}
                        entryFilter={entryFilter}
                        entries={entries}
                        date={date}
                    />
                </Box>
            </Box>
        </ToolbarEntry>
    );
}

export const EntriesTableHeader = separateLogicDecorator<
IEntriesTableHeaderProps,
IEntriesTableHeaderStoreProps
>(({
    timeSheetIds,
    date,
    isPerDiem = false,
    isHoliday = false,
}) => {
    const dispatch = useDispatch();
    const handleChangePerDiem = useCallback(evt => {
        if (timeSheetIds) {
            timeSheetIds.forEach(timeSheetId => {
                dispatch(patchEntriesDateSpecificBySheetId.init({
                    sheetId: timeSheetId,
                    isPerDiem: evt.target.checked,
                    isHoliday,
                    date,
                }));
            });
        }
    }, [dispatch, timeSheetIds, date, isHoliday]);
    const handleChangeHolidays = useCallback(evt => {
        if (timeSheetIds) {
            timeSheetIds.forEach(timeSheetId => {
                dispatch(patchEntriesDateSpecificBySheetId.init({
                    sheetId: timeSheetId,
                    isPerDiem,
                    isHoliday: evt.target.checked,
                    date,
                }));
            });
        }
    }, [dispatch, timeSheetIds, date, isPerDiem]);

    const patchingTimeSheetsByIds = useSelector(selectPatchingSheetsByIds);
    const isSheetPatching = useMemo(() => {
        if (!timeSheetIds) {
            return false;
        }
        return !!timeSheetIds.find(sheetId => patchingTimeSheetsByIds[sheetId]);
    }, [timeSheetIds, patchingTimeSheetsByIds]);

    return {
        perDiemChangeHandler: handleChangePerDiem,
        holidayChangeHandler: handleChangeHolidays,
        fieldsBySlug: useSelector(selectSheetTotalConfigurationByTotalSlug),
        isHolidaysSaving: isSheetPatching,
        isPerDiemSaving: isSheetPatching,
    };
})(EntriesTableHeaderPure);

import clsx from 'clsx';
import { useSheetCalculationUpdate } from 'modules/timeAndExpense/components/utils/updateSheetCalculationUtils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Hidden } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BackButton } from 'modules/timeAndExpense/components/EditSheet/BackButton';
import { PayPeriodReadonly } from 'modules/timeAndExpense/components/EditSheet/PayPeriodReadonly';
import AddNewEntry from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddNewEntry';
import AddNewEntryDialog
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControlsMobile/AddNewEntryDialog';
import EntriesGrid from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/EntriesGrid';
import Footer from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/Footer';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { useEntriesByDayArray } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import { EntryType, ISheetStatusesId } from 'shared/models/sheet/Sheet';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import { TotalMinutesByDay } from 'shared/utils/context/totalMinutesByDay';
import { selectAllEntries, selectTimeSheetsByIds, selectExpensesSheetsByIds } from 'store/entities/timesheet/selectors';
import { setHeaderContent } from 'modules/home/header/Header';
import PayrollSheetStatusTabs
    from 'modules/payrollProcessorHub/components/PayrollSheetStatusTabs/PayrollSheetStatusTabs';
import { selectPayrollFilter } from 'modules/payrollProcessorHub/store/selectors';
import { SelectorsPanel } from 'modules/timeAndExpense/components/AddEntry/components/SelectorsPanel/SelectorsPanel';
import StatusBadge from './StatusBadge';
import { TotalInfo } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/components/TotalInfo/TotalInfo';

export interface IEditSheetContentProps {
    onClose: () => void;
    currentSheetStatusesId: ISheetStatusesId;
    onSheetSubmit?: () => void;
    timeSheetId?: string | null;
    expenseSheetId?: string | null;
    isLoading?: boolean;
    isPayroll?: boolean;
}

export const EditSheetView = ({
    onClose,
    timeSheetId,
    expenseSheetId,
    onSheetSubmit,
    isLoading = false,
    isPayroll = false,
}: IEditSheetContentProps) => {
    const classes = useSheetsInProgressStyles();

    const { status } = useSelector(selectPayrollFilter);
    useEffect(() => {
        setHeaderContent(
            <Box display="flex" alignItems="flex-end">
                {status && (<StatusBadge status={status}/>)}
                <PayrollSheetStatusTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, [status]);

    const entries = useSelector(selectAllEntries).filter(
        entry => (entry.entry_type === EntryType.TIME && entry.sheet_id === timeSheetId)
        || (entry.entry_type === EntryType.EXPENSE && entry.sheet_id === expenseSheetId),
    );
    const entriesByDay = useEntriesByDayArray(entries);
    const totalEntryMinutesByDay = useTotalTimeEntriesByDay(entries);

    const timeSheet = useSelector(selectTimeSheetsByIds)[timeSheetId || ''];
    const expenseSheet = useSelector(selectExpensesSheetsByIds)[expenseSheetId || ''];
    const sheet = timeSheet || expenseSheet;

    const entryTypeFilter = timeSheetId && expenseSheetId ? undefined
        : timeSheetId ? EntryType.TIME : EntryType.EXPENSE;

    const entryTypes = [];
    timeSheetId && entryTypes.push(EntryType.TIME);
    expenseSheetId && entryTypes.push(EntryType.EXPENSE);
    const hasEntries = entries.length > 0;
    useSheetCalculationUpdate(timeSheetId ? [timeSheetId] : []);

    return (
        <TotalMinutesByDay.Provider value={totalEntryMinutesByDay}>
            <Box className={classes.mainContainer}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.backButtonWrapper}
                >
                    <Box display="flex" alignItems="center">
                        <BackButton onClick={onClose}/>
                        <PayPeriodReadonly payPeriod={sheet}/>
                    </Box>
                    {isPayroll && (
                        <Box mr={4}>
                            <Alert severity="warning">
                                Please note that all sheet changes are saved immediately after entries update.
                            </Alert>
                        </Box>
                    )}
                </Box>
                <>
                    <Box className={classes.topContainer} mt={5}>
                        <SelectorsPanel/>
                    </Box>
                    <Hidden xsDown>
                        <AddNewEntry
                            customClasses={{ paper: clsx(classes.addControlsPaper, classes.editControlsPaper) }}
                            timeSheetId={timeSheetId || undefined}
                            expenseSheetId={expenseSheetId || undefined}
                            userId={sheet?.user_id}
                            payPeriod={sheet}
                            entryTypeFilter={entryTypeFilter}
                            statusName={sheet?.status?.name}
                        />
                    </Hidden>
                    <Hidden smUp>
                        <AddNewEntryDialog
                            entries={entries}
                            timeSheetId={timeSheetId || undefined}
                            expenseSheetId={expenseSheetId || undefined}
                            userId={sheet?.user_id}
                            payPeriod={sheet}
                        />
                    </Hidden>
                </>
                <EntriesGrid
                    hasEntries={hasEntries}
                    noFilteredEntries={!hasEntries}
                    filteredEntriesByDay={entriesByDay}
                    entryFilter={entryTypeFilter}
                    isLoaded={!isLoading}
                    userId={sheet?.user_id}
                />
                <Hidden xsDown>
                    <Box>
                        <TotalInfo
                            entries={entries}
                            entryFilter={entryTypeFilter}
                        />
                    </Box>
                </Hidden>
                <Footer
                    isPayroll={isPayroll}
                    entryTypes={entryTypes}
                    hasEntries={hasEntries}
                    submitAction={onSheetSubmit}
                    status={sheet?.status?.name}
                />
            </Box>
        </TotalMinutesByDay.Provider>
    );
};

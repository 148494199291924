import React, { ChangeEvent, useEffect } from 'react';
import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { FormControl, FormHelperText, Select } from '@material-ui/core';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IActivity } from 'store/entities/configuration/configurationModel';
import {
    selectActivitiesByIdWithoutDeleted, selectDefaultTimeActivities,
    selectTimeActivities,
} from 'store/entities/configuration/configurationSelectors';

interface ITimeActivitySelectProps extends IFormFieldProps, IFormSelect {
    onlyDefaultActivities: boolean;
}

export const TimeActivitySelect = ({
    name,
    outerLabel,
    className,
    title,
    onlyDefaultActivities,
    disabled = false,
    id = name,
}: ITimeActivitySelectProps) => {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [field, meta, helpers] = useField(name);
    const hasError = Boolean(meta.error && meta.touched);
    const activitiesById = useSelector(selectActivitiesByIdWithoutDeleted);
    const allTimeActivities = useSelector(selectTimeActivities);
    const defaultTimeActivities = useSelector(selectDefaultTimeActivities);
    const timeActivities = onlyDefaultActivities
        ? defaultTimeActivities
        : allTimeActivities;
    useEffect(() => {
        if (onlyDefaultActivities) {
            helpers.setValue(defaultTimeActivities);
        }
        // helpers are created each render
        // eslint-disable-next-line
    }, [onlyDefaultActivities, defaultTimeActivities]);
    const handleChangeMultiple = (event: ChangeEvent<HTMLSelectElement>) => {
        const { options } = event.target as HTMLSelectElement;
        const value: IActivity[] = [...options]
            .filter(option => option.selected)
            .map(option => activitiesById[option.value]);
        helpers.setValue(value);
    };

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            {outerLabel && (
                <label
                    htmlFor={id}
                    className={formHelperTextClasses.outerLabel}
                    title={title}
                >
                    {outerLabel}
                </label>
            )}
            <Select
                multiple
                native
                value={field.value.map((item: IActivity) => item?.id)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={handleChangeMultiple}
                inputProps={{
                    id,
                }}
                disabled={disabled || onlyDefaultActivities}
                title={title}
            >
                {timeActivities.map(activity => (
                    <option key={activity.id} value={activity.id}>
                        {activity.description}
                    </option>
                ))}
            </Select>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
};

import { IEntity } from 'shared/models/Entity';
import { AnyAction } from 'redux';
import { ActionCreatorKnownArgs, IActionsCreatorCommon } from 'store/utils';
import { ItemsById } from 'shared/models/ItemsById';
import { ISystemState } from 'shared/models/Global';
import { Nullable } from '../@types/types';
import { mergeWith, isArray } from 'lodash';

export function itemsByIds<Input extends IEntity, Output extends IEntity = Input>(
    array: Array<Input>, getItem?: (item: Input) => Output,
): ItemsById<Output> {
    return array.reduce((acc, item) => ({
        ...acc,
        [item.id]: getItem ? getItem(item) : item,
    }), {});
}

export function itemsByKey<Input>(
    array: Array<Input>,
    getKey: (item: Input) => string,
): Record<string, Input> {
    return array.reduce((acc, item) => ({
        ...acc,
        [getKey(item)]: item,
    }), {});
}

export function extractIds(array: Array<IEntity>) {
    return array.map(item => item.id);
}

export function isLoadingReducer(actionsCreator: IActionsCreatorCommon, defaultState = false) {
    return (state = defaultState, action: AnyAction): boolean => {
        switch (action.type) {
            case actionsCreator.initType:
                return true;
            case actionsCreator.successType:
            case actionsCreator.errorType:
                return false;
            default:
                return state;
        }
    };
}

const withMessageReducerInitialState: Nullable<ISystemState> = null;
export function withMessageReducer(
    actionsCreator: IActionsCreatorCommon,
    defaultState: Nullable<ISystemState>,
    skipMessageAction?: string,
) {
    return (state = defaultState, action: AnyAction): Nullable<ISystemState> => {
        switch (action.type) {
            case skipMessageAction:
            case actionsCreator.initType:
                return withMessageReducerInitialState;
            case actionsCreator.successType:
                return {
                    isError: false,
                    message: action.payload,
                };
            case actionsCreator.errorType:
                return {
                    isError: true,
                    message: action.payload,
                };
            default:
                return state;
        }
    };
}

export function singleValueReducer<TValue>(actionName: string, defaultState: TValue) {
    return (state = defaultState, action: AnyAction): TValue => {
        switch (action.type) {
            case actionName:
                return action.payload;
            default:
                return state;
        }
    };
}

export function isOpenModalReducer(actionName: string, defaultState = false) {
    return singleValueReducer<boolean>(actionName, defaultState);
}

export interface IActionsCreatorItemsById<Item> extends IActionsCreatorCommon{
    success: ActionCreatorKnownArgs<Array<Item>, any>;
}

interface IActionsCreatorDeleteById extends IActionsCreatorCommon{
    success: ActionCreatorKnownArgs<string, any>;
}

function replaceArrayMergeCustomizer(objValue: any, srcValue: any) {
    if (isArray(srcValue)) {
        return srcValue;
    }
}

export function itemsByIdReducer<InputItem, Item extends IEntity>(
    actionCreator: IActionsCreatorItemsById<InputItem>,
    defaultState = {},
    getItem?: (inputItem: InputItem) => Item,
) {
    return (state: ItemsById<Item> = defaultState, action: AnyAction): ItemsById<Item> => {
        switch (action.type) {
            case actionCreator.successType: {
                const payload = action.payload as Parameters<typeof actionCreator.success>[0];
                const data = getItem ? payload.map(getItem) : payload as unknown as Array<Item>;

                // recursive merge items and replace arrays
                return mergeWith(
                    {},
                    state,
                    itemsByIds(data),
                    replaceArrayMergeCustomizer,
                );
            }
            default:
                return state;
        }
    };
}

export function deleteItemById<Item extends IEntity>(
    itemsByIdState: ItemsById<Item>,
    removeItemId: string,
): ItemsById<Item> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [removeItemId]: removedEntry, ...newState } = itemsByIdState;
    return newState;
}

export function deleteItemByIdReducer<Item extends IEntity>(
    actionCreator: IActionsCreatorDeleteById,
    defaultState = {},
) {
    return (state: ItemsById<Item> = defaultState, action: AnyAction): ItemsById<Item> => {
        switch (action.type) {
            case actionCreator.successType:
                return deleteItemById(state, action.payload);
            default:
                return state;
        }
    };
}

export type OneToMany = Record<string, Array<string>>;

export interface IInfinityScrollState<Item> {
    total?: number;
    items: Item[];
    isLoading: boolean;
}
export const defaultInfinityScrollState = {
    total: undefined,
    items: [],
    isLoading: false,
};

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntryType } from 'shared/models/sheet/Sheet';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { getSheetEditInfo } from 'modules/payrollProcessorHub/store/actions';
import { ISheetGroupId, PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';
import { selectIsLoadingSheetEditInfo, selectSheetsGroupById } from 'modules/payrollProcessorHub/store/selectors';
import { EditSheetView } from 'modules/timeAndExpense/components/EditSheet/EditSheetView';

export interface IEditPayrollSheetPageContent {
    groupId: ISheetGroupId;
    onClose: () => void;
    openConfirmation?: () => void;
}

export const EditPayrollSheetPageContent = ({
    groupId,
    onClose,
    openConfirmation,
}: IEditPayrollSheetPageContent) => {
    const dispatch = useDispatch();
    const sheetGroup = useSelector(selectSheetsGroupById(groupId));
    const isLoading = useSelector(selectIsLoadingSheetEditInfo);
    const { timeSheetId, expenseSheetId } = groupId;

    useEffect(() => {
        dispatch(getSheetEditInfo.init(groupId));
    }, [dispatch, groupId]);

    const onSheetSubmit = useCallback(() => {
        if (sheetGroup?.payroll_status !== PayrollSheetStatuses.APPROVED) {
            const sheetsEntryTypes: SheetsEntryTypes = {};
            if (timeSheetId) {
                sheetsEntryTypes[timeSheetId] = EntryType.TIME;
            }
            if (expenseSheetId) {
                sheetsEntryTypes[expenseSheetId] = EntryType.EXPENSE;
            }
            dispatch(updateSheetsStatus.init({
                statusName: StatusNames.SUBMITTED,
                sheetsEntryTypes,
            }));
        }
        onClose();
    }, [dispatch, timeSheetId, expenseSheetId, sheetGroup, onClose]);
    const statusesId = {
        timeStatusId: sheetGroup?.time_status_id || undefined,
        expenseStatusId: sheetGroup?.expense_status_id || undefined,
    };

    return (
        <EditSheetView
            isPayroll
            onClose={openConfirmation ? openConfirmation : onClose}
            currentSheetStatusesId={statusesId}
            onSheetSubmit={onSheetSubmit}
            timeSheetId={timeSheetId}
            expenseSheetId={expenseSheetId}
            isLoading={isLoading}
        />
    );
};

import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { FormMode } from 'shared/models/FormMode';
import { IClientWorkLocationFormValues } from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/model';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import WorkLocationFormWrapper
    from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/WorkLocationFormWrapper';
import { setEditClientWorkLocationId } from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/state/actions';
import {
    selectEditWorkLocationId,
    selectIsClientWorkLocationUpdating,
} from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/state/selector';
import { selectLocationById } from 'store/entities/configuration/configurationSelectors';
import { updateClientWorkLocation } from 'modules/settings/submodules/clients/workLocation/store/actions';

export default function EditWorkLocationModal() {
    const dispatch = useDispatch();
    const modalClasses = useSettingsFormModalStyles();
    const editWorkLocationId = useSelector(selectEditWorkLocationId);
    const isLoading = useSelector(selectIsClientWorkLocationUpdating);
    const editLocation = useSelector(selectLocationById(editWorkLocationId));

    const onSave = useCallback((values: IClientWorkLocationFormValues) => {
        dispatch(updateClientWorkLocation.init({
            id: editWorkLocationId,
            request: values,
        }));
    }, [dispatch, editWorkLocationId]);
    const onClose = useCallback(() => {
        dispatch(setEditClientWorkLocationId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit"
            isOpened={Boolean(editWorkLocationId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <WorkLocationFormWrapper
                    submitTitle="Save"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    location={editLocation}
                    mode={ FormMode.Edit }/>
            </Box>
        </ModalDialog>
    );
}

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authApi } from 'shared/utils/authApi';
import * as qs from 'query-string';
import { logger } from 'shared/utils/logging/logger';
import { getRequestDetails } from 'shared/utils/logging/requestModel';
import { browserHistory } from './browserHistory';
import { routes } from 'shared/routes';
import { TokenRefreshedRecently } from './error/TokenRefreshedRecently';

export interface IApiResponse<DataModel> {
    data: DataModel;
    status: number;
}

export const baseUrl: string = process.env.REACT_APP_API_BASE_URL as string;

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        common: {
            'Ocp-Apim-Subscription-Key':
                process.env.REACT_APP_OCI_APIM_SUBSCRIPTION_KEY,
        },
    },
    paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: 'comma' }),
});

axiosInstance.interceptors.request.use(config => {
    return authApi
        .getAccessToken()
        .then(validToken => {
            config.headers['Authorization'] = 'Bearer ' + validToken;
            return config;
        })
        .catch(() => {
            return config;
        });
});

const urlsToIgnoreErrors = [
    /\/client-users\/avatar/, //404 is ok when a user has no avatar
];

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    (error: AxiosError) => {
        const statusCode = error.response?.status;
        if (statusCode !== 401) {
            if (statusCode && statusCode >= 400) {
                const requestDetails = getRequestDetails(error);
                const shouldIgnore = urlsToIgnoreErrors
                    .some(urlToIgnore => urlToIgnore.test(requestDetails?.request?.responseURL));
                if (!shouldIgnore){
                    if (statusCode < 500) {
                        logger.info(error, requestDetails);
                    } else {
                        logger.error(error, requestDetails);
                    }
                }
            }

            return Promise.reject(error);
        }
        if (error.config.headers['TokenRefreshed']) {
            const err = new TokenRefreshedRecently();
            logger.info(err, getRequestDetails(error));
            return Promise.reject(err);
        }
        return authApi.refreshTokens().then(() => {
            error.config.headers['TokenRefreshed'] = true;
            return axiosInstance.request(error.config).catch(err => {
                if (!(err instanceof TokenRefreshedRecently)) {
                    throw err;
                }
            });
        }).catch(err => {
            logger.info(err);
            browserHistory.replace(routes.AUTH.LOGIN);
            throw err;
        });
    },
);

const baseApi = {
    axiosInstance: axiosInstance,
    clientId: null as string | null,
    get: async <Response>(
        url: string,
        params?: any,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .get<Response>(url, { params, ...config })
            .catch(error => {
                throw error;
            }),
    create: async <Request, Response>(
        service: string,
        entity: string,
        body: Request,
        params?: any,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.post<Response>(`/${service}/${entity}`, body, {
            params,
        }),

    update: async <Request, Response>(
        service: string,
        entity: string,
        body: Request,
        params?: any,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .put<Response>(`/${service}/${entity}`, body, {
            params: { ...params },
        })
            .catch(error => {
                throw error;
            }),

    updateById: async <Request, Response>(
        service: string,
        entity: string,
        id: string,
        body: Request,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .put<Response>(`/${service}/${entity}/${id}`, body)
            .catch(error => {
                throw error;
            }),

    patch: async <Request, Response>(
        url: string,
        body: Request,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.patch<Response>(url, body).catch(error => {
            throw error;
        }),

    post: async <Request, Response>(
        url: string,
        body: Request,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.post<Response>(url, body, config),

    put: async <Request, Response>(
        url: string,
        body: Request,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.put<Response>(url, body, config),

    delete: async <Response>(
        url: string,
        id: string,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance.delete<Response>(`${url}/${id}`).catch(error => {
            throw error;
        }),
    head: async <Response>(
        url: string,
        params?: any,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<Response>> =>
        axiosInstance
            .head<Response>(url, { params, ...config })
            .catch(error => {
                throw error;
            }),
};

export default baseApi;

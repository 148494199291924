import { combineReducers } from 'redux';
import { IManagerSubmittedSheets } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsModel';
import {
    SET_MANAGER_SUBMITTED_SHEETS_FILTERS,
    setManagerSubmittedSheetsFilters,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';

const initialState = {
    filters: {
        location_id: '',
        position_id: '',
        user_id: '',
        pay_period: '',
    },
};

function filters(
    state: IManagerSubmittedSheets = initialState.filters,
    action: ReturnType<typeof setManagerSubmittedSheetsFilters>,
): IManagerSubmittedSheets {
    switch (action.type) {
        case SET_MANAGER_SUBMITTED_SHEETS_FILTERS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export const managerSubmittedSheets = combineReducers({
    filters,
});

import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { IFormFieldProps } from '../formFields/models';
import { IFormSelect } from './model';
import { selectPayPeriodsBySheets } from 'store/entities/timesheet/selectors';
import { getFormattedPayPeriod } from 'shared/models/Dates';

interface IPayPeriodProps extends IFormFieldProps, IFormSelect {}

export const PayPeriod = (props: IPayPeriodProps) => {
    const payPeriods = useSelector(selectPayPeriodsBySheets);

    return (
        <FormSelect
            getKey={(period: IPayPeriod) => getFormattedPayPeriod(period)}
            getText={(period: IPayPeriod) => getFormattedPayPeriod(period)}
            options={payPeriods}
            {...props}
        />
    );
};

import { useSelector } from 'react-redux';
import { selectDealTypeById } from 'store/entities/configuration/configurationSelectors';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import { IDeal } from 'shared/models/Deal';

export const useIsOnlyDefaultActivities = (deal?: IDeal | null) => {
    const dealTypeId = deal?.type_id;
    const dealType = useSelector(selectDealTypeById(dealTypeId));
    const dealTypeHasOnlyDefaultActivities = dealType?.use_default_activity ?? false;
    const [defaultActivityFeatureEnabled] = useFeature(FeatureSwitches.displayDefaultPropertyInSettingsActivities);
    return dealTypeHasOnlyDefaultActivities && defaultActivityFeatureEnabled;
};

import { IStore } from 'store/configureStore';

export const selectPayCodesState = (state: IStore) =>
    state.modules.settings.clientsSettings.clients.payCodes;
export const selectIsPayCodesLoading = (state: IStore) =>
    selectPayCodesState(state).isLoading;
export const selectPayCodes = (state: IStore) =>
    Object.values(selectPayCodesState(state).payCodesById).filter(payCode => !payCode.deleted_at);
export const selectPayCodesById = (state: IStore) =>
    selectPayCodesState(state).payCodesById;

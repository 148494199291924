import { usersApi } from 'store/entities/users/api';
import { getBackendErrorOrDefaultMessage } from 'store/utils/sagas/getBackendErrorOrDefaultMessage';
import { call, put, takeLatest } from 'typed-redux-saga';
import { newPasswordAfterResetting, newPasswordCodeChecking, passwordResetting } from './actions';
import { authByPassword } from '../auth/authActions';
import { IPasswordAuthRequest } from '../auth/authModels';
import { navigateSaga } from '../router/routerSagas';
import { routes } from 'shared/routes';

function* passwordResettingSaga(action: ReturnType<typeof passwordResetting.init>) {
    try {
        yield* call(usersApi.passwordResetting, action.payload);
        yield* put(passwordResetting.success(
            'An email with instructions has been sent to reset your password. Please check your email to complete this process.',
        ));
    } catch (e) {
        if (e.response?.status === 404) {
            yield* put(passwordResetting.error('Email is not registered'));
        } else {
            const errorMessage = getBackendErrorOrDefaultMessage(e, 'Unable to reset password. Please repeat attempt later');
            yield* put(passwordResetting.error(errorMessage));
        }
    }
}

export function* passwordResettingSagaWatcher() {
    yield* takeLatest(passwordResetting.initType, passwordResettingSaga);
}

function* newPasswordAfterResettingSaga(action: ReturnType<typeof newPasswordAfterResetting.init>) {
    try {
        yield* call(usersApi.newPasswordAfterResetting, action.payload);
        const { password, email } = action.payload;
        const credentials: IPasswordAuthRequest = {
            password,
            username: email,
        };
        yield* put(newPasswordAfterResetting.success());
        yield* put(authByPassword.init(credentials));
    } catch (e) {
        if (e.response?.status === 410) {
            yield* call(navigateSaga, routes.AUTH.RESET_FAILED);
        } else {
            const errorMessage = getBackendErrorOrDefaultMessage(e, 'Unable to change password. Please repeat attempt later');
            yield* put(newPasswordAfterResetting.error(errorMessage));
        }
    }
}

export function* newPasswordAfterResettingSagaWatcher() {
    yield* takeLatest(newPasswordAfterResetting.initType, newPasswordAfterResettingSaga);
}

function* newPasswordCodeCheckingSaga(action: ReturnType<typeof newPasswordCodeChecking.init>) {
    try {
        yield* call(usersApi.newPasswordCodeChecking, action.payload);
        yield* put(newPasswordCodeChecking.success());
    } catch (e) {
        yield* call(navigateSaga, routes.AUTH.RESET_FAILED);
    }
}

export function* newPasswordCodeCheckingSagaWatcher() {
    yield* takeLatest(newPasswordCodeChecking.initType, newPasswordCodeCheckingSaga);
}

export default [
    passwordResettingSagaWatcher,
    newPasswordAfterResettingSagaWatcher,
    newPasswordCodeCheckingSagaWatcher,
];

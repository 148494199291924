import { setEditLogoClientId } from 'modules/settings/submodules/clients/components/EditClientLogo/store/actions';
import React, { useCallback } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from 'modules/settings/submodules/components/DashboardLink/styles';
import { useDispatch, useSelector } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';

export const EditClientLogoDashboardLink = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const openEditLogo = useCallback(() => {
        dispatch(setEditLogoClientId(clientId));
    }, [dispatch, clientId]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableClientLogoUpdate} noPlaceholder>
            <Grid
                item
                sm={4}
                xs={12}
            >
                <Box className={classes.block} onClick={openEditLogo}>
                    <Typography className={classes.heading}>Logo</Typography>
                    <Typography variant="body2">Edit client logo</Typography>
                </Box>
            </Grid>
        </FeatureSwitch>
    );
};

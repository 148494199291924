import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    defaultUserAccountValues, IUserAccountFormValues,
} from 'modules/profile/store/profileModel';
import { passwordChangeValidationSchema } from './form/passwordChangeValidationSchema';
import { UserAccountForm } from 'modules/profile/form/UserAccountForm';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { newPasswordAfterResetting } from 'store/components/resetPassword/actions';
import { selectResettingPasswordNewPassword, selectResettingPasswordNewPasswordIsLoading } from 'store/components/resetPassword/selectors';
import { ISystemState } from 'shared/models/Global';
import { Nullable } from '../../@types/types';

const AccountContent = () => {
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const initialValues = {
        ...defaultUserAccountValues,
        email: currentUser?.email || defaultUserAccountValues.email,
        current_password: defaultUserAccountValues.current_password,
        new_password: defaultUserAccountValues.new_password,
    };

    const isSubmitting = useSelector(selectResettingPasswordNewPasswordIsLoading);
    const [formHelp, setFormHelp] = useState<Nullable<ISystemState>>(null);
    const userResult = useSelector(selectResettingPasswordNewPassword);

    useEffect(() => {
        setFormHelp(userResult);
    }, [userResult]);

    const onChange = useCallback(() => {
        setFormHelp(null);
    }, [setFormHelp]);

    const onSubmitUpdateForm = useCallback((values: IUserAccountFormValues) => {
        dispatch(newPasswordAfterResetting.init({
            password: values.new_password,
            email: values.email,
            current_password: values.current_password,
        }));
    }, [dispatch]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={passwordChangeValidationSchema}
            onSubmit={onSubmitUpdateForm}
            validateOnBlur={false}
        >
            {props => (
                <UserAccountForm
                    {...props}
                    helpText={formHelp ? formHelp.message : ''}
                    hasError={Boolean(formHelp && formHelp.isError)}
                    onChange={onChange}
                    isSubmitting={isSubmitting}
                />
            )}
        </Formik>
    );
};

export default AccountContent;

import { setEditEntryId } from 'modules/timeAndExpense/components/EditEntry/store/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { IWithUserInfo } from 'shared/models/Authentication';
import { IEntity } from 'shared/models/Entity';
import { IEntryCommonBackend } from 'shared/models/sheet/Sheet';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { IUserSelfInfo, Permission } from 'store/components/auth/authModels';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IStore } from 'store/configureStore';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { ICreateEntryParams } from 'store/entities/timesheet/models/Entry';
import { ActionCreatorKnownArgs } from 'store/utils';
import { all, call, put, select } from 'typed-redux-saga';

export function* sagaSelectUserId() {
    const { id } = (yield* select(selectCurrentUser)) as IUserSelfInfo;
    return id;
}

export type SelectEntryById = (id: string) => (state: IStore) => IEntryCommonBackend;

export function* fetchSheets(sheetIds: Array<string>) {
    const payload = {
        purpose: Permission.SubmitSheets,
        request: { sheet_ids: sheetIds },
    };
    yield* all([
        put(loadExpenseSheetsWithEntries.init(payload)),
        put(loadTimeSheetsWithEntries.init(payload)),
    ]);
}

export function createAddEntrySaga<CreateEntryPayload, EntryType extends IEntryCommonBackend>(
    createEntryApi: (entryToCreate: CreateEntryPayload & IWithUserInfo, params: ICreateEntryParams) =>
    Promise<EntryType>,
    successAction: ActionCreatorKnownArgs<EntryType, { type: string; payload: EntryType }>,
): (action: any) => Generator {
    return function* (action: { payload: CreateEntryPayload & ICreateEntryParams }){
        const user_id = yield* sagaSelectUserId();
        const { status_id, ...entry } = action.payload;
        const entryRequest = {
            user_id,
            ...entry,
        } as CreateEntryPayload & IWithUserInfo;
        const params = { status_id };
        let createdEntry;

        try {
            createdEntry = yield* call(createEntryApi, entryRequest, params);
        } catch (e) {
            if (e?.response?.status === 409) {
                yield* put(setGlobalToast(
                    {
                        severity: IModalSeverity.Error,
                        title: e.response.data?.error?.message || e.response.data?.error?.data?.message,
                    },
                ));
                return;
            } else {
                logErrorWithCustomMessage(e, `Cannot create entry`);
                throw e;
            }
        }

        yield all([
            call(fetchSheets, [createdEntry.sheet_id]),
            put(successAction(createdEntry)),
        ]);
    };
}

export function createUpdateEntrySaga<UpdateActionPayload, EntryType extends IEntryCommonBackend>(
    updateEntryApi: (id: string, entryToCreate: UpdateActionPayload & IWithUserInfo) => Promise<EntryType>,
    successAction: ActionCreatorKnownArgs<EntryType, { type: string; payload: EntryType }>,
    selectEntry: SelectEntryById,
): (action: any) => Generator {
    return function* ({ payload: entry }: { payload: UpdateActionPayload & IEntity }) {
        const user_id = yield* sagaSelectUserId();
        const { id, ...entryWithoutId } = entry;
        const { sheet_id: originalSheetId } = yield* select(selectEntry(id));

        const entryToUpdate: UpdateActionPayload & IWithUserInfo = {
            user_id,
            // We need to cast to unknown because & IEntity and Omit doesn't equal to original type
            ...entryWithoutId as unknown as UpdateActionPayload,
        };

        const updatedEntry = yield* call(updateEntryApi, id, entryToUpdate);
        yield all([
            call(fetchSheets, [updatedEntry.sheet_id, originalSheetId]),
            put(successAction(updatedEntry)),
            put(setEditEntryId(null)),
        ]);
    };
}

export function createDeleteEntrySaga(
    removeEntryApi: (entryId: string) => void,
    successAction: ActionCreatorKnownArgs<string, { type: string; payload: string }>,
    selectEntryById: SelectEntryById,
): (action: any) => Generator {
    return function* ({ payload: entryId }: { payload: string }) {
        const { sheet_id: originalSheetId } = yield* select(selectEntryById(entryId));

        yield* call(removeEntryApi, entryId);

        yield all([
            call(fetchSheets, [originalSheetId]),
            put(successAction(entryId)),
        ]);
    };
}

import { routes } from 'shared/routes';

export const settingsRoutes = {
    ROOT: routes.SETTINGS,
    CLIENTS: {
        ROOT: `${routes.SETTINGS}/clients`,
        DASHBOARD: `${routes.SETTINGS}/clients/:clientId`,
        GENERAL: `${routes.SETTINGS}/clients/:clientId/general`,
        CONTROLLING_ORG: `${routes.SETTINGS}/clients/:clientId/controlling-org`,
        SUBMITTING_ORG: `${routes.SETTINGS}/clients/:clientId/submitting-org`,
        COST_CENTERS: `${routes.SETTINGS}/clients/:clientId/cost-centers`,
        DEALS: `${routes.SETTINGS}/clients/:clientId/deals`,
        JOB_NUMBER: `${routes.SETTINGS}/clients/:clientId/job-number`,
        POSITION: `${routes.SETTINGS}/clients/:clientId/positions`,
        LOCATION: `${routes.SETTINGS}/clients/:clientId/locations`,
        POSITION_LOCATION_ASSOCIATIONS: `${routes.SETTINGS}/clients/:clientId/positions-locations`,
        TIMESHEET: `${routes.SETTINGS}/clients/:clientId/timesheet`,
        EXPENSE: `${routes.SETTINGS}/clients/:clientId/expense`,
        PAYCODES: `${routes.SETTINGS}/clients/:clientId/paycodes`,
        PAY_SETTINGS: `${routes.SETTINGS}/clients/:clientId/pay-settings`,
        ACTIVITIES: `${routes.SETTINGS}/clients/:clientId/activities`,
        PHYSICAL_DEMANDS: `${routes.SETTINGS}/clients/:clientId/physical-demands`,
        WORKING_CONDITIONS: `${routes.SETTINGS}/clients/:clientId/working-conditions`,
        BACKGROUND_CHECKS: `${routes.SETTINGS}/clients/:clientId/background-checks`,
        DEPARTMENTS: `${routes.SETTINGS}/clients/:clientId/departments`,
        APP_CONFIGURATION: `${routes.SETTINGS}/clients/:clientId/app-configuration`,
    },
    PLATFORM_USERS: {
        ROOT: `${routes.SETTINGS}/platform-users`,
        USER_ROLES: `${routes.SETTINGS}/platform-users/user-roles`,
    },
    EMPLOYEES: {
        ROOT: `${routes.SETTINGS}/employees`,
    },
    OFFER_LETTERS: {
        TEMPLATES: `${routes.SETTINGS}/offer-letters/templates`,
    },
    CUSTOM_FIELDS: {
        ROOT: `${routes.SETTINGS}/custom-fields`,
    },
};

import { clients } from 'modules/settings/submodules/clients/reducer';
import { combineReducers } from 'redux';
import {
    ClientSettingsAction,
    setClientStatuses,
} from 'modules/settings/submodules/clients/store/actions';
import { IClientStatuses } from 'modules/settings/submodules/clients/store/models';

export const initialState = {
    currentClientId: '',
    clientStatuses: [],
    clientManagers: [],
    clientPayrollRep: [],
};

function clientStatuses(
    state: IClientStatuses[] = initialState.clientStatuses, action: ClientSettingsAction,
): IClientStatuses[] {
    switch (action.type) {
        case setClientStatuses.successType:
            return action.payload;
        default:
            return state;
    }
}

export const clientsSettings = combineReducers({
    clientStatuses,
    clients,
});

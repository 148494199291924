import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { TotalBlock } from 'shared/components/sidebars/SheetDetail/TotalBlock';
import { IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { DetailSlugs, IDetailConfiguration } from 'store/entities/clients/clientsModel';
import SheetInfo from './SheetInfo';
import { useSheetDetailStyles } from './styles';
import SheetDetailFilters from 'shared/components/sidebars/SheetDetail/SheetDetailFilters';
import { useEntriesByDayArray } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import EntriesTable from 'shared/components/table/EntriesTable/EntriesTable';
import { useEntriesCells } from 'modules/clients/content/supervisor/entriesCells';
import SheetStatus from 'shared/components/table/Cells/SheetStatus';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/clientsSelectors';

interface ISheetDetailContentProps {
    sheet: ISheet;
    entries: Array<IEntry>;
    isLoading: boolean;
    sheetAvailableActions?: JSX.Element | null;
    configuration: IDetailConfiguration<DetailSlugs>[];
}

export default function SheetDetailContent({
    sheet, entries, isLoading, sheetAvailableActions, configuration,
}: ISheetDetailContentProps) {
    const classes = useSheetDetailStyles();
    const [assignmentFilter, setAssignmentFilter] = useState('');
    const [activityFilter, setActivityFilter] = useState('');
    const [taskFilter, setTaskFilter] = useState('');
    const entryType = sheet.entry_type;

    const clientId = useSelector(selectCurrentClientId) ?? '';
    const clientConfiguration = useSelector(selectFieldConfiguration(clientId));

    const hasFilters = !!clientConfiguration?.filters?.sheet_details;

    const filteredEntries = entries.filter(entry => {
        if (assignmentFilter && entry.assignment_id !== assignmentFilter) {
            return false;
        }
        if (activityFilter && entry.activity_id !== activityFilter) {
            return false;
        }
        return !(taskFilter && entry.task_id !== taskFilter);
    });

    const filteredEntriesByDay = useEntriesByDayArray(filteredEntries);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const cells = useEntriesCells(isMobile, entryType);

    return (
        <>
            <Box className={clsx(classes.infoSection, { [classes.noFilters]: !hasFilters })} component="section">
                <SheetInfo
                    sheetId={sheet.id}
                    configuration={configuration}
                />
                <Box className={classes.statusWrapper}>
                    <SheetStatus sheet={sheet} className={classes.statusDisplay}/>
                </Box>
                <Box display="flex" className={classes.actionAndTotalsContainer}>
                    <Box className={classes.buttonWrapper}>
                        {sheetAvailableActions}
                    </Box>
                    <Box className={classes.totalsWrapper}>
                        <TotalBlock
                            sheet={sheet}
                            entries={filteredEntries}
                            entryType={entryType}
                        />
                    </Box>
                </Box>
                {hasFilters && (
                    <Box className={classes.filtersWrapper}>
                        <SheetDetailFilters entries={entries}
                            entryType={entryType}
                            assignment={assignmentFilter}
                            setAssignment={setAssignmentFilter}
                            activity={activityFilter}
                            setActivity={setActivityFilter}
                            task={taskFilter}
                            setTask={setTaskFilter}
                        />
                    </Box>
                )}
            </Box>
            {filteredEntriesByDay.map((dayEntries, index) => {
                if (isLoading || dayEntries.length > 0) {
                    return (
                        <EntriesTable
                            key={dayEntries[0]?.entry_date || index}
                            date={dayEntries[0]?.entry_date || ''}
                            entries={dayEntries}
                            cells={cells}
                            hideHeader={isMobile}
                            entryFilter={entryType}
                            isLoading={isLoading}
                        />
                    );
                }
                return null;
            })}
        </>
    );
}

import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { PaySettingsFormWrapper } from 'modules/settings/submodules/clients/paySettings/components/PaySettingsFormWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getPaySettings } from 'store/entities/clients/clientsAction';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { Box } from '@material-ui/core';
import { SyncPaySettingsButton } from 'modules/settings/submodules/clients/paySettings/components/SyncPaySettings/SyncPaySettingsButton';
import { useFeature } from '@optimizely/react-sdk';

export const PaySettingsPage = () => {

    const clientId = useSelector(selectCurrentClientId);

    const dispatch = useDispatch();
    useEffect(() => {
        if (clientId) {
            dispatch(getPaySettings.init(clientId));
        }
    }, [dispatch, clientId]);

    const [displaySyncPaySettingsButton] = useFeature(FeatureSwitches.displaySyncPaySettingsButton);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableManagementOfTimeAndPaySettings}>
            <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
            >
                <Alert severity="warning">
                    Pay setting changes will apply to future timesheets only.
                </Alert>
                <Box>
                    {displaySyncPaySettingsButton && (<SyncPaySettingsButton/>)}
                </Box>
            </Box>
            <PaySettingsFormWrapper/>
        </FeatureSwitch>
    );
};

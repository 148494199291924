import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import { Theme } from '@material-ui/core';

export const useUserHeaderStyles = makeHighPriorityStyles((theme: Theme) => ({
    profileMenu: {
        border: `1px solid ${colors.black}`,
    },
    divider: { 
        alignSelf: 'center',
        backgroundColor: colors.lightGray,
        width: '100%',
    },
    Title: {
        fontSize: theme.typography.h5.fontSize,
        fontWeight: theme.typography.fontWeightBold,

        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        },
        color: theme.palette.primary.main,
        padding: theme.spacing(0.5, 0, 1.5, 2),
    },
}));

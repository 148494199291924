import React from 'react';
import { List } from '@material-ui/core';
import { SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { useSheetDetail } from 'shared/utils/formatters/sheetDetail';
import { DetailSlugs, IDetailConfiguration } from 'store/entities/clients/clientsModel';
import { useSheetDetailStyles } from './styles';

interface ISheetInfoProps {
    sheetId: string;
    configuration: IDetailConfiguration<DetailSlugs>[];
}

export default function SheetInfo({ sheetId, configuration }: ISheetInfoProps) {
    const classes = useSheetDetailStyles();
    const data = useSheetDetail(sheetId, configuration);
    return (
        <List className={classes.listWrapper}>
            {data.map(item => (
                <>
                    {item.value && (
                        <SheetInfoItem key={item.title} data={item}/>
                    )}
                </>
            ))}
        </List>
    );
}

import React, { useCallback, useEffect } from 'react';
import { FormikProps } from 'formik';
import { isEqual, omit, toString, isNumber } from 'lodash';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import NumberField from 'shared/components/formFields/NumberField';
import ClientSelect from 'shared/components/selects/ClientSelect';
import { pphFilterDefaultValues } from 'modules/payrollProcessorHub/store/reducer';
import { DealNumberSelect } from 'shared/components/selects/DealNumberSelect';
import { Permission } from 'store/components/auth/authModels';
import { IPayrollProcessorFilters } from 'modules/payrollProcessorHub/store/model';
import PayrollPayPeriodSelect from 'modules/payrollProcessorHub/components/PayPeriodSelect/PayrollPayPeriodSelect';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { debounce } from 'ts-debounce';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useHeaderFormStyles, useStyles } from './styles';

export interface IPayrollFilterFormProps extends FormikProps<IPayrollProcessorFilters> {
    onChange: (value: Partial<IPayrollProcessorFilters>) => void;
    actualFilterValues: IPayrollProcessorFilters;
    isDashboard?: boolean;
    clientHasJobNumberConfiguration?: boolean;
}

export const PayrollFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
    setFieldValue,
    isDashboard = false,
    clientHasJobNumberConfiguration = false,
}: IPayrollFilterFormProps) => {
    const headerFormClasses = useHeaderFormStyles();
    const formClasses = useStyles();
    const classes = isDashboard ? headerFormClasses : formClasses;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFilterChange = useCallback(debounce(
        (newValues: IPayrollProcessorFilters) => {
            onChange({
                clientId: newValues.clientId || pphFilterDefaultValues.clientId,
                payPeriodEnd: newValues.payPeriodEnd || pphFilterDefaultValues.payPeriodEnd,
                employeeId: newValues.employeeId || pphFilterDefaultValues.employeeId,
                assignmentId: newValues.assignmentId || pphFilterDefaultValues.assignmentId,
                payrollProcessorUserId: newValues.payrollProcessorUserId
                    || pphFilterDefaultValues.payrollProcessorUserId,
                dealId: newValues.dealId || pphFilterDefaultValues.dealId,
                jobNumber: newValues.jobNumber !== '' ? toString(newValues.jobNumber) : pphFilterDefaultValues.jobNumber,
                managerId: newValues.managerId || pphFilterDefaultValues.managerId,
            });
        },
        300,
    ), [onChange]);
    useEffect(() => {
        if (!isEqual(omit(values, 'status'), omit(actualFilterValues, 'status'))) {
            onFilterChange(values);
        }
    }, [actualFilterValues, values, onChange, onFilterChange]);
    useEffect(() => {
        const { dealId, jobNumber } = values;
        if (isNumber(jobNumber)) {
            // Reset job number value after entering negative value
            setFieldValue('jobNumber', pphFilterDefaultValues.jobNumber);
        }
        if ((!clientHasJobNumberConfiguration || isDashboard) && (dealId || jobNumber)) {
            setFieldValue('dealId', pphFilterDefaultValues.dealId);
            setFieldValue('jobNumber', pphFilterDefaultValues.jobNumber);
        }
    }, [clientHasJobNumberConfiguration, values, setFieldValue, isDashboard]);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            {!process.env.REACT_APP_CLIENT_ID && (
                <ClientSelect
                    name="clientId"
                    label="Client"
                    useIdValue
                    className={classes.input}
                />
            )}
            <PayrollPayPeriodSelect
                name="payPeriodEnd"
                status={actualFilterValues.status}
                useIdValue
                className={classes.input}
            />
            {isDashboard ? (
                <UserAutocompleteWithSearch
                    additionalFilter={{
                        purpose: Permission.payrollProcessing,
                        client_id: values.clientId || undefined,
                    }}
                    name="payrollProcessorUserId"
                    label="Payroll Processor"
                    useIdValue
                    className={classes.input}
                />
            ) : (
                <UserAutocompleteWithSearch
                    additionalFilter={{
                        purpose: Permission.SubmitSheets,
                        client_id: values.clientId || undefined,
                    }}
                    name="employeeId"
                    label="Find an employee"
                    useIdValue
                    className={classes.input}
                />
            )}
            {!isDashboard && (
                <FeatureSwitch feature={FeatureSwitches.enablePPHFilterByManager} noPlaceholder>
                    <UserAutocompleteWithSearch
                        additionalFilter={{
                            purpose: Permission.ApproveSheets,
                            client_id: values.clientId || undefined,
                        }}
                        name="managerId"
                        label="Search manager"
                        useIdValue
                        className={classes.input}
                    />
                </FeatureSwitch>
            )}
            {clientHasJobNumberConfiguration && !isDashboard && (
                <>
                    <DealNumberSelect
                        name="dealId"
                        label="Deal Number"
                        useIdValue
                        className={classes.input}
                    />
                    <NumberField
                        name="jobNumber"
                        label="Job Number"
                        className={classes.input}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                    />
                </>
            )}
        </form>
    );
};

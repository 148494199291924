import React, { useMemo } from 'react';
import { debounce } from 'ts-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';
import { useOfferLetterHeaderStyles } from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterHeaderStyles';

export default function OfferLetterSearch() {
    const classes = useOfferLetterHeaderStyles();
    const dispatch = useDispatch();
    const filter = useSelector(selectOfferLetterFilters);
    const debounceTimeout = 300;
    const changeSearch = useMemo(() =>
        debounce(
            (search: string) => {
                const minSearchLength = 2;
                if (!search || search.length > minSearchLength) {
                    dispatch(setOfferLetterFilters({
                        ...filter,
                        search,
                    }));
                }
            },
            debounceTimeout,
        ), [dispatch, filter],
    );
    return (
        <TextFilter
            data-testid="offer-letter-search"
            className={classes.search}
            onFilterValueChanged={changeSearch}
        />
    );
}

import { StatusNames } from 'store/entities/timesheet/models/Status';
import { pluralize } from 'shared/utils/formatters/pluralize';

interface IFlags {
    time: boolean;
    expense: boolean;
}

export function sheetStatusMessage(amount: number, flags: IFlags, statusName: StatusNames, isSuccess = true) {
    let typeString = '';

    if (flags.time && flags.expense) {
        typeString = pluralize('time and expense sheet', amount);
    } else if (flags.time) {
        typeString = pluralize('timesheet', amount);
    } else if (flags.expense) {
        typeString = pluralize('expense sheet', amount);
    }

    return `${amount} ${typeString} ${pluralize('was', amount, 'were')} ${isSuccess ? 'successfully' : 'not'} ${statusName.toLowerCase()}`;
}

import { getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { sheetsApi } from 'store/entities/timesheet/api/sheetsApi';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, select, takeEvery, takeLatest,
} from 'typed-redux-saga';
import { EntryType } from 'shared/models/sheet/Sheet';
import { payrollApi } from 'store/entities/timesheet/api/payrollApi';
import { loadExpenseSheets, removeExpenseEntry } from '../actions/expenseActions';
import { getSimpleSheets, quickRemoveEntry } from '../actions/sheetState';
import { loadTimeSheets, removeTimeEntry } from '../actions/timeActions';
import { selectEntry } from '../selectors';
import { Permission } from 'store/components/auth/authModels';
import { IExpenseSheetCalculation, ITimesheetCalculation, CalculationSheetType, MixedCalculations } from 'store/entities/timesheet/models/Calculation';
import { getExpenseSheetCalculations, getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';

function* loadSimpleSheetsSaga() {
    /**
     * Load sheets without entries for T&E submitted/recalled/approved/rejected/all tabs
     */
    yield* all([
        put(loadTimeSheets.init({ purpose: Permission.SubmitSheets } )),
        put(loadExpenseSheets.init({ purpose: Permission.SubmitSheets })),
    ]);
}

export function* loadSimpleSheetsWatcher() {
    yield takeLatest(getSimpleSheets.initType, loadSimpleSheetsSaga);
}

function* removeEntrySaga(action: ReturnType<typeof quickRemoveEntry.init>) {
    const entryId = action.payload;
    const entry = yield* select(selectEntry(entryId));

    if (entry?.entry_type === EntryType.TIME) {
        yield* put(removeTimeEntry.init(entryId));
    }
    if (entry?.entry_type === EntryType.EXPENSE) {
        yield* put(removeExpenseEntry.init(entryId));
    }
}

export function* removeEntryWatcher() {
    yield takeLatest(quickRemoveEntry.initType, removeEntrySaga);
}

function* getSheetCalculationsSaga({ payload }: ReturnType<typeof getTimesheetCalculations.init>) {
    const { calculations } = yield call(payrollApi.getSheetCalculations, payload);
    const timeCalculations: ITimesheetCalculation[] = [];
    const expenseCalculations: IExpenseSheetCalculation[] = [];
    calculations.forEach((calculation: MixedCalculations) => {
        if (calculation.sheet_type === CalculationSheetType.Time) {
            timeCalculations.push(calculation);
        } else {
            expenseCalculations.push(calculation);
        }
    });
    if (timeCalculations.length) {
        yield put(getTimesheetCalculations.success(timeCalculations));
    }
    if (expenseCalculations.length) {
        yield put(getExpenseSheetCalculations.success(expenseCalculations));
    }
}

function* getSheetCalculationsWatcher() {
    yield takeEvery(
        getTimesheetCalculations.initType,
        withBackendErrorHandler(
            getSheetCalculationsSaga,
            getTimesheetCalculations.error,
            `Unable to fetch sheet calculations`,
        ),
    );
}

export const getSheetsPayPeriodsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSheetsPayPeriod,
    sheetsApi.getSheetsPayPeriods,
    'pay periods',
);

export default [
    loadSimpleSheetsWatcher,
    removeEntryWatcher,
    getSheetCalculationsWatcher,
    getSheetsPayPeriodsWatcher,
];

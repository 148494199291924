import { DateBackend } from 'shared/models/Dates';
import { INamedEntity } from 'shared/models/Entity';
import { Status } from 'shared/models/User';

export interface IOfferLetterTemplate extends INamedEntity{
    status: Status;
    createdAt: DateBackend;
    filename: string;
}

export interface IOfferLetterTemplateBackend extends INamedEntity{
    status: Status;
    created_at: DateBackend;
    updated_at: DateBackend;
    filename: string;
}

export const mapOfferLetterTemplateFromBackend = ({
    id,
    name,
    status,
    ...offerLetterTemplate
}: IOfferLetterTemplateBackend) => {
    return {
        id,
        name,
        status,
        createdAt: offerLetterTemplate.created_at,
        filename: offerLetterTemplate.filename,
    };
};

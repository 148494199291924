import clsx from 'clsx';
import { LoginAsUserButton } from 'modules/settings/common/components/LoginAsUserButton';
import moment from 'moment';
import React, { useMemo } from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { shortDateFormat } from 'shared/models/Dates';
import { IUserInfo } from 'shared/models/User';

export interface IUserRow extends IUserInfo {
    className?: string;
}

export const useUserCellDictionary = (): Record<string, ICellInfo<IUserRow>> => {
    const tableClasses = useDefaultTableStyles();
    return useMemo(() => ({
        id: {
            key: 'id',
            title: 'ID',
            width: '2.2fr',
            render: function IdCell({ id, className }: IUserRow) {
                return (
                    <PlainText className={className} value={id}/>
                );
            },
        },
        name: {
            key: 'name',
            title: 'Name',
            render: function NameCell({ first_name, last_name, className }: IUserRow){
                return (
                    <PlainText className={className} value={`${first_name} ${last_name}`}/>
                );
            },
        },
        created: {
            key: 'created',
            title: 'created',
            render: function CreatedCell({ created_at, className }: IUserRow){
                return (
                    <PlainText className={className} value={moment(created_at).format(shortDateFormat)}/>
                );
            },
        },
        updated: {
            key: 'updated',
            title: 'updated',
            render: function UpdatedCell({ updated_at, className }: IUserRow){
                return (
                    <PlainText className={className} value={moment(updated_at).format(shortDateFormat)}/>
                );
            },
        },
        statusReadonly: {
            key: 'status',
            title: 'status',
            render: function StatusCell({ status, className }: IUserRow) {
                return (
                    <PlainText className={className} value={status}/>
                );
            },
        },
        state: {
            key: 'state',
            title: 'state',
            render: function StateCell({ state, className }: IUserRow) {
                return (
                    <PlainText className={className} value={state}/>
                );
            },
        },
        actions: {
            key: 'actions',
            title: '',
            width: '54px',
            render: function ActionCell({ className, id }: IUserRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <LoginAsUserButton userId={id}/>
                    </div>
                );
            },
        },
    }), [tableClasses]);
};

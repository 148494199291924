/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICostCenter } from 'shared/models/CostCenters';
import { useIsMobile } from 'shared/utils/hooks/media';
import { selectCostCentersIsLoading } from 'store/entities/configuration/configurationSelectors';
import { selectFilteredCostCenters } from 'modules/settings/submodules/clients/costCenters/components/CostCenterTable/store/filteredCostCentersSelector';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { setEditCostCenterId } from 'modules/settings/submodules/clients/costCenters/components/EditCostCenter/store/actions';

interface ICostCenterRow extends ICostCenter {
    className?: string;
}

export const CostCenterTable = () => {
    const tableClasses = useDefaultTableStyles();
    const costCenters = useSelector(selectFilteredCostCenters);
    const isMobile = useIsMobile();
    const isLoading = useSelector(selectCostCentersIsLoading);

    const cells = [
        {
            key: 'number',
            title: 'number',
            width: '1fr',
            render: ({ number, className }: ICostCenterRow) => (
                <PlainText className={className} value={number.toString()}/>
            ),
        },
        {
            key: 'description',
            title: 'description',
            width: '3fr',
            render: ({ description, className }: ICostCenterRow) => (
                <PlainText className={className} value={description}/>
            ),
        },
        {
            key: 'actions',
            title: '',
            width: '54px',
            render: function DetailsCell({ className, id }: ICostCenterRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <EditItemButton
                            id={id}
                            editAction={setEditCostCenterId}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <GridTable
            rowData={costCenters}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={row => row.id}
            cells={cells}
            stickyHeader
            hideHeader={isMobile}
            isLoading={isLoading}
        />
    );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { IDeal } from 'shared/models/Deal';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { DealNumberSelect } from 'shared/components/selects/DealNumberSelect';
import { LabelWithValue } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/components/LabelWithValue';
import { selectSubmittingOrgById } from 'store/entities/configuration/configurationSelectors';
import { selectContractClientDisplayValue } from 'modules/settings/submodules/clients/jobNumber/store/selectors';

interface IDealFormSectionProps {
    isEdit?: boolean;
    jobNumber?: number;
    dealReadonly?: boolean;
    deal?: IDeal | null;
}

export const DealFormSection = ({
    deal,
    jobNumber,
    isEdit = false,
    dealReadonly = false,
}: IDealFormSectionProps) => {
    const classes = useSettingsFormStyles();
    const submittingOrgs = useSelector(selectSubmittingOrgById(deal?.submitting_org_id));
    const contractClient = useSelector(selectContractClientDisplayValue(deal));
    return (
        <Box
            display="flex"
            flexDirection="row"
        >
            <Box width="50%">
                <Box ml={2}>
                    {dealReadonly && (<LabelWithValue label="Deal Number" value={deal?.deal_number?.toString()}/>)}
                    {isEdit && jobNumber && <LabelWithValue label="Job Number" value={jobNumber.toString()}/>}
                </Box>
                {!dealReadonly && (
                    <DealNumberSelect
                        name="deal"
                        label="--"
                        outerLabel="Deal Number"
                        className={classes.input}
                    />
                )}
            </Box>
            <Box width="50%">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                    ml={2}
                >
                    <LabelWithValue label="Contract Client" value={contractClient}/>
                    <LabelWithValue label="Jobsite" value={submittingOrgs?.client_site_name}/>
                </Box>
            </Box>
        </Box>
    );
};

import { AccessTime, DescriptionOutlined, GetAppOutlined } from '@material-ui/icons';
import { IMenuCategory, TMenuCategoryChild } from 'shared/models/Global';
import { Permission } from 'store/components/auth/authModels';
import { FeatureSwitches } from 'utils/featureSwitches';
import { routes } from 'shared/routes';
import { IGetClientMenuParams } from './typings';
import { getClientMenu as getClientMenuDefault } from './verticalMenu';
import { MenuItemEnum } from 'shared/models/MenuItem';

export function getClientMenu({ clientId }: IGetClientMenuParams): Array<IMenuCategory> {

    const clientMenu = getClientMenuDefault({ clientId });
    const defaultClientMenu = clientMenu.find(({ id }) => id === 'Menu')?.children;
    // Remove dashboard link
    const defaultMenu = defaultClientMenu?.filter(({ id }) => id !== MenuItemEnum.Dashboard);

    const permittedTabs: TMenuCategoryChild[] = [
        {
            title: MenuItemEnum.MyOfferLetter,
            Icon: DescriptionOutlined,
            to: routes.EMPLOYEE_OFFER_LETTER.ROOT,
            feature: FeatureSwitches.enableOfferLetters,
            permission: Permission.ViewMyOffers,
        },
        ...defaultMenu,
    ];

    return [
        {
            id: 'Menu',
            children: permittedTabs,
        },
        {
            id: 'Payroll',
            title: 'Payroll',
            permission: Permission.PPHReadAccess,
            children: [
                {
                    title: MenuItemEnum.PayrollDashboard,
                    Icon: GetAppOutlined,
                    to: routes.PAYROLL_PROCESSOR_HUB.ROOT,
                    isExact: true,
                },
                {
                    title: MenuItemEnum.TimeExpensesManagement,
                    Icon: AccessTime,
                    to: routes.PAYROLL_PROCESSOR_HUB.SHEETS,
                },
            ],
        },
    ];
}

import React, { FC, SyntheticEvent } from 'react';
import { Box } from '@material-ui/core';
import { IDashboardProps } from 'shared/models/Dashboard';
import { useStyles } from './styles';
import { DashboardItemContent } from './DashboardItemContent';
import clsx from 'clsx';

const Dashboard: FC<IDashboardProps> = ({ data, isLoading = false }: IDashboardProps) => {
    const classes = useStyles();
    return (
        <Box
            display="flex"
            flexWrap="wrap"
            pl={7}
            pr={3}
        >
            {data.map(itemData => {
                const content = <DashboardItemContent {...itemData} isLoading={isLoading}/>;
                const { link, title } = itemData;
                const onLinkClick = (event: SyntheticEvent) => {
                    event.preventDefault();
                    typeof link === 'function' && link();
                };

                if (typeof link === 'function') {
                    return (
                        <button
                            key={`${title}-dashboard`}
                            className={clsx(classes.CurrentBox, classes.dashItemButton)}
                            onClick={onLinkClick}
                        >
                            {content}
                        </button>
                    );
                }
                return (
                    <Box key={`${title}-dashboard`} className={classes.CurrentBox}>{content}</Box>
                );
            })}
        </Box>
    );
};

export default Dashboard;

import React, { useMemo } from 'react';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';
import StatusCell from 'shared/components/table/Cells/StatusCell';
import { IOfferLetter, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';

interface ISheetStatusProps {
    className?: string;
    offerLetter: IOfferLetter;
}

export default function OfferLetterStatus({ className, offerLetter }: ISheetStatusProps){
    const classes = useStatusStyles();

    const statusToColor: Record<string, string> = useMemo(() => ({
        [OfferLetterStatusSlug.Outstanding]: classes.blue,
        [OfferLetterStatusSlug.Declined]: classes.red,
        [OfferLetterStatusSlug.Accepted]: classes.lightGreen,
        [OfferLetterStatusSlug.Expired]: classes.lightGray,
        default: classes.orange,
    }), [classes]);

    const colorsClassName = statusToColor[offerLetter.status.slug ?? ''] || statusToColor.default;

    return (
        <StatusCell className={className} text={offerLetter.status.name ?? ''}
            statusClassName={colorsClassName}
        />
    );
}

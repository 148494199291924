import ClientsSagas from 'modules/settings/submodules/clients/store/sagas';
import PlatformUsersSagas from 'modules/settings/submodules/platformUsers/store/sagas';
import EmployeesSagas from 'modules/settings/submodules/employees/store/sagas';
import OfferLetterTemplatesSagas from 'modules/settings/submodules/offerLetters/store/sagas';

export default [
    ...ClientsSagas,
    ...PlatformUsersSagas,
    ...EmployeesSagas,
    ...OfferLetterTemplatesSagas,
];

import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import GridTableSkeleton from './GridTableSkeleton';
import GridTable, { IGridTableProps } from './GridTable';

export interface IInfinityGridTable {
    onLoadMore: (page: number) => void;
    hasMore: boolean;
}

export interface IInfinityGridTableProps<RowData> extends IGridTableProps<RowData>, IInfinityGridTable {

}

export default function InfinityGridTable<RowData>({
    onLoadMore,
    hasMore,
    ...gridTableData
}: IInfinityGridTableProps<RowData>) {

    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={onLoadMore}
            hasMore={hasMore}
            initialLoad={false}
            loader={<GridTableSkeleton key={0}/>}
        >
            <GridTable {...gridTableData}/>
        </InfiniteScroll>
    );
}

import React, { useEffect } from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import MainContent from 'shared/components/common/MainContent';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useDispatch } from 'react-redux';
import { loadGlobalRoles } from 'store/entities/users/actions';
import { Box } from '@material-ui/core';
import { EmployeeTable } from 'modules/settings/submodules/employees/components/UserListPage/EmployeeTable/EmployeeTable';
import EmployeeFilter from 'modules/settings/submodules/employees/components/UserListPage/Filter/EmployeeFilter';
import EmployeeChips from 'modules/settings/submodules/employees/components/UserListPage/FilterChips/EmployeeChips';
import { EmployeeNameFilter } from 'modules/settings/submodules/employees/components/UserListPage/Filter/EmployeeNameFilter';

export const EmployeeListPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadGlobalRoles.init());
    }, [dispatch]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableEmployeeManagement}>
            <MainContent whiteBackground>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    mb={2}
                >
                    <Box display="flex">
                        <EmployeeNameFilter/>
                        <EmployeeFilter/>
                    </Box>
                </Box>
                <EmployeeChips/>
                <EmployeeTable/>
            </MainContent>
        </FeatureSwitch>
    );
};

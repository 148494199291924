import UneditableFormFieldDecorator from '../FormFields/UneditableFormFieldDecorator';
import FormFieldBase, { IFormFieldProps } from 'shared/components/formFields/FormField';
import CorpTypeBase, { ICorpTypeProps } from 'shared/components/selects/CorpType';
import StatusesBase, { IStatusesProps } from 'shared/components/selects/Statuses';
import DayPickerFieldBase, { IDayPickerFieldProps } from 'shared/components/formFields/DayPickerField';
import SpecialUserSelectBase, { ISpecialUserSelectProps } from 'shared/components/selects/SpecialUserSelect';

export const FormField = UneditableFormFieldDecorator<IFormFieldProps>(FormFieldBase);
export const CorpType = UneditableFormFieldDecorator<ICorpTypeProps>(CorpTypeBase);
export const Statuses = UneditableFormFieldDecorator<IStatusesProps>(StatusesBase);
export const DayPickerField = UneditableFormFieldDecorator<IDayPickerFieldProps>(DayPickerFieldBase);
export const SpecialUserSelect = UneditableFormFieldDecorator<ISpecialUserSelectProps>(SpecialUserSelectBase);

import React, {
    useCallback,
    useState,
    ComponentType,
    SyntheticEvent,
    useEffect,
    useMemo,
} from 'react';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import { Schedule } from '@material-ui/icons';
import { Time12HoursKeyboardInput } from './Time12HoursKeyboardInput';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { TIME_FORMAT_FULL, BACKEND_DATE_TIME_FORMAT, CLOCK_TIME_FORMAT } from 'shared/models/DateTime';
import { TextFieldProps } from '@material-ui/core/TextField';
import { InputBaseComponentProps, ThemeProvider, useTheme } from '@material-ui/core';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput';
import clsx from 'clsx';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import baseTheme from 'baseTheme';

const useTime12HoursKeyboardPickerStyles = makeHighPriorityStyles((theme: Theme) => ({
    adornmentRoot: {
        marginLeft: 0,
    },
    adornmentRootUnFocused: {
        display: 'none',
    },
    iconButton: {
        fontSize: theme.typography.body2.fontSize,
    },
}));

export type Time12HoursKeyboardPickerStyles = Partial<ReturnType<typeof useTime12HoursKeyboardPickerStyles>>

export type Time12HoursValue = KeyboardTimePickerProps['value'];

interface ITime12HoursKeyboardPicker {
    name: string;
    label?: string;
    onChange?: (value: string) => void;
    value?: Time12HoursValue;
    InputFieldComponent?: ComponentType<TextFieldProps>;
    onBlur?: (evt: SyntheticEvent) => void;
    onFocus?: (evt: SyntheticEvent) => void;
    error?: boolean;
    helperText?: string;
    className?: string;
    inputProps?: InputBaseComponentProps;
    isFocussed?: boolean;
    InputProps?: Partial<OutlinedInputProps>;
    classes?: Time12HoursKeyboardPickerStyles;
}

export function Time12HoursKeyboardPicker({
    name,
    label,
    value = null,
    onChange,
    InputFieldComponent,
    onBlur,
    onFocus,
    error,
    className,
    inputProps,
    InputProps,
    isFocussed,
    helperText,
    classes,
}: ITime12HoursKeyboardPicker) {
    const pickerClasses = useTime12HoursKeyboardPickerStyles();

    const [localValue, setValue] = useState<Time12HoursValue>(value);
    const onChangeLocal = useCallback((date: MaterialUiPickersDate, changedValue?: string | null) => {
        const nextValue = moment(changedValue || '', CLOCK_TIME_FORMAT);
        if (date && nextValue.isValid()) {
            setValue(nextValue.format(BACKEND_DATE_TIME_FORMAT));
            if (onChange) {
                onChange(nextValue.format(TIME_FORMAT_FULL));
            }
        } else {
            if (onChange) {
                onChange('');
            }
        }
    }, [onChange]);

    useEffect(() => {
        setValue(value ? moment(value, TIME_FORMAT_FULL).format(BACKEND_DATE_TIME_FORMAT) : null);
    }, [value]);

    const TextFieldComponent = InputFieldComponent || Time12HoursKeyboardInput;
    const iconClasses = useMemo(() => ({
        root: clsx(pickerClasses.iconButton, classes?.iconButton),
    }), [pickerClasses.iconButton, classes?.iconButton]);

    const theme = useTheme();

    const textFieldComponentWithOriginalTheme = useMemo(
        () => function TextFieldComponentWithOriginalTheme(props: TextFieldProps) {
            return (
                <ThemeProvider theme={theme}>
                    <TextFieldComponent {...props}/>
                </ThemeProvider>
            );
        }, [TextFieldComponent, theme]);

    const inputValue = useMemo(() => {
        if (localValue) {
            if (moment(localValue).isValid()) {
                return moment(localValue).format(CLOCK_TIME_FORMAT);
            }
            if (moment(localValue, CLOCK_TIME_FORMAT).isValid()) {
                return moment(localValue, CLOCK_TIME_FORMAT).format(CLOCK_TIME_FORMAT);
            }
        }
        return undefined;
    }, [localValue]);

    return (
        <ThemeProvider theme={baseTheme}>
            <KeyboardTimePicker
                name={name}
                label={label}
                TextFieldComponent={textFieldComponentWithOriginalTheme}
                onChange={onChangeLocal}
                format={'h:mmA'}
                rifmFormatter={(val: string) => val}
                inputValue={inputValue}
                value={localValue}
                keyboardIcon={isFocussed && (
                    <Schedule fontSize="small" classes={iconClasses}/>
                )}
                onBlur={onBlur}
                onFocus={onFocus}
                error={error}
                helperText={helperText}
                className={className}
                inputProps={inputProps}
                InputProps={InputProps}
                InputAdornmentProps={{
                    classes: {
                        root: clsx(pickerClasses.adornmentRoot, {
                            [pickerClasses.adornmentRootUnFocused]: !isFocussed,
                        }),
                    },
                }}
                KeyboardButtonProps={{ tabIndex: -1 }}
            />
        </ThemeProvider>
    );
}

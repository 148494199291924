import { IJobNumberFormValues } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import { IDealType } from 'shared/models/DealType';
import { FeeType, TimesheetSettings } from 'shared/models/JobNumber';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

const moreThanZeroMessage = 'Should be more than 0';

const billingInfoRequiredCondition = (timesheetSettings?: TimesheetSettings, dealType?: IDealType) =>
    timesheetSettings === TimesheetSettings.Hourly && dealType?.billing_info_required;

const jobNumbersValuesSchema: Record<keyof IJobNumberFormValues, yup.Schema<any>> = {
    jobNumber: yup.number(),
    deal: yup.object().nullable().required(ValidationMessages.REQUIRED),
    dealType: yup.object(),
    jobDescription: yup.string().required(ValidationMessages.REQUIRED),
    startDate: yup.string().required(ValidationMessages.REQUIRED),
    endDate: yup.string(),
    managerId: yup.string().nullable().required(ValidationMessages.REQUIRED),
    userTypeId: yup.string().nullable().required(ValidationMessages.REQUIRED),

    uwSystem: yup.string().required(ValidationMessages.REQUIRED),
    fte: yup.string().required(ValidationMessages.REQUIRED),
    timesheetSettings: yup.string().required(ValidationMessages.REQUIRED),

    billingInfo: yup.string().when(['timesheetSettings', 'dealType'], {
        is: billingInfoRequiredCondition,
        then: yup.string().required(ValidationMessages.REQUIRED),
    }),
    max: yup.number().nullable().when(['timesheetSettings', 'dealType'], {
        is: billingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Daily,
            then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
        }),
    }),
    min: yup.number().nullable().when(['timesheetSettings', 'dealType'], {
        is: billingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Daily,
            then: yup.number()
                .min(0, moreThanZeroMessage)
                .required(ValidationMessages.REQUIRED)
                .when(
                    'max',
                    (max: number, schema: yup.NumberSchema) => schema.max(max, 'Min should be less than max'),
                ),
        }),
    }),
    miCredit: yup.number().nullable().when(['timesheetSettings', 'dealType'], {
        is: billingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Daily,
            then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
        }),
    }),
    blendedAmount: yup.number().nullable().when(['timesheetSettings', 'dealType'], {
        is: billingInfoRequiredCondition,
        then: yup.number().when('billingInfo', {
            is: FeeType.Blended,
            then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
        }),
    }),

    isPerDiem: yup.boolean(),
    hourlyRate: yup.number().nullable().when('timesheetSettings', {
        is: TimesheetSettings.Hourly,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    fileRate: yup.number().nullable().when('timesheetSettings', {
        is: TimesheetSettings.PerFile,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    perDiemRate: yup.number().nullable().when('isPerDiem', {
        is: true,
        then: yup.number().min(0, moreThanZeroMessage).required(ValidationMessages.REQUIRED),
    }),
    timeActivities: yup.array().min(1, 'Please select at least one activity'),

    reasonForClose: yup.string().nullable(),
    closeOnDate: yup.string().nullable(),
};

export const jobNumbersValidationSchema = yup.object().shape(jobNumbersValuesSchema);

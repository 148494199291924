/* eslint-disable react/display-name */
import React from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { IPosition } from 'shared/models/Position';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { selectPositions } from 'store/entities/configuration/configurationSelectors';

interface IPositionRow extends IPosition {
    className?: string;
}

export const PositionsTable = () => {
    const tableClasses = useDefaultTableStyles();
    const positions = useSelector(selectPositions);

    const positionsCells = [
        {
            key: 'code',
            title: 'code',
            render: ({ prism_position_id, className }: IPositionRow) => (
                <PlainText className={className} value={prism_position_id}/>
            ),
        },
        {
            key: 'position',
            title: 'position',
            render: ({ name, className }: IPositionRow) => (
                <PlainText className={className} value={name}/>
            ),
        },
        {
            key: 'description',
            title: 'description',
            render: ({ className, description }: IPositionRow) => (
                <PlainText className={className} value={description}/>
            ),
        },
        {
            key: 'classification',
            title: 'classification',
            render: ({ classification, className }: IPositionRow) => (
                <PlainText className={className} value={classification}/>
            ),
        },
    ];

    return (
        <GridTable
            rowData={positions}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={client => client.id}
            cells={positionsCells}
            stickyHeader
        />
    );
};

import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import React from 'react';
import { IMenuContext, TMenuState } from 'shared/models/Global';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { UserProfileTab } from 'modules/profile/store/profileModel';

const menuContext: IMenuContext = {
    menuState: {
        currentPage: '',
        currentHeader: '',
        currentMobileHeader: '',
        tabs: {
            timeEntryPage: StatusNames.WORKING,
            sheetApprovalPage: StatusNames.SUBMITTED,
            payroll: PayrollSheetTabs.APPROVED,
            userProfile: UserProfileTab.Profile,
        },
    },
    // Placeholder function that will be set later in order
    // to avoid Typescript requiring checking its presense
    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    setMenuState: (o: TMenuState) => {},
};

export const MenuContext = React.createContext(menuContext);

import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import { useSheetDetailStyles } from './styles';

export interface ISheetInfoItemData {
    title: string;
    value: string;
}

interface ISheetInfoItemProps {
    data: ISheetInfoItemData;
}

export const SheetInfoItem = ({ data }: ISheetInfoItemProps) => {
    const classes = useSheetDetailStyles();

    return (
        <ListItem classes={{ root: classes.timesheetInfoItem }}>
            <Typography variant="subtitle2">
                {data.title}:
            </Typography>
            <Typography variant="body2" noWrap>
                {data.value}
            </Typography>
        </ListItem>
    );
};

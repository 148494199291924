import { IScaZone, IStateUS } from 'store/entities/scaZone/models';
import baseApi from 'shared/utils/baseApi';

class ScaZoneApi {
    async getStates(): Promise<Array<IStateUS>> {
        const { data } = await baseApi.get<{ states: Array<IStateUS> }>('addresses/states');

        return data.states;
    }

    async getScaZones(parent_sca_zone_id: number, state_id: string): Promise<Array<IScaZone>> {
        const { data } = await baseApi.get<{ sca_zones: Array<IScaZone> }>(
            `addresses/sca_zones`, { state_id, parent_sca_zone_id },
        );

        return data.sca_zones;
    }
}

export const scaZoneApi = new ScaZoneApi();

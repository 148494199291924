import { IStore } from 'store/configureStore';
import { selectTimeAndExpenseState } from 'modules/timeAndExpense/store/selectors';

const selectEditEntryState = (state: IStore) => {
    const timeAndExpenseState = selectTimeAndExpenseState(state);
    return timeAndExpenseState.editEntry;
};

export const selectEditEntryId = (state: IStore) => {
    const editEntryState = selectEditEntryState(state);
    return editEntryState.editEntryId;
};
export const selectIsEntryUpdating = (state: IStore) => {
    const editEntryState = selectEditEntryState(state);
    return editEntryState.isEntryUpdating;
};

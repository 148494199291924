import { IEmployeeFilter } from 'modules/settings/submodules/employees/store/models';
import { combineReducers } from 'redux';
import { IUserInfo } from 'shared/models/User';
import { defaultInfinityScrollState, IInfinityScrollState } from 'store/reducerUtils';
import {
    EmployeesActions, getMoreEmployeesAction, initialLoadEmployees, setEmployeesFilter,
} from './actions';

export function employeesTable(
    state: IInfinityScrollState<IUserInfo>,
    action: EmployeesActions,
): IInfinityScrollState<IUserInfo> {
    state = state || defaultInfinityScrollState;
    switch (action.type) {
        case setEmployeesFilter.action:
        case initialLoadEmployees.action:
            return {
                ...defaultInfinityScrollState,
                isLoading: true,
            };
        case getMoreEmployeesAction.errorType:
            return {
                ...state,
                isLoading: false,
            };
        case getMoreEmployeesAction.successType:
            return {
                total: action.payload.total_users,
                items: [...state.items, ...action.payload.users],
                isLoading: false,
            };
        default:
            return state;
    }
}

function employeesFilter(
    state: IEmployeeFilter,
    action: EmployeesActions,
): IEmployeeFilter {
    const defaultState = {};
    state = state || defaultState;
    switch (action.type) {
        case initialLoadEmployees.action:
            return defaultState;
        case setEmployeesFilter.action:
            return action.payload;
        default:
            return state;
    }
}

export const employees = combineReducers({
    employeesTable,
    employeesFilter,
});

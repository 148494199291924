import React from 'react';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import PlainText from 'shared/components/table/Cells/PlainText';
import { userFullName } from 'shared/utils/converters/user';
import { IUserInfo } from 'shared/models/User';
import moment from 'moment';
import { shortDateFormat } from 'shared/models/Dates';
import { useTableStyles } from 'shared/styles/tableStyles';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { useIsMobile } from 'shared/utils/hooks/media';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { IOfferLetter, allOfferLetterTabName, OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import {
    getOfferLetterPdfCell, getOfferLetterStatusCell, getOfferEditBtnCell,
    locationCell, offerDateCell,
    payRateDesktopCell, positionCell,
    startDateCell, departmentCell,
} from 'modules/offerLetter/components/OfferLetterTable/commonCells';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { selectOfferLetterActiveTab } from 'modules/offerLetter/store/selectors';
import { selectDepartmentsSortedByName } from 'modules/employmentInfo/store/department/selectors';

export interface IOfferLetterWithPdf extends IOfferLetter {
    isPdfLoading: boolean;
    isPdfAvailable: boolean;
    userCanContinueOnboarding: boolean;
    isStatusChecking: boolean;
}

export interface IOfferLetterRow {
    className?: string;
    offerLetter: IOfferLetterWithPdf;
    isPdfLoading: boolean;
    isPdfAvailable: boolean;
}

const getFullName = (row: IOfferLetterRow) => userFullName(row.offerLetter as unknown as IUserInfo);

export function useOfferLetterCells(
    openDetail: (offerLetter: IOfferLetterWithPdf) => void,
    offerLetterRows: IOfferLetterRow[],
): Array<ICellInfo<IOfferLetterRow>> {
    const tableClasses = useTableStyles();
    const offerLetterTab = useSelector(selectOfferLetterActiveTab);
    const clientId = useSelector(selectCurrentClientId);
    const showStatus = offerLetterTab === allOfferLetterTabName;
    const isMobile = useIsMobile();

    const clientDepartments = useSelector(selectDepartmentsSortedByName);

    const offerLettersDepartments: (IDepartment | null)[] = [];
    offerLetterRows.forEach(row => {
        if (!offerLettersDepartments.includes(row.offerLetter?.department)) {
            offerLettersDepartments.push(row.offerLetter?.department);
        }
    });

    let offerDateChangeCell: ICellInfo<IOfferLetterRow>;

    switch (offerLetterTab) {
        // TODO change data source for accepted and declined
        case OfferLetterStatusSlug.Accepted:
            offerDateChangeCell = {
                key: 'acceptedDate',
                title: 'accepted date',
                render: function OfferDateCell({ offerLetter, className }: IOfferLetterRow) {
                    return (
                        <PlainText className={className}
                            value={moment(offerLetter.accepted_at).format(shortDateFormat)}
                        />
                    );
                },
            };
            break;
        case OfferLetterStatusSlug.Declined:
            offerDateChangeCell = {
                key: 'declinedDate',
                title: 'declined date',
                render: function OfferDateCell({ offerLetter, className }: IOfferLetterRow) {
                    return (
                        <PlainText className={className}
                            value={moment(offerLetter.rejected_at).format(shortDateFormat)}
                        />
                    );
                },
            };
            break;
        default:
            offerDateChangeCell = offerDateCell;
    }

    const statusEmailCell: ICellInfo<IOfferLetterRow> = showStatus
        ? getOfferLetterStatusCell(tableClasses)
        : {
            key: 'email',
            title: 'email',
            render: function EmailCell({ offerLetter, className }: IOfferLetterRow) {
                return (
                    <PlainText className={className} value={offerLetter.email}/>
                );
            },
        };

    const [isOfferLetterCardFeatureEnabled] = useFeature(FeatureSwitches.enableOfferLetterCard);

    return isMobile ? [
        {
            key: 'FullInfo',
            title: '',
            render: function FullInfoCell(row: IOfferLetterRow) {
                return (
                    <MobileListCell className={row.className} title={getFullName(row)}
                        items={[
                            `Position: ${row.offerLetter.position.name}`,
                            `Start Date: ${moment(row.offerLetter.start_date).format(shortDateFormat)}`,
                        ]}
                    />
                );
            },
        },
        {
            key: 'payRate',
            title: '',
            width: '128px',
            render: function PayRateMobile({ className, offerLetter }: IOfferLetterRow) {
                return (
                    <MobileListCell className={className} title={formatDollars(offerLetter.pay_rate_value)}
                        items={[`/${offerLetter.pay_rate_type}`]}
                    />
                );
            },
        },
    ] : [
        {
            key: 'employee',
            title: 'employee',
            render: function EmployeeCell(row: IOfferLetterRow) {
                return (
                    <PlainText className={row.className} value={getFullName(row)}/>
                );
            },
        },
        statusEmailCell,
        offerDateChangeCell,
        positionCell,
        ...((offerLettersDepartments.length > 1 || clientDepartments.length > 1) ? [departmentCell] : []),
        locationCell,
        payRateDesktopCell,
        startDateCell,
        getOfferLetterPdfCell(tableClasses, openDetail),
        ...(isOfferLetterCardFeatureEnabled ? [getOfferEditBtnCell(clientId)] : []),
    ];
}

import { useMemo } from 'react';
import { IUserClientAssociationRow } from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationTable/model';
import { clientUserAssociationSelectors } from 'modules/settings/submodules/platformUsers/store/selectors';
import { useSelector } from 'react-redux';
import { selectAllClientsById } from 'store/entities/clients/clientsSelectors';
import { selectClientRolesById, selectUsersById } from 'store/entities/users/selectors';

export const useClientUserAssociationRows = (): IUserClientAssociationRow[] => {
    const associations = useSelector(clientUserAssociationSelectors.selectItems);
    const clientById = useSelector(selectAllClientsById);
    const clientRolesById = useSelector(selectClientRolesById);
    const usersById = useSelector(selectUsersById);
    return useMemo(() => associations.map(item => ({
        ...item,
        user: usersById[item.user_id],
        client: clientById[item.client_id],
        role: clientRolesById[item.client_role_id],
    })), [associations, clientById, clientRolesById, usersById]);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { settingsRoutes } from 'modules/settings/routes';
import { matchPath } from 'react-router';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectClientDisplayName } from 'store/entities/clients/clientsSelectors';
import { useStyles } from './styles';
import { routes } from 'shared/routes';

export interface IBreadcrumbItem {
    name: string;
    route: string;
}

export const SettingsBreadcrumbs = () => {
    const classes = useStyles();
    const clientName = useSelector(selectClientDisplayName);
    const breadcrumbRoutes: IBreadcrumbItem[] = [
        {
            name: 'Home',
            route: routes.HOME,
        },
        {
            name: 'Settings',
            route: settingsRoutes.ROOT,
        },
        {
            name: 'Clients',
            route: settingsRoutes.CLIENTS.ROOT,
        },
        {
            name: clientName,
            route: settingsRoutes.CLIENTS.DASHBOARD,
        },
        {
            name: 'Platform Users',
            route: settingsRoutes.PLATFORM_USERS.ROOT,
        },
    ];
    const path = browserHistory.location.pathname;
    const params = useParams();
    const breadcrumbs = breadcrumbRoutes
        .filter(({ route }) => matchPath(path, {
            path: route,
            exact: false,
            strict: false,
        }))
        .map(({ route, name }) => {
            let link;
            try {
                link = generatePath(route, params);
            } catch {
                link = null;
            }

            return {
                link,
                route,
                name,
            };
        });

    return (
        <Breadcrumbs aria-label="breadcrumb" separator="›"
            className={classes.breadcrumbs}>
            {breadcrumbs.map(item => item.link ? (
                <NavLink
                    key={item.name}
                    className={classes.link}
                    to={item.link}
                >
                    {item.name}
                </NavLink>
            ) : (
                <Typography key={item.name}
                    variant="h3"
                    className={classes.nonLink}
                >
                    {item.name}
                </Typography>
            ))}
        </Breadcrumbs>
    );
};

import React, { useState } from 'react';
import { Formik } from 'formik';
import {
    defaultOfferLetterValues,
    IOfferLetterFormValues,
} from 'modules/offerLetter/components/OfferLetterForm/model';
import { selectOfferIsCreating } from 'modules/offerLetter/components/CreateOffer/store/selectors';
import {
    OfferLetterForm, IOfferLetterUser,
} from 'modules/offerLetter/components/OfferLetterForm/OfferLetterForm';
import { offerLetterValidationSchema } from 'modules/offerLetter/components/OfferLetterForm/OfferLetterValidationsSchema';
import { useSelector } from 'react-redux';
import { selectLookupIsLoading } from 'modules/offerLetter/components/lookup/data/selectors';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { IOfferLetter } from '../../store/model';
import { FeatureSwitches } from 'utils/featureSwitches';
import { OfferLetterMultiStepForm } from './OfferLetterMultiStepForm';
import FeatureABTest from 'shared/components/common/FeatureABTest';
import { IUserInfo } from 'shared/models/User';

export interface ILookupFormWrapperProps {
    onSubmit?: (offer: IOfferLetterFormValues) => void;
    onCancel: () => void;
    selectedUser?: IOfferLetterUser;
    offer?: IOfferLetter;
    managers?: (IUserInfo | null)[];
    confirmMessage: (offer: IOfferLetterFormValues | null) => string;
}

const OfferLetterFormWrapper = ({
    onSubmit,
    onCancel,
    selectedUser,
    managers,
    offer,
    confirmMessage,
}: ILookupFormWrapperProps) => {
    const isLookupLoading = useSelector(selectLookupIsLoading);
    const isCreating = useSelector(selectOfferIsCreating);
    const isLoading = isLookupLoading || isCreating;
    const [creatingOfferData, setConfirmOfferData] = useState<IOfferLetterFormValues | null>(null);

    const initialValues: IOfferLetterFormValues = {
        ...defaultOfferLetterValues,
        ...selectedUser ? {
            userId: selectedUser.id,
            first_name: selectedUser.first_name,
            last_name: selectedUser.last_name,
            email: selectedUser.email,
            phone: selectedUser.cell_phone || '',
        } : {},
        ...offer ? {
            position: {
                ...offer.position,
                pay_rate_value: `${offer.pay_rate_value}`,
                pay_rate_type: offer.pay_rate_type,
            },
            locationId: offer.location.id,
            startDate: offer.start_date,
            approvers: managers || [],
        } : {},
    };

    const onSubmitForm = (values: IOfferLetterFormValues) => {
        setConfirmOfferData(values);
    };

    const onCancelConfirmation = () => {
        setConfirmOfferData(null);
    };

    const onConfirm = () => {
        if (creatingOfferData) {
            if (onSubmit) {
                onSubmit(creatingOfferData);
            }
            onCancelConfirmation();
        }
    };

    return (
        <>
            <FeatureABTest
                feature={FeatureSwitches.enableOfferLetterTemplates}
                aComponent={(
                    <OfferLetterMultiStepForm
                        onCancel={onCancel}
                        initialValues={initialValues}
                        onSubmit={onSubmitForm}
                        selectedEmployee={selectedUser}
                        offer={offer}
                        isSubmitting={isCreating}
                    />
                )}
                bComponent={(
                    <Formik
                        initialValues={initialValues}
                        validationSchema={offerLetterValidationSchema}
                        onSubmit={onSubmitForm}
                        validateOnBlur={false}
                    >
                        {props => (
                            <OfferLetterForm
                                {...props}
                                isLoading={isLoading}
                                onCancel={onCancel}
                                selectedUser={selectedUser}
                            />
                        )}
                    </Formik>
                )}
            />
            <ConfirmationDialog
                open={Boolean(creatingOfferData)}
                onConfirm={onConfirm}
                onCancel={onCancelConfirmation}
            >
                {confirmMessage(creatingOfferData)}
            </ConfirmationDialog>
        </>
    );
};

export default OfferLetterFormWrapper;

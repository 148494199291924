import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';

export function useWithClientEffect(
    callback: (dispatch: Dispatch, clientId?: string) => void,
    deps: any[] = [],
){
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);

    useEffect(() => {
        if (clientId) {
            callback(dispatch, clientId);
        }
        // eslint-disable-next-line
    }, [clientId, dispatch, ...deps]); //disabling in order not to use useCallback every time for Callback
}

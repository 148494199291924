import { getUsers } from 'store/entities/users/actions';
import { call, put, takeLatest, select } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { usersApi } from 'store/entities/users/api';
import { IGetUserRequest, UserType } from 'store/entities/users/model';
import {
    getMoreEmployeesAction,
    initialLoadEmployees,
    setEmployeesFilter,
} from 'modules/settings/submodules/employees/store/actions';
import {
    employeesSelectors,
    pageSize,
    selectEmployeeFilter,
} from './selectors';

export function* getMoreUsersSaga() {
    const nextPageToLoad = yield* select(employeesSelectors.selectNextPage);
    const filter = yield* select(selectEmployeeFilter);
    const request: IGetUserRequest = {
        page_size: pageSize,
        page_number: nextPageToLoad,
        sort: '["created_at","desc"]',
        name: filter.name || undefined,
        client_id: filter.client?.id || undefined,
        status: filter.status || undefined,
        user_type: UserType.Employee,
    };
    const getUsersResponse = yield* call(usersApi.getUsers, request);
    yield put(getMoreEmployeesAction.success(getUsersResponse));
    yield put(getUsers.success(getUsersResponse.users));
}

function* getMoreUsersSagaWatcher() {
    yield* takeLatest(
        [
            getMoreEmployeesAction.initType,
            initialLoadEmployees.action,
            setEmployeesFilter.action,
        ],
        withBackendErrorHandler(
            getMoreUsersSaga,
            getMoreEmployeesAction.error,
            'Unable to get employees.',
        ),
    );
}

export default [
    getMoreUsersSagaWatcher,
];

import { IClientUserAssociationRequest, IClientUserAssociationResponse } from 'modules/settings/submodules/platformUsers/store/models';
import baseApi from 'shared/utils/baseApi';
import { ICreatePlatformUser, IUpdatePlatformUserRequest, IUserInfo } from 'shared/models/User';
import { IClientUserAssociation, ICreateClientUserAssociation } from 'store/entities/users/model';

export const api = {
    async createPlatformUser(user: ICreatePlatformUser): Promise<IUserInfo> {
        const { data } = await baseApi.create<ICreatePlatformUser, IUserInfo>(
            'client-users',
            'users',
            user,
        );

        return data;
    },
    async updatePlatformUser(id: string, user: IUpdatePlatformUserRequest): Promise<IUserInfo> {
        const { data } = await baseApi.updateById<IUpdatePlatformUserRequest, IUserInfo>(
            'client-users',
            'users',
            id,
            user,
        );

        return data;
    },
    async createClientUserAssociation(request: ICreateClientUserAssociation): Promise<IClientUserAssociation> {
        const { data } = await baseApi.create<ICreateClientUserAssociation, IClientUserAssociation>(
            'client-users', 'client_user_roles', request,
        );
        return data;
    },
    async getClientUserAssociation(request: IClientUserAssociationRequest): Promise<IClientUserAssociationResponse> {
        const { data } = await baseApi.get<IClientUserAssociationResponse>(
            'client-users/client_user_roles', request,
        );
        return data;
    },
    async deleteClientUserAssociation(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>('/client-users/client_user_roles', id);
        return data.status;
    },
};

import { EntryType } from 'shared/models/sheet/Sheet';

export enum StatusNames {
    SUBMITTED = 'Submitted',
    WORKING = 'Working',
    APPROVED = 'Approved',
    APPROVED_CLOSED = 'Approved - Closed',
    REJECTED = 'Rejected',
    RECALLED = 'Recalled',
    AWAITING_FINAL_APPROVAL = 'Awaiting Final Approval',
    ALL = 'All',
}

export enum SheetStatusSlug {
    SUBMITTED = 'submitted',
    WORKING = 'working',
    APPROVED = 'approved',
    APPROVED_CLOSED = 'approved_closed',
    REJECTED = 'rejected',
    RECALLED = 'recalled',
    AWAITING_FINAL_APPROVAL = 'awaiting_final_approval',
}

export interface IUpdateSheetsStatus {
    sheets: Array<IUpdateSheetStatus>;
}

export interface IUpdateSheetStatus {
    id: string;
    status_id: string;
    notes?: string | null;
}

export type SheetsEntryTypes = Record<string, EntryType>;

export type NoteEntry = Record<string, string>;

export interface ISheetStatusChange {
    statusName: StatusNames;
    sheetsEntryTypes: SheetsEntryTypes;
    notes?: NoteEntry;
}

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFiltersChipsStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChipsStyles';
import { Box } from '@material-ui/core';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { setPositionWorkLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/actions';
import { IPositionWorkLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/model';
import { selectIsPositionLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/selector';
import FilterChip
    from 'modules/settings/submodules/components/FilterChips/FilterChip';

export default function AssociationFilterChips() {
    const dispatch = useDispatch();
    const classes = useFiltersChipsStyles();
    const filter = useSelector(selectIsPositionLocationAssociationFilter);

    const onDelete = useCallback((name: keyof IPositionWorkLocationAssociationFilter) => {
        dispatch(setPositionWorkLocationAssociationFilter({
            ...filter,
            [name]: null,
        }));
    }, [dispatch, filter]);

    const hasFilters = filter.position || filter.location || filter.unassociated;

    return hasFilters ? (
        <Box
            mb={2}
            data-testid={FiltersTestIds.ChipsWrapper}
        >
            {filter.location && (
                <FilterChip
                    name="location"
                    label={filter.location.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}

            {filter.position && (
                <FilterChip
                    name="position"
                    label={filter.position.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}

            {filter.unassociated && (
                <FilterChip
                    name="unassociated"
                    label="Show unassociated"
                    onDelete={onDelete}
                    classes={classes}
                />
            )}
        </Box>
    ) : null;
}

import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { getClientTheme } from 'shared/components/theme/extendClientTheme';
import { useSelector } from 'react-redux';
import { colors } from 'shared/styles/constants';
import { selectConfigurationThemeById, selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { AppClient } from 'utils/constants';
import baseTheme from '../../../baseTheme';

interface IClientThemeProvider {
    children: React.ReactNode
}

export function ClientThemeProvider({ children }: IClientThemeProvider) {
    const clientId = useSelector(selectCurrentClientId) || '';
    const clientConfiguration = useSelector(selectConfigurationThemeById(clientId));
    let clientColor = null;
    if (clientConfiguration) {
        clientColor = clientConfiguration?.theme?.primary_color;
    }
    if (process.env.REACT_APP_CLIENT === AppClient.RTI) {
        clientColor = colors.rtiPrimary;
    }
    const theme = clientColor ? getClientTheme(clientColor) : baseTheme;
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

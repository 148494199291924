import { Box } from '@material-ui/core';
import React from 'react';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormField from 'shared/components/formFields/FormField';

type FormFieldWithSeparateLabelProps<FieldProps extends IFormFieldProps> = FieldProps & {
    outerLabel?: string;
    classes?: Partial<Record<'input', string>>;
    FieldComponent?: React.ComponentType<FieldProps>
}

export function FormFieldWithSeparateLabel<FieldPropType extends IFormFieldProps>({
    outerLabel,
    classes,
    FieldComponent,
    ...props
}: FormFieldWithSeparateLabelProps<FieldPropType>) {
    const defaultClasses = useSettingsFormStyles();
    const customClasses = {
        ...defaultClasses,
        ...classes,
    };
    const Field = FieldComponent || FormField;
    return (
        <Box display="flex"
            alignItems="baseline">
            <label>{ outerLabel }</label>
            <Field
                className={customClasses.input}
                {...props as FieldPropType}
            />
        </Box>
    );
}

import React, { useCallback } from 'react';
import { Checkbox } from '@material-ui/core';
import useCheckBoxStyles from '../CheckBox/CheckBoxStyles';

interface ICheckboxProps {
    isChecked: boolean;
    sheetId: string;
    onChange: (checked: boolean, sheetId: string) => void;
}

export default function CheckBox({ isChecked, sheetId, onChange }: ICheckboxProps) {
    const initialClasses = useCheckBoxStyles();
    const handleCheckChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const isCheckedUpdated = event.target.checked;
        onChange(isCheckedUpdated, sheetId);
    }, [onChange, sheetId]);
    const name = `sheet_row_checkbox_${sheetId}`;

    return (
        <Checkbox
            checked={isChecked}
            onChange={handleCheckChange}
            color="default"
            classes={{ checked: initialClasses.root }}
            name={name}
            inputProps={{ 'aria-label': name }}
        />
    );
}

import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';

import { FeatureSwitches } from 'utils/featureSwitches';
import { CreateWorkingConditionsButton } from 'modules/settings/submodules/clients/workingConditions/CreateWorkingConditions/CreateWorkingConditionsButton';
import { WorkingConditionsTable } from 'modules/settings/submodules/clients/workingConditions/WorkingConditionsTable/WorkingConditionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkingConditions } from 'store/entities/configuration/configurationAction';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';

export const WorkingConditionsPage = () => {

    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();
    useEffect(() => {
        if (clientId) {
            dispatch(getWorkingConditions.init());
        }
    }, [dispatch, clientId]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableClientWorkingConditionsManagement}>
            <Box mb={2}>
                <CreateWorkingConditionsButton/>
            </Box>
            <WorkingConditionsTable/>
        </FeatureSwitch>
    );
};

import React, { FC, useEffect } from 'react';
import { Box, Fab, Grid, Theme, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddEntry from 'shared/components/sidebars/AddEntry/AddEntry';
import { SheetsInProgressFilters } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/SheetsInProgressFilters';
import useAddNewEntryDialogStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControlsMobile/AddNewEntryDialogStyles';
import { TotalInfo } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/components/TotalInfo/TotalInfo';
import { isMobileBreakpoint } from 'shared/styles/constants';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { setAddEntryWasOpened } from 'store/components/addEntry/addEntryActions';
import { useModal } from 'shared/utils/hooks/useModal';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

interface IAddNewEntryDialogProps {
    activeEntry?: EntryType;
    entries: Array<IEntry>;
    sheetId?: string;
    timeSheetId?: string;
    expenseSheetId?: string;
    userId?: string;
    entryTypeAllowed?: EntryType[];
    payPeriod: IPayPeriod;
}

const AddNewEntryDialog: FC<IAddNewEntryDialogProps> = ({
    activeEntry,
    entries,
    timeSheetId,
    expenseSheetId,
    userId,
    payPeriod,
    entryTypeAllowed = [EntryType.TIME, EntryType.EXPENSE],
}: IAddNewEntryDialogProps) => {
    const classes = useAddNewEntryDialogStyles();
    const dispatch = useDispatch();
    const { wasOpened } = useSelector((state: IStore) => state.components.addEntry);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMobileBreakpoint), { noSsr: true });

    useEffect(() => {
        if (!wasOpened) {
            dispatch(setAddEntryWasOpened());
        }
    }, [wasOpened, dispatch]);

    const { isModalOpened, onModalOpen, onModalClose } = useModal(isMobile && !wasOpened);

    return (
        <Box className={classes.container} position="fixed">
            <Grid
                container
                alignItems="center"
                justify="space-between"
            >
                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={onModalOpen}
                    className={classes.button}
                >
                    <AddIcon/>
                </Fab>

                <SheetsInProgressFilters className={classes.mobileFiltersButton}/>

                <TotalInfo
                    entries={entries}
                    entryFilter={activeEntry}
                />
                <AddEntry
                    anchor="right"
                    onClose={onModalClose}
                    isOpened={isModalOpened}
                    timeSheetId={timeSheetId}
                    expenseSheetId={expenseSheetId}
                    userId={userId}
                    initialValue={entryTypeAllowed[0]}
                    entryTypeAllowed={entryTypeAllowed}
                    payPeriod={payPeriod}
                />
            </Grid>
        </Box>
    );
};

export default AddNewEntryDialog;

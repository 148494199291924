import React from 'react';
import { useSelector } from 'react-redux';

import { IOfferLetterTemplate } from 'modules/offerLetter/store/templates/models';
import { selectOfferLetterTemplates, selectOfferLetterTemplatesLoading } from 'modules/offerLetter/store/templates/selectors';

import GridTable from 'shared/components/table/GridTable/GridTable';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { useIsMobile } from 'shared/utils/hooks/media';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';

import { useOfferLetterTemplateCell } from './OfferLetterTemplateCells';

interface IOfferLetterTemplateListLogicProps {
    isMobile?: boolean;
    offerLetterTemplates: IOfferLetterTemplate[];
}

export function OfferLetterTemplateListPure({
    isMobile = false,
    offerLetterTemplates,
}: IOfferLetterTemplateListLogicProps) {
    const tableClasses = useDefaultTableStyles();
    const isLoading = useSelector(selectOfferLetterTemplatesLoading);
    const offerLetterTemplateCells = useOfferLetterTemplateCell(tableClasses);

    return (
        <div>
            <GridTable
                rowData={offerLetterTemplates}
                getKey={row => row.id}
                cells={offerLetterTemplateCells}
                isLoading={isLoading}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hideHeader={isMobile}
                stickyHeader
            />
        </div>
    );
}

export const OfferLetterTemplateList = (
    separateLogicDecorator<object, IOfferLetterTemplateListLogicProps>(() => {
        return {
            isMobile: useIsMobile(),
            offerLetterTemplates: useSelector(selectOfferLetterTemplates),
        };
    })(OfferLetterTemplateListPure)
);

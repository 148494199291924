import React, { useCallback, useMemo } from 'react';
import { selectDepartmentsById } from 'modules/employmentInfo/store/department/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik';
import { EditTimeEntryPure } from 'shared/components/sidebars/EntryEdit/EditTimeEntryPure';
import { ITimeEntry, TimeEntryDataBackend } from 'shared/models/sheet/Sheet';
import { updateTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import {
    ITimeEntryUpdate,
    transformBackendTimeToTimeData,
    transformTimeDataToBackend,
} from 'store/entities/timesheet/models/Entry';
import { selectSheet } from 'store/entities/timesheet/selectors';
import { useTimeEntryValidationSchema } from 'shared/models/validationSchemes/timeEntry';
import {
    selectActivity,
    selectAssignmentById,
    selectAssignmentProjectIds,
    selectAssignmentsById,
    selectJobNumbersById,
    selectLocationsById,
    selectPositionsById,
    selectProjectsWithAssignments,
} from 'store/entities/configuration/configurationSelectors';
import { ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { TSubmitBlock } from './models';
import { getCommonEntryModel, getProjectAssignmentByProjectAndAssignmentIds } from 'shared/utils/helpers/entries';
import { IWithInputFields } from 'shared/components/forms/utils';

interface IEditTimeEntryProps extends IWithInputFields {
    entry: ITimeEntry;
    totalMinutesByDay: Record<string, number>;
    supervisorId?: string;
    userId?: string;
    SubmitBlock: TSubmitBlock;
}

export default function EditTimeEntry({
    entry,
    supervisorId,
    userId,
    SubmitBlock,
    inputs,
}: IEditTimeEntryProps) {
    const dispatch = useDispatch();
    const entryId = entry.id;
    const areaId = useSelector(selectSheet(entry.sheet_id))?.area_id;
    const assignmentProjectId = useSelector(selectAssignmentProjectIds);
    const assignmentsById = useSelector(selectAssignmentsById);
    const assignment = useSelector(selectAssignmentById(entry.assignment_id));
    const activity = useSelector(selectActivity(entry.activity_id));
    const positionsById = useSelector(selectPositionsById);
    const locationsById = useSelector(selectLocationsById);
    const departmentsById = useSelector(selectDepartmentsById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const projectAssignments = useSelector(selectProjectsWithAssignments);
    const schema = useTimeEntryValidationSchema(userId, entryId);

    const completes = completesByNotes(entry.notes || '');
    const initialValues: ITimeEntryFormValues = useMemo(() => {
        const projectAssignment = getProjectAssignmentByProjectAndAssignmentIds(
            projectAssignments,
            entry.project_id,
            entry.assignment_id,
        );
        return {
            projectAssignment,
            taskId: entry.task_id,
            activity: activity,
            entry_date: entry.entry_date,
            data: transformBackendTimeToTimeData(entry.data as TimeEntryDataBackend),
            notes: entry.notes || '',
            completes: completes > 0 ? completes : undefined,
            scaZone: entry.sca_zone,
            position: positionsById[entry.position_id || ''],
            location: locationsById[entry.location_id || ''],
            department: departmentsById[entry.department_id || ''],
            jobNumber: jobNumbersById[entry.job_number_id || ''],
        };
    }, [
        assignmentProjectId,
        activity,
        assignment,
        completes,
        departmentsById,
        entry,
        locationsById,
        positionsById,
        projectAssignments,
        jobNumbersById,
    ]);

    const onSubmit = useCallback((values: ITimeEntryFormValues, formikActions: FormikHelpers<ITimeEntryFormValues>) => {
        const baseEntryModel = getCommonEntryModel(values, null, assignmentsById, assignmentProjectId);
        if (baseEntryModel && values.data) {
            const updatedEntry: ITimeEntryUpdate = {
                ...baseEntryModel,
                id: entryId,
                user_id: userId,
                data: transformTimeDataToBackend(values.data, values.entry_date),
                sca_zone: values.projectAssignment && values.projectAssignment.sca_zone_id ? values.scaZone : null,
            };
            dispatch(updateTimeEntry.init(updatedEntry));
        }
        formikActions.setSubmitting(false);
    }, [dispatch, entryId, assignmentProjectId, assignmentsById, userId]);

    return (
        <EditTimeEntryPure
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            supervisorId={supervisorId}
            areaId={areaId}
            userId={userId}
            sheetId={entry.sheet_id}
            inputs={inputs}
            SubmitBlock={SubmitBlock}
        />
    );
}

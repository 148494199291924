import { selectEditJobNumberId } from 'modules/settings/submodules/clients/jobNumber/components/EditJobNumber/store/selectors';
import { jobNumbersAssignUsersSagas } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/sagas';
import { normalizeJobNumbersSaga } from 'store/entities/configuration/configurationSagas';
import { selectJobNumberById } from 'store/entities/configuration/configurationSelectors';
import { call, put, takeLatest, select } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    jobNumbersTableStateSelectors,
    selectJobNumbersFilter,
} from 'modules/settings/submodules/clients/jobNumber/store/selectors';
import {
    createJobNumberAction,
    getMoreJobNumbersAction,
    initialLoadJobNumbers,
    setCreateJobNumberModalState,
    setJobNumberFilter,
    assignUserToJobNumber,
    updateJobNumber,
    setEditJobNumberId,
} from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { jobNumbersApi } from 'modules/settings/submodules/clients/jobNumber/store/api';
import { getJobNumbers } from 'store/entities/configuration/configurationAction';
import { IJobNumberRequest } from 'store/entities/configuration/configurationModel';
import { getUsers } from 'store/entities/users/actions';
import { setAssignUserModalJobNumberId } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/store/actions';
import { isEmpty } from 'lodash';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';

export function* getMoreJobNumbersSaga() {
    const range = yield* select(jobNumbersTableStateSelectors.selectNextRange);
    const filter = yield* select(selectJobNumbersFilter);

    const filters: any = {};
    if (filter.userId) {
        filters['user_id'] = {
            'eq': filter.userId,
        };
    }
    if (filter.dealId) {
        filters['deal_id'] = {
            'eq': filter.dealId,
        };
    }
    const request: IJobNumberRequest = {
        sort: '["created_at","desc"]',
        range: range,
        filters: isEmpty(filters) ? undefined : JSON.stringify(filters),
    };
    const response = yield* call(jobNumbersApi.getJobNumbers, request);

    yield put(getMoreJobNumbersAction.success({
        total_items: response.total_items,
        job_number_ids: response.job_numbers.map(jobNumber => jobNumber.id),
    }));
    yield* normalizeJobNumbersSaga(response.job_numbers);

    const loadUserIds = [...new Set(response.job_numbers.map(number => number.user_id))].filter(id => id).join(',');
    if (loadUserIds) {
        yield put(getUsers.init({
            ids: loadUserIds,
        }));
    }
}

function* getMoreJobNumbersSagaWatcher() {
    yield* takeLatest(
        [
            getMoreJobNumbersAction.initType,
            initialLoadJobNumbers.action,
            setJobNumberFilter.action,
        ],
        withBackendErrorHandler(
            getMoreJobNumbersSaga,
            getMoreJobNumbersAction.error,
            'Unable to get job numbers.',
        ),
    );
}

export function* createJobNumberSaga({ payload }: ReturnType<typeof createJobNumberAction.init>) {
    const jobNumber = yield* call(jobNumbersApi.createJobNumber, payload);
    yield put(createJobNumberAction.success(jobNumber));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: `Job Number #${jobNumber.job_number} successfully created`,
        autoHideDuration: autoHideDefaultDuration * 2,
    }));
    yield put(setCreateJobNumberModalState(false));
    yield put(setAssignUserModalJobNumberId(jobNumber.id));
    yield put(initialLoadJobNumbers());
}

function* createJobNumberSagaWatcher() {
    yield* takeLatest(
        createJobNumberAction.initType,
        withBackendErrorHandler(
            createJobNumberSaga,
            createJobNumberAction.error,
            'Unable to create Job Number.',
        ),
    );
}

export function* updateJobNumberSaga({ payload }: ReturnType<typeof updateJobNumber.init>) {
    const editJobNumberId = yield select(selectEditJobNumberId);
    const jobNumber = yield* call(jobNumbersApi.updateJobNumber, editJobNumberId, payload);
    yield put(updateJobNumber.success(jobNumber));
    yield* normalizeJobNumbersSaga([jobNumber]);
    yield put(setEditJobNumberId(null));
    yield put(setAssignUserModalJobNumberId(jobNumber.id));
}

function* updateJobNumberSagaWatcher() {
    yield* takeLatest(
        updateJobNumber.initType,
        withBackendErrorHandler(
            updateJobNumberSaga,
            updateJobNumber.error,
            'Unable to save Job Number.',
        ),
    );
}

export function* assignUserToJobNumberSaga({ payload }: ReturnType<typeof assignUserToJobNumber.init>) {
    const jobNumber = yield select(selectJobNumberById(payload.job_number_id));
    yield* call(jobNumbersApi.assignUserToJobNumber, payload);
    yield put(assignUserToJobNumber.success());
    yield put(getJobNumbers.success([{
        ...jobNumber,
        user_id: payload.user_id,
    }]));
    yield put(setAssignUserModalJobNumberId(null));
}

function* assignUserToJobNumberSagaWatcher() {
    yield* takeLatest(
        assignUserToJobNumber.initType,
        withBackendErrorHandler(
            assignUserToJobNumberSaga,
            assignUserToJobNumber.error,
            'Unable to assign user.',
        ),
    );
}

export const jobNumbersSagas = [
    getMoreJobNumbersSagaWatcher,
    createJobNumberSagaWatcher,
    updateJobNumberSagaWatcher,
    assignUserToJobNumberSagaWatcher,
    ...jobNumbersAssignUsersSagas,
];

import ClientUserAssociationFilter from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationFilter/ClientUserAssociationFilter';
import ClientUserAssociationFilterChips from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationFilterChips/ClientUserAssociationFilterChips';
import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import MainContent from 'shared/components/common/MainContent';
import { loadClientRoles, loadGlobalRoles } from 'store/entities/users/actions';
import { getClientUsersAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import CreateUserClientAssociation from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/CreateUserClientAssociation';
import { UserClientAssociationTable } from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationTable/UserClientAssociationTable';

export const UserRolesPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadClientRoles.init());
        dispatch(loadGlobalRoles.init());
        dispatch(getClientUsersAssociation.init());
    }, [dispatch]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enablePlatformUsersManagement} noPlaceholder>
            <MainContent whiteBackground>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                >
                    <CreateUserClientAssociation/>
                    <ClientUserAssociationFilter/>
                </Box>
                <ClientUserAssociationFilterChips/>
                <UserClientAssociationTable/>
            </MainContent>
        </FeatureSwitch>
    );
};

import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';
import { Dialog, DialogContent, Button, DialogActions } from '@material-ui/core';

export interface IConfirmationDialogProps extends Partial<DialogProps> {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    cancelText?: string;
    confirmText?: string;
    children: React.ReactNode;
    customClasses?: {
        paper?: string;
        content?: string;
        actions?: string;
        confirmButton?: string;
        cancelButton?: string;
    };
}

export default function ConfirmationDialog({
    open,
    children,
    onCancel,
    onConfirm,
    cancelText = 'Cancel',
    confirmText = 'Confirm',
    customClasses,
    ...props
}: IConfirmationDialogProps) {
    return (
        <Dialog
            {...props}
            open={open}
            onClose={onCancel}
            disableBackdropClick
            classes={{
                paper: customClasses?.paper || '',
            }}
        >
            <DialogContent className={customClasses?.content}>
                {children}
            </DialogContent>
            <DialogActions className={customClasses?.actions}>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    className={customClasses?.cancelButton}
                >
                    {cancelText}
                </Button>
                <Button
                    className={customClasses?.confirmButton}
                    onClick={onConfirm}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

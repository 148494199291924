import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { selectSubmittingOrgsForControllingOrg } from 'modules/settings/submodules/clients/deals/components/DealForm/components/SubmittingOrgSelect/store/submittingOrgsForControllingOrgSelector';

interface ISubmittingOrgSelectProps extends IFormFieldProps, IFormSelect {
    controllingOrgId: string;
}

export const SubmittingOrgSelect = (props: ISubmittingOrgSelectProps) => {
    const { controllingOrgId } = props;
    const submittingOrgs = useSelector(selectSubmittingOrgsForControllingOrg(controllingOrgId));
    return (
        <FormSelect
            getKey={(submittingOrg: ISubmittingOrg) => submittingOrg?.id || ''}
            getText={(submittingOrg: ISubmittingOrg) => submittingOrg?.client_site_name || ''}
            options={submittingOrgs}
            useIdValue
            {...props}
        />
    );
};

import React, { useCallback } from 'react';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { Button, Typography } from '@material-ui/core';
import { useRecallStyles } from 'shared/components/sheetsSubmitted/filterAndActionControls/RecallStyles';
import { useActions } from 'store/utils';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { EntryType } from 'shared/models/sheet/Sheet';

interface IRecallSidebarProps {
    onClose: () => void;
    sheetsEntryTypes: SheetsEntryTypes;
    onAction?: () => void;
}

export function RecallSidebar({ onClose, sheetsEntryTypes, onAction }: IRecallSidebarProps) {
    const classes = useRecallStyles();
    const actions = useActions({
        updateSheetsStatus: updateSheetsStatus.init,
    });

    const onRecall = useCallback(() => {
        actions.updateSheetsStatus({
            statusName: StatusNames.RECALLED,
            sheetsEntryTypes,
        });

        onClose();

        if (onAction) {
            onAction();
        }
    }, [actions, sheetsEntryTypes, onClose, onAction]);

    const sheetsEntryTypesValues = Object.values(sheetsEntryTypes);

    const amount = sheetsEntryTypesValues.length;
    const hasTime = sheetsEntryTypesValues.some(entryTypes => entryTypes.includes(EntryType.TIME));
    const hasExpense = sheetsEntryTypesValues.some(entryTypes => entryTypes.includes(EntryType.EXPENSE));

    let sheetsTitle = '';

    if (hasTime && hasExpense) {
        sheetsTitle = 'time and expense sheet';
    } else if (hasTime) {
        sheetsTitle = 'timesheet';
    } else if (hasExpense) {
        sheetsTitle = 'expense sheet';
    }

    const haveHas = pluralize('has', amount, 'have');
    const sheetTitlePluralized = pluralize(sheetsTitle, amount);

    return (
        <Sidebar title={`Recall ${sheetsTitle}s`} customClasses={classes.paper}
            isOpened
            onClose={onClose}
        >
            <section className={classes.sidebarSection}>
                <Typography className={classes.infoText} variant="body1">
                    This action is if you have submitted {pluralize('a', amount, '')} {sheetTitlePluralized} that {haveHas} <b>NOT</b> been approved
                    by your supervisor and you need to make an edit.
                </Typography>
                <Typography className={classes.infoText} variant="body1">
                    A total of {amount} {sheetTitlePluralized} {haveHas} been selected to recall.
                    This will revert your submission and you will need to re-submit after your edits.
                </Typography>
            </section>
            <section className={classes.sidebarSection}>
                <Button color="primary"
                    variant="contained" onClick={onRecall }
                >
                    Recall
                </Button>
                <Button className={classes.secondaryButton} color="primary"
                    variant="text" onClick={onClose}
                >
                    Cancel
                </Button>
            </section>
        </Sidebar>
    );

}

import { IFormFieldProps } from 'shared/components/formFields/models';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectDealTypes,
} from 'store/entities/configuration/configurationSelectors';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';

import { useField } from 'formik';
import { IDealType } from 'shared/models/DealType';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { inputParams } from 'shared/styles/constants';
import { createDealType } from 'modules/settings/submodules/clients/deals/components/DealForm/components/DealTypeCreatableSelect/store/actions';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

import { ICreateDealTypePayload } from 'modules/settings/submodules/clients/deals/components/DealForm/components/DealTypeCreatableSelect/store/models';

interface IDealTypeSelectProps extends IFormFieldProps {
}

const filter = createFilterOptions<IDealType | IPseudoDealTypeOption | string | null>();

interface IPseudoDealTypeOption extends IDealType {
    pseudoTitle: string;
}

export const DealTypeCreatableSelect = ({ name, label, outerLabel, className }: IDealTypeSelectProps) => {

    const dealTypeOptions: IDealType[] = useSelector(selectDealTypes);

    const [field, meta, helper] = useField(name);
    const fieldValue = field.value;

    useEffect(() => {
        if (typeof fieldValue === 'string'){
            const newDealType = dealTypeOptions.find(option => option.name === fieldValue);
            if (newDealType){ // on create a new deal type;
                helper.setValue(newDealType.id);
            }
        }
    }, [dealTypeOptions, fieldValue, helper]);

    const value = useMemo(
        () => {
            return fieldValue ? dealTypeOptions.find(option => option.id === fieldValue) : null;
        },
        [fieldValue, dealTypeOptions],
    );

    const errorMessage = typeof meta.error === 'string' ? meta.touched && meta.error : null;
    const hasError = Boolean(meta.error && meta.touched);
    const formHelperTextClasses = useFormHelperTextStyles();

    const [createDealTypePayloadToDispatch,
        setCreateDealTypePayloadToDispatch] = useState<ICreateDealTypePayload|null>(null);

    const onCancelConfirmCreateDealType = useCallback(() => {
        setCreateDealTypePayloadToDispatch(null);
    }, [setCreateDealTypePayloadToDispatch]);

    const dispatch = useDispatch();
    const onConfirmCreateDealType = useCallback(() => {
        if (createDealTypePayloadToDispatch) {
            dispatch(createDealType.init(createDealTypePayloadToDispatch));
        }
        onCancelConfirmCreateDealType();
    }, [dispatch, createDealTypePayloadToDispatch, onCancelConfirmCreateDealType]);

    const onChange = useCallback((_, newValue: IDealType | IPseudoDealTypeOption | string | null) => {
        if (typeof newValue === 'string') {
            helper.setValue(newValue);
            return;
        } else if (newValue && (newValue as IPseudoDealTypeOption).pseudoTitle) {
            // Create a new value from the user input
            setCreateDealTypePayloadToDispatch({
                name: newValue.name,
            });
            helper.setValue(newValue.name);
        } else {
            helper.setValue(newValue?.id || null);
        }
    }, [helper]);

    const getOptionLabel = useCallback((option: string | IDealType | IPseudoDealTypeOption | null) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add Create option
        if (option && (option as IPseudoDealTypeOption).pseudoTitle) {
            return (option as IPseudoDealTypeOption).pseudoTitle;
        }
        // Regular option or null
        return option?.name || '';
    }, []);

    const filterOptions = useCallback((options: (IDealType | IPseudoDealTypeOption | string | null)[], params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== ''
            && !filtered.some(dealType => (dealType as IDealType).name === params.inputValue)) {
            filtered.push({
                pseudoTitle: `Add "${params.inputValue}"`,
                name: params.inputValue,
                client_id: '',
                deleted_at: '',
                id: '',
            });
        }

        return filtered;
    }, []);

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            <>
                {outerLabel && (
                    <label
                        htmlFor={name}
                        className={formHelperTextClasses.outerLabel}
                    >
                        {outerLabel}
                    </label>
                )}
                <Autocomplete
                    id={name}
                    options={dealTypeOptions}
                    freeSolo
                    getOptionLabel={getOptionLabel}
                    value={value}
                    onChange={onChange}
                    filterOptions={filterOptions}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={label}
                            error={Boolean(errorMessage)}
                            helperText={errorMessage}
                            inputProps={{
                                ...params.inputProps,
                                ...inputParams,
                            }}
                        />
                    )}
                />
                <ConfirmationDialog
                    open={Boolean(createDealTypePayloadToDispatch)}
                    onConfirm={onConfirmCreateDealType}
                    onCancel={onCancelConfirmCreateDealType}
                >
                    {`Please confirm you want to create "${createDealTypePayloadToDispatch?.name}" deal type`}
                </ConfirmationDialog>
            </>
        </FormControl>);
};

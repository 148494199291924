import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { Position } from 'shared/components/selects/Position';
import { useStyles } from '../styles';
import { Location } from 'shared/components/selects/Location';
import { defaultOfferLetterValues, IOfferLetterFormValues } from '../model';
import { FormikProps } from 'formik';
import { Department } from 'shared/components/selects/Department';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { monthTextDateFormat } from 'shared/models/Dates';
import FormField from 'shared/components/formFields/FormField';
import { EmployeeType } from 'shared/components/selects/EmployeeType';
import { OfferLetterTemplateSelect } from '../../CreateOffer/components/OfferLetterTemplateSelect';
import clsx from 'clsx';
import { OfferLetterPayRateField } from '../components/OfferLetterPayRateField';
import { MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';

export function OfferLetterOfferForm({
    values,
    setFieldValue,
}: FormikProps<MultiStepFormValues<IOfferLetterFormValues>>) {
    const classes = useStyles();

    const { position, locationId, payRate } = values;

    useEffect(() => {
        if (position) {
            setFieldValue('payRate', payRate || position.pay_rate_value || defaultOfferLetterValues.payRate);
            setFieldValue('payRateType', position.pay_rate_type || defaultOfferLetterValues.payRateType);
        }
        if (locationId) {
            setFieldValue('locationId', locationId || defaultOfferLetterValues.locationId);
        }
    }, [position, locationId, setFieldValue, payRate]);

    return (
        <>
            <Position
                name="position"
                label="Position"
                className={classes.field}
            />

            <Location
                name="locationId"
                label="Work Location"
                className={classes.field}
                disabled={!position}
                positionId={position?.id}
                title={!position ? 'Select Position first' : undefined}
                useIdValue
            />

            <FeatureSwitch feature={FeatureSwitches.enableDepartmentInOfferLetter} noPlaceholder>
                <Department
                    name="departmentId"
                    label="Department"
                    className={classes.field}
                    disabled={!position}
                    title={!position ? 'Select Position first' : undefined}
                    useIdValue
                />
            </FeatureSwitch>

            <Box display="flex">
                <EmployeeType
                    name="employeeType"
                    label="Employee Type"
                    className={clsx(classes.field, classes.firstInputBlock)}
                    useIdValue
                />
                <FormField
                    name="hours"
                    label="Range Of Hours"
                    inputProps={{ maxLength: 10 }}
                    className={clsx(classes.field, classes.secondInputBlock)}
                />
            </Box>

            <DayPickerField
                name="startDate"
                label="Start Date"
                className={classes.field}
                format={monthTextDateFormat}
                disableToolbar
            />

            <OfferLetterTemplateSelect
                name="template"
                label="Offer Letter Template"
                className={classes.field}
                useIdValue
            />

            <OfferLetterPayRateField values={values}/>
        </>
    );
}

import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { settingsRoutes } from 'modules/settings/routes';
import { UserListPage } from 'modules/settings/submodules/platformUsers/components/UserListPage/UserListPage';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import { UserRolesPage } from 'modules/settings/submodules/platformUsers/components/UserRolesPage/UserRolesPage';

export const PlatformUsersRoutes = () => {
    return (
        <>
            <CommonSettingsSideMenu/>
            <Switch>
                <PrivateRoute
                    path={settingsRoutes.PLATFORM_USERS.USER_ROLES}
                    component={UserRolesPage}
                />
                <PrivateRoute
                    path={settingsRoutes.PLATFORM_USERS.ROOT}
                    component={UserListPage}
                />
            </Switch>
        </>
    );
};

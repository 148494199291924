import React from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Button, ButtonTypeMap } from '@material-ui/core';

const useButtonStyles = makeHighPriorityStyles(() => ({
    root: {
        fontSize: 14,
    },
    label: {
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
    },
}));

interface IUpperButtonProps {
    customClasses?: Partial<{ labelClass: string; upperButtonClass: string }>;
    label: string | JSX.Element;
    onClick?: any;
    className?: string;
    variant?: ButtonTypeMap['props']['variant'];
    disabled?: boolean;
}

export default function UpperButton({
    label, customClasses, className, onClick, variant = 'contained', disabled,
}: IUpperButtonProps) {
    const classes = useButtonStyles();

    return (
        <Button
            onClick={onClick}
            classes={{
                root: customClasses?.upperButtonClass ?? classes.root,
                label: customClasses?.labelClass ?? classes.label,
            }}
            className={className}
            variant={variant}
            color="primary"
            type="submit"
            disabled={disabled}
        >
            {label}
        </Button>
    );
}

import { call, put, takeLatest, select } from 'typed-redux-saga';
import { getDeals } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';

import {
    createDeal,
    setCreateDealModalState,
} from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/actions';
import { api } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/api';
import { ICreateDealRequest } from 'modules/settings/submodules/clients/deals/components/CreateDeal/store/models';
import {
    selectInvoiceRecipientById,
} from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/selectors';
import baseApi from 'shared/utils/baseApi';
import { setCreateJobNumberModalState } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { setRedirectToJobNumberWithDealNumber } from '../../DealForm/store/actions';

function* createDealSaga(action: ReturnType<typeof createDeal.init>) {

    const { invoice_recipient_id, continueJobNumberCreation, ...clearedPayload } = action.payload;

    const recipient = yield* select(selectInvoiceRecipientById(invoice_recipient_id));

    const request = {
        ...clearedPayload,
        recipient: {
            recipient_id: invoice_recipient_id,
            recipient_type: recipient?.recipient_type,
        },
        client_id: baseApi.clientId,
    } as ICreateDealRequest;

    const result = yield* call(api.createDeal, request);
    yield put(createDeal.success(result));
    yield put(getDeals.success([result]));
    yield put(setCreateDealModalState(false));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: `Deal #${result.deal_number} successfully created`,
        autoHideDuration: autoHideDefaultDuration * 2,
    }));

    if (continueJobNumberCreation){
        yield put(setRedirectToJobNumberWithDealNumber(result.deal_number));
        yield put(setCreateJobNumberModalState(true));
    }
}

export function* createDealWatcher() {
    yield* takeLatest(
        createDeal.initType,
        withBackendErrorHandler(
            createDealSaga,
            createDeal.error,
            'Unable to create Deal.',
        ),
    );
}

import { IUserRow, useUserCellDictionary } from 'modules/settings/submodules/components/SharedTableRows/UsersRows';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { Box } from '@material-ui/core';
import { PaginationCounter } from 'modules/settings/submodules/components/PaginationCounter/PaginationCounter';
import { employeesSelectors } from 'modules/settings/submodules/employees/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import {
    initialLoadEmployees,
    getMoreEmployeesAction,
} from 'modules/settings/submodules/employees/store/actions';

export const EmployeeTable = () => {
    const tableClasses = useDefaultTableStyles();
    const users = useSelector(employeesSelectors.selectItems);
    const hasMoreRows = useSelector(employeesSelectors.selectHasMore);
    const total = useSelector(employeesSelectors.selectTotalItems);
    const isLoading = useSelector(employeesSelectors.isLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initialLoadEmployees());
    }, [dispatch]);

    const onLoadMoreRows = useCallback(() => {
        if (hasMoreRows){
            dispatch(getMoreEmployeesAction.init());
        }
    }, [dispatch, hasMoreRows]);

    const isMobile = useIsMobile();
    const userCellsDictionary = useUserCellDictionary();

    const userCells: ICellInfo<IUserRow>[] = useMemo(() => [
        userCellsDictionary.id,
        userCellsDictionary.name,
        userCellsDictionary.created,
        userCellsDictionary.updated,
        userCellsDictionary.statusReadonly,
        userCellsDictionary.state,
        userCellsDictionary.actions,
    ], [userCellsDictionary]);

    return (
        <Box mt={2} mb={2}>
            <InfinityGridTable
                rowData={users}
                getKey={row => row.id}
                cells={userCells}
                isLoading={isLoading}
                headerCellClassName={tableClasses.headCell}
                bodyCellClassName={tableClasses.bodyCell}
                className={tableClasses.tableBorder}
                hideHeader={isMobile}
                stickyHeader
                onLoadMore={onLoadMoreRows}
                hasMore={hasMoreRows}
            />
            <PaginationCounter
                shown={users.length}
                total={total}
            />
        </Box>
    );
};

import { IExpenseEntry, IExpenseSheetBackend, ISheetCommonBackend, IStatus } from 'shared/models/sheet/Sheet';
import { ActionsReturnTypes, createActions, createRequestActions, RequestType } from 'store/utils';
import { IExpenseEntryCreate, IExpenseEntryUpdate } from 'store/entities/timesheet/models/Entry';
import { ILoadSheetsPayload } from 'store/entities/timesheet/models/SheetApi';

/**
 * LOAD_EXPENSE_SHEETS
 */

export const loadExpenseSheetsWithEntries = createRequestActions<ILoadSheetsPayload, IExpenseSheetBackend[]>(
    RequestType.Get,
    'EXPENSE_SHEETS_WITH_ENTRIES',
    'expense_sheets',
);

export const loadExpenseSheets = createRequestActions<ILoadSheetsPayload, ISheetCommonBackend[]>(
    RequestType.Get,
    'EXPENSE_SHEETS',
    'expense_sheets',
);

/**
 * UPDATE_EXPENSE_SHEETS
 */

const UPDATE_EXPENSE_SHEETS = 'expense_sheets/UPDATE';
const UPDATE_EXPENSE_SHEETS_SUCCESS = 'expense_sheets/UPDATE_SUCCESS';
const UPDATE_EXPENSE_SHEETS_ERROR = 'expense_sheets/UPDATE_ERROR';

export const updateExpenseSheets = createActions<
void,
Array<IExpenseSheetBackend>,
any,
typeof UPDATE_EXPENSE_SHEETS,
typeof UPDATE_EXPENSE_SHEETS_SUCCESS,
typeof UPDATE_EXPENSE_SHEETS_ERROR
>(
    UPDATE_EXPENSE_SHEETS,
    UPDATE_EXPENSE_SHEETS_SUCCESS,
    UPDATE_EXPENSE_SHEETS_ERROR,
);

/**
 * UPDATE_EXPENSE_ENTRY
 */

const UPDATE_EXPENSE_ENTRY = 'expense_entry/UPDATE';
const UPDATE_EXPENSE_ENTRY_SUCCESS = 'expense_entry/UPDATE_SUCCESS';
const UPDATE_EXPENSE_ENTRY_ERROR = 'expense_entry/UPDATE_ERROR';

export const updateExpenseEntry = createActions<
IExpenseEntryUpdate,
IExpenseEntry,
any,
typeof UPDATE_EXPENSE_ENTRY,
typeof UPDATE_EXPENSE_ENTRY_SUCCESS,
typeof UPDATE_EXPENSE_ENTRY_ERROR
>(
    UPDATE_EXPENSE_ENTRY,
    UPDATE_EXPENSE_ENTRY_SUCCESS,
    UPDATE_EXPENSE_ENTRY_ERROR,
);

/**
 * ADD_EXPENSE_ENTRY
 */

const ADD_EXPENSE_ENTRY = 'expense_entry/ADD';
const ADD_EXPENSE_ENTRY_SUCCESS = 'expense_entry/ADD_SUCCESS';
const ADD_EXPENSE_ENTRY_ERROR = 'expense_entry/ADD_ERROR';

export const addExpenseEntry = createActions<
IExpenseEntryCreate,
IExpenseEntry,
any,
typeof ADD_EXPENSE_ENTRY,
typeof ADD_EXPENSE_ENTRY_SUCCESS,
typeof ADD_EXPENSE_ENTRY_ERROR
>(
    ADD_EXPENSE_ENTRY,
    ADD_EXPENSE_ENTRY_SUCCESS,
    ADD_EXPENSE_ENTRY_ERROR,
);

/**
 * REMOVE_EXPENSE_ENTRY
 */

const REMOVE_EXPENSE_ENTRY = 'expense_entry/REMOVE';
const REMOVE_EXPENSE_ENTRY_SUCCESS = 'expense_entry/REMOVE_SUCCESS';
const REMOVE_EXPENSE_ENTRY_ERROR = 'expense_entry/REMOVE_ERROR';

export const removeExpenseEntry = createActions<
string,
string,
any,
typeof REMOVE_EXPENSE_ENTRY,
typeof REMOVE_EXPENSE_ENTRY_SUCCESS,
typeof REMOVE_EXPENSE_ENTRY_ERROR
>(
    REMOVE_EXPENSE_ENTRY,
    REMOVE_EXPENSE_ENTRY_SUCCESS,
    REMOVE_EXPENSE_ENTRY_ERROR,
);

/**
 * GET_EXPENSE_STATUSES
 */

const GET_EXPENSE_STATUSES = 'GET_EXPENSE_STATUSES';
const GET_EXPENSE_STATUSES_SUCCESS = 'GET_EXPENSE_STATUSES_SUCCESS';
const GET_EXPENSE_STATUSES_ERROR = 'GET_EXPENSE_STATUSES_ERROR';

export const getExpenseStatuses = createActions<void, Array<IStatus>, any,
typeof GET_EXPENSE_STATUSES,
typeof GET_EXPENSE_STATUSES_SUCCESS,
typeof GET_EXPENSE_STATUSES_ERROR
>(
    GET_EXPENSE_STATUSES,
    GET_EXPENSE_STATUSES_SUCCESS,
    GET_EXPENSE_STATUSES_ERROR,
);

export type ExpenseSheetActions =
    | ActionsReturnTypes<typeof loadExpenseSheetsWithEntries>
    | ActionsReturnTypes<typeof loadExpenseSheets>
    | ActionsReturnTypes<typeof updateExpenseSheets>
    | ActionsReturnTypes<typeof addExpenseEntry>
    | ActionsReturnTypes<typeof removeExpenseEntry>
    | ActionsReturnTypes<typeof updateExpenseEntry>
    | ActionsReturnTypes<typeof getExpenseStatuses>;

import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useQuickEditStyles } from 'shared/components/table/tableEntryCells/QuickEditStyles';
import OutlinedTimeField from 'shared/components/textField/OutlinedTimeField';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { getDurationFromDateTimes, getMinutesByTimeUnits, parseTimeUnitsFromString } from 'shared/models/DateTime';
import { IInOutBreakDataBackend, ITimeEntry, QuantityType } from 'shared/models/sheet/Sheet';
import { ValidationMessages } from 'shared/models/Validation';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { updateTimeEntry } from 'store/entities/timesheet/actions/timeActions';

export interface IQuickEditBreakProps {
    entry: ITimeEntry;
    customClasses?: string;
}

export const QuickEditBreak = ({ customClasses, entry }: IQuickEditBreakProps) => {
    const dispatch = useDispatch();
    const classes = useQuickEditStyles();
    const disabled = entry.data.entry_type !== QuantityType.TIME_IN_OUT_BREAK;
    const data = entry?.data as IInOutBreakDataBackend;
    const inputValue = formatMinutes(data?.break_minutes);
    const [storedValue] = useState(inputValue);
    const [newValue, setNewValue] = useState(inputValue);

    const handleOnRestore = useCallback(() => {
        setNewValue(storedValue);
    }, [setNewValue, storedValue]);

    const handleOnFocus = useCallback(() => {
        setNewValue('');
    }, [setNewValue]);

    const handleOnBlur = useCallback(({ target }) => {
        const { value: changedValue } = target;
        if (changedValue === '') {
            handleOnRestore();
            return;
        }

        const parsedValue = getMinutesByTimeUnits(parseTimeUnitsFromString(changedValue));
        const error = parsedValue > getMinutesByTimeUnits(getDurationFromDateTimes(
            data.time_in,
            data.time_out,
        )) ? ValidationMessages.BREAK_LESS_THAN_TIME_IN_OUT : null;

        if (error) {
            dispatch(setGlobalToast({
                title: error,
                severity: IModalSeverity.Error,
                autoHideDuration: null,
            }));
            handleOnRestore();
        } else {
            dispatch(updateTimeEntry.init({
                id: entry.id,
                data: {
                    ...data,
                    break_minutes: parsedValue,
                } as IInOutBreakDataBackend,
            }));
            setNewValue(changedValue);
        }
    }, [dispatch, handleOnRestore, setNewValue, entry, data]);

    return (
        <OutlinedTimeField
            customClasses={{
                input: classes.input,
                inputRoot: clsx(classes.inputRoot, classes.inputRootNoShadow, customClasses),
                disabled: classes.disabledInput,
            }}
            value={newValue}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            disabled={disabled}
        />
    );
};

import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { useSelector } from 'react-redux';
import { IFormSelect } from './model';
import { selectClientStatuses } from 'modules/settings/submodules/clients/store/selectors';
import { IClientStatuses } from 'modules/settings/submodules/clients/store/models';
import { IFormFieldProps } from '../formFields/models';

export interface IStatusesProps extends IFormFieldProps, IFormSelect {
}

export default function Statuses({
    ...props
}: IStatusesProps) {
    const statuses = useSelector(selectClientStatuses);

    return (
        <FormSelect
            {...props}
            getKey={(status: IClientStatuses) => status.id}
            getText={(status: IClientStatuses) => status.name}
            options={statuses}
        />
    );
}

import {
    all,
    call,
    put,
    SagaGenerator,
    spawn,
} from 'typed-redux-saga';
import ComponentsSagas from 'store/components/sagas';
import SheetsSagas from './entities/timesheet/sagas';
import * as ScaZoneSagas from './entities/scaZone/sagas';
import * as ZipCodeSagas from './entities/zipCode/sagas';
import UsersSagas from './entities/users/sagas';
import OfferLetterSagas from 'modules/offerLetter/store/sagas';
import EmploymentSagas from 'modules/employmentInfo/store/sagas';
import profileSagas from 'modules/profile/store/profileSaga';
import ConfigurationSagas from './entities/configuration/configurationSagas';
import ClientsSagas from './entities/clients/clientsSagas';
import SettingsSagas from 'modules/settings/store/sagas';
import NotificationsSagas from 'modules/notificationCenter/store/sagas';
import HomeSagas from 'modules/home/store/sagas';
import PayrollProcessorHubSagas from 'modules/payrollProcessorHub/store/sagas';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { logger } from 'shared/utils/logging/logger';
import { getRequestDetails } from 'shared/utils/logging/requestModel';

type RunnableSaga = () => Generator<SagaGenerator<any>, void>;

export function* rootSaga() {
    const sagas: Array<RunnableSaga> = [
        ...ComponentsSagas,
        ...SheetsSagas,
        ...ConfigurationSagas,
        ...ClientsSagas,
        ...OfferLetterSagas,
        ...EmploymentSagas,
        ...SettingsSagas,
        ...UsersSagas,
        ...NotificationsSagas,
        ...PayrollProcessorHubSagas,
        ...HomeSagas,
        ...profileSagas,
        ...Object.values(ScaZoneSagas),
        ...Object.values(ZipCodeSagas),
    ] as any;

    yield all(sagas.map(saga => spawn(function* () {
        while (true) {
            try {
                yield call(saga);
                break;
            } catch (e) {
                if (process.env.NODE_ENV === 'development') {
                    console.error(e);
                }
                if (['config', 'request', 'response'].every(key => Object.keys(e).includes(key))) {
                    yield* put(setGlobalToast({
                        severity: IModalSeverity.Error,
                        title: 'Unhandled error happened during a request',
                    }));

                    e.message = `Unhandled network error in root saga: ${e.message}`;
                    logger.error(e, getRequestDetails(e));
                } else {
                    e.message = `Unhandled error in root saga: ${e.message}`;
                    logger.error(e);
                }
            }
        }
    })));
}

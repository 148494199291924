import React from 'react';
import { Formik } from 'formik';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import {
    ISubmittingOrgFormValues,
} from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/model';
import { SubmittingOrgValidationSchema } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/ValidationsSchema';
import { SubmittingOrgForm } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/SubmittingOrgForm';
import { Status } from 'shared/models/Status';

export interface ISubmittingOrgFormWrapperProps {
    onSubmit: (values: ISubmittingOrgFormValues) => void;
    onCancel?: () => void;
    submitTitle: string;
    isLoading: boolean;
    submittingOrg?: ISubmittingOrg;
}

const SubmittingOrgFormWrapper = ({
    onSubmit,
    onCancel,
    submitTitle,
    isLoading,
    submittingOrg,
}: ISubmittingOrgFormWrapperProps) => {

    const initialFormValues = {
        client_site_name: submittingOrg?.client_site_name || '',
        status: submittingOrg?.active === false ? Status.inactive : Status.active,
        controlling_org_id: submittingOrg?.controlling_org_id || '',
        submitting_org_number: submittingOrg?.submitting_org_number || '',

        address: submittingOrg?.address || '',
        city: submittingOrg?.city || '',
        state: submittingOrg?.state || '',
        zip_code: submittingOrg?.zip || '',
        county: '',

        location_id: submittingOrg?.location_id || '',
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={SubmittingOrgValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <SubmittingOrgForm
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                />
            )}
        </Formik>
    );
};

export default SubmittingOrgFormWrapper;

import React, { Fragment, useCallback } from 'react';
import { generatePath, Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Drawer,
    DrawerProps,
    Theme,
    useMediaQuery,
    Typography,
    Avatar,
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { VerticalMenuItems } from 'shared/components/verticalMenu/VerticalMenuItems';
import { routes } from 'shared/routes';
import { logout } from 'store/components/auth/authActions';
import { useSidebarState } from 'store/entities/appConfig/utils';
import { AssignmentInd, ExitToAppOutlined, Home, SettingsOutlined } from '@material-ui/icons';
import { isMediumScreenBreakpoint, isSmallScreenBreakpoint } from 'shared/styles/constants';
import { IMenuCategory } from 'shared/models/Global';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfigurationThemeById, selectCurrentClientId, selectClientDisplayName } from 'store/entities/clients/clientsSelectors';
import { ClientPicker } from 'shared/components/verticalMenu/ClientPicker';
import { VerticalMenuIds } from 'shared/components/verticalMenu/testIds';
import { AppClient } from 'utils/constants';
import { FeatureSwitches } from 'utils/featureSwitches';
import { selectUserPermissions, selectCurrentUser, selectIsManager } from 'store/components/auth/selectors';
import { useFeature } from '@optimizely/react-sdk';
import { Permission } from 'store/components/auth/authModels';
import PayrollForm from 'shared/components/verticalMenu/PayrollForm';
import { selectUserAvatar } from 'modules/profile/store/profileSelector';

export interface IVerticalMenuProps extends Pick<DrawerProps, 'PaperProps' | 'variant'> {
    menu: IMenuCategory[];
}

const VerticalMenu: React.FC<IVerticalMenuProps> = ({ menu, ...other }: IVerticalMenuProps) => {
    const classes = useMenuStyles();
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down(isSmallScreenBreakpoint), { noSsr: true },
    );

    const [payrollFeature] = useFeature(FeatureSwitches.displaypayrollbutton);
    const [isSettingsModuleEnabled] = useFeature(FeatureSwitches.enableSettingsModule);

    const clientId = useSelector(selectCurrentClientId);
    const isManager = useSelector(selectIsManager);
    const configuration = useSelector(selectConfigurationThemeById(clientId ?? ''));
    const permissions = useSelector(selectUserPermissions);
    const tenantName = useSelector(selectClientDisplayName);
    const { isSidebarOpened, setSidebarOpened } = useSidebarState();
    const isMenuTemporary = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMediumScreenBreakpoint));
    const isMinimized = !isMenuTemporary && !isSidebarOpened;
    const currentUser = useSelector(selectCurrentUser);

    const closeMenu = useCallback(event => {
        event.preventDefault();
        setSidebarOpened(false);
    }, [setSidebarOpened]);

    const onClick = useCallback(() => {
        if (isSmallScreen) {
            setSidebarOpened(false);
        }
    }, [isSmallScreen, setSidebarOpened]);

    const handleLogout = () => {
        dispatch(logout());
    };
    const avatar = useSelector(selectUserAvatar);

    return (
        <Drawer onClose={closeMenu} classes={{
            paper: clsx(classes.paper, { [classes.sidebarMinimized]: isMinimized }),
        }}
        open={!isMenuTemporary || isSidebarOpened}
        data-testid={VerticalMenuIds.Container}
        variant="permanent" {...other}
        >
            <List disablePadding component="nav"
                onClick={onClick} className={classes.list}
            >
                <ListItem component={Link} to="/"
                    className={clsx(
                        classes.item, classes.itemCategory, classes.logoItem,
                        { [classes.minimizedLogoWrapper]: !isSidebarOpened },
                    )}
                >
                    <div className={classes.logoWrapper}>
                        {process.env.REACT_APP_CLIENT === AppClient.RTI
                            ? (<img alt="RTI" className={classes.logoImage}
                                src={`${process.env.PUBLIC_URL}/images/rti_logo_white.png`}/>)
                            : ( <img alt="Headway" className={classes.logoImage}
                                src={`${process.env.PUBLIC_URL}/images/headway.png`}/>)
                        }
                        {isSidebarOpened && (
                            <IconButton
                                color="inherit"
                                aria-label="close drawer"
                                onClick={closeMenu}
                                className={classes.hideMenuButton}
                                data-testid={VerticalMenuIds.HideMenu}
                            >
                                <ChevronLeft classes={{ root: classes.chevronMargin }}/>
                                <MenuIcon/>
                            </IconButton>
                        )}

                    </div>
                </ListItem>
                {!process.env.REACT_APP_CLIENT && tenantName ? (
                    <ClientPicker configuration={configuration} tenantName={tenantName}
                        isMinimized={isMinimized} isSmallScreen={isSmallScreen}
                    />
                ) : (
                    <ListItem component={NavLink} to="/"
                        exact
                        className={clsx(classes.item, classes.itemCategory)}
                    >
                        <ListItemIcon className={clsx(classes.itemIcon)}>
                            <Home/>
                        </ListItemIcon>
                        <ListItemText>
                            Home
                        </ListItemText>
                    </ListItem>
                )}
                {menu.map(({ id, children, title, permission }, index) => (
                    <Fragment key={id}>
                        {(!permission || permissions.includes(permission)) && (
                            <>
                                {index > 0 && <Divider/>}
                                {!isMinimized && title && <Typography variant="h6" className={classes.subtitle}>{title}</Typography>}
                                <VerticalMenuItems menuItems={children} isMinimized={isMinimized}/>
                            </>
                        )}
                    </Fragment>
                ))}
                {tenantName && payrollFeature && permissions.includes(Permission.ApproveSheets) && (
                    <PayrollForm/>

                )}
            </List>
            <List
                disablePadding
                className={classes.bottomMenu}
                onClick={closeMenu}
            >
                <Divider/>
                {isSettingsModuleEnabled && permissions.includes(Permission.modifyClientSettings) && (
                    <ListItem
                        component={NavLink}
                        to={routes.SETTINGS}
                        className={classes.item}
                    >
                        <ListItemIcon className={classes.itemIcon}>
                            <SettingsOutlined/>
                        </ListItemIcon>
                        <ListItemText
                            classes={{
                                primary: classes.itemPrimary,
                            }}
                        >
                            Settings
                        </ListItemText>
                    </ListItem>
                )}
                {isSmallScreen && (
                    <>
                        <ListItem className={classes.item}
                            component={NavLink}
                            to={routes.USER_PROFILE}
                        >
                            <ListItemIcon className={classes.itemIcon}>
                                <Avatar className={classes.avatar} src = {avatar.url}>
                                    {currentUser?.firstName.substring(0, 1)}{currentUser?.lastName.substring(0, 1)}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.itemPrimary,
                                }}
                            >
                                User Profile
                            </ListItemText>
                        </ListItem>
                        {!isManager && clientId && (
                            <ListItem
                                className={classes.item}
                                component={NavLink}
                                to={generatePath(routes.CLIENT.EMPLOYEE_PORTAL, { client_id: clientId })}
                            >
                                <ListItemIcon className={classes.itemIcon}>
                                    <AssignmentInd/>
                                </ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.itemPrimary,
                                    }}
                                >
                                    Employee Portal
                                </ListItemText>
                            </ListItem>
                        )}
                        <ListItem className={classes.item} onClick={handleLogout}>
                            <ListItemIcon className={classes.itemIcon}>
                                <ExitToAppOutlined/>
                            </ListItemIcon>
                            <ListItemText
                                classes={{
                                    primary: classes.itemPrimary,
                                }}
                            >
                            Logout
                            </ListItemText>
                        </ListItem>
                    </>
                )}
            </List>
        </Drawer>
    );
};

export default VerticalMenu;

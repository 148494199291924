import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { EntryType, QuantityType, QuantityTypeLabelMap } from 'shared/models/sheet/Sheet';
import { useSelector } from 'react-redux';

import { selectDataTypes } from 'modules/settings/submodules/clients/activities/components/selects/store/selectors';

interface IDataTypeSelectProps extends IFormFieldProps, IFormSelect {
    entryType: EntryType;
}

export default function DataTypeSelect({
    entryType,
    ...props
}: IDataTypeSelectProps) {
    const dataTypes = useSelector(selectDataTypes(entryType));

    return (
        <FormSelect
            {...props}
            getKey={(type: QuantityType) => type}
            getText={(type: QuantityType) => QuantityTypeLabelMap[type]}
            options={dataTypes}
        />
    );
}

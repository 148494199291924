import { IUserInfo } from 'shared/models/User';
import { IStore } from 'store/configureStore';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { IEmployeeFilter } from 'modules/settings/submodules/employees/store/models';

export const pageSize = 30;

export const selectEmployeesState = (state: IStore) => state.modules.settings.employees;
export const selectEmployeesTableState = (state: IStore): IInfinityScrollState<IUserInfo> =>
    selectEmployeesState(state).employeesTable;
export const employeesSelectors = getInfinityScrollSelectors(selectEmployeesTableState);
export const selectEmployeeFilter = (state: IStore): IEmployeeFilter => selectEmployeesState(state).employeesFilter;

import { PayRateType } from 'shared/models/Position';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';

function tryShorten(payRateType: PayRateType): string {
    if (payRateType === PayRateType.PER_HOUR) {
        return 'hr';
    }
    return payRateType;
}

export function formatPayRate(
    { pay_rate_type, pay_rate_value } : { pay_rate_type: PayRateType, pay_rate_value: number },
) {
    const payRateType = tryShorten(pay_rate_type);
    return `${formatDollars(pay_rate_value)}/${payRateType}`;
}

import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { desktopContentSpace } from 'shared/styles/constants';

const useFilterAndActionControlsStyles = makeHighPriorityStyles((theme: Theme) => ({
    rootContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        borderTop: 'none',

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4, desktopContentSpace, 2),
        },
        [theme.breakpoints.down('xs')]: {
            border: 'none',
            width: '100%',
        },
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    controlsContainer: {
        display: 'inline-flex',
        alignItems: 'flex-start',
        marginLeft: theme.spacing(4),
        height: theme.spacing(4),

        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            margin: 0,
            width: '100%',
            height: theme.spacing(5),
        },
    },
    hidden: {
        visibility: 'hidden',
    },
    filterButton: {
        marginRight: theme.spacing(2),
    },
}));

export default useFilterAndActionControlsStyles;

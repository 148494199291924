import { pick } from 'lodash';

import { selectCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/selectors';
import { useEffect, useRef } from 'react';
import { useEntryDate } from 'shared/utils/hooks/useEntryDate';

import { selectCurrentClientInputsConfiguration } from 'store/entities/clients/clientsSelectors';
import { IAssignment, IProjectWithAssignment } from 'store/entities/configuration/configurationModel';
import { InputFields } from 'store/entities/clients/clientsModel';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { useSelector } from 'react-redux';
import { ProjectIdsByAssignmentId } from 'store/entities/configuration/configurationReducer';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { selectDefaultDepartment } from 'modules/employmentInfo/store/department/selectors';
import { defaultValues as defaultExpenseValues, IExpenseEntryFormValues } from 'shared/components/forms/entries/ExpenseEntryModel';
import { defaultValues as defaultTimeValues, ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { ItemsById } from 'shared/models/ItemsById';
import { CommonEntryBackend } from 'store/entities/timesheet/models/Entry';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

import { Nullable } from '../../../@types/types';

/**
 * Build common backend model for entry from form value
 * @param values - form value
 * @param defaultTask - default task value
 * @param assignmentsById - all available assignments by id
 * @param assignmentProjectId - Map all project ids by assignment id
 */
export const getCommonEntryModel = (
    values: ICommonEntryFormValues,
    defaultTask: Nullable<string>,
    assignmentsById: ItemsById<IAssignment>,
    assignmentProjectId: ProjectIdsByAssignmentId,
): CommonEntryBackend | null => {
    const taskId = entryFormGetTask(values, defaultTask);
    const { assignmentId, projectId } = entryFormGetAssignment(values, assignmentsById, assignmentProjectId);
    if (!assignmentId) {
        return null;
    }
    const jobNumberId = values.jobNumber?.id;
    return {
        assignment_id: assignmentId,
        project_id: projectId || undefined,
        job_number_id: jobNumberId || undefined,
        activity_id: values.activity?.id || undefined,
        task_id: taskId,
        position_id: values.position?.id || null,
        location_id: values.location?.id || null,
        department_id: values.department?.id || undefined,
        entry_date: values.entry_date,
        notes: values.notes,
    };
};

interface IProjectAndAssignmentIds {
    assignmentId: Nullable<string>,
    projectId: Nullable<string>,
}

export function entryFormGetAssignment(
    values: ICommonEntryFormValues,
    assignmentsById: ItemsById<IAssignment>,
    projectIdsByAssignmentId: ProjectIdsByAssignmentId,
): IProjectAndAssignmentIds {
    if (values.position && values.location) {
        const assignment = Object.values(assignmentsById).find(
            ({ location_id, position_id }) => location_id === values.location?.id
                && position_id === values.position?.id,
        ) || null;
        return {
            assignmentId: assignment?.id,
            projectId: (projectIdsByAssignmentId[assignment?.id || ''] || [])[0], // default project
        };
    }
    if (values.jobNumber) {
        // TODO: This is a simplification. We will take first assignment
        const assignment = Object.values(assignmentsById).find(
            ({ user_id }) => values.jobNumber?.user_id,
        ) || null;
        return {
            assignmentId: assignment?.id,
            projectId: (projectIdsByAssignmentId[assignment?.id || ''] || [])[0], // default project
        };
    }

    const { projectAssignment } = values;
    return {
        assignmentId: projectAssignment?.assignment.id,
        projectId: projectAssignment?.project_id,
    };
}

export function entryFormGetTask(values: ICommonEntryFormValues, defaultTask: Nullable<string>) {
    // Task Id value is not saved when set as default value
    if (defaultTask) {
        return defaultTask;
    }
    return values.taskId;
}

export function useDefaultActivity(defaultValues: ICommonEntryFormValues, inputs: InputFields) {
    const activitiesById = useSelector(selectActivitiesById);

    if (inputs.activity && inputs.activity.default_value) {
        defaultValues.activity = activitiesById[inputs.activity.default_value];
        return activitiesById[inputs.activity.default_value];
    }
}

export const normalizeCommonValuesForState = (formValues: ICommonEntryFormValues) => {
    return pick(
        formValues,
        [
            'location',
            'position',
            'assignment',
            'department',
            'entry_date',
            'projectAssignment',
            'taskId',
            'jobNumber',
        ],
    ) as ICommonEntryFormValues;
};

export function useExpenseEntryDefaultValues(
    userId?: string | null,
): IExpenseEntryFormValues {
    const commonValues = useSelector(selectCommonEntryFormValues);
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const defaultActivity = useDefaultActivity(defaultExpenseValues, inputsConfiguration.expense);
    const defaultDepartment = useSelector(selectDefaultDepartment(userId));
    return {
        ...defaultExpenseValues,
        activity: defaultActivity ?? null,
        department: defaultDepartment ?? null,
        ...commonValues,
    };
}

export function useTimeEntryDefaultValues(
    userId?: string | null,
): ITimeEntryFormValues {
    const commonValues = useSelector(selectCommonEntryFormValues);
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const defaultActivity = useDefaultActivity(defaultTimeValues, inputsConfiguration.time);
    const defaultDepartment = useSelector(selectDefaultDepartment(userId));
    return {
        ...defaultTimeValues,
        activity: defaultActivity ?? null,
        department: defaultDepartment ?? null,
        ...commonValues,
    };
}

/**
 * Update selected day on change pay period
 * @param payPeriod - selected pay period
 * @param onChangeCommonValues - callback for change common values
 */
export function useDayUpdateOnChangePayPeriod(
    payPeriod: IPayPeriod,
    onChangeCommonValues: (values: ICommonEntryFormValues) => void,
) {
    const commonValues = useSelector(selectCommonEntryFormValues);
    const defaultEntryDateValue = useEntryDate(payPeriod);
    const previousDefaultDate = useRef('');
    useEffect(() => {
        if (previousDefaultDate.current !== defaultEntryDateValue) {
            // Set a new day on change current pay period
            previousDefaultDate.current = defaultEntryDateValue;
            onChangeCommonValues({
                ...commonValues,
                entry_date: defaultEntryDateValue,
            });
        }
    }, [defaultEntryDateValue, commonValues, onChangeCommonValues, previousDefaultDate]);
}

export function getProjectAssignmentByProjectAndAssignmentIds(
    projectAssignments: IProjectWithAssignment[],
    projectId?: string,
    assignmentId?: string,
) {
    return projectAssignments.find(
        item => item.project_id === projectId && item.assignment?.id === assignmentId,
    ) || null;
}

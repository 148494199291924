import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useReduxModal } from 'shared/utils/hooks/useModal';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import CreateJobNumberModal from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/CreateJobNumberModal';
import { selectIsCreateJobNumberModalOpen } from 'modules/settings/submodules/clients/jobNumber/components/CreateJobNumber/store/selectors';
import { setCreateJobNumberModalState } from 'modules/settings/submodules/clients/jobNumber/store/actions';

export const CreateJobNumberButton = () => {
    const classes = useSettingsFormModalStyles();
    const {
        isModalOpened,
        openModal,
        closeModal,
    } = useReduxModal(selectIsCreateJobNumberModalOpen, setCreateJobNumberModalState);

    return (
        <Box mt={1}>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + Add new
            </Button>
            <CreateJobNumberModal
                title="Create New Job Number"
                isOpened={isModalOpened}
                onClose={closeModal}
                customClasses={classes}
                showCloseIcon
            />
        </Box>
    );
};

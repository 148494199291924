import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { FilterForm } from './FilterForm';
import { IFiltersContentProps } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { IFiltersAllOptions } from 'shared/models/Filters';

export function DefaultFiltersContent(
    { onClose, selectFiltersConfig, selectFilters, submitFilters, entryType }: IFiltersContentProps,
) {
    const dispatch = useDispatch();
    const classes = useFilterContentStyles();
    const filters = useSelector(selectFilters);

    const onSubmitCallback = useCallback((values: Partial<IFiltersAllOptions>) => {
        dispatch(submitFilters(values));
        onClose();
    }, [dispatch, submitFilters, onClose]);

    return (
        <Box className={classes.paper}>
            <Formik
                initialValues={filters}
                onSubmit={onSubmitCallback}
                validateOnBlur={false}
            >
                {props => (
                    <FilterForm
                        {...props}
                        selectFiltersConfig={selectFiltersConfig}
                        entryType={entryType}
                    />
                )}
            </Formik>
        </Box>
    );
}

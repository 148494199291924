import React from 'react';
import { Box } from '@material-ui/core';
import { PayrollButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollButton';
import { ISheetGroupIdWithClient, PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { ApproveButton } from 'modules/payrollProcessorHub/components/SheetStatusAction/ApproveButton';
import { RejectButton } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectButton';
import { parseSheetGroupId } from 'modules/payrollProcessorHub/store/helpers';
import { IPayrollSheetRow } from '../PayrollSheetTable/model';

export interface IPayrollSheetBatchActionsProps {
    checkedRowIds: string[];
    status: PayrollSheetTabs;
    sheets: IPayrollSheetRow[];
}

export const PayrollSheetBatchActions = ({ checkedRowIds, status, sheets }: IPayrollSheetBatchActionsProps) => {
    const groupIds = checkedRowIds.map(id => parseSheetGroupId(id));
    const groupWithClientIds: ISheetGroupIdWithClient[] = sheets.map(sheet => ({
        timeSheetId: sheet.time_sheet_id,
        expenseSheetId: sheet.expense_sheet_id,
        clientId: sheet.client_id,
    }));
    return (
        <Box
            display="flex"
            flexDirection="row"
            height="max-content"
        >
            {checkedRowIds.length > 0 && status === PayrollSheetTabs.APPROVED && (
                <PayrollButton groupIds={groupWithClientIds}/>
            )}
            {checkedRowIds.length > 0 && status === PayrollSheetTabs.SUBMITTED && (
                <>
                    <RejectButton
                        groupIds={groupIds}
                    />
                    <Box m={1}/>
                    <ApproveButton
                        groupIds={groupIds}
                    />
                </>
            )}
        </Box>
    );
};

import React, { useCallback, useMemo } from 'react';
import { ISheetViewProps } from 'modules/clients/content/supervisor/SheetsView/SheetsViewModel';
import SheetApprovalTable from 'shared/components/sheetApproval/filterAndActionControls/SheetApprovalTable';
import {
    getCheckboxCell,
} from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/checkedSheets';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import { useCheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { ISheet } from 'shared/models/sheet/Sheet';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { useIsMobile } from 'shared/utils/hooks/media';
import SheetQuickAction from 'shared/components/sheetApproval/sheetQuickActions/SheetQuickAction';
import { useModal } from 'shared/utils/hooks/useModal';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import clsx from 'clsx';
import Reject from 'shared/components/table/tableApprovalCells/Reject/Reject';
import Approve from 'shared/components/table/tableApprovalCells/Approve/Approve';

interface IApprovalQuickActions {
    isApprove: boolean;
    sheet: ISheet | null;
}

export default function SubmittedSheetsView({ sheets, sheetType, linked, sheetStatus, isLoading }: ISheetViewProps) {
    const classes = useSubmittedTableStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const [quickActionState, setQuickActionsState] = React.useState<IApprovalQuickActions>({
        isApprove: false,
        sheet: null,
    });

    const onSheetApproveQuickAction = useCallback(
        (isApprove: boolean, sheet: ISheet) => {
            setQuickActionsState({
                isApprove: isApprove,
                sheet: sheet,
            });
            onModalOpen();
        },
        [onModalOpen],
    );

    const actionsCell: ICellInfo<ISheetRow> = useMemo(() => ({
        key: 'actions',
        title: '',
        width: '88px',
        render: function ActionsCell({ className, sheet }: ISheetRow) {
            return (
                <div className={clsx(className, classes.iconCell, classes.actionsIcon)}>
                    <Reject onClick={() => onSheetApproveQuickAction(false, sheet)}/>
                    <Approve onClick={() => onSheetApproveQuickAction(true, sheet)}/>
                </div>
            );
        },
    }), [classes, onSheetApproveQuickAction]);

    const tableClasses = useSubmittedTableStyles();
    const isMobile = useIsMobile();
    const { checkedItems, checkedAll, onCheckAll, onCheck } = useCheckedItems<ISheet>(sheets);

    const checkboxCell = getCheckboxCell(checkedItems, onCheck, checkedAll, onCheckAll, tableClasses, isMobile);

    return (
        <>
            <SheetApprovalTable sheets={sheets} sheetStatus={sheetStatus}
                linked={linked} sheetType={sheetType}
                checkboxCell={checkboxCell}
                actionsCell={actionsCell}
                filteredSheets={sheets.filter(sheet => checkedItems[sheet.id])}
                isLoading={isLoading}
            />
            {quickActionState.sheet !== null && (
                <SheetQuickAction
                    sheetType={sheetType}
                    isOpened={isModalOpened}
                    linkedUsers={linked}
                    sheetApproval={quickActionState.sheet}
                    isApprove={quickActionState.isApprove}
                    onClose={onModalClose}
                />
            )}
        </>
    );

}

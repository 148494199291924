import { OverrideWorkGeoCode } from 'modules/settings/submodules/clients/store/models';

export interface IClientWorkLocationFormValues {
    name: string;
    client_id: string;
    client_name: string;
    location_id: string;
    address: string;
    address2: string;
    city: string;
    state_code: string;
    county: string;
    zip_code: string;
    override_work_geo_code: OverrideWorkGeoCode;
    geo_code: string;
}

export const defaultClientWorkLocationValues: IClientWorkLocationFormValues = {
    name: '',
    client_id: '',
    client_name: '',
    location_id: '',
    address: '',
    address2: '',
    city: '',
    state_code: '',
    county: '',
    zip_code: '',
    override_work_geo_code: OverrideWorkGeoCode.OverrideWithResidentGeoCode,
    geo_code: '',
};

import { createRequestActions, RequestType } from 'store/utils';

import {
    IGetInvoiceRecipientsPayload,
    IInvoiceRecipient,
} from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/models';

export const getInvoiceRecipients = createRequestActions<IGetInvoiceRecipientsPayload, IInvoiceRecipient[]>(
    RequestType.Get,
    'INVOICE_RECIPIENTS');

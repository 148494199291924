import React, { useCallback } from 'react';
import { Create, SvgIconComponent } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

interface IEditButtonProps {
    id: string;
    editAction: (payload: string) => AnyAction;
    Icon?: SvgIconComponent;
    title?: string;
}

export const EditItemButton = ({ id, editAction, title, Icon = Create }: IEditButtonProps) => {
    const dispatch = useDispatch();
    const openModal = useCallback(() => {
        dispatch(editAction(id));
    }, [dispatch, editAction, id]);

    return (
        <IconButton
            onClick={openModal}
            title={title}
            color="primary"
            data-testid="details-button"
        >
            <Icon/>
        </IconButton>
    );
};

import React, { useCallback } from 'react';
import clsx from 'clsx';
import { setWorkingEntryTypeFilter } from 'modules/timeAndExpense/components/WorkingPage/store/actions';
import { selectWorkingEntryTypeFilter } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import { Box, Hidden } from '@material-ui/core';
import useFilterAndActionControlsStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FilterAndActionControlsStyles';
import { useDispatch, useSelector } from 'react-redux';
import PayPeriodSelector from 'shared/components/filters/PayPeriodSelector';
import { EntryType } from 'shared/models/sheet/Sheet';
import EntryButtonFilter from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/EntryButtonFilter';
import useActionButtonStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { SheetsInProgressFilters } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/SheetsInProgressFilters';

interface IFilterAndActionControlsProps {
    hasEntries: boolean;
    entryTypeAllowed: EntryType[];
}

function FilterAndActionControls({
    hasEntries,
    entryTypeAllowed,
}: IFilterAndActionControlsProps,
) {
    const classes = useFilterAndActionControlsStyles();
    const classesFilter = useActionButtonStyles();

    const dispatch = useDispatch();
    const entryTypeFilter = useSelector(selectWorkingEntryTypeFilter);

    const setEntryTypeFilter = useCallback((newEntryTypeFilter: EntryType) => {
        const filter = newEntryTypeFilter !== entryTypeFilter ? newEntryTypeFilter : null;
        dispatch(setWorkingEntryTypeFilter(filter));
    }, [entryTypeFilter, dispatch]);

    return (
        <Box className={classes.rootContainer}>
            <Box className={classes.actionsContainer}>
                <Hidden xsDown>
                    <Box className={clsx(classes.controlsContainer, { [classes.hidden]: !hasEntries })}>
                        {
                            entryTypeAllowed.length > 1
                            && (
                                <>
                                    <EntryButtonFilter
                                        isActive={entryTypeFilter === EntryType.TIME}
                                        onClick={() => setEntryTypeFilter(EntryType.TIME)}
                                        buttonClass={classesFilter.blue}
                                        labelClass={classesFilter.label}
                                        text="TIME"
                                    />
                                    <EntryButtonFilter
                                        isActive={entryTypeFilter === EntryType.EXPENSE}
                                        onClick={() => setEntryTypeFilter(EntryType.EXPENSE)}
                                        buttonClass={classesFilter.green}
                                        labelClass={classesFilter.label}
                                        text="EXPENSE"
                                    />
                                </>
                            )
                        }
                        <SheetsInProgressFilters activeEntryType={entryTypeFilter}/>
                    </Box>
                </Hidden>
                <Box className={classes.controlsContainer}>
                    <PayPeriodSelector/>
                </Box>
            </Box>
        </Box>
    );
}

export default FilterAndActionControls;

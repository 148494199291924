import { SheetEntryTable } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/SheetEntryTable';
import { SheetGroupActions } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/SheetGroupActions';
import { SheetGroupDetailByType } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/SheetGroupDetailByType';
import { SheetGroupTotalByType } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/SheetGroupTotalByType';
import { PayrollStatus } from 'modules/payrollProcessorHub/components/PayrollStatus/PayrollStatus';
import React, { useState } from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';

export interface ISheetGroupDetailProp {
    sheetGroup: IGroupedSheetCalculation;
}

export const SheetGroupDetail = ({ sheetGroup }: ISheetGroupDetailProp) => {
    const classes = useGroupedSheetDetailStyles();
    const [activeTab, setActiveTab] = useState(sheetGroup.time_sheet_id ? EntryType.TIME : EntryType.EXPENSE);

    return (
        <Box>
            <ButtonGroup
                variant="contained"
                color="primary"
                disabled={!sheetGroup.time_sheet_id || !sheetGroup.expense_sheet_id}
                className={classes.typeSwitch}
            >
                <Button
                    onClick={() => setActiveTab(EntryType.TIME)}
                    variant={activeTab === EntryType.TIME ? 'contained' : 'outlined'}
                    className={classes.typeSwitchButton}
                >
                    Time
                </Button>
                <Button
                    onClick={() => setActiveTab(EntryType.EXPENSE)}
                    variant={activeTab === EntryType.EXPENSE ? 'contained' : 'outlined'}
                    className={classes.typeSwitchButton}
                >
                    Expense
                </Button>
            </ButtonGroup>
            <Box component="section" p={2}>
                <Box display="flex" flexDirection="row">
                    <SheetGroupDetailByType
                        sheetGroup={sheetGroup}
                        type={activeTab}
                    />
                    <PayrollStatus status={sheetGroup.payroll_status}/>
                </Box>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={3}
                >
                    <Box justifyContent="flex-start">
                        <SheetGroupActions sheetGroup={sheetGroup}/>
                    </Box>
                    <Box justifyContent="flex-end">
                        <SheetGroupTotalByType
                            sheetGroup={sheetGroup}
                            type={activeTab}
                        />
                    </Box>
                </Box>
            </Box>
            <SheetEntryTable
                sheetGroup={sheetGroup}
                type={activeTab}
            />
        </Box>
    );
};

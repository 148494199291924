import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTime12Hours } from './useTime12Hours';
import { TextField, TextFieldProps } from '@material-ui/core';

const fixValue = (value?: unknown) => value ? `${value}` : '';

export function Time12HoursKeyboardInput({ onChange, value, onBlur, ...rest }: TextFieldProps) {
    const [localValue, setValue] = useState<string>(fixValue(value));
    const onChangeFunc = (changedValue: string) => {
        setValue(changedValue);
    };

    const { onChange: onChangeFromHook, value: valueFromHook } = useTime12Hours({
        value: localValue,
        onChange: (evt: ChangeEvent<HTMLInputElement>) => {
            onChangeFunc(evt.target.value);
        },
    });

    useEffect(() => {
        setValue(fixValue(value));
    }, [value]);

    return (
        <TextField
            {...rest}
            value={valueFromHook}
            onChange={onChangeFromHook}
            onBlur={event => {
                if (onChange) {
                    onChange(event);
                }
                setTimeout(() => {
                    if (onBlur) {
                        onBlur(event);
                    }
                }, 1); // onChange logics is in callback, need to wait;
            }}
            inputProps={{ 'data-testid': 'time-12-hours-input' }}
        />
    );
}

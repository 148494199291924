/* eslint-disable react/display-name */
import { useFeature } from '@optimizely/react-sdk';
import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { selectPayCodesById } from 'modules/settings/submodules/clients/payCodes/store/selectors';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { EntryType } from 'shared/models/sheet/Sheet';
import { selectCurrentClientHasJobNumberConfiguration } from 'store/entities/clients/clientsSelectors';
import { deleteActivity } from 'store/entities/configuration/configurationAction';
import { ActivityTag, IActivity } from 'store/entities/configuration/configurationModel';
import { selectActivities, selectIsActivitiesLoading } from 'store/entities/configuration/configurationSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { Box } from '@material-ui/core';
import { Status } from 'shared/models/Status';
import { UpdateActivityStatusSelect } from 'modules/settings/submodules/clients/activities/components/ClientActivitiesTable/components/UpdateStatusSelect/UpdateActivityStatusSelect';

interface IActivityRow extends IActivity {
    className?: string;
}

export const ClientActivitiesTable = () => {
    const tableClasses = useDefaultTableStyles();
    const activities = useSelector(selectActivities);
    const payCodesById = useSelector(selectPayCodesById);
    const isLoading = useSelector(selectIsActivitiesLoading);
    const clientHasJobNumberConfiguration = useSelector(selectCurrentClientHasJobNumberConfiguration);
    const [isShortNameDisplayed] = useFeature(FeatureSwitches.displayActivityShortDescription);
    const [isShowZipCodeDisplayed] = useFeature(FeatureSwitches.displayActivityShowZipCode);
    const [showActivityStatus] = useFeature(FeatureSwitches.enableChangingActivityStatus);
    const [enableNonProduction] = useFeature(FeatureSwitches.enableActivityNonProduction);
    const [displayDefault] = useFeature(FeatureSwitches.displayDefaultPropertyInSettingsActivities);

    const activitiesCells = useMemo(() => [
        {
            key: 'activity',
            title: 'activity',
            render: ({ description, className }: IActivityRow) => (
                <PlainText className={className} value={description || undefined}/>
            ),
        },
        ...(isShortNameDisplayed ? [{
            key: 'short',
            title: 'short name',
            render: ({ short_description, className }: IActivityRow) => (
                <PlainText className={className} value={short_description}/>
            ),
        }] : []),
        {
            key: 'type',
            title: 'type',
            render: ({ sheet_type, className }: IActivityRow) => (
                <PlainText className={className} value={sheet_type}/>
            ),
        },
        {
            key: 'data_type',
            title: 'Data type',
            render: ({ className, data_type }: IActivityRow) => (
                <PlainText className={className} value={data_type}/>
            ),
        },
        {
            key: 'pay_code',
            title: 'Pay code',
            render: ({ pay_code_id, className }: IActivityRow) => (
                <PlainText className={className} value={payCodesById[pay_code_id]?.description}/>
            ),
        },
        ...(isShowZipCodeDisplayed ? [{
            key: 'show_zip_code',
            title: 'Show Zip Code',
            render: ({ show_zip_code, className }: IActivityRow) => (
                <PlainText className={className} value={show_zip_code ? 'Yes' : 'No'}/>
            ),
        }] : []),
        ...(enableNonProduction && clientHasJobNumberConfiguration ? [{
            key: 'non_production',
            title: 'Production',
            render: ({ tags, className, sheet_type }: IActivityRow) => {
                let value = '';
                if (sheet_type === EntryType.TIME) {
                    value = tags?.includes(ActivityTag.NonProduction) ? 'Non production' : 'Production';
                }
                return (
                    <PlainText className={className} value={value}/>
                );
            },
        }] : []),
        ...(displayDefault ? [{
            key: 'default',
            title: 'default',
            render: ( { className, tags }: IActivityRow) => {
                return (
                    <PlainText className={className} value={tags?.includes(ActivityTag.Default) ? 'Default' : 'Non default'}/>
                );
            },
        }] : []),
        ...(showActivityStatus ? [{
            key: 'status',
            title: 'status',
            render: function StatusCell({ id, is_active, className }: IActivityRow) {
                const currentStatus = is_active ? Status.active : Status.inactive;
                return (
                    <Box className={className}>
                        <UpdateActivityStatusSelect id={id} status={currentStatus}
                            className={tableClasses.statusSelect}/>
                    </Box>
                );
            },
        }] : []),
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: ({ className, id }: IActivityRow) => {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <DeleteItemButton
                            confirmation="Are you sure you want to delete activity?"
                            id={id}
                            deleteAction={deleteActivity.init}
                        />
                    </div>
                );
            },
        },
    ], [tableClasses, payCodesById, isShortNameDisplayed, isShowZipCodeDisplayed,
        enableNonProduction, clientHasJobNumberConfiguration, displayDefault, showActivityStatus]);

    return (
        <GridTable
            rowData={activities}
            cells={activitiesCells}
            getKey={activity => activity.id}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            stickyHeader
        />
    );
};

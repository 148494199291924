import {
    IClient,
    IClientInfo,
    IFieldsConfigurationWrapper,
    IPayrollInfo,
    IPaySettings,
    IThemeConfigurationWrapper,
} from 'store/entities/clients/clientsModel';
import { ActionsReturnTypes, createActions, createRequestActions, createSingleAction, RequestType } from 'store/utils';

const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const setClientId = createSingleAction<string | null, typeof SET_CLIENT_ID>(SET_CLIENT_ID);

export const SET_TENANT_SUCCESS = 'SET_TENANT_SUCCESS';
export const setTenantSuccess = createSingleAction<string | null, typeof SET_TENANT_SUCCESS>(SET_TENANT_SUCCESS);

export const getClientFieldsConfiguration = createRequestActions<string, IFieldsConfigurationWrapper>(
    RequestType.Get,
    'CLIENT_FIELDS_CONFIGURATION',
    'clients',
);

const GET_CLIENT_CONFIGURATION_THEME = 'GET_CLIENT_CONFIGURATION_THEME';
const GET_CLIENT_CONFIGURATION_THEME_SUCCESS = 'GET_CLIENT_CONFIGURATION_THEME_SUCCESS';
const GET_CLIENT_CONFIGURATION_THEME_ERROR = 'GET_CLIENT_CONFIGURATION_THEME_ERROR';

export const getClientConfigurationTheme = createActions<void, Array<IThemeConfigurationWrapper>, any,
    typeof GET_CLIENT_CONFIGURATION_THEME,
    typeof GET_CLIENT_CONFIGURATION_THEME_SUCCESS, typeof GET_CLIENT_CONFIGURATION_THEME_ERROR
>(
    GET_CLIENT_CONFIGURATION_THEME, GET_CLIENT_CONFIGURATION_THEME_SUCCESS, GET_CLIENT_CONFIGURATION_THEME_ERROR,
);

export const SET_PAYROLL_PROCESSING = 'SET_PAYROLL_PROCESSING';
export const SET_PAYROLL_PROCESSING_SUCCESS = 'SET_PAYROLL_PROCESSING_SUCCESS';
export const SET_PAYROLL_PROCESSING_ERROR = 'SET_PAYROLL_PROCESSING_ERROR';

export const setPayrollProcessing = createActions<IPayrollInfo, boolean, any,
    typeof SET_PAYROLL_PROCESSING,
    typeof SET_PAYROLL_PROCESSING_SUCCESS, typeof SET_PAYROLL_PROCESSING_ERROR
>(
    SET_PAYROLL_PROCESSING, SET_PAYROLL_PROCESSING_SUCCESS, SET_PAYROLL_PROCESSING_ERROR,
);

const GET_PAY_SETTINGS = 'GET_PAY_SETTINGS';
const GET_PAY_SETTINGS_SUCCESS = 'GET_PAY_SETTINGS_SUCCESS';
const GET_PAY_SETTINGS_ERROR = 'GET_PAY_SETTINGS_ERROR';

export const getPaySettings = createActions<
string,
IPaySettings,
any,
    typeof GET_PAY_SETTINGS,
    typeof GET_PAY_SETTINGS_SUCCESS,
    typeof GET_PAY_SETTINGS_ERROR
>(
    GET_PAY_SETTINGS,
    GET_PAY_SETTINGS_SUCCESS,
    GET_PAY_SETTINGS_ERROR,
);

export const SET_CLIENTS = 'SET_CLIENTS';
export const setClients = createSingleAction<Array<IClient>, typeof SET_CLIENTS>(SET_CLIENTS);

export const SET_CLIENTS_INFO = 'SET_CLIENTS_INFO';
export const setClientsInfo = createSingleAction<Array<IClientInfo>, typeof SET_CLIENTS_INFO>(SET_CLIENTS_INFO);

const REFRESH_CLIENTS = 'REFRESH_CLIENTS';
export const refreshClients = createSingleAction<void, typeof REFRESH_CLIENTS>(REFRESH_CLIENTS);

export type ClientAction =
    | ActionsReturnTypes<typeof getClientFieldsConfiguration>
    | ActionsReturnTypes<typeof getClientConfigurationTheme>
    | ActionsReturnTypes<typeof setPayrollProcessing>
    | ReturnType<typeof setClients>
    | ReturnType<typeof setClientsInfo>
    | ReturnType<typeof setClientId>
    | ReturnType<typeof setTenantSuccess>
    | ActionsReturnTypes<typeof getPaySettings>;

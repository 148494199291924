import { IGetUsersResponse } from 'shared/models/User';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import { IEmployeeFilter } from './models';

export const INITIAL_LOAD_EMPLOYEES = 'settings/employees/INITIAL_LOAD_EMPLOYEES';
export const initialLoadEmployees = createSingleAction<void, typeof INITIAL_LOAD_EMPLOYEES>(
    INITIAL_LOAD_EMPLOYEES,
);

export const GET_MORE_EMPLOYEES = 'settings/employees/GET_MORE_EMPLOYEES';
export const GET_MORE_EMPLOYEES_SUCCESS = 'settings/employees/GET_MORE_EMPLOYEES_SUCCESS';
export const GET_MORE_EMPLOYEES_ERROR = 'settings/employees/GET_MORE_EMPLOYEES_ERROR';

export const getMoreEmployeesAction = createActions<
void,
IGetUsersResponse,
any,
    typeof GET_MORE_EMPLOYEES,
    typeof GET_MORE_EMPLOYEES_SUCCESS,
    typeof GET_MORE_EMPLOYEES_ERROR
>(
    GET_MORE_EMPLOYEES,
    GET_MORE_EMPLOYEES_SUCCESS,
    GET_MORE_EMPLOYEES_ERROR,
);

export const SET_EMPLOYEES_FILTER = 'settings/employees/SET_EMPLOYEES_FILTER';
export const setEmployeesFilter = createSingleAction<IEmployeeFilter, typeof SET_EMPLOYEES_FILTER>(
    SET_EMPLOYEES_FILTER,
);

export type EmployeesActions =
    | ActionsReturnTypes<typeof getMoreEmployeesAction>
    | ReturnType<typeof initialLoadEmployees>
    | ReturnType<typeof setEmployeesFilter>;

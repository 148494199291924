import React, { useCallback } from 'react';
import { Chip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

interface IFilterChipProps<NameType, ValueType> {
    values: Record<string, ValueType>;
    name: NameType;
    id: string;
    onDelete: (key: NameType) => void;
    getText: (value: ValueType) => string;
    classes: Record<'root' | 'deleteIcon', string>;
}

export default function FilterChip<NameType, ValueType>({
    values, id, onDelete, name, getText, classes,
}: IFilterChipProps<NameType, ValueType>) {
    const handleDelete = useCallback(() => onDelete(name), [name, onDelete]);

    return values[id] ? (
        <Chip clickable
            classes={classes}
            label={getText(values[id])}
            onDelete={handleDelete}
            onClick={handleDelete}
            deleteIcon={<Clear/>}
        />
    ) : null;
}

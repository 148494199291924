export enum MenuItemEnum {
    Dashboard = 'Dashboard',
    OfferLetter = 'Offer Letter',
    TimeApproval = 'Time Approval',
    ExpenseApproval = 'Expense Approval',
    TimeExpenses = 'Time & Expenses',
    MyOfferLetter = 'My Offer Letter',
    PayrollDashboard = 'Payroll Dashboard',
    TimeExpensesManagement = 'T&E sheets',
}

import React, { useEffect, useState } from 'react';
import { Collapse, Button } from '@material-ui/core';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { TMenuCategoryChild } from 'shared/models/Global';
import { VerticalMenuItem } from 'shared/components/verticalMenu/VerticalMenuItem';

interface VerticalMenuItemsProps {
    menuItems: TMenuCategoryChild[];
    isMinimized: boolean;
}

export const VerticalMenuItems = ({ menuItems, isMinimized }: VerticalMenuItemsProps) => {
    const classes = useMenuStyles();
    const [menuExpanded, setMenuExpanded] = useState(false);
    const nonCollapseItemsMaxCount = 9;
    const nonCollapsable = menuItems.slice(0, nonCollapseItemsMaxCount);
    const collapsable = menuItems.slice(nonCollapseItemsMaxCount);

    useEffect(() => {
        if (isMinimized) {
            setMenuExpanded(false);
        }
    }, [isMinimized, setMenuExpanded]);

    return (
        <>
            {nonCollapsable.map(menuItem => (
                <VerticalMenuItem
                    key={menuItem.id || menuItem.title}
                    menuItem={menuItem}
                />))}
            {collapsable.length > 0 && (
                <>
                    <Collapse
                        in={menuExpanded}
                        timeout="auto"
                        unmountOnExit
                    >
                        {collapsable.map(menuItem => (
                            <VerticalMenuItem
                                key={menuItem.id || menuItem.title}
                                menuItem={menuItem}
                            />
                        ))}
                    </Collapse>
                    {!isMinimized && (
                        <Button
                            className={classes.expandMenuButton}
                            onClick={() => setMenuExpanded(!menuExpanded)}
                            disableRipple
                        >
                            {menuExpanded ? 'Show less' : 'Show more'}
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { nullOrValue } from 'shared/utils/converters/urlParams';
import { selectIsEditedMissingSheetsHasChanges } from 'modules/payrollProcessorHub/store/selectors';
import { EditPayrollSheetPageContent } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollSheetPageContent';
import { LeavePageConfirmation } from 'modules/payrollProcessorHub/components/EditSheet/LeavePageConfirmation';

export const EditPayrollSheetPage = () => {
    const {
        timeSheetId: timeSheetIdParam,
        expenseSheetId: expenseSheetIdParam,
    } = useParams<{ timeSheetId: string; expenseSheetId: string; clientId: string }>();
    const timeSheetId = nullOrValue(timeSheetIdParam);
    const expenseSheetId = nullOrValue(expenseSheetIdParam);
    const groupId = useMemo(() => ({
        timeSheetId,
        expenseSheetId,
    }), [timeSheetId, expenseSheetId]);
    const missingSheetHasChanges = useSelector(selectIsEditedMissingSheetsHasChanges(groupId));
    const onLeaveMessage = `
Sheet status has become overdue and this sheet will no longer be available on the missing tab.
Are you sure you want to leave the page without submit?
You will not be able to submit this sheet later.`;
    const handleClosePage = useCallback(() => {
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
    }, []);

    return (
        <LeavePageConfirmation
            where={missingSheetHasChanges}
            message={onLeaveMessage}
            onClose={handleClosePage}
        >
            {({ onClose, openConfirmation }) => (
                <EditPayrollSheetPageContent
                    groupId={groupId}
                    onClose={onClose}
                    openConfirmation={openConfirmation}
                />
            )}
        </LeavePageConfirmation>
    );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Hidden } from '@material-ui/core';
import { SummaryBlock, SummaryColors } from 'modules/payrollProcessorHub/components/SheetSummary/SummaryBlock';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { selectGroupedSheetsSummary } from 'modules/payrollProcessorHub/store/selectors';
import { useSheetSummaryStyles } from 'modules/payrollProcessorHub/components/SheetSummary/styles';
import DottedDivider from 'shared/components/divider/DottedDivider';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { timePaymentFormatter } from 'shared/utils/formatters/timePaymentFormatter';
import { MobileSheetSummary } from 'modules/payrollProcessorHub/components/SheetSummary/MobileSheetSummary';
import { useActivePayrollSheetStatus } from 'modules/payrollProcessorHub/store/helpers';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';

export interface ISummaryBlockData {
    value: string;
    title: string;
    color: SummaryColors;
    visible: boolean;
}

export default function SheetSummary() {
    const classes = useSheetSummaryStyles();

    const activeTab = useActivePayrollSheetStatus();
    const data = useSelector(selectGroupedSheetsSummary);

    const blocks: ISummaryBlockData[] = data ? [
        {
            value: timePaymentFormatter(data.time_total_rt_hours, data.time_total_rt_dollars),
            title: 'total reg hrs | pay',
            color: 'blue',
            visible: activeTab === PayrollSheetTabs.APPROVED,
        },
        {
            value: data.time_total_rt_hours ? formatDecimalHoursStringAsHoursAndMinutes(data.time_total_rt_hours) : '0:00',
            title: 'total reg hrs',
            color: 'blue',
            visible: activeTab !== PayrollSheetTabs.APPROVED,
        },
        {
            value: timePaymentFormatter(data.time_total_ot_hours, data.time_total_ot_dollars),
            title: 'total OT hrs | pay',
            color: 'orange',
            visible: activeTab === PayrollSheetTabs.APPROVED,
        },
        {
            value: data.time_total_ot_hours ? formatDecimalHoursStringAsHoursAndMinutes(data.time_total_ot_hours) : '0:00',
            title: 'total OT hrs',
            color: 'orange',
            visible: activeTab !== PayrollSheetTabs.APPROVED,
        },
        {
            value: formatDollars(data.expense_total_dollars),
            title: 'total expenses',
            color: 'green',
            visible: activeTab === PayrollSheetTabs.APPROVED,
        },
        {
            value: formatDollars(data.total_dollars),
            title: 'total pay',
            color: 'green',
            visible: true,
        },
    ].filter(block => block.visible) as ISummaryBlockData[] : [];

    return (
        <>
            <Hidden xsDown>
                <Box className={classes.root}>
                    {blocks.map((block, index) => (
                        <>
                            {index > 0 && <DottedDivider key={`divider-${block.title}`} height={40}/>}
                            <SummaryBlock
                                key={block.title}
                                value={block.value}
                                title={block.title}
                                color={block.color}
                            />
                        </>
                    ))}
                </Box>
            </Hidden>
            <Hidden smUp>
                <Box className={classes.root}>
                    <MobileSheetSummary blocks={blocks}/>
                </Box>
            </Hidden>
        </>
    );
}

import { useSelector } from 'react-redux';
import { EntryType, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { generatePath, useRouteMatch } from 'react-router-dom';

export function useOpenSheetDetail() {
    const clientId = useSelector(selectCurrentClientId);
    const isTimeAndExpensePage = useRouteMatch({
        path: routes.CLIENT.TIME_AND_EXPENSE.ROOT,
        exact: false,
    });
    const getSheetDetailRoute = (entryType: EntryType) => {
        if (isTimeAndExpensePage) {
            return routes.CLIENT.TIME_AND_EXPENSE.SHEET_DETAIL;
        }
        if (entryType === EntryType.TIME) {
            return routes.CLIENT.TIME_APPROVAL.SHEET_DETAIL;
        }
        return routes.CLIENT.EXPENSE_APPROVAL.SHEET_DETAIL;
    };
    const handleSheetClick = (clickInfo: ISheetClickInfo) => {
        const route = getSheetDetailRoute(clickInfo.entryType);
        browserHistory.push(generatePath(route, {
            client_id: clientId || '',
            sheetId: clickInfo.sheetId,
            sheetType: clickInfo.entryType,
        }));
    };

    return handleSheetClick;
}

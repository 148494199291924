import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IGlobalRole } from 'shared/models/User';
import { selectGlobalRoles } from 'store/entities/users/selectors';
import { GlobalRoles } from 'store/entities/users/model';

interface IGlobalRoleSelectProps extends IFormFieldProps, IFormSelect {}

export const GlobalRoleSelect = (props: IGlobalRoleSelectProps) => {
    const whitelist = [GlobalRoles.AccountManager, GlobalRoles.PayrollCoordinator, GlobalRoles.SuperAdmin];
    const globalRoles = useSelector(selectGlobalRoles).filter(role => whitelist.includes(role.name));

    return (
        <FormSelect
            getKey={(role: IGlobalRole) => role.id}
            getText={(role: IGlobalRole) => role.name}
            options={globalRoles}
            {...props}
        />
    );
};

import React from 'react';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { useUploadedFiles } from 'shared/utils/hooks/useFileUpload';
import { ReceiptCellCommon } from 'shared/components/table/EntriesTable/cellsComponents/ReceiptCellCommon';

export default function ReceiptCell(
    entry: IEntry) {

    const { onAttachmentRemove } = useUploadedFiles();

    return (
        <>
            {entry.entry_type === EntryType.EXPENSE && entry.sheet_entry_attachments.length > 0 && (
                <ReceiptCellCommon
                    files={entry.sheet_entry_attachments}
                    onDelete={attachment => onAttachmentRemove(attachment, entry.id)}
                />
            )}
        </>
    );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { IEntity } from 'shared/models/Entity';

export type CheckedItems = Record<string, boolean>;
export type OnCheck = (checked: boolean, id: string) => void;

export type CheckBoxCellActions = {
    onCheck: OnCheck;
    checkedAll: boolean;
    onCheckAll: (checked: boolean) => void;
    setCheckedItems: (checked: CheckedItems) => void;
    checkedItems: CheckedItems;
}

export function useCheckedItems<ItemType extends IEntity>(items: Array<ItemType>): CheckBoxCellActions {
    const [checkedItems, setCheckedItems] = useState<CheckedItems>({});

    const checkedAll = useMemo(
        () => items.length > 0 && items.every((item: ItemType) => checkedItems[item.id]),
        [items, checkedItems],
    );

    const onCheck: OnCheck = useCallback(
        (checked: boolean, sheetId: string) => {
            setCheckedItems({
                ...checkedItems,
                [sheetId]: checked,
            });
        },
        [setCheckedItems, checkedItems],
    );

    const onCheckAll = useCallback((checked: boolean) => {
        const checkedAllItems: CheckedItems = {};
        if (checked) {
            items.forEach(item => {
                checkedAllItems[item.id] = true;
            });
        }

        setCheckedItems(checkedAllItems);
    }, [items, setCheckedItems]);

    useEffect(() => {
        const itemsIds = items.map(item => item.id);
        const results = Object.entries(checkedItems).reduce((acc, checkedItem) => {
            return itemsIds.includes(checkedItem[0]) ? {
                ...acc,
                [checkedItem[0]]: checkedItem[1],
            } : acc;
        }, {});

        if (Object.keys(results).length !== Object.keys(checkedItems).length) {
            setCheckedItems(results);
        }
    }, [items, checkedItems, setCheckedItems]);

    return {
        checkedItems,
        onCheck,
        checkedAll,
        onCheckAll,
        setCheckedItems,
    };

}

import React from 'react';
import { List } from '@material-ui/core';
import { SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { IOfferLetter } from '../../store/model';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';

interface IOfferLetterInfoProps {
    offerLetter: IOfferLetter;
}

export default function OfferLetterInfo({ offerLetter }: IOfferLetterInfoProps) {
    const classes = useSheetDetailStyles(); // TODO: We need to use real shared classes & components
    const data = [
        {
            title: 'Email',
            value: offerLetter.email,
        },
        {
            title: 'Offer Date',
            value: offerLetter.offer_date,
        },
        {
            title: 'Position',
            value: offerLetter.position.name,
        },
        {
            title: 'Location',
            value: offerLetter.location.name,
        },
        {
            title: 'Start Date',
            value: offerLetter.start_date,
        },
    ];
    return (
        <List className={classes.listWrapper}>
            {data.map(item => (
                <>
                    {item.value && (
                        <SheetInfoItem key={item.title} data={item}/>
                    )}
                </>
            ))}
        </List>
    );
}

import { IOfferLetterRow, IOfferLetterWithPdf } from 'modules/offerLetter/components/OfferLetterTable/cells';
import {
    departmentCell,
    getOfferLetterPdfCell,
    getOfferLetterStatusCell,
    getStatusPayRateMobileCell,
    locationCell,
    offerDateCell,
    payRateDesktopCell,
    positionCell,
    startDateCell,
    getActionCell,
} from 'modules/offerLetter/components/OfferLetterTable/commonCells';
import { useOfferLetterEmployeeStyles } from 'modules/offerLetter/OfferLetterEmployee/OfferLetterEmployeeStyles';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { getRejectReasonTitle, getRescissionReasonTitle } from 'modules/offerLetter/store/utils';
import React, { useCallback } from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { useTableStyles } from 'shared/styles/tableStyles';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IClient } from 'store/entities/clients/clientsModel';

export interface IOfferLetterRowWithClient extends IOfferLetterRow {
    client?: IClient;
}

export function useEmployeeOfferCells(openDetail: (offerLetter: IOfferLetterWithPdf) => void) {
    const tableClasses = useTableStyles();
    const classes = useOfferLetterEmployeeStyles();
    const isMobile = useIsMobile();

    return isMobile ? [
        {
            key: 'FullInfo',
            title: '',
            render: function FullInfoCell({ className, offerLetter, client }: IOfferLetterRowWithClient) {
                const items = [
                    client?.name || '',
                    `Offer Date: ${formatShortDate(offerLetter.offer_date)}`,
                    `Start Date: ${formatShortDate(offerLetter.start_date)}`,
                ];

                if (
                    ([OfferLetterStatusSlug.Declined, OfferLetterStatusSlug.Rescinded] as Array<string>)
                        .includes(offerLetter.status.slug)
                ) {
                    items.push(`Reason: ${getRejectReasonTitle(offerLetter.rejection_reason)}`);
                } else if (OfferLetterStatusSlug.Expired === offerLetter.status.slug) {
                    items.push(`Expiration Date: ${formatShortDate(offerLetter.start_date)}`);
                }

                const mobileAction = useCallback(() => {
                    openDetail(offerLetter);
                }, [offerLetter]);

                return (
                    <MobileListCell
                        className={className}
                        listClassName={classes.mobileListWrapper}
                        title={offerLetter.position.name}
                        items={items}
                        action={mobileAction}
                    />
                );
            },
        },
        getStatusPayRateMobileCell(classes, openDetail),
    ] : [
        positionCell,
        {
            ...locationCell,
            title: 'work location',
        },
        departmentCell,
        getOfferLetterStatusCell(tableClasses),
        {
            key: 'client',
            title: 'client',
            render: function ClientCell({ className, client }: IOfferLetterRowWithClient) {
                return (
                    <PlainText className={className} value={client?.name}/>
                );
            },
        },
        offerDateCell,
        startDateCell,
        payRateDesktopCell,
        getOfferLetterPdfCell(tableClasses, openDetail),
        {
            key: 'reason',
            title: 'reason',
            render: function LocationCell({ offerLetter, className }: IOfferLetterRow) {
                const rowLabel = (() => {
                    switch (offerLetter.status.slug) {
                        case OfferLetterStatusSlug.Rescinded:
                            return getRescissionReasonTitle(offerLetter);
                        case OfferLetterStatusSlug.Declined:
                            return getRejectReasonTitle(offerLetter.rejection_reason);
                        default:
                            return '';
                    }
                })();
                return (
                    <PlainText className={className} value={rowLabel}/>
                );
            },
        },
        getActionCell(tableClasses),
    ];
}

import { Permission } from 'store/components/auth/authModels';
import { Typography } from '@material-ui/core';
import { SpecialUserAutocomplete } from 'shared/components/autocomplete/SpecialUserAutocomplete';
import React from 'react';
import { useStyles } from 'modules/offerLetter/components/OfferLetterForm/styles';
import { selectClientApprovalLevels } from 'store/entities/clients/clientsSelectors';
import { useSelector } from 'react-redux';

export function MultipleApproversFormFields() {
    const numberOfApprovers = useSelector(selectClientApprovalLevels);
    const classes = useStyles();
    return (
        <>
            {
                Array.from(Array(numberOfApprovers).keys()).map(approverIndex => {
                    const approverNumber = approverIndex + 1;
                    return (
                        <SpecialUserAutocomplete
                            feature="purpose"
                            featureValue={Permission.ApproveSheets}
                            key={`approver_${approverNumber}`}
                            name={`approvers.${approverIndex}`}
                            label={`Approver${numberOfApprovers < 2 ? '' : ` #${approverNumber}`}`}
                            className={classes.field}
                            Label={numberOfApprovers > 1 ? null : <Typography variant="body2" gutterBottom>Assign to </Typography>}
                        />
                    );
                })
            }
        </>
    );
}

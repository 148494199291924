import { Box } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { IOfferLetterTemplate } from 'modules/offerLetter/store/templates/models';

import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { shortDateFormat } from 'shared/models/Dates';
import clsx from 'clsx';
import { removeOfferLetterTemplate } from 'modules/offerLetter/store/templates/actions';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { UpdateOfferLetterTemplateStatus } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateList/UpdateOfferLetterTemplateStatus';
import PreviewOfferLetterTemplate from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateList/PreviewOfferLetter';

export interface IOfferLetterTemplateRow extends IOfferLetterTemplate {
    className?: string;
}

export const useOfferLetterTemplateCell = (classes: Record<string, string>): ICellInfo<IOfferLetterTemplateRow>[] => ([
    {
        key: 'name',
        title: 'Name',
        width: '2.2fr',
        render: function NameCell({ name, className }: IOfferLetterTemplateRow) {
            return (
                <PlainText className={className} value={name}/>
            );
        },
    },
    {
        key: 'created',
        title: 'created',
        render: function CreatedCell({ createdAt, className }: IOfferLetterTemplateRow){
            return (
                <PlainText className={className} value={moment(createdAt).format(shortDateFormat)}/>
            );
        },
    },
    {
        key: 'status',
        title: 'status',
        render: function StatusCell({ id, status, className }: IOfferLetterTemplateRow) {
            return (
                <Box className={className}>
                    <UpdateOfferLetterTemplateStatus
                        id={id}
                        status={status}
                        className={classes.statusSelect}
                    />
                </Box>
            );
        },
    },
    {
        key: 'preview',
        title: '',
        width: '100px',
        render: function PreviewCell({ className, id }: IOfferLetterTemplateRow) {
            return (
                <div className={className}>
                    <PreviewOfferLetterTemplate id={id}/>
                </div>
            );
        },
    },
    {
        key: 'actions',
        title: '',
        width: '100px',
        render: function ActionsCell({ id, className }: IOfferLetterTemplateRow) {
            return (
                <div className={clsx(className, classes.iconCell)}>
                    <DeleteItemButton
                        confirmation="Are you sure you want to delete offer letter template?"
                        id={id}
                        deleteAction={removeOfferLetterTemplate.init}
                    />
                </div>
            );
        },
    },
]);

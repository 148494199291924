import React from 'react';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

interface IPlainTextProps {
    className?: string;
    value?: string;
}

export const usePlainTextStyles = makeHighPriorityStyles({
    textEllipsis: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export default function PlainText({ value = '', className }: IPlainTextProps) {
    const classes = usePlainTextStyles();

    return (
        <div className={className}>
            <span className={classes.textEllipsis}>
                {value}
            </span>
        </div>
    );
}

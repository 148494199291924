import React from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { TMenuCategoryChild } from 'shared/models/Global';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { selectUserPermissions } from 'store/components/auth/selectors';
import { MenuIconClient } from 'shared/components/verticalMenu/Icon';

export interface IMenuItemProps {
    menuItem: TMenuCategoryChild;
}

export const VerticalMenuItem = ({ menuItem }: IMenuItemProps) => {
    const {
        title,
        to,
        isExact,
        Icon,
        iconUrl,
        feature,
        permission,
    } = menuItem;
    const userPermissions = useSelector(selectUserPermissions);
    const classes = useMenuStyles();
    let isEnabled = true;
    if (feature) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isFeatureEnabled] = useFeature(feature);
        isEnabled = isFeatureEnabled;
    }
    if (permission && userPermissions) {
        isEnabled = isEnabled && userPermissions?.includes(permission);
    }
    return (
        <>
            {isEnabled && (
                <ListItem
                    component={NavLink}
                    to={to}
                    exact={
                        isExact === undefined
                            ? false
                            : isExact
                    }
                    className={classes.item}
                >
                    {iconUrl ? <MenuIconClient icon_url={iconUrl}/>
                        : <ListItemIcon className={classes.itemIcon}><Icon/></ListItemIcon>}
                    <ListItemText
                        classes={{
                            root: classes.itemPrimaryRoot,
                            primary: classes.itemPrimary,
                        }}
                    >
                        {title}
                    </ListItemText>
                </ListItem>
            )}
        </>
    );
};

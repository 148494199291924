import React from 'react';
import { Drawer, IconButton, Typography } from '@material-ui/core';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { ChevronLeft, Close } from '@material-ui/icons';
import clsx from 'clsx';

export type Anchor = 'top' | 'left' | 'bottom' | 'right' ;

export interface ISidebarCommonProps {
    title: string;
    open: boolean;
    onBack?: () => void;
    onClose?: () => void;
    children: React.ReactNode;
    customClasses?: string;
    titleClasses?: Partial<{
        root: string;
        text: string;
        icon: string;
    }>;
    anchor?: Anchor;
}

export default function SidebarCommon({
    title,
    open,
    onBack,
    onClose,
    children,
    customClasses = '',
    titleClasses = {},
    anchor = 'right',
}: ISidebarCommonProps) {
    const classes = useSidebarStyles();

    return (
        <Drawer classes={{ root: classes.root, paper: clsx(classes.wrapper, customClasses) }}
            anchor={anchor}
            open={open}
        >
            <div className={clsx(classes.header, classes.sidebarElement, titleClasses?.root ?? '')}>
                {onBack
                    ? (
                        <IconButton edge="start" color="inherit"
                            classes={{
                                root: clsx(classes.chevronIcon, titleClasses?.icon ?? ''),
                                edgeStart: classes.edgeStart,
                            }}
                            onClick={onBack}>
                            <ChevronLeft/>
                        </IconButton>
                    ) : <></>
                }
                <Typography className={clsx(titleClasses?.text ?? '', classes.titleText)} variant="h5">
                    {title}
                </Typography>
                {onClose
                    ? (
                        <IconButton edge="end" color="inherit"
                            classes={{ root: classes.closeIcon }}
                            onClick={onClose}>
                            <Close/>
                        </IconButton>
                    ) : <></>
                }
            </div>
            {children}
        </Drawer>
    );
}

import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors, getIncreasedSidebarWidth } from 'shared/styles/constants';

const gridArea = (amount: number, name: string) => Array(amount).fill(name).join(' ');
const detailsArea = 'Details';
const filtersArea = 'Filters';
const statusArea = 'Status';
const actionAndTotalsArea = 'ActionsAndTotals';

export const useSheetDetailStyles = makeHighPriorityStyles((theme: Theme) => {
    return {
        paper: {
            ...getIncreasedSidebarWidth(theme),
            border: `1px solid ${colors.gray}`,
        },
        paperTitle: {
            backgroundColor: `${colors.white} !important`,
            color: `${colors.black} !important`,
            borderBottom: `1px solid ${colors.gray}`,
        },
        paperTitleText: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        paperTitleIcon: {
            left: theme.spacing(1.5),

            '& svg': {
                fontSize: 32,
            },
        },
        infoSection: {
            display: 'grid',
            alignItems: 'flex-start',
            justifyItems: 'flex-start',
            gridRowGap: theme.spacing(3),
            gridColumnGap: theme.spacing(1),
            padding: theme.spacing(3, 2),
            gridTemplateColumns: `repeat(10, 1fr)`,

            gridTemplateAreas: `"${gridArea(8, detailsArea)} ${gridArea(2, statusArea)}"
                "${gridArea(10, actionAndTotalsArea)}"`,
            gridTemplateRows: 'min-content min-content min-content',

            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),

                gridTemplateAreas: `"${gridArea(7, detailsArea)} ${gridArea(3, statusArea)}"
                    "${gridArea(10, actionAndTotalsArea)}"
                    "${gridArea(10, filtersArea)}"
                `,
                gridTemplateRows: 'min-content min-content min-content',
            },
        },
        noFilters: {
            gridTemplateAreas: `"${gridArea(8, detailsArea)} ${gridArea(2, statusArea)}"
                "${gridArea(10, actionAndTotalsArea)}"`,

            [theme.breakpoints.down('xs')]: {
                gridTemplateAreas: `"${gridArea(7, detailsArea)} ${gridArea(3, statusArea)}"
                    "${gridArea(10, actionAndTotalsArea)}"
                `,
            },
        },
        listWrapper: {
            gridArea: detailsArea,
            width: '100%',
            overflowX: 'hidden',
            padding: 0,
        },
        statusWrapper: {
            gridArea: statusArea,
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            justifySelf: 'flex-end',
        },
        actionAndTotalsContainer: {
            width: '100%',
            flexWrap: 'wrap-reverse',
            gridArea: actionAndTotalsArea,
        },
        totalsWrapper: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
            paddingBottom: theme.spacing(3),
        },
        filtersWrapper: {
            gridArea: filtersArea,
            display: 'flex',
            height: '100%',
            alignItems: 'flex-end',
            justifySelf: 'flex-end',

            '& > *': {
                '& + *': {
                    marginLeft: theme.spacing(1),
                },
            },
        },
        buttonWrapper: {
            display: 'flex',

            '& > button': {
                margin: 0,
                marginRight: theme.spacing(1),
            },
        },
        timesheetInfoItem: {
            padding: 0,
            lineHeight: `${theme.spacing(2.5)}px`,
            '& > *': {
                color: colors.black,
                fontSize: 14,
                letterSpacing: 'normal',
                maxWidth: '100%',

                [theme.breakpoints.up('sm')]: {
                    '& + *': {
                        marginLeft: theme.spacing(0.5),
                    },
                },
            },

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },
        },
        statusDisplay: {
            '& > *': {
                display: 'flex',
                alignItems: 'center',
                width: theme.spacing(15),
                padding: theme.spacing(0.5, 0),
                borderRadius: theme.spacing(0.5),
                fontSize: 14,
                lineHeight: `${theme.spacing(2)}px`,
            },
        },
        totalNum: {
            '&&': {
                fontSize: 20,
            },
        },
        dayTotals: {
            marginLeft: 'auto',
            padding: theme.spacing(3),
        },
        dayTotalsRow: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        dayTotalHeader: {
            fontSize: 14,
            color: colors.darkGray,
            textTransform: 'uppercase',
            marginRight: theme.spacing(5),
        },
        dayTotalValue: {
            fontWeight: 'bold',
        },
        entriesTable: {
            borderTop: `1px solid ${colors.darkGray}`,
            margin: `0 !important`,
        },
        assignmentFilter: {
            flexBasis: theme.spacing(20),
            flexGrow: 1,
        },
        activitiesFilter: {
            flexBasis: theme.spacing(18),
            flexGrow: 1,
        },
        taskFilter: {
            flexBasis: theme.spacing(14),
            flexGrow: 1,
        },
        totalsBlock: {
            '& > *': {
                paddingLeft: theme.spacing(1),
            },
        },
    };
});

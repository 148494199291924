import { SET_EDIT_CLIENT_WORK_LOCATION_ID } from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/state/actions';
import { AnyAction, combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';
import { updateClientWorkLocation } from 'modules/settings/submodules/clients/workLocation/store/actions';

const workLocationId = (state = null, action: AnyAction): string | null => {
    switch (action.type) {
        case SET_EDIT_CLIENT_WORK_LOCATION_ID:
            return action.payload;
        case updateClientWorkLocation.successType:
            return null;
        default:
            return state;
    }
};

const isUpdating = isLoadingReducer(updateClientWorkLocation);

export const editWorkLocation = combineReducers({
    workLocationId,
    isUpdating,
});

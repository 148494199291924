import React, { useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import { IOfferLetter, OfferLetterRescissionReason } from '../../store/model';
import { useOfferLetterStyles } from '../../OfferLetterStyles';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import OfferLetterRescissionPanel from './OfferLetterRescissionPanel';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { rescindOffer } from '../../store/actions';
import { useDispatch } from 'react-redux';

export interface IOfferLetterRescissionContentProp {
    offerLetter: IOfferLetter;
    onCancel: () => void;
    id: string;
}

const rescissionOfferLetterValidationSchema = yup.object().shape({
    rescissionReason: yup.string().required(ValidationMessages.REQUIRED),
    rescissionReasonText: yup.string().trim(),
});

const useOfferLetterRescissionFormStyles = makeHighPriorityStyles(() => ({
    form: {
        width: '100%',
    },
}));

export interface IOfferLetterRescissionFormValues {
    rescissionReason: OfferLetterRescissionReason | '';
    rescissionReasonText: string;
}

function OfferLetterRescissionContent({ offerLetter, onCancel, id }: IOfferLetterRescissionContentProp) {
    const classes = useOfferLetterStyles();
    const dispatch = useDispatch();
    const onSubmit = useCallback(
        (values: IOfferLetterRescissionFormValues) => {
            dispatch(rescindOffer.init({ id, values }));
        },
        [id, dispatch],
    );
    const localClasses = useOfferLetterRescissionFormStyles();
    return (
        <Box component="section" p={2}>
            <Box>
                You are <b>rescinding</b> this offer letter. Please enter the reason why you are revoking it below.
            </Box>
            <Formik
                initialValues={{
                    rescissionReason: '',
                    rescissionReasonText: '',
                }}
                validationSchema={rescissionOfferLetterValidationSchema}
                onSubmit={onSubmit}
                validateOnBlur={false}
            >
                {props => (
                    <form onSubmit={props.handleSubmit} className={localClasses.form}>
                        <Box>
                            <OfferLetterRescissionPanel
                                offerLetter={offerLetter}
                                hasCustomReason={props.values.rescissionReason === OfferLetterRescissionReason.Other}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            mt={3}
                        >
                            <Box className={classes.buttonWrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Confirm
                                </Button>
                                <Button
                                    className={classes.secondaryButton}
                                    variant="text"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
}

export default OfferLetterRescissionContent;

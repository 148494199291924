/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { deleteDepartment } from 'modules/employmentInfo/store/department/actions';
import { selectDepartmentsIsLoading } from 'modules/employmentInfo/store/department/selectors';
import { setEditDepartmentId } from 'modules/settings/submodules/clients/departments/store/actions';
import { selectFilteredDepartments } from 'modules/settings/submodules/clients/departments/store/selectors';

interface IDepartmentRow extends IDepartment {
    className?: string;
}

export const DepartmentsTable = () => {
    const departments = useSelector(selectFilteredDepartments) as IDepartmentRow[];
    const isLoading = useSelector(selectDepartmentsIsLoading);

    const tableClasses = useDefaultTableStyles();

    const cells = [
        {
            key: 'id',
            title: 'id',
            render: ({ id, className }: IDepartmentRow) => (
                <PlainText className={className} value={id}/>
            ),
        },
        {
            key: 'name',
            title: 'name',
            render: ({ name, className }: IDepartmentRow) => (
                <PlainText className={className} value={name}/>
            ),
        },
        {
            key: 'code',
            title: 'code',
            render: ({ code, className }: IDepartmentRow) => (
                <PlainText className={className} value={code}/>
            ),
        },
        {
            key: 'actions',
            title: '',
            width: '100px',
            render: ({ className, id }: IDepartmentRow) => {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <DeleteItemButton
                            confirmation={`Are you sure you want to delete Department?`}
                            id={id}
                            deleteAction={deleteDepartment.init}
                        />
                        <EditItemButton
                            id={id}
                            editAction={setEditDepartmentId}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <GridTable
            rowData={departments}
            cells={cells}
            getKey={item => item.id}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            stickyHeader
        />
    );
};

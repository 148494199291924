import { IEntity } from 'shared/models/Entity';

export enum PayRateType {
    PER_HOUR = 'per hour',
    PER_WEEK = 'per week',
    BI_WEEKLY = 'bi-weekly',
    SEMI_MONTHLY = 'semi-monthly',
    MONTHLY = 'monthly',
    DAILY = 'daily',
}

export interface IPosition extends IEntity {
    name: string;
    client_id: string;
    pay_rate_type: PayRateType;
    pay_rate_value: string;
    deleted: boolean;
    deleted_at: string | null;
    classification: string;
    description: string;
    prism_position_id: string;
}

import React from 'react';
import { useSelector } from 'react-redux';
import HomeDashboard from 'modules/home/dashboard/HomeDashboard';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { selectIsManager } from 'store/components/auth/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';
// import { generatePath, Redirect } from 'react-router-dom';
// import { routes } from 'shared/routes';

const HomeDashboardPage = () => {
    const isManager = useSelector(selectIsManager);
    // if (process.env.REACT_APP_CLIENT === 'rti' && process.env.REACT_APP_CLIENT_ID) {
    //     return (
    //         <Redirect to={generatePath(routes.CLIENT.ROOT, { client_id: process.env.REACT_APP_CLIENT_ID })}/>
    //     );
    // }
    return (
        <FeatureSwitch
            feature={isManager ? FeatureSwitches.enableManagerDashboard : FeatureSwitches.enableEmployeeDashboard}
            noPlaceholder
        >
            <HomeDashboard/>
        </FeatureSwitch>
    );
};

export default HomeDashboardPage;

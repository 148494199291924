import { IStore } from 'store/configureStore';

export const selectPositionLocationAssociationState = (state: IStore) =>
    state.modules.settings.clientsSettings.clients.positionLocationAssociation;
export const selectIsPositionLocationAssociationLoading = (state: IStore) =>
    selectPositionLocationAssociationState(state).isLoading;
export const selectIsPositionLocationAssociationCreating = (state: IStore) =>
    selectPositionLocationAssociationState(state).isCreating;
export const selectIsPositionLocationAssociationFilter = (state: IStore) =>
    selectPositionLocationAssociationState(state).filter;
export const selectPositionLocationAssociations = (state: IStore) =>
    Object.values(selectPositionLocationAssociationState(state).associationsById);

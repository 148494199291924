import { IGetClientPayCodesRequest, IPayCode } from 'modules/settings/submodules/clients/payCodes/store/model';
import {
    IClientData,
    IClientStatuses, ICreateActivityRequest,
    ICreatePositionWorkLocationAssociationRequest,

    IPositionWorkLocationAssociation,
} from 'modules/settings/submodules/clients/store/models';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import {
    createClientWorkLocation,
    deleteClientWorkLocation, syncClientWorkLocation, updateClientWorkLocation,
} from 'modules/settings/submodules/clients/workLocation/store/actions';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_ERROR = 'CREATE_CLIENT_ERROR';

export const createClient = createActions<IClientData, any, any,
    typeof CREATE_CLIENT,
    typeof CREATE_CLIENT_SUCCESS, typeof CREATE_CLIENT_ERROR
>(
    CREATE_CLIENT, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_ERROR,
);

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_ERROR = 'UPDATE_CLIENT_ERROR';

export const updateClient = createActions<IClientData, any, any,
    typeof UPDATE_CLIENT,
    typeof UPDATE_CLIENT_SUCCESS, typeof UPDATE_CLIENT_ERROR
>(
    UPDATE_CLIENT, UPDATE_CLIENT_SUCCESS, UPDATE_CLIENT_ERROR,
);

export const SET_CLIENT_STATUSES = 'SET_CLIENT_STATUSES';
export const SET_CLIENT_STATUSES_SUCCESS = 'SET_CLIENT_STATUSES_SUCCESS';
export const SET_CLIENT_STATUSES_ERROR = 'SET_CLIENT_STATUSES_ERROR';

export const setClientStatuses = createActions<void, IClientStatuses[], any,
    typeof SET_CLIENT_STATUSES,
    typeof SET_CLIENT_STATUSES_SUCCESS, typeof SET_CLIENT_STATUSES_ERROR
>(
    SET_CLIENT_STATUSES, SET_CLIENT_STATUSES_SUCCESS, SET_CLIENT_STATUSES_ERROR,
);

export const GET_POSITION_WORK_LOCATION_ASSOCIATION = 'GET_POSITION_WORK_LOCATION_ASSOCIATION';
export const GET_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS = 'GET_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS';
export const GET_POSITION_WORK_LOCATION_ASSOCIATION_ERROR = 'GET_POSITION_WORK_LOCATION_ASSOCIATION_ERROR';

export const getPositionWorkLocationAssociation = createActions<
void,
IPositionWorkLocationAssociation[],
any,
typeof GET_POSITION_WORK_LOCATION_ASSOCIATION,
typeof GET_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS,
typeof GET_POSITION_WORK_LOCATION_ASSOCIATION_ERROR
>(
    GET_POSITION_WORK_LOCATION_ASSOCIATION,
    GET_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS,
    GET_POSITION_WORK_LOCATION_ASSOCIATION_ERROR,
);

export const CREATE_POSITION_WORK_LOCATION_ASSOCIATION = 'CREATE_POSITION_WORK_LOCATION_ASSOCIATION';
export const CREATE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS = 'CREATE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS';
export const CREATE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR = 'CREATE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR';

export const createPositionWorkLocationAssociation = createActions<
ICreatePositionWorkLocationAssociationRequest,
IPositionWorkLocationAssociation,
any,
typeof CREATE_POSITION_WORK_LOCATION_ASSOCIATION,
typeof CREATE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS,
typeof CREATE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR
>(
    CREATE_POSITION_WORK_LOCATION_ASSOCIATION,
    CREATE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS,
    CREATE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR,
);

export const DELETE_POSITION_WORK_LOCATION_ASSOCIATION = 'DELETE_POSITION_WORK_LOCATION_ASSOCIATION';
export const DELETE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS = 'DELETE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS';
export const DELETE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR = 'DELETE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR';

export const deletePositionWorkLocationAssociation = createActions<string, string, any,
    typeof DELETE_POSITION_WORK_LOCATION_ASSOCIATION,
    typeof DELETE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS,
    typeof DELETE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR
>(
    DELETE_POSITION_WORK_LOCATION_ASSOCIATION,
    DELETE_POSITION_WORK_LOCATION_ASSOCIATION_SUCCESS,
    DELETE_POSITION_WORK_LOCATION_ASSOCIATION_ERROR,
);

export const GET_CLIENTS_PAYCODES = 'GET_CLIENTS_PAYCODES';
export const GET_CLIENTS_PAYCODES_SUCCESS = 'GET_CLIENTS_PAYCODES_SUCCESS';
export const GET_CLIENTS_PAYCODES_ERROR = 'GET_CLIENTS_PAYCODES_ERROR';

export const getClientPayCodes = createActions<
IGetClientPayCodesRequest | void,
IPayCode[],
any,
typeof GET_CLIENTS_PAYCODES,
typeof GET_CLIENTS_PAYCODES_SUCCESS,
typeof GET_CLIENTS_PAYCODES_ERROR
>(
    GET_CLIENTS_PAYCODES,
    GET_CLIENTS_PAYCODES_SUCCESS,
    GET_CLIENTS_PAYCODES_ERROR,
);

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_ERROR = 'CREATE_ACTIVITY_ERROR';

export const createActivity = createActions<ICreateActivityRequest, IActivity, any,
    typeof CREATE_ACTIVITY,
    typeof CREATE_ACTIVITY_SUCCESS,
    typeof CREATE_ACTIVITY_ERROR
>(
    CREATE_ACTIVITY,
    CREATE_ACTIVITY_SUCCESS,
    CREATE_ACTIVITY_ERROR,
);

const SET_IS_NO_GEOCODE_WARNING_SHOWN = 'SET_IS_NO_GEOCODE_WARNING_SHOWN';
export const setIsNoGeocodeWarningShown = createSingleAction<
boolean,
    typeof SET_IS_NO_GEOCODE_WARNING_SHOWN>(SET_IS_NO_GEOCODE_WARNING_SHOWN);

export type ClientSettingsAction =
    | ActionsReturnTypes<typeof createClient>
    | ActionsReturnTypes<typeof updateClient>
    | ActionsReturnTypes<typeof setClientStatuses>
    | ActionsReturnTypes<typeof createClientWorkLocation>
    | ActionsReturnTypes<typeof updateClientWorkLocation>
    | ActionsReturnTypes<typeof deleteClientWorkLocation>
    | ActionsReturnTypes<typeof syncClientWorkLocation>
    | ActionsReturnTypes<typeof getPositionWorkLocationAssociation>
    | ActionsReturnTypes<typeof createPositionWorkLocationAssociation>
    | ActionsReturnTypes<typeof deletePositionWorkLocationAssociation>
    | ActionsReturnTypes<typeof getClientPayCodes>
    | ActionsReturnTypes<typeof createActivity>;

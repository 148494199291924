import React, { useMemo } from 'react';
import SheetDetail, { ISheetDetailWrapperProps } from 'shared/components/sidebars/SheetDetail/SheetDetail';
import { EntryType } from 'shared/models/sheet/Sheet';
import { userFullName } from 'shared/utils/converters/user';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { AvailableDetailConfiguration } from 'store/entities/clients/clientsModel';
import { selectDetailConfiguration } from 'store/entities/clients/clientsSelectors';
import { selectUserById } from 'store/entities/users/selectors';
import { selectTypedSheet } from 'store/entities/timesheet/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import ApproveButton from 'shared/components/sheetApproval/filterAndActionControls/buttons/ApproveButton';
import RejectButton from 'shared/components/sheetApproval/filterAndActionControls/buttons/RejectButton';

export default function ManagerSheetDetails({
    entryType, onClose, sheetId, ...sheetDetailProps
}: ISheetDetailWrapperProps) {
    const sheet = useSelector(selectTypedSheet(sheetId, entryType));
    const user = useSelector(selectUserById(sheet?.user_id));
    const configuration = useSelector(selectDetailConfiguration(
        entryType === EntryType.TIME
            ? AvailableDetailConfiguration.ManagerTimeSheet : AvailableDetailConfiguration.ManagerExpenseSheet,
    ));

    const sheetAvailableActions: JSX.Element | null = useMemo(() => {
        return sheet && sheet.status.name === StatusNames.SUBMITTED && user
            ? (
                <>
                    <RejectButton sheets={[sheet]} users={[user]}
                        sheetType={sheet.entry_type} onAction={onClose}
                    />
                    <ApproveButton sheets={[sheet]} sheetType={sheet.entry_type}
                        onAction={onClose}
                    />
                </>
            ) : null;
    }, [sheet, onClose, user]);

    return (
        <SheetDetail
            sheetId={sheetId}
            entryType={entryType}
            permission={Permission.ApproveSheets}
            onClose={onClose}
            title={userFullName(user)}
            sheetAvailableActions={sheetAvailableActions}
            configuration={configuration.info}
            {...sheetDetailProps}
        />
    );
}

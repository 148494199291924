import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

const useHeaderTabsStyles = makeHighPriorityStyles((theme: Theme) => {

    const borderStyles = `1px solid ${theme.palette.primary.main}`;

    return {
        secondaryBar: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                zIndex: 0,
            },
        },
        tabs: {
            minHeight: 'unset',
            overflow: 'visible',

            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(-1),
            },

            [theme.breakpoints.down('sm')]: {
                borderBottom: `1px solid ${colors.lightGray}`,
                width: '100%',
                marginLeft: 0,
            },
        },
        tabsIndicator: {
            display: 'none',
        },
        tabsFlexContainer: {
            height: '100%',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginLeft: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        tab: {
            opacity: 1,
            fontSize: 14,
            minHeight: 'unset',
            height: theme.spacing(5),

            color: colors.darkGray,
            textTransform: 'uppercase',
            padding: theme.spacing(0, 2),
            margin: 0,
            borderBottom: `2px solid transparent`,

            [theme.breakpoints.up('md')]: {
                paddingBottom: theme.spacing(0.5),
                alignItems: 'flex-end',
                minWidth: theme.spacing(14),
            },
        },
        tabMobileHidden: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        tabActive: {
            color: theme.palette.primary.main,
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        moreVert: {
            display: 'none',
            color: colors.darkGray,
            alignSelf: 'center',
            cursor: 'pointer',
            marginLeft: 'auto',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
            },
        },
        modalTitle: {
            textTransform: 'uppercase',
            color: colors.black,
            borderTop: borderStyles,
            borderBottom: borderStyles,
        },
        modalPaper: {
            width: '100%',
            height: '100vh',
            '&&': {
                margin: 0,
                maxHeight: '100%',
                backgroundColor: colors.white,
            },
        },
        modalContent: {
            '&&': {
                padding: 0,
            },
        },
        mobileTab: {
            color: theme.palette.primary.main,
            width: '100%',
            padding: theme.spacing(1),
            minHeight: theme.spacing(6),
            borderBottom: borderStyles,
            textTransform: 'uppercase',
        },
        iconLabelWrapper: {
            flexDirection: 'row-reverse',
        },
        warningClass: {
            fill: colors.orange,
            marginBottom: '2px !important',
            marginLeft: '5px',
            fontSize: 16,
        },
    };
});

export default useHeaderTabsStyles;

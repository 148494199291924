import { useDispatch, useSelector } from 'react-redux';
import { IPdfViewProps, PdfView } from 'shared/components/PdfViewer/PdfView';
import React, { useEffect } from 'react';
import {
    selectOfferLetterPdfIsAvailableById,
    selectOfferLetterPdfIsCheckingById,
} from '../store/selectors';
import { checkOfferLetterPdf } from '../store/actions';
import { Box, CircularProgress } from '@material-ui/core';

interface IOfferLetterPdf extends IPdfViewProps {
    errorRender: React.ReactElement;
    id: string;
}

function OfferLetterPdf({ id, errorRender, ...props }: IOfferLetterPdf) {
    const isPdfChecking = useSelector(selectOfferLetterPdfIsCheckingById(id));
    const isPdfAvailable = useSelector(selectOfferLetterPdfIsAvailableById(id));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkOfferLetterPdf.init({ id }));
    }, [dispatch, id]);

    if (isPdfChecking) {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                flexGrow={1}
            >
                <CircularProgress/>
            </Box>
        );
    }

    if (isPdfAvailable) {
        return (
            <PdfView {...props}/>
        );
    }

    return errorRender;
}

export default OfferLetterPdf;

import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IJobNumberFormValues } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import JobNumberFormWrapper from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/JobNumberFormWrapper';
import { setEditJobNumberId, updateJobNumber } from 'modules/settings/submodules/clients/jobNumber/store/actions';
import { selectEditedJobNumber, selectEditJobNumberId, selectIsJobNumberUpdating } from 'modules/settings/submodules/clients/jobNumber/components/EditJobNumber/store/selectors';
import { updateFormValuesToPayload } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/utils';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';

export const EditJobNumberModal = () => {
    const classes = useSettingsFormModalStyles();
    const dispatch = useDispatch();
    const editJobNumberId = useSelector(selectEditJobNumberId);
    const isLoading = useSelector(selectIsJobNumberUpdating);
    const jobNumber = useSelector(selectEditedJobNumber);

    const onClose = useCallback(() => {
        dispatch(setEditJobNumberId(null));
    }, [dispatch]);
    const onSave = useCallback((values: IJobNumberFormValues) => {
        const payload = updateFormValuesToPayload(values);
        dispatch(updateJobNumber.init(payload));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Job Number"
            isOpened={Boolean(editJobNumberId)}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <Box>
                <JobNumberFormWrapper
                    isEdit
                    onSubmit={onSave}
                    onBack={onClose}
                    submitTitle="Save & Assign a user"
                    isLoading={isLoading}
                    jobNumber={jobNumber}
                />
            </Box>
        </ModalDialog>
    );
};

import { Theme } from '@material-ui/core';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    sideMenu: {
        width: '200px',
        minWidth: '200px',
        borderRight: `1px solid ${colors.gray}`,
        background: colors.whiteSecondary,
        display: 'flex',
        flexDirection: 'column',
    },
    menuItem: {
        fontSize: theme.typography.fontSize,
        borderBottom: `1px solid ${colors.gray}`,
        color: colors.black,
        textDecoration: 'none',
    },
    activeLink: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    childContainer: {
        padding: theme.spacing(2, 2, 2, 4),
        borderBottom: `1px solid ${colors.gray}`,
        background: colors.white,
    },
    childItem: {
        fontSize: 12,
        textDecoration: 'none',
        color: colors.black,
    },
    childItemActive: {
        color: colors.primary,
    },
}));

import React, { useCallback, useEffect, useRef } from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useField } from 'formik';
import FormField from 'shared/components/formFields/FormField';
import { ITemplate } from 'store/entities/configuration/configurationModel';

interface IOfferLetterTemplatedTextFieldProps<TemplateType> extends IFormFieldProps {
    nameTemplate?: string;
    labelTemplate: string;
    templates?: TemplateType[];
    forceValidate?: () => void;
}

export function OfferLetterTemplatedTextField<TemplateType extends ITemplate>({
    templates,
    name,
    nameTemplate,
    labelTemplate,
    className,
    forceValidate,
    ...props
}: IOfferLetterTemplatedTextFieldProps<TemplateType>) {
    const nameTemplateSelect = nameTemplate || `${name}Template`;
    const [fieldTemplate] = useField(nameTemplateSelect);
    const [,, helpers] = useField(name);
    const fieldHelper = useRef(helpers);
    const setSlaveValue = useCallback((value: string) => {
        fieldHelper.current.setValue(value);
        typeof forceValidate === 'function' && forceValidate();
    }, [fieldHelper, forceValidate]);
    const template = fieldTemplate.value;
    const isTemplateSelected = Boolean(template);
    const selectedTemplateText = isTemplateSelected ? template.template : '';

    useEffect(() => {
        if (isTemplateSelected) {
            setSlaveValue(selectedTemplateText);
        }
    }, [isTemplateSelected, selectedTemplateText, setSlaveValue]);

    return (
        <>
            <FormSelect
                getKey={(offerLetterTemplate: TemplateType) => offerLetterTemplate.id}
                getText={(offerLetterTemplate: TemplateType) => offerLetterTemplate.name}
                options={templates || []}
                name={nameTemplateSelect}
                label={labelTemplate}
                className={className}
            />
            {isTemplateSelected && (
                <FormField
                    name={name}
                    {...props}
                    multiline
                    rows={4}
                    rowsMax={8}
                    className={className}
                />
            )}
        </>
    );
}

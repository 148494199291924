import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import {
    EntryType,
    IEntry,
    IExpenseEntry,
    IExpenseSheet,
    ISheet, ISheetStatusesId,
    IStatus,
    ITimeEntry,
    ITimeSheet,
} from 'shared/models/sheet/Sheet';
import { ItemsById } from 'shared/models/ItemsById';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { IStore } from '../../configureStore';

/**
 * Time sheets
 */
export const selectTimeSheetsByIds = (state: IStore): ItemsById<ITimeSheet> => state.sheets.time.sheetsById;
export const selectTimeSheetById = (id: string) =>
    (state: IStore): ITimeSheet | undefined => selectTimeSheetsByIds(state)[id];
export const selectTimeSheets = createSelector(
    selectTimeSheetsByIds,
    sheetsByIds => Object.values(sheetsByIds),
);
/**
 * Expense sheets
 */
export const selectExpensesSheetsByIds = (state: IStore): ItemsById<IExpenseSheet> => state.sheets.expenses.sheetsById;
export const selectExpensesSheetById = (id: string) =>
    (state: IStore): IExpenseSheet | undefined => selectExpensesSheetsByIds(state)[id];
export const selectExpensesSheets = createSelector(
    selectExpensesSheetsByIds,
    sheetsByIds => Object.values(sheetsByIds),
);

export const selectAllSheets = createSelector(
    selectTimeSheets,
    selectExpensesSheets,
    (timeSheets, expensesSheets) => ([
        ...timeSheets,
        ...expensesSheets,
    ]),
);

/**
 * Time entries
 */
export const selectTimeEntriesByIds = (state: IStore) => state.sheets.time.entriesById;
export const selectTimeEntries = createSelector(
    selectTimeEntriesByIds,
    (entriesById): ITimeEntry[] => Object.values(entriesById),
);
/**
 * Expense entries
 */
export const selectExpenseEntriesByIds = (state: IStore) => state.sheets.expenses.entriesById;
export const selectExpenseEntries = createSelector(
    selectExpenseEntriesByIds,
    (entriesById): IExpenseEntry[] => Object.values(entriesById),
);

export const selectAllEntries = createSelector(
    selectTimeEntries,
    selectExpenseEntries,
    (timeEntries, expenseEntries): IEntry[] => ([
        ...timeEntries,
        ...expenseEntries,
    ]),
);

//TODO: Change interface for receive type
export const selectSheet = (sheetId?: string | null) =>
    (state: IStore): ISheet | null => sheetId
        ? state.sheets.time.sheetsById[sheetId] || state.sheets.expenses.sheetsById[sheetId]
        : null;
export const selectEntry = (entryId?: string | null) =>
    (state: IStore): IEntry | null => entryId
        ? state.sheets.time.entriesById[entryId] || state.sheets.expenses.entriesById[entryId]
        : null;
export const selectExpenseEntry = (entryId?: string | null) =>
    (state: IStore): IExpenseEntry | null => entryId
        ? state.sheets.expenses.entriesById[entryId]
        : null;
export const selectTimeEntry = (entryId?: string | null) =>
    (state: IStore): ITimeEntry | null => entryId
        ? state.sheets.time.entriesById[entryId]
        : null;
export const selectTimeEntriesWithSheet = createSelector(
    selectTimeEntries,
    selectTimeSheetsByIds,
    (entries, sheetsById) => {
        return entries.map(entry => ({
            ...entry,
            sheet: sheetsById[entry.sheet_id],
        }));
    },
);
export const selectExpenseEntriesWithSheet = createSelector(
    selectExpenseEntries,
    selectExpensesSheetsByIds,
    (entries, sheetsById) => {
        return entries.map(entry => ({
            ...entry,
            sheet: sheetsById[entry.sheet_id],
        }));
    },
);
// TODO: reselect needs to be implemented
export const selectAllEntriesWithSheet = createSelector(
    selectTimeEntriesWithSheet,
    selectExpenseEntriesWithSheet,
    (timeEntries, expenseEntries) => ([
        ...timeEntries,
        ...expenseEntries,
    ]),
);

export const selectPatchingSheetsByIds = (state: IStore) => state.sheets.time.timeSheetIsPatching;
export const selectLastAddedEntry = (state: IStore) => state.sheets.lastAddedEntry;
export const selectSheetsIsLoading = (state: IStore) => state.sheets.time.isSheetsLoading
    || state.sheets.expenses.isSheetsLoading;

const findSheetByName = (statusName: string) => (status: IStatus) => status.name === statusName;
export const selectTimeSheetStatuses = (state: IStore) => state.sheets.time.statuses;
export const selectTimeSheetStatusByName = (statusName: string) =>
    (state: IStore) => selectTimeSheetStatuses(state).find(findSheetByName(statusName));
export const selectExpenseSheetStatuses = (state: IStore) => state.sheets.expenses.statuses;
export const selectExpenseSheetStatusByName = (statusName: string) =>
    (state: IStore) => selectExpenseSheetStatuses(state).find(findSheetByName(statusName));

export const selectTempEntryAttachments = (state: IStore) => state.sheetEntryAttachments.attachments;

export const selectTypedEntries = (entryType: EntryType) => function (state: IStore): Array<IEntry> {
    return entryType === EntryType.TIME ? selectTimeEntries(state) : selectExpenseEntries(state);
};

export const selectTypedSheet = (sheetId: string, entryType: EntryType) => function (state: IStore): ISheet {
    return entryType === EntryType.TIME
        ? selectTimeSheetsByIds(state)[sheetId] : selectExpensesSheetsByIds(state)[sheetId];
};

export const selectTimeStatusIdByName = (statusName: string) => (state: IStore) =>
    state.sheets.time.statuses.find(status => status?.name === statusName)?.id;
export const selectExpenseStatusIdByName = (statusName: string) => (state: IStore) =>
    state.sheets.expenses.statuses.find(status => status?.name === statusName)?.id;
export const selectSheetStatusIdsByName = (statusName: string) => (state: IStore): ISheetStatusesId => ({
    timeStatusId: selectTimeStatusIdByName(statusName)(state),
    expenseStatusId: selectExpenseStatusIdByName(statusName)(state),
});

export const isReceiptUploading = (state: IStore) => {
    return state.sheetEntryAttachments.isUploading;
};

export const isFileReading = (state: IStore) => state.sheetEntryAttachments.setFileRead;

/**
 * Calculations
 */
export const selectCalculationsByTimesheetId = (state: IStore) => state.sheets.calculations.byTimesheetId;
export const selectCalculationByTimesheetId = (id?: string) =>
    (state: IStore) => selectCalculationsByTimesheetId(state)[id || ''];

/**
 * Pay periods
 */
export const selectPayPeriods = (state: IStore): IPayPeriod[] => state.sheets.payPeriods;
export const selectOrderedPayPeriods = createSelector(
    selectPayPeriods,
    payPeriods => sortBy(payPeriods, period => period.period_end),
);
export const selectPayPeriodsIsLoading = (state: IStore) => state.sheets.payPeriodsIsLoading;
export const selectPayPeriodsBySheets = createSelector(
    selectAllSheets,
    sheets => {
        const sheetPayPeriods: IPayPeriod[] = [];
        sheets.forEach(sheet => {
            const sheetPayPeriod: IPayPeriod = {
                period_start: sheet.period_start,
                period_end: sheet.period_end,
            };
            const existPeriod = sheetPayPeriods.find(
                period => period.period_start === sheetPayPeriod.period_start
                    && period.period_end === sheetPayPeriod.period_end,
            );
            if (!existPeriod) {
                sheetPayPeriods.push(sheetPayPeriod);
            }
        });
        return sheetPayPeriods;
    },
);

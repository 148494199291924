import { IEntity } from 'shared/models/Entity';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { IClientStatuses } from 'store/components/auth/authModels';
import { ILocation } from 'shared/models/Location';

export enum AvailableFormConfiguration {
    Time = 'time',
    Expense = 'expense'
}

export enum AvailableTableConfiguration {
    TimeExpense = 'time_expense',
    Time = 'time',
    Expense = 'expense',
    TimeDetail = 'time_detail',
    ExpenseDetail = 'expense_detail',
    EmployeeExpenseSheet = 'employee_expense_sheet',
    EmployeeTimeSheet = 'employee_time_sheet',
    ManagerExpenseSheet = 'manager_expense_sheet',
    ManagerTimeSheet = 'manager_time_sheet',
}

export enum AvailableDetailConfiguration {
    EditTimeEntry = 'edit_time_entry',
    EditExpenseEntry = 'edit_expense_entry',
    EmployeeTimeSheet = 'employee_time_sheet',
    ManagerTimeSheet = 'manager_time_sheet',
    EmployeeExpenseSheet = 'employee_expense_sheet',
    ManagerExpenseSheet = 'manager_expense_sheet',
}

export enum AvailableTotalConfiguration {
    DaySheetTotal = 'day_sheet_total',
    SheetsTotal = 'sheets_total',
    TimeSheetDetail = 'time_sheet_detail',
    TimeSheetDetailDay = 'time_sheet_detail_day',
}

export interface IThemeConfiguration {
    primary_color: string;
    logo_url: string;
}

export interface IClient extends IEntity {
    name: string;
    short_name: string;
    created_at?: string;
    prism_client_id: string;
}

export enum EntrySlug {
    Day = 'day',
    Task = 'task',
    Assignment = 'assignment_project',
    JobNumber = 'job_number',
    Activity = 'activity',
    Notes = 'notes',
    ZipCode = 'zip_code',
    ScaZone = 'sca_zone',
    Location = 'location',
    Position = 'position',
    Department = 'department',
    Time = 'time',
    TimeIn = 'time_in',
    TimeOut = 'time_out',
}

export interface IInputConfiguration {
    slug: EntrySlug;
    visible: boolean;
    placeholder: string;
    default_value?: string;
}

export type InputFields = Record<EntrySlug, IInputConfiguration>;

export interface IInputsConfiguration {
    time: InputFields;
    expense: InputFields;
}

export interface IClientsThemes {
    [idClients: string]: IThemeConfigurationWrapper;
}

export type TablesConfiguration = Record<AvailableTableConfiguration, IColumnConfiguration<ColumnSlugs>[]>;
export type DetailsConfiguration = Record<AvailableDetailConfiguration, {
    info: Array<IDetailConfiguration<DetailSlugs>>;
    title?: IDetailConfiguration<DetailSlugs>;
}>;
export type FiltersConfiguration = Record<AvailableFiltersConfiguration, FiltersConfigurationBySlug>;

export enum SheetTotalSlug {
    Time = 'time',
    Expense = 'expense',
    Completes = 'completes',
    PerDiem = 'per-diem',
    Files = 'files',
    HolidayTime = 'holiday-time',
    RegularTime = 'total_reg',
    OverTime = 'ot',
    DoubleTime = 'dt',
    Break = 'break',
}

export enum TotalSlug {
    TrailingDoc = 'trailing-doc',
    NewLoan = 'new_loan',
    Files = 'files',
    TotalReg = 'total_reg',
    OverTime = 'ot',
    DoubleTime = 'dt',
    Break = 'break',
    Holidays = 'holidays',
    Completes = 'completes',
    Time = 'time',
    Expense = 'expense',
}

export interface ITotalFieldsConfiguration<Slug> {
    slug: Slug;
    unitLabel?: string;
}

export interface ISheetTotalConfiguration extends ITotalFieldsConfiguration<SheetTotalSlug> {

}

export interface ITotalConfiguration extends ITotalFieldsConfiguration<TotalSlug> {

}

export interface ITotalsConfiguration {
    [AvailableTotalConfiguration.SheetsTotal]: ITotalConfiguration[]
    [AvailableTotalConfiguration.DaySheetTotal]: ISheetTotalConfiguration[]
    [AvailableTotalConfiguration.TimeSheetDetail]: ITotalConfiguration[]
    [AvailableTotalConfiguration.TimeSheetDetailDay]: ISheetTotalConfiguration[]
}

export interface IFieldsConfiguration {
    inputs: IInputsConfiguration;
    tables: TablesConfiguration;
    detail: DetailsConfiguration;
    filters: FiltersConfiguration;
    totals: ITotalsConfiguration;
}

export type ActivityType = QuantityType.TIME
| QuantityType.TIME_IN_OUT
| QuantityType.TIME_BREAK
| QuantityType.TIME_IN_OUT_BREAK
| QuantityType.FILE

export interface ITimeTracking {
    activity_types: ActivityType[];
}

export enum ApproversFromFields {
    FromAssignment= 'assignment',
    FromJobNumber= 'job_number',
}

export interface ITimeAndPayClientConfiguration {
    id: string;
    timeTracking: ITimeTracking;
    approvalLevels: number;
    approversFrom: ApproversFromFields;
    hasJobNumberConfiguration: boolean;
    isGovernmentContract: boolean;
}

export interface IFieldsConfigurationBackend {
    inputs: Array<{ type: AvailableFormConfiguration; fields: Array<IInputConfiguration> }>;
    tables: Array<{ type: AvailableTableConfiguration; columns: Array<IColumnConfiguration<ColumnSlugs>> }>;
    details: Array<{
        type: AvailableDetailConfiguration;
        info: Array<IDetailConfiguration<DetailSlugs>>;
        title?: IDetailConfiguration<DetailSlugs>;
    }>;
    filters: Array<{ type: AvailableFiltersConfiguration; fields: Array<IFiltersConfiguration> }>;
    totals: Array<{
        type: AvailableTotalConfiguration;
        info: Array<ITotalFieldsConfiguration<TotalSlug | SheetTotalSlug>>
    }>;
    time_tracking: ITimeTracking;
    approvers_from?: ApproversFromFields;
    is_government_contract: boolean;
    sheet_entry_group_key: string[];
    approval_levels: number;
}

export interface IFieldsConfigurationWrapper {
    id: string;
    client_id: string;
    configuration: IFieldsConfigurationBackend;
}

export interface IThemeConfigurationWrapper {
    id: string;
    client_id: string;
    theme: IThemeConfiguration;
}

export enum AvailableFiltersConfiguration {
    EmployeeEntries = 'employee_entries',
    EmployeeSheets = 'employee_sheets',
    ManagerSheets = 'manager_sheets',
    SheetDetails = 'sheet_details',
}

export enum FilterSlug {
    Location = 'location',
    Position = 'position',
    LocationByAssignment = 'location_by_assignment',
    PositionByAssignment = 'position_by_assignment',
    Area = 'area',
    Assignment = 'assignment',
    Task = 'task',
    Activity = 'activity',
    JobNumber = 'job_number',
    Employee = 'employee',
    PayPeriod = 'pay_period',
}

export interface IColumnConfiguration<TableSlugs extends ColumnSlugs> {
    slug: TableSlugs;
    placeholder: string;
}

export interface IDetailConfiguration<DetailSlug extends DetailSlugs> {
    slug: DetailSlug;
    placeholder: string;
}

export interface IFiltersConfiguration {
    slug: FilterSlug;
    placeholder: string;
}

export type FiltersConfigurationBySlug = Record<FilterSlug, IFiltersConfiguration>;

export enum SheetColumnSlug {
    Employee = 'employee',
    PayPeriod = 'pay_period',
    Area = 'area',
    Hours = 'hours',
    Amount = 'amount',
    Receipt = 'receipt',
    Id = 'id',
    Approver = 'approver',
    Position = 'position',
    Location = 'location',
    Department = 'department',
    JobNumber = 'job_number',
    RegularHours = 'regular_hours',
    Overtime1Hours = 'overtime_1_hours',
    Overtime2Hours = 'overtime_2_hours',
    HolidayHours = 'holiday_hours',
}

export enum SheetDetailSlug {
    SheetId = 'sheet_id',
    PayPeriod = 'pay_period',
    Approver = 'approver',
    Position = 'position',
    Location = 'location',
    Department = 'department',
    Area = 'area',
    Employee = 'employee',
    PayRate = 'pay_rate',
    Notes = 'notes',
    BatchId = 'prism_batch_id',
    DeliveryOrder = 'delivery_order',
    JobsiteLocation = 'jobsite_location',
    JobNumber = 'job_number',
    DealNumber = 'deal_number',
}
export enum EntryColumnSlug {
    Activity = 'activity',
    Position = 'position',
    Location = 'location',
    ZipCode = 'zip_code',
    Receipt = 'receipt',
    Amount = 'amount',
    AmountReadonly = 'amount_readonly',
    Assignment = 'assignment',
    AssignmentProject = 'assignment_project',
    Task = 'task',
    Notes = 'notes',
    Quantity = 'quantity',
    Rate = 'rate',
    PayRate = 'pay_rate',
    Hours = 'hours',
    Time = 'time',
    Department = 'department',
    JobNumber = 'job_number',
    TimeReadOnly = 'time_readonly',
    Break = 'break',
}

export interface IPayrollInfo {
    client_id: string;
    pay_date: string;
}

export type ColumnSlugs = EntryColumnSlug | SheetColumnSlug
export type DetailSlugs = SheetDetailSlug

export enum PrismCorporationType {
    L = 'L',
    S = 'S',
    C = 'C',
    I = 'I',
    P = 'P',
    A = 'A',
    M = 'M',
    R = 'R',
    V = 'V',
    B = 'B',
    J = 'J',
    T = 'T',
    U = 'U',
    H = 'H',
    N = 'N',
    O = 'O'
}

export const PrismCorporationTypeTitle: Record<PrismCorporationType, string> = {
    [PrismCorporationType.L]: 'Limited Liability Corporation',
    [PrismCorporationType.S]: 'S-Corporation',
    [PrismCorporationType.C]: 'C-Corporation',
    [PrismCorporationType.I]: 'Individual/Sole Proprieter',
    [PrismCorporationType.P]: 'Partnership',
    [PrismCorporationType.A]: 'Limited Partnership',
    [PrismCorporationType.M]: 'Limited Liability Partnership',
    [PrismCorporationType.R]: 'Professional Corporation',
    [PrismCorporationType.V]: 'Personal Service Corporation',
    [PrismCorporationType.B]: 'Board Owned',
    [PrismCorporationType.J]: 'Joint Venture',
    [PrismCorporationType.T]: 'Tax Exempt',
    [PrismCorporationType.U]: 'Trust',
    [PrismCorporationType.H]: 'Church Organization',
    [PrismCorporationType.N]: 'Not for Profit',
    [PrismCorporationType.O]: 'Other',
};

export enum PayPeriodType {
    weekly = 'weekly'
}

export interface IPaySettings {
    'id': string;
    'client_id': string;
    'pay_period_type': PayPeriodType;
    'pay_day': string;
    'pay_period_start_day': string;
    'pay_period_end_day': string;
    'week_end_on_day': string;
    'prism_group_id': string;
    'prism_group_description': string;
    'prism_schedule_id': string;
    'created_at': string;
    'updated_at': string;
}

export interface IClientInfo extends IClient {
    short_name: string;
    name: string;
    employer_id: string;
    cost_center: string;
    status: IClientStatuses;
    status_date: string;
    service_type: string;
    main_location: ILocation;
    manager: IUserInfo | null;
    payroll_rep_user: IUserInfo | null;
    address: string;
    address2: string;
    city: string;
    state: string;
    country?: string;
    county: string;
    zip_code: string;
    contact_name: string;
    contact_title: string;
    contact_email: string;
    contact_phone: string;
    created_at?: string;
    prism_corporation_type?: PrismCorporationType;
}

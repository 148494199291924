import React from 'react';
import { Box } from '@material-ui/core';
import { ISummaryBlockData } from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { SummaryBlock } from 'modules/payrollProcessorHub/components/SheetSummary/SummaryBlock';
import { emptyFormattedTime } from 'shared/models/DateTime';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { getTotalHours } from 'shared/utils/helpers/hoursCalculations';

export interface ISheetGroupTotalByTypeProp {
    sheetGroup: IGroupedSheetCalculation;
    type: EntryType;
}

/**
 * Calculate total dollars in payment for time activity
 * @param {(string | null)[]} dollarsComponents
 * @return number
 */
const calculateTotalTimeDollars = (dollarsComponents: (string | null)[]) => (
    dollarsComponents.reduce((result: number, componentValue: string | null) => {
        if (!componentValue) {
            return result;
        }
        const floatValue = parseFloat(componentValue);
        return !isNaN(floatValue) ? result + floatValue : result;
    }, 0).toFixed(2)
);

export const SheetGroupTotalByType = ({ sheetGroup, type }: ISheetGroupTotalByTypeProp) => {
    const totalTimeDollars = calculateTotalTimeDollars([
        sheetGroup.time_ot_dollars,
        sheetGroup.time_rt_dollars,
        sheetGroup.time_dt_dollars,
        sheetGroup.time_files_dollars,
    ]);
    const totalMiles = parseFloat(sheetGroup.expense_total_miles || '');
    const totalHours = getTotalHours([sheetGroup.time_ot_hours, sheetGroup.time_rt_hours, sheetGroup.time_dt_hours]);

    const blocks: ISummaryBlockData[] = [
        {
            value: totalMiles.toString(),
            title: 'total miles',
            color: 'blue',
            visible: type === EntryType.EXPENSE && totalMiles,
        },
        {
            value: formatDollars(sheetGroup.expense_total_dollars),
            title: 'total amount',
            color: 'green',
            visible: type === EntryType.EXPENSE,
        },
        {
            value: totalHours,
            title: 'total hours',
            color: 'blue',
            visible: type === EntryType.TIME && totalHours !== emptyFormattedTime,
        },
        {
            value: sheetGroup?.time_files,
            title: 'total files',
            color: 'blue',
            visible: type === EntryType.TIME && sheetGroup?.time_files,
        },
        {
            value: formatDollars(totalTimeDollars),
            title: 'total pay',
            color: 'green',
            visible: type === EntryType.TIME,
        },
    ].filter(block => block.visible) as ISummaryBlockData[];

    return (
        <Box
            display="flex"
            flexDirection="row"
            mt={-1}
        >
            {blocks.map(block => (
                <SummaryBlock
                    key={block.title}
                    value={block.value}
                    title={block.title}
                    color={block.color}
                />
            ))}
        </Box>
    );
};

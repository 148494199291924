import mapValue from 'lodash/mapValues';
import { OfferLetterTabValue } from 'modules/offerLetter/store/model';
import { createSelector } from 'reselect';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { IStore } from 'store/configureStore';
import { IInfinityScrollState } from 'store/reducerUtils';
import { getInfinityScrollSelectors } from 'store/utils/infinityScroll/selectors';
import { PdfCheckStateById } from './reducer';
import { omit } from 'lodash';

const offerLetterState = (state: IStore) => state.modules.offerLetter;
export const selectOfferLetters = (state: IStore) => offerLetterState(state).offerLetterById;
export const selectOfferLetter = (id: string) => (state: IStore) => offerLetterState(state).offerLetterById[id];
export const selectStartDates = (state: IStore) => offerLetterState(state).startDates;
export const selectGetOfferLetterLoading = (state: IStore) => offerLetterState(state).getOfferLetterLoading;
export const selectMyOfferLettersLoading = (state: IStore) => offerLetterState(state).myOfferLettersLoading;
export const selectOfferLetterFilters = (state: IStore) => offerLetterState(state).offerLetterFilters;
const selectOfferLetterPdfCheck = (state: IStore): PdfCheckStateById => offerLetterState(state).pdfCheck;
export const selectOfferLetterPdfIsLoading = createSelector(
    selectOfferLetterPdfCheck,
    pdfChecks => mapValue(pdfChecks, check => check.isLoading),
);
export const selectOfferLetterPdfIsAvailable = createSelector(
    selectOfferLetterPdfCheck,
    pdfChecks => mapValue(pdfChecks, check => !check.result?.isError),
);

/**
 *  Offer letter table with pagination
 */
export const selectOfferLettersTableState = (state: IStore): IInfinityScrollState<string> =>
    offerLetterState(state).offerLettersTable;
export const offerLettersTableStateSelectors = getInfinityScrollSelectors(selectOfferLettersTableState);

/**
 * Flag is true if offer letter pdf checking is in progress
 * @param {string} id
 */
export const selectOfferLetterPdfIsCheckingById = (id: string) => (state: IStore) => (
    Boolean((selectOfferLetterPdfCheck(state)[id] || {}).isLoading)
);
/**
 * Flag is true if offer letter pdf is available
 * @param {string} id
 */
export const selectOfferLetterPdfIsAvailableById = (id: string) => (state: IStore) => (
    !((selectOfferLetterPdfCheck(state)[id] || {}).result?.isError)
);

/**
 * Select Employee profiles by client ids
 */
export const selectMyEmployeeProfilesByClientIds = (state: IStore): Record<string, IEmployeeProfile> =>
    state.modules.offerLetter.employeeProfilesByClientId;
/**
 * Select Employee profile by client id
 * @param {string} clientId
 */
export const selectMyEmployeeProfileByClientId = (clientId: string) => (state: IStore): IEmployeeProfile | null =>
    selectMyEmployeeProfilesByClientIds(state)[clientId];
/**
 * Select flag that means that employee already onboarded and have an manager approve
 * @param {string} clientId
 */
export const selectIsEmployeeAlreadyOnboarded = (clientId: string) => (state: IStore): boolean =>
    selectMyEmployeeProfileByClientId(clientId)(state)?.prism_onboarding_completed || false;
/**
 * Select Employee Profile Checking status.
 */
export const selectEmployeeProfileStatusCheckingByClientId = (state: IStore): Record<string, boolean> =>
    state.modules.offerLetter.employeeProfileStatusCheckingByClientId;
/**
 * Select Offer Letter Active tab.
 */
export const selectOfferLetterActiveTab = (state: IStore): OfferLetterTabValue =>
    selectOfferLetterFilters(state).statusSlug;
/**
 * Select Offer Letters Has Active Filter.
 */
export const selectOfferLetterHasActiveFilter = (state: IStore): boolean =>
    isNotEmpty(omit(selectOfferLetterFilters(state), 'statusSlug'));

import React, { useEffect } from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import MainContent from 'shared/components/common/MainContent';
import { FeatureSwitches } from 'utils/featureSwitches';
import { UsersTable } from './UsersTable/UsersTable';
import { CreateUserButton } from '../CreateUser/CreateUserButton';
import { useDispatch } from 'react-redux';
import { loadGlobalRoles } from 'store/entities/users/actions';
import { Box } from '@material-ui/core';
import { UserNameFilter } from 'modules/settings/submodules/platformUsers/components/UserListPage/Filter/UserNameFilter';
import PlatformUserFilter from 'modules/settings/submodules/platformUsers/components/UserListPage/Filter/PlatformUserFilter';
import EditUserModal from 'modules/settings/submodules/platformUsers/components/EditUser/EditUserModal';
import PlatformUserFilterChips
    from 'modules/settings/submodules/platformUsers/components/UserListPage/FilterChips/PlatformUserFilterChips';

export const UserListPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadGlobalRoles.init());
    }, [dispatch]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enablePlatformUsersManagement} noPlaceholder>
            <MainContent whiteBackground>
                <Box display="flex" justifyContent="space-between"
                    mb={2}>
                    <CreateUserButton/>
                    <Box display="flex">
                        <UserNameFilter/>
                        <PlatformUserFilter/>
                    </Box>
                </Box>
                <PlatformUserFilterChips/>
                <UsersTable/>
            </MainContent>
            <EditUserModal/>
        </FeatureSwitch>
    );
};

import React, { useCallback } from 'react';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useSheetRejectStyles } from './styles';
import { Button } from '@material-ui/core';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import SheetRejectLabel from './SheetRejectLabel';
import SheetRejectNotes from './SheetRejectNotes';
import { useForm } from 'react-hook-form';
import { useActions } from 'store/utils';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { IUserInfo } from 'shared/models/User';
import { StatusNames } from 'store/entities/timesheet/models/Status';

interface ISheetRejectContentProps {
    sheetType: EntryType;
    sheets: Array<ISheet>;
    users: IUserInfo[];
    onClose: () => void;
    onAction?: () => void;
}
export interface IFormData {
    rejectionNotes: Record<string, string>;
}

export default function SheetRejectContent(
    { sheets, sheetType, users, onClose, onAction }: ISheetRejectContentProps,
) {
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();
    const defaultValues: Record<string, string> = {};

    sheets.forEach(sheet => defaultValues[`rejectionNotes-${sheet.id}`] = '');

    const { control, getValues } = useForm<Record<string, string>>({
        defaultValues,
    });

    const actions = useActions({
        updateSheetsStatus: updateSheetsStatus.init,
    });

    const onSave = useCallback(() => {
        onClose();
        if (onAction) {
            onAction();
        }

        actions.updateSheetsStatus({
            statusName: StatusNames.REJECTED,
            sheetsEntryTypes: sheets.reduce((acc: Record<string, EntryType>, sheet) => ({
                ...acc,
                [sheet.id]: sheetType,
            }), {}),
            notes: sheets.reduce((acc: Record<string, string>, sheet) => ({
                ...acc,
                [sheet.id]: getValues()[`rejectionNotes-${sheet.id}`],
            }), {}),
        });
    }, [onClose, sheetType, sheets, actions, onAction, getValues]);

    return (
        <>
            <form>
                <SheetRejectLabel count={sheets.length} sheetType={sheetType}/>
                <section className={sidebarClasses.sidebarElement}>
                    {sheets.map(sheet => (
                        <SheetRejectNotes sheet={sheet} control={control}
                            key={sheet.id} sheetType={sheetType}
                            employee={
                                users.find(x => x.identity_id === sheet.user_id)?.last_name + ', '
                                + users.find(x => x.identity_id === sheet.user_id)?.first_name
                            }
                        />
                    ))}
                </section>
            </form>
            <footer className={classes.footer}>
                <Button onClick={onSave} variant="contained"
                    color="primary"
                    classes={{ root: classes.buttonSave, label: classes.buttonTextStyle }}>
                    Confirm
                </Button>
                <Button onClick={onClose} variant="contained"
                    classes={{ root: classes.buttonCancel, label: classes.buttonTextStyle }}>
                    Cancel
                </Button>
            </footer>
        </>
    );
}

import React from 'react';
import { Button } from '@material-ui/core';
import FilterSVG from 'shared/components/icons/FilterSVG';
import clsx from 'clsx';
import useActionButtonStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';

interface IFilterButtonMockProps {
    className?: string;
    overrideClassName?: string;
}

export default function FilterButtonMock(
    { className = '', overrideClassName = '' }: IFilterButtonMockProps,
) {
    const buttonClasses = useActionButtonStyles();
    const buttonRootClassName = overrideClassName ? overrideClassName : buttonClasses.default;

    return (
        <Button classes={{ root: clsx(buttonRootClassName, className) }} data-testid={FiltersTestIds.ButtonMock}>
            <FilterSVG/>
        </Button>
    );
}

import { getClientMenu } from 'modules/clients/menu';
import { getUserAvatar } from 'modules/profile/store/profileActions';

import { routes } from 'shared/routes';
import { authApi } from 'shared/utils/authApi';
import baseApi from 'shared/utils/baseApi';
import { browserHistory } from 'shared/utils/browserHistory';
import { api } from 'store/components/auth/api';
import {
    authByPassword,
    authByToken,
    authTokenUpdate,
    loginAsUser,
    setClientUserPermission,
    logout,
} from 'store/components/auth/authActions';
import { IUserTokenInfo, Permission } from 'store/components/auth/authModels';
import { selectCurrentUser, selectGlobalPermissions } from 'store/components/auth/selectors';
import { setClientId, setTenantSuccess } from 'store/entities/clients/clientsAction';
import { updateMenu } from 'store/entities/menu/actions/menu';
import Appcues from 'shared/services/Appcues/Appcues';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    call, put, select, takeLatest, take,
} from 'typed-redux-saga';
import { some } from 'lodash';

export function* authByTokenSaga() {
    try {
        const user: IUserTokenInfo = yield* call(authApi.refreshTokens);
        yield put(authByToken.success({ user }));
    } catch (error) {
        yield put(authByToken.error(null));
    }
}

export function* authByPasswordSaga(action: ReturnType<typeof authByPassword.init>) {
    const { username, password } = action.payload;
    try {
        const user: IUserTokenInfo = yield* call(authApi.passwordAuth, username, password);
        yield put(authByPassword.success({ user }));

        const urlParams = new URLSearchParams(browserHistory.location.search);
        let redirectUrl = urlParams.get('redirect_url');

        if ((redirectUrl && redirectUrl.startsWith('/auth')) || !redirectUrl) {
            redirectUrl = routes.HOME;
        }

        browserHistory.replace(redirectUrl);

    } catch (error) {
        yield put(authByPassword.error(error?.response?.data || error));
    }
}

function* authSuccessSaga(action: ReturnType<typeof authByToken.success>
| ReturnType<typeof authByPassword.success>) {
    const { payload: { user } } = action;
    Appcues.user(user.sub, user.email, `${user.given_name} ${user.family_name}`);
    Appcues.setPermissions(user.global_permissions || []);
    yield put(getUserAvatar.init());
}

function* authTokenUpdateSaga() {
    try {
        const user: IUserTokenInfo = yield* call(authApi.refreshTokens);
        yield put(authTokenUpdate.success({ user }));
    } catch (error) {
        yield put(authTokenUpdate.error(null));
    }
}

export function* authSuccessWatcher() {
    yield* takeLatest([authByPassword.successType, authByToken.successType], authSuccessSaga);
}

export function* authByTokenWatcher() {
    yield takeLatest(authByToken.initType, authByTokenSaga);
}

export function* authByPasswordWatcher() {
    yield takeLatest(authByPassword.initType, authByPasswordSaga);
}

function* authTokenUpdateWatcher() {
    yield takeLatest(authTokenUpdate.initType, authTokenUpdateSaga);
}

function* setAppClientSaga(
    action: ReturnType<typeof authByToken.success>,
) {
    const { payload: { user } } = action;
    const configurationClientId = process.env.REACT_APP_CLIENT_ID;
    if (configurationClientId && some(user?.clients, client => client.client_id === configurationClientId)) {
        yield put(setClientId(configurationClientId));
    }
}

export function* setAppClientSagaWatcher() {
    yield* takeLatest([
        authByPassword.successType,
        authByToken.successType,
        authByToken.successType,
    ], setAppClientSaga);
}

export function* logoutSaga() {
    yield* call(authApi.removeTokens);
    yield put(updateMenu([]));
    browserHistory.push(routes.AUTH.LOGIN);
}
export function* logoutWatcher() {
    yield takeLatest(logout.action, logoutSaga);
}

export function* setAuthTenantSaga({ payload: clientId }: ReturnType<typeof setClientId>) {
    const user = yield* select(selectCurrentUser);
    const globalPermissions = yield* select(selectGlobalPermissions);

    let permissions: Permission[] = [];
    if (clientId && user) {
        baseApi.clientId = clientId;

        const currentClient = user.clients.find(client => client.client_id === clientId);
        permissions = currentClient?.permissions || globalPermissions || [];
        Appcues.setPermissions(permissions);
        const menuItems = getClientMenu({ permissions, clientId });
        yield put(updateMenu(menuItems));
    } else {
        baseApi.clientId = null;
    }
    yield put(setClientUserPermission([...globalPermissions, ...permissions]));
    yield put(setTenantSuccess(clientId));
}

function* setTenantWatcher() {
    yield* takeLatest(setClientId.action, setAuthTenantSaga);
}

export function* loginAsUserSaga({ payload: userId }: ReturnType<typeof loginAsUser.init>) {
    const userTokens = yield* call(api.getImpersonateUserToken, userId);
    yield* put(logout());
    yield* take(logout.action);
    authApi.setAuthTokens(userTokens.access_token, userTokens.refresh_token);
    yield* put(authByToken.init());
    browserHistory.replace(routes.HOME);
    yield put(loginAsUser.success());
}

function* loginAsUserWatcher() {
    yield* takeLatest(loginAsUser.initType, withBackendErrorHandler(
        loginAsUserSaga,
        loginAsUser.error,
        'Unable to login as user',
    ));
}

export default [
    authSuccessWatcher,
    authByTokenWatcher,
    authByPasswordWatcher,
    logoutWatcher,
    setTenantWatcher,
    authTokenUpdateWatcher,
    setAppClientSagaWatcher,
    loginAsUserWatcher,
];

import { IDepartment } from 'modules/employmentInfo/models/Department';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import { IActivity, IAssignment, IProjectWithAssignment, ITask } from 'store/entities/configuration/configurationModel';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { Nullable } from '../../../../@types/types';
import { IJobNumber } from 'shared/models/JobNumber';

export interface IEntryRow {
    entry: IEntry;
    assignment?: IAssignment;
    projectAssignment?: IProjectWithAssignment;
    task?: ITask;
    activity?: IActivity;
    entryFilter?: EntryType;
    className?: string;
    location?: ILocation;
    position?: IPosition;
    department?: IDepartment;
    jobNumber?: IJobNumber;
    userId?: string;
    error?: Nullable<string>;
}

export enum EntriesGridCellsTitles {
    Assignment = 'project/assignment',
    Activity = 'activity',
    Task = 'task',
    ScaZone = 'sca zone',
    ZipCode = 'zip',
    Receipt = 'receipt',
    Notes = 'notes',
    Hours = 'hours',
    Amount = 'amount',
    Actions = 'actions',
}

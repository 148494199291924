import React, { useMemo } from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { useSelector } from 'react-redux';
import { IProjectWithAssignmentBackend } from 'store/entities/configuration/configurationModel';
import { IFormFieldProps } from '../formFields/models';
import { selectProjectsWithAssignments } from 'store/entities/configuration/configurationSelectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IFormSelect } from './model';

interface IAssignmentProps extends IFormFieldProps, IFormSelect {
    areaId?: number;
    supervisorId?: string;
    unavailableAreasIds?: number[];
}

interface IAssignmentSelectStoreProps {
    projectsWithAssignment: IProjectWithAssignmentBackend[];
}

export function ProjectsWithAssignmentPure({
    projectsWithAssignment,
    disabled,
    ...props
}: IAssignmentProps & IAssignmentSelectStoreProps) {

    return (
        <FormSelect
            {...props}
            getKey={({ project_id }) => project_id}
            getText={({ description }) => description}
            options={projectsWithAssignment}
            disabled={disabled || !projectsWithAssignment.length}
        />
    );
}

export const ProjectWithAssignment = separateLogicDecorator<
IAssignmentProps,
IAssignmentSelectStoreProps
>(({
    areaId,
    supervisorId,
    unavailableAreasIds = [],
}) => {
    const projectWithAssignments = useSelector(selectProjectsWithAssignments);

    const assignmentsMenu = useMemo(() => {
        const supervisorProjectsIds = supervisorId ? projectWithAssignments
            .filter(projectAssignment => projectAssignment.assignment.user_id === supervisorId)
            .map(projectAssignment => projectAssignment.project_id) : [];

        return projectWithAssignments.filter(({ project_id, area_id }) => {
            let filtered = !area_id || !unavailableAreasIds.includes(area_id);
            if (areaId !== undefined) {
                filtered = filtered && area_id === areaId;
            }
            if (supervisorId) {
                filtered = filtered && supervisorProjectsIds.includes(project_id);
            }
            return filtered;
        });
    }, [
        projectWithAssignments,
        areaId,
        supervisorId,
        unavailableAreasIds,
    ]);
    return { projectsWithAssignment: assignmentsMenu };
})(ProjectsWithAssignmentPure);

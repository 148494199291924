import { useOptionalTimeExpenseReportTemplate } from 'modules/payrollProcessorHub/components/SheetStatusAction/utils';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ActionButton from 'shared/components/buttons/ActionButton';
import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { useModal } from 'shared/utils/hooks/useModal';
import {
    RejectSheetGroups,
} from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectSheetGroups';

export interface IRejectButtonProps {
    groupIds: ISheetGroupId[];
}

export const RejectButton = ({ groupIds }: IRejectButtonProps) => {
    const sidebarClasses = useSidebarStyles();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const optionalTemplateFunc = useOptionalTimeExpenseReportTemplate(groupIds);
    const title = `Reject Submitted${optionalTemplateFunc('Timesheet', 'Expense', 'Timesheet and Expense')}report`;
    return (
        <>
            <ActionButton
                variant="outlined"
                color="primary"
                customType="danger"
                onClick={onModalOpen}
            >
                Reject
            </ActionButton>
            <Sidebar
                isOpened={isModalOpened}
                onClose={onModalClose}
                anchor="right"
                title={title}
                titleClasses={{
                    root: sidebarClasses.whiteTitle,
                }}
            >
                <Box
                    mt={4}
                    mb={4}
                    mr={7}
                    ml={7}
                >
                    <Box>
                        <Typography gutterBottom>
                            You are not on the approver list of this
                            {optionalTemplateFunc('timesheet', 'expense', 'timesheet and expense')}
                            report.
                        </Typography>
                        <Typography gutterBottom>
                            Are you sure you want to bypass the regular approval flow and reject
                            {optionalTemplateFunc('the timesheet', 'the  expense', 'both timesheet and expense')}
                            report?
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        Please enter the reasoning below.
                    </Box>
                </Box>
                <RejectSheetGroups
                    groupIds={groupIds}
                    onCancel={onModalClose}
                />
            </Sidebar>
        </>
    );
};

import React, { useCallback, useEffect } from 'react';
import { useModal } from 'shared/utils/hooks/useModal';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { selectToastState } from 'store/entities/appConfig/appConfigSelectors';
import Toast from './Toast';
import { useDispatch, useSelector } from 'react-redux';

export function GlobalToast() {
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const toastState = useSelector(selectToastState);
    const dispatch = useDispatch();
    const handleModalClose = useCallback((event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setGlobalToast(null));

        onModalClose();
    }, [onModalClose, dispatch]);
    useEffect(() => {
        if (toastState) {
            onModalOpen();
        }
    }, [onModalOpen, toastState]);

    return toastState && (
        <Toast
            title={toastState.title}
            severity={toastState.severity}
            ignoreSidebar={toastState.ignoreSidebar}
            isOpened={isModalOpened}
            onClose={handleModalClose}
            autoHideDuration={toastState.autoHideDuration}
        />
    );
}

import { dollarFormatter } from 'shared/utils/formatters/dollarFormatter';
import { IEntry, ISheet, QuantityType } from 'shared/models/sheet/Sheet';
import { convertToCents } from 'shared/utils/converters/convertToCents';
import { getFormattedMiles, getFormattedOdometer } from 'shared/components/formSpecialFields/expenseData/utils';

export function dollarCounter(totalCents: number): string {
    const dollars = Math.floor(totalCents / 100);
    const cents = (totalCents - dollars * 100);
    return dollarFormatter(dollars, cents);
}

export function totalDollars(entries: Array<IEntry>): string {
    const amount = entries.reduce(
        (acc: number, entry) => {
            if (entry.data.entry_type === QuantityType.MONEY) {
                return acc + convertToCents(entry.data.dollars);
            }
            if (entry.data.entry_type === QuantityType.ODOMETER) {
                return acc + convertToCents(getFormattedOdometer(entry.data));
            }
            if (entry.data.entry_type === QuantityType.MILES) {
                return acc + convertToCents(getFormattedMiles(entry.data.miles));
            }
            return acc;
        },
        0,
    );

    return dollarCounter(amount);
}

export function sheetsTotalDollars(sheets: Array<ISheet>): string {
    const amount = sheets.reduce((acc: number, sheet) => acc + convertToCents(sheet.total_dollars), 0);

    return dollarFormatter(Math.floor(amount / 100), amount % 100);
}

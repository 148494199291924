import * as yup from 'yup';
import { useEntriesByDay } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/utils/entriesByDay';
import { IDepartment } from 'modules/employmentInfo/models/Department';
import { useMemo } from 'react';
import { moment } from 'utils/momentExtensions';
import { useSelector } from 'react-redux';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import { selectCurrentClientInputsConfiguration } from 'store/entities/clients/clientsSelectors';
import { selectTimeEntries } from 'store/entities/timesheet/selectors';
import { constants } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/constants/constants';
import { IActivity, IProjectWithAssignment } from 'store/entities/configuration/configurationModel';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { EntryValidation } from 'store/entities/timesheet/models/validation';
import { ITimeInputValue } from '../../components/formFields/utils';
import { ValidationMessages } from '../Validation';
import { selectUserDepartmentsList } from 'modules/employmentInfo/store/department/selectors';
import { addCommonFields, CommonEntryShapeType, DefaultShapeType } from 'shared/models/validationSchemes/sheetCommon';
import {
    IBreakEntryData,
    IFileEntryData, IInOutBreakDataBackend,
    IInOutBreakEntryData,
    IInOutEntryData,
    ITimeEntry,
    QuantityType,
} from '../sheet/Sheet';
import {
    compareInOutTimeValue,
    getDurationFromTime,
    getMinutesByTimeUnits,
    TIME_FORMAT,
} from '../DateTime';
import { ITimeEntryFormValues } from '../../components/forms/entries/TimeEntryModel';
import { showField } from '../../components/forms/utils';
import { IJobNumber, TimesheetSettings } from '../JobNumber';

const checkTimeInShouldBeLessThanTimeOut = (timeFieldFormat: string) =>
    (value: (IInOutEntryData | IBreakEntryData | IInOutBreakEntryData) | null): boolean => {
        if (value) {
            if (
                [
                    QuantityType.TIME_IN_OUT,
                    QuantityType.TIME_BREAK,
                    QuantityType.TIME_IN_OUT_BREAK,
                ].includes(value.entry_type)
            ) {
                if (value.timeIn && value.timeOut) {
                    return compareInOutTimeValue(value.timeIn, value.timeOut, timeFieldFormat) > 0;
                }
            }
        }
        return true;
    };

export const checkNoIntersections = (
    value: IInOutBreakEntryData,
    day: string,
    timeEntriesByDay: Record<string, ITimeEntry[]>,
): boolean => {
    const intersectionEntryTypes = [
        QuantityType.TIME_IN_OUT,
        QuantityType.TIME_BREAK,
        QuantityType.TIME_IN_OUT_BREAK,
    ];
    const dayEntries = timeEntriesByDay[day] || [];
    const entriesToCheck = dayEntries.map(entry => entry.data)
        .filter(data => intersectionEntryTypes.includes(data.entry_type)) as IInOutBreakDataBackend[];
    if (
        value
        && intersectionEntryTypes.includes(value.entry_type)
        && entriesToCheck.length > 0
    ) {
        const entryDay = moment(day);
        const newEntryRange = moment.range(
            entryDay.clone().add(moment.duration(value.timeIn)),
            entryDay.clone().add(moment.duration(value.timeOut)),
        );
        return !entriesToCheck.some(data => {
            const existEntryRange = moment.range(moment(data.time_in), moment(data.time_out));
            return newEntryRange.intersect(existEntryRange);
        });
    }
    return true;
};

export const getTimeInTimeOutValidation = (
    schema: yup.ObjectSchema,
    timeFieldFormat: string = TIME_FORMAT,
) => (
    schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`(${[QuantityType.TIME_IN_OUT, QuantityType.TIME_BREAK].join('|')})`)),
            timeIn: yup.string().nullable().required(ValidationMessages.REQUIRED),
            timeOut: yup.string().nullable().required(ValidationMessages.REQUIRED),
        })
        .required(ValidationMessages.REQUIRED)
        .test({
            name: 'timeInShouldBeLessThanTimeOut',
            test: checkTimeInShouldBeLessThanTimeOut(timeFieldFormat),
            message: ValidationMessages.TIME_IN_LESS_THAN_TIME_OUT,
            exclusive: true,
        })
);

const getTimeDefaultValidation = (schema: yup.ObjectSchema, totalMinutesByDay: Record<string, number> = {}) => (
    schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`(${QuantityType.TIME})`)),
        })
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .test({
            name: 'timeNotEmpty',
            test: (value: ITimeInputValue | null): boolean => {
                return !!value?.hours || !!value?.minutes;
            },
            message: EntryValidation.NotEmpty,
            exclusive: true,
        })
        .test({
            name: 'totalEntryTimesLessDay',
            params: { totalMinutesByDay },
            test: function (value: ITimeInputValue | null): boolean {
                if (!value) {
                    return true;
                }
                const { hours, minutes } = value;
                if (hours === undefined && minutes === undefined) {
                    return true;
                }
                const totalMinutes = totalMinutesByDay[this.parent.entry_date] || 0;
                return hours * constants.minutesInHour + minutes
                    <= constants.hoursInDay * constants.minutesInHour - totalMinutes;
            },
            message: EntryValidation.Exceed,
            exclusive: true,
        })
);

const getTimeInOutBreakValidation = (
    schema: yup.ObjectSchema,
    timeFieldFormat: string = TIME_FORMAT,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    entriesByDays: Record<string, ITimeEntry[]> = {},
) => (
    schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`${QuantityType.TIME_IN_OUT_BREAK}`)),
        })
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .test({
            name: 'timeInShouldBeLessThanTimeOut',
            test: checkTimeInShouldBeLessThanTimeOut(timeFieldFormat),
            message: ValidationMessages.TIME_IN_LESS_THAN_TIME_OUT,
            exclusive: true,
        })
        .test({
            name: 'BreakLessTime',
            test: function (value: IInOutBreakEntryData | null): boolean {
                if (!value) {
                    return true;
                }
                const { hoursBreak, minutesBreak, timeIn, timeOut } = value;
                if (hoursBreak === undefined && minutesBreak === undefined) {
                    return true;
                }
                return getMinutesByTimeUnits({ hours: hoursBreak, minutes: minutesBreak })
                    < getMinutesByTimeUnits(getDurationFromTime(timeIn, timeOut));
            },
            message: ValidationMessages.BREAK_LESS_THAN_TIME_IN_OUT,
            exclusive: true,
        })
);

const getPerFilesValidation = (schema: yup.ObjectSchema) => (
    schema
        .shape({
            entry_type: yup.string().required(ValidationMessages.REQUIRED)
                .matches(new RegExp(`${QuantityType.FILE}`)),
        })
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .test({
            name: 'FileNotEmpty',
            test: (value: IFileEntryData | null): boolean => {
                return !!value?.files;
            },
            message: EntryValidation.NotEmpty,
            exclusive: true,
        })
);

type TimeEntryShapeType = CommonEntryShapeType & Partial<Record<keyof ITimeEntryFormValues, DefaultShapeType>>

export function createTimeEntryValidationSchema(
    fields: InputFields,
    totalMinutesByDay: Record<string, number> = {},
    departments: IDepartment[] = [],
    entriesByDays: Record<string, ITimeEntry[]> = {},
) {
    const shape: TimeEntryShapeType = addCommonFields({}, fields, departments);

    if (showField(fields, EntrySlug.JobNumber)) {
        shape.data = yup.object()
            .nullable()
            .required(ValidationMessages.REQUIRED)
            .when(
                'jobNumber',
                (jobNumber: IJobNumber | undefined, schema: yup.ObjectSchema) => {
                    if (jobNumber?.timesheet_setting === TimesheetSettings.PerFile) {
                        return getPerFilesValidation(schema);
                    }
                    return getTimeInOutBreakValidation(schema, undefined, entriesByDays);
                });
    } else {
        shape.data = yup.object()
            .nullable()
            .required(ValidationMessages.REQUIRED)
            .when(
                'activity',
                (activity: IActivity | undefined, schema: yup.ObjectSchema) => {
                    switch (activity?.data_type) {
                        case QuantityType.TIME_IN_OUT:
                        case QuantityType.TIME_BREAK:
                            return getTimeInTimeOutValidation(schema);
                        default:
                            return getTimeDefaultValidation(schema, totalMinutesByDay);
                    }
                },
            );
    }

    shape.scaZone = yup.object().nullable().when(
        ['projectAssignment'],
        {
            is: (projectAssignment: IProjectWithAssignment | null) => projectAssignment?.sca_zone_id,
            then: yup.object().required('Required'),
        },
    );

    return yup.object().shape(shape);
}

export function useTimeEntryValidationSchema(
    userId?: string,
    ignoreEntryId?: string,
) {
    const timeEntries = useSelector(selectTimeEntries);
    const filteredTimeEntries = useMemo(() => {
        return timeEntries.filter(entry => entry.id !== ignoreEntryId);
    }, [timeEntries, ignoreEntryId]);
    const totalMinutesByDay = useTotalTimeEntriesByDay(filteredTimeEntries);
    const timeEntriesByDays = useEntriesByDay(filteredTimeEntries) as Record<string, ITimeEntry[]>;
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const departments = useSelector(selectUserDepartmentsList(userId));
    return useMemo(
        () => createTimeEntryValidationSchema(
            inputsConfiguration.time,
            totalMinutesByDay || {},
            departments,
            timeEntriesByDays,
        ),
        [totalMinutesByDay, inputsConfiguration, departments, timeEntriesByDays],
    );
}

import React, { useMemo } from 'react';
import { useFeature } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import {
    getApprovedLevelCell,
    getDetailsCell,
    getHoursAmountCell,
    getStatusCell,
} from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useSheetColumnDictionary } from 'shared/components/table/SheetSupervisorTable/useSheetColumnDictionary';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { getMobileItemsRowByConfig } from 'shared/components/table/utils';
import { EntryType, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { userFullName } from 'shared/utils/converters/user';
import { areaView } from 'shared/utils/formatters/areaFormatter';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import {
    AvailableTableConfiguration,
    IColumnConfiguration,
    SheetColumnSlug,
} from 'store/entities/clients/clientsModel';
import { selectTableConfiguration } from 'store/entities/clients/clientsSelectors';
import { getColumnsByConfiguration } from 'store/utils/tables';
import { FeatureSwitches } from 'utils/featureSwitches';
import { IStore } from 'store/configureStore';
import { selectCurrentUserApprovalsLevelByAssignments } from 'store/entities/configuration/configurationSelectors';

export const useSheetReviewerCells = (
    sheetRows: ISheetRow[],
    onDetailsClick: (sheetInfo: ISheetClickInfo) => void,
    entryType: EntryType,
    isMobile: boolean,
    checkboxCell?: ICellInfo<ISheetRow>,
    actionsCell?: ICellInfo<ISheetRow>,
    showStatus = false,
): Array<ICellInfo<ISheetRow>> => {
    const classes = useSubmittedTableStyles();
    const configuration = useSelector(selectTableConfiguration(
        entryType === EntryType.EXPENSE
            ? AvailableTableConfiguration.ManagerExpenseSheet : AvailableTableConfiguration.ManagerTimeSheet,
    )) as IColumnConfiguration<SheetColumnSlug>[];
    const columnDictionary = useSheetColumnDictionary(isMobile);
    const [statusCellFeatureFlag] = useFeature(FeatureSwitches.displayStatusColumnApproveView);
    const activeStatusTab = useSelector((state: IStore) => state.appConfig.sheetGridStatus);

    const currentUserApprovalLevelsByAssignments = useSelector(selectCurrentUserApprovalsLevelByAssignments);

    return useMemo(() => {
        const checkboxCellWrapper = checkboxCell ? [checkboxCell as any] : [];
        const actionsCellWrapper = actionsCell ? [actionsCell] : [];
        const detailsCell = getDetailsCell(classes, entryType, onDetailsClick, isMobile);
        const hoursAmountCell = getHoursAmountCell(classes, entryType, isMobile);
        const statusCell = getStatusCell(classes, statusCellFeatureFlag, showStatus);

        const oneSheetRowNeedsHighManagerApproval = sheetRows
            .some(sr => {
                const currentUserManagementLevel = currentUserApprovalLevelsByAssignments[sr.sheet.assignment_id || ''] || 0;
                return sr.sheet.approved_level < currentUserManagementLevel && currentUserManagementLevel > 1;
            });

        const approvedLevelCell = getApprovedLevelCell(classes, oneSheetRowNeedsHighManagerApproval, activeStatusTab);

        return isMobile ? [
            ...checkboxCellWrapper,
            {
                key: 'all details',
                title: '',
                width: '2fr',
                render: function FullSheetInfo({
                    user,
                    sheet,
                    className,
                    area,
                    position,
                    location,
                    jobNumber,
                }: ISheetRow) {
                    return (
                        <MobileListCell
                            title={userFullName(user)}
                            className={className}
                            items={[
                                getPayPeriodByStartEnd(sheet.period_start, sheet.period_end),
                                getMobileItemsRowByConfig(
                                    [
                                        {
                                            slug: SheetColumnSlug.Area,
                                            getText: () => areaView(area),
                                        },
                                        {
                                            slug: SheetColumnSlug.Position,
                                            getText: () => position?.name,
                                        },
                                        {
                                            slug: SheetColumnSlug.Location,
                                            getText: () => location?.name,
                                        },
                                        {
                                            slug: SheetColumnSlug.JobNumber,
                                            getText: () => jobNumber?.job_number?.toString(),
                                        },
                                    ],
                                    configuration,
                                ),
                            ]}
                        />
                    );
                },
            },
            hoursAmountCell(entryType === EntryType.TIME ? 'hours' : 'amount'),
            detailsCell,
        ] : [
            ...checkboxCellWrapper,
            ...getColumnsByConfiguration(configuration, columnDictionary, classes),
            ...actionsCellWrapper,
            ...statusCell,
            ...approvedLevelCell,
            detailsCell,
        ];
    }, [
        classes,
        isMobile,
        entryType,
        onDetailsClick,
        checkboxCell,
        actionsCell,
        columnDictionary,
        configuration,
        showStatus,
        statusCellFeatureFlag,
        activeStatusTab,
        sheetRows,
        currentUserApprovalLevelsByAssignments,
    ]);
};

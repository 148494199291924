import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import {
    loadExpenseSheets,
    loadExpenseSheetsWithEntries,
    addExpenseEntry,
    removeExpenseEntry,
    updateExpenseEntry,
} from 'store/entities/timesheet/actions/expenseActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { createAddEntrySaga, createDeleteEntrySaga, createUpdateEntrySaga } from 'store/entities/timesheet/sagas/utils';
import { IStore } from 'store/configureStore';
import { withErrorHandler } from 'store/utils/sagas/withErrorHandler';
import { IExpenseSheetBackend, IStatus } from 'shared/models/sheet/Sheet';
import { IUpdateSheetsStatus, IUpdateSheetStatus, StatusNames } from '../models/Status';
import {
    selectExpenseSheetStatusByName,
    selectExpensesSheetsByIds,
} from 'store/entities/timesheet/selectors';
import { selectClientApproversFrom, selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import {
    selectApproversCountBySheets,
    selectCurrentUserApprovalsLevelByAssignments,
} from 'store/entities/configuration/configurationSelectors';
import { tryApproveSheet } from 'store/entities/timesheet/helpers';
const selectSheetByEntryId = (entryId: string) => (state: IStore) => state.sheets.expenses.entriesById[entryId];
const addExpenseEntrySaga = createAddEntrySaga(expenseApi.createEntry, addExpenseEntry.success);

function* addExpenseEntryWatcher() {
    yield takeEvery(addExpenseEntry.initType, addExpenseEntrySaga);
}

const updateExpenseEntrySaga = createUpdateEntrySaga(
    expenseApi.updateEntry,
    updateExpenseEntry.success,
    selectSheetByEntryId,
);

function* updateExpenseEntryWatcher() {
    yield* takeEvery(updateExpenseEntry.initType, updateExpenseEntrySaga);
}

const removeExpenseEntrySaga = createDeleteEntrySaga(
    expenseApi.deleteEntry,
    removeExpenseEntry.success,
    selectSheetByEntryId,
);

function* removeExpenseEntryWatcher() {
    yield* takeEvery(removeExpenseEntry.initType, removeExpenseEntrySaga);
}

function* loadExpenseSheetsSaga({ payload }: ReturnType<typeof loadExpenseSheetsWithEntries.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(expenseApi.getSheetListByPurpose, purpose, request || {});
    yield* put(loadExpenseSheetsWithEntries.success(sheets));
}

function* loadExpenseSheetsWatcher() {
    yield takeLatest(loadExpenseSheetsWithEntries.initType, withErrorHandler(
        loadExpenseSheetsSaga,
        loadExpenseSheetsWithEntries.error,
        'Expense sheets were not loaded',
    ));
}

function* loadSimplifiedExpenseSheetsSaga({ payload }: ReturnType<typeof loadExpenseSheets.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(expenseApi.getSimplifiedSheetListByPurpose, purpose, request || {});
    yield* put(loadExpenseSheets.success(sheets));
}

function* loadSimplifiedExpenseSheetsWatcher() {
    yield takeLatest(loadExpenseSheets.initType, withErrorHandler(
        loadSimplifiedExpenseSheetsSaga,
        loadExpenseSheets.error,
        'Expense sheets were not loaded',
    ));
}

//the following function  is used to reject only since november 2020, approve is made with create approval api.
export function* updateExpenseSheetsStatusesSaga(sheetsIds: string[], status: IStatus, notes?: Record<string, string>) {
    const payload: IUpdateSheetsStatus = {
        sheets: sheetsIds.map(id => {
            const sheet: IUpdateSheetStatus = {
                id,
                status_id: status.id,
            };

            if (notes){
                sheet.notes = notes[id];
            }

            return sheet;
        }),
    };
    const updatedSheets = yield* call(
        expenseApi.updateSheetsStatuses,
        payload,
    );
    yield* put(loadExpenseSheetsWithEntries.success(updatedSheets));
}

export function* createExpenseSheetApprovalSaga(sheetsIds: string[]) {
    yield* call(
        expenseApi.createSheetApprovals, sheetsIds,
    );

    const sheetsById = yield* select(selectExpensesSheetsByIds);
    const clientId = yield* select(selectCurrentClientId);
    const currentUserApprovalLevelsByAssignments = yield* select(selectCurrentUserApprovalsLevelByAssignments);
    const approversFrom = yield* select(selectClientApproversFrom);
    const approversCountBySheets = yield* select(selectApproversCountBySheets(sheetsIds));
    const approvedStatus = yield* select(selectExpenseSheetStatusByName(StatusNames.APPROVED));
    const updatedSheets = sheetsIds.map(sheetId => {
        const sheet: IExpenseSheetBackend = {
            ...sheetsById[sheetId],
            entries: [],
        };

        const possiblyUpdatedSheet = tryApproveSheet(
            sheet,
            approversFrom,
            currentUserApprovalLevelsByAssignments,
            approversCountBySheets,
            clientId,
            approvedStatus,
        );
        return possiblyUpdatedSheet;
    });
    yield* put(loadExpenseSheetsWithEntries.success(updatedSheets));
}

export default [
    addExpenseEntryWatcher,
    updateExpenseEntryWatcher,
    removeExpenseEntryWatcher,
    loadExpenseSheetsWatcher,
    loadSimplifiedExpenseSheetsWatcher,
];

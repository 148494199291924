import React, { useCallback } from 'react';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { useDispatch, useSelector } from 'react-redux';
import { sendSheetGroupReminder } from '../../store/actions';
import { sendReminderLoadingModule } from '../../store/loadingModules';
import { generateGroupRowId } from '../../store/helpers';
import { IGroupedSheetCalculation } from '../../store/model';
import { IStore } from '../../../../store/configureStore';
import { selectPayrollProcessorHubState } from '../../store/selectors';
import { useSendReminderStyles } from 'modules/payrollProcessorHub/components/SendReminder/styles';

export interface ISendReminderButtonProps {
    timeSheetId: string | null;
    expenseSheetId: string | null;
}

export const SendReminderButton = ({ timeSheetId, expenseSheetId }: ISendReminderButtonProps) => {
    const dispatch = useDispatch();
    const classes = useSendReminderStyles();
    const isLoading = useSelector(sendReminderLoadingModule.getSelector(
        generateGroupRowId(
            { time_sheet_id: timeSheetId, expense_sheet_id: expenseSheetId } as IGroupedSheetCalculation,
        ),
        (state: IStore) => selectPayrollProcessorHubState(state),
    ));

    const onClick = useCallback(() => {
        dispatch(sendSheetGroupReminder.init({
            timeSheetId,
            expenseSheetId,
        }));
    }, [dispatch, timeSheetId, expenseSheetId]);
    return (
        <ButtonWithLoader
            variant="contained"
            color="primary"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onClick}
            classes={classes}
        >
            Send reminder
        </ButtonWithLoader>
    );
};

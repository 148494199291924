import { EmployeePortal } from 'modules/employeePortal/EmployeePortal';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { routes } from 'shared/routes';
import Employees from 'modules/employees/Employees';
import EmploymentInfo from 'modules/employmentInfo/EmploymentInfo';
import Evaluations from 'modules/evaluations/Evaluations';
import Onboarding from 'modules/onboarding/Onboarding';
import ExpenseApproval from './expenseApproval/ExpenseApproval';
import TimeApproval from './timeApproval/TimeApproval';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import TimeAndExpensePage from 'modules/clients/content/TimeAndExpensePage/TimeAndExpensePage';
import { Permission } from 'store/components/auth/authModels';
import { ClientDashboard } from 'modules/dashboard/ClientDashboard';
import OfferLetterModule from '../../offerLetter/OfferLetterModule';

const Content: React.FC = () => {
    return (
        <div style={{ height: '100%' }} className="layout-content">
            <Switch>
                <PrivateRoute
                    path={routes.CLIENT.DASHBOARD}
                    component={ClientDashboard}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.EMPLOYEES}
                    component={Employees}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.EMPLOYMENT_INFO}
                    component={EmploymentInfo}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.OFFER_LETTER.ROOT}
                    component={OfferLetterModule}
                />
                <PrivateRoute
                    path={routes.CLIENT.ONBOARDING}
                    component={Onboarding}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.EVALUATIONS}
                    component={Evaluations}
                    permission={Permission.SubmitSheets}
                    exact
                />
                <PrivateRoute
                    path={routes.CLIENT.TIME_AND_EXPENSE.ROOT}
                    component={TimeAndExpensePage}
                />
                <PrivateRoute
                    path={routes.CLIENT.TIME_APPROVAL.ROOT}
                    component={TimeApproval}
                    permission={Permission.ApproveSheets}
                />
                <PrivateRoute
                    path={routes.CLIENT.EXPENSE_APPROVAL.ROOT}
                    component={ExpenseApproval}
                    permission={Permission.ApproveSheets}
                />
                <PrivateRoute
                    path={routes.CLIENT.EMPLOYEE_PORTAL}
                    component={EmployeePortal}
                    permission={Permission.ViewMyOffers}
                />
                <Redirect
                    // Temporary redirect for support BE employee link
                    exact
                    path="/rti/offer-letter/:id"
                    to={routes.EMPLOYEE_OFFER_LETTER.DETAIL}
                />
                <Redirect exact from={routes.CLIENT.ROOT}
                    to={routes.CLIENT.DASHBOARD}/>
            </Switch>
        </div>
    );
};

export default Content;

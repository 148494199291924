import { ISheetCommonBackend, IStatus } from 'shared/models/sheet/Sheet';
import { ApproversFromFields } from 'store/entities/clients/clientsModel';
import { Dictionary } from 'lodash';

export function tryApproveSheet<TSheet extends ISheetCommonBackend>(
    sheet: TSheet,
    approversFrom: ApproversFromFields | undefined,
    currentUserApprovalLevelsByAssignments: Dictionary<number | undefined>,
    approversCountBySheets: {[p: string]: number},
    clientId: string,
    approvedStatus: IStatus,
){
    const currentUserApprovalLevel = approversFrom === ApproversFromFields.FromJobNumber
        ? 1
        : sheet.assignment_id
            ? currentUserApprovalLevelsByAssignments[sheet.assignment_id] || 0
            : 0;

    const approversCount = approversCountBySheets[sheet.id];

    const updatedSheet = {
        ...sheet,
    };

    updatedSheet.approved_level = currentUserApprovalLevel;
    if (updatedSheet.approved_level >= approversCount){
        updatedSheet.status = approvedStatus;
    }
    return updatedSheet;
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router-dom';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { routes } from 'shared/routes';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { FeatureSwitches } from 'utils/featureSwitches';
import { getPayrollPayPeriods } from 'modules/payrollProcessorHub/store/actions';
import { EditPayrollSheetPage } from 'modules/payrollProcessorHub/components/EditSheet/EditPayrollSheetPage';
import { PayrollDashboard } from 'modules/payrollProcessorHub/PayrollDashboard/PayrollDashboard';
import { PayrollSheets } from 'modules/payrollProcessorHub/PayrollSheets';

export const PayrollProcessorHubRoutes = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPayrollPayPeriods.init());
    }, [dispatch]);
    return (
        <FeatureSwitch feature={FeatureSwitches.enablePayrollProcessorHub}>
            <Switch>
                <PrivateRoute
                    path={routes.PAYROLL_PROCESSOR_HUB.EDIT_SHEET}
                    component={EditPayrollSheetPage}
                />
                <PrivateRoute
                    path={routes.PAYROLL_PROCESSOR_HUB.SHEETS}
                    component={PayrollSheets}
                />
                <PrivateRoute
                    path={routes.PAYROLL_PROCESSOR_HUB.ROOT}
                    component={PayrollDashboard}
                    exact
                />
            </Switch>
        </FeatureSwitch>
    );
};

import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import { IAuthSuccess, IPasswordAuthRequest, Permission } from './authModels';

export const AUTHENTICATION_TOKEN_REQUESTED = 'AUTHENTICATION_TOKEN_REQUESTED';
export const AUTHENTICATION_TOKEN_SUCCEEDED = 'AUTHENTICATION_TOKEN_SUCCEEDED';
export const AUTHENTICATION_TOKEN_FAILED = 'AUTHENTICATION_TOKEN_FAILED';

export const AUTHENTICATION_PASSWORD_REQUESTED = 'AUTHENTICATION_PASSWORD_REQUESTED';
export const AUTHENTICATION_PASSWORD_SUCCEEDED = 'AUTHENTICATION_PASSWORD_SUCCEEDED';
export const AUTHENTICATION_PASSWORD_FAILED = 'AUTHENTICATION_PASSWORD_FAILED';

export const AUTHENTICATION_TOKEN_UPDATE = 'AUTHENTICATION_TOKEN_UPDATE';
export const AUTHENTICATION_TOKEN_UPDATE_SUCCESS = 'AUTHENTICATION_TOKEN_UPDATE_SUCCESS';
export const AUTHENTICATION_TOKEN_UPDATE_ERROR = 'AUTHENTICATION_TOKEN_UPDATE_ERROR';

export const AUTHENTICATION_FORGET_TOKENS_REQUESTED = 'AUTHENTICATION_FORGET_TOKENS_REQUESTED';
export const AUTHENTICATION_FORGET_TOKENS_SUCCEEDED = 'AUTHENTICATION_FORGET_TOKENS_SUCCEEDED';
export const AUTHENTICATION_FORGET_TOKENS_FAILED = 'AUTHENTICATION_FORGET_TOKENS_FAILED';

export const authByToken = createActions<void, IAuthSuccess, any,
    typeof AUTHENTICATION_TOKEN_REQUESTED,
    typeof AUTHENTICATION_TOKEN_SUCCEEDED,
    typeof AUTHENTICATION_TOKEN_FAILED
>(
    AUTHENTICATION_TOKEN_REQUESTED,
    AUTHENTICATION_TOKEN_SUCCEEDED,
    AUTHENTICATION_TOKEN_FAILED,
);

export const authByPassword = createActions<IPasswordAuthRequest, IAuthSuccess, any,
    typeof AUTHENTICATION_PASSWORD_REQUESTED,
    typeof AUTHENTICATION_PASSWORD_SUCCEEDED,
    typeof AUTHENTICATION_PASSWORD_FAILED
>(
    AUTHENTICATION_PASSWORD_REQUESTED,
    AUTHENTICATION_PASSWORD_SUCCEEDED,
    AUTHENTICATION_PASSWORD_FAILED,
);

export const authTokenUpdate = createActions<void, IAuthSuccess, any,
    typeof AUTHENTICATION_TOKEN_UPDATE, typeof AUTHENTICATION_TOKEN_UPDATE_SUCCESS,
    typeof AUTHENTICATION_TOKEN_UPDATE_ERROR
>(
    AUTHENTICATION_TOKEN_UPDATE, AUTHENTICATION_TOKEN_UPDATE_SUCCESS, AUTHENTICATION_TOKEN_UPDATE_ERROR,
);

export const LOGOUT = 'LOGOUT';
export const logout = createSingleAction<void, typeof LOGOUT>(LOGOUT);

const CACHE_AUTH_EMAIL = 'auth/CACHE_EMAIL';
export const cacheAuthEmail = createSingleAction<string, typeof CACHE_AUTH_EMAIL>(CACHE_AUTH_EMAIL);

export const SET_CLIENT_USER_PERMISSIONS = 'SET_CLIENT_USER_PERMISSIONS';
export const setClientUserPermission = createSingleAction<Permission[],
    typeof SET_CLIENT_USER_PERMISSIONS>(SET_CLIENT_USER_PERMISSIONS);

const LOGIN_AS_USER = 'auth/LOGIN_AS_USER';
const LOGIN_AS_USER_SUCCESS = 'auth/LOGIN_AS_USER_SUCCESS';
const LOGIN_AS_USER_ERROR = 'auth/LOGIN_AS_USER_ERROR';
export const loginAsUser = createActions<
string,
void,
any,
typeof LOGIN_AS_USER,
typeof LOGIN_AS_USER_SUCCESS,
typeof LOGIN_AS_USER_ERROR
>(
    LOGIN_AS_USER,
    LOGIN_AS_USER_SUCCESS,
    LOGIN_AS_USER_ERROR,
);

export type AuthAction = ActionsReturnTypes<typeof authByToken>
| ActionsReturnTypes<typeof authByPassword>
| ActionsReturnTypes<typeof authTokenUpdate>
| ActionsReturnTypes<typeof loginAsUser>
| ReturnType<typeof setClientUserPermission>
| ReturnType<typeof logout>;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';

import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';

import ControllingOrgFormWrapper
    from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/ControllingOrgFormWrapper';
import { IControllingOrgFormValues } from 'modules/settings/submodules/clients/controllingOrg/components/ControllingOrgForm/model';
import {
    selectEditControllingOrgId,
    selectIsControllingOrgUpdating,
} from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/selectors';
import {
    setEditControllingOrgId,
    updateControllingOrg,
} from 'modules/settings/submodules/clients/controllingOrg/components/EditControllingOrg/store/actions';
import { selectControllingOrgById } from 'store/entities/configuration/configurationSelectors';
import { IUpdateControllingOrgRequest } from 'shared/models/ControllingOrg';

export default function EditControllingOrgModal() {
    const modalClasses = useSettingsFormModalStyles();
    const editControllingOrgId = useSelector(selectEditControllingOrgId);
    const isLoading = useSelector(selectIsControllingOrgUpdating);
    const dispatch = useDispatch();
    const editControllingOrg = useSelector(selectControllingOrgById(editControllingOrgId));
    const onSave = useCallback((formValues: IControllingOrgFormValues) => {
        const { zip_code, county, ...valuesCleared } = formValues;
        const data = {
            ...valuesCleared,
            zip: zip_code,
        } as IUpdateControllingOrgRequest;
        dispatch(updateControllingOrg.init({
            id: editControllingOrgId,
            data,
        }));
    }, [dispatch, editControllingOrgId]);
    const onClose = useCallback(() => {
        dispatch(setEditControllingOrgId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Controlling Org"
            isOpened={Boolean(editControllingOrgId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <ControllingOrgFormWrapper
                    submitTitle="Save"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    controllingOrg={editControllingOrg}
                />
            </Box>
        </ModalDialog>
    );
}

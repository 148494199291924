import OfferLetterFormWrapper from 'modules/offerLetter/components/OfferLetterForm/OfferLetterFormWrapper';
import { selectOfferUser } from 'modules/offerLetter/components/CreateOffer/store/selectors';
import { useSidebarStyles } from 'modules/offerLetter/components/lookup/useSidebarStyles';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidebarCommon from 'shared/components/sidebars/Sidebar/SidebarCommon';
import { getUserName, userFullName } from 'shared/utils/converters/user';
import { createOffer } from '../../store/actions';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    wrapper: {
        width: theme.spacing(70),
    },
}));

export interface ICreateOfferMobileProps {
    open: boolean;
    onBack: () => void;
    onClose?: () => void;
}

interface ICreateOfferSidebarContext {
    step: number;
    setStep: (value: number) => void;
}
export const CreateOfferSidebarContext = React.createContext<ICreateOfferSidebarContext>({
    step: 0,
    setStep: value => value,
});

const CreateOfferSidebar = ({ open, onClose, onBack }: ICreateOfferMobileProps) => {
    const sidebarClasses = useSidebarStyles();
    const customClasses = useStyles();
    const selectedUser = useSelector(selectOfferUser);
    const title = selectedUser ? `Create offer - ${userFullName(selectedUser)}` : 'Invite & Create offer';
    const dispatch = useDispatch();
    const onSubmit = useCallback(
        offerData => dispatch(createOffer.init(offerData)),
        [dispatch],
    );

    const [step, setStep] = useState(0);

    const onStepBack = useCallback(() => {
        if (step > 0) {
            setStep(step - 1);
        } else {
            onBack();
        }
    }, [onBack, step, setStep]);

    return (
        <CreateOfferSidebarContext.Provider value={{ step, setStep }}>
            <SidebarCommon
                open={open}
                title={title}
                onBack={onStepBack}
                onClose={onClose}
                titleClasses={{
                    root: sidebarClasses.sidebarTitle,
                }}
                customClasses={customClasses.wrapper}
            >
                <OfferLetterFormWrapper
                    onCancel={() => onBack()}
                    onSubmit={onSubmit}
                    selectedUser={selectedUser}
                    confirmMessage={offerLetter => `Are you sure you want to send ${getUserName(offerLetter)} an offer letter?`}
                />
            </SidebarCommon>
        </CreateOfferSidebarContext.Provider>
    );
};

export default CreateOfferSidebar;

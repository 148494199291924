import React, { FC, useCallback } from 'react';
import { IEntryData } from 'shared/models/sheet/Sheet';
import { updateTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import { Formik, FormikValues } from 'formik';
import { ObjectSchema } from 'yup';
import { useDispatch } from 'react-redux';
import { TimeInOutQuickEditForm } from 'shared/components/table/EntriesTable/cellsComponents/quickEdit/TimeInOutQuickEditForm';

export interface IQuickEditEntryComponentProps {
    name: string;
    className?: string;
    value: FormikValues;
    disabled?: boolean;
    submitForm: () => void;
}

// TODO: type QuickEditAction = typeof updateTimeEntry.init | typeof updateExpenseEntry.init;
type QuickEditAction = typeof updateTimeEntry.init;
type QuickEditActionSuccess = typeof updateTimeEntry.success;

export type QuickEditActionExtraData<Action extends QuickEditAction> = Omit<Parameters<Action>[0], 'id' | 'assignment_id' | 'project_id'| 'activity_id' | 'entry_date' | 'task_id' | 'notes' | 'position_id' | 'location_id' | 'department_id' | 'weekend' | 'data' | 'user_id' | 'status_id'>;
export type QuickEditActionEntryData<Action extends QuickEditAction> = Pick<Parameters<Action>[0], 'data'>['data'];
export type QuickEditActionEntry<Action extends QuickEditActionSuccess> = Parameters<Action>[0];

export interface IQuickEditEntryProps<Success extends QuickEditActionSuccess> {
    value: IEntryData;
    disabled?: boolean;
    userId?: string;
    entry: QuickEditActionEntry<Success>;
}

interface IQuickEditEntryParams<
    Action extends QuickEditAction,
    Entry extends IEntryData,
    Success extends QuickEditActionSuccess
> {
    action: Action;
    getInitialValues: (value: Entry) => FormikValues;
    schema: ObjectSchema;
    transformFormValues:
    (value: FormikValues, entry: QuickEditActionEntry<Success>) => QuickEditActionEntryData<Action>;
    extraData: (entry: QuickEditActionEntry<Success>) => QuickEditActionExtraData<Action>;
}

const withQuickEditEntry = <
    Action extends QuickEditAction,
    Success extends QuickEditActionSuccess,
    Entry extends IEntryData
>({
    action,
    getInitialValues,
    transformFormValues,
    schema,
    extraData,
}: IQuickEditEntryParams<Action, Entry, Success>) => {
    function QuickEditEntryComponent(EditComponent: FC<IQuickEditEntryComponentProps>) {
        function QuickEditEntry({
            value,
            entry,
            disabled,
            userId,
        }: IQuickEditEntryProps<Success>) {
            const dispatch = useDispatch();
            const onSubmit = useCallback(({ data }) => {
                dispatch(action({
                    data: {
                        ...transformFormValues(data, entry),
                    },
                    ...(userId ? { user_id: userId } : {}),
                    id: entry.id,
                    assignment_id: entry.assignment_id,
                    project_id: entry.project_id,
                    activity_id: entry.activity_id,
                    entry_date: entry.entry_date,
                    task_id: entry.task_id,
                    notes: entry.notes,
                    position_id: entry.position_id,
                    location_id: entry.location_id,
                    department_id: entry.department_id,
                    ...(extraData(entry)),
                }));
            }, [dispatch, entry, userId]);

            return (
                <Formik
                    enableReinitialize
                    initialValues={{ data: getInitialValues(value as Entry) }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                >
                    {({ submitForm, handleSubmit, validateForm, values }) => {

                        return (
                            <TimeInOutQuickEditForm
                                EditComponent={EditComponent}
                                handleSubmit={handleSubmit}
                                submitForm={submitForm}
                                validateForm={validateForm}
                                values={values}
                                getInitialValues={getInitialValues}
                                value={value as Entry}
                                disabled={disabled}
                            />
                        );
                    }}
                </Formik>
            );
        }
        return QuickEditEntry;
    }
    return QuickEditEntryComponent;
};

export default withQuickEditEntry;

import ManagerSheetDetailPage from 'modules/clients/content/timeApproval/ManagerSheetDetailPage';
import { useSheetApprovalTabs } from 'modules/clients/header/useSheetApprovalTabs';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import SheetApproval from 'shared/components/sheetApproval/SheetApproval';
import { EntryType } from 'shared/models/sheet/Sheet';
import { routes } from 'shared/routes';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getActivities } from 'store/entities/configuration/configurationAction';

export default function TimeApproval() {
    useTitleUpdate('Time Approval', 'Time Approval');
    useSheetApprovalTabs();

    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (clientId) {
            dispatch(getActivities.init());
        }
    }, [dispatch, clientId]);

    return (
        <>
            <SheetApproval sheetType={EntryType.TIME}/>
            <Route
                path={routes.CLIENT.TIME_APPROVAL.SHEET_DETAIL}
                component={() => <ManagerSheetDetailPage entryType={EntryType.TIME}/>}
            />
        </>
    );
}

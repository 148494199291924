import { IStore } from 'store/configureStore';
import { IInfinityScrollState } from 'store/reducerUtils';

export function getInfinityScrollSelectors<Item>(
    stateSelector: (state: IStore) => IInfinityScrollState<Item>,
    pageSize = 30,
) {
    const selectItems = (state: IStore): Item[] => {
        return stateSelector(state).items;
    };
    const selectTotalItems = (state: IStore) => {
        return stateSelector(state).total;
    };
    const isLoading = (state: IStore) => {
        return stateSelector(state).isLoading;
    };
    const selectHasMore = (state: IStore) => {
        const total = selectTotalItems(state);
        const items = selectItems(state);

        if (total === undefined) {
            return true;
        }

        return items.length < total;
    };
    const selectNextPage = (state: IStore) => {
        const items = selectItems(state);
        return items.length / pageSize + 1;
    };
    const selectPageSize = () => pageSize;
    const selectNextRange = (state: IStore) => {
        const nextPage = selectNextPage(state);
        return `[${(nextPage - 1) * pageSize},${nextPage * pageSize}]`;
    };

    return {
        selectItems,
        selectTotalItems,
        selectHasMore,
        selectNextPage,
        selectPageSize,
        selectNextRange,
        isLoading,
    };
}

import { IAttachment } from 'shared/models/Attachments';
import { IPaginationResponse } from 'shared/models/IPaginationResponse';
import { IPosition } from 'shared/models/Position';
import { IEntryData } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { IAssignment } from 'store/entities/configuration/configurationModel';
import { SheetStatusSlug, StatusNames } from 'store/entities/timesheet/models/Status';
import { IPayrollSheetRow } from '../components/PayrollSheetTable/model';

export enum PayrollSheetStatuses {
    SUBMITTED = 'submitted',
    APPROVED = 'approved', // all approved and not processed sheets
    MISSING = 'missing', // sheets for active employees that were not started
    OVERDUE = 'overdue', // sheets that were not submitted
    CLOSED = 'closed', // sheets that were processed and closed
}

export enum PayrollSheetTabs {
    APPROVED = 'Approved',
    SUBMITTED = 'Submitted',
    MISSING = 'Missing',
    OVERDUE = 'Overdue',
    CLOSED = 'Closed',
    ALL = 'All',
}

export enum TimePayType {
    RegularTime = 'regular',
    OverTime = 'ot',
    DoubleTime = 'dt',
}

export const getSheetStatusByTab = (tab: PayrollSheetTabs): PayrollSheetStatuses => {
    const match = {
        [PayrollSheetTabs.APPROVED]: PayrollSheetStatuses.APPROVED,
        [PayrollSheetTabs.SUBMITTED]: PayrollSheetStatuses.SUBMITTED,
        [PayrollSheetTabs.MISSING]: PayrollSheetStatuses.MISSING,
        [PayrollSheetTabs.OVERDUE]: PayrollSheetStatuses.OVERDUE,
        [PayrollSheetTabs.CLOSED]: PayrollSheetStatuses.CLOSED,
        [PayrollSheetTabs.ALL]: undefined,
    };
    return match[tab] as PayrollSheetStatuses;
};

export const payrollSheetTabsSorted: PayrollSheetTabs[] = [
    PayrollSheetTabs.APPROVED,
    PayrollSheetTabs.SUBMITTED,
    PayrollSheetTabs.MISSING,
    PayrollSheetTabs.OVERDUE,
    PayrollSheetTabs.CLOSED,
    PayrollSheetTabs.ALL,
];

export interface ICalculationEntry {
    sheet_entry_id: string;
    activity_id: string;
    location_id: string;
    position_id: string;
    entry_date: string;
    pay_code_id: string;
    client_pay_code_id: string;
    assignment_id?: string;
    project_id: string;
    task_id: string;
}

export interface ITimeCalculationEntry extends ICalculationEntry {
    hours: string;
    files: number;
    pay_rate: string;
    pay_type: TimePayType;
}

export interface IExpenseCalculationEntry extends ICalculationEntry {
    pay_units: number;
    pay_rate: number;
    data: IEntryData;
    total_dollars: number;
    sheet_entry_attachments: IAttachment[];
}

export interface ISheetGroupId {
    timeSheetId: string | null;
    expenseSheetId: string | null;
}

export interface ISheetGroupIdWithClient extends ISheetGroupId {
    clientId: string;
}

export interface IDownloadSheetProps extends ISheetGroupId{
    fileName: string;
}

export interface ISheetGroupIdRequest {
    time_sheet_id: string | null;
    expense_sheet_id: string | null;
}

export interface IGroupedSheetCalculation {
    pay_period_ends_at: string | null;
    pay_period_starts_at: string | null;
    assignment_id: string;
    client_id: string;
    payroll_status: PayrollSheetStatuses;
    job_number_id?: string;
    job_number?: string;
    deal_number?: string;
    user_id: string | null;
    position_id: string | null;
    time_approved_level: number | null;
    time_rt_dollars: string | null;
    time_ot_dollars: string | null;
    time_dt_dollars: string | null;
    time_rt_hours: string | null;
    time_ot_hours: string | null;
    time_dt_hours: string | null;
    time_holiday_hours: string | null;
    time_sheet_id: string | null;
    time_status_id: string | null;
    time_status_slug: SheetStatusSlug | null;
    time_prism_batch_number: string | null;
    time_entries: ITimeCalculationEntry[];
    time_latest_approver_user_id: string | null;
    time_files: number | null;
    time_files_dollars: string | null;
    time_updated_at: string | null;
    expense_approved_level: number | null;
    expense_total_miles: string | null;
    expense_total_dollars: string | null;
    expense_sheet_id: string | null;
    expense_status_id: string | null;
    expense_status_slug: SheetStatusSlug | null;
    expense_entries: IExpenseCalculationEntry[];
    expense_prism_batch_number: string | null;
    expense_latest_approver_user_id: string | null;
    expense_updated_at: string | null;
    total_dollars: string | null;
    manager_id: string | null;
}

export interface IGroupedSheetCalculationRequest {
    client_id?: string;
    user_id?: string;
    payroll_status?: string;
    pay_period_ends_at?: string;
    page_number?: number;
    page_size?: number;
    sort?: string[];
    time_sheet_ids?: string[];
    expense_sheet_ids?: string[];
    deal_number_id?: string;
    job_number?: number;
    latest_approver_user_id?: number;
}

export interface IGroupedSheetSummaryRequest {
    client_id?: string;
    user_id?: string;
    payroll_status?: string;
    pay_period_ends_at?: string;
}

export interface IGroupedSheetPayroll extends ISheetGroupIdRequest{
    client_id: string | null;
    pay_date: string;
}

export interface IGroupedSheetPayrollRequest {
    sheets_for_payroll: IGroupedSheetPayroll[];
}

export interface ISheetForPayroll extends ISheetGroupIdWithClient {
    payDate: string;
}

export interface IPayrollParams {
    sheets: ISheetForPayroll[];
}

export interface IPayrollProcessorFilters {
    status: PayrollSheetTabs;
    payPeriodEnd: string | null;
    clientId: string | null;
    employeeId: string | null;
    assignmentId: string | null;
    payrollProcessorUserId: string | null;
    dealId: string | null;
    jobNumber: string;
    managerId: string | null;
}

export interface ISearchGroupedCalculationsResponse {
    calculations: IPaginationResponse<IGroupedSheetCalculation>;
    linked: {
        users: IUserInfo[];
        positions: IPosition[];
        assignments: IAssignment[];
    };
}

export interface IPayrollSheetSummary {
    time_total_rt_dollars: string | null;
    time_total_ot_dollars: string | null;
    time_total_dt_dollars: string | null;
    time_total_rt_hours: string | null;
    time_total_ot_hours: string | null;
    time_total_dt_hours: string | null;
    expense_total_dollars: string | null;
    total_dollars: string | null;
}

export interface IChangeSheetGroupStatusPayload {
    status: StatusNames;
    groups: {
        groupId: ISheetGroupId;
        note?: string;
    }[];
}

interface IPrePayrollResponseBase {
    expense_total_dollars: string;
    expense_total_miles: string;
    number_of_batches: number;
    number_of_employees: number;
    number_of_entries: number;
    number_of_payroll_records: number;
    time_dt_dollars: string;
    time_dt_hours: string;
    time_ot_dollars: string;
    time_ot_hours: string;
    time_rt_dollars: string;
    time_rt_hours: string;
    time_files: string;
    time_files_dollars: string;
    total_dollars: string;
}

export interface IPrePayrollBase {
    expenseTotalDollars: string;
    expenseTotalMiles: string;
    numberOfBatches: string;
    numberOfEmployees: string;
    numberOfEntries: string;
    numberOfPayrollRecords: string;
    timeDtDollars: string;
    timeDtHours: string;
    timeOtDollars: string;
    timeOtHours: string;
    timeRtDollars: string;
    timeRtHours: string;
    timeFiles: string;
    timeFilesDollars: string;
    totalDollars: string;
}

export interface ISheetsForPrePayrollResponse {
    client_id: string;
    employee_user_id: string;
    expense_sheet_id: string;
    expense_total_dollars: string;
    expense_total_miles: string;
    pay_period: string;
    position_id: string;
    time_dt_dollars: string;
    time_dt_hours: string;
    time_ot_dollars: string;
    time_ot_hours: string;
    time_rt_dollars: string;
    time_rt_hours: string;
    time_files: string;
    time_files_dollars: string;
    time_sheet_id: string;
    total_dollars: string;
}

export interface ISheetsForPostPayrollResponse extends ISheetsForPrePayrollResponse {
    failed_reason: string;
}

export interface ISheetsForPrePayroll {
    clientId: string;
    employeeUserId: string;
    expenseSheetId: string;
    expenseTotalDollars: string;
    expenseTotalMiles: string;
    payPeriod: string;
    positionId: string;
    timeDtDollars: string;
    timeDtHours: string;
    timeOtDollars: string;
    timeOtHours: string;
    timeRtDollars: string;
    timeRtHours: string;
    timeFiles: string;
    timeFilesDollars: string;
    timeSheetId: string;
    totalDollars: string;
}

export interface ISheetsForPostPayroll extends ISheetsForPrePayroll {
    failedReason: string;
}

export interface IPrePayrollResponse extends IPrePayrollResponseBase {
    sheets_for_payroll: ISheetsForPrePayrollResponse[];
}

export interface IPrePayroll extends IPrePayrollBase {
    sheetsForPayroll: ISheetsForPrePayroll[];
}

export interface IPostPayrollResponse extends IPrePayrollResponse {
    number_of_batches_failed: number;
    number_of_payroll_records_failed: number;
    number_of_employees_failed: number;
    number_of_entries_failed: number;
    sheets_for_payroll: ISheetsForPostPayrollResponse[];
}

export interface IPostPayroll extends IPrePayroll {
    numberOfBatchesFailed: string;
    numberOfPayrollRecordsFailed: string;
    numberOfEmployeesFailed: string;
    numberOfEntriesFailed: string;
    sheetsForPayroll: ISheetsForPostPayroll[];
}

export interface IPayrollReportItem {
    label: string;
    values: string[];
}

export enum PrePayrollReportOverview {
    NumberOfBatchesToCreate = '# of batches to be created',
    NumberOfPayrollToCreate = '# of payroll records to be created',
    NumberOfEmployeeToProcess = '# of employee to process',
    NumberOfTimeAndExpenseToProcess = '# of T&E to process',
    TotalRegularTimeAndPay = 'Total regular time | pay',
    TotalOvertimeTimeAndPay = 'Total overtime time | pay',
    TotalExpenses = 'Total Expenses',
    TotalPay = 'Total Pay',
}

export enum PostPayrollReportOverview {
    NumberOfBatchesToCreate = '# of batches to be created | failed',
    NumberOfPayrollToCreate = '# of payroll records to be created | failed',
    NumberOfEmployeeToProcess = '# of employee to process | failed',
    NumberOfTimeAndExpenseToProcess = '# of T&E to process | failed',
}

export type IPayrollReportData = IPayrollSheetRow | IGroupedSheetCalculation;  // TODO: Needs to use one type!

export interface IPayrollPayPeriodRequest {
    client_id?: string;
    payroll_processor_user_id?: string;
}

export interface IPayrollPayPeriod {
    pay_period_end: string;
    payroll_statuses: PayrollSheetStatuses[];
}

import React from 'react';
import ActivityWithIcon from 'shared/components/table/EntriesTable/cellsComponents/ActivityWithIcon';
import PlainText from 'shared/components/table/Cells/PlainText';
import { getScaOrZip } from 'shared/utils/converters/entry';
import { EntriesGridCellsTitles, IEntryRow } from 'shared/components/table/EntriesTable/model';
import { renderTaskInfo } from 'store/entities/configuration/configurationModel';
import ReceiptCell from './cellsComponents/ReceiptCell';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import clsx from 'clsx';

export const desktopCells = {
    activityCell: {
        key: EntriesGridCellsTitles.Activity,
        title: EntriesGridCellsTitles.Activity,
        render: ActivityWithIcon,
    },
    assignmentCell: {
        key: EntriesGridCellsTitles.Assignment,
        title: EntriesGridCellsTitles.Assignment,
        render: function AssignmentCell({ projectAssignment, className }: IEntryRow){
            return (
                <PlainText className={className} value={projectAssignment?.description}/>
            );
        },
    },
    taskCell: {
        key: EntriesGridCellsTitles.Task,
        title: EntriesGridCellsTitles.Task,
        render: function TaskCell({ task, className }: IEntryRow){
            return (
                <PlainText className={className} value={task ? renderTaskInfo(task) : ''}/>
            );
        },
    },
    getScaZipCell: (scaZipTitle: string) => ({
        key: scaZipTitle,
        title: scaZipTitle,
        render: function TaskCell({ entry, className }: IEntryRow) {
            return (
                <PlainText className={className} value={getScaOrZip(entry)}/>
            );
        },
    }),
    receiptCell: {
        key: EntriesGridCellsTitles.Receipt,
        title: EntriesGridCellsTitles.Receipt,
        render: function AttachmentCell({ entry, className }: IEntryRow){
            const classes = useEntriesTableStyles();
            return (
                <div className = {clsx(className, classes.receiptCell)}>
                    {ReceiptCell(entry)}
                </div>
            );
        },
    },
    notesCell: {
        key: EntriesGridCellsTitles.Notes,
        title: EntriesGridCellsTitles.Notes,
        render: function NotesCell({ entry, className }: IEntryRow){
            return (
                <PlainText className={className} value={entry.notes ?? ''}/>
            );
        },
    },
};

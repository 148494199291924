import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';

export const getTotalHours = (hoursComponents: Array<string | null>): string => {
    const totalHours = hoursComponents.reduce((result: number, hours: string | null) => {
        if (hours) {
            return result + parseFloat(hours);
        }
        return result;
    }, 0);
    return formatDecimalHoursStringAsHoursAndMinutes(totalHours.toFixed(2).toString());
};

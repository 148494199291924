import {
    IGroupedSheetCalculation,
    IPayrollProcessorFilters,
    IPayrollSheetSummary,
    IPostPayroll,
    IPrePayroll,
    ISheetGroupId,
    PayrollSheetTabs,
} from 'modules/payrollProcessorHub/store/model';
import { createSelector } from 'reselect';
import { IPaginationState } from 'shared/models/IPaginationResponse';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import { totalTime } from 'shared/utils/counters/timeCounter';
import { IStore } from 'store/configureStore';
import { selectClientHasJobNumberConfiguration } from 'store/entities/clients/clientsSelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllEntries, selectExpensesSheetById, selectTimeSheetById } from 'store/entities/timesheet/selectors';

export const selectPayrollProcessorHubState = (state: IStore) => state.modules.payrollProcessorHub;
export const selectPayrollActiveTab = (state: IStore): PayrollSheetTabs =>
    selectPayrollProcessorHubState(state).filter.status || PayrollSheetTabs.ALL;
export const selectPayrollFilter = (state: IStore): IPayrollProcessorFilters =>
    selectPayrollProcessorHubState(state).filter;
export const selectGroupedSheetsPagination = (state: IStore): IPaginationState =>
    selectPayrollProcessorHubState(state).groupedSheets.pagination;
export const selectGroupedSheetsTotalCount = (state: IStore) =>
    selectGroupedSheetsPagination(state).total_items;
export const selectGroupedSheetsCalculations = (state: IStore): IGroupedSheetCalculation[] =>
    selectPayrollProcessorHubState(state).groupedSheets.groups;
export const selectIsGroupedSheetsCalculationsLoading = (state: IStore) =>
    selectPayrollProcessorHubState(state).groupedSheets.isLoading;
export const selectSheetsGroupById = (groupId: ISheetGroupId) =>
    (state: IStore) =>
        selectGroupedSheetsCalculations(state).find(
            group => group.time_sheet_id === groupId.timeSheetId && group.expense_sheet_id === groupId.expenseSheetId,
        );
export const selectSheetsGroupsByIds = (groupIds: ISheetGroupId[]) => (state: IStore): IGroupedSheetCalculation[] =>
    groupIds.map(id => selectSheetsGroupById(id)(state)).filter(item => Boolean(item)) as IGroupedSheetCalculation[];
export const selectGroupedSheetsSummary = (state: IStore): IPayrollSheetSummary | null =>
    selectPayrollProcessorHubState(state).sheetSummary.summary;
export const selectIsLoadingSheetsSummary = (state: IStore) =>
    selectPayrollProcessorHubState(state).sheetSummary.isLoading;
export const selectIsLoadingSheetEditInfo = (state: IStore) =>
    selectPayrollProcessorHubState(state).sheetEditInfo.isLoading;
export const selectPayrollPayPeriods = (state: IStore) =>
    selectPayrollProcessorHubState(state).payPeriods;

/**
 * Selector for getting processing state of payroll request
 * @param state
 * @return {boolean}
 */
export const selectIsPayRollProcessing = (state: IStore): boolean =>
    selectPayrollProcessorHubState(state).payRoll.isProcessing || false;

/**
 * Get availability of next payroll list pagination page
 * @param state
 * @return {boolean}
 */
export const selectPayrollProcessorSheetsHasNextPage = (state: IStore) => {
    const pagination = selectGroupedSheetsPagination(state);
    if (pagination.page_size) {
        return (pagination.total_items / pagination.page_size) > pagination.page;
    }
    return false;
};

/**
 * Get loading state for pre-initialize report
 * @param state
 * @return {boolean}
 */
export const selectIsPreInitializeReportLoading = (state: IStore) =>
    selectPayrollProcessorHubState(state).payRoll.isPreInitializeReportLoading || false;

/**
 * Get pre-initialize report
 * @param state
 */
export const selectPrePayrollReport = (state: IStore): IPrePayroll =>
    selectPayrollProcessorHubState(state).payRoll.prePayrollReport;

/**
 * Get post-processing report
 * @param state
 */
export const selectPostPayrollReport = (state: IStore): IPostPayroll =>
    selectPayrollProcessorHubState(state).payRoll.postPayrollReport;

/**
 * Get an attribute meaning that the missing sheet has changes and will be moved to overdue status in PPH
 *
 * Select all group related entries and count total time & expenses for missing sheets.
 * @param groupId {
 *     timeSheetId,
 *     expenseSheetId
 * }
 * @return (state) => boolean
 */
export const selectIsEditedMissingSheetsHasChanges = (groupId: ISheetGroupId) => (state: IStore): boolean => {
    const timeSheet = selectTimeSheetById(groupId.timeSheetId || '')(state);
    const expenseSheet = selectExpensesSheetById(groupId.expenseSheetId || '')(state);
    const sheetGroupEntries = selectAllEntries(state).filter(
        entry => entry.data?.entry_type !== QuantityType.TIME_BREAK
            && ((entry.entry_type === EntryType.TIME && entry.sheet_id === timeSheet?.id)
            || (entry.entry_type === EntryType.EXPENSE && entry.sheet_id === expenseSheet?.id)),
    );
    const hasWorkedTime = totalTime(sheetGroupEntries) > 0;
    const hasExpenses = parseFloat(totalDollars(sheetGroupEntries)) > 0;
    return (timeSheet?.status?.name === StatusNames.WORKING && hasWorkedTime)
        || (expenseSheet?.status?.name === StatusNames.WORKING && hasExpenses);
};

/**
 * Check that any of calculations in table has job number
 */
export const selectCalculationsHasJobNumbers = createSelector(
    selectGroupedSheetsCalculations,
    groups => {
        return groups.some(group => group.job_number_id);
    },
);

/**
 * Check that any of calculations in table has files
 */
export const selectCalculationsHasFiles = createSelector(
    selectGroupedSheetsCalculations,
    groups => {
        return groups.some(group => group.time_files);
    },
);

/**
 * Check that selected in filter client has job number configuration
 * @param state
 */
export const selectPPHFilterClientHasJobNumberConfiguration = (state: IStore): boolean => {
    const { clientId } = selectPayrollFilter(state);
    if (clientId) {
        return selectClientHasJobNumberConfiguration(clientId)(state) || false;
    }
    return false;
};

import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { routes } from 'shared/routes';
import { Box, Button } from '@material-ui/core';
import { IOfferLetter, OfferLetterStatusSlug } from '../../store/model';
import { useOfferLetterStyles } from '../../OfferLetterStyles';
import { FeatureSwitches } from 'utils/featureSwitches';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import useActionButtonStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import clsx from 'clsx';

export interface IOfferLetterActionsProp {
    offerLetter: IOfferLetter;
    clientId: string | null;
}

const OfferLetterActions = ({ offerLetter, clientId }: IOfferLetterActionsProp) => {
    const classes = useOfferLetterStyles();
    const actionButtonClasses = useActionButtonStyles();

    return (
        <Box className={classes.buttonWrapper}>
            {offerLetter.status.slug === OfferLetterStatusSlug.Outstanding && clientId && (
                <>
                    <FeatureSwitch feature={FeatureSwitches.enableOfferLetterRescission}>
                        <Link
                            to={generatePath(routes.CLIENT.OFFER_LETTER.RESCIND, {
                                id: offerLetter.id,
                                client_id: clientId,
                            })}
                            className={actionButtonClasses.buttonTextStyle}
                        >
                            <Button
                                color="primary"
                                variant="outlined"
                                classes={{
                                    root: clsx(actionButtonClasses.actionButton, actionButtonClasses.secondaryButton),
                                    label: actionButtonClasses.buttonTextStyle,
                                }}
                            >
                                Rescind
                            </Button>
                        </Link>
                    </FeatureSwitch>
                    <FeatureSwitch feature={FeatureSwitches.enableOfferLetterEdit}>
                        <Link
                            to={generatePath(routes.CLIENT.OFFER_LETTER.EDIT, {
                                id: offerLetter.id,
                                client_id: clientId,
                            })}
                            className={actionButtonClasses.buttonTextStyle}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                classes={{
                                    root: actionButtonClasses.buttonApprove,
                                    label: actionButtonClasses.buttonTextStyle,
                                }}
                            >
                                Edit & Resend
                            </Button>
                        </Link>
                    </FeatureSwitch>
                </>
            )}
        </Box>
    );
};

export default OfferLetterActions;

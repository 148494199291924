import React from 'react';
import { useFilteredActivities } from 'shared/components/forms/entries/helpers/commonEntryFormHelpers';

import FormSelect, { SelectOption } from 'shared/components/selects/FormSelect';
import { IJobNumber } from 'shared/models/JobNumber';
import { EntryType } from 'shared/models/sheet/Sheet';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { ActivityTag, IActivity } from 'store/entities/configuration/configurationModel';

import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';

interface IActivitySelectProps extends IFormFieldProps, IFormSelect {
    entryType?: EntryType;
    taskId?: string;
    jobNumber?: IJobNumber | null;
    withAssignment?: boolean;
}

interface IActivitySelectStoreProps {
    options: SelectOption<IActivity>[];
}

export function ActivitySelectPure({ options, ...props }: IActivitySelectProps & IActivitySelectStoreProps) {
    return (
        <FormSelect
            {...props}
            getKey={(activity: IActivity) => activity.id}
            getText={(activity: IActivity) => activity.description}
            options={options}
        />
    );
}

export const ActivitySelect = separateLogicDecorator<
IActivitySelectProps,
IActivitySelectStoreProps
>(({
    taskId,
    entryType,
    withAssignment,
    jobNumber,
}) => {
    const filteredActivities = useFilteredActivities(taskId, entryType, withAssignment, jobNumber || null);
    const options: SelectOption<IActivity>[] = [];
    const nonProduction: IActivity[] = [];
    filteredActivities.forEach((activity: IActivity) => {
        if (entryType === EntryType.TIME && activity.tags?.includes(ActivityTag.NonProduction)) {
            nonProduction.push(activity);
        } else {
            options.push(activity);
        }
    });
    if (nonProduction.length > 0) {
        options.push({
            isOptionGroup: true,
            label: 'Non production',
            options: nonProduction,
        });
    }
    return { options };
})(ActivitySelectPure);

import React from 'react';
import { Typography } from '@material-ui/core';
import { PayRateType } from 'shared/models/Position';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';

export interface IShowPayRateType {
    type: PayRateType;
}

export const useStyles = makeHighPriorityStyles(() => ({
    type: {
        textTransform: 'lowercase',
    },
}));

const ShowPayRateType = ({ type }: IShowPayRateType) => {
    const classes = useStyles();
    return (
        <Typography variant="body2" className={classes.type}>{type}</Typography>
    );
};

export default ShowPayRateType;

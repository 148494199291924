import { combineReducers } from 'redux';
import { ItemsById } from 'shared/models/ItemsById';
import {
    IStatus,
    EntryType,
    IExpenseEntry,
    IExpenseSheet,
    IExpenseSheetBackend,
    ISheetCommonBackend,
} from 'shared/models/sheet/Sheet';
import { isLoadingReducer, itemsByIds } from 'store/reducerUtils';
import {
    addExpenseEntry,
    ExpenseSheetActions,
    getExpenseStatuses,
    loadExpenseSheets,
    loadExpenseSheetsWithEntries,
    removeExpenseEntry,
    updateExpenseEntry,
    updateExpenseSheets,
} from 'store/entities/timesheet/actions/expenseActions';
import { AttachmentActions, UPDATE_ENTRY_ATTACHMENTS } from '../actions/entryAttachments';

const defaultState = {
    sheetsById: {},
    entriesById: {},
    isSheetsLoading: false,
    statuses: [],
};

function transformSheet(item: ISheetCommonBackend): IExpenseSheet {
    return {
        ...item,
        entry_type: EntryType.EXPENSE,
    };
}

export function getExpenseEntriesFromSheet(sheet: IExpenseSheetBackend): ItemsById<IExpenseEntry> {
    return sheet.entries?.reduce((entriesWithSheetTypeById: ItemsById<IExpenseEntry>, sheetEntry) => {
        return {
            ...entriesWithSheetTypeById,
            [sheetEntry.id]: {
                ...sheetEntry,
                entry_type: EntryType.EXPENSE,
            },
        };
    }, {}) || {};
}

export const isSheetsLoading = isLoadingReducer(loadExpenseSheetsWithEntries);

export function sheetsById(
    state: ItemsById<IExpenseSheet> = defaultState.sheetsById,
    action: ExpenseSheetActions,
): ItemsById<IExpenseSheet> {
    switch (action.type) {
        case loadExpenseSheets.successType:
        case updateExpenseSheets.successType: {
            return {
                ...state,
                ...itemsByIds(
                    action.payload,
                    transformSheet,
                ),
            };
        }
        case loadExpenseSheetsWithEntries.successType: {
            // We want to save every property but entries info this reducer
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const updatedSheets = action.payload.reduce((acc, { entries, ...sheet }) => ({
                ...acc,
                [sheet.id]: transformSheet(sheet),
            }), {});
            return {
                ...state,
                ...updatedSheets,
            };
        }
        default:
            return state;
    }
}

export function entriesById(
    state: ItemsById<IExpenseEntry> = defaultState.entriesById,
    action: ExpenseSheetActions | AttachmentActions,
): ItemsById<IExpenseEntry> {
    switch (action.type) {
        case loadExpenseSheetsWithEntries.successType: {
            const entries = action.payload.reduce((acc, sheet) => ({
                ...acc,
                ...getExpenseEntriesFromSheet(sheet),
            }), {});

            return {
                ...state,
                ...entries,
            };
        }
        case addExpenseEntry.successType:
        case updateExpenseEntry.successType:
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload,
                    entry_type: EntryType.EXPENSE,
                },
            };
        case UPDATE_ENTRY_ATTACHMENTS:
        {
            const attachmentFilter = state[action.payload.entryId].sheet_entry_attachments
                .filter(file => file.id !== action.payload.attachmentId);
            return {
                ...state,
                [action.payload.entryId]: {
                    ...state[action.payload.entryId],
                    sheet_entry_attachments: attachmentFilter,
                },
            };
        }
        case removeExpenseEntry.successType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload]: removedEntry, ...newState } = state;
            return newState;
        }
        default:
            return state;
    }
}

export function statuses(state: Array<IStatus> = defaultState.statuses, action: ExpenseSheetActions): Array<IStatus> {
    switch (action.type) {
        case getExpenseStatuses.successType:
            return action.payload;
        default:
            return state;
    }
}

export const expenses = combineReducers({
    sheetsById,
    entriesById,
    isSheetsLoading,
    statuses,
});

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import {
    defaultOfferLetterValues,
    IOfferLetterFormValues,
} from 'modules/offerLetter/components/OfferLetterForm/model';
import React, { useEffect } from 'react';
import { SpecialUserAutocomplete } from 'shared/components/autocomplete/SpecialUserAutocomplete';

import DayPickerField from 'shared/components/formFields/DayPickerField';
import FormField from 'shared/components/formFields/FormField';
import PhoneField from 'shared/components/formSpecialFields/phoneField/PhoneField';
import { Location } from 'shared/components/selects/Location';
import { Position } from 'shared/components/selects/Position';
import { monthTextDateFormat } from 'shared/models/Dates';
import { IEmployeeUserInfo, IUserInfo } from 'shared/models/User';
import { formatPhoneNumber } from 'shared/utils/formatters/phoneNumberFormatter';
import { Permission } from 'store/components/auth/authModels';
import { formBasicSpacing, useStyles } from './styles';
import { OfferLetterPayRateField } from './components/OfferLetterPayRateField';

export interface IOfferLetterUser extends IEmployeeUserInfo, Omit<Partial<IUserInfo>, 'first_name' | 'last_name' | 'cell_phone' | 'email' | 'job_title'> {

}

export interface IOfferLetterFormProps extends FormikProps<IOfferLetterFormValues> {
    isLoading?: boolean;
    onCancel: () => void;
    selectedUser?: IOfferLetterUser;
}

/**
 * @deprecated Use multistep form form instead by design
 */
export function OfferLetterForm({
    handleSubmit,
    onCancel,
    isLoading = false,
    values,
    setFieldValue,
    selectedUser,
}: IOfferLetterFormProps) {
    const classes = useStyles();

    const { position, locationId } = values;

    useEffect(() => {
        if (position) {
            setFieldValue('payRate', position.pay_rate_value || defaultOfferLetterValues.payRate);
            setFieldValue('payRateType', position.pay_rate_type || defaultOfferLetterValues.payRateType);
        }
        if (locationId) {
            setFieldValue('locationId', locationId || defaultOfferLetterValues.locationId);
        }
    }, [position, locationId, setFieldValue]);

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            {selectedUser ? (
                <Box m={formBasicSpacing} mb={1}>
                    <Typography variant="body2"><b>Email:</b> {selectedUser.email}</Typography>
                    {selectedUser.cell_phone && (
                        <Typography variant="body2"><b>Cell Number:</b> {formatPhoneNumber(selectedUser.cell_phone)}</Typography>
                    )}
                    {selectedUser.identity_id && (
                        <Typography variant="body2"><b>ID:</b> {selectedUser.identity_id}</Typography>
                    )}
                    {selectedUser.state && (
                        <Typography variant="body2"><b>State:</b> {selectedUser.state}</Typography>
                    )}
                    {selectedUser.zip_code && (
                        <Typography variant="body2"><b>Zip:</b> {selectedUser.zip_code}</Typography>
                    )}
                </Box>
            ) : (
                <>
                    <Box
                        m={formBasicSpacing}
                        mt={5}
                        mb={0}
                    >
                        <Typography variant="subtitle2">
                            1. Invite Employee
                        </Typography>
                    </Box>

                    <FormField
                        name="first_name"
                        label="First Name"
                        className={classes.input}
                    />

                    <FormField
                        name="last_name"
                        label="Last Name"
                        className={classes.input}
                    />

                    <FormField
                        name="email"
                        type="email"
                        label="Email Address"
                        className={classes.input}
                    />

                    <PhoneField
                        name="phone"
                        label="Cell Phone Number"
                        className={classes.input}
                    />

                    <Box
                        m={formBasicSpacing}
                        mt={5}
                        mb={0}
                    >
                        <Typography variant="subtitle2">
                            2. Create Offer
                        </Typography>
                    </Box>
                </>
            )}

            <Position
                name="position"
                label="Position"
                className={classes.input}
            />

            <Location
                name="locationId"
                label="Work Location"
                className={classes.input}
                disabled={!position}
                positionId={position?.id}
                title={!position ? 'Select Position first' : undefined}
                useIdValue
            />

            <DayPickerField
                name="startDate"
                label="Start Date"
                className={classes.input}
                format={monthTextDateFormat}
                disableToolbar
            />

            <OfferLetterPayRateField values={values}/>

            <Box m={formBasicSpacing}>
                <Typography variant="body2">
                    Assign to
                </Typography>
            </Box>

            <SpecialUserAutocomplete
                feature="purpose"
                featureValue={Permission.ApproveSheets}
                name="approver"
                label="Approver"
                className={classes.input}
            />

            <Box m={formBasicSpacing} mt={5}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={clsx(classes.button, classes.green)}
                    disabled={isLoading}
                    data-testid="offer_submit_button"
                >
                    Submit offer
                    {isLoading && (
                        <Box ml={1} mb={-0.5}>
                            <CircularProgress color="inherit" size={16}/>
                        </Box>
                    )}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={onCancel}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
            </Box>
        </form>
    );
}

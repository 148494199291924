import { Box } from '@material-ui/core';
import { useHomeStyles } from 'modules/home/HomeStyles';
import { settingsRoutes } from 'modules/settings/routes';
import { ClientsSettingsRoutes } from 'modules/settings/submodules/clients/ClientsSettingsRoutes';
import { SettingsAppBar } from 'modules/settings/submodules/components/SettingsAppBar/SettingsAppBar';
import { CustomFieldsRoutes } from 'modules/settings/submodules/customFields/CustomFieldsRoutes';
import { EmployeeRoutes } from 'modules/settings/submodules/employees/EmployeeRoutes';
import { PlatformUsersRoutes } from 'modules/settings/submodules/platformUsers/PlatformUsersRoutes';
import { SettingsDashboard } from 'modules/settings/submodules/SettingsDashboard';
import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { Permission } from 'store/components/auth/authModels';
import OfferLetterTemplatesRoutes from './submodules/offerLetters/OfferLetterTemplatesRoutes';
import { FeatureSwitches } from 'utils/featureSwitches';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { Maintaince } from '../../shared/components/alerts/Maintaince';

export const SettingsRoutes = () => {
    const classes = useHomeStyles();

    return (
        <FeatureSwitch feature={FeatureSwitches.enableSettingsModule} noPlaceholder>
            <Box
                display="flex"
                flexDirection="column"
                minHeight="100vh"
            >
                <Maintaince/>
                <SettingsAppBar/>
                <Box display="flex" className={classes.main}>
                    <Switch>
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.ROOT}
                            component={ClientsSettingsRoutes}
                            permission={Permission.modifyClientSettings}
                        />
                        <PrivateRoute
                            path={settingsRoutes.PLATFORM_USERS.ROOT}
                            component={PlatformUsersRoutes}
                            permission={Permission.ManageManagerUsers}
                        />
                        <PrivateRoute
                            path={settingsRoutes.OFFER_LETTERS.TEMPLATES}
                            component={OfferLetterTemplatesRoutes}
                            permission={Permission.ManageOfferLetterTemplates}
                        />
                        <PrivateRoute
                            path={settingsRoutes.EMPLOYEES.ROOT}
                            component={EmployeeRoutes}
                            permission={Permission.ManageEmployeeUsers}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CUSTOM_FIELDS.ROOT}
                            component={CustomFieldsRoutes}
                            // TODO: Check permission
                        />
                        <PrivateRoute
                            path={settingsRoutes.ROOT}
                            component={SettingsDashboard}
                        />
                    </Switch>
                </Box>
            </Box>
        </FeatureSwitch>
    );
};

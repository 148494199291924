import { IUserInfoClient } from 'shared/models/User';

export enum Permission {
    SubmitSheets = 'submit_sheets',
    ApproveSheets = 'approve_sheets',
    CreateOffer = 'create_offer',
    ViewClientOffers = 'view_client_offers',
    ViewMyOffers = 'view_my_offers',
    toBeAssignedAsClientManager = 'to_be_assigned_as_client_manager',
    payrollProcessing = 'payroll_processing',
    createClient = 'create_client',
    modifyClientSettings = 'modify_client_settings',
    ManageEverything = 'manage_everything',
    ManageOfferLetterTemplates = 'manage_offer_letter_templates',
    ManageManagerUsers = 'manage_manager_users',
    ManageEmployeeUsers = 'manage_employee_users',
    ManagePaySettings = 'manage_pay_settings',
    Impersonate = 'impersonate',
    PPHReadAccess = 'pph_read_access',
}

export interface IUserTokenInfo {
    sub: string;
    given_name: string;
    family_name: string;
    email: string;
    clients: Array<IUserInfoClient>;
    global_permissions?: Array<Permission>;
}

export interface IUserSelfInfo extends Pick<IUserTokenInfo, 'email' | 'clients'> {
    id: string;
    firstName: string;
    lastName: string;
}

export interface IPasswordAuthRequest {
    username: string;
    password: string;
}

export interface IAuthSuccess {
    user: IUserTokenInfo;
}

export interface IRegistrationUserToken {
    first_name?: string;
    last_name?: string;
    email?: string;
}

export interface IClientStatuses{
    id: string;
    name: string;
    prism_status_code: string;
}

export interface IImpersonateResponse {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    not_before_policy: number;
    session_state: number;
    scope: string;
}

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { Formik, FormikProps } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { ActivityForm } from 'modules/settings/submodules/clients/activities/components/CreateActivity/ActivityForm';
import { defaultActivityValues, IActivityFormValues } from 'modules/settings/submodules/clients/activities/components/CreateActivity/model';
import { getActivityValidationsSchema } from 'modules/settings/submodules/clients/activities/components/CreateActivity/ValidationsSchema';
import { selectIsActivitiesCreating } from 'modules/settings/submodules/clients/activities/store/selectors';
import { createActivity } from 'modules/settings/submodules/clients/store/actions';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { selectCurrentClientHasJobNumberConfiguration, selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { ActivityTag } from 'store/entities/configuration/configurationModel';
import { selectTasks } from 'store/entities/configuration/configurationSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { pick } from 'lodash';

const ACTIVITY_NAME_LENGTH = 15;

const CreateActivity = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const isLoading = useSelector(selectIsActivitiesCreating);
    const hasJobNumber = useSelector(selectCurrentClientHasJobNumberConfiguration);
    const tasks = useSelector(selectTasks);
    const [isShortNameDisplayed] = useFeature(FeatureSwitches.displayActivityShortDescription);
    const onSubmitForm = (
        values: IActivityFormValues,
        { resetForm }: FormikHelpers<IActivityFormValues>,
    ) => {
        const tags = Object.values(
            pick(values, ['showZipCode', 'nonProduction', 'default']),
        ).filter(tag => tag) as ActivityTag[];
        dispatch(createActivity.init({
            client_id: clientId || '',
            sheet_type: values.type || EntryType.TIME,
            data_type: values.dataType || QuantityType.TIME,
            description: values.activity,
            short_description: values.shortDescription || undefined,
            pay_code_id: values.payCode?.id || null,
            task_id: values.task?.id,
            show_zip_code: Boolean(values.showZipCode) || false,
            tags,
        }));
        resetForm();
    };

    const validationScheme = useMemo(() => (
        getActivityValidationsSchema(ACTIVITY_NAME_LENGTH, !isShortNameDisplayed)
    ), [isShortNameDisplayed]);

    return (
        <Formik
            initialValues={{
                ...defaultActivityValues,
                task: tasks.length === 1 ? tasks[0] : defaultActivityValues.task,
            }}
            validationSchema={validationScheme}
            onSubmit={onSubmitForm}
            validateOnBlur={false}
            enableReinitialize
        >
            {(props: FormikProps<IActivityFormValues>) => (
                <ActivityForm
                    {...props}
                    isLoading={isLoading}
                    activityLength={ACTIVITY_NAME_LENGTH}
                    showNonProductionTag={hasJobNumber}
                />
            )}
        </Formik>
    );
};

export default CreateActivity;

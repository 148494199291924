import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';
import { IOfferLetterTemplate } from './models';
import { Status } from 'shared/models/Status';

const offerLetterTemplateState = (state: IStore) => state.modules.offerLetter.template;
export const selectOfferLetterTemplatesById = (state: IStore): Record<string, IOfferLetterTemplate> => (
    offerLetterTemplateState(state).offerLetterTemplatesById
);
export const selectOfferLetterTemplatesSortedByName = (
    createSelectorSortedByNameFromById<IOfferLetterTemplate>(selectOfferLetterTemplatesById)
);
export const selectActiveOfferLetterTemplatesSortedByName = (
    createSelector<IStore, IOfferLetterTemplate[], IOfferLetterTemplate[]>(
        selectOfferLetterTemplatesSortedByName,
        offerLetterTemplates => offerLetterTemplates.filter(
            offerLetterTemplate => offerLetterTemplate.status === Status.active,
        ),
    )
);
export const selectOfferLetterTemplates = createSelector(
    selectOfferLetterTemplatesById,
    offerLetterTemplateById => Object.values(offerLetterTemplateById),
);
export const selectOfferLetterTemplatesLoading = (state: IStore) => (
    offerLetterTemplateState(state).getOfferLetterTemplatesLoading
);

export const selectGetOfferLettersLoading = (state: IStore) => {
    return state.modules.offerLetter.getOfferLettersLoading;
};

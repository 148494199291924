import React, { useMemo } from 'react';
import { Box, Hidden } from '@material-ui/core';
import { MobilePayrollFooter } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/MobilePayrollFooter';
import { PayrollSheetBatchActions } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/PayrollSheetBatchActions';
import { useCheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { PayrollFilter } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilter';
import { PayrollSheetTable } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTable';
import { SheetTabTitle } from 'modules/payrollProcessorHub/components/SheetTitle/SheetTabTitle';
import SheetSummary from 'modules/payrollProcessorHub/components/SheetSummary/SheetSummary';
import { usePayrollSheetTableCells } from 'modules/payrollProcessorHub/components/PayrollSheetTable/Cells';
import { useActivePayrollSheetStatus, useFilteredPayrollSheetRows } from 'modules/payrollProcessorHub/store/helpers';

export const PayrollSheetsTablePage = () => {
    const activeStatus = useActivePayrollSheetStatus();

    const filteredSheets = useFilteredPayrollSheetRows();
    const checkedItemsActions = useCheckedItems<IPayrollSheetRow>(filteredSheets);
    const checkedRowIds = useMemo(() => {
        return Object
            .entries(checkedItemsActions.checkedItems)
            .filter(([_, isChecked]) => isChecked)
            .map(([id, _]) => id);
    }, [checkedItemsActions]);
    const checkedSheets = useMemo(() => {
        return filteredSheets.filter(sheet => checkedRowIds.includes(sheet.id));
    }, [filteredSheets, checkedRowIds]);
    const cells = usePayrollSheetTableCells(
        activeStatus,
        checkedItemsActions,
    );
    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                minHeight="42px"
            >
                <SheetTabTitle/>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                >
                    <PayrollSheetBatchActions
                        status={activeStatus}
                        checkedRowIds={checkedRowIds}
                        sheets={checkedSheets}
                    />
                    <Hidden xsDown>
                        <Box ml={2}>
                            <SheetSummary/>
                        </Box>
                    </Hidden>
                </Box>
            </Box>
            <Hidden xsDown>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <PayrollFilter/>
                </Box>
            </Hidden>
            <PayrollSheetTable
                cells={cells}
            />
            <MobilePayrollFooter/>
        </>
    );
};

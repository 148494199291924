import { selectTimeSheetStatusByName } from 'store/entities/timesheet/selectors';
import { put, select, take, takeLatest } from 'typed-redux-saga';
import { getTimeReviewerSheets } from 'store/components/timeApproval/timeApprovalActions';
import { Permission } from 'store/components/auth/authModels';
import { loadTimeSheets } from 'store/entities/timesheet/actions/timeActions';

function* getTimeReviewerSheetsSaga({ payload: statusName }: ReturnType<typeof getTimeReviewerSheets.init>) {
    const status = yield select(selectTimeSheetStatusByName(statusName));
    yield put(loadTimeSheets.init({
        purpose: Permission.ApproveSheets,
        request: { status_id: status?.id || undefined },
    }));
    yield take([loadTimeSheets.successType, loadTimeSheets.errorType]);
    yield put(getTimeReviewerSheets.success());
}

function* getTimeReviewerSheetsWatcher(){
    yield* takeLatest(getTimeReviewerSheets.initType, getTimeReviewerSheetsSaga);
}

export default [
    getTimeReviewerSheetsWatcher,
];

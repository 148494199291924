import { IStore } from 'store/configureStore';
import { employmentState } from '../selectors';
import { IEmployeeType } from '../../models/EmployeeType';
import { createSelector } from 'reselect';

const employeeTypeState = (state: IStore) => employmentState(state).employeeType;
export const selectEmployeeTypes = (state: IStore) => employeeTypeState(state).employeeTypes;
export const selectEmployeeTypesSortedByName = (
    createSelector<IStore, IEmployeeType[], IEmployeeType[]>(
        selectEmployeeTypes,
        employeeTypes => employeeTypes.sort(
            (first, second) => first.name.localeCompare(second.name),
        ),
    )
);

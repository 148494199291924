import { UploadTemplateField } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/UploadTemplateField';
import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { IOfferLetterTemplateFormValues } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/model';
import { TemplateNote } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/TemplateNote';

export interface IOfferLetterTemplateFormProps extends FormikProps<IOfferLetterTemplateFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
}

export function OfferLetterTemplateForm({
    handleSubmit,
    submitTitle,
    isLoading = false,
    isDisabled = false,
}: IOfferLetterTemplateFormProps) {
    const classes = useSettingsFormStyles();

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box display="flex">
                <Box
                    width="60%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Box className={classes.formBlock}>
                        <FormField
                            name="name"
                            label="Name"
                            className={classes.input}
                        />
                        <UploadTemplateField
                            name="filename"
                            className={classes.input}
                            fileTypes="application/pdf"
                            maxSizeMb={15}
                            text="Upload PDF template"
                            note="a file up to 15MB. The PDF MUST contain fillable form elements."
                            rejectMessage="Please upload a pdf file."
                        />
                    </Box>
                    <Box m={formBasicSpacing}>
                        <ButtonWithLoader
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled={isDisabled}
                            isLoading={isLoading}
                            data-testid="submit_update_button"
                        >
                            {submitTitle}
                        </ButtonWithLoader>
                    </Box>
                </Box>
                <Box width="40%" display="flex">
                    <TemplateNote/>
                </Box>
            </Box>
        </form>
    );
}

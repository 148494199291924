import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'shared/routes';
import { OfferLetterLayout } from 'modules/offerLetter/OfferLetterLayout';
import CreateOfferPage from './components/CreateOfferPage';
import OfferLetterEditPage from './OfferLetterEdit';
import OfferLetterCard from './OfferLetterCard';
import OfferLetters from './OfferLetters';
import { FeatureSwitches } from 'utils/featureSwitches';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import OfferLetterRescission from './OfferLetterRescission';

const OfferLetterModule = () => {
    return (
        <div style={{ height: '100%' }} className="layout-content">
            <OfferLetterLayout>
                <Switch>
                    <Route
                        path={routes.CLIENT.OFFER_LETTER.CREATE}
                        component={CreateOfferPage}
                        exact
                    />
                    <OfferLetters>
                        <FeatureSwitch feature={FeatureSwitches.enableOfferLetterCard}>
                            <Route
                                path={routes.CLIENT.OFFER_LETTER.DETAILS}
                                component={OfferLetterCard}
                                exact
                            />
                        </FeatureSwitch>
                        <FeatureSwitch feature={FeatureSwitches.enableOfferLetterEdit}>
                            <Route
                                path={routes.CLIENT.OFFER_LETTER.EDIT}
                                component={OfferLetterEditPage}
                                exact
                            />
                        </FeatureSwitch>
                        <FeatureSwitch feature={FeatureSwitches.enableOfferLetterRescission}>
                            <Route
                                path={routes.CLIENT.OFFER_LETTER.RESCIND}
                                component={OfferLetterRescission}
                                exact
                            />
                        </FeatureSwitch>
                    </OfferLetters>
                </Switch>
            </OfferLetterLayout>
        </div>
    );
};

export default OfferLetterModule;

import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useStyles = makeStyles(theme => ({
    CurrentBox: {
        background: 'transparent',
        minWidth: theme.spacing(31),
        marginTop: theme.spacing(6),
        marginRight: theme.spacing(4),
        padding: theme.spacing(2, 0),
        height: theme.spacing(31),
        boxShadow: '0px 1px 12px rgba(0,0,0,0.2)',
        fontFamily: 'Roboto',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        border: 'none',
        '&:focus, &.active': {
            border: 'none',
            outline: 'none',
        },
    },
    dashItemButton: {
        cursor: 'pointer',
    },
    Icon: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    Length: {
        fontSize: '60px',
        textDecoration: 'none solid rgb(0, 155, 229)',
    },
    Title: {
        maxWidth: theme.spacing(21),
        fontSize: '12px',
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    Label: {
        maxWidth: theme.spacing(21),
        fontSize: '11px',
        color: '#666666',
        textDecoration: 'none solid rgb(102, 102, 102)',
        letterSpacing: '1px',
        textAlign: 'center',
        paddingTop: theme.spacing(0.5),
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: theme.spacing(2),
            width: '100%',
            alignItems: 'center',
        },
    },
    input: {
        width: theme.spacing(30),
        display: 'flex',
        background: colors.white,
    },
}));

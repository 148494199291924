import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { AuthenticationError, authRequestTimeout, IAuthData, LocalStorageKey } from 'shared/models/Authentication';
import { IUserTokenInfo } from 'store/components/auth/authModels';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';

const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string;
const scope = process.env.REACT_APP_SCOPE as string;
const EXPIRE_IN_MINUTES = 2;
const authBasePath = process.env.REACT_APP_KEYCLOACK_URL as string;

interface IExpiringToken {
    exp: number;
}

class AuthApi {
    public static pendingAccessTokenRequest: Promise<string> | null = null;

    public async passwordAuth(username: string, password: string) {
        const { data } = await AuthApi.authRequest({ username, password, grant_type: 'password' });

        return jwtDecode<IUserTokenInfo>(data.id_token);
    }

    public async refreshTokens() {
        const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);

        if (!refreshToken) {
            throw new AuthenticationError('Refresh token not found');
        }

        const { data } = await AuthApi.authRequest({ refresh_token: refreshToken, grant_type: 'refresh_token' });
        return jwtDecode<IUserTokenInfo>(data.id_token);
    }

    public removeTokens() {
        localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
    }

    public setAuthTokens(access_token: string, refresh_token: string) {
        localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, access_token);
        localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, refresh_token);
    }

    public async getAccessToken(): Promise<string> {
        const token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN) as string;
        const parsedAccessToken = jwtDecode<IExpiringToken>(token);

        if (AuthApi.tokenIsExpired(parsedAccessToken) && AuthApi.pendingAccessTokenRequest === null) {
            AuthApi.pendingAccessTokenRequest = new Promise<string>((resolve, reject) => {
                this.refreshTokens()
                    .then(() => {
                        const newToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN) as string;
                        resolve(newToken);
                    })
                    .catch(error => {
                        logErrorWithCustomMessage(error, `Auth get token error`);
                        reject(error);
                    })
                    .finally(() => {
                        AuthApi.pendingAccessTokenRequest = null;
                    });
            });
        }

        if (AuthApi.pendingAccessTokenRequest) {
            return AuthApi.pendingAccessTokenRequest;
        }

        return Promise.resolve(token);
    }

    private static async authRequest(rawParams: Record<string, string>) {
        const params = new URLSearchParams();
        params.append('client_id', clientId);
        params.append('scope', scope);
        Object.entries(rawParams).forEach(([key, value]) => {
            params.append(key, value);
        });

        const response = await axios.post<IAuthData>(
            `${authBasePath}/auth/realms/headway/protocol/openid-connect/token`,
            params,
            {
                timeout: authRequestTimeout,
            },
        );

        localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, response.data.access_token);
        localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, response.data.refresh_token);

        return response;
    }

    private static tokenIsExpired({ exp }: IExpiringToken): boolean {
        const now = moment();
        const expiration = moment.unix(exp);

        return expiration.diff(now, 'minutes') < EXPIRE_IN_MINUTES;
    }
}

export const authApi = new AuthApi();

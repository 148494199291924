import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPayrollPayPeriods, setPayrollProcessorFilter } from 'modules/payrollProcessorHub/store/actions';
import { selectPayrollActiveTab } from 'modules/payrollProcessorHub/store/selectors';
import { PayrollSheetTabs, payrollSheetTabsSorted } from 'modules/payrollProcessorHub/store/model';
import { HeaderTabs, IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';

const PayrollSheetStatusTabs = () => {
    const tabs = (payrollSheetTabsSorted.map(value => ({
        value,
        label: value,
    })) as Array<IHeaderTab>);
    const dispatch = useDispatch();
    const currentValue = useSelector(selectPayrollActiveTab);

    const onChange = useCallback((newValue: string) => {
        dispatch(setPayrollProcessorFilter({
            status: newValue as PayrollSheetTabs,
        }));
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        // Update pay periods list on change status
        dispatch(getPayrollPayPeriods.init());
    }, [dispatch]);

    return (
        <HeaderTabs
            tabs={tabs}
            mobileHeader="Payroll Sheet Status"
            value={currentValue}
            onChange={onChange}
        />
    );
};

export default PayrollSheetStatusTabs;

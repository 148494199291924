import { useSelector } from 'react-redux';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { usePayrollReportStyle } from './PayrollStyle';
import { selectUsersById } from 'store/entities/users/selectors';
import { selectAllClientsById } from 'store/entities/clients/clientsSelectors';
import GridTable from 'shared/components/table/GridTable/GridTable';
import {
    IPayrollReportItem, ISheetsForPostPayroll,
    PostPayrollReportOverview,
} from 'modules/payrollProcessorHub/store/model';
import ReportOverview from './ReportOverview';
import { selectPostPayrollReport } from '../../../store/selectors';
import {
    clientCell,
    employeeCell, expenseSheetCell, expenseTotalMilesCell, failedReasonCell,
    payPeriodCell,
    timeOtHoursTimeOtDollarsCell,
    timeRtHoursTimeRtDollarsCell, totalDollarsCell,
} from './cells';
import clsx from 'clsx';

const PostPayrollReport = () => {
    const classes = usePayrollReportStyle();
    const tableClasses = useDefaultTableStyles();

    const allClients = useSelector(selectAllClientsById);
    const allUsers = useSelector(selectUsersById);
    const report = useSelector(selectPostPayrollReport);

    const cells = [
        employeeCell(allUsers),
        payPeriodCell(),
        clientCell(allClients),
        timeRtHoursTimeRtDollarsCell(tableClasses),
        timeOtHoursTimeOtDollarsCell(tableClasses),
        expenseSheetCell(tableClasses),
        expenseTotalMilesCell(tableClasses),
        totalDollarsCell(tableClasses),
        failedReasonCell(tableClasses),
    ];

    const reportBlocks: IPayrollReportItem[][] | null = useMemo(() => {
        if (!report) {
            return null;
        }
        const {
            numberOfBatches,
            numberOfPayrollRecords,
            numberOfEmployees,
            numberOfEntries,
            numberOfBatchesFailed,
            numberOfPayrollRecordsFailed,
            numberOfEmployeesFailed,
            numberOfEntriesFailed,
        } = report;
        return [
            [
                {
                    label: PostPayrollReportOverview.NumberOfBatchesToCreate,
                    values: [numberOfBatches, numberOfBatchesFailed],
                },
                {
                    label: PostPayrollReportOverview.NumberOfPayrollToCreate,
                    values: [numberOfPayrollRecords, numberOfPayrollRecordsFailed],
                },
                {
                    label: PostPayrollReportOverview.NumberOfEmployeeToProcess,
                    values: [numberOfEmployees, numberOfEmployeesFailed],
                },
                {
                    label: PostPayrollReportOverview.NumberOfTimeAndExpenseToProcess,
                    values: [numberOfEntries, numberOfEntriesFailed],
                },
            ],
        ];
    }, [report]);

    const reportSheets = useMemo(() => {
        if (!report) {
            return [];
        }
        return report.sheetsForPayroll.filter((sheet: ISheetsForPostPayroll) => sheet.failedReason);
    }, [report]);

    if (!report) {
        return null;
    }

    return (
        <Box className={classes.SummaryWrapper}>
            {reportBlocks && (
                <Box className={classes.SummaryInfo}>
                    <ReportOverview
                        blocks={reportBlocks}
                        classes={
                            {
                                column: classes.SummaryInfoColumn,
                                row: classes.SummaryInfoRow,
                                values: [
                                    clsx(classes.SummaryInfoValue, classes.SummaryInfoValueValid),
                                    clsx(classes.SummaryInfoValue, classes.SummaryInfoValueError),
                                ],
                            }
                        }
                    />
                </Box>
            )}
            {reportSheets.length > 0 && (
                <Box className={classes.SummaryTable}>
                    <GridTable<ISheetsForPostPayroll>
                        getKey={row => row.timeSheetId}
                        cells={cells}
                        rowData={reportSheets}
                        stickyHeader
                        headerCellClassName={tableClasses.headCell}
                        bodyCellClassName={tableClasses.bodyCell}
                    />
                </Box>
            )}
        </Box>
    );
};

export default PostPayrollReport;

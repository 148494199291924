import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { omit } from 'lodash';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { selectIsUserCreating } from 'modules/settings/submodules/platformUsers/store/selectors';
import { IUserFormValues } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/model';
import UserFormWrapper from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/UserFormWrapper';
import { createPlatformUser } from 'modules/settings/submodules/platformUsers/store/actions';

interface INewClientModal extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
}

export default function CreateUserModal({
    title, isOpened, onClose, ...modalProps
}: INewClientModal) {
    const dispatch = useDispatch();

    const isLoading = useSelector(selectIsUserCreating);
    const onSave = useCallback((values: IUserFormValues) => {
        dispatch(createPlatformUser.init({
            ...omit(values, 'address'),
            address1: values.address,
        }));
    }, [dispatch]);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <UserFormWrapper
                    onSubmit={onSave}
                    submitTitle="Create"
                    isLoading={isLoading}
                />
            </Box>
        </ModalDialog>
    );
}

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import { Box, Button } from '@material-ui/core';
import { DayPickerWithOuterLabel } from 'modules/settings/common/components/DayPickerWithOuterLabel';
import { SpecialUserSelect } from 'modules/settings/submodules/clients/components/ClientEditForm/FormComponents';
import { UserTypeSelect } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/components/UserTypeSelect';
import { defaultJobNumberFormValues, IJobNumberFormValues } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/model';
import { BillingInfoFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/BillingInfoFormSection';
import { CloseJobNumberFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/CloseJobNumberFormSection';
import { DealFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/DealFormSection';
import { PayrollInfoFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/PayrollInfoFormSection';
import { SettingsFormSection } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/sections/SettingsFormSection';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import { FeeType, TimesheetSettings } from 'shared/models/JobNumber';
import { Permission } from 'store/components/auth/authModels';
import { useAdditionalValidityErrorText } from 'modules/settings/submodules/clients/jobNumber/components/JobNumberForm/store/useAdditionalValidityErrorText';
import { selectDealTypesById } from 'store/entities/configuration/configurationSelectors';

export interface IJobNumberFormProps extends FormikProps<IJobNumberFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onBack?: () => void;
    isEdit?: boolean;
    dealReadonly?: boolean;
}

export function JobNumberForm({
    handleSubmit,
    submitTitle,
    onBack,
    values,
    setFieldValue,
    isLoading = false,
    isDisabled = false,
    isEdit = false,
    dealReadonly = false,
}: IJobNumberFormProps) {
    const classes = useSettingsFormStyles();
    const {
        deal,
        dealType,
        timesheetSettings,
        jobNumber,
        billingInfo,
        isPerDiem,
    } = values;
    const dealTypesByIds = useSelector(selectDealTypesById);

    useEffect(() => {
        // Reset fields related to TimesheetSettings
        if (timesheetSettings === TimesheetSettings.Hourly) {
            setFieldValue('fileRate', defaultJobNumberFormValues.fileRate);
        } else if (timesheetSettings === TimesheetSettings.PerFile) {
            setFieldValue('hourlyRate', defaultJobNumberFormValues.hourlyRate);
            setFieldValue('billingInfo', defaultJobNumberFormValues.billingInfo);
            setFieldValue('max', defaultJobNumberFormValues.max);
            setFieldValue('min', defaultJobNumberFormValues.min);
            setFieldValue('miCredit', defaultJobNumberFormValues.miCredit);
            setFieldValue('blendedAmount', defaultJobNumberFormValues.blendedAmount);
        }
    }, [setFieldValue, timesheetSettings]);
    useEffect(() => {
        if (!isPerDiem) {
            setFieldValue('perDiemRate', defaultJobNumberFormValues.perDiemRate);
        }
    }, [setFieldValue, isPerDiem]);
    useEffect(() => {
        if (billingInfo === FeeType.Daily) {
            setFieldValue('blendedAmount', defaultJobNumberFormValues.blendedAmount);
        }
        if (billingInfo === FeeType.Blended) {
            setFieldValue('max', defaultJobNumberFormValues.max);
            setFieldValue('min', defaultJobNumberFormValues.min);
            setFieldValue('miCredit', defaultJobNumberFormValues.miCredit);
        }
    }, [setFieldValue, billingInfo]);
    useEffect(() => {
        setFieldValue('dealType', dealTypesByIds[deal?.type_id] || null);
    }, [setFieldValue, deal, dealTypesByIds]);

    const additionalValidityErrorText = useAdditionalValidityErrorText(deal);

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.wideForm, classes.smallPadding)}>
            <Box
                display="flex"
                flexDirection="row"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width="70%"
                    mr={2}
                >
                    <Box className={classes.formBlock}>
                        <DealFormSection
                            deal={deal}
                            jobNumber={jobNumber}
                            isEdit={isEdit}
                            dealReadonly={dealReadonly}
                        />
                        <Box>
                            <FormField
                                name="jobDescription"
                                label="Job Description"
                                className={classes.input}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                            <Box width="50%">
                                <DayPickerWithOuterLabel
                                    withKeyboard
                                    name="startDate"
                                    outerLabel="Start Date"
                                    className={classes.input}
                                />
                            </Box>
                            <Box width="50%">
                                <DayPickerWithOuterLabel
                                    withKeyboard
                                    name="endDate"
                                    outerLabel="End Date"
                                    className={classes.input}
                                />
                            </Box>
                        </Box>

                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                            <Box width="50%">
                                <SpecialUserSelect
                                    feature="purpose"
                                    featureValue={Permission.ApproveSheets}
                                    name="managerId"
                                    label="--"
                                    outerLabel="Approving Manager"
                                    className={classes.input}
                                    useIdValue
                                />
                            </Box>
                            <Box width="50%">
                                <UserTypeSelect
                                    name="userTypeId"
                                    label="--"
                                    outerLabel="User Type"
                                    className={classes.input}
                                    useIdValue
                                />
                            </Box>
                        </Box>
                    </Box>

                    <SettingsFormSection deal={deal}/>

                    <Box m={formBasicSpacing} mt={2}>
                        {onBack && (
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={onBack}
                                disabled={isLoading}
                            >
                                Back
                            </Button>
                        )}
                        <ButtonWithLoader
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            disabled={isDisabled || Boolean(additionalValidityErrorText)}
                            isLoading={isLoading}
                            data-testid="submit_update_button"
                        >
                            {submitTitle}
                        </ButtonWithLoader>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    width="30%"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                    >
                        <BillingInfoFormSection
                            timesheetSettings={timesheetSettings}
                            feeType={billingInfo}
                            dealType={dealType}
                        />
                        <PayrollInfoFormSection timesheetSettings={timesheetSettings} isPerDiem={isPerDiem}/>
                    </Box>

                    {isEdit && (<CloseJobNumberFormSection/>)}
                </Box>
            </Box>
        </form>
    );
}

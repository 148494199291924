import { combineReducers } from 'redux';
import { IClientRole, IGlobalRole, IUserInfo } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import {
    IPermissionRequest,
    IPrismUserTypeRequest,
    IPurposeRequest,
    PrismUserType,
} from 'store/entities/users/model';
import { itemsByIdReducer } from 'store/reducerUtils';
import {
    getSpecialUsersAction, loadClientRoles, loadGlobalRoles, getUsers, lookupUsers, searchUsers, UsersActions,
} from './actions';
import { getUsersById } from './utils';

export interface IUsersById extends Record<string, IUserInfo> {}

const defaultState = {
    usersById: {},
    userIdsByPurpose: {} as Record<Permission, string[]>,
    userIdsByPermission: {} as Record<Permission, string[]>,
    userIdsByPrismUserType: {} as Record<PrismUserType, string[]>,
    userSearchResultIds: {},
    globalRolesById: {},
    isSearching: false,
};

function usersById(
    state: IUsersById = defaultState.usersById,
    action: UsersActions,
): IUsersById {
    switch (action.type) {
        case getUsers.successType:
        case lookupUsers.successType:
            return {
                ...state,
                ...getUsersById(action.payload),
            };
        case getSpecialUsersAction.successType:
            return {
                ...state,
                ...getUsersById(action.payload.result),
            };
        default:
            return state;
    }
}

function userIdsByPurpose(
    state: Record<Permission, string[]> = defaultState.userIdsByPurpose,
    action: UsersActions,
): Record<Permission, string[]> {
    switch (action.type) {
        case getSpecialUsersAction.successType: {
            const { request, result } = action.payload as {
                request: IPurposeRequest;
                result: IUserInfo[];
            };
            if (request?.purpose) {
                return {
                    ...state,
                    [request.purpose]: result.map(user => user.identity_id),
                };
            }
            return state;
        }
        default:
            return state;
    }
}

function userIdsByPermission(
    state: Record<Permission, string[]> = defaultState.userIdsByPermission,
    action: UsersActions,
): Record<Permission, string[]> {
    switch (action.type) {
        case getSpecialUsersAction.successType: {
            const { request, result } = action.payload as {
                request: IPermissionRequest;
                result: IUserInfo[];
            };
            if (request?.permission) {
                return {
                    ...state,
                    [request.permission]: result.map(user => user.identity_id),
                };
            }
            return state;
        }
        default:
            return state;
    }
}

function userIdsByPrismUserType(
    state: Record<PrismUserType, string[]> = defaultState.userIdsByPrismUserType,
    action: UsersActions,
): Record<PrismUserType, string[]> {
    switch (action.type) {
        case getSpecialUsersAction.successType: {
            const { request, result } = action.payload as {
                request: IPrismUserTypeRequest;
                result: IUserInfo[];
            };
            if (request?.prism_user_type) {
                return {
                    ...state,
                    [request.prism_user_type]: result.map(user => user.identity_id),
                };
            }
            return state;
        }
        default:
            return state;
    }
}

function userSearchResultIds(
    state: Record<string, string[]> = defaultState.userSearchResultIds,
    action: UsersActions,
): Record<string, string[]> {
    switch (action.type) {
        case searchUsers.successType: {
            const { searchKey, userIds } = action.payload;
            return {
                ...state,
                [searchKey]: userIds,
            };
        }
        default:
            return state;
    }
}
const isSearching = (
    state: Record<string, boolean> = defaultState.userSearchResultIds,
    action: UsersActions,
): Record<string, boolean> => {
    switch (action.type) {
        case searchUsers.initType:
            return {
                ...state,
                [action.payload.searchKey]: true,
            };
        case searchUsers.successType:
        case searchUsers.errorType:
            return {
                ...state,
                [action.payload.searchKey]: false,
            };
        default:
            return state;
    }
};

const globalRolesById = itemsByIdReducer<IGlobalRole, IGlobalRole>(loadGlobalRoles);
const clientRolesById = itemsByIdReducer<IClientRole, IClientRole>(loadClientRoles);

export const users = combineReducers({
    usersById,
    userIdsByPurpose,
    userIdsByPermission,
    userIdsByPrismUserType,
    userSearchResultIds,
    isSearching,
    globalRolesById,
    clientRolesById,
});

export type TimesheetApprovalState = ReturnType<typeof users>;

import { useSelector } from 'react-redux';
import { ISheetInfoItemData } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { ISheet } from 'shared/models/sheet/Sheet';
import { userFullName } from 'shared/utils/converters/user';
import { areaView } from 'shared/utils/formatters/areaFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { logger } from 'shared/utils/logging/logger';
import {
    ApproversFromFields,
    DetailSlugs,
    IDetailConfiguration,
    SheetDetailSlug,
} from 'store/entities/clients/clientsModel';
import {
    selectAreaBySheetId,
    selectAssignmentById,
    selectDealById,
    selectJobNumberById,
    selectLocationById,
    selectPositionById,
    selectSubmittingOrgById,
} from 'store/entities/configuration/configurationSelectors';
import { selectDepartmentById } from 'modules/employmentInfo/store/department/selectors';
import { selectSheet } from 'store/entities/timesheet/selectors';
import { selectUserById, selectUsersById } from 'store/entities/users/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectActiveGridStatus } from 'store/entities/appConfig/appConfigSelectors';
import { rejectedReasonFormatter } from 'shared/utils/formatters/rejectedReasonFormatter';
import { selectClientTimeAndPayConfiguration } from 'store/entities/clients/clientsSelectors';
import { IManagerInfo } from '../../../store/entities/configuration/configurationModel';
import { getPayPeriodByStartEnd } from './payPeriod';

export const useDetailTitle = (sheet?: ISheet, config?: IDetailConfiguration<DetailSlugs>): string => {
    const area = useSelector(selectAreaBySheetId(sheet?.id || ''));
    const assignment = useSelector(selectAssignmentById(sheet?.assignment_id));
    const position = useSelector(selectPositionById(assignment?.position_id));
    const user = useSelector(selectUserById(sheet?.user_id));
    const jobNumber = useSelector(selectJobNumberById(sheet?.job_number_id));

    switch (config?.slug) {
        case SheetDetailSlug.Area: {
            return `${config?.placeholder} - ${areaView(area)}`;
        }
        case SheetDetailSlug.Position: {
            return position?.name || '';
        }
        case SheetDetailSlug.Employee: {
            return userFullName(user);
        }
        case SheetDetailSlug.JobNumber: {
            return `Job #: ${jobNumber?.job_number || ''}`;
        }
        default:
            return '';
    }
};

export const useSheetDetail = (
    sheetId: string,
    configuration: IDetailConfiguration<SheetDetailSlug>[] = [],
): ISheetInfoItemData[] => {
    const area = useSelector(selectAreaBySheetId(sheetId));
    const sheet = useSelector(selectSheet(sheetId));
    const payPeriod = getPayPeriodByStartEnd(sheet?.period_start || '', sheet?.period_end || '');
    const activeStatus = useSelector(selectActiveGridStatus);
    const showReason = activeStatus === StatusNames.REJECTED;
    const assignment = useSelector(selectAssignmentById(sheet?.assignment_id));
    const position = useSelector(selectPositionById(assignment?.position_id));
    const location = useSelector(selectLocationById(assignment?.location_id));
    const department = useSelector(selectDepartmentById(assignment?.department_id));
    const jobNumber = useSelector(selectJobNumberById(sheet?.job_number_id));
    const usersById = useSelector(selectUsersById);

    const timeAndPayConfiguration = useSelector(selectClientTimeAndPayConfiguration);
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;

    const approverIds: number[] = approverFrom === ApproversFromFields.FromAssignment
        ? assignment?.managers?.map((manager: IManagerInfo) => manager.user_id) ?? []
        : jobNumber?.manager_id ? [jobNumber.manager_id] : [];
    const approvers = approverIds.map(approverId => userFullName(usersById[approverId]));

    const user = usersById[sheet?.user_id || ''];
    const notes = rejectedReasonFormatter(sheet?.notes)[0];
    const batchId = sheet?.prism_batch_id;
    const deal = useSelector(selectDealById(jobNumber?.deal_id));
    const submittingOrg = useSelector(selectSubmittingOrgById(deal?.submitting_org_id));

    const dataViewDictionary: Record<SheetDetailSlug, () => string> = {
        [SheetDetailSlug.SheetId]: () => sheetId,
        [SheetDetailSlug.PayPeriod]: () => `${payPeriod}`,
        [SheetDetailSlug.Area]: () => areaView(area),
        [SheetDetailSlug.Position]: () => position?.name || '',
        [SheetDetailSlug.Location]: () => location?.name || '',
        [SheetDetailSlug.Department]: () => department?.name || '',
        [SheetDetailSlug.Approver]: () => approvers.join(', '),
        [SheetDetailSlug.Employee]: () => userFullName(user),
        [SheetDetailSlug.PayRate]: () => assignment ? `${formatDollars(assignment.pay_rate_value)}/${assignment.pay_rate_type}` : '',
        [SheetDetailSlug.Notes]: () => notes?.notes || '',
        [SheetDetailSlug.BatchId]: () => batchId || '',
        [SheetDetailSlug.DeliveryOrder]: () => '',
        [SheetDetailSlug.JobsiteLocation]: () => submittingOrg?.client_site_name,
        [SheetDetailSlug.JobNumber]: () => jobNumber?.job_number?.toString() || '',
        [SheetDetailSlug.DealNumber]: () => deal?.deal_number?.toString() || '',
    };
    const detailConfiguration = configuration.map(config => {
        let value = '';
        if (dataViewDictionary[config.slug]) {
            value = dataViewDictionary[config.slug]();
        } else {
            logger.error(new Error(`Sheet detail slug not found: ${config.slug}`));
        }
        return {
            title: config.placeholder,
            value,
        };
    });
    showReason && detailConfiguration.push({
        title: 'Rejection Reason',
        value: dataViewDictionary[SheetDetailSlug.Notes](),
    });
    batchId && detailConfiguration.push({
        title: 'Prism BatchId',
        value: dataViewDictionary[SheetDetailSlug.BatchId](),
    });

    return detailConfiguration;
};

import React, { useContext, useCallback } from 'react';
import { HeaderTabs, IHeaderTab } from 'shared/components/common/headerTabs/HeaderTabs';
import { IMenuTabsState } from 'shared/models/Global';
import { MenuContext } from 'shared/models/menuContext';

export interface IHeaderTabsProps {
    tabs: Array<IHeaderTab>;
    menuKey: keyof IMenuTabsState;
    mobileHeader: string;
    onChange?: () => void;
}

const HeaderContextTabs = ({
    tabs,
    menuKey,
    mobileHeader,
    onChange,
}: IHeaderTabsProps) => {
    const { menuState, setMenuState } = useContext(MenuContext);

    const onChangeCallback = useCallback((value: string) => {
        setMenuState({
            ...menuState,
            tabs: {
                ...menuState.tabs,
                [menuKey]: value,
            },
        });
        onChange && onChange();
    }, [menuKey, menuState, setMenuState, onChange]);

    return (
        <HeaderTabs
            tabs={tabs}
            mobileHeader={mobileHeader}
            value={menuState.tabs[menuKey]}
            onChange={onChangeCallback}
        />
    );
};

export default HeaderContextTabs;

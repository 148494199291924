import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Publish } from '@material-ui/icons';
import AttachmentsPreview from 'shared/components/uploader/AttachmentsPreview';
import { selectExpenseEntry } from 'store/entities/timesheet/selectors';
import { useUploadStyles } from './styles';
import clsx from 'clsx';
import { Typography, Button } from '@material-ui/core';
import { useModal } from 'shared/utils/hooks/useModal';
import PdfSVG from '../icons/PdfSVG';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { AttachmentsState } from 'store/entities/timesheet/reducers/sheetEntryAttachments';
import { IEntryAttachment, IPreviewTypes } from 'shared/models/Attachments';

interface IEntryFilesUploaderProps {
    fileTypes: string;
    maxSize: number;
    customClasses?: string;
    onAttachmentChange: (files: IPreviewTypes[], entryId?: string) => void;
    onAttachmentRemove: (file: IEntryAttachment, entryId: string) => void;
    entryId?: string;
}

export default function EntryFilesUploader(
    {
        fileTypes,
        maxSize,
        customClasses,
        onAttachmentChange,
        onAttachmentRemove,
        entryId,
    }: IEntryFilesUploaderProps,
) {
    const classes = useUploadStyles();

    const {
        isModalOpened: isPreviewOpen,
        onModalOpen: onPreviewOpen,
        onModalClose: onPreviewClose,
    } = useModal();

    const onDrop = useCallback(acceptedFiles => {
        onPreviewClose();
        onAttachmentChange(acceptedFiles, entryId);
    }, [entryId, onAttachmentChange, onPreviewClose]);

    const {
        getRootProps, getInputProps, isDragReject, rejectedFiles,
    } = useDropzone({
        accept: fileTypes,
        onDrop,
        minSize: 0,
        maxSize: maxSize,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles.some(file => file.size > maxSize);

    const { attachments } = useSelector<IStore, AttachmentsState>(state => state.sheetEntryAttachments);

    const entry = useSelector(selectExpenseEntry(entryId)) ?? null;

    const filteretedAttach = attachments.filter(file => file.mimetype !== 'application/pdf');
    const filteredDefaults = entry?.sheet_entry_attachments.filter(file => file.mimetype !== 'application/pdf') || [];
    const entryAttachments = entry?.sheet_entry_attachments || [];

    return (
        <>
            {(attachments.length > 0 || entryAttachments.length > 0) && (
                <section className={classes.preview}>
                    <div className = {classes.filePreviewZone}>

                        {filteretedAttach.length > 0
                            ? (<img className={classes.img}
                                alt=""
                                src={filteretedAttach[0].url}
                                key={filteretedAttach[0].id}
                            />)
                            : (
                                filteredDefaults.length > 0
                                    ? (<img className={classes.img}
                                        alt=""
                                        src={filteredDefaults[0].url}
                                        key={filteredDefaults[0].id}
                                    />)
                                    : ((attachments.length > 0 || entryAttachments.length > 0)
                                        && (
                                            <div className={clsx(classes.img, classes.previewImg)}>
                                                <PdfSVG customClasses={classes.pdfPreview}/>
                                            </div>
                                        )
                                    )
                            )
                        }
                        <Button
                            className = {classes.imageButton}
                            color="inherit"
                            onClick={onPreviewOpen}>
                            See All [{attachments.length + entryAttachments.length}]
                        </Button>
                    </div>
                </section>
            )
            }

            {entry && (attachments.length > 0 || entryAttachments.length > 0) && isPreviewOpen && (
                <AttachmentsPreview
                    open={isPreviewOpen}
                    onClose={onPreviewClose}
                    files={[...attachments, ...entryAttachments]}
                    onDelete={attachment => onAttachmentRemove(attachment, entry.id)}
                />
            )}

            <section className={clsx(classes.fileUpload, customClasses)}>
                <div {...getRootProps()} className={classes.fileDropzone}>
                    <div className={classes.uploadHeader}>
                        <input {...getInputProps()}/>
                        <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                        <Typography className={classes.uploadHeaderTitle}
                            color="primary"
                            variant="subtitle2"
                        >
                                Upload Receipt
                        </Typography>
                    </div>
                    <Typography color="textSecondary" variant="caption">a file up to 10MB</Typography>
                    {isDragReject && (
                        <Typography className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                        Please upload image or a pdf file.
                        </Typography>
                    )}
                    {isFileTooLarge && (
                        <Typography className={classes.uploadError}
                            color="primary"
                            variant="body1"
                        >
                        File is too large.
                        </Typography>
                    )}
                </div>
            </section>
        </>
    );
}

import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Tabs, Tab, IconButton } from '@material-ui/core';
import { MenuContext } from 'shared/models/menuContext';
import { useActions } from 'store/utils';
import MoreVert from '@material-ui/icons/MoreVert';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllSheets } from 'store/entities/timesheet/selectors';
import useHeaderTabsStyles from 'shared/components/common/headerTabs/TabStyles';
import a11yProps from 'shared/components/tabs/a11yProps';
import { useModal } from 'shared/utils/hooks/useModal';
import { TimeEntryMobileModalTabs } from './TimeEntryMobileModalTabs';
import Modal from '../../../shared/components/modals/Modal';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';

const TimeEntryTabs: React.FC = () => {
    const actions = useActions( { setSheetGridStatus });
    const actionsRef = useRef(actions);

    const { menuState, setMenuState } = useContext(MenuContext);
    const classes = useHeaderTabsStyles();
    const tabsClasses = {
        root: classes.tabs,
        flexContainer: classes.tabsFlexContainer,
        indicator: classes.tabsIndicator,
    };

    const { isModalOpened, onModalOpen, onModalClose } = useModal();

    useEffect(() => {
        actionsRef.current.setSheetGridStatus(menuState.tabs.timeEntryPage);
    }, [menuState.tabs.timeEntryPage, actionsRef]);

    const onChange = useCallback((_, value: StatusNames) => {
        setMenuState({
            ...menuState,
            tabs: {
                ...menuState.tabs,
                timeEntryPage: value,
            },
        });
    }, [menuState, setMenuState]);

    const sheets = useSelector(selectAllSheets);

    const hasRecalled = sheets.some(sheet => sheet.status.name === StatusNames.RECALLED);

    return (
        <Tabs
            classes={tabsClasses}
            value={menuState.tabs.timeEntryPage}
            onChange={onChange}
            textColor="inherit"
        >
            <Tab classes={{ root: classes.tab, selected: classes.tabActive }}
                label="Working" value={StatusNames.WORKING}
                {...a11yProps(0)}/>
            <Tab classes={{ root: classes.tab, selected: classes.tabActive }}
                label="Submitted" value={StatusNames.SUBMITTED}
                {...a11yProps(1)}/>
            {hasRecalled && (
                <Tab classes={{ root: clsx(classes.tabMobileHidden, classes.tab), selected: classes.tabActive,
                    wrapper: classes.iconLabelWrapper }}
                label="Recalled" value={StatusNames.RECALLED}
                icon={<WarningIcon className={classes.warningClass}/>}
                {...a11yProps(2)}/>
            )}
            <Tab classes={{ root: clsx(classes.tabMobileHidden, classes.tab), selected: classes.tabActive }}
                label="Approved" value={StatusNames.APPROVED}
                {...a11yProps(3)}/>
            <Tab classes={{ root: classes.tab, selected: classes.tabActive }}
                label="Rejected" value={StatusNames.REJECTED}
                {...a11yProps(4)}/>
            <Tab classes={{ root: clsx(classes.tabMobileHidden, classes.tab), selected: classes.tabActive }}
                label="All" value={StatusNames.ALL}
                {...a11yProps(5)}/>

            <IconButton className={classes.moreVert} onClick={onModalOpen}>
                <MoreVert/>
            </IconButton>
            <Modal
                title="Timesheet status"
                isOpened={isModalOpened}
                onClose={onModalClose}
                showCloseIcon={true}
                customClasses={{
                    title: classes.modalTitle,
                    paper: classes.modalPaper,
                    content: classes.modalContent,
                }}
                fullWidth
            >
                <TimeEntryMobileModalTabs onClose={onModalClose}/>
            </Modal>
        </Tabs>
    );
};

export default TimeEntryTabs;

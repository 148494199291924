import { selectSyncWorkLocationsInProgress } from 'modules/settings/submodules/clients/selectors';
import React from 'react';
import { syncClientWorkLocation } from 'modules/settings/submodules/clients/workLocation/store/actions';
import { SyncButton } from 'modules/settings/common/components/SyncButton/SyncButton';

export const SyncWorkLocations = () => {
    return (
        <SyncButton syncAction={syncClientWorkLocation.init}
            isLoadingSelector={selectSyncWorkLocationsInProgress}
            titleEntity={'Work Locations'}/>
    );
};

import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';

export const useTextFilterStyles = makeHighPriorityStyles((theme: Theme) => ({
    filter: {
        width: 320,
        background: colors.white,
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
    },
}));

import fromPairs from 'lodash/fromPairs';
import pickBy from 'lodash/pickBy';
import { createSelector } from 'reselect';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber, IJobNumberUserType } from 'shared/models/JobNumber';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IStore } from 'store/configureStore';
import {
    ActivityTag,
    IActivity,
    IArea,
    IBackgroundCheckTemplate,
    IPhysicalDemandsTemplate,
    IProjectWithAssignmentBackend,
    IWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationModel';
import { selectSheet, selectTimeSheetById } from 'store/entities/timesheet/selectors';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';
import { IDeal } from 'shared/models/Deal';
import { selectClientApproversFrom } from 'store/entities/clients/clientsSelectors';
import { ApproversFromFields } from 'store/entities/clients/clientsModel';
import { EntryType } from 'shared/models/sheet/Sheet';

const filterDeletedActivity = (activity: IActivity) => !activity.deleted_at;

export const selectIsActivitiesLoading = (state: IStore): boolean => state.configuration.isActivitiesLoading;
export const selectActivitiesById = (state: IStore): ItemsById<IActivity> => state.configuration.activitiesById;
export const selectActivitiesByIdWithoutDeleted = createSelector(
    selectActivitiesById,
    activitiesById => pickBy(activitiesById, filterDeletedActivity),
);
export const selectTimeActivities = createSelector(
    selectActivitiesByIdWithoutDeleted,
    activitiesById => Object.values(activitiesById).filter(activity => activity.sheet_type === EntryType.TIME),
);
export const selectDefaultTimeActivities = createSelector(
    selectTimeActivities,
    timeActivities => timeActivities.filter(activity => activity.tags?.includes(ActivityTag.Default)),
);
const selectActivitiesIncludingDeleted = createSelector(
    selectActivitiesById,
    activitiesById => Object.values(activitiesById),
);
export const selectActivities = createSelector(
    selectActivitiesIncludingDeleted,
    activitiesIncludingDeleted => activitiesIncludingDeleted.filter(filterDeletedActivity),
);

export const selectActivitiesByTaskId = (state: IStore) => state.configuration.activitiesByTaskId;

export const selectActivity = (id?: string) => (state: IStore): IActivity => state.configuration.activitiesById[id || ''];
export const selectAreasById = (state: IStore) => state.configuration.areasById;
export const selectAssignmentsById = (state: IStore) => state.configuration.assignmentsById;
export const selectAreaBySheetId = (id: string) => (state: IStore): IArea | undefined =>
    state.configuration.areasById[selectSheet(id)(state)?.area_id ?? ''];
export const selectTasksById = (state: IStore) => state.configuration.tasksById;
export const selectTaskById = (taskId: string) => (state: IStore) => state.configuration.tasksById[taskId];
export const selectTasks = (state: IStore) => Object.values(selectTasksById(state));
export const selectAssignmentById = (id?: string) => (state: IStore) => selectAssignmentsById(state)[id || ''];
export const selectCurrentUserApprovalsLevelByAssignments = createSelector(
    selectAssignmentsById,
    selectCurrentUser,
    (assignmentsById, currentUser) => {
        return fromPairs(Object.values(assignmentsById).map(assignment => [
            assignment.id,
            assignment.managers.find(item => item.user_id === currentUser?.id)?.manager_level,
        ]));
    },
);
const selectApproversCount = (sheetId: string) => (state: IStore) => {
    const sheet = selectTimeSheetById(sheetId)(state);
    const approversFrom = selectClientApproversFrom(state);
    if (sheet && approversFrom === ApproversFromFields.FromAssignment){
        const assignment = selectAssignmentById(sheet.assignment_id)(state);
        return assignment.managers.length;
    }
    return 1;
};
export const selectApproversCountBySheets = (sheetIds: string[]) => (state: IStore) => {
    return sheetIds.reduce((acc, sheetId) => ({
        ...acc,
        [sheetId]: selectApproversCount(sheetId)(state),
    }), {}) as {[key: string]: number};
};

export const selectProjectIdByAssignmentId = (id: string) => (state: IStore) => selectAssignmentProjectIds(state)[id];
export const selectAssignmentAreaId = (state: IStore) => state.configuration.assignmentAreaId;
export const selectAssignmentProjectIds = (state: IStore) => state.configuration.projectIdsByAssignmentId;
export const selectProjectsWithAssignments = (state: IStore): IProjectWithAssignmentBackend[] =>
    state.configuration.projectWithAssignments;
export const selectProjectsById = (state: IStore) => state.configuration.projectsById;

/**
 * Positions
 */
export const selectPositionsById = (state: IStore) => state.configuration.positionsById;
export const selectPositions = (state: IStore) =>
    Object.values(selectPositionsById(state)).filter(position => !position.deleted);
export const selectPositionById = (id = '') => (state: IStore) => state.configuration.positionsById[id];

/**
 * Controlling Orgs
 */
export const selectControllingOrgsById = (state: IStore) => {
    return state.configuration.controllingOrgsById;
};
export const selectAllControllingOrgs = (state: IStore): IControllingOrg[] => {
    return Object.values(selectControllingOrgsById(state));
};
export const selectActiveControllingOrgs = (state: IStore) => {
    return selectAllControllingOrgs(state).filter(org => org.is_active);
};
export const selectControllingOrgById = (id = '') => (state: IStore) => {
    const controllingOrgsById = selectControllingOrgsById(state);
    return controllingOrgsById[id];
};
export const selectControllingOrgsIsLoading = (state: IStore) => {
    return state.configuration.controllingOrgsIsLoading;
};

/**
 * Deals
 */
export const selectDealsById = (state: IStore) => state.configuration.dealsById;
export const selectDealById = (id = '') => (state: IStore) => selectDealsById(state)[id];
export const selectDeals = (state: IStore): IDeal[] => Object.values(selectDealsById(state));
export const selectDealsIsLoading = (state: IStore) => {
    return state.configuration.dealsIsLoading;
};

/**
 * Deal Types
 */
export const selectDealTypesById = (state: IStore) => state.configuration.dealTypesById;
export const selectDealTypeById = (id = '') => (state: IStore) => selectDealTypesById(state)[id];
export const selectDealTypes = (state: IStore) => Object.values(selectDealTypesById(state));

/**
 * Submitting Orgs
 */
export const selectSubmittingOrgsById = (state: IStore) => {
    return state.configuration.submittingOrgsById;
};
export const selectSubmittingOrgs = (state: IStore) => {
    return Object.values(selectSubmittingOrgsById(state));
};
export const selectSubmittingOrgById = (id = '') => (state: IStore) => {
    const submittingOrgsById = selectSubmittingOrgsById(state);
    return submittingOrgsById[id];
};
export const selectSubmittingOrgsIsLoading = (state: IStore) => {
    return state.configuration.submittingOrgsIsLoading;
};

/**
 * Submitting Org Locations
 */
export const selectSubmittingOrgLocationsById = (state: IStore) => {
    return state.configuration.submittingOrgLocationsById;
};
export const selectSubmittingOrgLocations = (state: IStore) => {
    return Object.values(selectSubmittingOrgLocationsById(state));
};
export const selectSubmittingOrgLocationById = (id = '') => (state: IStore) => {
    const submittingOrgLocationsById = selectSubmittingOrgLocationsById(state);
    return submittingOrgLocationsById[id];
};
export const selectSubmittingOrgLocationsIsLoading = (state: IStore) => {
    return state.configuration.submittingOrgLocationsIsLoading;
};

/**
 * Work locations
 */
export const selectLocationsById = (state: IStore) => state.configuration.locationsById;
export const selectLocations = (state: IStore) =>
    Object.values(selectLocationsById(state)).filter(location => !location.deleted);
export const selectLocationById = (id = '') => (state: IStore) => state.configuration.locationsById[id];

/**
 * Physical demands
 */
export const selectPhysicalDemandsById = (state: IStore) => state.configuration.physicalDemandsById;
export const selectPhysicalDemands = (state: IStore) => Object.values(selectPhysicalDemandsById(state));
export const selectPhysicalDemandById = (id = '') => (state: IStore) => selectPhysicalDemandsById(state)[id];
export const selectIsPhysicalDemandsTemplatesLoading = (state: IStore) =>
    state.configuration.isPhysicalDemandsTemplatesLoading;
export const selectPhysicalDemandsSortedByName = (
    createSelectorSortedByNameFromById<IPhysicalDemandsTemplate>(selectPhysicalDemandsById)
);

/**
 * Working conditions
 */
export const selectWorkingConditionsById = (state: IStore) => state.configuration.workingConditionsById;
export const selectWorkingConditions = (state: IStore) => Object.values(selectWorkingConditionsById(state));
export const selectWorkingConditionById = (id = '') => (state: IStore) => selectWorkingConditionsById(state)[id];
export const selectIsWorkingConditionsLoading = (state: IStore) => state.configuration.isWorkingConditionsLoading;
export const selectWorkingConditionsSortedByName = (
    createSelectorSortedByNameFromById<IWorkingConditionsTemplate>(selectWorkingConditionsById)
);

/**
 * Background check templates
 */
export const selectBackgroundCheckTemplatesById = (state: IStore) => state.configuration.backgroundCheckTemplatesById;
export const selectBackgroundCheckTemplates = (state: IStore) =>
    Object.values(selectBackgroundCheckTemplatesById(state));
export const selectBackgroundCheckTemplateById = (id = '') => (state: IStore) =>
    selectBackgroundCheckTemplatesById(state)[id];
export const selectIsBackgroundCheckTemplatesLoading = (state: IStore) =>
    state.configuration.isBackgroundCheckTemplatesLoading;
export const selectBackgroundCheckTemplatesSortedByName = (
    createSelectorSortedByNameFromById<IBackgroundCheckTemplate>(selectBackgroundCheckTemplatesById)
);

/**
 * Cost Center
 */
export const selectCostCentersById = (state: IStore) => state.configuration.costCentersById;
export const selectCostCenters = (state: IStore) => Object.values(selectCostCentersById(state));
export const selectCostCenterById = (id?: string | null) => (state: IStore) => selectCostCentersById(state)[id || ''];
export const selectCostCentersIsLoading = (state: IStore) => state.configuration.costCentersIsLoading;

/**
 * Job Numbers
 */
export const selectJobNumbersById = (state: IStore): Record<string, IJobNumber> => state.configuration.jobNumbersById;
export const selectJobNumberById = (id?: string | null) => (state: IStore) =>
    selectJobNumbersById(state)[id || ''];

/**
 * Job Numbers User Types
 */
export const selectJobNumbersUserTypesById = (state: IStore) => state.configuration.jobNumbersUserTypesById;
export const selectJobNumbersUserTypes = (state: IStore): IJobNumberUserType[] =>
    Object.values(selectJobNumbersUserTypesById(state));
export const selectJobNumbersUserTypeById = (id?: string | null) => (state: IStore) =>
    selectJobNumbersUserTypesById(state)[id || ''];

import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { selectClientRoles } from 'store/entities/users/selectors';

interface IClientRoleSelectProps extends IFormFieldProps, IFormSelect {
    clientId?: string | null;
    blockNameList?: string[];
}

export default function ClientRoleSelect({ clientId, blockNameList = [], ...props }: IClientRoleSelectProps) {
    const roles = Object.values(useSelector(selectClientRoles)).filter(
        role => (role.client_id === clientId || role.client_id === null) && !blockNameList?.includes(role.name),
    );

    return (
        <FormSelect
            getKey={role => role.id}
            getText={role => role.name}
            options={roles}
            {...props}
        />
    );
}

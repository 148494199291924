import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { ILocation } from 'shared/models/Location';
import { IFormFieldProps } from '../formFields/models';
import {
    selectAssignmentsById,
    selectLocations,
} from 'store/entities/configuration/configurationSelectors';
import { IFormSelect } from './model';
import { selectCurrentUser } from 'store/components/auth/selectors';

interface ILocationProps extends IFormFieldProps, IFormSelect {
    positionId?: string;
    userId?: string | null;
}

export function LocationByAssignments({ positionId, userId = null, ...props }: ILocationProps) {
    const currentUser = useSelector(selectCurrentUser);
    const assignments = useSelector(selectAssignmentsById);
    const allLocation = useSelector(selectLocations);
    const filterByUserId = userId === null ? currentUser?.id : userId;

    const locationIds = Object.values(assignments)
        .filter(item => item.location_id && item.user_id === filterByUserId)
        .map(({ location_id }) => location_id);

    let locations = allLocation.filter(({ id }) => locationIds.includes(id));

    if (positionId) {
        locations = locations.filter(
            location => location.positions?.includes(positionId),
        );
    }

    return (
        <FormSelect
            getKey={(location: ILocation) => location.id}
            getText={(location: ILocation) => location.name}
            options={locations}
            {...props}
        />
    );
}

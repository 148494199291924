import React, { MouseEvent } from 'react';
import { IconButton, useTheme } from '@material-ui/core';
import useActionIconStyles from './ActionIconStyles';
import { SvgIconComponent } from '@material-ui/icons';

interface IActionIconProps {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    Icon: SvgIconComponent;
    htmlColor?: string;
}

export default function ActionIcon({ onClick, Icon, htmlColor }: IActionIconProps) {
    const initialClasses = useActionIconStyles();
    const theme = useTheme();
    return (
        <IconButton onClick={onClick} classes={{ root: initialClasses.button }}>
            <Icon
                className={`${initialClasses.actionIcon}`}
                htmlColor={htmlColor || theme.palette.primary.main}
            />
        </IconButton>
    );
}

import React from 'react';
import { useSelector } from 'react-redux';
import FormAutocomplete from 'shared/components/autocomplete/FormAutocomplete';
import { IPosition } from 'shared/models/Position';
import { IFormFieldProps } from '../formFields/models';
import {
    selectPositions,
} from 'store/entities/configuration/configurationSelectors';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';

interface IPositionProps extends IFormFieldProps {}

export const PositionAutocomplete = (props: IPositionProps) => {
    const allPositions = useSelector(selectPositions);
    const selectedClientId = useSelector(selectCurrentClientId);
    const positions = allPositions.filter(position => position.client_id === selectedClientId);

    return (
        <FormAutocomplete
            getText={(position: IPosition) => position.name}
            options={positions}
            {...props}
        />
    );
};

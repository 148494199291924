import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { setHeaderContent } from 'modules/home/header/Header';
import OfferLetterTabs from 'modules/offerLetter/components/OfferLetterTabs';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { FeatureSwitches } from 'utils/featureSwitches';
import { getSpecialUsersAction } from '../../store/entities/users/actions';
import { Permission } from '../../store/components/auth/authModels';
import { selectCurrentClientId } from '../../store/entities/clients/clientsSelectors';

interface IOfferLetterLayout {
    children?: React.ReactNode;
}

export const OfferLetterLayout = ({ children }: IOfferLetterLayout) => {
    useTitleUpdate('Offer Letter');
    const clientId = useSelector(selectCurrentClientId);
    const dispatch = useDispatch();

    useEffect(() => {
        setHeaderContent(
            <Box display="flex" alignItems="flex-end">
                <OfferLetterTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, []);
    useEffect(() => {
        dispatch(getSpecialUsersAction.init({
            purpose: Permission.ApproveSheets,
            client_id: clientId || undefined,
        }));
    }, [dispatch, clientId]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableOfferLetters}>
            {children}
        </FeatureSwitch>
    );
};

import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import useSheetsInProgressStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import UpperButton from 'shared/components/buttons/UpperButton';

import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSubmitSheetStyles } from 'shared/components/modals/SubmitSheets/SubmitSheetsStyles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useModal } from 'shared/utils/hooks/useModal';
import { useIsMobile } from 'shared/utils/hooks/media';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useSelector } from 'react-redux';
import { selectClientIsGovernmentContract } from 'store/entities/clients/clientsSelectors';

interface ISubmitSheetsProps {
    entryTypes?: Array<EntryType>;
    submitAction?: (entryTypes: Array<EntryType>) => void;
    status?: StatusNames;
    isPayroll?: boolean;
}

export default function SubmitSheets({ entryTypes, submitAction, status, isPayroll = false }: ISubmitSheetsProps) {
    const isMobile = useIsMobile();
    const classes = useSubmitSheetStyles();
    const entryGridClasses = useSheetsInProgressStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();

    const isGovernmentContract = useSelector(selectClientIsGovernmentContract);
    const [governmentalConfirmationEnabled] = useFeature(FeatureSwitches.showGovernmentalConfirmationOnSubmitSheet);
    const showGovernmentalConfirmation = governmentalConfirmationEnabled && isGovernmentContract;

    const [isAgreed, setAgreed] = useState(false);
    const [isGovernmentalConfirmationAgreed, setGovernmentalConfirmationAgreed] = useState(false);
    const [time, setTime] = useState(false);
    const [expense, setExpense] = useState(false);

    const onAgreedChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setAgreed(target.checked);
    }, [setAgreed]);

    const onGovernmentalConfirmationChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setGovernmentalConfirmationAgreed(target.checked);
    }, [setGovernmentalConfirmationAgreed]);

    const onTimeChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setTime(target.checked);
    }, [setTime]);

    const onExpenseChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setExpense(target.checked);
    }, [setExpense]);

    let title: string;
    let sheetsText: string;
    const actionText = status === StatusNames.REJECTED ? 'Resubmit' : 'Submit';
    const approveActionText = isPayroll ? '' : ' for Approval';

    if (entryTypes) {
        const titleParts = [];
        const sheetsParts = [];

        if (entryTypes.includes(EntryType.TIME)){
            titleParts.push('Timesheet');
            sheetsParts.push('timesheets');
        }
        if (entryTypes.includes(EntryType.EXPENSE)){
            titleParts.push('Expenses');
            sheetsParts.push('expense sheets');
        }
        title = `${actionText} ${titleParts.join(' & ')}${approveActionText}`;
        sheetsText = sheetsParts.join(' and ');
    } else {
        title = `${actionText} Timesheet & Expenses${approveActionText}`;
        sheetsText = 'timesheets and expense sheets';
    }

    const onSubmit = useCallback(() => {
        const entriesStatuses = { time, expense };
        const entryTypesLocal: Array<EntryType> = [EntryType.TIME, EntryType.EXPENSE]
            .filter(entry => entriesStatuses[entry]);

        submitAction && submitAction(entryTypesLocal);
    }, [time, expense, submitAction]);

    const onSubmitConfirm = useCallback(() => {
        onSubmit();
        onModalClose();
    }, [onSubmit, onModalClose]);

    const onActionButtonClick = useCallback(() => {
        if (status === StatusNames.APPROVED) {
            onSubmit();
        } else {
            onModalOpen();
        }
    }, [status, onSubmit, onModalOpen]);

    return (
        <>
            <UpperButton
                label={`${actionText}${!isMobile ? approveActionText : ''}`}
                onClick={onActionButtonClick}
                customClasses={{ upperButtonClass: entryGridClasses.actionButton }}
                disabled={!submitAction}
            />
            <ModalDialog
                isOpened={isModalOpened}
                onClose={onModalClose}
                title={title}
                modalProps={{ customClasses: { content: classes.paper, title: classes.title } }}
                sidebarProps={{ anchor: 'bottom' }}
            >
                <Box className={classes.content}>
                    <FormControlLabel
                        control={(
                            <Checkbox className={classes.warningCheckbox} color="primary"
                                onChange={onAgreedChange} checked={isAgreed}/>
                        )}
                        label="I certify that this report is true and accurately reflects time
                        and expenses actually worked or incurred."
                        classes={{
                            root: classes.warningWrapper,
                            label: clsx(classes.warningText, classes.typography),
                        }}
                    />
                    <Box mt={2}>
                        {showGovernmentalConfirmation && (
                            <FormControlLabel
                                control={(
                                    <Checkbox className={classes.warningCheckbox}
                                        color="primary"
                                        onChange={onGovernmentalConfirmationChange}
                                        checked={isGovernmentalConfirmationAgreed}/>
                                )}
                                label={`By signing this form, I understand that the expenses included herein are actual expenses
                            incurred during performance of the referenced contract and any misrepresentation may subject myself
                            to criminal penalties if I knowingly present a false, fictitious, or fraudulent travel claim under
                            18 U.S.C. 287 and 1001.`}
                                classes={{
                                    root: classes.warningWrapper,
                                    label: clsx(classes.warningText, classes.typography),
                                }}
                            />
                        ) }
                    </Box>

                    {!entryTypes && (
                        <>
                            <Typography
                                className={clsx(classes.typography, classes.warning)}
                                data-testid="modal-request-test-about-approve"
                            >
                                Please select the {sheetsText} you would like to submit for manager approval.
                            </Typography>
                            <Box display="flex" flexDirection="column"
                                mt={2} ml={4}
                            >
                                <FormControlLabel control={(
                                    <Checkbox color="primary" onChange={onTimeChange}
                                        checked={time}/>
                                )}
                                label="submit timesheet"/>

                                <FormControlLabel control={(
                                    <Checkbox color="primary" onChange={onExpenseChange}
                                        checked={expense}/>
                                )}
                                label="submit expenses"/>
                            </Box>
                        </>
                    )}
                    <Typography className={clsx(classes.typography, classes.warning)}>
                        Are you sure the sheets are complete for the week?
                    </Typography>
                    <Box mt={4}>
                        <UpperButton label="Submit" onClick={onSubmitConfirm}
                            disabled={!isAgreed
                            || !(entryTypes || time || expense )
                            || (showGovernmentalConfirmation && !isGovernmentalConfirmationAgreed)}
                            customClasses={{ upperButtonClass: entryGridClasses.actionButton }}
                        />
                        <Button onClick={onModalClose} color="primary"
                            className={clsx(entryGridClasses.secondaryButton, entryGridClasses.actionButton)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </ModalDialog>
        </>
    );
}

export enum LocalStorageKey {
    ACCESS_TOKEN = 'access_token',
    REFRESH_TOKEN = 'refresh_token',
    ID_TOKEN = 'id_token',
}

export class AuthenticationError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(message: string) {
        super(message);
    }
}

export interface IAuthData {
    access_token: string;
    refresh_token: string;
    id_token: string;
}

export interface IWithUserInfo {
    user_id: string;
}

export const authRequestTimeout = 30000;

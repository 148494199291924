import React from 'react';
import clsx from 'clsx';
import { List, ListItem, Typography } from '@material-ui/core';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { useSheetDetail } from 'shared/utils/formatters/sheetDetail';
import { AvailableDetailConfiguration } from 'store/entities/clients/clientsModel';
import { useEntryEditStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectDetailConfiguration } from 'store/entities/clients/clientsSelectors';

interface ISheetInfoProps {
    entryType: EntryType;
    sheetId: string;
}

export default function SheetInfo({ entryType, sheetId }: ISheetInfoProps) {
    const classes = useEntryEditStyles();
    const sidebarClasses = useSidebarStyles();

    const configuration = useSelector(selectDetailConfiguration(
        entryType === EntryType.TIME
            ? AvailableDetailConfiguration.EditTimeEntry : AvailableDetailConfiguration.EditExpenseEntry,
    ));
    const data = useSheetDetail(sheetId, configuration.info);

    return (
        <section className={clsx(sidebarClasses.sidebarElement, classes.timesheetInfo )}>
            <List>
                {data.filter(item => !!item.value).map(item => (
                    <ListItem key={item.title} classes={{ root: classes.timesheetInfoItem }}>
                        <Typography display="inline" variant="subtitle2">{item.title}:</Typography>
                        <Typography display="inline" variant="body2">{item.value}</Typography>
                    </ListItem>
                ))}
            </List>
        </section>
    );
}

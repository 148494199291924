import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntryType, ISheetStatusesId } from 'shared/models/sheet/Sheet';
import { Permission } from 'store/components/auth/authModels';
import { getSheetDetails } from 'store/components/sheetDetails/sheetDetailsActions';
import { EditSheetView } from 'modules/timeAndExpense/components/EditSheet/EditSheetView';
import { updateSheetsStatus } from 'store/entities/timesheet/actions/statuses';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectSheetsIsLoading } from 'store/entities/timesheet/selectors';

interface ISheetsRecalledViewProps {
    sheetId: string;
    entryType: EntryType;
    onClose: () => void;
    currentSheetStatusesId: ISheetStatusesId;
}

export default function EditSheetAfterSubmit({
    sheetId,
    entryType,
    onClose,
    currentSheetStatusesId,
}: ISheetsRecalledViewProps) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSheetDetails.init({ sheetId, entryType, permission: Permission.SubmitSheets }));
    }, [dispatch, sheetId, entryType]);

    const onUpdateSheetStatus = useCallback(() => {
        dispatch(updateSheetsStatus.init({
            statusName: StatusNames.SUBMITTED,
            sheetsEntryTypes: {
                [sheetId]: entryType,
            },
        }));
        onClose();
    }, [dispatch, sheetId, entryType, onClose]);

    const isLoading = useSelector(selectSheetsIsLoading);

    return (
        <EditSheetView
            onClose={onClose}
            currentSheetStatusesId={currentSheetStatusesId}
            onSheetSubmit={onUpdateSheetStatus}
            timeSheetId={entryType === EntryType.TIME ? sheetId : undefined}
            expenseSheetId={entryType === EntryType.EXPENSE ? sheetId : undefined}
            isLoading={isLoading}
        />
    );
}

import baseApi from 'shared/utils/baseApi';
import { IBackgroundCheckTemplate } from 'store/entities/configuration/configurationModel';
import {
    ICreateTemplatePayload,
    ITemplateFormValues,
} from 'modules/settings/submodules/clients/components/PhysicalDemandsAndWorkingConditions/AddTemplate/Modal/models';
import { withClientId } from 'store/utils/withClientId';

const usersPath = 'client-users';
const entityPath = 'background_checks';

export const backgroundCheckTemplateApi = {
    async createBackgroundCheckTemplate(request: ICreateTemplatePayload): Promise<IBackgroundCheckTemplate> {
        const { data } = await baseApi.create<ICreateTemplatePayload, IBackgroundCheckTemplate>(
            usersPath, entityPath, withClientId(request),
        );
        return data;
    },
    async updateBackgroundCheckTemplate(
        id: string,
        request: ITemplateFormValues,
    ): Promise<IBackgroundCheckTemplate> {
        const { data } = await baseApi.patch<ITemplateFormValues, IBackgroundCheckTemplate>(
            `${usersPath}/${entityPath}/${id}`, request,
        );
        return data;
    },
    async deleteBackgroundCheckTemplate(id: string): Promise<string> {
        const { data } = await baseApi.delete<{ status: string }>(
            `/${usersPath}/${entityPath}`, id,
        );
        return data.status;
    },
};

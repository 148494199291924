import React, { useCallback } from 'react';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { selectLocationsById, selectPositionsById } from 'store/entities/configuration/configurationSelectors';
import { useDispatch, useSelector } from 'react-redux';
import FilterChip from 'shared/components/filters/FilterChip';
import { IEntity } from 'shared/models/Entity';
import { Box } from '@material-ui/core';
import { useOfferLetterFilterChipsStyles } from 'modules/offerLetter/OfferLetterHeader/OfferLetterFilterChipsStyles';
import { selectOfferLetterFilters, selectStartDates } from 'modules/offerLetter/store/selectors';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { IOfferLetterFilters } from 'modules/offerLetter/store/model';
import { selectUsersById } from 'store/entities/users/selectors';

interface INamedEntity extends IEntity {
    name: string;
}

const getTextBase = (entity: INamedEntity) => entity.name;

export default function OfferLetterFiltersChips() {
    const classes = useOfferLetterFilterChipsStyles();
    const filters = useSelector(selectOfferLetterFilters);
    const dispatch = useDispatch();

    const onDelete = useCallback((name: keyof IOfferLetterFilters) => {
        dispatch(setOfferLetterFilters({
            ...filters,
            [name]: '',
        }));
    }, [dispatch, filters]);

    const positionsById = useSelector(selectPositionsById);
    const locationsById = useSelector(selectLocationsById);
    const usersById = useSelector(selectUsersById);
    const startDates = useSelector(selectStartDates);

    return (
        <Box ml={3}>
            <FilterChip values={positionsById} name="positionId"
                id={filters.positionId} onDelete={onDelete}
                getText={getTextBase} classes={classes}
            />
            <FilterChip values={locationsById} name="locationId"
                id={filters.locationId} onDelete={onDelete}
                getText={getTextBase} classes={classes}
            />
            <FilterChip values={startDates} name="startDate"
                id={filters.startDate} onDelete={onDelete}
                getText={startDate => startDate} classes={classes}
            />
            <FilterChip
                values={usersById}
                name="creatorId"
                id={filters.creatorId}
                onDelete={onDelete}
                getText={(user: IUserInfo) => `Created by ${getUserName(user)}`}
                classes={classes}
            />
        </Box>
    );
}

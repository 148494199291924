import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import { selectDealById } from 'store/entities/configuration/configurationSelectors';
import DealFormWrapper from 'modules/settings/submodules/clients/deals/components/DealForm/DealFormWrapper';
import {
    selectEditDealId,
    selectIsDealUpdating,
} from 'modules/settings/submodules/clients/deals/components/EditDeal/store/selectors';
import { IDealFormValues } from 'modules/settings/submodules/clients/deals/components/DealForm/model';
import { setEditDealId, updateDeal } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';
import { IUpdateDealPayloadData } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/models';

export default function EditDealModal() {
    const modalClasses = useSettingsFormModalStyles();
    const editDealId = useSelector(selectEditDealId) || '';
    const isLoading = useSelector(selectIsDealUpdating);
    const editDeal = useSelector(selectDealById(editDealId));
    const dispatch = useDispatch();
    const onSave = useCallback((formValues: IDealFormValues) => {
        const { deal_type_id, continueJobNumberCreation, ...valuesCleared } = formValues;
        const data = {
            ...valuesCleared,
            type_id: deal_type_id,
            continueJobNumberCreation,
        } as IUpdateDealPayloadData;

        dispatch(updateDeal.init({
            id: editDealId,
            data,
        }));
    }, [dispatch, editDealId]);
    const onClose = useCallback(() => {
        dispatch(setEditDealId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Deal"
            isOpened={Boolean(editDealId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <DealFormWrapper
                    submitTitle="Save"
                    submitAndGoToJobNumberTitle="Save & Add Job Number"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    deal={editDeal}
                />
            </Box>
        </ModalDialog>
    );
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { getPositions } from 'store/entities/configuration/configurationAction';
import { FeatureSwitches } from 'utils/featureSwitches';
import { PayCodesTable } from 'modules/settings/submodules/clients/payCodes/components/PayCodesTable/PayCodesTable';

export const ClientPayCodesList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPositions.init());
    }, [dispatch]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableClientPayCodesManagement}>
            <PayCodesTable/>
        </FeatureSwitch>
    );
};

import AuthSagas from './auth/authSagas';
import AddEntrySagas from './addEntry/addEntrySagas';
import SheetsGridSagas from 'store/components/workingSheets/workingSheetsSagas';
import SheetDetailsSagas from './sheetDetails/sheetDetailsSagas';
import ExpenseApprovalSagas from './expensesApproval/expensesApprovalSagas';
import TimeApprovalSagas from 'store/components/timeApproval/timeApprovalSagas';
import RegistrationSagas from 'store/components/registration/sagas';
import EmailConfirmationSagas from 'store/components/emailConfirmation/sagas';
import PasswordResettingSagas from 'store/components/resetPassword/sagas';

export default [
    ...AuthSagas,
    ...AddEntrySagas,
    ...SheetDetailsSagas,
    ...SheetsGridSagas,
    ...ExpenseApprovalSagas,
    ...TimeApprovalSagas,
    ...RegistrationSagas,
    ...EmailConfirmationSagas,
    ...PasswordResettingSagas,
];

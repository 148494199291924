import { combineReducers } from 'redux';
import { ExpenseSheetActions, updateExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';
import { TimeSheetActions, updateTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import { singleValueReducer } from 'store/reducerUtils';
import { setEditEntryId } from 'modules/timeAndExpense/components/EditEntry/store/actions';

const editEntryId = singleValueReducer<string | null>(setEditEntryId.action, null);
const isEntryUpdating = (state = false, action: ExpenseSheetActions | TimeSheetActions): boolean => {
    switch (action.type) {
        case updateExpenseEntry.initType:
        case updateTimeEntry.initType:
            return true;
        case updateExpenseEntry.successType:
        case updateTimeEntry.successType:
        case updateExpenseEntry.errorType:
        case updateTimeEntry.errorType:
            return false;
        default:
            return state;
    }
};

export const editEntry = combineReducers({
    editEntryId,
    isEntryUpdating,
});

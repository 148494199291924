import { Box, Grid, Typography } from '@material-ui/core';
import { useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import React from 'react';
import EnumFormSelect from 'shared/components/formFields/EnumFormSelect';
import FormRadioButtonGroup from 'shared/components/selects/FormRadioButtonGroup';
import { IDealType } from 'shared/models/DealType';
import { FeeType, FeeTypeDisplayValues, TimesheetSettings } from 'shared/models/JobNumber';
import NumberField from 'shared/components/formFields/NumberField';

interface IBillingInfoFormSectionProps {
    timesheetSettings?: TimesheetSettings;
    feeType?: FeeType;
    dealType: IDealType | null;
}

export const BillingInfoFormSection = ({
    feeType,
    dealType,
    timesheetSettings = TimesheetSettings.Hourly,
}: IBillingInfoFormSectionProps) => {
    const classes = useSettingsFormStyles();
    return (
        <>
            {dealType?.billing_info_required && timesheetSettings === TimesheetSettings.Hourly && (
                <Box className={classes.formBlockCard} style={{ minHeight: 208 }}>
                    <Typography
                        variant="subtitle2"
                        className={classes.formBlockCardTitle}
                    >
                        Billing Info:
                    </Typography>
                    <Box m={2} ml={0}>
                        <EnumFormSelect
                            name="billingInfo"
                            values={FeeType}
                            className={classes.smallInput}
                            displayValues={FeeTypeDisplayValues}
                            InputComponent={FormRadioButtonGroup}
                        />
                    </Box>
                    <Grid container spacing={2}>
                        {feeType === FeeType.Blended ? (
                            <>
                                <Grid item xs={6}>
                                    <NumberField
                                        name="blendedAmount"
                                        outerLabel="$ amount"
                                        className={classes.smallInput}
                                        inputProps={{
                                            allowNegative: false,
                                        }}
                                        min={0}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={4}>
                                    <NumberField
                                        name="max"
                                        outerLabel="Max"
                                        className={classes.smallInput}
                                        inputProps={{
                                            allowNegative: false,
                                        }}
                                        min={0}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        name="min"
                                        outerLabel="Min"
                                        className={classes.smallInput}
                                        inputProps={{
                                            allowNegative: false,
                                        }}
                                        min={0}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        name="miCredit"
                                        outerLabel="MICredit"
                                        className={classes.smallInput}
                                        inputProps={{
                                            allowNegative: false,
                                        }}
                                        min={0}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            )}
        </>
    );
};

import React, { useCallback, useRef } from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { Nullable } from '../../../../@types/types';
import IntervalDataField, { IIntervalDataFieldClasses } from '../intervalField/IntervalDataField';
import { InOutTimeFieldPicker } from './InOutTimeFieldPicker';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { calculateDurationFromStartEndValue, compareInOutTimeValue } from 'shared/models/DateTime';

export interface IInOutTimeFieldProps extends IFormFieldProps {
    showTotalInput?: boolean;
    showNote?: boolean;
    classes?: IIntervalDataFieldClasses;
    entryType: QuantityType;
}

const getFormattedValue = (startValue: Nullable<string>, endValue: Nullable<string>) => (
    startValue && endValue ? calculateDurationFromStartEndValue(startValue, endValue) : 'h:mm'
);

function InOutTimeField({
    name,
    className,
    disabled = false,
    showTotalInput = true,
    showNote = false,
    classes,
    entryType,
}: IInOutTimeFieldProps) {
    const { current: inputs } = useRef([
        {
            name: 'timeIn',
            label: 'Time In',
        },
        {
            name: 'timeOut',
            label: 'Time Out',
        },
    ]);

    const convert = useCallback((value: string) => value, []);
    const compare = useCallback(compareInOutTimeValue, []);

    return (
        <IntervalDataField
            calculate={getFormattedValue}
            Input={InOutTimeFieldPicker}
            inputs={inputs}
            name={name}
            className={className}
            disabled={disabled}
            showTotalInput={showTotalInput}
            showNote={showNote}
            convert={convert}
            compare={compare}
            classes={classes}
            entryType={entryType}
        />
    );
}

export default InOutTimeField;

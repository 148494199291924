import { getPreviousPayPeriod } from 'modules/payrollProcessorHub/components/PayPeriodSelect/utils';
import {
    changeSheetGroupStatus,
    getGroupedSheetCalculations,
    getGroupedSheetPdf,
    getPayrollPayPeriods,
    getPrePayrollReport,
    getSheetEditInfo,
    getSheetSummary,
    INCREMENT_PAYROLL_PAGE,
    initSheetGroupPayroll,
    LOAD_GROUPED_SHEET_NEXT_PAGE,
    LOAD_GROUPED_SHEETS,
    loadGroupedSheets,
    sendSheetGroupReminder,
    setPostPayrollReports,
    setPayrollProcessorFilter,
} from 'modules/payrollProcessorHub/store/actions';
import { payrollProcessorHubApi } from 'modules/payrollProcessorHub/store/api';
import { uniq } from 'lodash';
import {
    getSheetStatusByTab,
    IGroupedSheetCalculation,
    IGroupedSheetCalculationRequest, IGroupedSheetPayrollRequest,
    IGroupedSheetSummaryRequest, ISheetGroupId, ISheetGroupIdRequest,
} from 'modules/payrollProcessorHub/store/model';
import {
    selectGroupedSheetsPagination,
    selectPayrollFilter,
    selectSheetsGroupById,
    selectSheetsGroupsByIds,
} from 'modules/payrollProcessorHub/store/selectors';
import { EntryType, IExpenseSheetBackend, ISheetCommonBackend, ITimeSheetBackend } from 'shared/models/sheet/Sheet';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { getClientFieldsConfiguration, setClientId } from 'store/entities/clients/clientsAction';
import { selectClientTimeAndPayConfigurationByClientId } from 'store/entities/clients/clientsSelectors';
import {
    getActivities,
    getAssignments,
    getDeals,
    getJobNumbers,
    getPositions,
    getProjectsAssignments,
    getSubmittingOrgs,
    getTasks,
} from 'store/entities/configuration/configurationAction';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import { IUpdateSheetStatus, StatusNames } from 'store/entities/timesheet/models/Status';
import { getUsers } from 'store/entities/users/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, select, take, takeLatest,
} from 'typed-redux-saga';
import { downloadFileSaga, navigateWithClientSaga } from 'store/components/router/routerSagas';
import { AppClient } from 'utils/constants';
import { FeatureSwitches } from 'utils/featureSwitches';
import { optimizely } from 'utils/optimizely';
import { notificationApi } from '../../notificationCenter/store/api';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { postPayrollResponseMapper, prePayrollResponseMapper } from './mappers';

const getPayPeriodValueOrDefault = (payPeriod: string | null): string | undefined => {
    if (payPeriod) {
        return payPeriod;
    }
    const disableDefaultValue = optimizely.isFeatureEnabled(FeatureSwitches.disablePayPeriodEndFilterDefaultValue);
    return disableDefaultValue ? undefined : getPreviousPayPeriod();
};

export function* loadGroupedSheetSaga() {
    yield put(getGroupedSheetCalculations.init());
    const { page, page_size } = yield select(selectGroupedSheetsPagination);
    const {
        clientId,
        employeeId,
        status,
        payPeriodEnd,
        dealId,
        jobNumber,
        managerId,
    } = yield select(selectPayrollFilter);
    const request: IGroupedSheetCalculationRequest = {
        page_number: page,
        page_size,
        client_id: clientId,
        user_id: employeeId,
        payroll_status: getSheetStatusByTab(status),
        pay_period_ends_at: getPayPeriodValueOrDefault(payPeriodEnd),
        deal_number_id: dealId || undefined,
        job_number: jobNumber?.length ? jobNumber : undefined,
        latest_approver_user_id: managerId || undefined,
    };
    const response = yield* call(payrollProcessorHubApi.getGroupedSheetCalculation, request);
    yield put(getPositions.success(response.linked.positions));
    yield put(getUsers.success(response.linked.users));
    yield put(getAssignments.success(response.linked.assignments));
    yield put(getGroupedSheetCalculations.success(response.calculations));

    if (process.env.REACT_APP_CLIENT === AppClient.RTI && response.linked.assignments.length) {
        const assignment_ids = response.linked.assignments.map(assignment => assignment.id).join(',');
        yield put(getProjectsAssignments.init({ assignment_ids }));
        yield put(getTasks.init({ assignment_ids }));
    }
}

function* loadGroupedSheetWatcher() {
    yield takeLatest(
        [
            LOAD_GROUPED_SHEETS,
            LOAD_GROUPED_SHEET_NEXT_PAGE,
            setPayrollProcessorFilter.action,
            changeSheetGroupStatus.successType,
            INCREMENT_PAYROLL_PAGE,
        ],
        withBackendErrorHandler(
            loadGroupedSheetSaga,
            getGroupedSheetCalculations.error,
            'Unable to load grouped sheets',
        ),
    );
}

export function* loadGroupedSheetSummarySaga() {
    yield put(getSheetSummary.init());
    const { clientId, employeeId, status, payPeriodEnd } = yield select(selectPayrollFilter);
    const request: IGroupedSheetSummaryRequest = {
        client_id: clientId,
        user_id: employeeId,
        payroll_status: getSheetStatusByTab(status),
        pay_period_ends_at: getPayPeriodValueOrDefault(payPeriodEnd),
    };
    const response = yield* call(payrollProcessorHubApi.getSheetSummary, request);
    yield put(getSheetSummary.success(response));
}

function* loadGroupedSheetSummaryWatcher() {
    yield takeLatest(
        [
            LOAD_GROUPED_SHEETS,
            setPayrollProcessorFilter.action,
            changeSheetGroupStatus.successType,
        ],
        withBackendErrorHandler(
            loadGroupedSheetSummarySaga,
            getSheetSummary.error,
            'Unable to load sheet summary',
        ),
    );
}

export function* getEditSheetGenworthConfigurationSaga(jobNumberId: string) {
    yield put(getJobNumbers.init({ ids: [jobNumberId] }));
    yield put(getDeals.init());
    yield put(getSubmittingOrgs.init());
}

function* getEditSheetInfoSaga({ payload }: ReturnType<typeof getSheetEditInfo.init>) {
    const { timeSheetId, expenseSheetId } = payload;
    const sheetGroup = yield select(selectSheetsGroupById(payload));
    if (!sheetGroup) {
        browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        return;
    }
    yield put(setClientId(sheetGroup?.client_id));
    yield put(getActivities.init({
        assignment_ids: sheetGroup.assignment_id,
    }));
    const sheets = yield* all({
        timeSheet: timeSheetId && call(timeApi.getSheetById, timeSheetId),
        expenseSheet: expenseSheetId && call(expenseApi.getSheetById, expenseSheetId),
    });
    const jobNumberId = (sheets.timeSheet as ISheetCommonBackend)?.job_number_id
        || (sheets.expenseSheet as ISheetCommonBackend)?.job_number_id;
    if (jobNumberId) {
        yield* getEditSheetGenworthConfigurationSaga(jobNumberId);
    }
    if (sheets.timeSheet) {
        yield* put(loadTimeSheetsWithEntries.success([sheets.timeSheet as ITimeSheetBackend]));
    }
    if (sheets.expenseSheet) {
        yield* put(loadExpenseSheetsWithEntries.success([sheets.expenseSheet as IExpenseSheetBackend]));
    }
    yield put(getProjectsAssignments.init({ assignment_ids: sheetGroup.assignment_id }));

    yield put(getSheetEditInfo.success());
}

function* getEditSheetInfoWatcher() {
    yield takeLatest(
        getSheetEditInfo.initType,
        withBackendErrorHandler(
            getEditSheetInfoSaga,
            getSheetEditInfo.error,
            'Unable to load sheet information',
        ),
    );
}

export function getClientSheetStatusIdByName(
    type: EntryType,
    clientId: string,
    statusName: StatusNames,
): Promise<string | undefined> {
    const api = type === EntryType.TIME ? timeApi : expenseApi;
    return api.getAvailableStatuses({
        client_id: clientId,
    }).then(statuses => statuses.find(status => status.name === statusName)?.id);
}

export function* updateTypedSheetStatusSaga(
    type: EntryType,
    statusName: StatusNames,
    groupsIdsWithNote: {
        groupId: ISheetGroupId;
        note?: string;
    }[],
) {
    const groups = yield select(selectSheetsGroupsByIds(groupsIdsWithNote.map(item => item.groupId)));
    const clientIds: string[] = uniq(groups.map((group: IGroupedSheetCalculation) => group.client_id));
    const sheetNotesById = groupsIdsWithNote.reduce(
        (mem, groupNote) => {
            const sheetId = type === EntryType.TIME ? groupNote.groupId.timeSheetId : groupNote.groupId.expenseSheetId;
            if (sheetId) {
                mem[sheetId] = groupNote.note;
            }
            return mem;
        }, {} as Record<string, string | undefined>);
    const statusIdByClientId = yield* all(clientIds.reduce(
        (mem: Record<string, any>, clientId: string) => {
            mem[clientId] = call(getClientSheetStatusIdByName, type, clientId, statusName);
            return mem;
        },
        {} as Record<string, any>,
    ));
    const updateStatusesPayload: IUpdateSheetStatus[] = groups.map((group: IGroupedSheetCalculation) => {
        const sheetId = type === EntryType.TIME ? group.time_sheet_id : group.expense_sheet_id;
        return {
            id: sheetId,
            status_id: statusIdByClientId[group.client_id || ''],
            notes: sheetNotesById[sheetId || ''],
        };
    }).filter((sheet: IUpdateSheetStatus) => sheet.id && sheet.status_id);

    if (!updateStatusesPayload.length) {
        return;
    }

    const api = type === EntryType.TIME ? timeApi : expenseApi;
    return api.updateSheetsStatuses({
        sheets: updateStatusesPayload,
    });
}

export function* changeSheetGroupStatusSaga({ payload }: ReturnType<typeof changeSheetGroupStatus.init>) {
    const { status, groups: groupsIdsWithNote } = payload;

    browserHistory.push(routes.PAYROLL_PROCESSOR_HUB.SHEETS);
    const result = yield* all([
        call(updateTypedSheetStatusSaga, EntryType.TIME, status, groupsIdsWithNote),
        call(updateTypedSheetStatusSaga, EntryType.EXPENSE, status, groupsIdsWithNote),
    ]);
    yield* all(result);

    yield* put(changeSheetGroupStatus.success());
}

function* changeSheetGroupStatusSagaWatcher() {
    yield takeLatest(
        changeSheetGroupStatus.initType,
        withBackendErrorHandler(
            changeSheetGroupStatusSaga,
            changeSheetGroupStatus.error,
            'Unable to update sheet status',
        ),
    );
}

export function* initSheetGroupPayrollSaga({ payload: { sheets } }: ReturnType<typeof initSheetGroupPayroll.init>) {
    const params: IGroupedSheetPayrollRequest = {
        sheets_for_payroll: sheets.map(sheet => ({
            pay_date: sheet.payDate,
            client_id: sheet.clientId,
            time_sheet_id: sheet.timeSheetId,
            expense_sheet_id: sheet.expenseSheetId,
        })),
    };

    const response = yield* call(payrollProcessorHubApi.initSheetGroupPayroll, params);
    yield* put(initSheetGroupPayroll.success());
    if (response.status === 201 && response.data) {
        // If payroll returns report then create post process report
        yield* put(setPostPayrollReports(postPayrollResponseMapper(response.data)));
    } else {
        // else update sheet list
        yield* call(navigateWithClientSaga, routes.PAYROLL_PROCESSOR_HUB.SHEETS);
        yield* put(loadGroupedSheets());
    }
}

function* initSheetGroupPayrollSagaWatcher() {
    yield takeLatest(
        initSheetGroupPayroll.initType,
        withBackendErrorHandler(
            initSheetGroupPayrollSaga,
            initSheetGroupPayroll.error,
            'Unable to initialize payroll',
        ),
    );
}

export function* preInitializeReportSaga({ payload: { sheets } }: ReturnType<typeof getPrePayrollReport.init>) {
    const params: IGroupedSheetPayrollRequest = {
        sheets_for_payroll: sheets.map(sheet => ({
            pay_date: sheet.payDate,
            client_id: sheet.clientId,
            time_sheet_id: sheet.timeSheetId,
            expense_sheet_id: sheet.expenseSheetId,
        })),
    };

    const report = yield* call(payrollProcessorHubApi.getPreInitializeReport, params);
    yield* put(getPrePayrollReport.success(prePayrollResponseMapper(report)));
}

function* preInitializeReportSagaWatcher() {
    yield takeLatest(
        getPrePayrollReport.initType,
        withBackendErrorHandler(
            preInitializeReportSaga,
            getPrePayrollReport.error,
            'Unable to load pre-initialize report',
        ),
    );
}

export function* getGroupedSheetPdfSaga({ payload }: ReturnType<typeof getGroupedSheetPdf.init>) {
    const { timeSheetId, expenseSheetId, fileName } = payload;
    const params: ISheetGroupIdRequest = {
        time_sheet_id: timeSheetId,
        expense_sheet_id: expenseSheetId,
    };
    const result = yield call(payrollProcessorHubApi.getGroupedSheetPdf, params);
    yield call(downloadFileSaga, result, `${fileName}.pdf`);
    yield* put(getGroupedSheetPdf.success(payload));
}

function* getGroupedSheetPdfSagaWatcher() {
    yield takeLatest(
        getGroupedSheetPdf.initType,
        withBackendErrorHandler(
            getGroupedSheetPdfSaga,
            getGroupedSheetPdf.error,
            'Unable to download sheet pdf',
        ),
    );
}

export function* sendSheetGroupReminderSaga({ payload }: ReturnType<typeof sendSheetGroupReminder.init>) {
    const { timeSheetId, expenseSheetId } = payload;
    const params: ISheetGroupIdRequest = {
        time_sheet_id: timeSheetId,
        expense_sheet_id: expenseSheetId,
    };
    yield call(notificationApi.sheetReminders, params);
    yield* put(sendSheetGroupReminder.success(payload));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Reminder was successfully sent.',
        autoHideDuration: 5000,
    }));
}

function* sendSheetGroupReminderSagaWatcher() {
    yield takeLatest(
        sendSheetGroupReminder.initType,
        withBackendErrorHandler(
            sendSheetGroupReminderSaga,
            sendSheetGroupReminder.error,
            'Unable to send reminder',
        ),
    );
}

export function* getPayPeriodsSaga({ payload }: ReturnType<typeof getPayrollPayPeriods.init>) {
    const payPeriods = yield call(payrollProcessorHubApi.getPayPeriods, payload || {});
    yield* put(getPayrollPayPeriods.success(payPeriods));
}

function* getPayPeriodsSagaWatcher() {
    yield takeLatest(
        getPayrollPayPeriods.initType,
        withBackendErrorHandler(
            getPayPeriodsSaga,
            getPayrollPayPeriods.error,
            'Unable to get pay periods',
        ),
    );
}

function* loadClientAdditionalDataForFilterSaga({ payload }: ReturnType<typeof setPayrollProcessorFilter>) {
    const { clientId } = payload;
    if (clientId) {
        const clientConfiguration = yield select(selectClientTimeAndPayConfigurationByClientId(clientId));
        let hasJobNumberConfiguration = clientConfiguration?.hasJobNumberConfiguration || false;
        if (!clientConfiguration) {
            yield put(getClientFieldsConfiguration.init(clientId));
            const getClientFieldsConfigurationActionResult = yield* take(
                [getClientFieldsConfiguration.successType, getClientFieldsConfiguration.errorType],
            );
            if (getClientFieldsConfigurationActionResult.type === getClientFieldsConfiguration.successType) {
                const {
                    payload: getConfigurationPayload,
                } = getClientFieldsConfigurationActionResult as ReturnType<typeof getClientFieldsConfiguration.success>;
                hasJobNumberConfiguration = getConfigurationPayload
                    ?.configuration?.sheet_entry_group_key?.includes('job_number_id') || false;
            }
        }
        if (hasJobNumberConfiguration) {
            // Fetch deals only if client has job number configuration
            yield put(getDeals.init({
                client_id: clientId,
            }));
        }
    }
}

function* loadClientAdditionalDataForFilterWatcher() {
    yield takeLatest(
        setPayrollProcessorFilter.action,
        loadClientAdditionalDataForFilterSaga,
    );
}

export default [
    loadGroupedSheetWatcher,
    loadGroupedSheetSummaryWatcher,
    getEditSheetInfoWatcher,
    changeSheetGroupStatusSagaWatcher,
    initSheetGroupPayrollSagaWatcher,
    getGroupedSheetPdfSagaWatcher,
    sendSheetGroupReminderSagaWatcher,
    preInitializeReportSagaWatcher,
    getPayPeriodsSagaWatcher,
    loadClientAdditionalDataForFilterWatcher,
];

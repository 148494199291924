import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const DealValidationSchema = yup.object().shape({
    deal_type_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    controlling_org_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    submitting_org_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    invoice_recipient_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
    cost_center_id: yup.string().nullable().trim()
        .required(ValidationMessages.REQUIRED),
});

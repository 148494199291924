import { selectExpenseStatusIdByName } from 'store/entities/timesheet/selectors';
import { put, select, take, takeLatest } from 'typed-redux-saga';
import { getExpenseReviewerSheets } from 'store/components/expensesApproval/expensesApprovalActions';
import { loadExpenseSheets } from 'store/entities/timesheet/actions/expenseActions';
import { Permission } from 'store/components/auth/authModels';

function* getExpenseReviewerSheetsSaga({ payload: statusName }: ReturnType<typeof getExpenseReviewerSheets.init>) {
    const status = yield select(selectExpenseStatusIdByName(statusName));
    yield put(loadExpenseSheets.init({
        purpose: Permission.ApproveSheets,
        request: { status_id: status?.id || undefined },
    }));
    yield take([loadExpenseSheets.successType, loadExpenseSheets.errorType]);
    yield put(getExpenseReviewerSheets.success());
}

function* getExpenseReviewerSheetsWatcher(){
    yield* takeLatest(getExpenseReviewerSheets.initType, getExpenseReviewerSheetsSaga);
}

export default [
    getExpenseReviewerSheetsWatcher,
];

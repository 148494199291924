import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core';

const useTotalInfoTabStyles = makeHighPriorityStyles((theme: Theme) => ({
    containerFiber: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: theme.spacing(0, 1, 0, 0),
        cursor: 'pointer',
    },
    totalItem: {
        display: 'flex',
        flexDirection: 'row',
    },
    totalItemSecondary: {
        paddingLeft: theme.spacing(1),
    },
    container: {
        cursor: 'pointer',
        userSelect: 'none',
    },
    fiber: {
        fontSize: 10,
    },
}));

export default useTotalInfoTabStyles;

import React, { FC, useContext, useEffect, useRef } from 'react';
import { MenuContext } from 'shared/models/menuContext';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useTimeEntryMobileModalTabsStyles } from './TimeEntryMobileModalTabsStyles';
import { useActions } from 'store/utils';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { ButtonBase } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAllSheets } from 'store/entities/timesheet/selectors';
import WarningIcon from '@material-ui/icons/Warning';

interface ITimeEntryMobileModalTabsProps {
    onClose: () => void;
}

export const TimeEntryMobileModalTabs: FC<ITimeEntryMobileModalTabsProps> = ({
    onClose,
}: ITimeEntryMobileModalTabsProps) => {
    const classes = useTimeEntryMobileModalTabsStyles();
    const { menuState, setMenuState } = useContext(MenuContext);

    const actions = useActions( { setSheetGridStatus });
    const actionsRef = useRef(actions);

    const sheets = useSelector(selectAllSheets);
    const hasRecalled = sheets.some(sheet => sheet.status.name === StatusNames.RECALLED);

    const onClick = (value: StatusNames) => () => {
        setMenuState({
            ...menuState,
            tabs: {
                ...menuState.tabs,
                timeEntryPage: value,
            },
        });
        onClose();
    };

    useEffect(() => {
        actionsRef.current.setSheetGridStatus(menuState.tabs.timeEntryPage);
    }, [menuState.tabs.timeEntryPage, actionsRef]);

    return (
        <div>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.WORKING)}>Working</ButtonBase>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.SUBMITTED)}>Submitted</ButtonBase>
            {hasRecalled && (
                <ButtonBase className={classes.status} onClick={onClick(StatusNames.RECALLED)}>Recalled
                    <WarningIcon className={classes.warningClass}/>
                </ButtonBase>
            )}
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.REJECTED)}>Rejected</ButtonBase>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.APPROVED)}>Approved</ButtonBase>
            <ButtonBase className={classes.status} onClick={onClick(StatusNames.ALL)}>All</ButtonBase>
        </div>
    );
};

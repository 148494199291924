import { IEntry, QuantityType } from 'shared/models/sheet/Sheet';
import { ActivityDictionary } from 'shared/models/Activity';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { AppClient } from 'utils/constants';

export const activityHasCompletes = (activity?: IActivity | null) => {
    return process.env.REACT_APP_CLIENT === AppClient.RTI
        && activity?.description === ActivityDictionary.Interviewing;
};

const getSuffix = (entry: IEntry, activity?: IActivity) => {
    if (activityHasCompletes(activity) && entry.notes && entry.notes.length > 0) {
        return completesByNotes(entry.notes);
    }
    if (entry.data?.entry_type === QuantityType.FILE) {
        return entry.data.files;
    }
    if (entry.data?.entry_type === QuantityType.MILES) {
        return entry.data.miles;
    }
    if (entry.data?.entry_type === QuantityType.ODOMETER) {
        const { data } = entry;
        return data.miles_end - data.miles_start;
    }
    return '';
};

export function activityGridView(entry: IEntry, activity?: IActivity) {
    const suffix = getSuffix(entry, activity);

    return `${activity?.description || ''}${suffix ? ` (${suffix})` : ''}`;
}

import { call, put, takeLatest } from 'typed-redux-saga';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { createDepartment, setCreateDepartmentModalState, setEditDepartmentId, updateDepartment } from 'modules/settings/submodules/clients/departments/store/actions';
import { departmentApi } from 'modules/settings/submodules/clients/departments/store/api';
import { deleteDepartment, getDepartments } from 'modules/employmentInfo/store/department/actions';

function* createDepartmentSaga({ payload }: ReturnType<typeof createDepartment.init>) {
    const result = yield* call(departmentApi.createDepartment, payload);
    yield put(createDepartment.success(result));
    yield put(getDepartments.success([result]));
    yield put(setCreateDepartmentModalState(false));
}

function* createDepartmentWatcher() {
    yield* takeLatest(
        createDepartment.initType,
        withBackendErrorHandler(
            createDepartmentSaga,
            createDepartment.error,
            'Unable to create Department.',
        ),
    );
}

function* updateDepartmentSaga({ payload }: ReturnType<typeof updateDepartment.init>) {
    const { id, data } = payload;
    const result = yield* call(departmentApi.updateDepartment, id, data);
    yield put(updateDepartment.success(result));
    yield put(getDepartments.success([result]));
    yield put(setEditDepartmentId(null));
}

function* updateDepartmentWatcher() {
    yield* takeLatest(
        updateDepartment.initType,
        withBackendErrorHandler(
            updateDepartmentSaga,
            updateDepartment.error,
            'Unable to update Department',
        ),
    );
}

function* deleteDepartmentSaga({ payload }: ReturnType<typeof deleteDepartment.init>) {
    yield* call(departmentApi.deleteDepartment, payload);
    yield put(deleteDepartment.success(payload));
}

function* deleteDepartmentWatcher() {
    yield* takeLatest(
        deleteDepartment.initType,
        withBackendErrorHandler(
            deleteDepartmentSaga,
            deleteDepartment.error,
            'Unable to delete Department',
        ),
    );
}

export default [
    createDepartmentWatcher,
    updateDepartmentWatcher,
    deleteDepartmentWatcher,
];

import React from 'react';
import { useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { ISheetInfoItemData, SheetInfoItem } from 'shared/components/sidebars/SheetDetail/SheetInfoItem';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { selectAllClientsById } from 'store/entities/clients/clientsSelectors';
import { selectDepartmentById } from 'modules/employmentInfo/store/department/selectors';
import {
    selectAssignmentById,
    selectLocationById,
    selectPositionById,
} from 'store/entities/configuration/configurationSelectors';
import { SheetStatusSlug } from 'store/entities/timesheet/models/Status';

export interface ISheetGroupDetailByTypeProp {
    sheetGroup: IGroupedSheetCalculation;
    type: EntryType;
}

export const SheetGroupDetailByType = ({ sheetGroup, type }: ISheetGroupDetailByTypeProp) => {
    const sheetDetailClasses = useSheetDetailStyles();
    const position = useSelector(selectPositionById(sheetGroup?.position_id || ''));
    const assignment = useSelector(selectAssignmentById(sheetGroup?.assignment_id));
    const location = useSelector(selectLocationById(assignment?.location_id || ''));
    const department = useSelector(selectDepartmentById(assignment?.department_id || ''));
    const client = useSelector(selectAllClientsById)[sheetGroup?.client_id || ''];

    const typeRelatedInfo = type === EntryType.TIME ? [
        {
            title: 'Client',
            value: client?.name,
        },
        sheetGroup.time_status_slug === SheetStatusSlug.APPROVED_CLOSED ? {
            title: 'Prism Batch ID',
            value: sheetGroup.time_prism_batch_number || '',
        } : {
            title: 'Timesheet ID',
            value: sheetGroup.time_sheet_id || '',
        },
    ] : [
        {
            title: 'Location',
            value: location?.name,
        },
        sheetGroup.expense_status_slug === SheetStatusSlug.APPROVED_CLOSED ? {
            title: 'Prism Batch ID',
            value: sheetGroup.expense_prism_batch_number || '',
        } : {
            title: 'Expense Sheet ID',
            value: sheetGroup.expense_sheet_id || '',
        },
    ];

    const sheetDetailsList: ISheetInfoItemData[] = [
        {
            title: 'Pay Period',
            value: getPayPeriodByStartEnd(sheetGroup.pay_period_starts_at, sheetGroup.pay_period_ends_at),
        },
        {
            title: 'Position',
            value: position?.name,
        },
        {
            title: 'Department',
            value: department?.name,
        },
        ...typeRelatedInfo,
        {
            title: 'Deal #',
            value: sheetGroup?.deal_number,
        },
        {
            title: 'Job #',
            value: sheetGroup?.job_number,
        },
    ].filter(item => item.value);
    return (
        <List className={sheetDetailClasses.listWrapper}>
            {sheetDetailsList.map(item => <SheetInfoItem key={item.title} data={item}/>)}
        </List>
    );
};

import SheetRejectedView from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetRejectedView';
import React, { useEffect, useState } from 'react';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import { useSheetsData } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { useSelector } from 'react-redux';
import { ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { selectEmployeeSubmittedEditSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { IStore } from 'store/configureStore';
import { selectTypedSheet } from 'store/entities/timesheet/selectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import SheetsRecalledView from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsRecalledView';
import { useOpenSheetDetail } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/useOpenSheetDetail';

export default function SheetsSubmittedAllPage() {
    const openSheetDetail = useOpenSheetDetail();
    const editSheetInfo = useSelector(selectEmployeeSubmittedEditSheet);
    const { timeRows, expenseRows, isLoading } = useSheetsData(false);
    const [selectedSheetInfo, setSelectedSheetInfo] = useState<ISheetClickInfo | null>(null);

    const sheet = useSelector((state: IStore) => selectedSheetInfo
        ? selectTypedSheet(selectedSheetInfo.sheetId, selectedSheetInfo.entryType)(state)
        : null,
    );
    useEffect(() => {
        if (selectedSheetInfo && sheet && sheet.status.name !== StatusNames.RECALLED) {
            openSheetDetail(selectedSheetInfo);
            setSelectedSheetInfo(null);
        }
    }, [openSheetDetail, selectedSheetInfo, sheet]);
    const handleSheetClick = (clickInfo: ISheetClickInfo) => {
        setSelectedSheetInfo(clickInfo);
    };

    return (
        <>
            {editSheetInfo ? (
                <SheetRejectedView
                    sheetId={editSheetInfo.sheetId}
                    entryType={editSheetInfo.entryType}
                />
            ) : sheet && sheet.status.name === StatusNames.RECALLED ? (
                <SheetsRecalledView
                    sheetId={sheet.id}
                    entryType={sheet.entry_type}
                    resetSheet={setSelectedSheetInfo}
                />
            ) : (
                <SheetsSubmittedView
                    timeRows={timeRows}
                    expenseRows={expenseRows}
                    onDetailsClick={handleSheetClick}
                    showStatus={true}
                    isLoading={isLoading}
                    showFilters={false}
                />
            )}
        </>
    );
}

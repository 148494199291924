import baseApi from 'shared/utils/baseApi';
import { IFieldsConfigurationWrapper, ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';

const servicePath = 'client-configurations';
const modulePath = 'clients';

interface IAppSettingRequest {
    approval_levels: number;
}

export const configurationApi = {
    async updateAppSettings(
        clientId: string,
        request: Partial<ITimeAndPayClientConfiguration>,
    ): Promise<IFieldsConfigurationWrapper> {
        const requestData: IAppSettingRequest = {
            approval_levels: request.approvalLevels || 1,
        };
        const { data } = await baseApi.patch<IAppSettingRequest, IFieldsConfigurationWrapper>(
            `${servicePath}/${modulePath}/${clientId}/configuration`, requestData,
        );
        return data;
    },
};

import { useRef } from 'react';
import { setGridHoverStatus } from 'store/entities/appConfig/actions';
import { useActions } from 'store/utils';

export type THoverState = {
    rowId: string;
    color: string;
    isHover: boolean;
}

export function useHover() {
    const actions = useActions( { setGridHoverStatus });
    const actionsRef = useRef(actions);
    
    const onMouseEnter = (color: string, rowId: string) => {
        actionsRef.current.setGridHoverStatus({ isHover: true, color: color, rowId: rowId });
    };
    
    const onMouseLeave = (rowId: string) => {
        actionsRef.current.setGridHoverStatus({ isHover: false, color: '', rowId: rowId });
    };
    
    return {
        onMouseEnter, 
        onMouseLeave,
    };
}

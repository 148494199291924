import { combineReducers } from 'redux';
import { THoverState } from 'shared/components/table/tableApprovalCells/hover';
import { IGlobalModal } from 'shared/components/toasts/modal';
import {
    appSidebarActionsType,
    SET_GLOBAL_TOAST,
    SET_GRID_HOVER_STATUS,
    SET_HEADER_TITLE,
    SET_SHEET_GRID_STATUS,
    SET_SIDEBAR_OPENED,
    setGlobalToastActionType,
    setGridHoverStatusActionType,
    setHeaderTitle,
    setSheetGridStatusActionType,
} from 'store/entities/appConfig/actions';
import { IHeaderTitle } from 'store/entities/appConfig/appConfigModel';
import { StatusNames } from 'store/entities/timesheet/models/Status';

const defaultState = {
    isSidebarOpened: true,
    toastState: null,
    sheetGridStatus: StatusNames.WORKING,
    hoverState: {
        color: '',
        isHover: false,
        rowId: '',
    },
    headerTitle: {
        desktopHeader: '',
        mobileHeader: '',
    },
};

function isSidebarOpened(state: boolean = defaultState.isSidebarOpened, action: appSidebarActionsType): boolean {
    switch (action.type) {
        case SET_SIDEBAR_OPENED:
            return action.payload;
        default:
            return state;
    }
}

function headerTitle(state = defaultState.headerTitle, action: ReturnType<typeof setHeaderTitle>): IHeaderTitle {
    switch (action.type) {
        case SET_HEADER_TITLE:
            if (typeof action.payload === 'string') {
                return {
                    desktopHeader: action.payload,
                    mobileHeader: action.payload,
                };
            } else {
                return action.payload;
            }
        default:
            return state;
    }
}

function toastState(state: IGlobalModal | null = defaultState.toastState, action: setGlobalToastActionType) {
    switch (action.type) {
        case SET_GLOBAL_TOAST:
            return action.payload;
        default:
            return state;
    }
}

function sheetGridStatus(state = defaultState.sheetGridStatus, action: setSheetGridStatusActionType): StatusNames {
    switch (action.type) {
        case SET_SHEET_GRID_STATUS:
            return action.payload;
        default:
            return state;
    }
}

function approvalGridHoverStatus(state = defaultState.hoverState, action: setGridHoverStatusActionType): THoverState {
    switch (action.type) {
        case SET_GRID_HOVER_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export const appConfig = combineReducers({
    headerTitle,
    isSidebarOpened,
    toastState,
    sheetGridStatus,
    approvalGridHoverStatus,
});

import { useStyles } from 'modules/timeAndExpense/components/AddEntry/components/SelectorsPanel/styles';
import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { ISelectorPanelEntry } from 'modules/timeAndExpense/components/AddEntry/store/models';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { clearSelectorValue } from 'modules/timeAndExpense/components/AddEntry/store/actions';
import { shortenText } from 'shared/utils/helpers/shortenText';

export interface ISelectorsPanelBlockProps{
    entry: ISelectorPanelEntry,
}

export function SelectorsPanelBlock({ entry }: ISelectorsPanelBlockProps){
    const classes = useStyles();
    const dispatch = useDispatch();
    const onCloseClick = useCallback(() => {
        if (entry.key) {
            dispatch(clearSelectorValue(entry.key));
        }
    }, [dispatch, entry]);
    const shortenValue = shortenText(`${ entry.value || ''}`, 17);
    return (
        <Box className={classes.block}>
            <Box className={classes.blockTop}>
                <Box>{entry.title}</Box>
                {entry.key && (<CloseIcon onClick={onCloseClick} className={classes.blockClose}/>)}
            </Box>
            <Box className={classes.blockValue} title={ shortenValue !== entry.value ? entry.value : undefined }>
                {shortenValue}
            </Box>
        </Box>
    );
}

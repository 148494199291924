import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { timeApi } from 'store/entities/timesheet/api/timeApi';
import {
    submitSheets,
    updateSheetsStatus,
} from 'store/entities/timesheet/actions/statuses';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { EntryType, IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { sheetStatusMessage } from 'shared/utils/formatters/sheetStatus';
import { setGlobalToast } from '../../appConfig/actions';
import { ISheetStatusChange, StatusNames } from '../models/Status';
import { selectAllEntries, selectAllSheets, selectExpenseSheetStatusByName, selectTimeSheetStatusByName } from '../selectors';
import { createExpenseSheetApprovalSaga, updateExpenseSheetsStatusesSaga } from './expenseSagas';
import { createTimeSheetApprovalSaga, updateTimeSheetsStatusesSaga } from './timeSagas';
import { SET_TENANT_SUCCESS, setTenantSuccess } from 'store/entities/clients/clientsAction';
import { getExpenseStatuses } from 'store/entities/timesheet/actions/expenseActions';
import { getTimeStatuses } from 'store/entities/timesheet/actions/timeActions';
import { expenseApi } from 'store/entities/timesheet/api/expenseApi';

export function* fetchStatusesSaga({ payload: clientId }: ReturnType<typeof setTenantSuccess>) {
    if (!clientId) {
        return;
    }
    const [timeStatuses, expenseStatuses] = yield* all([
        call(timeApi.getAvailableStatuses),
        call(expenseApi.getAvailableStatuses),
    ]);
    yield* put(getTimeStatuses.success(timeStatuses));
    yield* put(getExpenseStatuses.success(expenseStatuses));
}

export function* fetchStatusesWatcher() {
    yield takeLatest(SET_TENANT_SUCCESS, withBackendErrorHandler(fetchStatusesSaga, getTimeStatuses.error, 'Unable to load sheet statuses'));
}

function* changeSheetStatusSaga({
    payload: { statusName, sheetsEntryTypes, notes },
}: ReturnType<typeof updateSheetsStatus.init>) {
    const newTimeStatus = yield* select(selectTimeSheetStatusByName(statusName));
    const newExpenseStatus = yield* select(selectExpenseSheetStatusByName(statusName));

    const timeSheetsIds: string[] = [];
    const expenseSheetsIds: string[] = [];

    Object.entries(sheetsEntryTypes).forEach(([id, entryType]) => {
        if (entryType === EntryType.EXPENSE) {
            expenseSheetsIds.push(id);
        }
        if (entryType === EntryType.TIME) {
            timeSheetsIds.push(id);
        }
    });

    const flags = {
        time: false,
        expense: false,
    };

    if (!newTimeStatus || !newExpenseStatus) {
        const errorSheets = [];
        if (!newTimeStatus && timeSheetsIds.length > 0) {
            errorSheets.push('timesheet');
        }
        if (!newExpenseStatus && expenseSheetsIds.length > 0) {
            errorSheets.push('expense sheet');
        }
        // If we don't have needed status for sheet type then we throw exception
        if (errorSheets.length > 0) {
            yield* put(updateSheetsStatus.error('Appropriate status was not found'));
            yield* put(setGlobalToast({
                title: `There is no needed status for ${errorSheets.join(' and ')}`,
                severity: IModalSeverity.Error,
            }));
            return;
        }
    }

    const updateSagas = [];
    if (expenseSheetsIds.length) {
        if (newExpenseStatus.name === StatusNames.APPROVED){
            updateSagas.push(call(createExpenseSheetApprovalSaga, expenseSheetsIds));
        } else {  //reject keeps the old logic
            updateSagas.push(call(updateExpenseSheetsStatusesSaga, expenseSheetsIds, newExpenseStatus, notes));
        }
        flags.expense = true;
    }
    if (timeSheetsIds.length) {
        if (newTimeStatus.name === StatusNames.APPROVED){
            updateSagas.push(call(createTimeSheetApprovalSaga, timeSheetsIds));
        } else {  //reject keeps the old logic
            updateSagas.push(call(updateTimeSheetsStatusesSaga, timeSheetsIds, newTimeStatus, notes));
        }
        flags.time = true;
    }

    const amount = Object.values(sheetsEntryTypes).length;

    try {
        yield* all(updateSagas);

        yield* put(setGlobalToast({
            title: sheetStatusMessage(amount, flags, statusName),
            severity: IModalSeverity.Success,
        }));

    } catch (e) {
        yield* put(setGlobalToast({
            title: sheetStatusMessage(amount, flags, statusName, false),
            severity: IModalSeverity.Error,
        }));
    }
}

export function* updateSheetsStatusWatcher() {
    yield takeLatest(updateSheetsStatus.initType, changeSheetStatusSaga);
}

const sheetNotEmpty = (sheet: ISheet, entries: IEntry[]): boolean => {
    return Boolean(
        sheet.total_minutes
        || parseFloat(sheet.total_dollars)
        || entries.find(entry => entry.sheet_id === sheet.id),
    );
};

export function* submitSheetsSaga(action: ReturnType<typeof submitSheets>) {
    const { entryTypes, payPeriod } = action.payload;
    const sheets = yield* select(selectAllSheets);
    const entries = yield* select(selectAllEntries);

    const submitSheetsInfo: ISheetStatusChange = {
        statusName: StatusNames.SUBMITTED,
        sheetsEntryTypes: sheets.reduce((acc, sheet) => {
            if (sheet.period_end === payPeriod.period_end
                && sheet.status.name === StatusNames.WORKING
                && entryTypes.includes(sheet.entry_type)
                && sheetNotEmpty(sheet, entries)
            ) {
                return {
                    ...acc,
                    [sheet.id]: sheet.entry_type,
                };
            }
            return acc;
        }, {}),
    };

    if (Object.values(submitSheetsInfo.sheetsEntryTypes).length === 0) {
        yield put(setGlobalToast({ title: 'No sheets to submit', severity: IModalSeverity.Warning }));
        return;
    }

    yield* put(updateSheetsStatus.init(submitSheetsInfo));
}

export function* submitSheetsWatcher() {
    yield takeLatest(submitSheets.action, submitSheetsSaga);
}

export default [
    fetchStatusesWatcher,
    updateSheetsStatusWatcher,
    submitSheetsWatcher,
];

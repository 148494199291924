import clsx from 'clsx';
import React from 'react';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';

interface IGridHeaderProps<RowData> {
    rowsData: Array<RowData>;
    cells: Array<ICellInfo<RowData>>;
    headerCellClassName?: string;
    classes: ReturnType<typeof useGridTableStyles>;
    stickyHeader?: boolean;
}

export default function GridHeader<RowData>({
    cells, headerCellClassName, classes, rowsData, stickyHeader,
}: IGridHeaderProps<RowData>) {
    return (
        <>
            {cells.map(({ key, renderTitle: TitleComponent, title, headerClassName }) => (
                <div className={clsx(
                    classes.headerCell,
                    stickyHeader ? classes.headerCellSticky : '',
                    headerClassName ?? '',
                    headerCellClassName ?? '',
                )} key={key}>
                    {TitleComponent ? (
                        <TitleComponent rows={rowsData}/>
                    ) : title}
                </div>
            ))}
        </>
    );
}

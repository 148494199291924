import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { FormMode } from 'shared/models/FormMode';
import { setCreateClientWorkLocationModalState } from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/state/actions';
import { IClientWorkLocationFormValues } from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/model';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import {
    selectCreateClientWorkLocationModalState,
    selectIsClientWorkLocationCreating,
} from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/state/selector';
import WorkLocationFormWrapper
    from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/WorkLocationFormWrapper';
import { createClientWorkLocation } from 'modules/settings/submodules/clients/workLocation/store/actions';

export default function CreateWorkLocationModal() {
    const dispatch = useDispatch();
    const modalClasses = useSettingsFormModalStyles();
    const isOpen = useSelector(selectCreateClientWorkLocationModalState);
    const isLoading = useSelector(selectIsClientWorkLocationCreating);

    const onSave = useCallback((values: IClientWorkLocationFormValues) => {
        dispatch(createClientWorkLocation.init(values));
    }, [dispatch]);
    const onClose = useCallback(() => {
        dispatch(setCreateClientWorkLocationModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Create New Work Location"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <WorkLocationFormWrapper
                    submitTitle={'Create work location'}
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    mode={ FormMode.New }/>
            </Box>
        </ModalDialog>
    );
}

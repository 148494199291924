import { IStore } from 'store/configureStore';
import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';

const selectEditDealState = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.editDeal;
};
export const selectEditDealId = (state: IStore) => {
    const editDealState = selectEditDealState(state);
    return editDealState.dealId;
};
export const selectIsDealUpdating = (state: IStore) => {
    const editDealState = selectEditDealState(state);
    return editDealState.isUpdating;
};

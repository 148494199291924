import React, { FC, useCallback } from 'react';
import { useQuickEditStyles } from 'shared/components/table/tableEntryCells/QuickEditStyles';
import OutlinedTimeField from '../../textField/OutlinedTimeField';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { IModalSeverity } from '../../toasts/modal';
import { useActions } from 'store/utils';
import { IError } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/validations/IError';
import { QuantityType, TEntryData } from 'shared/models/sheet/Sheet';
import DollarQuantity from '../addEntryControls/quantity/DollarQuantity';
import { moneyModifiers } from 'shared//models/Money';
import clsx from 'clsx';

interface IQuickEditProps {
    value: string;
    quickEdit: (changedValue: string) => IError | null;
    onFocus: () => void;
    handleOnRestore: () => void;
    onSubmit: any;
    parserEntry: (value: string) => string;
    entryDataType: TEntryData;
    customClasses?: string;
    disabled?: boolean;
}

const QuickEdit: FC<IQuickEditProps> = ({
    entryDataType,
    onSubmit,
    quickEdit,
    onFocus,
    handleOnRestore,
    parserEntry,
    value,
    customClasses = '',
    disabled,
}: IQuickEditProps) => {
    const actions = useActions({ setGlobalToast });
    const classes = useQuickEditStyles();

    const handleOnBlur = useCallback(({ target }): IError | null => {
        const { value: changedValue } = target;
        if (changedValue === '') {
            handleOnRestore();
            return null;
        }

        const parsedValue = parserEntry(changedValue);
        const error = quickEdit(parsedValue);
        if (error) {
            actions.setGlobalToast({
                title: error.text,
                severity: IModalSeverity.Error,
                autoHideDuration: null,
            });
            handleOnRestore();
            return error;
        } else {
            onSubmit(parsedValue);
            return null;
        }
    }, [actions, quickEdit, onSubmit, handleOnRestore, parserEntry]);
    return entryDataType.entry_type === QuantityType.TIME ? (
        <OutlinedTimeField
            customClasses={{
                input: classes.input,
                inputRoot: clsx(classes.inputRoot, customClasses),
                disabled: classes.disabledInput,
            }}
            value={value}
            onBlur={handleOnBlur}
            onFocus={onFocus}
            disabled={disabled}
        />
    ) : (
        <DollarQuantity
            onBlur={handleOnBlur}
            value={value}
            modifiers={moneyModifiers}
            customClasses={{
                input: classes.input,
                inputRoot: clsx(classes.inputRoot, customClasses),
            }}
            disabled={disabled}
        />
    );
};

export default QuickEdit;

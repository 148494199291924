import React from 'react';
import { settingsRoutes } from 'modules/settings/routes';
import { SideMenu } from 'modules/settings/submodules/components/SideMenu/SideMenu';
import { Permission } from 'store/components/auth/authModels';
import { FeatureSwitches } from 'utils/featureSwitches';

export const CommonSettingsSideMenu = () => {
    const items = [
        {
            title: 'Clients',
            link: settingsRoutes.CLIENTS.ROOT,
            permission: Permission.modifyClientSettings,
        },
        {
            title: 'Platform Users',
            link: settingsRoutes.PLATFORM_USERS.ROOT,
            feature: FeatureSwitches.enablePlatformUsersManagement,
            permission: Permission.ManageManagerUsers,
            childs: [
                {
                    title: 'Clients Roles & Permissions',
                    link: settingsRoutes.PLATFORM_USERS.USER_ROLES,
                },
            ],
        },
        {
            title: 'Employees',
            link: settingsRoutes.EMPLOYEES.ROOT,
            feature: FeatureSwitches.enableEmployeeManagement,
            permission: Permission.ManageEmployeeUsers,
        },
        {
            title: 'Offer Letter Templates',
            link: settingsRoutes.OFFER_LETTERS.TEMPLATES,
            feature: FeatureSwitches.enableOfferLetterTemplates,
            permission: Permission.ManageOfferLetterTemplates,
        },
        {
            title: 'Custom Fields',
            link: settingsRoutes.CUSTOM_FIELDS.ROOT,
            feature: FeatureSwitches.enableCustomFieldsModule,
            // TODO: Check permission
        },
    ];

    return (
        <SideMenu items={items}/>
    );
};

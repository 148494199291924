import { logger } from 'shared/utils/logging/logger';
import {
    AvailableFormConfiguration,
    DetailsConfiguration,
    FiltersConfiguration,
    FilterSlug,
    IFieldsConfiguration,
    IFieldsConfigurationBackend,
    IInputConfiguration,
    InputFields,
    ITotalsConfiguration,
    TablesConfiguration,
} from 'store/entities/clients/clientsModel';
import { itemsByKey } from 'store/reducerUtils';

export const getTableConfiguration = (
    fieldsConfiguration: IFieldsConfigurationBackend,
    clientId: string,
): TablesConfiguration => {
    if (fieldsConfiguration.tables) {
        return fieldsConfiguration.tables?.reduce(
            (mem, tableConfig) => {
                return {
                    ...mem,
                    [tableConfig.type]: tableConfig.columns,
                };
            },
            {} as TablesConfiguration,
        );
    } else {
        logger.error(new Error('No tables configuration for client'), { clientId });
    }
    return {} as TablesConfiguration;
};

export const getDetailConfiguration = (
    fieldsConfiguration: IFieldsConfigurationBackend,
    clientId: string,
): DetailsConfiguration => {
    if (fieldsConfiguration.tables) {
        return fieldsConfiguration.details?.reduce(
            (mem, config) => {
                return {
                    ...mem,
                    [config.type]: {
                        info: config.info,
                        title: config.title,
                    },
                };
            },
            {} as DetailsConfiguration,
        );
    } else {
        logger.error(new Error('No details configuration for client'), { clientId });
    }
    return {} as DetailsConfiguration;
};

export const getFiltersConfiguration = (
    fieldsConfiguration: IFieldsConfigurationBackend,
    clientId: string,
): FiltersConfiguration => {
    if (fieldsConfiguration.filters) {
        return fieldsConfiguration.filters?.reduce(
            (filterTypes, filterType) => ({
                ...filterTypes,
                [filterType.type]: filterType.fields.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.slug]: item,
                    }),
                    {} as Record<FilterSlug, IFieldsConfiguration>,
                ),
            }),
            {} as FiltersConfiguration,
        );
    } else {
        logger.error(new Error('No filters configuration for client'), { clientId });
    }
    return {} as FiltersConfiguration;
};

export const getTotalsConfiguration = (
    fieldsConfiguration: IFieldsConfigurationBackend,
    clientId: string,
): ITotalsConfiguration => {
    if (fieldsConfiguration.totals) {
        return fieldsConfiguration.totals?.reduce(
            (mem, totalConfig) => {
                return {
                    ...mem,
                    [totalConfig.type]: totalConfig.info,
                };
            },
            {} as ITotalsConfiguration,
        );
    } else {
        logger.error(new Error('No totals configuration for client'), { clientId });
    }
    return {} as ITotalsConfiguration;
};

export const processFieldsConfiguration = (
    fieldsConfiguration: IFieldsConfigurationBackend,
    clientId: string,
): IFieldsConfiguration => {
    let timeInputs = {} as InputFields;
    let expenseInputs = {} as InputFields;
    if (fieldsConfiguration.inputs) {
        timeInputs = itemsByKey(
            fieldsConfiguration.inputs?.find(
                (item: { type: AvailableFormConfiguration; fields: Array<IInputConfiguration> }) =>
                    item.type === AvailableFormConfiguration.Time,
            )?.fields as Array<IInputConfiguration>,
            item => item.slug,
        );
        expenseInputs = itemsByKey(
            fieldsConfiguration.inputs?.find(
                (item: { type: AvailableFormConfiguration; fields: Array<IInputConfiguration> }) =>
                    item.type === AvailableFormConfiguration.Expense,
            )?.fields as Array<IInputConfiguration>,
            item => item.slug,
        );
    } else {
        logger.error(new Error('No inputs configuration for client'), { clientId });
    }

    return {
        inputs: {
            time: timeInputs,
            expense: expenseInputs,
        },
        tables: getTableConfiguration(fieldsConfiguration, clientId),
        detail: getDetailConfiguration(fieldsConfiguration, clientId),
        filters: getFiltersConfiguration(fieldsConfiguration, clientId),
        totals: getTotalsConfiguration(fieldsConfiguration, clientId),
    };
};

import baseApi from 'shared/utils/baseApi';
import {
    ICheckEmployeeOnboardingRequest,
    ICheckEmployeeOnboardingResponse,
    ICreateEmployeeOfferLetterBackend,
    ICreateOfferLetterBackend, IGetOfferLetterParams,
    IOfferLetter, IOfferLetterWithLinked, IUpdateOfferRequest, IUpdateProfileRequest,
} from 'modules/offerLetter/store/model';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { withClientId } from 'store/utils/withClientId';

export const offerLetterApi = {
    // This function is for requests that should get data from all the tenants
    async getAllOfferLetters(params: IGetOfferLetterParams = {}): Promise<IOfferLetterWithLinked> {
        const { data } = await baseApi.get<IOfferLetterWithLinked>(
            '/client-users/offer_letters', params,
        );

        return data;
    },
    async getOfferLetter(params: IGetOfferLetterParams = {}): Promise<IOfferLetterWithLinked> {
        const { data } = await baseApi.get<IOfferLetterWithLinked>(
            '/client-users/offer_letters', withClientId(params),
        );

        return data;
    },
    async getOfferLetterById(id: string): Promise<IOfferLetter> {
        const { data } = await baseApi.get<IOfferLetter>(
            `/client-users/offer_letters/${id}`, withClientId({}),
        );

        return data;
    },
    async createOffer(offerLetterCreateRequest: ICreateOfferLetterBackend): Promise<IOfferLetter> {
        const { data } = await baseApi.create<ICreateOfferLetterBackend, IOfferLetter>(
            'client-users', 'offer_letters', offerLetterCreateRequest,
        );

        return data;
    },
    async createEmployeeOffer(offerLetterCreateRequest: ICreateEmployeeOfferLetterBackend): Promise<IOfferLetter> {
        const { data } = await baseApi.create<ICreateEmployeeOfferLetterBackend, IOfferLetter>(
            'client-users', 'offer_letters/employee', offerLetterCreateRequest,
        );

        return data;
    },
    async updateOfferAndResend(id: string, request: ICreateOfferLetterBackend): Promise<IOfferLetter> {
        const { data } = await baseApi.create<ICreateOfferLetterBackend, IOfferLetter>(
            'client-users', `offer_letters/${id}/edit_and_resend`, request,
        );

        return data;
    },
    async updateOfferLetter(id: string, request: Partial<IUpdateOfferRequest>) {
        const params = {
            ...request,
            status: request?.status?.toLowerCase(),
        };

        const { data } = await baseApi.patch<Partial<IUpdateOfferRequest>, IOfferLetter>(
            `client-users/offer_letters/${id}`, params,
        );

        return data;
    },
    async updateEmployeeProfile(clientId: string, request: IUpdateProfileRequest) {
        const { data } = await baseApi.create<IUpdateProfileRequest, IEmployeeProfile>(
            'client-users', `clients/${clientId}/employee_profiles/mine`, request,
        );

        return data;
    },
    async getMyEmployeeProfile(clientId: string): Promise<IEmployeeProfile> {
        const { data } = await baseApi.get<IEmployeeProfile>(
            `/client-users/clients/${clientId}/employee_profiles/mine`,
        );

        return data;
    },
    async getMyEmployeeProfiles(): Promise<IEmployeeProfile[]> {
        const { data } = await baseApi.get<{ employee_profiles: IEmployeeProfile[]}>(
            `/client-users/employee_profiles/mine`,
        );

        return data.employee_profiles;
    },
    async getPrismSSOLink(clientId: string): Promise<string> {
        const { data } = await baseApi.get<{ prism_sso: string }>(
            `/client-users/clients/${clientId}/prism_sso`,
        );

        return data?.prism_sso;
    },
    async checkOfferLetterPdf(id: string): Promise<string> {
        const { data } = await baseApi.head<string>(
            `/documents/offer_letters/${id}`,
        );
        return data;
    },
    async checkEmployeeOnboardingStatus(
        prism_client_id: string,
        request: ICheckEmployeeOnboardingRequest,
    ): Promise<ICheckEmployeeOnboardingResponse[]> {
        const { data } = await baseApi.post<
        ICheckEmployeeOnboardingRequest,
        { employees: ICheckEmployeeOnboardingResponse[]
        }>(
            `/prismhr/clients/${prism_client_id}/employees`,
            request,
        );

        return data.employees;
    },
};

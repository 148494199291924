import { PayrollProcessorHubRoutes } from 'modules/payrollProcessorHub/PayrollProcessorHubRoutes';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EmailConfirmation from 'shared/components/auth/EmailConfirmation';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import ErrorBoundary from 'shared/components/errorBoundary/ErrorBoundary';
import { BackspaceListener } from 'shared/components/listeners/BackspaceListener';
import { InactivityListener } from 'shared/components/listeners/InactivityListener';
import { GlobalToast } from 'shared/components/toasts/GlobalToast';
import { routes } from 'shared/routes';
import { AppClient } from 'utils/constants';
import Home from './modules/home/Home';
import Client from 'modules/clients/Client';
import Login from 'shared/components/auth/Login';
import Registration from 'shared/components/auth/Registration';
import ResetPassword from 'shared/components/auth/ResetPassword';
import NotFound from 'shared/components/notFound/NotFound';
import { IStore } from 'store/configureStore';
import { authByToken } from 'store/components/auth/authActions';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { optimizely } from 'utils/optimizely';
import HomeDashboardPage from 'modules/home/dashboard/HomeDashboardPage';
import OfferPdfOnly from 'modules/offerLetter/OfferLetterEmployee/OfferPdfOnly';
import ErrorTestPage from 'ErrorTestPage';
import { SettingsRoutes } from 'modules/settings/SettingsRoutes';
import { Permission } from 'store/components/auth/authModels';
import { OfferLetterEmployeeRoutes } from 'modules/offerLetter/OfferLetterEmployeeRoutes';
import UserProfile from 'modules/profile/UserProfile';
import EnterPassword from './shared/components/auth/EnterPassword';
import ResetPasswordFailed from './shared/components/auth/ResetPasswordFailed';
import { HeaderTags } from 'HeaderTags';
import { ClientThemeProvider } from 'shared/components/theme/ClientThemeProvider';
import { useCodeRefresher } from 'utils/codeRefresher';
import { StatusPageWidget } from './shared/components/StatusPage/StatusPage';
import Appcues from './shared/services/Appcues/Appcues';

const App: React.FC = () => {
    const auth = useSelector((state: IStore) => state.auth);

    const dispatch = useDispatch();

    useCodeRefresher();

    useEffect(() => {
        dispatch(authByToken.init());
    }, [dispatch]);

    const location = useLocation();
    useEffect(() => {
        Appcues.view();
    }, [location.pathname]);

    return (
        <OptimizelyProvider
            optimizely={optimizely}
            user={{
                id: auth.user ? auth.user.id : 'empty',
            }}
        >
            <HeaderTags title={process.env.REACT_APP_CLIENT === AppClient.RTI ? 'DCSnet.net' : 'Headway Connect'}/>
            <ErrorBoundary>
                <ClientThemeProvider>
                    <Switch>
                        <PrivateRoute
                            path={routes.HOME}
                            component={Home}
                            componentProps={{ sectionTitle: `${auth.user?.firstName} ${auth.user?.lastName}`,
                                content: <HomeDashboardPage/> }}
                            exact
                        />
                        <PrivateRoute
                            path={routes.EMPLOYEE_OFFER_LETTER.ROOT}
                            component={Home}
                            componentProps={{ content: <OfferLetterEmployeeRoutes/> }}
                        />
                        <PrivateRoute
                            path={routes.CLIENT.ROOT}
                            component={Client}
                        />
                        <PrivateRoute
                            path={routes.USER_PROFILE}
                            component={Home}
                            componentProps={{
                                sectionTitle: `${auth.user?.firstName} ${auth.user?.lastName}`,
                                content: <UserProfile/>,
                            }}
                        />
                        <Route
                            path={routes.AUTH.LOGIN}
                            component={Login}
                        />
                        <Route
                            path={routes.AUTH.REGISTRATION}
                            component={Registration}
                        />
                        <Route
                            path={routes.AUTH.RESET_PASSWORD}
                            component={ResetPassword}
                        />
                        <Route
                            path={routes.AUTH.RESET_FAILED}
                            component={ResetPasswordFailed}
                        />
                        <Route
                            path={routes.AUTH.NEW_PASSWORD}
                            component={EnterPassword}
                        />
                        <Route
                            path={routes.AUTH.EMAIL_CONFIRMATION}
                            component={EmailConfirmation}
                        />
                        <PrivateRoute
                            path={'/test-error'}
                            component={Home}
                            componentProps={{ content: <ErrorTestPage/> }}
                        />
                        <PrivateRoute component={OfferPdfOnly} path={routes.OFFER_PDF_ONLY_VIEW}/>
                        <PrivateRoute
                            path={routes.PAYROLL_PROCESSOR_HUB.ROOT}
                            component={Home}
                            permission={Permission.PPHReadAccess}
                            componentProps={{
                                sectionTitle: 'Payroll',
                                content: <PayrollProcessorHubRoutes/>,
                            }}
                        />
                        <PrivateRoute
                            path={routes.SETTINGS}
                            component={SettingsRoutes}
                            anyPermissionFrom={[
                                Permission.modifyClientSettings,
                                Permission.ManageManagerUsers,
                                Permission.ManageEmployeeUsers,
                                Permission.ManageOfferLetterTemplates,
                                Permission.ManagePaySettings,
                            ]}
                        />
                        <Route component={NotFound}/>
                    </Switch>
                </ClientThemeProvider>
            </ErrorBoundary>
            <GlobalToast/>
            <BackspaceListener/>
            <InactivityListener/>
            <StatusPageWidget/>
        </OptimizelyProvider>
    );
};
export default hot(module)(App);

import React, { useMemo } from 'react';
import { isDayInJobNumberPeriod } from 'shared/components/selects/JobNumbers';
import { IJobNumber } from 'shared/models/JobNumber';
import { IMenuItemType } from 'shared/models/MenuItemType';
import FormSelect from 'shared/components/selects/FormSelect';
import { moment } from 'utils/momentExtensions';
import { backendDateFormat } from 'shared/models/Dates';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';

interface IDayProps extends IFormFieldProps {
    payPeriod: IPayPeriod | null;
    jobNumber?: IJobNumber | null;
}

export default function DaySelect({ payPeriod, jobNumber, ...props }: IDayProps) {
    const weekDaysMenuTimes: IMenuItemType<string>[] = useMemo(() => {
        const payPeriodRange = moment.range(moment(payPeriod?.period_start), moment(payPeriod?.period_end));

        return [...payPeriodRange.by('day')]
            .filter(day => jobNumber ? isDayInJobNumberPeriod(jobNumber)(day) : true)
            .map(day => ({
                text: day.format('dddd M/D/YY'),
                value: day.format(backendDateFormat),
            }));
    }, [payPeriod, jobNumber]);

    return (
        <FormSelect
            {...props}
            useIdValue
            options={weekDaysMenuTimes}
            getKey={(item: IMenuItemType<string>) => item.value}
            getText={(item: IMenuItemType<string>) => item.text}
            isNullable={false}
        />
    );
}

import { selectDepartmentsById } from 'modules/employmentInfo/store/department/selectors';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { IEntryRow } from 'shared/components/table/EntriesTable/model';
import { getEntryErrorsById, getEntryRowIdByRow } from 'shared/components/table/EntriesTable/utils';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { getProjectAssignmentByProjectAndAssignmentIds } from 'shared/utils/helpers/entries';
import {
    selectActivitiesById,
    selectAssignmentsById,
    selectJobNumbersById,
    selectLocationsById,
    selectPositionsById,
    selectProjectsWithAssignments,
    selectTasksById,
} from 'store/entities/configuration/configurationSelectors';
import { EntriesTableHeader } from './components/EntriesTableHeader';
import { optimizely } from 'utils/optimizely';
import { FeatureSwitches } from 'utils/featureSwitches';

interface IEntriesTableProps {
    date: string;
    entries: Array<IEntry>;
    entryFilter?: EntryType;
    cells: Array<ICellInfo<IEntryRow>>;
    hideHeader: boolean;
    isLoading: boolean;
    isFileEntriesView?: boolean;
    headerActionsEnabled?: boolean;
    collapsed?: boolean;
    isCollapsible?: boolean;
    userId?: string;
}

export default function EntriesTable({
    date,
    entries,
    entryFilter,
    cells,
    isLoading,
    hideHeader,
    userId,
    isFileEntriesView = false,
    headerActionsEnabled = false,
    collapsed = false,
    isCollapsible = false,
}: IEntriesTableProps) {
    const classes = useEntriesTableStyles();

    const sortedEntries: Array<IEntry> = useMemo(
        () => [...entries].sort(
            (a, b) => moment(a.created_at).diff(moment(b.created_at)),
        ),
        [entries],
    );

    const assignmentsById = useSelector(selectAssignmentsById);
    const activitiesById = useSelector(selectActivitiesById);
    const tasksById = useSelector(selectTasksById);
    const positionsById = useSelector(selectPositionsById);
    const locationsById = useSelector(selectLocationsById);
    const departmentsById = useSelector(selectDepartmentsById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const projectAssignments = useSelector(selectProjectsWithAssignments);
    const entryErrorById = getEntryErrorsById(sortedEntries);

    const entriesRows: Array<IEntryRow> = sortedEntries.map(entry => {
        const projectAssignment = getProjectAssignmentByProjectAndAssignmentIds(
            projectAssignments,
            entry.project_id,
            entry.assignment_id,
        );
        return {
            entry,
            userId,
            projectAssignment,
            location: locationsById[entry.location_id ?? ''],
            position: positionsById[entry.position_id ?? ''],
            department: departmentsById[entry.department_id ?? ''],
            assignment: assignmentsById[entry.assignment_id],
            activity: activitiesById[entry.activity_id ?? ''],
            jobNumber: jobNumbersById[entry.job_number_id ?? ''],
            task: tasksById[entry.task_id],
            error: entryErrorById[entry.id],
        };
    });

    const timeSheetIds = useMemo(() => {
        return [...new Set(entries.filter(entry => entry.entry_type === EntryType.TIME).map(entry => entry.sheet_id))];
    }, [entries]);
    const isPerDiem = useMemo(() => {
        return !!entries.find(entry => entry.is_per_diem);
    }, [entries]);
    const isHoliday = useMemo(() => {
        return !!entries.find(entry => entry.is_holiday_time);
    }, [entries]);

    const [currentCollapsed, setCollapsed] = useState(collapsed);
    const collapseSheetsHandler = useCallback(() => {
        setCollapsed(!currentCollapsed);
    }, [currentCollapsed]);

    const isCollapsibleFeatureEnabled = optimizely.isFeatureEnabled(FeatureSwitches.enabledCollapsingDayEntriesInSheet);
    const isCollapsed = isCollapsibleFeatureEnabled && isCollapsible && currentCollapsed;

    return isLoading || entriesRows.length > 0 ? (
        <>
            <Box>
                {!isLoading && (
                    <EntriesTableHeader
                        date={date}
                        entryFilter={entryFilter}
                        entries={sortedEntries}
                        timeSheetIds={timeSheetIds}
                        isFileView={isFileEntriesView}
                        isPerDiem={isPerDiem}
                        isHoliday={isHoliday}
                        actionsEnabled={headerActionsEnabled}
                        onCollapse={collapseSheetsHandler}
                        collapsed={currentCollapsed}
                        isCollapsible={isCollapsible && isCollapsibleFeatureEnabled}
                    />
                )}
                {!isCollapsed && (
                    <Box className={clsx(
                        isLoading ? classes.tableWrapperLoading : '',
                    )}>
                        <GridTable
                            getKey={row => row.entry.id}
                            getRowId={getEntryRowIdByRow}
                            headerCellClassName={classes.headerCellClassName}
                            isLoading={isLoading}
                            rowData={entriesRows}
                            cells={cells}
                            hideHeader={hideHeader}
                            stickyHeader
                        />
                    </Box>
                )}
            </Box>
        </>
    ) : null;
}

/* eslint-disable react/display-name */
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { Box } from '@material-ui/core';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import DayOfWeekDate from 'shared/components/toolbar/dayOfWeekDate/dayOfWeekDate';
import ToolbarEntry from 'shared/components/toolbar/ToolbarEntry';
import TotalNum from 'shared/components/toolbar/totalNum/totalNum';
import { EntryType } from 'shared/models/sheet/Sheet';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';
import { IActivity, IProjectWithAssignment, ITask, renderTaskInfo } from 'store/entities/configuration/configurationModel';
import { useGroupedPayrollEntries } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/utils';
import { ICalculationEntry, IGroupedSheetCalculation } from 'modules/payrollProcessorHub/store/model';
import { IPayCode } from 'modules/settings/submodules/clients/payCodes/store/model';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import { PayRateType } from 'shared/models/Position';
import { useFeature } from '@optimizely/react-sdk';
import { AppClient } from 'utils/constants';
import { FeatureSwitches } from 'utils/featureSwitches';
import { ReceiptCellCommon } from 'shared/components/table/EntriesTable/cellsComponents/ReceiptCellCommon';
import { IAttachment } from 'shared/models/Attachments';

export interface ISheetEntryTableProp {
    sheetGroup: IGroupedSheetCalculation;
    type: EntryType;
}

export interface IPayrollTimeExpenseRow extends ICalculationEntry {
    className?: string;
    activity?: IActivity;
    payCode?: IPayCode;
    projectAssignment?: IProjectWithAssignment | null;
    task?: ITask;
    pay_rate?: string;
    hours: string;
    files?: number;
    sheet_entry_attachments?: IAttachment[];
    amount: string;
}

export interface IPayrollExpenseRowGroup {
    entryDate: moment.Moment;
    totalHours: string;
    totalAmount: string;
    items: IPayrollTimeExpenseRow[];
}

export const SheetEntryTable = ({ sheetGroup, type }: ISheetEntryTableProp) => {
    const [displayPPHReceiptColumn] = useFeature(FeatureSwitches.displayPPHReceiptColumn);
    const classes = useEntriesTableStyles();
    const groupHasFiles = Boolean(sheetGroup?.time_files);
    const cells: ICellInfo<IPayrollTimeExpenseRow>[] = [
        ...(process.env.REACT_APP_CLIENT === AppClient.RTI ? [
            {
                key: 'project_assignment',
                title: 'project/assignment',
                render: ({ className, projectAssignment }: IPayrollTimeExpenseRow) =>
                    <PlainText className={className} value={projectAssignment?.description}/>,
            },
            {
                key: 'task',
                title: 'task',
                render: ({ className, task }: IPayrollTimeExpenseRow) =>
                    <PlainText className={className} value={renderTaskInfo(task)}/>,
            },
        ] : []),
        {
            key: 'activity',
            title: 'activity',
            render: ({ className, activity }: IPayrollTimeExpenseRow) =>
                <PlainText className={className} value={activity?.description}/>,
        },
        {
            key: 'paycode',
            title: 'paycode',
            render: ({ className, payCode }: IPayrollTimeExpenseRow) =>
                <PlainText className={className} value={payCode?.description}/>,
        },
    ];
    if (type === EntryType.TIME) {
        cells.push(
            {
                key: 'payRate',
                title: 'pay rate',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, pay_rate }: IPayrollTimeExpenseRow) => (
                    <PlainText
                        className={clsx(className, classes.amountBodyCell)}
                        value={formatPayRate({
                            pay_rate_value: Number(pay_rate),
                            pay_rate_type: PayRateType.PER_HOUR,
                        })}
                    />
                ),
            },
            ...(groupHasFiles ? [{
                key: 'files',
                title: 'files',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, files }: IPayrollTimeExpenseRow) => (
                    <PlainText
                        className={clsx(className, classes.amountBodyCell)}
                        value={formatFiles(files)}
                    />
                ),
            }] : []),
            {
                key: 'hours',
                title: 'hours',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, hours }: IPayrollTimeExpenseRow) => (
                    <PlainText
                        className={clsx(className, classes.amountBodyCell)}
                        value={formatDecimalHoursStringAsHoursAndMinutes(hours)}
                    />
                ),
            });
    } else {
        cells.push(
            ...(displayPPHReceiptColumn ? [{
                key: 'receipt',
                title: 'receipt',
                width: '74px',
                render: ({ className, sheet_entry_attachments }: IPayrollTimeExpenseRow) => (
                    <Box className={className}>
                        <ReceiptCellCommon files={sheet_entry_attachments || []}/>
                    </Box>
                ),
            }] : []),
            {
                key: 'amount',
                title: 'amount',
                headerClassName: classes.hoursAmountHeader,
                render: ({ className, amount }: IPayrollTimeExpenseRow) =>
                    <PlainText className={clsx(className, classes.amountBodyCell)} value={formatDollars(amount)}/>,
            },
        );
    }

    const groupsByDay = useGroupedPayrollEntries(sheetGroup, type);

    return (
        <Box>
            {groupsByDay.map(group => (
                <Box key={`${type}-${group.entryDate}`}>
                    <ToolbarEntry classes={{ root: classes.toolbar }}>
                        <DayOfWeekDate date={group.entryDate}/>
                        {type === EntryType.TIME ? (
                            <TotalNum
                                value={group.totalHours}
                                label={'hrs'}
                            />
                        ) : (
                            <TotalNum
                                value={group.totalAmount}
                                currency={'$'}
                            />
                        )}
                    </ToolbarEntry>
                    <GridTable
                        getKey={row => row.sheet_entry_id}
                        headerCellClassName={classes.headerCellClassName}
                        rowData={group.items}
                        cells={cells}
                        stickyHeader
                    />
                </Box>
            ))}
        </Box>
    );
};

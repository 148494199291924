import React from 'react';
import { Box, Button } from '@material-ui/core';
import { usePayrollModalStyle } from './PayrollModalStyle';
import PostPayrollReport from './reports/PostPayrollReport';

interface IPostPayrollModal {
    close: () => void;
}

const PostPayrollModal = ({ close }: IPostPayrollModal) => {
    const classes = usePayrollModalStyle();

    return (
        <Box className={classes.ContentWrapper}>
            <PostPayrollReport/>
            <Box className={classes.ButtonWrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={close}
                >
                    Close
                </Button>
            </Box>
        </Box>
    );
};

export default PostPayrollModal;

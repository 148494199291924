import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { IDeal } from 'shared/models/Deal';
import { useSelector } from 'react-redux';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectDeals } from 'store/entities/configuration/configurationSelectors';
import { sortBy } from 'lodash';

interface IDealNumberSelectStoreProps {
    deals?: IDeal[];
}

interface IDealNumberSelectProps extends IFormFieldProps, IFormSelect {
    clientId?: string;
}

export const DealNumberSelectPure = ({ deals, ...props }: IDealNumberSelectProps & IDealNumberSelectStoreProps) => {
    return (
        <FormSelect
            getKey={(deal: IDeal) => deal.id}
            getText={(deal: IDeal) => deal.deal_number.toString()}
            options={deals || []}
            {...props}
        />
    );
};

export const DealNumberSelect = separateLogicDecorator<IDealNumberSelectProps, IDealNumberSelectStoreProps>(
    ({ clientId }) => ({
        deals: sortBy(
            useSelector(selectDeals).filter(deal => clientId ? deal.client_id === clientId : true),
            deal => deal.deal_number,
        ),
    }),
)(DealNumberSelectPure);

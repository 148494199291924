export enum FeatureSwitches {
    enableOfferLetters = 'enableofferletters',
    enableOfferLetterTemplates = 'enableofferlettertemplates',
    enableEditingEmployeeNameOnOfferLetterCreation = 'enableeditingemployeenameonofferlettercreation',
    enableDepartmentInOfferLetter = 'enabledepartmentinofferletter',
    enableClientDepartmentManagement = 'enableclientdepartmentmanagement',
    enableOfferLetterEdit = 'enableofferletteredit',
    enableOfferLetterCard = 'offerletterscard',
    enableOfferLetterRescission = 'enableofferletterrescission',
    displayStatusColumnApproveView = 'displaystatuscolumnapproveview',
    displayStatusColumnSubmitView = 'displaystatuscolumnsubmitview',
    displayFilters = 'displayfilters',
    displaypayrollbutton= 'displaypayrollbutton',
    testMonitoringPage = 'testmonitoringpage',
    enableSettingsModule = 'enablesettingsmodule',
    enableClientPositionsManagement = 'enableclientpositionsmanagement',
    enableClientWorkLocationManagement = 'enableclientworklocationmanagement',
    enablePositionWorkLocationAssociationManagement = 'enablepositionworklocationassociationmanagement',
    enableClientPhysicalDemandsManagement = 'enableclientphysicaldemandsmanagement',
    enableClientWorkingConditionsManagement = 'enableclientworkingconditionsmanagement',
    enableBackgroundCheckTemplatesManagement = 'enablebackgroundchecktemplatesmanagement',
    enableOfferLetterTemplatePartsOptional = 'enableofferlettertemplatepartsoptional',
    enableClientPayCodesManagement = 'enableclientpaycodesmanagement',
    enableClientActivitiesManagement = 'enableclientactivitiesmanagement',
    enablePayrollProcessorHub = 'enablepayrollprocessorhub',
    authResetPassword = 'authresetpassword',
    enableEmployeeDashboard = 'enableemployeedashboard',
    enableManagerDashboard = 'enablemanagerdashboard',
    enablePlatformUsersManagement = 'enableplatformusersmanagement',
    enableEmployeeManagement = 'enableemployeemanagement',
    inOutTimeSheet = 'inouttimesheet',
    enableEmployeePortal = 'enableemployeeportal',
    enableManagementOfTimeAndPaySettings = 'enablemanagementoftimeandpaysettings',
    enableManagementOfAppConfiguration = 'enablemanagementofappconfiguration',
    enableGenworthSheets = 'enablegenworthsheets',
    enabledCollapsingDayEntriesInSheet = 'enabledcollapsingdayentriesinsheet',
    displayActivityShortDescription = 'displayactivityshortdescription',
    displayActivityShowZipCode = 'displayactivityshowzipcode',
    enableActivityNonProduction = 'enableactivitynonproduction',
    enablePPHFilterByManager='enablepphfilterbymanager',
    enableTotalClientConfiguration='enabletotalclientconfiguration',
    disablePayPeriodEndFilterDefaultValue='disablepayperiodendfilterdefaultvalue',
    showGovernmentalConfirmationOnSubmitSheet='showgovermentalconfirmationonsubmitsheet',
    enableAlwaysDisplayContinueOnboarding='enablealwaysdisplaycontinueonboarding',
    enableMaintainceWorkMessage='enablemaintainceworkmessage',
    enableAlertAboutPossibleErrorsOnOnboardingPage='enablealertaboutpossibleerrorsononboardingpage',
    displayPPHReceiptColumn='displaypphreceiptcolumn',
    disableThirdPartyCookiesChecking='disablethirdpartycookieschecking',
    enablePrismSSODomainReplace='enableprismssodomainreplace',
    enable3rdPartyAlertOnOnboardingPage='enable3rdpartyalertononboardingpage',
    enable3rdPartyLinkButtonOnOnboardingPage='enable3rdpartylinkbuttonononboardingpage',
    enableFrontendRefresher='enablefrontendrefresher',
    enableChangingActivityStatus='enablechangingactivitystatus',
    displaySyncPaySettingsButton='displaysyncpaysettingsbutton',
    enableInactiveListener='enableinactivelistener',
    displayDefaultPropertyInSettingsActivities='displaydefaultpropertyinsettingsactivities',
    enableControllingOrgStatusChange='enablecontrollingorgstatuschange',
    enableClientLogoUpdate='enableclientlogoupdate',
    enableLoginAsUserButton='enableloginasuserbutton',
    enableCustomFieldsModule='enablecustomfieldsmodule',
}

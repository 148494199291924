import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import { useMemo } from 'react';

function getTemplateFunction(
    hasTimesheet: boolean,
    hasExpenseSheet: boolean,
) {
    return (
        timesheetStr: string,
        expenseSheetStr: string,
        bothStr: string,
    ): string => {
        let result = '';
        if (hasTimesheet) {
            result = timesheetStr;
        }
        if (hasExpenseSheet) {
            result = expenseSheetStr;
        }
        if (hasTimesheet && hasExpenseSheet) {
            result = bothStr;
        }
        return ` ${result} `;
    };
}

export const useOptionalTimeExpenseReportTemplate = (groupIds: ISheetGroupId[]) => {
    return useMemo(() => {
        const { hasTimesheet, hasExpenseSheet } = groupIds.reduce(
            (mem, groupId) => {
                if (groupId.timeSheetId) {
                    mem.hasTimesheet = true;
                }
                if (groupId.expenseSheetId) {
                    mem.hasExpenseSheet = true;
                }
                return mem;
            }, {
                hasTimesheet: false,
                hasExpenseSheet: false,
            });
        return getTemplateFunction(hasTimesheet, hasExpenseSheet);
    }, [groupIds]);
};

import { IStore } from 'store/configureStore';
import { selectTimeAndExpenseState } from 'modules/timeAndExpense/store/selectors';
import { ISelectorPanelEntry } from 'modules/timeAndExpense/components/AddEntry/store/models';
import {
    selectDealsById,
    selectSubmittingOrgsById,
    selectTaskById,
} from 'store/entities/configuration/configurationSelectors';
import { IDeal } from 'shared/models/Deal';

const selectAddEntryState = (state: IStore) => {
    const timeAndExpenseState = selectTimeAndExpenseState(state);
    return timeAndExpenseState.addEntry;
};

export const selectCommonEntryFormValues = (state: IStore) => {
    const addEntryState = selectAddEntryState(state);
    return addEntryState.commonEntryFormValues;
};

export const selectSelectorsPanelEntries = (state: IStore) => {
    const commonEntryFormValues = selectCommonEntryFormValues(state);

    const dealsById = selectDealsById(state);
    const submittingOrgsById = selectSubmittingOrgsById(state);

    const deal = dealsById[commonEntryFormValues.jobNumber?.deal_id] as IDeal;
    const jobSiteLocation = submittingOrgsById[deal?.submitting_org_id];

    const task = selectTaskById(commonEntryFormValues.taskId)(state);

    const selectorsPanelEntries: ISelectorPanelEntry[] = [
        {
            value: commonEntryFormValues.location?.name,
            key: 'location',
            title: 'Location',
        },
        {
            value: commonEntryFormValues.position?.name,
            key: 'position',
            title: 'Position',
        },
        {
            value: commonEntryFormValues.jobNumber?.job_number,
            key: 'jobNumber',
            title: 'Job Number',
        },
        {
            value: jobSiteLocation?.client_site_name,
            title: 'Job Site Location',
            key: undefined,
        },
        {
            value: commonEntryFormValues.projectAssignment?.description,
            key: 'projectAssignment',
            title: 'Project + Assign',
        },
        {
            value: task?.description,
            key: 'taskId',
            title: 'Task',
        },
        {
            value: commonEntryFormValues.activity?.description,
            key: 'activity',
            title: 'Activity',
        },
    ].filter(panelEntry => panelEntry.value);
    return selectorsPanelEntries;
};

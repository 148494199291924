import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useSettingsFormModalStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
    },
    paper: {
        minWidth: '750px',
        maxWidth: 'unset',
        backgroundColor: `${colors.white} !important`,
        boxShadow: theme.shadows[5],
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start !important',
        position: 'relative',
        backgroundColor: theme.palette.secondary.main,
        color: colors.white,
        '& > h4': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
        },
    },
    closeIcon: {
        position: 'absolute',
        top: '50%',
        right: theme.spacing(0.5),
        transform: 'translateY(-50%)',
        color: colors.white,
    },
    content: {
        padding: 0,
        margin: 0,
    },
}));

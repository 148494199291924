import React, { useCallback, useRef } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import {
    Box, Button, ClickAwayListener, Popper,
} from '@material-ui/core';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { useOfferLetterHeaderStyles } from 'modules/offerLetter/components/OfferLetterHeader/OfferLetterHeaderStyles';
import FormSelect from 'shared/components/selects/FormSelect';
import { Location } from 'shared/components/selects/Location';
import { Position } from 'shared/components/selects/Position';
import { shortDateFormat } from 'shared/models/Dates';
import { useModal } from 'shared/utils/hooks/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { IMenuItemType } from 'shared/models/MenuItemType';
import { selectOfferLetterFilters, selectStartDates } from 'modules/offerLetter/store/selectors';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { IOfferLetterFilters } from 'modules/offerLetter/store/model';
import { UserType } from 'store/entities/users/model';

export const offerLetterFilterTestId = {
    openFilterButton: 'open-offer-letter-filter-button',
    applyFilterButton: 'apply-offer-letter-filter-button',
};

export default function OfferLetterFilters() {
    const dispatch = useDispatch();

    const actualFilter = useSelector(selectOfferLetterFilters);
    const startDates = useSelector(selectStartDates);
    const startDateOptions: Array<IMenuItemType<string>> = Object.values(startDates).map(startDate => ({
        text: moment(startDate).format(shortDateFormat),
        value: startDate,
    }));

    const classes = useOfferLetterHeaderStyles();
    const { isModalOpened, onModalClose, onModalOpen } = useModal();
    const buttonRef = useRef(null);

    const applyFilter = useCallback((values: IOfferLetterFilters) => {
        dispatch(setOfferLetterFilters(values));
        onModalClose();
    }, [dispatch, onModalClose]);

    return (
        <>
            <Button
                className={classes.filterButton}
                ref={buttonRef}
                onClick={onModalOpen}
                data-testid={offerLetterFilterTestId.openFilterButton}
            >
                <FilterSVG/>
            </Button>
            <Popper
                open={isModalOpened}
                anchorEl={buttonRef.current}
                placement="bottom-end"
            >

                <Formik
                    initialValues={actualFilter}
                    onSubmit={applyFilter}
                    validateOnBlur={false}
                >
                    {props => (
                        <ClickAwayListener onClickAway={onModalClose}>
                            <form onSubmit={props.handleSubmit}>
                                <Box
                                    className={classes.filterRoot}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Position
                                        name="positionId"
                                        label="Position"
                                        useIdValue
                                    />
                                    <Location
                                        name="locationId"
                                        label="Work Location"
                                        useIdValue
                                    />
                                    <FormSelect
                                        name="startDate"
                                        label="Start Date"
                                        useIdValue
                                        getKey={(option: IMenuItemType<string>) => option.value}
                                        getText={(option: IMenuItemType<string>) => option.text}
                                        options={startDateOptions}
                                    />
                                    <UserAutocompleteWithSearch
                                        additionalFilter={{
                                            user_type: UserType.Manager,
                                        }}
                                        name="creatorId"
                                        label="Created by"
                                        useIdValue
                                    />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.filterApplyButton}
                                        type="submit"
                                        data-testid={offerLetterFilterTestId.applyFilterButton}
                                    >
                                        Apply
                                    </Button>
                                </Box>
                            </form>
                        </ClickAwayListener>
                    )}
                </Formik>
            </Popper>
        </>
    );
}

import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { AutocompleteClassKey } from '@material-ui/lab';
import { CSSProperties } from '@material-ui/styles';

interface ICoreAndLabThemeOverrides extends CoreOverrides {
    // https://github.com/mui-org/material-ui/issues/12164#issuecomment-564041219
    // Define additional lab components here as needed
    MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>>;
    MuiPickersTimePickerToolbar? :Partial<Record<string, CSSProperties | (() => CSSProperties)>>;
}

const initialTheme = createMuiTheme({
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: colors.secondary,
        },
    },
    typography: {
        fontSize: 16,
        h1: {
            // actually not used - can be adjusted
            fontSize: 32,
        },
        h2: {
            // actually not used - can be adjusted
            fontSize: 28,
            marginBlockStart: '0.83em',
            marginBlockEnd: '0.83em',
            fontWeight: 700,
        },
        h3: {
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: 24,
            letterSpacing: 0.5,
        },
        h4: {
            fontSize: 20,
            marginBlockStart: '0.83em',
            marginBlockEnd: '0.83em',
            fontWeight: 500,
        },
        h5: {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: 1,
        },
        h6: {
            textTransform: 'uppercase',
            fontSize: 16,
        },
        subtitle1: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '24px',
        },
        subtitle2: {
            letterSpacing: 0,
        },
        body1: {
            fontSize: 16,
        },
        body2: {
            fontSize: 14,
        },
        caption: {
            color: colors.darkGray,
        },
        button: {
            textTransform: 'uppercase',
        },
    },
    shape: {
        borderRadius: 0,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

const getOverrides = (baseTheme: Theme): ICoreAndLabThemeOverrides => ({
    MuiButton: {
        root: {
            padding: baseTheme.spacing(1, 3),
            fontSize: 14,
        },
        contained: {
            boxShadow: 'none',
            '&:active': {
                boxShadow: 'none',
            },
        },
        outlined: {
            padding: baseTheme.spacing(1, 3),
            fontSize: 14,
        },
        text: {
            textTransform: 'none',
        },
    },
    MuiTabs: {
        root: {
            marginLeft: baseTheme.spacing(1),
        },
        indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: baseTheme.palette.common.white,
        },
    },
    MuiTab: {
        root: {
            textTransform: 'none',
            margin: baseTheme.spacing(0, 2),
            minWidth: 0,
            padding: 0,
            [baseTheme.breakpoints.up('md')]: {
                padding: 0,
                minWidth: 0,
            },
        },
    },
    MuiIconButton: {
        root: {
            padding: baseTheme.spacing(.5),
        },
    },
    MuiTooltip: {
        tooltip: {
            borderRadius: 4,
        },
    },
    MuiDivider: {
        root: {
            backgroundColor: colors.rhino,
        },
    },
    MuiListItemText: {
        primary: {
            fontWeight: baseTheme.typography.fontWeightMedium,
        },
    },
    MuiListItemIcon: {
        root: {
            color: 'inherit',
            marginRight: 0,
        },
    },
    MuiAvatar: {
        root: {
            width: 32,
            height: 32,
            fontSize: '12px !important',
        },
    },
    MuiInputLabel: {
        root: {
            fontSize: 14,
            '&$error': {
                color: baseTheme.palette.text.secondary,
            },
            '&$error$disabled': {
                color: baseTheme.palette.text.disabled,
            },
        },
        shrink: {
            '&$error': {
                color: colors.error,
            },
        },
        outlined: {
            transform: 'translate(8px, 14px)',
        },
    },
    MuiSelect: {
        outlined: {
            padding: baseTheme.spacing(0, 2, 0, 1),
            '&:not(.Mui-disabled)': {
                color: colors.darkGray,
                borderColor: colors.gray,
            },
            '&&': {
                paddingRight: baseTheme.spacing(2),
            },
        },
        icon: {
            top: 'auto',
            right: 0,
            color: colors.darkGray,
            fontSize: '1em',
        },
        iconOutlined: {
            right: 0,
        },
    },
    MuiOutlinedInput: {
        input: {
            padding: baseTheme.spacing(0, 1),
            fontSize: 14,
            minHeight: baseTheme.spacing(5),
        },
    },
    MuiFormHelperText: {
        root: {
            fontSize: 12,
            margin: baseTheme.spacing(0.5, 0, 0),
        },
        contained: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    MuiAutocomplete: {
        input: {
            padding: baseTheme.spacing(0, 2, 0, 1),
            height: baseTheme.spacing(5),
            boxSizing: 'border-box',
        },
        inputRoot: {
            fontSize: 14,
            padding: baseTheme.spacing(0, 2, 0, 1),
            '&[class*="MuiOutlinedInput-root"]': {
                padding: baseTheme.spacing(0, 2, 0, 1),
            },
        },
        popupIndicator: {
            top: 'auto',
            right: 0,
            color: colors.darkGray,
            fontSize: '1em',
        },
        endAdornment: {
            right: 0,
        },
    },
    MuiPickersTimePickerToolbar: {
        hourMinuteLabel: {
            alignItems: 'center',
        },
    },
    MuiSvgIcon: {
        fontSizeSmall: {
            fontSize: 24,
        },
    },
});

export default ((baseTheme: Theme) => {
    return createMuiTheme({
        ...baseTheme,
        overrides: getOverrides(baseTheme),
    });
})(initialTheme);

import { IStore } from 'store/configureStore';
import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';
import { createSelector } from 'reselect';
import { selectDeals } from 'store/entities/configuration/configurationSelectors';

const selectDealFormState = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.dealForm;
};

export const selectRedirectToJobNumberWithDealNumber = (state: IStore) => {
    const dealFormState = selectDealFormState(state);
    return dealFormState.redirectToJobNumberWithDealNumber;
};

export const selectRedirectToJobNumberDeal = (
    createSelector(
        selectRedirectToJobNumberWithDealNumber,
        selectDeals,
        (redirectToJobNumberWithDealNumber, deals) => {
            return deals.find(deal => deal.deal_number === redirectToJobNumberWithDealNumber);
        },
    )
);

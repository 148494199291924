import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingHeader } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingHeader';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { WorkOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useOfferLetterStyles } from 'modules/offerLetter/OfferLetterStyles';
import { getMyEmployeeProfile, getPrismSSOLink } from 'modules/offerLetter/store/actions';
import {
    selectIsSSOLikLoading, selectSSOLink,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import { selectMyEmployeeProfileByClientId } from 'modules/offerLetter/store/selectors';
import { routes } from 'shared/routes';
import { thirdPartyCookiesCheck } from 'utils/thirdPartyCookiesCheck';
import { navigateIframedHost } from '../../helpers/navigateIframedHost';
import { FeatureSwitches } from 'utils/featureSwitches';
import FeatureSwitch from '../../../../shared/components/common/FeatureSwitch';
import { useFeature } from '@optimizely/react-sdk';
import { isIOs, isSafari } from 'utils/envCheck';
import { nbsp } from 'utils/constants';

export interface IOnboardingStep {
    clientId: string;
    offerLetterId: string;
}

export const OnboardingStep = ({ clientId, offerLetterId }: IOnboardingStep) => {
    const classes = useOfferLetterStyles();
    const dispatch = useDispatch();
    const myProfile = useSelector(selectMyEmployeeProfileByClientId(clientId));
    const [iframeLoading, setIframeLoading] = useState(true);
    const [cookiesEnabled, setCookiesEnabled] = useState(true);
    const isSSOLinkLoading = useSelector(selectIsSSOLikLoading);
    const isLoading = iframeLoading || isSSOLinkLoading;
    const link = useSelector(selectSSOLink);

    useEffect(() => {
        dispatch(getPrismSSOLink.init(clientId));
    }, [dispatch, clientId, offerLetterId]);

    useEffect(() => {
        thirdPartyCookiesCheck().then(({ supported }) => {
            setCookiesEnabled(supported);
        });
    }, [setCookiesEnabled]);

    useEffect(() => {
        const profileRequestInterval = 2000;
        const interval = setInterval(() => {
            dispatch(getMyEmployeeProfile.init(clientId));
        }, profileRequestInterval);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, clientId]);

    const onLoad = (evt: React.SyntheticEvent) => {
        navigateIframedHost(routes.EMPLOYEE_OFFER_LETTER.ROOT)(evt);
        setIframeLoading(false);
    };

    const [featureExtAlert, featureVars] = useFeature(FeatureSwitches.enableAlertAboutPossibleErrorsOnOnboardingPage);
    const hasAlertAboutIssues = useMemo(() => {
        if (isIOs) {
            return featureVars.messageIOS;
        }
        if (isSafari) {
            return featureVars.messageSafari;
        }
        return '';
    }, [featureVars]);

    const [thirdPartyAlertEnabled] = useFeature(FeatureSwitches.enable3rdPartyAlertOnOnboardingPage);
    const [thirdPartyLinkButtonEnabled] = useFeature(FeatureSwitches.enable3rdPartyLinkButtonOnOnboardingPage);

    return (
        <Box className={classes.onboardingContainer}>
            <OnboardingHeader
                icon={<WorkOutlined fontSize="small" className={classes.headerIcon}/>}
                title="3. Complete Onboarding"
            />
            <FeatureSwitch feature={FeatureSwitches.enableAlertAboutPossibleErrorsOnOnboardingPage} noPlaceholder>
                {hasAlertAboutIssues && (
                    <Box m={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Alert severity="error">{hasAlertAboutIssues}</Alert>
                    </Box>
                )}
            </FeatureSwitch>
            {!featureExtAlert && (
                <>
                    {cookiesEnabled ? (
                        <>
                            {isLoading && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                    height="100%"
                                    flexGrow={1}
                                >
                                    <CircularProgress/>
                                </Box>
                            )}
                            {link && (
                                <iframe
                                    src={link}
                                    title="Onboarding"
                                    frameBorder="0"
                                    height={isLoading ? 0 : '100%'}
                                    width="100%"
                                    onLoad={onLoad}
                                />
                            )}
                            {myProfile?.headway_onboarding_completed && (
                                <Box
                                    m={2}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Box className={classes.finishButtonContainer}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            href={routes.EMPLOYEE_OFFER_LETTER.ROOT}
                                        >
                                            Finished
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box m={3}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Box>
                                {
                                    thirdPartyAlertEnabled && (
                                        <Alert severity="error">
                                        Cookies are required to complete onboarding. <br/>
                                        Please enable the third party cookie in your browser and reload the page.<br/>
                                            <br/>
                                            <b>For IOS (Safari):</b>{nbsp}Go to Settings -&gt; Safari
                                    -&gt; Uncheck Prevent Cross-Site Tracking<br/>
                                            <b>For Chrome:</b>
                                            {nbsp}Go to Settigns -&gt; Privacy and security -&gt; Site settings
                                        -&gt; Cookies and other site data -&gt; Uncheck Block third-party cookies
                                        </Alert>
                                    )
                                }

                                {
                                    thirdPartyLinkButtonEnabled && (
                                        <Box mt={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                href={ link }>
                                                Complete Onboarding
                                            </Button>
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

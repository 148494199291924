import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { HeaderTabs } from 'shared/components/common/headerTabs/HeaderTabs';
import { setOfferLetterFilters } from 'modules/offerLetter/store/actions';
import { selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';
import { Box, Link, Button, Hidden } from '@material-ui/core';
import { offerLetterTabsSorted } from 'modules/offerLetter/store/model';
import { Permission } from 'store/components/auth/authModels';
import { selectUserPermissions } from 'store/components/auth/selectors';
import { routes } from 'shared/routes';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { browserHistory } from 'shared/utils/browserHistory';

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    createOfferButton: {
        width: theme.spacing(15),
        height: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(5.5),
            marginRight: theme.spacing(-2),
        },
    },
    createOfferButtonLabel: {
        fontSize: 12,
    },
}));

const OfferLetterTabs = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userPermissions = useSelector(selectUserPermissions);
    const clientId = useSelector(selectCurrentClientId);
    const filter = useSelector(selectOfferLetterFilters);
    const onTabChange = useCallback(statusSlug => {
        if (clientId) {
            browserHistory.push(generatePath(routes.CLIENT.OFFER_LETTER.ROOT, { client_id: clientId }));
        }
        dispatch(setOfferLetterFilters({
            ...filter,
            statusSlug,
        }));
    }, [dispatch, filter, clientId]);

    return (
        <>
            <HeaderTabs
                tabs={offerLetterTabsSorted}
                mobileHeader="Offer Letter Status"
                value={filter.statusSlug}
                onChange={onTabChange}
            />
            {clientId && userPermissions?.includes(Permission.CreateOffer) && (
                <Hidden xsDown>
                    <Box
                        display="flex"
                        height="100%"
                        mr={2}
                        flexDirection="column"
                        justifyContent="flex-end"
                    >
                        <Link
                            component={RouterLink}
                            to={generatePath(routes.CLIENT.OFFER_LETTER.CREATE, { client_id: clientId })}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                classes={{
                                    root: classes.createOfferButton,
                                    label: classes.createOfferButtonLabel,
                                }}
                            >
                                + Create Offer
                            </Button>
                        </Link>
                    </Box>
                </Hidden>
            )}
        </>
    );
};

export default OfferLetterTabs;

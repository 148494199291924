import React, { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import Total, { TotalTypes } from 'shared/components/toolbar/total/Total';
import { Box, Hidden } from '@material-ui/core';
import { useTotalInfoStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/components/TotalInfo/TotalInfoStyles';
import DottedDivider from 'shared/components/divider/DottedDivider';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatHours, formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import TotalInfoTab
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/totalInfoTab/TotalInfoTab';
import { EntryType, IEntry } from 'shared/models/sheet/Sheet';
import {
    totalBreakTime,
    totalFiles,
    totalHolidays,
    totalOverTimeUnits,
    totalTime,
} from 'shared/utils/counters/timeCounter';
import { useSelector } from 'react-redux';
import { ActivityDictionary } from 'shared/models/Activity';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { ItemsById } from 'shared/models/ItemsById';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { selectCalculationsByTimesheetId } from 'store/entities/timesheet/selectors';
import { TotalSlug } from 'store/entities/clients/clientsModel';
import {
    ITotalConfigurationByTotalSlug,
    selectTotalConfigurationByTotalSlug,
} from 'store/entities/clients/clientsSelectors';
import { optimizely } from 'utils/optimizely';
import { FeatureSwitches } from 'utils/featureSwitches';
import { formatDecimalHoursAsHoursAndMinutes } from 'shared/models/DateTime';

interface ITotalInfoProps {
    entryFilter?: EntryType;
    entries: IEntry[];
}

interface ITotalInfoStoreProps {
    activitiesById?: ItemsById<IActivity>;
    fieldsBySlug?: ITotalConfigurationByTotalSlug;
    calculationByTimeSheet?: Record<string, ITimesheetCalculation>
}

export function TotalInfoPure({
    entryFilter,
    entries,
    fieldsBySlug,
    activitiesById = {},
    calculationByTimeSheet = {},
}: ITotalInfoProps & ITotalInfoStoreProps) {
    const classes = useTotalInfoStyles();

    const hasTime = Boolean(fieldsBySlug?.[TotalSlug.Time]);
    const hasOverTime = Boolean(fieldsBySlug?.[TotalSlug.OverTime]);
    const hasDoubleTime = Boolean(fieldsBySlug?.[TotalSlug.DoubleTime]);
    const hasBreaks = Boolean(fieldsBySlug?.[TotalSlug.Break]);
    const hasHolidays = Boolean(fieldsBySlug?.[TotalSlug.Holidays]);
    const hasExpense = Boolean(fieldsBySlug?.[TotalSlug.Expense]);
    const hasCompletes = Boolean(fieldsBySlug?.[TotalSlug.Completes]);
    const hasTrailingDoc = Boolean(fieldsBySlug?.[TotalSlug.TrailingDoc]);
    const hasNewLoan = Boolean(fieldsBySlug?.[TotalSlug.NewLoan]);
    const hasFiles = Boolean(fieldsBySlug?.[TotalSlug.Files]);

    const completesAmount = useMemo(() => {
        return hasCompletes
            ? (
                entries.filter(
                    entry => activityHasCompletes(activitiesById[entry.activity_id ?? '']),
                ).reduce(
                    (acc: number, entry) => acc + completesByNotes(entry.notes || ''), 0,
                )
            )
            : 0;
    }, [hasCompletes, activitiesById, entries]);

    const trailingDocAmount = useMemo(() => (
        hasTrailingDoc
            ? entries.filter(entry => (
                activitiesById[entry.activity_id ?? '']?.description === ActivityDictionary.TrailingDoc
            )).length
            : 0
    ), [activitiesById, entries, hasTrailingDoc]);
    const newLoanAmount = useMemo(() => (
        hasNewLoan
            ? entries.filter(entry => (
                activitiesById[entry.activity_id ?? '']?.description === ActivityDictionary.NewLoan
            )).length
            : 0
    ), [activitiesById, entries, hasNewLoan]);

    const hasTimeEntries = useMemo(() => {
        return !!entries.find(entry => entry.entry_type === EntryType.TIME);
    }, [entries]);

    const formattedTime = formatMinutes(totalTime(entries));
    const formattedAmount = totalDollars(entries);
    const timeBreak = totalBreakTime(entries);
    const holidaysTime = totalHolidays(entries, calculationByTimeSheet);
    const filesAmount = totalFiles(entries);
    const sheets = uniqBy(entries, entry => entry.sheet_id).map(entry => calculationByTimeSheet[entry.sheet_id]);
    const { ot: overTimeHours, dt: doubleTimeHours, rt: regularTimeHours } = totalOverTimeUnits(sheets);

    const childComponentClass = classes.childComponent;
    const prefixItems = useMemo<React.ReactElement[]>(() => {
        const items = [];
        if (hasTrailingDoc) {
            items.push(
                <Total
                    key="trailingDoc"
                    type={TotalTypes.Count}
                    value={`${trailingDocAmount}`}
                    classes={{ root: childComponentClass }}
                    data-testId="total-trailing-doc"
                    label={fieldsBySlug?.[TotalSlug.TrailingDoc]?.unitLabel}
                />,
            );
        }
        if (hasNewLoan) {
            items.push(
                <Total
                    key="newLoan"
                    type={TotalTypes.Count}
                    value={`${newLoanAmount}`}
                    classes={{ root: childComponentClass }}
                    data-testId="total-new-loan"
                    label={fieldsBySlug?.[TotalSlug.NewLoan]?.unitLabel}
                />,
            );
        }
        items.push(<></>);
        return items;
    }, [hasNewLoan, newLoanAmount, hasTrailingDoc, trailingDocAmount, childComponentClass, fieldsBySlug]);
    const suffixItems = useMemo<React.ReactElement[]>(() => {
        const items = [];
        if (entryFilter !== EntryType.EXPENSE) {
            if (hasTimeEntries || !hasFiles) {
                if (hasFiles && filesAmount > 0) {
                    items.push(
                        <Total
                            key="files"
                            type={TotalTypes.Break}
                            value={`${filesAmount}`}
                            classes={{ root: childComponentClass }}
                            data-testId="total-files"
                            label={fieldsBySlug?.[TotalSlug.Files]?.unitLabel}
                        />,
                    );
                } else {
                    if (completesAmount > 0 && hasCompletes) {
                        items.push(
                            <Total
                                key="completes"
                                type={TotalTypes.Break}
                                value={`${completesAmount}`}
                                classes={{ root: childComponentClass }}
                                data-testId="total-completes"
                                label={fieldsBySlug?.[TotalSlug.Completes]?.unitLabel}
                            />,
                        );
                    }
                    items.push(
                        <React.Fragment key="times">
                            {hasTime && (
                                <Total
                                    key="time"
                                    type={TotalTypes.Time}
                                    value={hasOverTime ? formatHours(regularTimeHours) : formattedTime}
                                    classes={{ root: childComponentClass }}
                                    data-testId="total-time"
                                    label={fieldsBySlug?.[TotalSlug.Time]?.unitLabel}
                                />
                            )}
                            {(hasOverTime) && (
                                <Total
                                    key="overTime"
                                    type={TotalTypes.Time}
                                    value={formatHours(overTimeHours)}
                                    classes={{ root: childComponentClass }}
                                    data-testId="total-overtime"
                                    label={fieldsBySlug?.[TotalSlug.OverTime]?.unitLabel}
                                />
                            )}
                            {(hasDoubleTime) && (
                                <Total
                                    key="doubleTime"
                                    type={TotalTypes.Time}
                                    value={formatHours(doubleTimeHours)}
                                    classes={{ root: childComponentClass }}
                                    data-testId="total-doubletime"
                                    label={fieldsBySlug?.[TotalSlug.DoubleTime]?.unitLabel}
                                />
                            )}
                        </React.Fragment>,
                    );
                    if (timeBreak > 0 || hasBreaks || holidaysTime > 0 || hasHolidays) {
                        // TODO: timeBreak should be get from configuration too
                        items.push(
                            <React.Fragment key="breaks-holidays">
                                {(timeBreak > 0 || hasBreaks) && (
                                    <Total
                                        key="break"
                                        value={formatMinutes(timeBreak)}
                                        type={TotalTypes.Break}
                                        classes={{ root: childComponentClass }}
                                        data-testId="total-break"
                                        label={fieldsBySlug?.[TotalSlug.Break]?.unitLabel}
                                    />
                                )}
                                {(hasHolidays) && (
                                    <Total
                                        key="holidays"
                                        value={formatDecimalHoursAsHoursAndMinutes(holidaysTime)}
                                        type={TotalTypes.Break}
                                        classes={{ root: childComponentClass }}
                                        data-testId="total-holidays"
                                        label={fieldsBySlug?.[TotalSlug.Holidays]?.unitLabel}
                                    />
                                )}
                            </React.Fragment>,
                        );
                    }
                }
            }
        }
        if (entryFilter !== EntryType.TIME && hasExpense) {
            items.push(
                <Total
                    key="expense"
                    type={TotalTypes.Expense}
                    classes={{ root: childComponentClass }}
                    value={formattedAmount}
                    data-testId="total-expense"
                    label={fieldsBySlug?.[TotalSlug.Expense]?.unitLabel}
                />,
            );
        }
        return items.reduce<React.ReactElement[]>((acc, el, index) => {
            acc.push(el);
            if (index < items.length - 1) {
                acc.push(
                    <DottedDivider
                        key={`divider${index}`}
                        height={32}
                        customClasses={childComponentClass}
                        data-testId="total-divider"
                    />,
                );
            }
            return acc;
        }, []);
    }, [
        hasTimeEntries,
        fieldsBySlug,
        entryFilter,
        completesAmount,
        childComponentClass,
        formattedAmount,
        formattedTime,
        timeBreak,
        hasOverTime,
        hasDoubleTime,
        holidaysTime,
        hasBreaks,
        hasCompletes,
        doubleTimeHours,
        overTimeHours,
        hasExpense,
        hasHolidays,
        hasTime,
        hasFiles,
        filesAmount,
        regularTimeHours,
    ]);

    return (
        <>
            <Hidden xsDown>
                <Box className={classes.root}>
                    <Box className={classes.sides}>
                        {prefixItems}
                    </Box>
                    <Box className={classes.sides}>
                        {suffixItems}
                    </Box>
                </Box>
            </Hidden>
            <Hidden smUp>
                <Box className={classes.root}>
                    <TotalInfoTab
                        completes={completesAmount}
                        time={formattedTime}
                        timeBreak={timeBreak ? formatMinutes(timeBreak) : undefined}
                        amount={formattedAmount}
                        activeEntry={entryFilter}
                    />
                </Box>
            </Hidden>
        </>
    );
}

export const TotalInfo = separateLogicDecorator<
ITotalInfoProps,
ITotalInfoStoreProps
>(() => {
    const defaultFields = {
        [TotalSlug.Time]: { slug: TotalSlug.Time },
        [TotalSlug.Expense]: { slug: TotalSlug.Expense },
    };
    const totalFields = useSelector(selectTotalConfigurationByTotalSlug);
    const fieldsBySlug = optimizely.isFeatureEnabled(FeatureSwitches.enableTotalClientConfiguration)
        ? totalFields
        : defaultFields;

    return {
        activitiesById: useSelector(selectActivitiesById),
        fieldsBySlug,
        calculationByTimeSheet: useSelector(selectCalculationsByTimesheetId),
    };
})(TotalInfoPure);

import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IPosition } from 'shared/models/Position';
import { IFormFieldProps } from '../formFields/models';
import {
    selectAssignmentsById,
    selectPositions,
} from 'store/entities/configuration/configurationSelectors';
import { IFormSelect } from './model';
import { selectCurrentUser } from 'store/components/auth/selectors';

interface IPositionByAssignmentsProps extends IFormFieldProps, IFormSelect {
    userId?: string | null;
}

export function PositionByAssignments({ userId = null, ...props }: IPositionByAssignmentsProps) {
    const currentUser = useSelector(selectCurrentUser);
    const assignments = useSelector(selectAssignmentsById);
    const allPositions = useSelector(selectPositions);
    const filterByUserId = userId === null ? currentUser?.id : userId;

    const positionIds = Object.values(assignments)
        .filter(item => item.location_id && item.user_id === filterByUserId)
        .map(({ position_id }) => position_id);

    const positions = allPositions.filter(({ id }) => positionIds.includes(id));

    return (
        <FormSelect
            getKey={(position: IPosition) => position.id}
            getText={(position: IPosition) => position.name}
            options={positions}
            {...props}
        />
    );
}

import { IClientUserAssociationRequest } from 'modules/settings/submodules/platformUsers/store/models';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { IUpdatePlatformUserRequest } from 'shared/models/User';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { getUsers } from 'store/entities/users/actions';
import { selectUserById } from 'store/entities/users/selectors';
import { call, put, takeLatest, select } from 'typed-redux-saga';
import { api } from 'modules/settings/submodules/platformUsers/store/api';
import {
    createPlatformUser,
    getMoreUsersActions,
    setCreateUserModalState,
    createClientUsersAssociation,
    getMoreClientUsersAssociation,
    getClientUsersAssociation,
    removeClientUserAssociation,
    setClientUserAssociationFilter,
    updatePlatformUser,
    setEditPlatformUser,
    setPlatformUserStatus,
    setPlatformUsersFilter,
    clearUsersAndFilter,
} from 'modules/settings/submodules/platformUsers/store/actions';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { usersApi } from 'store/entities/users/api';
import { IGetUserRequest, UserType } from 'store/entities/users/model';
import {
    clientUserAssociationSelectors,
    pageSize,
    selectClientUserAssociationFilter,
    selectPlatformUserFilter,
    selectUserTableNextPageToLoad,
} from './selectors';

export function* createPlatformUserSaga(
    action: ReturnType<typeof createPlatformUser.init>,
) {
    const user = yield* call(api.createPlatformUser, action.payload);
    yield put(createPlatformUser.success(user));
    yield put(setCreateUserModalState(false));
    yield put(clearUsersAndFilter());
    yield put(getMoreUsersActions.init());
}

function* createPlatformUserWatcher() {
    yield* takeLatest(
        createPlatformUser.initType,
        withBackendErrorHandler(
            createPlatformUserSaga,
            createPlatformUser.error,
            'Unable to create platform user.',
        ),
    );
}

export function* updatePlatformUserSaga(
    action: ReturnType<typeof updatePlatformUser.init>,
) {
    const { id, data } = action.payload;
    const user = yield* call(api.updatePlatformUser, id, data);
    yield put(updatePlatformUser.success(user));
    yield put(getUsers.success([user]));
    yield put(setEditPlatformUser(null));
}

function* updatePlatformUserWatcher() {
    yield* takeLatest(
        updatePlatformUser.initType,
        withBackendErrorHandler(
            updatePlatformUserSaga,
            updatePlatformUser.error,
            'Unable to update platform user.',
        ),
    );
}

export function* setPlatformUserStatusSaga(
    action: ReturnType<typeof setPlatformUserStatus.init>,
) {
    const { id, status } = action.payload;
    const user = yield select(selectUserById(id));
    if (!user) {
        throw new Error('User not found');
    }
    const data: IUpdatePlatformUserRequest = {
        first_name: user.first_name,
        last_name: user.last_name,
        job_title: user.job_title,
        email: user.email,
        cell_phone: user.cell_phone,
        global_role_id: user.global_roles?.[0]?.id || null,
        address1: user.address1,
        address2: user.address2,
        city: user.city,
        state: user.state,
        county: user.county,
        zip_code: user.zip_code,
        status,
    };
    const updatedUser = yield* call(api.updatePlatformUser, id, data);
    yield put(setPlatformUserStatus.success(updatedUser));
    yield put(getUsers.success([updatedUser]));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'User status successfully updated',
        autoHideDuration: autoHideDefaultDuration,
    }));
}

function* setPlatformUserStatusWatcher() {
    yield* takeLatest(
        setPlatformUserStatus.initType,
        withBackendErrorHandler(
            setPlatformUserStatusSaga,
            setPlatformUserStatus.error,
            'Unable to change user status',
        ),
    );
}

export function* getMoreUsersSaga() {

    const nextPageToLoad = yield* select(selectUserTableNextPageToLoad);

    const filter = yield* select(selectPlatformUserFilter);

    const request: IGetUserRequest = {
        page_size: pageSize,
        page_number: nextPageToLoad,
        sort: '["created_at","desc"]',
        name: filter.name || undefined,
        global_role_id: filter.global_role?.id || undefined,
        client_id: filter.client?.id || undefined,
        status: filter.status || undefined,
        user_type: UserType.Manager,
    };
    const getUsersResponse = yield* call(usersApi.getUsers, request);
    yield put(getMoreUsersActions.success(getUsersResponse));
    yield put(getUsers.success(getUsersResponse.users));
}

function* getMoreUsersSagaWatcher() {
    yield* takeLatest(
        [
            getMoreUsersActions.initType,
            setPlatformUsersFilter.action,
        ],
        withBackendErrorHandler(
            getMoreUsersSaga,
            getMoreUsersActions.error,
            'Unable to get more users.',
        ),
    );
}

export function* createClientUsersAssociationSaga(
    action: ReturnType<typeof createClientUsersAssociation.init>,
) {
    const response = yield* call(api.createClientUserAssociation, action.payload);
    yield put(createClientUsersAssociation.success(response));
    yield put(getClientUsersAssociation.init());
}

function* createClientUsersAssociationWatcher() {
    yield* takeLatest(
        createClientUsersAssociation.initType,
        withBackendErrorHandler(
            createClientUsersAssociationSaga,
            createClientUsersAssociation.error,
            'Unable to create user role',
        ),
    );
}

export function* getMoreClientUserAssociationSaga() {
    const nextPageToLoad = yield* select(clientUserAssociationSelectors.selectNextPage);
    const filter = yield* select(selectClientUserAssociationFilter);
    const request: IClientUserAssociationRequest = {
        global_role_id: filter.global_role?.id || undefined,
        client_id: filter.client?.id || undefined,
        client_role_id: filter.client_role?.id || undefined,
        page_size: pageSize,
        page_number: nextPageToLoad,
        user_type: UserType.Manager,
    };
    const response = yield* call(api.getClientUserAssociation, request);
    const userIds = response.client_user_roles.map(item => item.user_id).filter(id => id);
    if (userIds.length) {
        const usersResponse = yield* call(usersApi.getUsers, { ids: userIds.join(',') });
        yield put(getUsers.success(usersResponse.users));
    }
    yield put(getMoreClientUsersAssociation.success(response));
}

function* getMoreClientUserAssociationSagaWatcher() {
    yield* takeLatest(
        [
            getClientUsersAssociation.initType,
            getMoreClientUsersAssociation.initType,
            setClientUserAssociationFilter.action,
        ],
        withBackendErrorHandler(
            getMoreClientUserAssociationSaga,
            getMoreClientUsersAssociation.error,
            'Unable to get user roles.',
        ),
    );
}

export function* removeClientUserAssociationSaga(
    { payload: associationId }: ReturnType<typeof removeClientUserAssociation.init>,
) {
    yield* call(api.deleteClientUserAssociation, associationId);
    yield put(removeClientUserAssociation.success(associationId));
    yield put(getClientUsersAssociation.init());
}

function* removeClientUserAssociationWatcher() {
    yield* takeLatest(
        removeClientUserAssociation.initType,
        withBackendErrorHandler(
            removeClientUserAssociationSaga,
            removeClientUserAssociation.error,
            'Unable to delete user role',
        ),
    );
}

export default [
    createPlatformUserWatcher,
    updatePlatformUserWatcher,
    setPlatformUserStatusWatcher,
    getMoreUsersSagaWatcher,
    createClientUsersAssociationWatcher,
    getMoreClientUserAssociationSagaWatcher,
    removeClientUserAssociationWatcher,
];

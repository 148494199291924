import React, { useCallback, useState } from 'react';
import { useField } from 'formik';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { maxMilesValue } from 'shared/models/validationSchemes/expenseEntry';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';
import { TextField } from '@material-ui/core';
import { dollarForMilesCalculations } from 'shared/models/Miles';
import { dollarCounter } from 'shared/utils/counters/dollarCounter';

const getDollarsFromMiles = (miles: number) =>
    dollarCounter(Math.round(dollarForMilesCalculations.times(miles).times(100).toNumber()));

export default function MilesDataField({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
}: IFormFieldProps) {
    const [field, meta, helper] = useField(name);
    const [dollars, setDollars] = useState<string>(field?.value?.miles ? getDollarsFromMiles(field.value.miles) : '');

    const handleChange = useCallback((value: string) => {
        const parsedValue = parseInt(value);
        setDollars(value ? getDollarsFromMiles(parsedValue) : '');

        helper.setValue({
            entry_type: QuantityType.MILES,
            miles: parsedValue,
        });
    }, [setDollars, helper]);

    return (
        <>
            <NumberInput
                id={id}
                name={name}
                label={label}
                initialValue={field.value?.miles}
                className={className}
                inputProps={{
                    thousandSeparator: true,
                    allowNegative: false,
                    fixedDecimalScale: false,
                }}
                disabled={disabled}
                error={meta.error}
                touched={meta.touched}
                setTouched={helper.setTouched}
                onChange={handleChange}
                min={0}
                max={maxMilesValue}
            />
            <TextField value={dollars} disabled
                className={className}
                variant="outlined" label="$"
            />
        </>
    );
}

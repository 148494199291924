import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';
import { useMemo } from 'react';

const registeredActions: Array<string> = [];

export interface IErrorActionPayload {
    // TODO replace any with some param as soon as we have error handling strategy
    error: any;
}

export type ActionCreatorKnownArgs<ArgsType, ReturnType> = (payload: ArgsType) => ReturnType;
interface IActionCreatorWithActionName<ArgsType, ReturnType> extends ActionCreatorKnownArgs<ArgsType, ReturnType> {
    action: string;
}

export function createSingleAction<PayloadType, ActionType extends string>(
    actionType: ActionType,
): IActionCreatorWithActionName<PayloadType, { type: ActionType; payload: PayloadType }> {
    // Prevent multiple actions with the same name from occuring in the application
    if (process.env.NODE_ENV === 'development') {
        if (registeredActions.includes(actionType)) {
            throw new Error(`Action ${actionType} is already registered somewhere else`);
        } else {
            registeredActions.push(actionType);
        }
    }

    const action = (payload: PayloadType) => ({
        type: actionType,
        payload,
    });
    action.action = actionType;
    return action;
}

export interface IActionsCreatorCommon {
    init: ActionCreatorKnownArgs<any, any>;
    initType: string;
    success: ActionCreatorKnownArgs<any, any>;
    successType: string;
    error: ActionCreatorKnownArgs<any, any>;
    errorType: string;
}

export function createActions<RequestType,
    SuccessType,
    ErrorType extends IErrorActionPayload,
    RequestActionType extends string,
    SuccessActionType extends string,
    ErrorActionType extends string,
>(
    requestType: RequestActionType,
    successType: SuccessActionType,
    errorType: ErrorActionType,
) {
    return {
        init: createSingleAction<RequestType, RequestActionType>(requestType),
        initType: requestType,
        success: createSingleAction<SuccessType, SuccessActionType>(successType),
        successType: successType,
        error: createSingleAction<ErrorType, ErrorActionType>(errorType),
        errorType: errorType,
    };
}

export enum RequestType{
    Get = 'GET',
    Create = 'CREATE',
    Update = 'UPDATE',
    Delete = 'DELETE',
    PATCH = 'PATCH',
}

export function createRequestActions<TInitPayload, TSuccessPayload, TErrorPayload extends IErrorActionPayload = any>(
    requestType: RequestType,
    entityName: string,
    prefix = '') {

    const entityToUpper = entityName.toUpperCase();
    const ACTION_ENTITY = `${prefix}/${requestType}_${entityToUpper}`;
    const ACTION_ENTITY_SUCCESS = `${prefix}/${requestType}_${entityToUpper}_SUCCESS`;
    const ACTION_ENTITY_ERROR = `${prefix}/${requestType}_${entityToUpper}_ERROR`;

    return createActions<
    TInitPayload,
    TSuccessPayload,
    TErrorPayload,
        typeof ACTION_ENTITY,
        typeof ACTION_ENTITY_SUCCESS,
        typeof ACTION_ENTITY_ERROR
    >(
        ACTION_ENTITY,
        ACTION_ENTITY_SUCCESS,
        ACTION_ENTITY_ERROR,
    );

}

export type ActionsReturnTypes<T extends IActionsCreatorCommon> =
    ReturnType<T['init']> | ReturnType<T['success']> | ReturnType<T['error']>;

/**
 * @deprecated
 */
export function useActions<T extends ActionCreatorsMapObject>(rawActions: T): T {
    const dispatch = useDispatch();
    // TODO replace with useRef
    // Actions object shouldn't change between re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => bindActionCreators(rawActions, dispatch), [dispatch]);
}

import { AppClient } from 'utils/constants';
import { getHomeMenu as getMenuDefault } from './verticalMenu';
import { getHomeMenu as getMenuRti } from './verticalMenuRti';
import { IMenuCategory } from 'shared/models/Global';
import { IGetHomeMenuParams } from './typings';

export const getHomeMenu: (
    params: IGetHomeMenuParams
) => Array<IMenuCategory> = process.env.REACT_APP_CLIENT === AppClient.RTI
    ? getMenuRti
    : getMenuDefault;

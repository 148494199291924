import { SyncWorkLocations } from 'modules/settings/submodules/clients/workLocation/components/SyncWorkLocations/SyncWorkLocations';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { getPositions } from 'store/entities/configuration/configurationAction';
import { Box } from '@material-ui/core';
import { CreateWorkLocationButton } from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/CreateWorkLocationButton';
import { WorkLocationTable } from 'modules/settings/submodules/clients/workLocation/components/WorkLocationTable/WorkLocationTable';
import { FeatureSwitches } from 'utils/featureSwitches';
import EditWorkLocationModal
    from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/EditWorkLocationModal';

export const ClientWorkLocationList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPositions.init());
    }, [dispatch]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableClientWorkLocationManagement}>
            <Box
                mb={2}
                display="flex"
                justifyContent="space-between"
            >
                <CreateWorkLocationButton/>
                <SyncWorkLocations/>
            </Box>
            <WorkLocationTable/>
            <EditWorkLocationModal/>
        </FeatureSwitch>
    );
};

import { ActionsReturnTypes, createActions } from 'store/utils';
import { IFieldsConfigurationWrapper, ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';

export const UPDATE_APP_SETTING = 'UPDATE_APP_SETTING';
export const UPDATE_APP_SETTING_SUCCESS = 'UPDATE_APP_SETTING_SUCCESS';
export const UPDATE_APP_SETTING_ERROR = 'UPDATE_APP_SETTING_ERROR';

export const updateAppSettings = createActions<{
    clientId: string,
    data: Partial<ITimeAndPayClientConfiguration>,
},
IFieldsConfigurationWrapper,
any,
typeof UPDATE_APP_SETTING,
typeof UPDATE_APP_SETTING_SUCCESS,
typeof UPDATE_APP_SETTING_ERROR
>(
    UPDATE_APP_SETTING, UPDATE_APP_SETTING_SUCCESS, UPDATE_APP_SETTING_ERROR,
);

export type SettingAppConfigurationTypesActions =
    | ActionsReturnTypes<typeof updateAppSettings>

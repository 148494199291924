import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useStyles = makeStyles((theme: Theme) => ({
    panel: {
        display: 'flex',
        padding: theme.spacing(2, 0, 2),
    },
    block: {
        width: theme.spacing(20),
        border: `1px solid ${ colors.gray }`,
        backgroundColor: colors.white,
        marginRight: theme.spacing(2),
        padding: theme.spacing(0, 1, 1),
        boxSizing: 'border-box',
        cursor: 'default',
    },
    blockTop: {
        display: 'flex',
        alignItems: 'baseline',
        color: colors.gray,
        justifyContent: 'space-between',
        fontSize: 12,
        lineHeight: '20px',
    },
    blockClose: {
        cursor: 'pointer',
        height: theme.spacing(2),
        width: theme.spacing(2),
        alignSelf: 'flex-end',
        marginRight: theme.spacing(-0.5),
    },
    blockValue: {
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '18px',
    },
}));

import { combineReducers } from 'redux';
import {
    ClientAction,
    getClientConfigurationTheme,
    getClientFieldsConfiguration,
    getPaySettings,
    SET_CLIENTS,
    SET_CLIENTS_INFO,
    setClientId,
    setPayrollProcessing,
} from 'store/entities/clients/clientsAction';
import { isLoadingReducer, itemsByIds } from 'store/reducerUtils';
import {
    ApproversFromFields,
    IClient,
    IClientInfo,
    IFieldsConfiguration,
    IPaySettings,
    IThemeConfigurationWrapper,
    ITimeAndPayClientConfiguration,
} from 'store/entities/clients/clientsModel';
import { processFieldsConfiguration } from 'store/entities/clients/utils';
import { ItemsById } from 'shared/models/ItemsById';

const initialState = {
    fieldsConfigurationByClientId: {},
    configurationThemeByClientId: {},
    timeAndPayConfigurationByClientId: {},
    paySettings: {},
    clientsById: {},
    tenantInfo: null,
    clientId: null,
    isPayrollProcessing: false,
};

function isPayrollProcessing(state: boolean = initialState.isPayrollProcessing, action: ClientAction): boolean {
    switch (action.type) {
        case setPayrollProcessing.initType:
            return true;
        case setPayrollProcessing.errorType:
        case setPayrollProcessing.successType:
            return false;
        default:
            return state;
    }
}

/**
 * Reducer for all clients.
 * For example clients that not related to the current user can be displayed at the Offer Letter page.
 */
function allClientsById(
    state: ItemsById<IClient> = initialState.clientsById,
    action: ClientAction,
): ItemsById<IClient> {
    switch (action.type) {
        case SET_CLIENTS_INFO:
        case SET_CLIENTS:
            return {
                ...state,
                ...itemsByIds(action.payload),
            };
        default:
            return state;
    }
}

/**
 * Reducer for all related to current user clients.
 * Usually it is a basic source for client data.
 */
function clientsById(
    state: ItemsById<IClientInfo> = initialState.clientsById, action: ClientAction,
): ItemsById<IClientInfo> {
    switch (action.type) {
        case SET_CLIENTS_INFO:
            return {
                ...state,
                ...itemsByIds(action.payload),
            };
        default:
            return state;
    }
}

function fieldsConfigurationByClientId(
    state: Record<string, IFieldsConfiguration> = initialState.fieldsConfigurationByClientId,
    action: ClientAction,
): Record<string, IFieldsConfiguration> {
    switch (action.type) {
        case getClientFieldsConfiguration.successType: {
            const { client_id, configuration } = action.payload;
            return {
                ...state,
                [client_id]: processFieldsConfiguration(configuration, client_id),
            };
        }
        default:
            return state;
    }
}

const isLoadingFieldsConfiguration = isLoadingReducer(getClientFieldsConfiguration);

function timeAndPayConfigurationByClientId(
    state: ItemsById<ITimeAndPayClientConfiguration> = initialState.timeAndPayConfigurationByClientId,
    action: ClientAction,
): ItemsById<ITimeAndPayClientConfiguration> {
    if (action.type === getClientFieldsConfiguration.successType) {
        const { payload } = action;
        const clientConfiguration: ITimeAndPayClientConfiguration = {
            id: payload.client_id,
            timeTracking: payload.configuration.time_tracking,
            approversFrom: payload.configuration.approvers_from || ApproversFromFields.FromAssignment,
            weekdays: payload.configuration.weekdays,
            approvalLevels: payload.configuration.approval_levels,
            isGovernmentContract: payload.configuration.is_government_contract,
            hasJobNumberConfiguration: payload.configuration?.sheet_entry_group_key?.includes('job_number_id') || false,
        };
        return {
            ...state,
            ...itemsByIds([clientConfiguration]),
        };
    }

    return state;

}

function paySettingsByClientId(
    state: ItemsById<IPaySettings> = initialState.paySettings,
    action: ClientAction,
): ItemsById<IPaySettings> {
    if (action.type === getPaySettings.successType) {
        const { payload } = action;
        return {
            ...state,
            [payload.client_id]: payload,
        };
    }

    return state;

}

const isPaySettingsLoading = isLoadingReducer(getPaySettings);

function configurationThemeByClientId(
    state: Record<string, IThemeConfigurationWrapper> = initialState.configurationThemeByClientId,
    action: ClientAction,
): Record<string, IThemeConfigurationWrapper> {
    switch (action.type) {
        case getClientConfigurationTheme.successType:
            return {
                ...state,
                ...action.payload.reduce((acc, item) => ({
                    ...acc,
                    [item.client_id]: item,
                }), {}),
            };
        default:
            return state;
    }
}

function clientId(
    state: string | null = initialState.clientId,
    action: ClientAction,
): string | null {
    switch (action.type) {
        case setClientId.action:
            return action.payload;
        default:
            return state;
    }
}

export const clients = combineReducers({
    fieldsConfigurationByClientId,
    configurationThemeByClientId,
    timeAndPayConfigurationByClientId,
    isLoadingFieldsConfiguration,
    isPayrollProcessing,
    allClientsById,
    clientsById,
    clientId,
    paySettingsByClientId,
    isPaySettingsLoading,
});

import { IStore } from 'store/configureStore';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectCurrentUser } from 'store/components/auth/selectors';
import createSelectorSortedByNameFromById from 'store/utils/selectors/createSelectorSortedByNameFromById';
import { employmentState } from '../selectors';
import { IDepartment } from '../../models/Department';

const departmentsState = (state: IStore) => employmentState(state).departments;
export const selectDepartmentsById = (state: IStore) => departmentsState(state).departmentsById;
export const selectDepartmentById = (id = '') =>
    (state: IStore): IDepartment => selectDepartmentsById(state)[id];
export const selectDepartmentsIsLoading = (state: IStore) => departmentsState(state).departmentsIsLoading;
export const selectDepartmentsSortedByName = createSelectorSortedByNameFromById<IDepartment>(selectDepartmentsById);
export const selectUserDepartmentsList = (userId?: string | null) => (state: IStore): IDepartment[] => {
    const currentUser = selectCurrentUser(state);
    const assignments = selectAssignmentsById(state);
    const allClientDepartments = selectDepartmentsSortedByName(state);
    const filterByUserId = userId || currentUser?.id;

    const filteredDepartmentIds = Object.values(assignments)
        .filter(item => item.department_id && item.user_id === filterByUserId)
        .map(({ department_id }) => department_id);

    return allClientDepartments.filter(({ id }) => filteredDepartmentIds.includes(id));
};
export const selectDefaultDepartment = (userId?: string | null) => (state: IStore): IDepartment | null => {
    const departments = selectUserDepartmentsList(userId)(state);
    return departments.length === 1 ? departments[0] : null;
};

import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { Typography, Button, FormHelperText, CircularProgress, Box } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import AttachmentsPreview from 'shared/components/uploader/AttachmentsPreview';
import { useModal } from 'shared/utils/hooks/useModal';
import { IAttachment } from 'shared/models/Attachments';
import { useUploadStyles } from './styles';
import PdfSVG from '../icons/PdfSVG';

export interface IFileInputProps {
    text?: string;
    note?: string;
    rejectMessage?: string;
    fileTypes: string;
    error?: string | null;
    maxSizeMb: number;
    customClasses?: string;
    attachments: IAttachment[];
    onAttachmentChange: (files: File[]) => void;
    onAttachmentRemove: (file: IAttachment) => void;
    multiple?: boolean;
    isLoading?: boolean;
}

export default function FileInput({
    text = 'Upload',
    note = '',
    rejectMessage = 'File type is not allowed.',
    fileTypes,
    error,
    maxSizeMb,
    multiple = true,
    customClasses,
    attachments = [],
    onAttachmentChange,
    onAttachmentRemove,
    isLoading,
}: IFileInputProps) {
    const classes = useUploadStyles();
    const maxSize = maxSizeMb * 1024 * 1024;

    const {
        isModalOpened: isPreviewOpen,
        onModalOpen: onPreviewOpen,
        onModalClose: onPreviewClose,
    } = useModal();

    const onDrop = useCallback(acceptedFiles => {
        onPreviewClose();
        onAttachmentChange(acceptedFiles);
    }, [onAttachmentChange, onPreviewClose]);

    const {
        getRootProps, getInputProps, isDragReject, rejectedFiles,
    } = useDropzone({
        accept: fileTypes,
        onDrop,
        minSize: 0,
        maxSize,
        multiple,
    });

    const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles.some(file => file.size > maxSize);
    const canUploadMore = multiple || attachments.length === 0;

    return (
        <>
            {(attachments.length > 0) && (
                <section className={classes.preview}>
                    <div className = {clsx(classes.filePreviewZone, { [classes.underLoading]: isLoading })}>
                        {attachments.length > 0 && (
                            <>
                                {attachments[0]?.mimetype?.includes('image/') && (
                                    <img
                                        className={classes.img}
                                        alt=""
                                        src={attachments[0].url}
                                        key={attachments[0].id}
                                    />
                                )}
                                {attachments[0]?.mimetype === 'application/pdf' && (
                                    <div className={clsx(classes.img, classes.previewImg)}>
                                        <PdfSVG customClasses={classes.pdfPreview}/>
                                        <Typography variant="subtitle2" align="center">
                                            {attachments[0].filename}
                                        </Typography>
                                    </div>
                                )}
                            </>
                        )}
                        {attachments.length > 1 && (
                            <Button
                                className = {classes.imageButton}
                                color="inherit"
                                onClick={onPreviewOpen}>
                                See All [{attachments.length}]
                            </Button>
                        )}
                    </div>
                    {isLoading && (
                        <Box className={classes.loader}>
                            <CircularProgress/>
                        </Box>
                    )}
                </section>
            )}

            {(attachments.length > 0) && isPreviewOpen && (
                <AttachmentsPreview
                    open={isPreviewOpen}
                    onClose={onPreviewClose}
                    files={attachments}
                    onDelete={onAttachmentRemove}
                />
            )}

            {canUploadMore && (
                <section className={clsx(classes.fileUpload, customClasses)}>
                    <div
                        {...getRootProps()}
                        className={clsx(classes.fileDropzone, { [classes.error]: Boolean(error) })}
                    >
                        <div className={classes.uploadHeader}>
                            <input {...getInputProps()}/>
                            <Publish fontSize="small" classes={{ root: classes.uploadIcon }}/>
                            <Typography className={classes.uploadHeaderTitle}
                                color="primary"
                                variant="subtitle2"
                            >
                                {text}
                            </Typography>
                        </div>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            className={classes.note}
                        >
                            {note}
                        </Typography>
                        {isDragReject && (
                            <Typography className={classes.uploadError}
                                color="primary"
                                variant="body1"
                            >
                                {rejectMessage}
                            </Typography>
                        )}
                        {isFileTooLarge && (
                            <Typography className={classes.uploadError}
                                color="primary"
                                variant="body1"
                            >
                                File is too large.
                            </Typography>
                        )}
                    </div>
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </section>
            )}
        </>
    );
}

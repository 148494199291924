import React from 'react';
import { IEntryFormTimeSplit } from 'store/entities/timesheet/models/Entry';
import { EntryType, QuantityType, TEntryData } from 'shared/models/sheet/Sheet';
import { IFileUpload } from 'shared/models/Attachments';
import { moneyToString } from 'shared/models/Money';
import { IActivity } from 'store/entities/configuration/configurationModel';

export interface ISubmitBlockProps {
    onSubmit: () => void;
}

export type TSubmitBlock = React.FC<ISubmitBlockProps>;

export function createData(values: IEntryFormTimeSplit, entryType: EntryType, activity: IActivity): TEntryData {
    if (entryType === EntryType.TIME) {
        return {
            entry_type: QuantityType.TIME,
            hours: (values.hours as number),
            minutes: (values.minutes as number),
        };
    } else {
        if (activity.data_type === QuantityType.ODOMETER) {
            return {
                entry_type: QuantityType.ODOMETER,
                miles_start: values.odometer?.miles_start || 0,
                miles_end: values.odometer?.miles_end || 0,
            };
        } else {
            return {
                entry_type: QuantityType.MONEY,
                dollars: moneyToString(values.dollars),
            };
        }
    }
}

export async function readFileContent(file: File) {
    return new Promise<IFileUpload>((accept, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => accept({
            filename: file.name,
            mimetype: file.type,
            body: reader.result === null ? '' : reader.result.toString(),
            entry_id: '',
        });
        reader.onerror = () => reject();
    });
}

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormAutocomplete from 'shared/components/autocomplete/FormAutocomplete';
import { IUserInfo } from 'shared/models/User';
import { getUserName } from 'shared/utils/converters/user';
import { searchUsers } from 'store/entities/users/actions';
import { IGetUserRequest } from 'store/entities/users/model';
import SearchIcon from '@material-ui/icons/Search';
import { selectIsUserSearching, selectSearchResultUsers } from 'store/entities/users/selectors';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { useStyles } from './styles';

interface IPositionProps extends IFormFieldProps {
    additionalFilter?: Partial<IGetUserRequest>;
    useIdValue?: boolean;
}

export const UserAutocompleteWithSearch = ({
    useIdValue = false,
    additionalFilter = {},
    ...props
}: IPositionProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const searchKey = [
        'searchKey',
        ...Object.values(additionalFilter),
    ].join('_');
    const users = useSelector(selectSearchResultUsers(searchKey));
    const isLoading = useSelector(selectIsUserSearching(searchKey));

    const onInputChange = useCallback((_: any, value: string) => {
        const minLettersCount = 2;
        if (value && value.length >= minLettersCount) {
            dispatch(searchUsers.init({
                searchKey,
                request: {
                    ...additionalFilter,
                    name: value,
                },
            }));
        }
    }, [dispatch, additionalFilter, searchKey]);

    return (
        <FormAutocomplete
            getText={(user: IUserInfo) => getUserName(user)}
            getKey={useIdValue ? (user: IUserInfo) => user.id : undefined }
            options={users}
            onInputChange={onInputChange}
            isLoading={isLoading}
            popupIcon={<SearchIcon color="primary"/>}
            freeSolo
            endAdornment={<SearchIcon color="primary"/>}
            classes={{
                inputRoot: classes.inputRoot,
                endAdornment: classes.endAdornment,
            }}
            {...props}
        />
    );
};

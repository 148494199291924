import { fade, Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const fadeModifier = 0.8;

export const useOfferLetterFilterChipsStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        maxWidth: '100%',
        borderRadius: theme.spacing(0),
        height: theme.spacing(3),
        marginTop: theme.spacing(1),
        '&:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    },
    deleteIcon: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        backgroundColor: colors.gray,
        borderRadius: '50%',
        color: colors.lightGray,

        '&:hover': {
            backgroundColor: fade(colors.gray, fadeModifier),
            color: fade(colors.lightGray, fadeModifier),
        },
    },
}));

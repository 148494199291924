import React from 'react';
import { Box, Button } from '@material-ui/core';
import { FormikProps } from 'formik';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';
import { ProjectWithAssignment } from 'shared/components/selects/ProjectWithAssignment';
import { Task } from 'shared/components/selects/Task';
import Area from 'shared/components/selects/Area';
import { initialState } from 'store/components/workingSheets/workingSheetsReducer';
import { showFilter, useEffectWithSkipInitialChange } from 'shared/components/forms/utils';
import { LocationByAssignments } from 'shared/components/selects/LocationByAssignments';
import { PositionByAssignments } from 'shared/components/selects/PositionByAssignments';
import { useSelector } from 'react-redux';
import { FilterSlug } from 'store/entities/clients/clientsModel';
import { IFiltersAllOptions, IFilterStoreProps } from 'shared/models/Filters';
import { useFilterContentStyles } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormStyles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { User } from 'shared/components/selects/User';
import { PayPeriod } from 'shared/components/selects/PayPeriod';
import { Location } from 'shared/components/selects/Location';
import { Position } from 'shared/components/selects/Position';
import { JobNumbers } from 'shared/components/selects/JobNumbers';
import { selectCurrentUser } from 'store/components/auth/selectors';

interface IFilterFormProps extends FormikProps<Partial<IFiltersAllOptions>>, Pick<IFilterStoreProps, 'selectFiltersConfig'> {
    entryType?: EntryType;
}

export function FilterForm({
    selectFiltersConfig, values, setFieldValue, handleSubmit, entryType,
}: IFilterFormProps) {
    const classes = useFilterContentStyles();
    const { area_id, assignment_id, task_id, job_number_id } = values;

    const filters = useSelector(selectFiltersConfig);

    useEffectWithSkipInitialChange(() => {
        setFieldValue('assignment_id', initialState.filters.assignment_id);
    }, [area_id, setFieldValue]);

    useEffectWithSkipInitialChange(() => {
        setFieldValue('task_id', initialState.filters.task_id);
    }, [assignment_id, setFieldValue]);

    useEffectWithSkipInitialChange(() => {
        setFieldValue('activity_id', initialState.filters.activity_id);
    }, [task_id, setFieldValue]);

    const user = useSelector(selectCurrentUser);

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.formInputsWrapper}>
                {showFilter(filters, FilterSlug.LocationByAssignment) && (
                    <LocationByAssignments name="location_id" label={filters.location.placeholder}
                        className={classes.selectField} useIdValue
                    />

                )}

                {showFilter(filters, FilterSlug.PositionByAssignment) && (
                    <PositionByAssignments name="position_id" label={filters.position.placeholder}
                        className={classes.selectField} useIdValue
                    />
                )}

                {showFilter(filters, FilterSlug.Location) && (
                    <Location name="location_id" label={filters.location.placeholder}
                        className={classes.selectField} useIdValue
                    />

                )}

                {showFilter(filters, FilterSlug.Position) && (
                    <Position name="position_id" label={filters.position.placeholder}
                        className={classes.selectField} useIdValue
                    />
                )}

                {showFilter(filters, FilterSlug.Employee) && (
                    <User name="user_id" useIdValue
                        className={classes.selectField} label={filters.employee.placeholder}
                    />
                )}
                {showFilter(filters, FilterSlug.PayPeriod) && (
                    <PayPeriod
                        name="pay_period"
                        className={classes.selectField}
                        label={filters.pay_period.placeholder}
                    />
                )}

                {showFilter(filters, FilterSlug.Area) && (
                    <Area
                        name="area_id"
                        label={filters.area.placeholder}
                        className={classes.selectField}
                        useIdValue
                    />
                )}

                {showFilter(filters, FilterSlug.Assignment) && (
                    <ProjectWithAssignment
                        name="assignment_id"
                        label={filters.assignment.placeholder}
                        className={classes.selectField}
                        areaId={area_id ? parseInt(area_id, 10) : undefined}
                        disabled={!area_id}
                        title={!area_id ? 'Select area first' : ''}
                        useIdValue
                    />
                )}

                {showFilter(filters, FilterSlug.Task) && (
                    <Task
                        name="task_id"
                        label={filters.task.placeholder}
                        className={classes.selectField}
                        assignmentId={assignment_id}
                        disabled={!assignment_id}
                        title={!assignment_id ? 'Select Proj + Assignment first' : ''}
                        useIdValue
                    />
                )}
                {showFilter(filters, FilterSlug.JobNumber) && (
                    <JobNumbers
                        name="job_number_id"
                        label={filters.job_number.placeholder}
                        userId={user?.id}
                        className={classes.selectField}
                        useIdValue
                    />
                )}
                {showFilter(filters, FilterSlug.Activity) && (
                    <ActivitySelect
                        name="activity_id"
                        label={filters.activity.placeholder}
                        entryType={entryType}
                        taskId={task_id}
                        className={classes.selectField}
                        disabled={!task_id && !job_number_id}
                        title={!task_id && showFilter(filters, FilterSlug.Task)
                            ? 'Select task first'
                            : !task_id && showFilter(filters, FilterSlug.JobNumber)
                                ? 'Select job number first'
                                : ''}
                        useIdValue
                    />
                )}
            </Box>

            <Button color="primary"
                variant="contained"
                type="submit"
                fullWidth
                classes={{ root: classes.applyButtonRoot, label: classes.applyButtonLabel }}
            >
                Apply
            </Button>
        </form>
    );
}

import { selectDepartmentsSortedByName } from 'modules/employmentInfo/store/department/selectors';
import { IDepartmentFilter } from 'modules/settings/submodules/clients/departments/store/models';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';

export const selectDepartmentsState = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.departments;
};

/*
 * Filter
 */
export const selectDepartmentFilter = (state: IStore): IDepartmentFilter =>
    selectDepartmentsState(state).filter;
export const selectFilteredDepartments = createSelector(
    selectDepartmentsSortedByName,
    selectDepartmentFilter,
    (departments, filter) => {
        if (filter?.name) {
            return departments.filter(
                department => Object.values(department).some(value => value?.includes(filter.name)),
            );
        }
        return departments;
    },
);

/*
 * Create
 */
export const selectIsCreateDepartmentModalOpened = (state: IStore) =>
    selectDepartmentsState(state).isOpenCreateModal;
export const selectIsDepartmentCreating = (state: IStore) =>
    selectDepartmentsState(state).isCreating;

/*
 * Update
 */
export const selectEditDepartmentId = (state: IStore) =>
    selectDepartmentsState(state).editId;
export const selectIsDepartmentUpdating = (state: IStore) =>
    selectDepartmentsState(state).isUpdating;

import { IPaginationResponse } from 'shared/models/IPaginationResponse';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import {
    IChangeSheetGroupStatusPayload, IDownloadSheetProps,
    IGroupedSheetCalculation, IPayrollParams,
    IPayrollProcessorFilters,
    IPayrollSheetSummary, ISheetGroupId, IPostPayroll, IPrePayroll, IPayrollPayPeriodRequest, IPayrollPayPeriod,
} from 'modules/payrollProcessorHub/store/model';

const SET_PAYROLL_PROCESSOR_FILTER = 'payroll/SET_PAYROLL_PROCESSOR_FILTER';
export const setPayrollProcessorFilter = createSingleAction<
Partial<IPayrollProcessorFilters>,
typeof SET_PAYROLL_PROCESSOR_FILTER
>(
    SET_PAYROLL_PROCESSOR_FILTER,
);

export const LOAD_GROUPED_SHEETS = 'payroll/LOAD_GROUPED_SHEETS';
export const loadGroupedSheets = createSingleAction<void, typeof LOAD_GROUPED_SHEETS>(
    LOAD_GROUPED_SHEETS,
);

export const INCREMENT_PAYROLL_PAGE = 'payroll/INCREMENT_PAYROLL_PAGE';
export const nextPayrollGroupedSheetPage = createSingleAction<void, typeof INCREMENT_PAYROLL_PAGE>(
    INCREMENT_PAYROLL_PAGE,
);

export const LOAD_GROUPED_SHEET_NEXT_PAGE = 'payroll/LOAD_NEXT_PAGE';
export const loadGroupedSheetNextPage = createSingleAction<void, typeof LOAD_GROUPED_SHEET_NEXT_PAGE>(
    LOAD_GROUPED_SHEET_NEXT_PAGE,
);

const GET_GROUPED_SHEET_CALCULATIONS = 'payroll/GET_GROUPED_SHEET_CALCULATIONS';
const GET_GROUPED_SHEET_CALCULATIONS_SUCCESS = 'payroll/GET_GROUPED_SHEET_CALCULATIONS_SUCCESS';
const GET_GROUPED_SHEET_CALCULATIONS_ERROR = 'payroll/GET_GROUPED_SHEET_CALCULATIONS_ERROR';

export const getGroupedSheetCalculations = createActions<
void,
IPaginationResponse<IGroupedSheetCalculation>,
any,
typeof GET_GROUPED_SHEET_CALCULATIONS,
typeof GET_GROUPED_SHEET_CALCULATIONS_SUCCESS,
typeof GET_GROUPED_SHEET_CALCULATIONS_ERROR
>(
    GET_GROUPED_SHEET_CALCULATIONS, GET_GROUPED_SHEET_CALCULATIONS_SUCCESS, GET_GROUPED_SHEET_CALCULATIONS_ERROR,
);

const GET_SHEET_SUMMARY = 'payroll/GET_SHEET_SUMMARY';
const GET_SHEET_SUMMARY_SUCCESS = 'payroll/GET_SHEET_SUMMARY_SUCCESS';
const GET_SHEET_SUMMARY_ERROR = 'payroll/GET_SHEET_SUMMARY_ERROR';

export const getSheetSummary = createActions<
void,
IPayrollSheetSummary,
any,
typeof GET_SHEET_SUMMARY,
typeof GET_SHEET_SUMMARY_SUCCESS,
typeof GET_SHEET_SUMMARY_ERROR
>(
    GET_SHEET_SUMMARY, GET_SHEET_SUMMARY_SUCCESS, GET_SHEET_SUMMARY_ERROR,
);

const GET_SHEET_EDIT_INFO = 'payroll/GET_SHEET_EDIT_INFO';
const GET_SHEET_EDIT_INFO_SUCCESS = 'payroll/GET_SHEET_EDIT_INFO_SUCCESS';
const GET_SHEET_EDIT_INFO_ERROR = 'payroll/GET_SHEET_EDIT_INFO_ERROR';

export const getSheetEditInfo = createActions<
ISheetGroupId,
void,
any,
typeof GET_SHEET_EDIT_INFO,
typeof GET_SHEET_EDIT_INFO_SUCCESS,
typeof GET_SHEET_EDIT_INFO_ERROR
>(
    GET_SHEET_EDIT_INFO, GET_SHEET_EDIT_INFO_SUCCESS, GET_SHEET_EDIT_INFO_ERROR,
);

const CHANGE_SHEET_GROUP_STATUS = 'payroll/CHANGE_SHEET_GROUP_STATUS';
const CHANGE_SHEET_GROUP_STATUS_SUCCESS = 'payroll/CHANGE_SHEET_GROUP_STATUS_SUCCESS';
const CHANGE_SHEET_GROUP_STATUS_ERROR = 'payroll/CHANGE_SHEET_GROUP_STATUS_ERROR';

export const changeSheetGroupStatus = createActions<
IChangeSheetGroupStatusPayload,
void,
any,
typeof CHANGE_SHEET_GROUP_STATUS,
typeof CHANGE_SHEET_GROUP_STATUS_SUCCESS,
typeof CHANGE_SHEET_GROUP_STATUS_ERROR
>(
    CHANGE_SHEET_GROUP_STATUS, CHANGE_SHEET_GROUP_STATUS_SUCCESS, CHANGE_SHEET_GROUP_STATUS_ERROR,
);

const INIT_SHEET_GROUP_PAYROLL = 'payroll/INIT_SHEET_GROUP_PAYROLL';
const INIT_SHEET_GROUP_PAYROLL_SUCCESS = 'payroll/INIT_SHEET_GROUP_PAYROLL_SUCCESS';
const INIT_SHEET_GROUP_PAYROLL_ERROR = 'payroll/INIT_SHEET_GROUP_PAYROLL_ERROR';

export const initSheetGroupPayroll = createActions<
IPayrollParams,
void,
any,
typeof INIT_SHEET_GROUP_PAYROLL,
typeof INIT_SHEET_GROUP_PAYROLL_SUCCESS,
typeof INIT_SHEET_GROUP_PAYROLL_ERROR
>(
    INIT_SHEET_GROUP_PAYROLL, INIT_SHEET_GROUP_PAYROLL_SUCCESS, INIT_SHEET_GROUP_PAYROLL_ERROR,
);

const GET_SHEET_GROUP_PDF = 'payroll/GET_SHEET_GROUP_PDF';
const GET_SHEET_GROUP_PDF_SUCCESS = 'payroll/GET_SHEET_GROUP_PDF_SUCCESS';
const GET_SHEET_GROUP_PDF_ERROR = 'payroll/GET_SHEET_GROUP_PDF_ERROR';

export const getGroupedSheetPdf = createActions<
IDownloadSheetProps,
IDownloadSheetProps,
any,
typeof GET_SHEET_GROUP_PDF,
typeof GET_SHEET_GROUP_PDF_SUCCESS,
typeof GET_SHEET_GROUP_PDF_ERROR
>(
    GET_SHEET_GROUP_PDF, GET_SHEET_GROUP_PDF_SUCCESS, GET_SHEET_GROUP_PDF_ERROR,
);

const SEND_SHEET_GROUP_REMINDER = 'payroll/SEND_SHEET_GROUP_REMINDER';
const SEND_SHEET_GROUP_REMINDER_SUCCESS = 'payroll/SEND_SHEET_GROUP_REMINDER_SUCCESS';
const SEND_SHEET_GROUP_REMINDER_ERROR = 'payroll/SEND_SHEET_GROUP_REMINDER_ERROR';

export const sendSheetGroupReminder = createActions<
ISheetGroupId,
any,
any,
typeof SEND_SHEET_GROUP_REMINDER,
typeof SEND_SHEET_GROUP_REMINDER_SUCCESS,
typeof SEND_SHEET_GROUP_REMINDER_ERROR
>(
    SEND_SHEET_GROUP_REMINDER, SEND_SHEET_GROUP_REMINDER_SUCCESS, SEND_SHEET_GROUP_REMINDER_ERROR,
);

const GET_PRE_PAYROLL_REPORT = 'payroll/GET_PRE_PAYROLL_REPORT';
const GET_PRE_PAYROLL_REPORT_SUCCESS = 'payroll/GET_PRE_PAYROLL_REPORT_SUCCESS';
const GET_PRE_PAYROLL_REPORT_ERROR = 'payroll/GET_PRE_PAYROLL_REPORT_ERROR';

export const getPrePayrollReport = createActions<
IPayrollParams,
IPrePayroll,
any,
typeof GET_PRE_PAYROLL_REPORT,
typeof GET_PRE_PAYROLL_REPORT_SUCCESS,
typeof GET_PRE_PAYROLL_REPORT_ERROR
>(
    GET_PRE_PAYROLL_REPORT, GET_PRE_PAYROLL_REPORT_SUCCESS, GET_PRE_PAYROLL_REPORT_ERROR,
);

export const CLEAR_PAYROLL_REPORT = 'payroll/CLEAR_PAYROLL_REPORT';
export const clearPayrollReports = createSingleAction<void, typeof CLEAR_PAYROLL_REPORT>(
    CLEAR_PAYROLL_REPORT,
);

export const SET_POST_PAYROLL_REPORT = 'payroll/SET_POST_PAYROLL_REPORT';
export const setPostPayrollReports = createSingleAction<IPostPayroll, typeof SET_POST_PAYROLL_REPORT>(
    SET_POST_PAYROLL_REPORT,
);

const GET_PAY_PERIODS = 'payroll/GET_PAY_PERIODS';
const GET_PAY_PERIODS_SUCCESS = 'payroll/GET_PAY_PERIODS_SUCCESS';
const GET_PAY_PERIODS_ERROR = 'payroll/GET_PAY_PERIODS_ERROR';

export const getPayrollPayPeriods = createActions<
void | IPayrollPayPeriodRequest,
IPayrollPayPeriod[],
any,
typeof GET_PAY_PERIODS,
typeof GET_PAY_PERIODS_SUCCESS,
typeof GET_PAY_PERIODS_ERROR
>(
    GET_PAY_PERIODS, GET_PAY_PERIODS_SUCCESS, GET_PAY_PERIODS_ERROR,
);

export type PayrollProcessorActions =
    | ActionsReturnTypes<typeof getGroupedSheetCalculations>
    | ActionsReturnTypes<typeof getSheetSummary>
    | ActionsReturnTypes<typeof getSheetEditInfo>
    | ActionsReturnTypes<typeof changeSheetGroupStatus>
    | ActionsReturnTypes<typeof initSheetGroupPayroll>
    | ActionsReturnTypes<typeof getGroupedSheetPdf>
    | ActionsReturnTypes<typeof sendSheetGroupReminder>
    | ActionsReturnTypes<typeof getPrePayrollReport>
    | ActionsReturnTypes<typeof getPayrollPayPeriods>
    | ReturnType<typeof loadGroupedSheets>
    | ReturnType<typeof loadGroupedSheetNextPage>
    | ReturnType<typeof nextPayrollGroupedSheetPage>
    | ReturnType<typeof clearPayrollReports>
    | ReturnType<typeof setPostPayrollReports>
    | ReturnType<typeof setPayrollProcessorFilter>;

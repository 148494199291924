import { createSelector } from 'reselect';
import { selectSubmittingOrgs } from 'store/entities/configuration/configurationSelectors';
import { selectSubmittingOrgFilter } from 'modules/settings/submodules/clients/submittingOrg/components/Filter/store/selector';

export const selectFilteredSubmittingOrgs = (
    createSelector(
        selectSubmittingOrgs,
        selectSubmittingOrgFilter,
        (submittingOrgs, submittingOrgFilter) => {
            if (!submittingOrgFilter){
                return submittingOrgs;
            }
            const submittingOrgFilterToLower = submittingOrgFilter.toLowerCase();
            return submittingOrgs.filter(org => {
                return org.client_site_name.toLowerCase().includes(submittingOrgFilterToLower)
                    || org.submitting_org_number.toLowerCase().includes(submittingOrgFilterToLower);
            });
        },
    )
);

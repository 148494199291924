import React from 'react';
import clsx from 'clsx';
import { GetRowId, ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';

interface IGridTableRowProps<RowData> {
    row: RowData;
    getRowId?: GetRowId<RowData>;
    cells: Array<ICellInfo<RowData>>;
    classes: ReturnType<typeof useGridTableStyles>;
    bodyCellClassName?: string;
}

export type IGridCellProps<RowData> = RowData & {
    className?: string;
};

function GridTableRow<RowData>({ row, getRowId, cells, classes, bodyCellClassName = '' }: IGridTableRowProps<RowData>) {
    // Do not delete div wrapper, or modify it's role because some logic depends on it
    return (
        <div className={classes.rowContainer}
            role="row"
            id={getRowId ? getRowId(row) : undefined}
            data-testid={getRowId ? getRowId(row) : undefined}
        >
            {cells.map(({ key, render: Component }) => (
                <Component className={clsx(classes.bodyCell, bodyCellClassName)}
                    key={key}
                    {...row}
                />
            ))
            }
        </div>
    );
}

export default React.memo(GridTableRow, ((prevProps, nextProps) => {
    return Object.entries(prevProps).every(([name, value]) => {
        if (name !== 'row') {
            return nextProps[name as keyof typeof prevProps] === value;
        } else {
            const nextRowValues = Object.values(nextProps.row as Array<any>);
            return Object.values(prevProps.row as Array<any>).every(
                (item, index) => item === nextRowValues[index],
            );
        }
    });
})) as typeof GridTableRow;
// export default GridTableRow;

/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { DeleteWorkLocationButton } from 'modules/settings/submodules/clients/workLocation/components/DeleteWorkLocation/DeleteWorkLocationButton';
import { EditWorkLocationButton } from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/EditWorkLocationButton';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { ILocation } from 'shared/models/Location';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useIsMobile } from 'shared/utils/hooks/media';
import { selectLocations } from 'store/entities/configuration/configurationSelectors';

interface IWorkLocationRow extends ILocation {
    className?: string;
}

export const WorkLocationTable = () => {
    const tableClasses = useDefaultTableStyles();
    const locations = useSelector(selectLocations);
    const isMobile = useIsMobile();

    const actionsCell = {
        key: 'actions',
        title: '',
        width: '100px',
        render: function DetailsCell({ className, id }: IWorkLocationRow) {
            return (
                <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                    <DeleteWorkLocationButton locationId={id}/>
                    <EditWorkLocationButton locationId={id}/>
                </div>
            );
        },
    };

    const locationsCells = isMobile ? [
        {
            key: 'FullInfo',
            title: '',
            render: function FullInfoCell({ className, id, name, state_code, zip_code }: IWorkLocationRow) {
                const items = [
                    `ID: ${id}`,
                    `${state_code ? state_code : ''}${state_code && zip_code ? ',' : ''} ${zip_code ? zip_code : ''}`,
                ];

                return (
                    <MobileListCell
                        className={className}
                        title={name}
                        items={items}
                    />
                );
            },
        },
        actionsCell,
    ] : [
        {
            key: 'id',
            title: 'id',
            render: ({ id, className }: IWorkLocationRow) => (
                <PlainText className={className} value={id}/>
            ),
        },
        {
            key: 'location',
            title: 'work location',
            render: ({ name, className }: IWorkLocationRow) => (
                <PlainText className={className} value={name}/>
            ),
        },
        {
            key: 'state',
            title: 'state',
            render: ({ className, state_code }: IWorkLocationRow) => (
                <PlainText className={className} value={state_code}/>
            ),
        },
        {
            key: 'zipcode',
            title: 'zip code',
            render: ({ className, zip_code }: IWorkLocationRow) => (
                <PlainText className={className} value={zip_code}/>
            ),
        },
        actionsCell,
    ];

    return (
        <GridTable
            rowData={locations}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={client => client.id}
            cells={locationsCells}
            stickyHeader
            hideHeader={isMobile}
        />
    );
};

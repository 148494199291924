import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { debounce } from 'ts-debounce';
import { setDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/actions';

export const DealFilter = () => {
    const dispatch = useDispatch();
    const debounceTimeout = 50;
    const changeFilter = useMemo(() =>
        debounce(
            (newFilterValue: string) => {
                dispatch(setDealFilter(newFilterValue || ''));
            },
            debounceTimeout),
    [dispatch],
    );
    return (
        <TextFilter onFilterValueChanged={changeFilter}/>
    );
};

import { ICreatePlatformUser, IUpdatePlatformUser, IUserInfo } from 'shared/models/User';
import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import { IClientUserAssociationFilter, IClientUserAssociationResponse, IGetMoreUsersResponse, IPlatformUsersFilter, IUpdateUserStatus } from './models';
import { ICreateClientUserAssociation } from 'store/entities/users/model';

export const SET_CREATE_USER_MODAL_STATE = 'settings/SET_CREATE_USER_MODAL_STATE';
export const setCreateUserModalState = createSingleAction<boolean, typeof SET_CREATE_USER_MODAL_STATE>(
    SET_CREATE_USER_MODAL_STATE,
);

export const CREATE_PLATFORM_USER = 'settings/CREATE_PLATFORM_USER';
export const CREATE_PLATFORM_USER_SUCCESS = 'settings/CREATE_PLATFORM_USER_SUCCESS';
export const CREATE_PLATFORM_USER_ERROR = 'settings/CREATE_PLATFORM_USER_ERROR';

export const createPlatformUser = createActions<
ICreatePlatformUser,
IUserInfo,
any,
typeof CREATE_PLATFORM_USER,
typeof CREATE_PLATFORM_USER_SUCCESS,
typeof CREATE_PLATFORM_USER_ERROR
>(
    CREATE_PLATFORM_USER,
    CREATE_PLATFORM_USER_SUCCESS,
    CREATE_PLATFORM_USER_ERROR,
);

export const SET_EDIT_PLATFORM_USER = 'settings/SET_EDIT_PLATFORM_USER';
export const setEditPlatformUser = createSingleAction<string | null, typeof SET_EDIT_PLATFORM_USER>(
    SET_EDIT_PLATFORM_USER,
);

export const UPDATE_PLATFORM_USER = 'settings/UPDATE_PLATFORM_USER';
export const UPDATE_PLATFORM_USER_SUCCESS = 'settings/UPDATE_PLATFORM_USER_SUCCESS';
export const UPDATE_PLATFORM_USER_ERROR = 'settings/UPDATE_PLATFORM_USER_ERROR';

export const updatePlatformUser = createActions<
IUpdatePlatformUser,
IUserInfo,
any,
typeof UPDATE_PLATFORM_USER,
typeof UPDATE_PLATFORM_USER_SUCCESS,
typeof UPDATE_PLATFORM_USER_ERROR
>(
    UPDATE_PLATFORM_USER,
    UPDATE_PLATFORM_USER_SUCCESS,
    UPDATE_PLATFORM_USER_ERROR,
);

export const SET_PLATFORM_USER_STATUS = 'settings/SET_PLATFORM_USER_STATUS';
export const SET_PLATFORM_USER_STATUS_SUCCESS = 'settings/SET_PLATFORM_USER_STATUS_SUCCESS';
export const SET_PLATFORM_USER_STATUS_ERROR = 'settings/SET_PLATFORM_USER_STATUS_ERROR';

export const setPlatformUserStatus = createActions<
IUpdateUserStatus,
IUserInfo,
any,
typeof SET_PLATFORM_USER_STATUS,
typeof SET_PLATFORM_USER_STATUS_SUCCESS,
typeof SET_PLATFORM_USER_STATUS_ERROR
>(
    SET_PLATFORM_USER_STATUS,
    SET_PLATFORM_USER_STATUS_SUCCESS,
    SET_PLATFORM_USER_STATUS_ERROR,
);

export const GET_MORE_USERS = 'settings/GET_MORE_USERS';
export const GET_MORE_USERS_SUCCESS = 'settings/GET_MORE_USERS_SUCCESS';
export const GET_MORE_USERS_ERROR = 'settings/GET_MORE_USERS_ERROR';

export const getMoreUsersActions = createActions<
void,
IGetMoreUsersResponse,
any,
    typeof GET_MORE_USERS,
    typeof GET_MORE_USERS_SUCCESS,
    typeof GET_MORE_USERS_ERROR
>(
    GET_MORE_USERS,
    GET_MORE_USERS_SUCCESS,
    GET_MORE_USERS_ERROR,
);

export const CREATE_CLIENT_USER_ASSOCIATION = 'settings/CREATE_CLIENT_USER_ASSOCIATION';
export const CREATE_CLIENT_USER_ASSOCIATION_SUCCESS = 'settings/CREATE_CLIENT_USER_ASSOCIATION_SUCCESS';
export const CREATE_CLIENT_USER_ASSOCIATION_ERROR = 'settings/CREATE_CLIENT_USER_ASSOCIATION_ERROR';

export const createClientUsersAssociation = createActions<
ICreateClientUserAssociation,
any,
any,
typeof CREATE_CLIENT_USER_ASSOCIATION,
typeof CREATE_CLIENT_USER_ASSOCIATION_SUCCESS,
typeof CREATE_CLIENT_USER_ASSOCIATION_ERROR
>(
    CREATE_CLIENT_USER_ASSOCIATION,
    CREATE_CLIENT_USER_ASSOCIATION_SUCCESS,
    CREATE_CLIENT_USER_ASSOCIATION_ERROR,
);

export const GET_CLIENT_USER_ASSOCIATION = 'settings/GET_CLIENT_USER_ASSOCIATION';
export const GET_CLIENT_USER_ASSOCIATION_SUCCESS = 'settings/GET_CLIENT_USER_ASSOCIATION_SUCCESS';
export const GET_CLIENT_USER_ASSOCIATION_ERROR = 'settings/GET_CLIENT_USER_ASSOCIATION_ERROR';

export const getClientUsersAssociation = createActions<
void,
IClientUserAssociationResponse,
any,
typeof GET_CLIENT_USER_ASSOCIATION,
typeof GET_CLIENT_USER_ASSOCIATION_SUCCESS,
typeof GET_CLIENT_USER_ASSOCIATION_ERROR
>(
    GET_CLIENT_USER_ASSOCIATION,
    GET_CLIENT_USER_ASSOCIATION_SUCCESS,
    GET_CLIENT_USER_ASSOCIATION_ERROR,
);

export const GET_MORE_CLIENT_USER_ASSOCIATION = 'settings/GET_MORE_CLIENT_USER_ASSOCIATION';
export const GET_MORE_CLIENT_USER_ASSOCIATION_SUCCESS = 'settings/GET_MORE_CLIENT_USER_ASSOCIATION_SUCCESS';
export const GET_MORE_CLIENT_USER_ASSOCIATION_ERROR = 'settings/GET_MORE_CLIENT_USER_ASSOCIATION_ERROR';

export const getMoreClientUsersAssociation = createActions<
void,
IClientUserAssociationResponse,
any,
typeof GET_MORE_CLIENT_USER_ASSOCIATION,
typeof GET_MORE_CLIENT_USER_ASSOCIATION_SUCCESS,
typeof GET_MORE_CLIENT_USER_ASSOCIATION_ERROR
>(
    GET_MORE_CLIENT_USER_ASSOCIATION,
    GET_MORE_CLIENT_USER_ASSOCIATION_SUCCESS,
    GET_MORE_CLIENT_USER_ASSOCIATION_ERROR,
);

export const CLEAR_USERS_AND_FILTER = 'settings/platformUsers/CLEAR_USERS_AND_FILTER';
export const clearUsersAndFilter = createSingleAction<void, typeof CLEAR_USERS_AND_FILTER>(
    CLEAR_USERS_AND_FILTER,
);

export const SET_PLATFORM_USERS_FILTER = 'settings/platformUsers/SET_PLATFORM_USERS_FILTER';
export const setPlatformUsersFilter = createSingleAction<IPlatformUsersFilter, typeof SET_PLATFORM_USERS_FILTER>(
    SET_PLATFORM_USERS_FILTER,
);

export const REMOVE_CLIENT_USER_ASSOCIATION = 'settings/REMOVE_CLIENT_USER_ASSOCIATION';
export const REMOVE_CLIENT_USER_ASSOCIATION_SUCCESS = 'settings/REMOVE_CLIENT_USER_ASSOCIATION_SUCCESS';
export const REMOVE_CLIENT_USER_ASSOCIATION_ERROR = 'settings/REMOVE_CLIENT_USER_ASSOCIATION_ERROR';

export const removeClientUserAssociation = createActions<
string,
string,
any,
    typeof REMOVE_CLIENT_USER_ASSOCIATION,
    typeof REMOVE_CLIENT_USER_ASSOCIATION_SUCCESS,
    typeof REMOVE_CLIENT_USER_ASSOCIATION_ERROR
>(
    REMOVE_CLIENT_USER_ASSOCIATION,
    REMOVE_CLIENT_USER_ASSOCIATION_SUCCESS,
    REMOVE_CLIENT_USER_ASSOCIATION_ERROR,
);

export const SET_CLIENT_USER_ASSOCIATION_FILTER = 'settings/SET_CLIENT_USER_ASSOCIATION_FILTER';
export const setClientUserAssociationFilter = createSingleAction<
IClientUserAssociationFilter, typeof SET_CLIENT_USER_ASSOCIATION_FILTER
>(
    SET_CLIENT_USER_ASSOCIATION_FILTER,
);

export type PlatformUsersActions =
    | ActionsReturnTypes<typeof getMoreUsersActions>
    | ActionsReturnTypes<typeof getMoreClientUsersAssociation>
    | ActionsReturnTypes<typeof getClientUsersAssociation>
    | ActionsReturnTypes<typeof removeClientUserAssociation>
    | ActionsReturnTypes<typeof updatePlatformUser>
    | ActionsReturnTypes<typeof setPlatformUserStatus>
    | ReturnType<typeof clearUsersAndFilter>
    | ReturnType<typeof setClientUserAssociationFilter>
    | ReturnType<typeof setCreateUserModalState>;

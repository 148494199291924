import {
    REMOVE_ADDED_ENTRY,
    SheetActions,
} from 'store/entities/timesheet/actions/sheetState';
import { EntryType } from 'shared/models/sheet/Sheet';
import { addExpenseEntry, ExpenseSheetActions } from 'store/entities/timesheet/actions/expenseActions';
import { addTimeEntry, TimeSheetActions } from 'store/entities/timesheet/actions/timeActions';
import { getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { isLoadingReducer, singleValueReducer } from 'store/reducerUtils';

export type ILastAddedEntryState = {
    id: string;
    type: EntryType;
} | null;

const defaultState = {
    lastAddedEntry: null as ILastAddedEntryState,
};

export function lastAddedEntry(
    state: ILastAddedEntryState = defaultState.lastAddedEntry,
    action: SheetActions | ExpenseSheetActions | TimeSheetActions,
): ILastAddedEntryState {
    switch (action.type) {
        case addExpenseEntry.successType:
            return {
                id: action.payload.id,
                type: EntryType.EXPENSE,
            };
        case addTimeEntry.successType:
            return {
                id: action.payload.id,
                type: EntryType.TIME,
            };
        case REMOVE_ADDED_ENTRY:
            return null;
        default:
            return state;
    }
}

export const payPeriods = singleValueReducer(getSheetsPayPeriod.successType, []);
export const payPeriodsIsLoading = isLoadingReducer(getSheetsPayPeriod);

export const sheetsReducers = {
    lastAddedEntry,
    payPeriods,
    payPeriodsIsLoading,
};

import { IPayCode, PayCodeClass } from 'modules/settings/submodules/clients/payCodes/store/model';
import { selectPayCodes } from 'modules/settings/submodules/clients/payCodes/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { EntryType } from 'shared/models/sheet/Sheet';

interface IPayCodeSelectProps extends IFormFieldProps, IFormSelect {
    entryType: EntryType;
}

export default function PayCodeSelect({
    entryType,
    ...props
}: IPayCodeSelectProps) {
    const payCodes = useSelector(selectPayCodes)
        .filter(payCode => entryType === EntryType.EXPENSE
            ? payCode.pay_class === PayCodeClass.ExpenseReimbursement
            : payCode.pay_class === PayCodeClass.Earnings);

    return (
        <FormSelect
            {...props}
            getKey={(code: IPayCode) => code.id}
            getText={(code: IPayCode) => code.description}
            options={payCodes}
        />
    );
}

import { ICostCenter } from 'shared/models/CostCenters';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber, IJobNumberUserType } from 'shared/models/JobNumber';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import {
    IActivity,
    IActivityRequest,
    IArea,
    IAssignment,
    IAssignmentsRequest,
    IAssignmentWithLinkedRequest,
    IBackgroundCheckTemplate,
    IConfigurationByAssignments, IConfigurationByClient, IJobNumberRequest,
    ILocationRequest,
    IPhysicalDemandsTemplate,
    IProject,
    IProjectsRequest,
    IProjectWithAssignmentBackend, IProjectWithAssignmentRequest,
    ITask,
    IWorkingConditionsTemplate,
} from 'store/entities/configuration/configurationModel';
import { ActionsReturnTypes, createRequestActions, RequestType } from 'store/utils';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { ISubmittingOrgLocation } from 'shared/models/SubmittingOrgLocation';
import { IDealType } from 'shared/models/DealType';
import { IRequestWithClientId } from '../../../shared/models/Request';

export const getTasks = createRequestActions<IConfigurationByAssignments | void, Array<ITask>>(
    RequestType.Get,
    'TASKS');

export const getAreas = createRequestActions<void, Array<IArea>>(
    RequestType.Get,
    'AREAS');

export const getActivities = createRequestActions<IActivityRequest | void, Array<IActivity>>(
    RequestType.Get,
    'ACTIVITY');

export const deleteActivity = createRequestActions<string, string>(
    RequestType.Delete,
    'ACTIVITY');

export const getProjectsAssignments = createRequestActions<IProjectWithAssignmentRequest,
Array<IProjectWithAssignmentBackend>>(
    RequestType.Get,
    'PROJECTS_ASSIGNMENTS');

export type TProjectAssignmentAction =
    ReturnType<typeof getProjectsAssignments.success> | ReturnType<typeof getProjectsAssignments.error>

export const getAssignments = createRequestActions<IAssignmentsRequest | void, IAssignment[]>(
    RequestType.Get,
    'ASSIGNMENTS');

export const getProjects = createRequestActions<IProjectsRequest | void, Array<IProject>>(
    RequestType.Get,
    'PROJECTS');

export const getControllingOrgs = createRequestActions<void, IControllingOrg[]>(
    RequestType.Get,
    'CONTROLLING_ORGS');

export const getSubmittingOrgs = createRequestActions<IRequestWithClientId, ISubmittingOrg[]>(
    RequestType.Get,
    'SUBMITTING_ORGS');

export const getSubmittingOrgLocations = createRequestActions<IRequestWithClientId, ISubmittingOrgLocation[]>(
    RequestType.Get,
    'SUBMITTING_ORG_LOCATIONS');

export const getPositions = createRequestActions<void, IPosition[]>(
    RequestType.Get,
    'POSITIONS');

export const getLocations = createRequestActions<ILocationRequest | void, ILocation[]>(
    RequestType.Get,
    'LOCATIONS');

export const getPhysicalDemands = createRequestActions<void, IPhysicalDemandsTemplate[]>(
    RequestType.Get,
    'PHYSICAL_DEMANDS');

export const removePhysicalDemandsTemplate = createRequestActions<string, string>(
    RequestType.Delete,
    'PHYSICAL_DEMANDS_TEMPLATE',
    'settings/');

export const getWorkingConditions = createRequestActions<void, IWorkingConditionsTemplate[]>(
    RequestType.Get,
    'WORKING_CONDITIONS');

export const removeWorkingConditionsTemplate = createRequestActions<string, string>(
    RequestType.Delete,
    'WORKING_CONDITIONS_TEMPLATE',
    'settings/');

export const getBackgroundCheckTemplates = createRequestActions<void, IBackgroundCheckTemplate[]>(
    RequestType.Get,
    'BACKGROUND_CHECK_TEMPLATE');

export const removeBackgroundCheckTemplate = createRequestActions<string, string>(
    RequestType.Delete,
    'BACKGROUND_CHECK_TEMPLATE',
    'settings/');

export const loadClientAssignmentsWithLinked = createRequestActions<IAssignmentWithLinkedRequest, any>(
    RequestType.Get,
    'CLIENT_ASSIGNMENTS');

export const getCostCenters = createRequestActions<void, ICostCenter[]>(
    RequestType.Get,
    'COST_CENTERS');

export const getJobNumbers = createRequestActions<IJobNumberRequest | void, IJobNumber[]>(
    RequestType.Get,
    'JOB_NUMBERS');

export const getUserTypesAction = createRequestActions<void, IJobNumberUserType[]>(
    RequestType.Get,
    'USER_TYPES',
    'job_numbers',
);

export const getDeals = createRequestActions<IConfigurationByClient | void, IDeal[]>(
    RequestType.Get,
    'DEALS');

export const getDealTypes = createRequestActions<void, IDealType[]>(
    RequestType.Get,
    'DEAL_TYPES');

export type ConfigurationAction =
    | ActionsReturnTypes<typeof getProjectsAssignments>
    | ActionsReturnTypes<typeof getActivities>
    | ActionsReturnTypes<typeof deleteActivity>
    | ActionsReturnTypes<typeof getAreas>
    | ActionsReturnTypes<typeof getProjects>
    | ActionsReturnTypes<typeof getLocations>
    | ActionsReturnTypes<typeof getControllingOrgs>
    | ActionsReturnTypes<typeof getSubmittingOrgs>
    | ActionsReturnTypes<typeof getPositions>
    | ActionsReturnTypes<typeof getAssignments>
    | ActionsReturnTypes<typeof getPhysicalDemands>
    | ActionsReturnTypes<typeof removePhysicalDemandsTemplate>
    | ActionsReturnTypes<typeof getWorkingConditions>
    | ActionsReturnTypes<typeof removeWorkingConditionsTemplate>
    | ActionsReturnTypes<typeof getBackgroundCheckTemplates>
    | ActionsReturnTypes<typeof removeBackgroundCheckTemplate>
    | ActionsReturnTypes<typeof loadClientAssignmentsWithLinked>
    | ActionsReturnTypes<typeof getCostCenters>
    | ActionsReturnTypes<typeof getJobNumbers>
    | ActionsReturnTypes<typeof getDeals>
    | ActionsReturnTypes<typeof getDealTypes>

/* eslint-disable react/display-name */
import moment from 'moment';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { IUserInfo } from 'shared/models/User';
import { useSelector } from 'react-redux';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { shortDateFormat } from 'shared/models/Dates';
import { settingsRoutes } from 'modules/settings/routes';
import { userFullName } from 'shared/utils/converters/user';
import { IClientInfo } from 'store/entities/clients/clientsModel';
import { selectAvailableClients } from 'store/entities/clients/clientsSelectors';

interface IClientRow extends IClientInfo {
    className?: string;
    name: string;
    id: string;
    short_name: string;
    created_at?: string;
    updated_at?: string;
    prism_client_id?: string;
    manager: IUserInfo | null;
}

export const ClientsTable = () => {
    const tableClasses = useDefaultTableStyles();
    const clientById = useSelector(selectAvailableClients);
    const clients = Object.values(clientById);

    const clientCells = [
        {
            key: 'id',
            title: 'ID',
            render: ({ prism_client_id, className }: IClientRow) => (
                <PlainText className={className} value={prism_client_id}/>
            ),
        },
        {
            key: 'client',
            title: 'Client',
            render: ({ name, className }: IClientRow) => (
                <PlainText className={className} value={name}/>
            ),
        },
        {
            key: 'client manager',
            title: 'client manager',
            render: ({ className, manager }: IClientRow) => (
                <PlainText className={className} value={userFullName(manager)}/>
            ),
        },
        {
            key: 'created',
            title: 'created',
            render: ({ created_at, className }: IClientRow) => (
                <PlainText className={className} value={moment(created_at).format(shortDateFormat)}/>
            ),
        },
        {
            key: 'updated',
            title: 'updated',
            render: ({ updated_at, className }: IClientRow) => (
                <PlainText className={className} value={moment(updated_at).format(shortDateFormat)}/>
            ),
        },
        {
            key: 'status',
            title: 'status',
            width: '100px',
            render: ({ status, className }: IClientRow) => (
                <PlainText className={className} value={status?.name || ''}/>
            ),
        },
        {
            key: 'state',
            title: 'state',
            render: ({ state, className }: IClientRow) => (
                <PlainText className={className} value={state}/>
            ),
        },
        {
            key: 'client contact',
            title: 'client contact',
            render: ({ contact_name, className }: IClientRow) => (
                <PlainText className={className} value={contact_name}/>
            ),
        },
        {
            key: 'details',
            title: '',
            width: '152px',
            render: function DetailsCell({ className, id }: IClientRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <Link to={generatePath(settingsRoutes.CLIENTS.DASHBOARD, { clientId: id })}>
                            <IconButton data-testid="details-button">
                                <ArrowForwardIosIcon/>
                            </IconButton>
                        </Link>
                    </div>
                );
            },
        },
    ];

    return (
        <GridTable
            rowData={clients}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={client => client.id}
            cells={clientCells}
            stickyHeader
        />
    );
};

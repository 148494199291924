import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditEntrySave from 'shared/components/sidebars/EntryEdit/EditEntrySave';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { EntryType } from 'shared/models/sheet/Sheet';
import { selectEntry, selectTempEntryAttachments, selectTimeEntries } from 'store/entities/timesheet/selectors';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import { removeAttachment } from 'store/entities/timesheet/actions/entryAttachments';
import EditExpenseEntry from './EditExpenseEntry';
import EditTimeEntry from './EditTimeEntry';
import { selectFieldConfiguration, selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { selectEditEntryId } from 'modules/timeAndExpense/components/EditEntry/store/selectors';
import { setEditEntryId } from 'modules/timeAndExpense/components/EditEntry/store/actions';

interface ITimeEntryEditWrapperProps {
    userId?: string;
}

export default function EntryEdit({ userId }: ITimeEntryEditWrapperProps) {
    const classes = useEntryEditStyles();
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));
    const entryId = useSelector(selectEditEntryId);

    const entry = useSelector(selectEntry(entryId));
    const title = useMemo(() => {
        if (!entry) {
            return '';
        }
        return `Edit ${entry.entry_type === EntryType.TIME ? 'Time' : 'Expense' } Entry`;
    }, [entry]);
    const attachments = useSelector(selectTempEntryAttachments);

    const onSidebarClose = useCallback(() => {
        attachments.forEach(file => dispatch(removeAttachment.init(file)));
        dispatch(setEditEntryId(null));
    }, [attachments, dispatch]);
    const timeEntries = useSelector(selectTimeEntries);
    const totalEntryMinutesByDay = useTotalTimeEntriesByDay(timeEntries, entry?.id);

    return (
        <Sidebar
            isOpened={Boolean(entryId)}
            onClose={onSidebarClose}
            title={title}
            customClasses={classes.paper}
            anchor="right"
        >
            {configuration && (
                <>
                    {entry && entry.entry_type === EntryType.TIME && (
                        <EditTimeEntry
                            totalMinutesByDay={totalEntryMinutesByDay}
                            SubmitBlock={EditEntrySave}
                            entry={entry}
                            inputs={configuration.inputs.time}
                            userId={userId}
                        />
                    )}
                    {entry && entry.entry_type !== EntryType.TIME && (
                        <EditExpenseEntry
                            SubmitBlock={EditEntrySave}
                            entry={entry}
                            inputs={configuration.inputs.expense}
                            userId={userId}
                        />
                    )}
                </>
            )}
        </Sidebar>
    );
}

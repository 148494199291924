import React from 'react';
import { useField } from 'formik';
import { INumberFormatProps } from '../inputs/model';
import { IFormFieldProps } from './models';
import NumberInput from './NumberInput';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { FormControl } from '@material-ui/core';

export interface IFormNumberInputProps extends IFormFieldProps {
    inputProps?: Partial<INumberFormatProps>;
    min?: number;
    max?: number;
    type?: 'text' | 'tel' | 'password';
    outerLabel?: string;
}

const NumberField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    inputProps,
    min,
    max,
    type,
    outerLabel,
}: IFormNumberInputProps) => {
    const [field, meta, helper] = useField(name);
    const hasError = Boolean(meta.error && meta.touched);

    const handleChange = (value: string) => {
        helper.setValue(value);
    };

    const formHelperTextClasses = useFormHelperTextStyles();

    return (
        <FormControl
            error={hasError}
            className={className}
            variant="outlined"
            key={id}
        >
            {outerLabel && (
                <label htmlFor={id} className={formHelperTextClasses.outerLabel}>
                    {outerLabel}
                </label>
            )}
            <NumberInput
                id={id}
                name={name}
                label={label}
                type={type}
                initialValue={field.value}
                inputProps={{
                    ...inputProps,
                }}
                disabled={disabled}
                error={meta.error}
                touched={meta.touched}
                setTouched={helper.setTouched}
                onChange={handleChange}
                min={min}
                max={max}
            />
        </FormControl>
    );
};

export default NumberField;

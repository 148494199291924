import { call, put, select, takeLatest } from 'typed-redux-saga';
import { api } from 'modules/settings/submodules/clients/store/api';
import {
    ICreateWorkLocationRequest,
    IUpdateWorkLocationRequest,
} from 'modules/settings/submodules/clients/store/models';
import { getLocations } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { selectLocationById } from 'store/entities/configuration/configurationSelectors';
import {
    createClientWorkLocation,
    deleteClientWorkLocation, syncClientWorkLocation, updateClientWorkLocation,
} from 'modules/settings/submodules/clients/workLocation/store/actions';
import { refreshClients } from 'store/entities/clients/clientsAction';

function* createClientWorkLocationSaga(action: ReturnType<typeof createClientWorkLocation.init>) {
    const result = yield* call(api.createClientWorkLocation, {
        ...action.payload,
        client_name: undefined,
        location_id: undefined,
        geo_code: undefined,
    } as ICreateWorkLocationRequest);
    yield put(createClientWorkLocation.success(result));
    yield put(getLocations.success([result]));
}

function* createClientWorkLocationWatcher() {
    yield* takeLatest(
        createClientWorkLocation.initType,
        withBackendErrorHandler(
            createClientWorkLocationSaga,
            createClientWorkLocation.error,
            'Unable to create Work Location.',
        ),
    );
}

function* updateClientWorkLocationSaga(action: ReturnType<typeof updateClientWorkLocation.init>) {
    const result = yield* call(api.updateClientWorkLocation, action.payload.id, {
        ...action.payload.request,
        client_name: undefined,
        location_id: undefined,
        geo_code: undefined,
        client_id: undefined,
    } as IUpdateWorkLocationRequest);
    yield put(updateClientWorkLocation.success(result));
    yield put(getLocations.success([result]));
}

function* updateClientWorkLocationWatcher() {
    yield* takeLatest(
        updateClientWorkLocation.initType,
        withBackendErrorHandler(
            updateClientWorkLocationSaga,
            updateClientWorkLocation.error,
            'Unable to update Work Location',
        ),
    );
}

function* syncClientWorkLocationSaga() {
    const locations = yield* call(api.syncWorkLocations);
    yield put(getLocations.success(locations));
    yield put(syncClientWorkLocation.success());
    yield put(refreshClients()); //clients contain main_location
}

function* syncClientWorkLocationWatcher() {
    yield* takeLatest(
        syncClientWorkLocation.initType,
        withBackendErrorHandler(
            syncClientWorkLocationSaga,
            syncClientWorkLocation.error,
            'Unable to sync Work Location',
        ),
    );
}

function* deleteClientWorkLocationSaga(action: ReturnType<typeof deleteClientWorkLocation.init>) {
    const locationId = action.payload;
    const result = yield* call(api.deleteClientWorkLocation, action.payload);
    yield put(deleteClientWorkLocation.success(result));
    const location = yield* select(selectLocationById(locationId));
    yield put(getLocations.success([{
        ...location,
        deleted: true,
    }]));
}

function* deleteClientWorkLocationWatcher() {
    yield* takeLatest(
        deleteClientWorkLocation.initType,
        withBackendErrorHandler(
            deleteClientWorkLocationSaga,
            deleteClientWorkLocation.error,
            'Unable to delete Work Location',
        ),
    );
}

export default [
    createClientWorkLocationWatcher,
    updateClientWorkLocationWatcher,
    deleteClientWorkLocationWatcher,
    syncClientWorkLocationWatcher,
];

import React from 'react';
import { Box } from '@material-ui/core';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import useFilterAndActionControlsStyles
    from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import Total, { TotalTypes } from 'shared/components/toolbar/total/Total';
import { sheetsTotalBreakTime, sheetsTotalTimeInMinutes } from 'shared/utils/counters/timeCounter';
import { sheetsTotalDollars } from 'shared/utils/counters/dollarCounter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import {
    selectEmployeeSubmittedSheetsFilters,
    selectEmployeeSubmittedSheetsFiltersConfiguration,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { setEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import FilterButton from 'shared/components/filters/FilterButtonAndForm/FilterButton';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import FilterButtonMock from 'shared/components/filters/FilterButtonAndForm/FilterButtonMock';

interface IMobileFiltersAndAmountProps {
    sheets: Array<ISheet>;
    sheetType: EntryType;
    showFilters?: boolean;
}

export default function MobileFiltersAndAmount(
    { sheetType, sheets, showFilters }: IMobileFiltersAndAmountProps,
) {
    const classes = useFilterAndActionControlsStyles();
    const [filtersFeature] = useFeature(FeatureSwitches.displayFilters);

    const totalHours = formatMinutes(sheetsTotalTimeInMinutes(sheets));
    const totalBreaks = sheetsTotalBreakTime(sheets);
    const totalAmount = sheetsTotalDollars(sheets);

    return (
        <Box className={classes.mobileRoot}>
            {(showFilters ?? true) && (
                <>
                    {filtersFeature ? (
                        <FilterButton overrideClassName={classes.actionButton}
                            selectFilters={selectEmployeeSubmittedSheetsFilters}
                            selectFiltersConfig={selectEmployeeSubmittedSheetsFiltersConfiguration}
                            submitFilters={setEmployeeSubmittedSheetsFilters}
                        />
                    ) : (
                        <FilterButtonMock overrideClassName={classes.actionButton}/>
                    )}
                </>
            )}
            {sheetType === EntryType.TIME ? (
                <Box className={classes.mobileTotalRoot}>
                    <Total value={totalHours} type={TotalTypes.Time}/>
                    {totalBreaks > 0 && (
                        <Total
                            value={formatMinutes(totalBreaks)}
                            type={TotalTypes.Break}
                            classes={{ root: classes.mobileTotal }}
                        />
                    )}
                </Box>
            ) : (
                <Total value={totalAmount} type={TotalTypes.Expense}/>
            )}
        </Box>
    );
}

import React from 'react';
import { Formik } from 'formik';
import { defaultUserFormValues, IUserFormValues } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/model';
import { UserValidationSchema } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/ValidationsSchema';
import { UserForm } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/UserForm';
import { IUserInfo } from 'shared/models/User';

export interface IUserFormWrapperProps {
    onSubmit: (values: IUserFormValues) => void;
    submitTitle: string;
    isLoading: boolean;
    user?: IUserInfo;
}

const UserFormWrapper = ({
    onSubmit,
    submitTitle,
    isLoading,
    user,
}: IUserFormWrapperProps) => {
    const initialFormValues = {
        first_name: user?.first_name || defaultUserFormValues.first_name,
        last_name: user?.last_name || defaultUserFormValues.last_name,
        job_title: user?.job_title || defaultUserFormValues.job_title,
        email: user?.email || defaultUserFormValues.email,
        cell_phone: user?.cell_phone || defaultUserFormValues.cell_phone,
        global_role_id: user?.global_roles?.[0]?.id || defaultUserFormValues.global_role_id,
        address: user?.address1 || defaultUserFormValues.address,
        address2: user?.address2 || defaultUserFormValues.address2,
        city: user?.city || defaultUserFormValues.city,
        state: user?.state || defaultUserFormValues.state,
        county: user?.county || defaultUserFormValues.county,
        zip_code: user?.zip_code || defaultUserFormValues.zip_code,
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={UserValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <UserForm
                    {...props}
                    isLoading={isLoading}
                    submitTitle={submitTitle}
                />
            )}
        </Formik>
    );
};

export default UserFormWrapper;

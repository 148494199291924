import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik/dist/types';
import {
    defaultPositionWorkLocationAssociationValues,
    IPositionWorkLocationAssociationFormValues,
} from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/CreateAssociation/model';
import { createPositionWorkLocationAssociation } from 'modules/settings/submodules/clients/store/actions';
import { selectIsPositionLocationAssociationCreating } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/selector';
import { PositionWorkLocationAssociationValidationsSchema } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/CreateAssociation/ValidationsSchema';
import { LocationAutocomplete } from 'shared/components/autocomplete/LocationAutocomplete';
import { PositionAutocomplete } from 'shared/components/autocomplete/PositionAutocomplete';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';

const CreateAssociation = () => {
    const classes = useInlineFormStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsPositionLocationAssociationCreating);
    const onSubmitForm = (
        values: IPositionWorkLocationAssociationFormValues,
        { resetForm }: FormikHelpers<IPositionWorkLocationAssociationFormValues>,
    ) => {
        dispatch(createPositionWorkLocationAssociation.init({
            position_id: values.position?.id || '',
            location_id: values.location?.id || '',
        }));
        resetForm();
    };

    return (
        <Formik
            initialValues={defaultPositionWorkLocationAssociationValues}
            validationSchema={PositionWorkLocationAssociationValidationsSchema}
            onSubmit={onSubmitForm}
        >
            {(props: FormikProps<IPositionWorkLocationAssociationFormValues>) => (
                <form
                    onSubmit={props.handleSubmit}
                    className={classes.form}
                >
                    <PositionAutocomplete
                        name="position"
                        label="Position"
                        className={classes.input}
                    />
                    <LocationAutocomplete
                        name="location"
                        label="Work location"
                        className={classes.input}
                    />
                    <ButtonWithLoader
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        isLoading={isLoading}
                        data-testid="submit_button"
                    >
                        Associate
                    </ButtonWithLoader>
                </form>
            )}
        </Formik>
    );
};

export default CreateAssociation;

import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import { selectUsersByFeature } from 'store/entities/users/selectors';
import { IFormSelect } from './model';
import { PrismUserType, UserFeature } from 'store/entities/users/model';
import { IFormFieldProps } from '../formFields/models';

export interface ISpecialUserSelectProps extends IFormFieldProps, IFormSelect {
    feature: UserFeature;
    featureValue: Permission | PrismUserType;
}

export default function SpecialUserSelect({
    feature,
    featureValue,
    ...props
}: ISpecialUserSelectProps) {
    const users = useSelector(selectUsersByFeature(feature, featureValue));

    return (
        <FormSelect
            {...props}
            getKey={(user: IUserInfo) => user.id || ''}
            getText={(user: IUserInfo) => `${user.first_name} ${user.last_name}`}
            options={users}
        />
    );
}

import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useOfferLetterStyles = makeHighPriorityStyles((theme: Theme) => ({
    paper: {
        maxWidth: '100%',
        background: 'transparent',
    },
    drawer: {
        width: '100vw',
    },
    wrapper: {
        padding: theme.spacing(0, 6),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    mobileLookupButton: {
        position: 'fixed !important' as 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    offerLetterViewWrapper: {
        width: '100%',
        minHeight: '100vh',
        background: colors.white,
    },
    acceptanceStepsWrapper: {
        color: colors.white,
        backgroundColor: colors.secondary,
        width: theme.spacing(50),
        padding: theme.spacing(3, 2),
        height: '100vh',
        boxSizing: 'border-box',
    },
    stepper: {
        marginTop: theme.spacing(5),
        backgroundColor: 'transparent !important',
        height: '40%',
    },
    step: {
        cursor: 'pointer',
    },
    fullWidthHeight: {
        width: '100%',
        height: '100vh',
        minHeight: '70vh',
        minWidth: '60vw',
    },
    offerWrapper: {
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '100%',
        gridTemplateRows: 'auto 72px',
    },
    onboardingContainer: {
        overflowY: 'auto',
        width: '100%',
        height: '100vh',
        flexDirection: 'column',
    },
    headerWrapper: {
        padding: theme.spacing(2),
        borderBottom: `1px solid ${colors.black}`,
    },
    blueHeader: {
        background: colors.primary,
        color: colors.white,
        '& a': {
            color: 'inherit !important',
        },
    },
    headerIcon: {
        backgroundColor: colors.lightGray,
        borderRadius: '50%',
        marginRight: theme.spacing(2),
        padding: theme.spacing(0.5),
        color: theme.palette.text.primary,
    },
    headerText: {
        fontWeight: 500,
    },
    contentWrapper: {
        width: `calc(100% - ${theme.spacing(10)}px)`,
        height: `calc(100% - ${theme.spacing(10)}px)`,
        margin: theme.spacing(2, 5, 0, 5),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2, 2, 0, 2),
            width: `calc(100% - ${theme.spacing(4)}px)`,
        },
    },
    actionButtonsWrapper: {
        padding: theme.spacing(2, 0),
        '& > *': {
            padding: theme.spacing(1, 3),

            '& + *': {
                marginLeft: '24px !important',
            },
        },
    },
    secondaryButton: {
        textDecoration: 'underline',
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',

        '& button': {
            margin: 0,
            marginRight: theme.spacing(1),
        },
    },
    pdfWrapper: {
        height: '100%',
    },
    link: {
        '&&, &&:visited': {
            color: colors.black,
        },
    },
    finishButtonContainer: {
        flex: '0 0 75%',

        [theme.breakpoints.down(769)]: {
            flex: '0 0 66.66667%',
        },
    },
}));

export const useStepLabelStyles = makeHighPriorityStyles((theme: Theme) => ({
    iconContainer: {
        margin: theme.spacing(0, 3.5, 0, -0.375),
    },
    label: {
        '&&, && *': {
            color: `${colors.gray} !important`,
        },
    },
    active: {
        '&&, && *': {
            color: `${colors.white} !important`,
            fontSize: '20px',
        },
    },
}));

export const useStepConnectorStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        padding: '0 !important',
        marginLeft: `16px !important`,
        fontWeight: theme.typography.fontWeightMedium,
    },
    lineVertical: {
        borderLeft: `2px solid ${colors.gray}`,
    },
    completed: {
        marginLeft: '15px !important',
        '& $lineVertical': {
            borderLeft: `4px solid ${colors.green}`,
        },
    },
    active: {
        marginLeft: '15px !important',
        '& $lineVertical': {
            borderLeft: `4px solid ${colors.green}`,
        },
    },
}));

export const useStepIconStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
        border: `2px solid ${colors.green}`,
        display: 'flex',
        borderRadius: '50%',
        color: colors.white,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',
        fontWeight: theme.typography.fontWeightMedium,
    },
    active: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        margin: theme.spacing(0, -1.25, 0, -1.25),
        backgroundColor: colors.green,
    },
    completed: {
        backgroundColor: colors.green,
        zIndex: 1,
    },
}));

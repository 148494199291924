import { chain, isString } from 'lodash';
import moment from 'moment';
import { generatePath, matchPath } from 'react-router';
import { IClient } from 'store/entities/clients/clientsModel';
import {
    all, call, put, select, spawn, takeEvery, takeLatest,
} from 'typed-redux-saga';

import { authByPassword, authByToken, authTokenUpdate } from 'store/components/auth/authActions';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { navigateWithClientSaga } from 'store/components/router/routerSagas';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { setClients } from 'store/entities/clients/clientsAction';
import { getLocations, getPositions } from 'store/entities/configuration/configurationAction';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { optimizely } from 'utils/optimizely';

import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import lookupSagas from 'modules/offerLetter/components/lookup/data/sagas';
import {
    createOffer, editOffer, rescindOffer,
    getMyEmployeeProfile, getMyOfferLetters,
    getOfferLetter,
    getOfferLetters,
    getPrismSSOLink,
    setOnboardingStep,
    updateEmployeeProfile,
    updateOfferLetter,
    updateOfferLetterStatus,
    viewOfferLetter,
    checkOfferLetterPdf,
    getEmployeeProfiles,
    checkEmployeeOnboardingStatus, getMoreOfferLetterAction, initialLoadOfferLetters, setOfferLetterFilters,
} from 'modules/offerLetter/store/actions';
import { offerLetterApi } from 'modules/offerLetter/store/api';
import {
    ICreateEmployeeOfferLetterBackend,
    ICreateOfferLetterBackend, ICreateOfferRequest, IGetOfferLetterParams,
    IOfferLetter,
    IUpdateOfferRequest, OfferLetterStatusNameMap,
    OfferLetterStatusSlug,
} from 'modules/offerLetter/store/model';
import { offerLettersTableStateSelectors, selectOfferLetter, selectOfferLetterFilters } from 'modules/offerLetter/store/selectors';

import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { getUserName } from 'shared/utils/converters/user';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { takeLatestActionByString } from 'shared/utils/sagas';

import { IOfferLetterFormValues } from '../components/OfferLetterForm/model';

import offerLetterTemplateSagas from './templates/sagas';
import { IPosition } from 'shared/models/Position';
import { convertFromBackendToLocation, ILocation } from 'shared/models/Location';
import { FeatureSwitches } from 'utils/featureSwitches';

function* getOfferLettersSaga() {
    const range = yield select(offerLettersTableStateSelectors.selectNextRange);
    const filter = yield select(selectOfferLetterFilters);
    const request: IGetOfferLetterParams = {
        range,
        position_id: filter.positionId || undefined,
        location_id: filter.locationId || undefined,
        start_date: filter.startDate || undefined,
        creator_id: filter.creatorId || undefined,
        search: filter.search || undefined,
        status: Object.values(OfferLetterStatusSlug).includes(filter.statusSlug) ? filter.statusSlug : undefined,
    };
    const { offer_letters: offerLetters, total_items: total } = yield* call(offerLetterApi.getOfferLetter, request);
    yield put(getPositions.success(offerLetters.map((letter: IOfferLetter) => letter.position)));
    yield put(getLocations.success(
        offerLetters.map((letter: IOfferLetter) => convertFromBackendToLocation(letter.location)),
    ));
    yield put(getOfferLetters.success(offerLetters));
    yield put(getMoreOfferLetterAction.success({
        offerLetterIds: offerLetters.map(offer => offer.id),
        total,
    }));
}

function* getOfferLettersWatcher() {
    yield takeLatest([
        getMoreOfferLetterAction.initType,
        initialLoadOfferLetters.action,
        setOfferLetterFilters.action,
    ], withBackendErrorHandler(
        getOfferLettersSaga,
        getMoreOfferLetterAction.error,
        'Unable to fetch offer letters',
    ));
}

const getCommonOfferLetterData = (payload: IOfferLetterFormValues): ICreateOfferRequest => {
    return {
        first_name: payload.first_name,
        last_name: payload.last_name,
        pay_rate_value: parseFloat(payload.payRate),
        pay_rate_type: payload.payRateType,
        position_id: payload.position?.id || '',
        location_id: payload.locationId,
        department_id: payload.departmentId || undefined,
        start_date: payload.startDate,
        cell_phone: payload.phone,
        managers: payload.approvers
            .filter(ap => ap !== null)
            .map((ap, index) => {
                return {
                    user_id: ap?.id || '',
                    manager_level: index + 1,
                };
            }),
        offer_letter_template_id: payload.template || undefined,
        range_of_hours: payload.hours || undefined,
        employment_type: payload.employeeType || undefined,
        working_conditions: payload.workingConditions || '',
        // TODO: Commented out fields until they are implemented
        // working_conditions_template_id: payload.workingConditionsTemplate?.id || undefined,
        physical_demands: payload.physicalDemands || '',
        // physical_demands_template_id: payload.physicalDemandsTemplate?.id || undefined,
        background_checks: payload.backgroundChecks || '',
        // background_checks_template_id: payload.backgroundChecksTemplate?.id || undefined,
    };
};

function* sentOfferToast(offerLetter: IOfferLetter) {
    const userName = getUserName(offerLetter);
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: userName ? `${userName}'s offer letter was successfully sent.` : 'Offer letter was successfully sent.',
        autoHideDuration: 5000,
    }));
}

function* createOfferSaga({ payload }: ReturnType<typeof createOffer.init>) {
    let createdOfferLetter;
    const commonOfferData = getCommonOfferLetterData(payload);
    if (payload.userId) {
        const createOfferPayload: ICreateEmployeeOfferLetterBackend = {
            user_id: payload.userId,
            ...commonOfferData,
        };
        createdOfferLetter = yield call(offerLetterApi.createEmployeeOffer, createOfferPayload);
    } else {
        const createOfferPayload: ICreateOfferLetterBackend = {
            email: payload.email,
            ...commonOfferData,
        };
        createdOfferLetter = yield call(offerLetterApi.createOffer, createOfferPayload);
    }
    yield put(createOffer.success(createdOfferLetter));
    yield* sentOfferToast(createdOfferLetter);
}

function* createOfferWatcher() {
    yield takeLatestActionByString(createOffer.initType, withBackendErrorHandler(
        createOfferSaga, createOffer.error, 'Offer wasn\'t created',
    ));
}

function* editOfferSaga({ payload: { id, values } }: ReturnType<typeof editOffer.init>) {
    const offerPayload: ICreateOfferLetterBackend = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        ...getCommonOfferLetterData(values),
    };
    const newOfferLetter = yield call(offerLetterApi.updateOfferAndResend, id, offerPayload);

    yield put(editOffer.success(newOfferLetter));
    yield put(getOfferLetter.init(id));
    yield* sentOfferToast(newOfferLetter);
}

function* editOfferWatcher() {
    yield takeLatestActionByString(editOffer.initType, withBackendErrorHandler(
        editOfferSaga, editOffer.error, 'Offer wasn\'t modified',
    ));
}

function* rescindOfferSaga({
    payload: { id, values: { rescissionReason, rescissionReasonText } },
}: ReturnType<typeof rescindOffer.init>) {
    const params: Partial<IUpdateOfferRequest> = {
        rescission_reason: rescissionReason,
        rescission_reason_custom_text: rescissionReasonText,
        status: OfferLetterStatusSlug.Rescinded,
    };
    const rescindedOfferLetter = yield call(offerLetterApi.updateOfferLetter, id, params);

    yield put(rescindOffer.success(rescindedOfferLetter));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Offer letter was successfully rescinded',
        autoHideDuration: 5000,
    }));
}

function* rescindOfferWatcher() {
    yield takeLatestActionByString(rescindOffer.initType, withBackendErrorHandler(
        rescindOfferSaga, rescindOffer.error, 'Offer wasn\'t rescinded',
    ));
}

function* listOfferRouteWatcher() {
    yield takeLatest(
        [editOffer.successType, rescindOffer.successType],
        navigateWithClientSaga,
        routes.CLIENT.OFFER_LETTER.ROOT,
    );
}

function* getOfferLetterSaga({ payload: offerId }: ReturnType<typeof getOfferLetter.init>) {
    const offerLetter = yield* call(offerLetterApi.getOfferLetterById, offerId);
    yield put(getPositions.success([offerLetter.position]));
    yield put(getLocations.success([convertFromBackendToLocation(offerLetter.location)]));
    yield put(getOfferLetter.success(offerLetter));
}

function* getOfferLetterWatcher() {
    yield takeLatestActionByString(getOfferLetter.initType, getOfferLetterSaga);
}

function* viewOfferLetterSaga({ payload: offerId }: ReturnType<typeof viewOfferLetter>) {
    const offerLetter = yield select(selectOfferLetter(offerId));
    if (offerLetter && !offerLetter.viewed_by_employee_at) {
        yield put(updateOfferLetter.init({
            id: offerLetter.id,
            values: {
                viewed_by_employee_at: moment.utc().format(),
            },
        }));
    }
}

function* viewOfferLetterWatcher() {
    yield takeLatestActionByString(viewOfferLetter.action, viewOfferLetterSaga);
}

function* getEmployeeProfilesSaga(clients: IClient[]) {
    const employeeProfiles = yield* call(offerLetterApi.getMyEmployeeProfiles);
    yield put(getEmployeeProfiles.success(employeeProfiles));

    const checkProfilesOnboardingStatus = employeeProfiles.filter(profile => !profile.prism_onboarding_completed);
    if (checkProfilesOnboardingStatus.length > 0) {
        yield all(
            checkProfilesOnboardingStatus.map(profile => put(checkEmployeeOnboardingStatus.init({
                profile,
                client: clients.find(client => client.id === profile.client_id),
            }))),
        );
    }
}

export function* getOwnOfferLettersSaga() {
    const userHasPermission = yield* select(selectIsUserHasPermission(Permission.ViewMyOffers));
    if (!userHasPermission) {
        return;
    }

    const offerLettersWithLinked = yield call(offerLetterApi.getAllOfferLetters, { self: true });

    const offerLetters = offerLettersWithLinked.offer_letters;

    const offerLetterPositions = offerLetters.map((letter: IOfferLetter) => letter.position)
        .filter((position: IPosition) => Boolean(position));
    yield put(getPositions.success(offerLetterPositions));

    const offerLetterLocations = offerLetters
        .map((letter: IOfferLetter) => convertFromBackendToLocation(letter.location))
        .filter((location: ILocation) => Boolean(location));
    yield put(getLocations.success(offerLetterLocations));
    yield put(setClients(offerLettersWithLinked.linked.clients));
    yield put(getMyOfferLetters.success(offerLetters));

    yield spawn(
        withBackendErrorHandler(
            getEmployeeProfilesSaga,
            getEmployeeProfiles.error,
            'Unable to get employee profiles',
        ),
        offerLettersWithLinked.linked.clients,
    );

    const outstandingOfferLetter = chain(offerLetters)
        .filter(offerLetter => offerLetter.status.slug === OfferLetterStatusSlug.Outstanding
            && !offerLetter.viewed_by_employee_at)
        .orderBy([(letter: IOfferLetter) => moment(letter.offer_date).unix()], ['desc'])
        .first()
        .value();
    const { pathname } = browserHistory.location;
    if (
        outstandingOfferLetter
        && !matchPath(
            pathname,
            {
                path: routes.EMPLOYEE_OFFER_LETTER.DETAIL,
                exact: true,
                strict: true,
            },
        )
    ) {
        browserHistory.push(generatePath(routes.EMPLOYEE_OFFER_LETTER.DETAIL, { id: outstandingOfferLetter.id }));
    }
}

function* getOwnOfferLettersWatcher() {
    yield takeLatest([
        authByToken.successType,
        authByPassword.successType,
        getMyOfferLetters.initType,
    ], withBackendErrorHandler(getOwnOfferLettersSaga, getMyOfferLetters.error, 'Unable to get offer letters'));
}

function* checkEmployeeOnboardingStatusSaga({
    payload: {
        profile,
        client,
    },
}: ReturnType<typeof checkEmployeeOnboardingStatus.init>) {
    if (profile.prism_onboarding_completed || !client) {
        return;
    }

    const onboardingStatuses = yield* call(
        offerLetterApi.checkEmployeeOnboardingStatus,
        client.prism_client_id,
        {
            prism_employee_ids: [profile.prism_employee_id],
            options: ['Client'],
        },
    );

    const employeeOnboardingStatus = onboardingStatuses.find(item => item.id === profile.prism_employee_id);
    if (employeeOnboardingStatus && employeeOnboardingStatus?.client?.onboard_in_progress === false) {
        // Update permissions & client list
        yield* put(authTokenUpdate.init());
        // Update offer statuses
        const offerLettersWithLinked = yield call(offerLetterApi.getAllOfferLetters, { self: true });
        yield put(getMyOfferLetters.success(offerLettersWithLinked.offer_letters));
        // Update employee profile
        const updatedProfile = {
            ...profile,
            prism_onboarding_completed: true,
        };
        yield put(updateEmployeeProfile.success(updatedProfile));
        yield put(checkEmployeeOnboardingStatus.success(updatedProfile));
    } else {
        yield put(checkEmployeeOnboardingStatus.success(profile));
    }
}

function* checkEmployeeOnboardingStatusWatcher() {
    yield takeEvery(
        checkEmployeeOnboardingStatus.initType,
        withBackendErrorHandler(
            checkEmployeeOnboardingStatusSaga,
            checkEmployeeOnboardingStatus.error,
            'Unable to check onboarding status',
        ),
    );
}

export function* updateOfferLetterStatusSaga(
    {
        payload: { id, status, rejectionReason },
    }: ReturnType<typeof updateOfferLetterStatus.init>,
) {

    const params: Partial<IUpdateOfferRequest> = {
        status,
        rejection_reason: rejectionReason || null,
    };

    const toastPrefix = 'Offer Letter';
    const actionName = status === OfferLetterStatusNameMap[OfferLetterStatusSlug.Accepted] ? 'Accepted' : 'Rejected';

    try {
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, id, params);
        yield put(updateOfferLetterStatus.success(updatedOfferLetter));
        yield put(setGlobalToast({
            severity: IModalSeverity.Success,
            title: `${toastPrefix} successfully ${actionName}`,
        }));
        browserHistory.push(routes.EMPLOYEE_OFFER_LETTER.ROOT);
    } catch (e) {
        yield put(updateOfferLetterStatus.error(e));
        const errorMessage = `${toastPrefix} was not ${actionName}`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* updateOfferLetterStatusWatcher() {
    yield takeLatestActionByString(updateOfferLetterStatus.initType, updateOfferLetterStatusSaga);
}

export function* updateOfferLetterSaga(
    {
        payload: { id, values },
    }: ReturnType<typeof updateOfferLetter.init>,
) {
    try {
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, id, values);
        yield put(updateOfferLetter.success(updatedOfferLetter));
        yield* put(authTokenUpdate.init());
    } catch (e) {
        yield put(updateOfferLetter.error(e));
        const errorMessage = e?.response?.data?.error?.message ?? `Offer letter update error.`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* updateOfferLetterWatcher() {
    yield takeLatestActionByString(updateOfferLetter.initType, updateOfferLetterSaga);
}

export function* updateEmployeeProfileSaga(
    {
        payload,
    }: ReturnType<typeof updateEmployeeProfile.init>,
) {
    try {
        const employeeProfile = yield* call(offerLetterApi.updateEmployeeProfile, payload.clientId, payload.profile);
        yield put(updateEmployeeProfile.success(employeeProfile));
        yield put(setOnboardingStep(OnboardingSteps.PrismOnboarding));
    } catch (e) {
        yield put(updateEmployeeProfile.error(e));
        let errorMessage = 'Profile update error';
        const serverErrorMessage = e.response?.data?.error?.message;
        errorMessage = `${errorMessage}${ isString(serverErrorMessage) ? `: ${serverErrorMessage}` : '.'}`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* updateEmployeeProfileWatcher() {
    yield takeLatestActionByString(updateEmployeeProfile.initType, updateEmployeeProfileSaga);
}

export function* getMyEmployeeProfileSaga({
    payload: clientId,
}: ReturnType<typeof getMyEmployeeProfile.init>) {
    try {
        const employeeProfile = yield* call(offerLetterApi.getMyEmployeeProfile, clientId);
        yield put(getMyEmployeeProfile.success(employeeProfile));
    } catch (e) {
        logErrorWithCustomMessage(e, `Cannot get own employee profile`);
        yield put(getMyEmployeeProfile.error(e));
    }
}

function* getMyEmployeeProfileWatcher() {
    yield takeLatestActionByString(getMyEmployeeProfile.initType, getMyEmployeeProfileSaga);
}

export function* getPrismSSOLinkSaga({
    payload: clientId,
}: ReturnType<typeof getPrismSSOLink.init>) {
    try {
        const link = yield* call(offerLetterApi.getPrismSSOLink, clientId);
        // Temporary solution with domain replace
        const customDomainLink = optimizely.isFeatureEnabled(FeatureSwitches.enablePrismSSODomainReplace)
            ? link.replace('https://hes-ep.prismhr.com/', 'https://prism-employee.headwaywfs.com/') : link;
        yield put(getPrismSSOLink.success(customDomainLink));
    } catch (e) {
        yield put(getPrismSSOLink.error(e));
        const errorMessage = 'Unable to open onboarding page';
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* getPrismSSOLinkWatcher() {
    yield takeLatestActionByString(getPrismSSOLink.initType, getPrismSSOLinkSaga);
}

function* checkOfferLetterPdfSaga({ payload: {
    id,
    hasGlobalToaster = false,
} }: ReturnType<typeof checkOfferLetterPdf.init>) {
    try {
        yield* call(offerLetterApi.checkOfferLetterPdf, id);
        yield* put(checkOfferLetterPdf.success({ id }));
    } catch (e) {
        if (hasGlobalToaster) {
            yield* put(setGlobalToast({
                severity: IModalSeverity.Error,
                title: 'Document generation is in progress. Please try again later.',
                autoHideDuration: autoHideDefaultDuration * 2,
            }));
        }
        yield* put(checkOfferLetterPdf.error({ id, error: e }));
    }
}

function* checkOfferLetterPdfWatcher() {
    yield takeEvery(checkOfferLetterPdf.initType, checkOfferLetterPdfSaga);
}

export default [
    createOfferWatcher,
    editOfferWatcher,
    rescindOfferWatcher,
    listOfferRouteWatcher,
    getOfferLetterWatcher,
    getOfferLettersWatcher,
    getOwnOfferLettersWatcher,
    updateOfferLetterStatusWatcher,
    updateOfferLetterWatcher,
    updateEmployeeProfileWatcher,
    getMyEmployeeProfileWatcher,
    getPrismSSOLinkWatcher,
    viewOfferLetterWatcher,
    checkOfferLetterPdfWatcher,
    checkEmployeeOnboardingStatusWatcher,
    ...lookupSagas,
    ...offerLetterTemplateSagas,
];

/* eslint-disable react/display-name */
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import React from 'react';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import PlainText from 'shared/components/table/Cells/PlainText';
import SheetStatus from 'shared/components/table/Cells/SheetStatus';
import QuantityInfo from 'shared/components/table/tableApprovalCells/QuantityMobile/QuantityInfo';
import Total from 'shared/components/table/tableApprovalCells/Total/Total';
import { EntryType, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { sheetsTotalDollars } from 'shared/utils/counters/dollarCounter';
import { areaView } from 'shared/utils/formatters/areaFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { rejectedReasonFormatter } from 'shared/utils/formatters/rejectedReasonFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { Permission } from 'store/components/auth/authModels';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { sheetsTotalTimeInMinutes } from 'shared/utils/counters/timeCounter';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';

export const getDetailsCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    entryType: EntryType,
    onDetailsClick?: (value: ISheetClickInfo) => void,
    isMobile?: boolean,
) => ({
    key: 'details',
    title: '',
    width: !isMobile ? '152px' : '48px',
    renderTitle: function DetailsHeaderCell({ rows }: { rows: Array<ISheetRow> }) {
        return (
            <Total total={
                entryType === EntryType.TIME
                    ? formatMinutes(sheetsTotalTimeInMinutes(rows.map(row => row.sheet)))
                    : formatDollars(sheetsTotalDollars(rows.map(row => row.sheet)))
            }
            label={entryType === EntryType.TIME ? 'hrs' : ''}
            />
        );
    },
    render: function DetailsCell({ className, sheet }: ISheetRow) {
        return (
            <div className={clsx(className, classes.iconCell, classes.detailsIcon)}>
                <IconButton onClick={onDetailsClick
                    ? () => onDetailsClick({ sheetId: sheet.id, entryType, permission: Permission.ApproveSheets })
                    : undefined}
                data-testid="details-button"
                >
                    <ArrowForwardIosIcon/>
                </IconButton>
            </div>
        );
    },
});

export const payPeriodCell = (title: string) => ({
    title,
    key: 'pay period',
    width: '3fr',
    render: function RenderCell({ sheet, className }: ISheetRow) {
        return (
            <PlainText className={className} value={getPayPeriodByStartEnd(sheet.period_start, sheet.period_end)}/>
        );
    },
});

export const areaCell = (title: string) => ({
    title,
    key: 'area',
    width: 'minmax(80px, 1fr)',
    render: function AreaCell({ area, className }: ISheetRow) {
        return (
            <PlainText className={className} value={areaView(area)}/>
        );
    },
});

export const hoursAmountCellKey = 'hours/amount';

export const getHoursAmountCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    entryType: EntryType,
    isMobile: boolean,
) => {
    function HoursAmountCell({ sheet, className }: ISheetRow) {
        let value = '';
        if (entryType === EntryType.TIME) {
            if (sheet?.total_files) {
                value = formatFiles(sheet?.total_files);
            } else {
                value = formatMinutes(sheet.total_minutes);
            }
        } else {
            value = formatDollars(sheet.total_dollars);
        }
        return (
            <PlainText className={clsx(className, classes.amountCell)} value={value}/>
        );
    }

    function HoursAmountMobileCell({ sheet, className }: ISheetRow) {
        return (
            <QuantityInfo quantity={entryType === EntryType.TIME
                ? formatMinutes(sheet.total_minutes)
                : formatDollars(sheet.total_dollars)
            }
            className={className}
            type={entryType === EntryType.TIME ? 'hours' : ''}
            />
        );
    }

    return (title: string) => ({
        key: 'hours/amount',
        width: isMobile ? 'minmax(90px, 1fr)' : '96px',
        title: title,
        headerClassName: classes.amountCell,
        render: isMobile ? HoursAmountMobileCell : HoursAmountCell,
    });
};

export const getStatusCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    statusCellFeatureFlag: boolean,
    showStatus: boolean,
) => statusCellFeatureFlag && showStatus ? [
    {
        key: 'status',
        title: 'status',
        width: '150px',
        headerClassName: classes.displayCenterCell,
        render: ({ className, sheet }: ISheetRow) => (
            <SheetStatus sheet={sheet} className={className}/>
        ),
    },
] : [];

export const getApprovedLevelCell = (
    classes: ReturnType<typeof useSubmittedTableStyles>,
    atLeastOneSheetRowNeedsHighManagerApproval: boolean,
    activeStatusTab: StatusNames,
) => {

    const allowedTabs = [
        StatusNames.SUBMITTED,
        StatusNames.ALL,
        StatusNames.REJECTED,
    ];

    return atLeastOneSheetRowNeedsHighManagerApproval && allowedTabs.includes(activeStatusTab) ? [
        {
            key: 'approved_level',
            title: 'Approved',
            render: ({ className, sheet }: ISheetRow) => (
                <PlainText className={ className } value={ sheet.approved_level.toLocaleString() }/>
            ),
        },
    ] : [];
};

export const getReasonRejectionCell = (
    showReason: boolean,
) => showReason ? [
    {
        key: 'Reason',
        title: 'Reason',
        width: '3fr',
        render: ({ sheet, className }: ISheetRow) => {
            return (
                <PlainText className={className} value={sheet.notes && sheet.notes.length
                    ? rejectedReasonFormatter(sheet.notes)[0].notes
                    : ''
                }/>
            );
        },
    },
] : [];

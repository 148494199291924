import { IStore } from 'store/configureStore';
import { selectCurrentClient } from 'store/entities/clients/clientsSelectors';

export const selectClientStatuses = (state: IStore) => {
    return state.modules.settings.clientsSettings.clientStatuses;
};
export const selectClientActiveStatus = (state: IStore) => {
    return selectClientStatuses(state).find(status => status.name === 'Active');
};
export const selectIsNoGeocodeWarningPopupShown = (state: IStore) => {
    return state.modules.settings.clientsSettings.clients.isNoGeocodeWarningShown;
};

export const selectCurrentClientHasNoGeocode = (state: IStore) => {
    const client = selectCurrentClient(state);
    return client && !Number(client?.main_location?.geo_code);
};

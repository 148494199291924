import clsx from 'clsx';
import React from 'react';
import {
    Box,
    Hidden,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
    useOfferLetterStyles,
} from 'modules/offerLetter/OfferLetterStyles';
import { NavLink } from 'react-router-dom';
import { routes } from 'shared/routes';

export interface IOnboardingHeaderProps {
    title: string;
    icon?: React.ReactNode;
    className?: string;
}

export const OnboardingHeader = ({ title, icon, className }: IOnboardingHeaderProps) => {
    const classes = useOfferLetterStyles();
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className={clsx(classes.headerWrapper, className)}
        >
            <Box display="flex" alignItems="center">
                <Hidden xsDown>
                    {icon}
                </Hidden>
                <Typography className={classes.headerText}>
                    {title}
                </Typography>
            </Box>
            <NavLink className={classes.link} to={routes.EMPLOYEE_OFFER_LETTER.ROOT}>
                <Close/>
            </NavLink>
        </Box>
    );
};

import { createActions, createSingleAction } from 'store/utils';
import { ICreateDepartmentRequest, IDepartmentFilter } from 'modules/settings/submodules/clients/departments/store/models';

export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_ERROR = 'CREATE_DEPARTMENT_ERROR';

export const createDepartment = createActions<ICreateDepartmentRequest, any, any,
    typeof CREATE_DEPARTMENT,
    typeof CREATE_DEPARTMENT_SUCCESS, typeof CREATE_DEPARTMENT_ERROR
>(
    CREATE_DEPARTMENT, CREATE_DEPARTMENT_SUCCESS, CREATE_DEPARTMENT_ERROR,
);

export const SET_CREATE_DEPARTMENT_MODAL_STATE = 'SET_CREATE_DEPARTMENT_MODAL_STATE';
export const setCreateDepartmentModalState = createSingleAction<boolean, typeof SET_CREATE_DEPARTMENT_MODAL_STATE>(
    SET_CREATE_DEPARTMENT_MODAL_STATE,
);

export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';
export const UPDATE_DEPARTMENT_ERROR = 'UPDATE_DEPARTMENT_ERROR';

export const updateDepartment = createActions<{
    id: string;
    data: ICreateDepartmentRequest;
}, any, any,
    typeof UPDATE_DEPARTMENT,
    typeof UPDATE_DEPARTMENT_SUCCESS,
    typeof UPDATE_DEPARTMENT_ERROR
>(
    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_ERROR,
);

export const SET_EDIT_DEPARTMENT_ID = 'SET_EDIT_DEPARTMENT_ID';
export const setEditDepartmentId = createSingleAction<string | null, typeof SET_EDIT_DEPARTMENT_ID>(
    SET_EDIT_DEPARTMENT_ID,
);

export const SET_DEPARTMENT_FILTER = 'settings/employees/SET_DEPARTMENT_FILTER';
export const setDepartmentFilter = createSingleAction<IDepartmentFilter, typeof SET_DEPARTMENT_FILTER>(
    SET_DEPARTMENT_FILTER,
);

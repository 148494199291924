/* eslint-disable react/display-name */
import { Delete, Edit } from '@material-ui/icons';
import clsx from 'clsx';
import { entryCellDictionary } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/EntryCellDictionary';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { EntriesGridCellsTitles, IEntryRow } from 'shared/components/table/EntriesTable/model';
import { getEntryIdByRowId, getSheetTypeDependentTitles } from 'shared/components/table/EntriesTable/utils';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import ActionIcon from 'shared/components/table/tableEntryCells/ActionIcon/ActionIcon';
import EntryDetails from 'shared/components/table/tableEntryCells/EntryDetails';
import { EntryType } from 'shared/models/sheet/Sheet';
import { colors } from 'shared/styles/constants';
import {
    AvailableTableConfiguration,
    EntryColumnSlug,
    IColumnConfiguration,
} from 'store/entities/clients/clientsModel';
import { selectTableConfiguration } from 'store/entities/clients/clientsSelectors';
import { getColumnsByConfiguration } from 'store/utils/tables';

export function useEmployeeEntriesCells(
    onEdit: (entryId: string) => void,
    onQuickRemove: (entryId: string) => void,
    isMobile: boolean,
    entryFilter?: EntryType,
): Array<ICellInfo<IEntryRow>> {
    const classes = useEntriesTableStyles();
    const { hoursAmountTitle } = getSheetTypeDependentTitles(entryFilter);

    const option = entryFilter
        ? entryFilter === EntryType.TIME ? AvailableTableConfiguration.Time : AvailableTableConfiguration.Expense
        : AvailableTableConfiguration.TimeExpense;

    const configuration = useSelector(selectTableConfiguration(option)) as IColumnConfiguration<EntryColumnSlug>[];

    const handleDelete = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        if (rowId) {
            onQuickRemove(getEntryIdByRowId(rowId));
        }
    }, [onQuickRemove]);

    const handleEdit = useCallback((event: MouseEvent) => {
        const rowId = event.currentTarget.closest('[role="row"]')?.id;
        if (rowId) {
            onEdit(getEntryIdByRowId(rowId));
        }
    }, [onEdit]);

    return useMemo(() => {
        const actionsColumn: ICellInfo<IEntryRow> = {
            key: EntriesGridCellsTitles.Actions,
            width: '80px',
            render: ({ className }: IEntryRow) => (
                <div className={clsx(className, classes.iconCell, classes.firstActionIcon)}>
                    <ActionIcon
                        onClick={handleDelete}
                        htmlColor={colors.secondary}
                        Icon={Delete}
                    />
                    <ActionIcon
                        onClick={handleEdit}
                        Icon={Edit}
                    />
                </div>
            ),
        };

        let cells;

        if (isMobile) {
            cells = [
                {
                    key: 'entryInfo',
                    title: 'Activity',
                    render: (row: IEntryRow) => (
                        <EntryDetails showIcon={true} configuration={configuration}
                            iconClass={classes.textIcon}
                            {...row}
                        />
                    ),
                },
                entryCellDictionary[EntryColumnSlug.Amount](hoursAmountTitle, classes),
                actionsColumn,
            ];
        } else {
            cells = getColumnsByConfiguration(configuration, entryCellDictionary, classes);
            cells = cells.concat([
                actionsColumn,
            ]);
        }

        return cells;
    }, [classes, isMobile, handleEdit, handleDelete, hoursAmountTitle, configuration]);
}

import { combineReducers } from 'redux';
import { ActionsReturnTypes } from 'store/utils';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import {
    createDepartment,
    setCreateDepartmentModalState,
    setDepartmentFilter,
    setEditDepartmentId,
    updateDepartment,
} from 'modules/settings/submodules/clients/departments/store/actions';
import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import { IDepartmentFilter } from 'modules/settings/submodules/clients/departments/store/models';

const isOpenCreateModal = isOpenModalReducer(setCreateDepartmentModalState.action);
const isCreating = isLoadingReducer(createDepartment);
const isUpdating = isLoadingReducer(updateDepartment);

function editId(
    state: string | null,
    action: ReturnType<typeof setEditDepartmentId>,
): string | null {
    state = state || null;
    switch (action.type) {
        case setEditDepartmentId.action:
            return action.payload;
        default:
            return state;
    }
}

function filter(
    state: IDepartmentFilter,
    action: ReturnType<typeof setDepartmentFilter> | ActionsReturnTypes<typeof getDepartments>,
): IDepartmentFilter {
    const defaultState = {};
    state = state || defaultState;
    switch (action.type) {
        case getDepartments.successType:
            return defaultState;
        case setDepartmentFilter.action:
            return action.payload;
        default:
            return state;
    }
}

export const departments = combineReducers({
    isOpenCreateModal,
    isCreating,
    isUpdating,
    editId,
    filter,
});

import { maxDollarsExpenseValue } from 'shared/models/validationSchemes/expenseEntry';
import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { OFFER_LETTER_FORM_STEP_INVITE } from './steps/OfferLetterFormStepInvite';
import { OFFER_LETTER_FORM_STEP_EMPLOYEE } from './steps/OfferLetterFormStepEmployee';
import { OFFER_LETTER_FORM_STEP_OFFER } from './steps/OfferLetterFormStepOffer';
import { OFFER_LETTER_FORM_STEP_ASSIGNMENT } from './steps/OfferLetterFormStepAssignment';

const nameShape = {
    firstName: yup.string().trim(),
    lastName: yup.string().trim(),
};

const inviteShape = {
    ...nameShape,
    email: yup.string().required(ValidationMessages.REQUIRED).email('Please enter valid email'),
    phone: yup.string().min(10, 'Please enter valid phone'),
};

const baseOfferShape = {
    position: yup.object().nullable().required(ValidationMessages.REQUIRED),
    locationId: yup.string().required(ValidationMessages.REQUIRED),
    startDate: yup.string().required(ValidationMessages.REQUIRED),
    payRate: yup.number()
        .min(0.01, 'Pay Rate should be more than 0')
        .max(maxDollarsExpenseValue, 'Pay Rate should be less than 10,000')
        .required(ValidationMessages.REQUIRED),
};

const approverShape = {
    approvers: yup.array().required(ValidationMessages.REQUIRED),
};

export const offerLetterValidationSchema = yup.object().shape({
    ...inviteShape,
    ...baseOfferShape,
    ...approverShape,
});

const departmentShape = {
    departmentId: yup.string().nullable().required(ValidationMessages.REQUIRED),
};

export const maxTemplateLength = 1000;

const backgroundChecksShape = (isTemplateRequired = true) => ({
    ...(isTemplateRequired
        ? { backgroundChecksTemplate: yup.string().nullable().required(ValidationMessages.REQUIRED) }
        : {}
    ),
    backgroundChecks: yup.string().nullable()
        .max(maxTemplateLength, `background checks must be at most ${maxTemplateLength} characters`)
        .when(
            ['backgroundChecksTemplate'],
            {
                is: (backgroundChecksTemplate: string | null) => backgroundChecksTemplate,
                then: yup.string().required(ValidationMessages.REQUIRED),
            },
        ),
});

const workingConditionsShape = (isTemplateRequired = true) => ({
    ...(isTemplateRequired
        ? { workingConditionsTemplate: yup.string().nullable().required(ValidationMessages.REQUIRED) }
        : {}
    ),
    workingConditions: yup.string().nullable()
        .max(maxTemplateLength, `working conditions must be at most ${maxTemplateLength} characters`)
        .when(
            ['workingConditionsTemplate'],
            {
                is: (workingConditionsTemplate: string | null) => workingConditionsTemplate,
                then: yup.string().required(ValidationMessages.REQUIRED),
            },
        ),
});

const physicalDemandsShape = (isTemplateRequired = true) => ({
    ...(isTemplateRequired
        ? { physicalDemandsTemplate: yup.string().nullable().required(ValidationMessages.REQUIRED) }
        : {}
    ),
    physicalDemands: yup.string().nullable()
        .max(maxTemplateLength, `physical demands must be at most ${maxTemplateLength} characters`)
        .when(
            ['physicalDemandsTemplate'],
            {
                is: (physicalDemandsTemplate: string | null) => physicalDemandsTemplate,
                then: yup.string().required(ValidationMessages.REQUIRED),
            },
        ),
});

const extendedOfferShape = {
    ...baseOfferShape,
    employeeType: yup.string().nullable().required(ValidationMessages.REQUIRED),
    hours: yup.string().nullable().max(10)
        .required(ValidationMessages.REQUIRED),
    template: yup.string().nullable().required(ValidationMessages.REQUIRED),
};

interface IOfferLetterSteppedValidationParams {
    department?: boolean;
    templatePartsRequired?: boolean;
}

export const offerLetterSteppedValidationSchema = (
    stepField: string,
    {
        department,
        templatePartsRequired,
    }: IOfferLetterSteppedValidationParams,
) => {
    return yup.lazy((values: any) => {
        switch (values[stepField]) {
            case OFFER_LETTER_FORM_STEP_INVITE:
                return yup.object().shape(inviteShape);
            case OFFER_LETTER_FORM_STEP_EMPLOYEE:
                return yup.object().shape({
                    ...nameShape,
                    ...extendedOfferShape,
                    ...(department ? departmentShape : {}),
                });
            case OFFER_LETTER_FORM_STEP_OFFER:
                return yup.object().shape({
                    ...extendedOfferShape,
                    ...(department ? departmentShape : {}),
                });
            case OFFER_LETTER_FORM_STEP_ASSIGNMENT:
                return yup.object().shape({
                    ...backgroundChecksShape(templatePartsRequired),
                    ...workingConditionsShape(templatePartsRequired),
                    ...physicalDemandsShape(templatePartsRequired),
                    ...approverShape,
                });
            default:
                return yup.object().shape({});
        }
    });
};

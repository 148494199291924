import { IClientWorkLocationFormValues } from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/model';
import React from 'react';
import clsx from 'clsx';
import { FormikProps } from 'formik';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import OverrideWorkGeoCodeSelect
    from 'modules/settings/submodules/clients/workLocation/components/selects/OverrideWorkGeoCodeSelect';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import AddressAutocompleteField
    from 'shared/components/formSpecialFields/addressAutocompleteField/AddressAutocompleteField';
import { IAddressComponents } from 'shared/components/formSpecialFields/addressAutocompleteField/models';
import { FormMode } from 'shared/models/FormMode';

export interface IEditFormProps extends FormikProps<IClientWorkLocationFormValues> {
    mode: FormMode;
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
}

export function WorkLocationForm({
    handleSubmit,
    setFieldValue,
    onCancel,
    submitTitle,
    mode,
    isLoading = false,
    isDisabled = false,
}: IEditFormProps) {
    const classes = useSettingsFormStyles();
    const onAddressSelect = (address: IAddressComponents) => {
        setFieldValue('county', address.county);
        setFieldValue('state_code', address.stateCode);
        setFieldValue('city', address.city);
        setFieldValue('zip_code', address.zip_code);
        setFieldValue('address', `${address.street_number} ${address.street}`);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={clsx(classes.formBlock)}>
                <Grid container>
                    {mode === FormMode.Edit && (
                        <>
                            <Grid
                                item
                                sm={7}
                                xs={12}
                            >
                                <FormField
                                    name="location_id"
                                    label="Work Location ID"
                                    className={classes.input}
                                    disabled
                                />
                            </Grid>
                            <Grid
                                item
                                sm={5}
                                xs={12}
                            >
                                <FormField
                                    name="client_name"
                                    label="Client ID"
                                    className={classes.input}
                                    disabled
                                />
                            </Grid>
                        </>
                    )}
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        <FormField
                            name="name"
                            label="Work Location Name"
                            className={classes.input}
                        />
                    </Grid>
                    {mode === FormMode.New && (
                        <Grid
                            item
                            sm={5}
                            xs={12}
                        >
                            <FormField
                                name="client_name"
                                label="Client ID"
                                className={classes.input}
                                disabled
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box className={clsx(classes.formBlock)}>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>Work Location Address</Typography>
                <AddressAutocompleteField
                    name="address"
                    label="Address"
                    className={classes.input}
                    onAddressSelect={onAddressSelect}
                />

                <FormField
                    name="address2"
                    label="Address 2"
                    className={classes.input}
                />

                <Grid container>
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        <FormField
                            name="city"
                            label="City"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                    >
                        <FormField
                            name="state_code"
                            label="State"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        <FormField
                            name="county"
                            label="Сounty"
                            className={classes.input}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                    >
                        <FormField
                            name="zip_code"
                            label="Zip code"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        sm={7}
                        xs={12}
                    >
                        {mode === FormMode.Edit && (
                            <FormField
                                name="geo_code"
                                label="GeoCode"
                                className={classes.input}
                                disabled
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        sm={5}
                        xs={12}
                    >
                        <OverrideWorkGeoCodeSelect
                            name="override_work_geo_code"
                            label="Override Work GeoCode"
                            className={classes.input}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box m={formBasicSpacing} mt={5}>
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    data-testid="submit_button"
                >
                    {submitTitle}
                </ButtonWithLoader>
                {onCancel && (
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={onCancel}
                        disabled={isLoading}
                    >
                    Cancel
                    </Button>
                )}
            </Box>
        </form>
    );
}

import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';
import { selectAllClientsSortedAlphabetically } from 'store/entities/clients/clientsSelectors';

interface IClientSelectProps extends IFormFieldProps, IFormSelect {}

export default function ClientSelect(props: IClientSelectProps) {
    const clients = useSelector(selectAllClientsSortedAlphabetically);
    return (
        <FormSelect
            getKey={client => client.id}
            getText={client => client.name}
            options={clients}
            {...props}
        />
    );
}

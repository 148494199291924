import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const WorkLocationValidationsSchema = yup.object().shape({
    name: yup.string().trim().required(ValidationMessages.REQUIRED),
    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    address2: yup.string().trim().matches(/^((?!#).)*$/, 'Please remove special characters'),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    state_code: yup.string().trim().required(ValidationMessages.REQUIRED),
    county: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),
    override_work_geo_code: yup.string().nullable().required(ValidationMessages.REQUIRED),
});

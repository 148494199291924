import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { IModalProps } from 'shared/components/modals/Modal';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import SubmittingOrgFormWrapper
    from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/SubmittingOrgFormWrapper';
import { ISubmittingOrgFormValues } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgForm/model';
import { ICreateSubmittingOrgPayload } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/models';
import { createSubmittingOrg } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from 'shared/models/Status';
import { selectIsSubmittingOrgCreating } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/store/selectors';

interface INewClientModal extends Omit<IModalProps, 'title' | 'children'> {
    title: string;
    isOpened: boolean;
    onClose: () => void;
}

export function CreateSubmittingOrgModal({
    title, isOpened, onClose, ...modalProps
}: INewClientModal) {
    const dispatch = useDispatch();
    const onSave = useCallback((values: ISubmittingOrgFormValues) => {
        const { zip_code, county, status, ...valuesCleared } = values;
        const payload = {
            ...valuesCleared,
            active: status !== Status.inactive,
            zip: zip_code,
        } as ICreateSubmittingOrgPayload;
        dispatch(createSubmittingOrg.init(payload));
    }, [dispatch]);

    const isLoading = useSelector(selectIsSubmittingOrgCreating);

    return (
        <ModalDialog
            title={title}
            isOpened={isOpened}
            onClose={onClose}
            modalProps={modalProps}
        >
            <Box>
                <SubmittingOrgFormWrapper
                    onSubmit={onSave}
                    onCancel={onClose}
                    submitTitle="Create"
                    isLoading={isLoading}
                />
            </Box>
        </ModalDialog>
    );
}

import { Box } from '@material-ui/core';
import React from 'react';
import { useField } from 'formik';
import { QuantityType } from 'shared/models/sheet/Sheet';
import { maxDollarsExpenseValue } from 'shared/models/validationSchemes/expenseEntry';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';

export interface IFormMoneyInputProps extends IFormFieldProps {
    showNote?: boolean;
}

const MoneyDataField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    showNote = false,
}: IFormMoneyInputProps) => {
    const [field, meta, helper] = useField(name);

    const handleChange = (value: string) => {
        helper.setValue({
            entry_type: QuantityType.MONEY,
            dollars: value,
        });
    };
    return (
        <>
            <NumberInput
                id={id}
                name={name}
                label={label}
                initialValue={field.value?.dollars}
                className={className}
                inputProps={{
                    decimalScale: 2,
                    thousandSeparator: true,
                    allowNegative: false,
                    fixedDecimalScale: true,
                    isNumericString: true,
                }}
                disabled={disabled}
                error={meta.error}
                touched={meta.touched}
                setTouched={helper.setTouched}
                onChange={handleChange}
                min={0}
                max={maxDollarsExpenseValue}
            />
            {showNote && (
                <Box
                    pt={2}
                    ml={2}
                    display="inline-flex"
                    className={className}
                >
                    *if the amount is over $25, please attach a receipt.
                </Box>
            )}
        </>
    );
};

export default MoneyDataField;

import { IDepartment } from 'modules/employmentInfo/models/Department';
import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber, IJobNumberBackend } from 'shared/models/JobNumber';
import { convertFromBackendToLocation, ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { IUserInfo } from 'shared/models/User';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { IArea, IAssignment, IManagerInfoWithUser, IProject, IProjectWithAssignmentBackend } from 'store/entities/configuration/configurationModel';
import { loadExpenseSheets, loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { getSheetsPayPeriod } from 'store/entities/timesheet/actions/sheets';
import { loadTimeSheets, loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { getUsers } from 'store/entities/users/actions';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all,
    call,
    put,
    take,
    takeEvery,
    takeLatest,
} from 'typed-redux-saga';
import {
    getAreas,
    getProjectsAssignments,
    getActivities,
    getTasks,
    getProjects,
    getLocations,
    getPositions,
    getAssignments,
    getWorkingConditions,
    getPhysicalDemands,
    getBackgroundCheckTemplates,
    loadClientAssignmentsWithLinked,
    getControllingOrgs,
    getSubmittingOrgs,
    getCostCenters,
    getSubmittingOrgLocations,
    getJobNumbers,
    getDeals,
    getDealTypes,
    getUserTypesAction,
} from 'store/entities/configuration/configurationAction';
import { SET_TENANT_SUCCESS, setTenantSuccess } from 'store/entities/clients/clientsAction';
import { configurationApi } from 'store/entities/configuration/configurationApi';
import { AppClient } from 'utils/constants';
import { FeatureSwitches } from 'utils/featureSwitches';
import { optimizely } from 'utils/optimizely';
import { compact } from 'lodash';

const getProjectsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getProjects,
    configurationApi.getProjects,
    'projects',
);

function* normalizeProjectsAssignmentsSaga(projectAssignments: IProjectWithAssignmentBackend[]) {
    const assignments: IAssignment[] = [];
    const areas: IArea[] = [];
    const projects: IProject[] = [];

    projectAssignments.forEach(projectAssignment => {
        const { project, area, assignment } = projectAssignment;
        if (assignment) {
            assignments.push(assignment);
        }
        if (area) {
            areas.push(area);
        }
        if (project) {
            projects.push(project);
        }
    });

    yield put(getAssignments.success(assignments));
    yield put(getAreas.success(areas));
    yield put(getProjects.success(projects));
    yield put(getProjectsAssignments.success(projectAssignments));
}

function* getProjectsAssignmentsSaga({ payload }: ReturnType<typeof getProjectsAssignments.init>) {
    const projectAssignments = yield* call(configurationApi.getProjectsWithAssignments, payload || {});
    yield* normalizeProjectsAssignmentsSaga(projectAssignments);
}

export function* getProjectsAssignmentsWatcher() {
    yield takeEvery(
        getProjectsAssignments.initType,
        withBackendErrorHandler(
            getProjectsAssignmentsSaga,
            getProjectsAssignments.error,
            `Unable to fetch project assignments`,
        ),
    );
}

const getAreasWatcher = getLoadEntitiesByRequestSagaWatcher(
    getAreas,
    configurationApi.getAreas,
    'areas',
);

const getActivitiesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getActivities,
    configurationApi.getActivities,
    'activities',
);

const getTasksWatcher = getLoadEntitiesByRequestSagaWatcher(
    getTasks,
    configurationApi.getTasks,
    'tasks',
);

const getPositionsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getPositions,
    configurationApi.getPositions,
    'positions',
);

const getAssignmentsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getAssignments,
    configurationApi.getAssignments,
    'assignments',
);

const getControllingOrgsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getControllingOrgs,
    configurationApi.getControllingOrgs,
    'controlling orgs',
);

const getCostCentersWatcher = getLoadEntitiesByRequestSagaWatcher(
    getCostCenters,
    configurationApi.getCostCenters,
    'cost centers',
);

const getSubmittingOrgsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSubmittingOrgs,
    configurationApi.getSubmittingOrgs,
    'submitting orgs',
);

const getSubmittingOrgLocationsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getSubmittingOrgLocations,
    configurationApi.getSubmittingOrgLocations,
    'submitting org locations',
);

const getLocationsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getLocations,
    configurationApi.getLocations,
    'locations',
);

const getWorkingConditionsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getWorkingConditions,
    configurationApi.getWorkingConditions,
    'working conditions',
);

const getPhysicalDemandsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getPhysicalDemands,
    configurationApi.getPhysicalDemands,
    'physical demands',
);

const getBackgroundCheckTemplatesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getBackgroundCheckTemplates,
    configurationApi.getBackgroundCheckTemplates,
    'background check templates',
);

export function* normalizeJobNumbersSaga(jobNumbersBackend: IJobNumberBackend[]) {
    const deals: IDeal[] = [];
    const jobNumbers: IJobNumber[] = [];
    jobNumbersBackend.forEach(jobNumberFull => {
        const { deal, ...jobNumber } = jobNumberFull;
        deals.push(deal);
        jobNumbers.push(jobNumber);
    });
    yield put(getJobNumbers.success(jobNumbers));
    yield put(getDeals.success(deals));
}

function* getJobNumbersSaga({ payload }: ReturnType<typeof getJobNumbers.init>) {
    const jobNumbers = yield* call(configurationApi.getJobNumbers, payload || {});
    yield* normalizeJobNumbersSaga(jobNumbers);
}

export function* getJobNumbersWatcher() {
    yield takeEvery(
        getJobNumbers.initType,
        withBackendErrorHandler(
            getJobNumbersSaga,
            getJobNumbers.error,
            `Unable to fetch job numbers`,
        ),
    );
}

const getJobNumberUserTypesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getUserTypesAction,
    configurationApi.getUserTypes,
    'user types',
);

const getDealsWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDeals,
    configurationApi.getDeals,
    'deals',
);

const getDealTypesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getDealTypes,
    configurationApi.getDealTypes,
    'deal types',
);

function* loadClientAssignmentsWithLinkedSaga({ payload }: ReturnType<typeof loadClientAssignmentsWithLinked.init>) {
    const assignmentsWithLinked = yield* call(configurationApi.getAssignmentsWithLinked, payload);
    const assignments: IAssignment[] = [];
    const locations: ILocation[] = [];
    const positions: IPosition[] = [];
    const users: IUserInfo[] = [];
    const departments: IDepartment[] = [];
    const areas: IArea[] = [];
    assignmentsWithLinked.forEach(assignmentWithLinked => {
        const {
            location,
            position,
            user,
            department,
            area,
            managers,
            ...assignment
        } = assignmentWithLinked;
        assignments.push({
            location_id: location?.id,
            position_id: position?.id,
            user_id: user?.id,
            department_id: department?.id,
            managers: managers.map(manager => ({
                user_id: manager.user_id,
                manager_level: manager.manager_level,
            })),
            ...assignment,
        });
        locations.push(convertFromBackendToLocation(location));
        positions.push(position);
        users.push(user);
        users.push(...(managers.map((item: IManagerInfoWithUser) => item.user)));
        departments.push(department);
        areas.push(area);
    });
    yield put(getAssignments.success(assignments));
    yield put(getLocations.success(compact(locations)));
    yield put(getPositions.success(compact(positions)));
    yield put(getUsers.success(compact(users)));
    yield put(getDepartments.success(compact(departments)));
    yield put(getAreas.success(compact(areas)));

    if (process.env.REACT_APP_CLIENT === AppClient.RTI && payload.purpose) {
        yield put(getProjectsAssignments.init({ purpose: payload.purpose }));
    }
}

function* loadClientAssignmentsWithLinkedWatcher() {
    yield takeLatest(
        loadClientAssignmentsWithLinked.initType,
        withBackendErrorHandler(
            loadClientAssignmentsWithLinkedSaga,
            loadClientAssignmentsWithLinked.error,
            'Unable to load client assignments',
        ),
    );
}

function* loadSheetsRelatedEntitiesSaga({
    payload: sheets,
}: ReturnType<typeof loadTimeSheets.success> | ReturnType<typeof loadExpenseSheets.success>) {
    const clientId = sheets?.[0].client_id;
    const jobNumberIds: string[] = [...sheets.reduce((idsSet, sheet) => {
        if (sheet.job_number_id) {
            idsSet.add(sheet.job_number_id);
        }
        return idsSet;
    }, new Set<string>())];
    if (isNotEmpty(jobNumberIds)) {
        yield put(getSubmittingOrgs.init({ client_id: clientId }));
        yield put(getJobNumbers.init({
            ids: jobNumberIds,
            client_id: clientId,
        }));
        const getJobNumbersAction = yield* take(
            [getJobNumbers.successType, getJobNumbers.errorType],
        );

        if (getJobNumbersAction.type !== getJobNumbers.successType) {
            return;
        }
        const jobNumbers = (getJobNumbersAction as ReturnType<typeof getJobNumbers.success>).payload;
        const userIds = [...jobNumbers.reduce((userIdsSet, {
            manager_id,
            user_id,
        }) => {
            if (manager_id) {
                userIdsSet.add(manager_id);
            }
            if (user_id) {
                userIdsSet.add(user_id);
            }
            return userIdsSet;
        }, new Set<string>())];
        yield put(getUsers.init({ ids: userIds.join(',') }));
    }
}

function* loadSheetsRelatedEntitiesSagaWatcher() {
    yield takeEvery(
        [
            loadTimeSheets.successType,
            loadTimeSheetsWithEntries.successType,
            loadExpenseSheets.successType,
            loadExpenseSheetsWithEntries.successType,
        ],
        loadSheetsRelatedEntitiesSaga,
    );
}

function* getConfiguration() {
    while (true) {
        const { payload: clientId }: ReturnType<typeof setTenantSuccess> = yield* take(SET_TENANT_SUCCESS);

        if (clientId) {
            yield all([
                put(getProjects.init()),
                put(getTasks.init()),
                put(getPositions.init()),
                put(getLocations.init()),
                put(getSheetsPayPeriod.init()),
            ]);

            if (optimizely.isFeatureEnabled(FeatureSwitches.enableDepartmentInOfferLetter)
                || optimizely.isFeatureEnabled(FeatureSwitches.enableClientDepartmentManagement)) {
                yield put(getDepartments.init());
            }
        }
    }
}

export default [
    getProjectsWatcher,
    getProjectsAssignmentsWatcher,
    getAreasWatcher,
    getActivitiesWatcher,
    getTasksWatcher,
    getPositionsWatcher,
    getLocationsWatcher,
    getControllingOrgsWatcher,
    getSubmittingOrgsWatcher,
    getSubmittingOrgLocationsWatcher,
    getConfiguration,
    getAssignmentsWatcher,
    getWorkingConditionsWatcher,
    getPhysicalDemandsWatcher,
    getBackgroundCheckTemplatesWatcher,
    loadClientAssignmentsWithLinkedWatcher,
    getJobNumbersWatcher,
    getJobNumberUserTypesWatcher,
    getCostCentersWatcher,
    getDealsWatcher,
    getDealTypesWatcher,
    loadSheetsRelatedEntitiesSagaWatcher,
];

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { uniq } from 'lodash';
import { changeSheetGroupStatus } from 'modules/payrollProcessorHub/store/actions';
import { getSheetGroupId, useGroupSheetRowsByIds } from 'modules/payrollProcessorHub/store/helpers';
import { Button, TextField, Typography } from '@material-ui/core';
import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import { useDispatch } from 'react-redux';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import useOutlinedTextFieldStyled from 'shared/components/textField/CustomOutlinedTextFieldStyles';
import { inputParams } from 'shared/styles/constants';
import { userFullName } from 'shared/utils/converters/user';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { getTotalHours } from 'shared/utils/helpers/hoursCalculations';
import { getLocations } from 'store/entities/configuration/configurationAction';
import { StatusNames } from 'store/entities/timesheet/models/Status';

export interface IRejectSheetGroupsProps {
    groupIds: ISheetGroupId[];
    onCancel: () => void;
}

export const RejectSheetGroups = ({ groupIds, onCancel }: IRejectSheetGroupsProps) => {
    const dispatch = useDispatch();
    const classes = useSheetRejectStyles();
    const sidebarClasses = useSidebarStyles();
    const inputClasses = useOutlinedTextFieldStyled();
    const groupsWithLinked = useGroupSheetRowsByIds(groupIds);
    const [notesByRowId, setNotesByRowId] = useState<Record<string, string>>({});
    const locationIdsAsString = uniq(
        groupsWithLinked
            .map(group => group.assignment?.location_id)
            .filter(id => Boolean(id))
            .map(id => `"${id}"`),
    ).join();

    useEffect(() => {
        dispatch(getLocations.init({
            filters: `{"id":{"in":[${locationIdsAsString}]}}`,
            client_id: undefined,
        }));
    }, [dispatch, locationIdsAsString]);

    const onReject = () => {
        const groups = groupsWithLinked.map(group => ({
            groupId: getSheetGroupId(group),
            note: notesByRowId[group.id],
        }));
        dispatch(changeSheetGroupStatus.init({
            status: StatusNames.REJECTED,
            groups,
        }));
        onCancel();
    };

    const onChangeNote = (id: string, note: string) => {
        setNotesByRowId({
            ...notesByRowId,
            [id]: note,
        });
    };

    return (
        <>
            <form>
                <section className={sidebarClasses.sidebarElement}>
                    {groupsWithLinked.map(group => (
                        <section
                            key={group.id}
                            className={clsx(sidebarClasses.sidebarElement, classes.rejectionCard)}
                        >
                            <Typography variant="h6">
                                {userFullName(group.employee)}
                            </Typography>
                            <section className={classes.sheetDisplay}>
                                <Typography variant="body1" className={classes.weekendPullLeft}>
                                    {group.position?.name}
                                    {group.location?.name && (
                                        <>
                                            &nbsp;/&nbsp;
                                            {group.location?.name}
                                        </>
                                    )}
                                    <br/>
                                    {getPayPeriodByStartEnd(group.pay_period_starts_at, group.pay_period_ends_at)}
                                </Typography>
                                <Typography variant="subtitle1" className={classes.hoursPullRight}>
                                    {getTotalHours([
                                        group.time_ot_hours,
                                        group.time_rt_hours,
                                        group.time_dt_hours,
                                    ])} hrs.
                                    <br/>
                                    ${group.total_dollars}
                                </Typography>
                            </section>
                            <section className={classes.notes}>
                                <TextField
                                    inputProps={{
                                        className: inputClasses.input,
                                        ...inputParams,
                                    }}
                                    InputLabelProps={{ className: inputClasses.label }}
                                    className={classes.notesInput}
                                    label="Reason for rejection"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={notesByRowId[group.id]}
                                    onChange={({ target }) => onChangeNote(group.id, target.value)}
                                />
                            </section>
                        </section>
                    ))}
                </section>
            </form>
            <footer className={classes.footer}>
                <Button
                    onClick={onReject}
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.buttonSave, label: classes.buttonTextStyle }}
                >
                    Confirm
                </Button>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    classes={{ root: classes.buttonCancel, label: classes.buttonTextStyle }}
                >
                    Cancel
                </Button>
            </footer>
        </>
    );
};

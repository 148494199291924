import React from 'react';
import clsx from 'clsx';
import { Snackbar } from '@material-ui/core';
import { useToastStyles } from 'shared/components/toasts/ToastStyles';
import Alert from '@material-ui/lab/Alert';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';

export interface IAlertProps {
    title: string;
    isOpened: boolean;
    onClose: () => void;
    severity?: IModalSeverity;
    autoHideDuration?: number | null;
    ignoreSidebar?: boolean;
}

export default function Toast({
    autoHideDuration,
    title,
    isOpened,
    onClose,
    severity,
    ignoreSidebar = false,
}: IAlertProps) {
    const classes = useToastStyles();
    let sidebar = useSelector<IStore, boolean>(state => state.appConfig.isSidebarOpened);
    sidebar = !ignoreSidebar && sidebar;

    const vertical = 'top';
    const horizontal = 'center';

    return (
        <div className={classes.root}>
            <Snackbar
                classes={{ anchorOriginTopCenter: clsx(classes.wrapper, sidebar ? classes.wrapperWithSidebar : '') }}
                anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`}
                open={isOpened}
                onClose={onClose}
                autoHideDuration={autoHideDuration === undefined ? autoHideDefaultDuration : autoHideDuration}
            >
                <Alert
                    className={classes.alert}
                    onClose={onClose}
                    severity={severity ?? 'success'}
                >
                    {title}
                </Alert>
            </Snackbar>
        </div>
    );
}

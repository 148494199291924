import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { PayrollSheetStatuses } from 'modules/payrollProcessorHub/store/model';
import StatusCell from 'shared/components/table/Cells/StatusCell';
import { useStatusStyles } from 'shared/components/table/Cells/StatusStyles';

export interface IPayrollStatusProps {
    status: PayrollSheetStatuses;
    className?: string;
}

export const PayrollStatus = ({ status, className }: IPayrollStatusProps) => {
    const statusClasses = useStatusStyles();
    const statusToColor: Record<string, string> = useMemo(() => ({
        [PayrollSheetStatuses.APPROVED]: statusClasses.lightGreen,
        [PayrollSheetStatuses.SUBMITTED]: statusClasses.blue,
        [PayrollSheetStatuses.MISSING]: statusClasses.orange,
        [PayrollSheetStatuses.OVERDUE]: statusClasses.red,
        [PayrollSheetStatuses.CLOSED]: statusClasses.brown,
    }), [statusClasses]);

    return (
        <StatusCell
            className={className}
            text={capitalize(status)}
            statusClassName={statusToColor[status]}
        />
    );
};

import * as Sentry from '@sentry/react';
import { ILogger } from 'shared/utils/logging/logger';

const errorsToIgnore = [
    'ResizeObserver loop limit exceeded', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
];

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    normalizeDepth: 8,
    ignoreErrors: errorsToIgnore,
});

const trackErrorEvent = (severity: Sentry.Severity) => (error: any, errorInfo?: any) => {
    Sentry.captureException(
        error,
        {
            level: severity,
            extra: errorInfo,
        },
    );
};

export const sentryLogger: ILogger = {
    critical: trackErrorEvent(Sentry.Severity.Critical),
    error: trackErrorEvent(Sentry.Severity.Error),
    warning: trackErrorEvent(Sentry.Severity.Warning),
    info: trackErrorEvent(Sentry.Severity.Info),
};

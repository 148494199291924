import { Box } from '@material-ui/core';
import clsx from 'clsx';
import SubmitSheets from 'shared/components/modals/SubmitSheets/SubmitSheets';
import React from 'react';
import useSheetsInProgressStyles from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsInProgressStyles';
import { EntryType } from 'shared/models/sheet/Sheet';
import { StatusNames } from 'store/entities/timesheet/models/Status';

interface IFooterProps {
    isPayroll?: boolean;
    hasEntries: boolean;
    entryTypes?: Array<EntryType>;
    submitAction?: (entryTypes: Array<EntryType>) => void;
    status?: StatusNames;
}

export default function Footer({ hasEntries, entryTypes, submitAction, status, isPayroll = false }: IFooterProps) {
    const classes = useSheetsInProgressStyles();

    return (
        <Box className={clsx(classes.actionBottomBar, { [classes.hidden]: !hasEntries })}>
            <Box className={classes.modifyActions}>
                <SubmitSheets
                    entryTypes={entryTypes}
                    submitAction={submitAction}
                    status={status}
                    isPayroll={isPayroll}
                />
            </Box>
        </Box>
    );
}

import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { DashboardLink } from 'modules/settings/submodules/components/DashboardLink/DashboardLink';
import { useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import getClientSubModules from './utils/getClientSubModules';
import { EditClientLogoDashboardLink } from 'modules/settings/submodules/clients/components/EditClientLogo/EditClientLogoDashboardLink';
import { Alert } from '@material-ui/lab';
import { noGeoCodeWarningText } from 'modules/settings/submodules/clients/clientDetails/NoGeocodeWarning';
import { selectCurrentClientHasNoGeocode } from 'modules/settings/submodules/clients/store/selectors';

export const ClientSettingsDashboard = () => {
    const clientId = useSelector(selectCurrentClientId);
    const items = getClientSubModules(clientId);
    const currentClientHasNoGeocode = useSelector(selectCurrentClientHasNoGeocode);
    return (
        <Box width="100%">
            {
                currentClientHasNoGeocode && (
                    <Box mb={2}>
                        <Alert severity="warning">
                            { noGeoCodeWarningText }
                        </Alert>
                    </Box>
                )
            }
            <Grid
                container
                spacing={3}
            >
                {items.map(item => (
                    <DashboardLink
                        key={item.title}
                        {...item}
                    />
                ))}
                <EditClientLogoDashboardLink/>
            </Grid>
        </Box>
    );
};

import { useMemo } from 'react';
import { EntryType } from 'shared/models/sheet/Sheet';
import { showField } from 'shared/components/forms/utils';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { IJobNumber } from 'shared/models/JobNumber';
import { IActivity, IProjectWithAssignment } from 'store/entities/configuration/configurationModel';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { useSelector } from 'react-redux';
import {
    selectActivitiesByIdWithoutDeleted,
    selectActivitiesByTaskId,
} from 'store/entities/configuration/configurationSelectors';
import { selectActivitiesByTaskIdByAssignment } from 'store/components/addEntry/addEntrySelectors';
import pick from 'lodash/pick';

export const useJobNumberActivities = (
    entryType: EntryType | undefined,
    jobNumber: IJobNumber | null,
) => {
    return useMemo(() => {
        if (entryType === EntryType.TIME && jobNumber) {
            return jobNumber.time_activities?.map(timeActivity => timeActivity.activity_id);
        }
        return undefined;
    }, [jobNumber, entryType]);
};

export const useFilteredActivities = (
    taskId: string | undefined,
    entryType: EntryType | undefined,
    withAssignment: boolean | undefined,
    jobNumber: IJobNumber | null) => {

    const activitiesById = useSelector(selectActivitiesByIdWithoutDeleted);
    const activitiesByTaskIdSelector = withAssignment ? selectActivitiesByTaskIdByAssignment : selectActivitiesByTaskId;
    const activitiesByTaskId = useSelector(activitiesByTaskIdSelector);
    const jobNumberActivities = useJobNumberActivities(entryType, jobNumber);
    const filteredActivities = useMemo(
        () => {
            const allActivities = (() => {
                let result: IActivity[] = Object.values(activitiesById);
                if (jobNumberActivities){
                    result = Object.values(pick(activitiesById, jobNumberActivities));
                } else if (taskId) {
                    const taskActivities = activitiesByTaskId[taskId] || [];
                    result = taskActivities.reduce<IActivity[]>((activityArray: IActivity[], activityId: string) => {
                        if (activitiesById[activityId]) {
                            activityArray.push(activitiesById[activityId]);
                        }
                        return activityArray;
                    }, []);
                }
                result = result.filter((activity: IActivity) => activity.is_active);
                return result;
            })();
            return allActivities.filter((activity: IActivity) => (!entryType || activity.sheet_type === entryType));
        },
        [entryType, activitiesById, activitiesByTaskId, taskId, jobNumberActivities],
    );
    return filteredActivities;
};

/**
 * Activity field is visible:
 * - if field is activated in configuration
 * - if user has already selected position && location or projectAssignment && taskId or jobNumber
 */
export const isActivityFieldIsVisibleInForm = (
    inputs: InputFields,
    position: IPosition | null,
    location: ILocation | null,
    projectAssignment: IProjectWithAssignment | null,
    taskId: string,
    jobNumber: IJobNumber | null,
) => {
    return showField(inputs, EntrySlug.Activity) && [
        position && location,
        projectAssignment && taskId,
        jobNumber,
    ].find(Boolean);
};

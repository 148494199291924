import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { ActionCreatorKnownArgs, useActions } from 'store/utils';
import React, { useCallback } from 'react';
import { useFiltersChipsStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChipsStyles';
import { IEntity, INamedEntity } from 'shared/models/Entity';
import { getAreaText } from 'shared/components/selects/utils';
import {
    selectActivitiesById, selectAreasById,
    selectAssignmentsById, selectJobNumbersById, selectLocationsById, selectPositionsById,
    selectTasksById,
} from 'store/entities/configuration/configurationSelectors';
import FilterChip from 'shared/components/filters/FilterChip';
import { Box } from '@material-ui/core';
import { FiltersNames, IFiltersAllOptions } from 'shared/models/Filters';
import { selectUsersById } from 'store/entities/users/selectors';
import { userFullName } from 'shared/utils/converters/user';
import { getFormattedPayPeriod } from 'shared/models/Dates';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import { IJobNumber } from 'shared/models/JobNumber';

interface IEntityWithDescription extends IEntity {
    description: string;
}

const getTextBase = (entry: IEntityWithDescription) => entry.description;
const getTextNamed = (entry: INamedEntity) => entry.name;

interface IFilterChipsProps {
    className?: string;
    selector: (state: IStore) => Partial<Record<FiltersNames, string>>;
    action: ActionCreatorKnownArgs<
    Partial<Record<FiltersNames, string>>,
    { type: string; payload: Partial<Record<FiltersNames, string>> }
    >;
}

export default function FiltersChips({ selector, action, className = '' }: IFilterChipsProps) {
    const classes = useFiltersChipsStyles();
    const actions = useActions({
        setFilters: action,
    });

    const onDelete = useCallback((name: keyof IFiltersAllOptions) => {
        actions.setFilters({
            [name]: '',
        });
    }, [actions]);

    const positionsById = useSelector(selectPositionsById);
    const usersById = useSelector(selectUsersById);
    const locationsById = useSelector(selectLocationsById);
    const areasById = useSelector(selectAreasById);
    const assignmentsById = useSelector(selectAssignmentsById);
    const activitiesById = useSelector(selectActivitiesById);
    const jobNumbersById = useSelector(selectJobNumbersById);
    const tasksById = useSelector(selectTasksById);

    const filters = useSelector(selector);

    const hasFilters = isNotEmpty(filters);

    return hasFilters ? (
        <Box className={className} data-testid={FiltersTestIds.ChipsWrapper}>
            {filters.location_id && (
                <FilterChip values={locationsById} name={FiltersNames.Location}
                    id={filters.location_id} onDelete={onDelete}
                    getText={getTextNamed} classes={classes}
                />
            )}

            {filters.position_id && (
                <FilterChip values={positionsById} name={FiltersNames.Position}
                    id={filters.position_id} onDelete={onDelete}
                    getText={getTextNamed} classes={classes}
                />
            )}

            {filters.area_id && (
                <FilterChip values={areasById} name={FiltersNames.Area}
                    id={filters.area_id.toString()} onDelete={onDelete}
                    getText={getAreaText} classes={classes}
                />
            )}

            {filters.assignment_id && (
                <FilterChip values={assignmentsById} name={FiltersNames.Assignment}
                    id={filters.assignment_id} onDelete={onDelete}
                    getText={getTextBase} classes={classes}
                />
            )}

            {filters.task_id && (
                <FilterChip values={tasksById} name={FiltersNames.Task}
                    id={filters.task_id} onDelete={onDelete}
                    getText={getTextBase} classes={classes}
                />
            )}
            {filters.job_number_id && (
                <FilterChip values={jobNumbersById} name={FiltersNames.JobNumber}
                    id={filters.job_number_id} onDelete={onDelete}
                    getText={(entry: IJobNumber) => String(entry.job_number)} classes={classes}
                />
            )}
            {filters.activity_id && (
                <FilterChip values={activitiesById} name={FiltersNames.Activity}
                    id={filters.activity_id} onDelete={onDelete}
                    getText={getTextBase} classes={classes}
                />
            )}

            {filters.user_id && (
                <FilterChip values={usersById} name={FiltersNames.User}
                    id={filters.user_id} onDelete={onDelete}
                    getText={userFullName} classes={classes}
                />
            )}

            {filters.pay_period && (
                <FilterChip
                    values={{ [filters.pay_period]: filters.pay_period }}
                    name={FiltersNames.PayPeriod}
                    id={filters.pay_period}
                    onDelete={onDelete}
                    getText={payPeriod => getFormattedPayPeriod(payPeriod)} classes={classes}
                />
            )}
        </Box>
    ) : null;
}

import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { FormMode } from 'shared/models/FormMode';
import { ILocation } from 'shared/models/Location';
import { selectClientDisplayName, selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { WorkLocationValidationsSchema } from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/ValidationsSchema';
import {
    defaultClientWorkLocationValues,
    IClientWorkLocationFormValues,
} from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/model';
import { WorkLocationForm } from 'modules/settings/submodules/clients/workLocation/components/WorkLocationForm/WorkLocationForm';

export interface IWorkLocationFormWrapperProps {
    onSubmit: (values: IClientWorkLocationFormValues) => void;
    submitTitle: string;
    isLoading: boolean;
    onCancel?: () => void;
    location?: ILocation;
    mode: FormMode;
}

const WorkLocationFormWrapper = ({
    onSubmit,
    onCancel,
    submitTitle,
    mode,
    isLoading,
    location,
}: IWorkLocationFormWrapperProps) => {
    const clientDisplayName = useSelector(selectClientDisplayName);
    const clientId = useSelector(selectCurrentClientId);
    const initialValues = {
        client_id: clientId || defaultClientWorkLocationValues.client_id,
        client_name: clientDisplayName,
        name: location?.name || defaultClientWorkLocationValues.name,
        location_id: location?.id || defaultClientWorkLocationValues.location_id,
        address: location?.address || defaultClientWorkLocationValues.address,
        address2: location?.address2 || defaultClientWorkLocationValues.address2,
        city: location?.city || defaultClientWorkLocationValues.city,
        state_code: location?.state_code || defaultClientWorkLocationValues.state_code,
        county: location?.county || defaultClientWorkLocationValues.county,
        zip_code: location?.zip_code || defaultClientWorkLocationValues.zip_code,
        override_work_geo_code: location?.override_work_geo_code
            || defaultClientWorkLocationValues.override_work_geo_code,
        geo_code: location?.geo_code || defaultClientWorkLocationValues.geo_code,
    };

    return (
        <Formik
            key={clientDisplayName + location?.id}
            initialValues={initialValues}
            validationSchema={WorkLocationValidationsSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <WorkLocationForm
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                    mode={mode}
                />
            )}
        </Formik>
    );
};

export default WorkLocationFormWrapper;

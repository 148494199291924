import { updateNotifications } from 'modules/notificationCenter/store/actions';
import React from 'react';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { NotificationCard } from 'modules/notificationCenter/components/notificationsSidebar/NotificationCard';
import { selectHasNewNotifications, selectNotifications } from 'modules/notificationCenter/store/selectors';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import SidebarCommon from 'shared/components/sidebars/Sidebar/SidebarCommon';
import { useStyles } from './styles';

export interface INotificationSidebarProps {
    isOpen: boolean;
    onClose: () => void;
}

export const NotificationSidebar = ({ isOpen, onClose }: INotificationSidebarProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const notifications = sortBy(
        useSelector(selectNotifications),
        notification => -moment(notification.created_at).unix(),
    );
    const hasUnreadNotifications = useSelector(selectHasNewNotifications);

    const readAll = () => {
        const notificationIds = notifications.filter(notification => !notification.viewed)
            .map(notification => notification.id);
        dispatch(updateNotifications.init(
            notificationIds.map(id => ({
                id,
                viewed: true,
            })),
        ));
    };

    return (
        <SidebarCommon
            title="Notifications"
            onClose={onClose}
            anchor="right"
            open={isOpen}
            data-testid={FiltersTestIds.Wrapper}
            titleClasses={{
                root: classes.sidebarTitle,
            }}
        >
            {hasUnreadNotifications && (
                <Button
                    variant="text"
                    className={classes.readAllButton}
                    onClick={readAll}
                >
                    Mark all as read
                </Button>
            )}
            {notifications.map(notification => (
                <NotificationCard key={notification.id} notification={notification}/>
            ))}
            {notifications.length === 0 && (
                <Box m={2}>No new notifications</Box>
            )}
        </SidebarCommon>
    );
};

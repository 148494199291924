import React, { ComponentType } from 'react';
import intersection from 'lodash/intersection';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotPermitted from 'shared/components/common/NotPermitted';
import { selectIsAuthenticated, selectIsUserLoading, selectUserPermissions } from 'store/components/auth/selectors';
import { Permission } from 'store/components/auth/authModels';

export interface IPrivateRouteProps<ComponentProps> {
    component: ComponentType<ComponentProps>;
    path: string;
    exact?: boolean;
    permission?: Permission;
    anyPermissionFrom?: Permission[];
    componentProps?: ComponentProps;
}

export default function PrivateRoute<ComponentProps>({
    path,
    component: Node,
    permission,
    anyPermissionFrom = [],
    exact = false,
    componentProps,
}: IPrivateRouteProps<ComponentProps>) {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const isUserLoading = useSelector(selectIsUserLoading);
    const userPermissions = useSelector(selectUserPermissions);
    const permissions = permission ? [...anyPermissionFrom, permission] : anyPermissionFrom;

    const { pathname } = useLocation();
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('redirect_url', pathname);
    const isPermitted = !permissions.length || intersection(userPermissions, permissions).length > 0;

    return (
        <Route
            path={path}
            exact={exact}
            render={(props: any) => {
                if (isUserLoading) {
                    return false;
                }
                if (!isAuthenticated) {
                    return <Redirect to={`/auth/login?${urlSearchParams.toString()}`}/>;
                }
                return isPermitted ? <Node {...props} {...(componentProps ?? {})}/> : <NotPermitted/>;
            }}
        />
    );
}

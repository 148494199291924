import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { logger } from 'shared/utils/logging/logger';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { FeatureSwitches } from 'utils/featureSwitches';

export default function ErrorTestPage() {
    const [hasError, setError] = useState(false);
    const dispatch = useDispatch();
    const fireLogError = () => {
        logger.error(new Error('Handled test error'));
        dispatch(setGlobalToast({
            severity: IModalSeverity.Error,
            title: 'Handled test error',
        }));
    };

    if (hasError) {
        throw new Error('Unhandled test error');
    }

    return (
        <FeatureSwitch feature={FeatureSwitches.testMonitoringPage}>
            <Box display="flex" m={3}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={fireLogError}
                >
                    cause error
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setError(true)}
                >
                    cause unhandled error
                </Button>
            </Box>
        </FeatureSwitch>
    );
}

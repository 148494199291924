import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { call, put, takeLatest } from 'typed-redux-saga';
import { getScaZones, getStates } from 'store/entities/scaZone/actions';
import { scaZoneApi } from 'store/entities/scaZone/api';

function* getScaZonesSaga({
    payload: { assignmentId, stateId, parentScaZoneId },
}: ReturnType<typeof getScaZones.init>) {
    const scaZones = yield* call(scaZoneApi.getScaZones, parentScaZoneId, stateId);

    yield* put(getScaZones.success({ assignmentId, stateId, parentScaZoneId, scaZones }));
}

export function* getScaZonesWatcher() {
    yield* takeLatest(getScaZones.initType, getScaZonesSaga);
}

export const getStatesWatcher = getLoadEntitiesByRequestSagaWatcher(getStates, scaZoneApi.getStates, 'states');

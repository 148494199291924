import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import {
    EntryType,
    IBreakEntryDataBackend,
    IEntry,
    IInOutBreakDataBackend,
    IInOutEntryDataBackend,
    ISheet,
    ITimeEntryDataBackend,
    QuantityType,
} from 'shared/models/sheet/Sheet';
import {
    getDurationFromDateTimes,
    getMinutesByTimeUnits,
    ITimeByUnits,
} from 'shared/models/DateTime';
import { ItemsById } from 'shared/models/ItemsById';
import { IJobNumber } from 'shared/models/JobNumber';
import {
    ITimeCalculationEntry,
    TimePayType,
} from 'modules/payrollProcessorHub/store/model';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';

export const timeCounter = (minutes: number, hours = 0): string => {
    const total = getMinutesByTimeUnits({ hours, minutes });
    return formatMinutes(total);
};

export function totalTimeByUnits(
    entries: Array<IEntry>,
    entryTypes: QuantityType[] = [QuantityType.TIME, QuantityType.TIME_IN_OUT, QuantityType.TIME_IN_OUT_BREAK],
    filter: (entry: IEntry) => boolean = () => true,
): ITimeByUnits {
    const checkEntryType = (entryType: QuantityType, entry: IEntry) => (
        entry.data.entry_type === entryType && entryTypes.includes(entryType)
    );

    return entries.filter(filter).reduce((acc: ITimeByUnits, entry) => {
        if (checkEntryType(QuantityType.TIME, entry)) {
            const data = entry.data as ITimeEntryDataBackend;
            return {
                hours: acc.hours + data.hours,
                minutes: acc.minutes + data.minutes,
            };
        }
        if (
            checkEntryType(QuantityType.TIME_IN_OUT, entry)
            || checkEntryType(QuantityType.TIME_BREAK, entry)
            || checkEntryType(QuantityType.TIME_IN_OUT_BREAK, entry)
        ) {
            const data = entry.data as IInOutEntryDataBackend | IInOutBreakDataBackend | IBreakEntryDataBackend;
            const { hours, minutes } = getDurationFromDateTimes(data.time_in, data.time_out, data?.break_minutes || 0);
            return {
                hours: acc.hours + hours,
                minutes: acc.minutes + minutes,
            };
        }
        return acc;
    },
    { hours: 0, minutes: 0 });
}

export function totalTime(entries: Array<IEntry>): number {
    const { hours, minutes } = totalTimeByUnits(entries);
    return getMinutesByTimeUnits({ hours, minutes });
}

export function totalBreakTime(entries: Array<IEntry>): number {
    const { hours, minutes } = totalTimeByUnits(entries, [QuantityType.TIME_BREAK]);
    const timeBreakMinutes = getMinutesByTimeUnits({ hours, minutes });
    const timeInOutBreakMinutes = entries.reduce<number>((acc: number, entry) => {
        if (entry.data?.entry_type === QuantityType.TIME_IN_OUT_BREAK) {
            return acc + entry.data.break_minutes;
        }
        return acc;
    }, 0);
    return timeBreakMinutes + timeInOutBreakMinutes;
}

export function totalFileTimePayment(entries: Array<IEntry>, jobNumbersById: ItemsById<IJobNumber>): number {
    return entries.reduce<number>((acc: number, entry) => {
        if (entry.data?.entry_type === QuantityType.FILE) {
            const jobNumber = jobNumbersById[entry.job_number_id ?? ''];
            if (jobNumber && entry.data.files > 0) {
                const rate = entry.is_per_diem ? jobNumber.per_diem_rate : jobNumber.per_file_rate;
                if (rate) {
                    return acc + parseFloat(rate) * entry.data.files;
                }
            }
            return acc;
        }
        return acc;
    }, 0);
}

export function totalHolidays(
    entries: Array<IEntry>,
    calculationBySheetId: Record<string, ITimesheetCalculation>,
): number {
    const holidayEntriesByDay = entries.reduce<Record<string, number>>((acc, entry) => {
        if (entry.entry_type === EntryType.TIME && entry.is_holiday_time) {
            if (calculationBySheetId[entry.sheet_id]) {
                const holidayTime = calculationBySheetId[entry.sheet_id].holiday_hours;
                acc[entry.sheet_id] = parseFloat(holidayTime || '0');
            }
        }
        return acc;
    }, {});
    return Object.values(holidayEntriesByDay).reduce((acc, hours) => acc + hours, 0);
}

export const totalFiles = (entries: Array<IEntry>): number => (
    entries.reduce((acc, entry) => acc + (entry.data.entry_type === QuantityType.FILE ? entry.data.files : 0), 0)
);

interface IOvertimeUnits {
    ot: number;
    dt: number;
    rt: number;
}
export function totalOverTimeUnits(sheets: Array<ITimesheetCalculation>): IOvertimeUnits {
    const overtimeUnits: IOvertimeUnits = { ot: 0, dt: 0, rt: 0 };
    return sheets.reduce((acc, sheet) => ({
        ot: acc.ot + parseFloat(sheet?.ot_hours || '0'),
        dt: acc.dt + parseFloat(sheet?.dt_hours || '0'),
        rt: acc.rt + parseFloat(sheet?.rt_hours || '0'),
    }), overtimeUnits);
}
export function totalDoubleTime(sheets: Array<ISheet>): number {
    return sheets.reduce((acc, sheet) => {
        if (sheet.total_dt_minutes) {
            return acc + sheet.total_dt_minutes;
        }
        return acc;
    }, 0);
}

export function sheetsTotalTimeInMinutes(sheets: Array<ISheet>) {
    return sheets.reduce((acc, sheet) => acc + sheet.total_minutes, 0);
}

export const sheetsTotalBreakTime = (sheets: Array<ISheet>): number => (
    sheets.reduce((acc, sheet) => {
        if (typeof sheet.total_break_minutes === 'undefined') {
            return acc;
        }
        return acc + sheet.total_break_minutes;
    }, 0)
);

interface ITotalTimesByDayUnits {
    regularTime: number;
    overTime: number;
    doubleTime: number;
}
export function totalTimesByDayUnits(timeEntryCalculations: ITimeCalculationEntry[]): ITotalTimesByDayUnits {
    const totalTimeUnits: ITotalTimesByDayUnits = { regularTime: 0, overTime: 0, doubleTime: 0 };
    return timeEntryCalculations.reduce((acc, calculation) => {
        switch (calculation.pay_type) {
            case TimePayType.RegularTime:
                acc.regularTime += parseFloat(calculation?.hours || '0');
                break;
            case TimePayType.OverTime:
                acc.overTime += parseFloat(calculation?.hours || '0');
                break;
            case TimePayType.DoubleTime:
                acc.doubleTime += parseFloat(calculation?.hours || '0');
                break;
        }
        return acc;
    }, totalTimeUnits);
}

import { IDepartment } from 'modules/employmentInfo/models/Department';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { selectCurrentClientInputsConfiguration } from 'store/entities/clients/clientsSelectors';
import { IActivity } from 'store/entities/configuration/configurationModel';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { IAmountEntryData, IMilesEntryData, IOdometerEntryData, QuantityType } from '../sheet/Sheet';
import { ValidationMessages } from '../Validation';
import { selectUserDepartmentsList } from 'modules/employmentInfo/store/department/selectors';
import { addCommonFields, CommonEntryShapeType, DefaultShapeType } from 'shared/models/validationSchemes/sheetCommon';
import { IExpenseEntryFormValues } from '../../components/forms/entries/ExpenseEntryModel';
import { showField } from '../../components/forms/utils';

export const maxDollarsExpenseValue = 10000;
export const maxMilesValue = 10000;

type ExpenseEntryShapeType = CommonEntryShapeType & Partial<Record<keyof IExpenseEntryFormValues, DefaultShapeType>>

export function generateExpenseSchema(fields: InputFields, departments: IDepartment[]) {
    const shape: ExpenseEntryShapeType = addCommonFields({}, fields, departments);
    shape.data = yup.object()
        .nullable()
        .required(ValidationMessages.REQUIRED)
        .when(
            'activity',
            (activity: IActivity | undefined, schema: yup.ObjectSchema) => {
                switch (activity?.data_type) {
                    case QuantityType.ODOMETER:
                        return schema
                            .shape({
                                miles_start: yup.number()
                                    .min(0, ValidationMessages.REQUIRED_MILES)
                                    .required(ValidationMessages.REQUIRED),
                                miles_end: yup.number()
                                    .min(0, ValidationMessages.REQUIRED_MILES)
                                    .required(ValidationMessages.REQUIRED)
                                    .test({
                                        name: 'startOdoShouldBeLessThanEndOdo',
                                        test: function (value: IOdometerEntryData | null): boolean {
                                            return value ? this.parent.miles_start < value : false;
                                        },
                                        message: ValidationMessages.ODOMETER_INVALID,
                                        exclusive: true,
                                    }),
                            });
                    case QuantityType.MILES:
                        return schema
                            .test({
                                name: 'milesRequired',
                                test: (value: IMilesEntryData | null): boolean => {
                                    return value?.miles ? value?.miles > 0 : false;
                                },
                                message: ValidationMessages.REQUIRED_MILES,
                                exclusive: true,
                            })
                            .test({
                                name: 'milesLessMax',
                                test: (value: IMilesEntryData | null): boolean => {
                                    return value?.miles ? value?.miles <= maxMilesValue : true;
                                },
                                message: ValidationMessages.OVERFLOW_MILES,
                                exclusive: true,
                            });
                    default:
                        return schema
                            .test({
                                name: 'dollarsRequired',
                                test: (value: IAmountEntryData | null): boolean => {
                                    return value?.dollars ? parseFloat(value?.dollars) > 0 : false;
                                },
                                message: ValidationMessages.REQUIRED_EXPENSE,
                                exclusive: true,
                            })
                            .test({
                                name: 'dollarsLessMax',
                                test: (value: IAmountEntryData | null): boolean => {
                                    return value?.dollars
                                        ? parseFloat(value?.dollars) <= maxDollarsExpenseValue : true;
                                },
                                message: ValidationMessages.OVERFLOW_EXPENSE,
                                exclusive: true,
                            });
                }
            },
        );

    if (showField(fields, EntrySlug.ZipCode)) {
        shape.zipCode = yup.object()
            .nullable()
            .when('activity.show_zip_code', {
                is: true,
                then: yup.object().required(ValidationMessages.REQUIRED),
                otherwise: yup.object(),
            });
    }

    return yup.object().shape(shape);
}

export function useExpenseSchema(userId?: string) {
    const inputsConfiguration = useSelector(selectCurrentClientInputsConfiguration);
    const departments = useSelector(selectUserDepartmentsList(userId));
    return useMemo(
        () => generateExpenseSchema(inputsConfiguration.expense, departments),
        [inputsConfiguration, departments],
    );
}

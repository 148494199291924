import React, { useCallback, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Delete } from '@material-ui/icons';
import { AnyAction } from 'redux';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';

export interface IDeleteItemButtonProps {
    confirmation: string;
    deleteAction: (payload: string) => AnyAction;
    id: string;
}

export const DeleteItemButton = ({ confirmation, deleteAction, id }: IDeleteItemButtonProps) => {
    const dispatch = useDispatch();
    const [isOpenConfirmation, setOpenConfirmation] = useState(false);

    const onCancel = useCallback(() => {
        setOpenConfirmation(false);
    }, [setOpenConfirmation]);
    const onConfirm = useCallback(() => {
        dispatch(deleteAction(id));
        onCancel();
    }, [dispatch, deleteAction, id, onCancel]);

    return (
        <>
            <IconButton
                onClick={() => setOpenConfirmation(true)}
                color="secondary"
                data-testid="delete-button"
            >
                <Delete fontSize="small"/>
            </IconButton>
            <ConfirmationDialog
                open={isOpenConfirmation}
                onConfirm={onConfirm}
                onCancel={onCancel}
                confirmText="Delete"
            >
                {confirmation}
            </ConfirmationDialog>
        </>
    );
};

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import { selectCostCenterById } from 'store/entities/configuration/configurationSelectors';
import CostCenterFormWrapper from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/CostCenterFormWrapper';
import { ICostCenterFormValues } from 'modules/settings/submodules/clients/costCenters/components/CostCenterForm/model';
import { setEditCostCenterId } from 'modules/settings/submodules/clients/costCenters/components/EditCostCenter/store/actions';
import { selectEditCostCenterId, selectIsCostCenterUpdating } from 'modules/settings/submodules/clients/costCenters/components/EditCostCenter/store/selectors';
import { updateCostCenter } from 'modules/settings/submodules/clients/costCenters/store/actions';

export default function EditCostCenterModal() {
    const modalClasses = useSettingsFormModalStyles();
    const editCostCenterId = useSelector(selectEditCostCenterId);
    const isLoading = useSelector(selectIsCostCenterUpdating);
    const dispatch = useDispatch();
    const editCostCenter = useSelector(selectCostCenterById(editCostCenterId));
    const onSave = useCallback((formValues: ICostCenterFormValues) => {
        if (editCostCenterId) {
            dispatch(updateCostCenter.init({
                id: editCostCenterId,
                data: {
                    ...formValues,
                    number: formValues.number || 1,
                },
            }));
        }
    }, [dispatch, editCostCenterId]);
    const onClose = useCallback(() => {
        dispatch(setEditCostCenterId(null));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Edit Cost Center"
            isOpened={Boolean(editCostCenterId)}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <Box>
                <CostCenterFormWrapper
                    submitTitle="Save"
                    onSubmit={onSave}
                    onCancel={onClose}
                    isLoading={isLoading}
                    costCenter={editCostCenter}
                />
            </Box>
        </ModalDialog>
    );
}

import { generatePath } from 'react-router-dom';
import { settingsRoutes } from '../../../routes';
import { FeatureSwitches } from 'utils/featureSwitches';

const getClientSubModules = (clientId?: string | null) => (
    clientId
        ? [
            {
                title: 'General',
                description: 'General account settings',
                link: generatePath(settingsRoutes.CLIENTS.GENERAL, { clientId }),
            },
            {
                title: 'Controlling Org',
                description: 'Create and edit controlling orgs',
                link: generatePath(settingsRoutes.CLIENTS.CONTROLLING_ORG, { clientId }),
                feature: FeatureSwitches.enableGenworthSheets,
            },
            {
                title: 'Submitting Org',
                description: 'Create and edit Submitting orgs',
                link: generatePath(settingsRoutes.CLIENTS.SUBMITTING_ORG, { clientId }),
                feature: FeatureSwitches.enableGenworthSheets,
            },
            {
                title: 'Cost Centers',
                description: 'Create and edit cost centers',
                link: generatePath(settingsRoutes.CLIENTS.COST_CENTERS, { clientId }),
                feature: FeatureSwitches.enableGenworthSheets,
            },
            {
                title: 'Deals',
                description: 'Create and edit cost deals',
                link: generatePath(settingsRoutes.CLIENTS.DEALS, { clientId }),
                feature: FeatureSwitches.enableGenworthSheets,
            },
            {
                title: 'Job number',
                description: 'Create and edit job numbers',
                link: generatePath(settingsRoutes.CLIENTS.JOB_NUMBER, { clientId }),
                feature: FeatureSwitches.enableGenworthSheets,
            },
            {
                title: 'Application configuration',
                description: 'Manage basic application configuration',
                link: generatePath(settingsRoutes.CLIENTS.APP_CONFIGURATION, { clientId }),
                feature: FeatureSwitches.enableManagementOfAppConfiguration,
            },
            {
                title: 'Work locations',
                description: 'Manage, create and configure work location',
                link: generatePath(settingsRoutes.CLIENTS.LOCATION, { clientId }),
                feature: FeatureSwitches.enableClientWorkLocationManagement,
            },
            {
                title: 'Positions',
                description: 'List of positions',
                link: generatePath(settingsRoutes.CLIENTS.POSITION, { clientId }),
                feature: FeatureSwitches.enableClientPositionsManagement,
            },
            {
                title: 'Pay Codes',
                description: 'List of pay codes',
                link: generatePath(settingsRoutes.CLIENTS.PAYCODES, { clientId }),
                feature: FeatureSwitches.enableClientPayCodesManagement,
            },
            {
                title: 'Pay Settings',
                description: 'Manage time and pay day settings',
                link: generatePath(settingsRoutes.CLIENTS.PAY_SETTINGS, { clientId }),
                feature: FeatureSwitches.enableManagementOfTimeAndPaySettings,
            },
            {
                title: 'Activities',
                description: 'List of activities',
                link: generatePath(settingsRoutes.CLIENTS.ACTIVITIES, { clientId }),
                feature: FeatureSwitches.enableClientActivitiesManagement,
            },
            {
                title: 'Work Location Position Association',
                description: 'Associate Work Locations ans Positions',
                link: generatePath(settingsRoutes.CLIENTS.POSITION_LOCATION_ASSOCIATIONS, { clientId }),
                feature: FeatureSwitches.enablePositionWorkLocationAssociationManagement,
            },
            {
                title: 'Departments',
                description: 'Manage Departments',
                link: generatePath(settingsRoutes.CLIENTS.DEPARTMENTS, { clientId }),
                feature: FeatureSwitches.enableClientDepartmentManagement,
            },
            {
                title: 'Physical Demand Templates',
                description: 'Manage and add templates',
                link: generatePath(settingsRoutes.CLIENTS.PHYSICAL_DEMANDS, { clientId }),
                feature: FeatureSwitches.enableClientPhysicalDemandsManagement,
            },
            {
                title: 'Working Conditions Templates',
                description: 'Manage and add templates',
                link: generatePath(settingsRoutes.CLIENTS.WORKING_CONDITIONS, { clientId }),
                feature: FeatureSwitches.enableClientWorkingConditionsManagement,
            },
            {
                title: 'Background Checks Templates',
                description: 'Manage and add templates',
                link: generatePath(settingsRoutes.CLIENTS.BACKGROUND_CHECKS, { clientId }),
                feature: FeatureSwitches.enableBackgroundCheckTemplatesManagement,
            },
        ]
        : []
);

export default getClientSubModules;

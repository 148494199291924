import React, { useEffect } from 'react';
import SheetApprovalTabs from 'modules/clients/header/SheetApprovalTabs';
import { setHeaderContent } from 'modules/home/header/Header';
import { Box } from '@material-ui/core';

export function useSheetApprovalTabs() {
    useEffect(() => {
        setHeaderContent(
            <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                width="100%"
            >
                <SheetApprovalTabs/>
            </Box>,
        );

        return () => {
            setHeaderContent(null);
        };
    }, []);
}

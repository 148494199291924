import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useFilterAndActionControlsStyles from './FilterAndActionControlsStyles';
import PrintButton from './buttons/PrintButton';
import ApproveButton from './buttons/ApproveButton';
import RejectButton from './buttons/RejectButton';
import { ISheet, EntryType } from 'shared/models/sheet/Sheet';
import Time from './buttons/TimeIcon';
import Expense from './buttons/ExpenseIcon';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import { IUserInfo } from 'shared/models/User';
import FilterButton from 'shared/components/filters/FilterButtonAndForm/FilterButton';
import { setManagerSubmittedSheetsFilters } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';
import {
    selectManagerSubmittedSheetsFilters,
    selectManagerSubmittedSheetsFiltersConfiguration,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import FilterButtonMock from 'shared/components/filters/FilterButtonAndForm/FilterButtonMock';

interface IFilterAndActionControlsProps {
    sheetType: EntryType;
    showActionControls: boolean;
    sheets: ISheet[];
    linkedUsers: IUserInfo[];
    showFilters: boolean;
    title?: string;
}

export default function FilterAndActionControls({
    sheetType, showActionControls, sheets, linkedUsers, showFilters, title,
}: IFilterAndActionControlsProps) {
    const classes = useFilterAndActionControlsStyles();
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    const [filtersFeature] = useFeature(FeatureSwitches.displayFilters);

    return (
        <>
            <Box className={classes.rootContainer}>
                <Box className={classes.leftContainer}>
                    {sheetType === EntryType.TIME ? <><Time/></> : <><Expense/></>}
                    <Box>
                        <Typography variant="h4">
                            {title ? title : `All ${(activeStatus === StatusNames.ALL) ? '' : activeStatus} ${sheetType === EntryType.TIME ? 'Timesheets' : 'Expenses'}` }
                        </Typography>
                    </Box>
                </Box>
                {showFilters && (
                    <FiltersChips className={classes.filtersChips} selector={selectManagerSubmittedSheetsFilters}
                        action={setManagerSubmittedSheetsFilters}
                    />
                )}
                <Box className={classes.rightContainer}>
                    {showActionControls && activeStatus === StatusNames.SUBMITTED ? (
                        <>
                            <RejectButton sheets={sheets} users={linkedUsers}
                                sheetType={sheetType}/>
                            <ApproveButton sheets = {sheets} sheetType={sheetType}/>
                        </>
                    ) : <></>}
                    {showFilters && (
                        <>
                            <PrintButton/>
                            {filtersFeature ? (
                                <FilterButton overrideClassName={classes.actionButton}
                                    submitFilters={setManagerSubmittedSheetsFilters}
                                    selectFiltersConfig={selectManagerSubmittedSheetsFiltersConfiguration}
                                    selectFilters={selectManagerSubmittedSheetsFilters}
                                />
                            ) : (
                                <FilterButtonMock overrideClassName={classes.actionButton}/>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
}

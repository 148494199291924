import { useField } from 'formik';
import React, { ChangeEvent, Ref } from 'react';
import { FormControl, FormHelperText, Select, Tooltip } from '@material-ui/core';
import { IFormFieldProps } from '../formFields/models';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';
import { IFormSelect } from './model';

export interface IOptionGroup<T> {
    isOptionGroup: true;
    label: string;
    options: Array<T>;
}

export type SelectOption<T> = T | IOptionGroup<T>

export interface IFormSelectProps<T> extends IFormFieldProps, IFormSelect {
    options: Array<SelectOption<T>>;
    getKey: (item: T) => string;
    getText: (item: T) => string;
    onChanged?: (item: T) => void;
    inputRef?: Ref<HTMLDivElement>;
}

export default function FormSelect({
    name,
    label,
    outerLabel,
    options,
    disabled = false,
    className,
    getKey,
    getText,
    onChanged,
    useIdValue = false,
    isNullable = true,
    title = '',
    id = name,
}: IFormSelectProps<any>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [field, meta, helpers] = useField(name);
    const hasError = Boolean(meta.error && meta.touched);
    const defaultValue = isNullable ? null : '';

    const onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const { value: changedValue = '' } = event.target;
        const flatOptions = options.reduce((mem, item) => {
            if (item.isOptionGroup) {
                return mem.concat(item.options);
            }
            mem.push(item);
            return mem;
        }, []);
        const objectValue = flatOptions.find(option => getKey(option) === changedValue) || defaultValue;
        const newValue = useIdValue ? changedValue || defaultValue : objectValue;
        helpers.setValue(newValue);
        helpers.setTouched(true);
        if (onChanged) {
            onChanged(objectValue);
        }
    };

    const onBlur = () => {
        helpers.setTouched(false);
    };

    const renderOptions = (optionList: any[]) => (
        <>
            {optionList.map(option => (
                <>
                    {option?.isOptionGroup ? (
                        <optgroup label={option.label}>
                            {renderOptions(option.options)}
                        </optgroup>
                    ) : (
                        <option
                            value={getKey(option)}
                            key={getKey(option)}
                        >
                            {getText(option)}
                        </option>
                    )}
                </>
            ))}
        </>
    );

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            <Tooltip title={title}>
                <>
                    {outerLabel && (
                        <label
                            htmlFor={id}
                            className={formHelperTextClasses.outerLabel}
                        >
                            {outerLabel}
                        </label>
                    )}
                    <Select
                        {...field}
                        value={!useIdValue && field.value ? getKey(field.value) : field.value || ''}
                        disabled={disabled}
                        id={id}
                        onChange={onChange}
                        native
                        onBlur={onBlur}
                    >
                        {label && (
                            <option value="">
                                {label}
                            </option>
                        )}
                        {renderOptions(options)}
                    </Select>
                </>
            </Tooltip>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
}

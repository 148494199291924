import React, { useMemo } from 'react';
import { FormikProps } from 'formik';
import { FormFieldWithSeparateLabel } from 'modules/settings/common/components/FormFieldWithSeparateLabel';
import { FormWithSections } from 'modules/settings/common/components/FormWithSections/FormWithSections';
import { ApprovalLevelsField } from './components/ApprovalLevelsField';
import { ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';

export interface IAppSettingsFormProps extends FormikProps<Partial<ITimeAndPayClientConfiguration>> {
    isLoading?: boolean;
}

export function AppSettingsForm({
    handleSubmit,
    isLoading,
}: IAppSettingsFormProps) {
    const sections = useMemo(() => ([
        {
            name: 'Approvals hierarchy',
            component: (
                <FormFieldWithSeparateLabel
                    name="approvalLevels"
                    outerLabel="Levels of approvers in sheets"
                    FieldComponent={ApprovalLevelsField}
                />
            ),
        },
    ]), []);

    return (
        <FormWithSections
            sections={sections}
            submitTitle="Update"
            onSubmit={handleSubmit}
            isLoading={isLoading}
            isDisabled={isLoading}
        />
    );
}

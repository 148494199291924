import { FormikProps } from 'formik';
import { IOfferLetterFormValues } from 'modules/offerLetter/components/OfferLetterForm/model';
import React, { useContext, useEffect, useMemo } from 'react';
import { IEmployeeUserInfo, IUserInfo } from 'shared/models/User';
import { OfferLetterFormNextButton } from './components/OfferLetterFormNextButton';
import { MultiStepForm, MultiStepFormValues } from 'shared/components/forms/multiStep/MultiStepForm';
import { OFFER_LETTER_FORM_STEP_EMPLOYEE, OfferLetterFormStepEmployee } from './steps/OfferLetterFormStepEmployee';
import { OFFER_LETTER_FORM_STEP_OFFER, OfferLetterFormStepOffer } from './steps/OfferLetterFormStepOffer';
import { offerLetterSteppedValidationSchema } from './OfferLetterValidationsSchema';
import { OFFER_LETTER_FORM_STEP_INVITE, OfferLetterFormStepInvite } from './steps/OfferLetterFormStepInvite';
import { OFFER_LETTER_FORM_STEP_ASSIGNMENT, OfferLetterFormStepAssignment } from './steps/OfferLetterFormStepAssignment';
import { OfferLetterFormSubmitBlock } from './components/OfferLetterFormSubmitBlock';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferLetterTemplates } from '../../store/templates/actions';
import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import { getEmployeeTypes } from 'modules/employmentInfo/store/employeeType/actions';
import { CreateOfferSidebarContext } from '../CreateOffer/CreateOfferSidebar';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import {
    getBackgroundCheckTemplates,
    getPhysicalDemands,
    getWorkingConditions,
} from 'store/entities/configuration/configurationAction';
import { IOfferLetter } from '../../store/model';
import {
    selectBackgroundCheckTemplateById,
    selectPhysicalDemandById,
    selectWorkingConditionById,
} from 'store/entities/configuration/configurationSelectors';

export interface IOfferLetterSeletedEmployee extends IEmployeeUserInfo, Omit<Partial<IUserInfo>, 'first_name' | 'last_name' | 'cell_phone' | 'email'> {

}

export interface IOfferLetterMultiStepFormProps {
    isLoading?: boolean;
    onSubmit: (values: IOfferLetterFormValues) => void;
    onCancel: () => void;
    isSubmitting?: boolean;
    selectedEmployee?: IOfferLetterSeletedEmployee;
    initialValues: IOfferLetterFormValues;
    offer?: IOfferLetter;
}

export function OfferLetterMultiStepForm({
    initialValues,
    onSubmit,
    onCancel,
    isSubmitting,
    selectedEmployee,
    offer,
}: IOfferLetterMultiStepFormProps) {
    const dispatch = useDispatch();
    const [editEmployeeFeature] = useFeature(FeatureSwitches.enableEditingEmployeeNameOnOfferLetterCreation);
    const [templatePartsOptional] = useFeature(FeatureSwitches.enableOfferLetterTemplatePartsOptional);

    useEffect(() => {
        dispatch(getOfferLetterTemplates.init());
        dispatch(getDepartments.init());
        dispatch(getEmployeeTypes.init());
    }, [dispatch]);

    const selectedBackgroundChecksTemplateId = offer?.background_checks_template_id || '';
    const selectedWorkingConditionsTemplateId = offer?.working_conditions_template_id || '';
    const selectedPhysicalDemandsTemplateId = offer?.physical_demands_template_id || '';

    const backgroundChecksTemplate = useSelector(selectBackgroundCheckTemplateById(selectedBackgroundChecksTemplateId));
    const workingConditionsTemplate = useSelector(selectWorkingConditionById(selectedWorkingConditionsTemplateId));
    const physicalDemandsTemplate = useSelector(selectPhysicalDemandById(selectedPhysicalDemandsTemplateId));

    const initialValuesPatched = useMemo<IOfferLetterFormValues>(() => ({
        ...initialValues,
        ...offer ? {
            template: offer.offer_letter_template_id || '',
            employeeType: offer.employment_type || null,
            hours: offer.range_of_hours || null,
            workingConditions: offer.working_conditions || '',
            workingConditionsTemplate: workingConditionsTemplate || null,
            physicalDemands: offer.physical_demands || '',
            physicalDemandsTemplate: physicalDemandsTemplate || null,
            backgroundChecks: offer.background_checks || '',
            backgroundChecksTemplate: backgroundChecksTemplate || null,
        } : {},
    }), [initialValues, offer, workingConditionsTemplate, physicalDemandsTemplate, backgroundChecksTemplate]);

    // Due to serial loading of feature switchers one effect should initialize dispatch several times
    useEffect(() => {
        dispatch(getBackgroundCheckTemplates.init());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getWorkingConditions.init());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getPhysicalDemands.init());
    }, [dispatch]);

    const canEditEmployee = selectedEmployee
        && editEmployeeFeature
        && selectedEmployee?.employee_profile?.prism_onboarding_completed;
    const steps = [
        ...selectedEmployee
            ? [
                {
                    name: OFFER_LETTER_FORM_STEP_EMPLOYEE,
                    component: function StepEmployee(
                        formikProps: FormikProps<MultiStepFormValues<IOfferLetterFormValues>>,
                    ) {
                        return (
                            <OfferLetterFormStepEmployee
                                {...formikProps}
                                employee={selectedEmployee}
                                canEditEmployee={canEditEmployee}
                            />
                        );
                    },
                },
            ]
            : [
                {
                    name: OFFER_LETTER_FORM_STEP_INVITE,
                    component: OfferLetterFormStepInvite,
                },
                {
                    name: OFFER_LETTER_FORM_STEP_OFFER,
                    component: OfferLetterFormStepOffer,
                },
            ],
        {
            name: OFFER_LETTER_FORM_STEP_ASSIGNMENT,
            component: OfferLetterFormStepAssignment,
        },
    ];

    const stepField = 'step';
    const { step, setStep: onSetStep } = useContext(CreateOfferSidebarContext);
    const validationSchema = useMemo(() => {
        return offerLetterSteppedValidationSchema(
            stepField,
            {
                templatePartsRequired: !templatePartsOptional,
            },
        );
    }, [stepField, templatePartsOptional]);

    return (
        <MultiStepForm
            onSubmit={onSubmit}
            initialValues={initialValuesPatched}
            NextButton={OfferLetterFormNextButton}
            validationSchema={validationSchema}
            FinalStepBlock={() => <OfferLetterFormSubmitBlock onCancel={onCancel} isLoading={isSubmitting}/>}
            steps={steps}
            step={step}
            onSetStep={onSetStep}
            stepFieldName={stepField}
        />
    );
}

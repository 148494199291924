import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import { PayrollFilter } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilter';
import { MobilePayrollFooter } from 'modules/payrollProcessorHub/components/PayrollSheetsTablePage/MobilePayrollFooter';
import { SheetTabTitle } from 'modules/payrollProcessorHub/components/SheetTitle/SheetTabTitle';
import { PayrollSheetTable } from 'modules/payrollProcessorHub/components/PayrollSheetTable/PayrollSheetTable';
import { useFilteredPayrollSheetRows } from 'modules/payrollProcessorHub/store/helpers';
import { usePayrollMissingSheetTableCells } from 'modules/payrollProcessorHub/components/PayrollSheetTable/Cells';

export const PayrollMissingSheetsTablePage = () => {
    const filteredSheets = useFilteredPayrollSheetRows();
    const cells = usePayrollMissingSheetTableCells(filteredSheets);

    return (
        <>
            <SheetTabTitle/>
            <Hidden xsDown>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mb={2}
                >
                    <PayrollFilter/>
                </Box>
            </Hidden>
            <PayrollSheetTable
                cells={cells}
            />
            <MobilePayrollFooter/>
        </>
    );
};

import { isString } from 'lodash';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { confirmEmail } from 'store/components/emailConfirmation/actions';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { usersApi } from 'store/entities/users/api';
import { call, put, takeLatest } from 'typed-redux-saga';

function* emailConfirmationSaga(action: ReturnType<typeof confirmEmail.init>) {
    try {
        yield* call(usersApi.emailConfirmation, action.payload);
        yield put(confirmEmail.success());
        browserHistory.replace(routes.AUTH.LOGIN);
        yield* put(setGlobalToast({
            severity: IModalSeverity.Success,
            title: 'Your email has been confirmed.',
            ignoreSidebar: true,
        }));
    } catch (e) {
        yield put(confirmEmail.error(e));
        const errorText = e?.response?.data?.error?.message;
        const errorMessage = isString(errorText) ? errorText : 'Unable to confirm you email. Try again.';
        yield* put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
            ignoreSidebar: true,
        }));
        browserHistory.replace(routes.AUTH.LOGIN);
    }
}

export function* emailConfirmationWatcher() {
    yield takeLatest(confirmEmail.initType, emailConfirmationSaga);
}

export default [
    emailConfirmationWatcher,
];

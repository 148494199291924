import { Permission } from 'store/components/auth/authModels';
import { IStore } from '../../configureStore';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { IUserInfoClient } from 'shared/models/User';

export const selectCurrentUser = (state: IStore) => state.auth.user;
export const selectCurrentUserClientInfo = (state: IStore) => {
    const currentUser = selectCurrentUser(state);
    const clientId = selectCurrentClientId(state);
    return currentUser?.clients?.find((userClientInfo: IUserInfoClient) => userClientInfo.client_id === clientId);
};
export const selectIsUserLoading = (state: IStore) => state.auth.isLoading;
export const selectIsAuthenticated = (state: IStore) => state.auth.isAuthenticated;
export const selectUserPermissions = (state: IStore) => state.auth.permissions;
export const selectIsUserHasPermission = (permission: Permission) =>
    (state: IStore) => state.auth.permissions.includes(permission);
export const selectGlobalPermissions = (state: IStore) => state.auth.globalPermissions;
export const selectIsManager = (state: IStore) => selectIsUserHasPermission(Permission.ApproveSheets)(state);
/**
 * Selector for getting inputted email on login or registration or reset password
 * @param state
 */
export const selectAuthEmail = (state: IStore) => state.auth.cacheEmailOnAuth;

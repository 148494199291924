import { browserHistory } from 'shared/utils/browserHistory';
import { generatePath } from 'react-router-dom';
import { select } from 'typed-redux-saga';
import { selectCurrentClientId } from '../../entities/clients/clientsSelectors';

export function* navigateWithClientSaga(path: string) {
    const clientId = yield select(selectCurrentClientId);
    yield browserHistory.push(generatePath(path, { client_id: clientId }));
}

export function* navigateSaga(path: string, params: any = {}) {
    yield browserHistory.push(generatePath(path, params));
}

export function downloadFileSaga(data: BlobPart, fileName: string) {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

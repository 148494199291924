import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { FormikProps } from 'formik';
import useAddControlsStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import UpperButton from 'shared/components/buttons/UpperButton';
import ExpenseDataField from 'shared/components/formSpecialFields/expenseData/ExpenseDataField';
import ZipCodeField from 'shared/components/formSpecialFields/zipCode/ZipCodeField';
import FormField from 'shared/components/formFields/FormField';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { CommonEntryInlineFields, IBaseCommonEntryFieldsProps } from './CommonEntryInlineFields';
import { defaultValues, IExpenseEntryFormValues } from './ExpenseEntryModel';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { AttachMoney } from '@material-ui/icons';
import { ICommonEntryFormValues } from './EntryCommonFields';
import { showField } from '../utils';

export interface IExpenseEntryFormProps extends FormikProps<IExpenseEntryFormValues>, IBaseCommonEntryFieldsProps {
    payPeriod: IPayPeriod;
    areaId?: number;
    inputs: InputFields;
    onChangeCommonValues: (values: ICommonEntryFormValues) => void;
    disabled?: boolean;
}

export function ExpenseEntryInlineForm({
    supervisorId,
    areaId,
    payPeriod,
    setFieldValue,
    setTouched,
    handleSubmit,
    inputs,
    userId,
    onChangeCommonValues,
    disabled = false,
    ...props
}: IExpenseEntryFormProps) {
    const classes = useAddControlsStyles();
    const { projectAssignment, activity, data } = props.values;
    const assignmentId = projectAssignment?.assignment?.id;

    useEffect(() => {
        setFieldValue('zipCode', defaultValues.zipCode);
    }, [assignmentId, setFieldValue]);

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Box className={classes.controlsContainer}>
                <Box className={classes.aSide}>
                    <CommonEntryInlineFields
                        values={props.values}
                        onChangeCommonValues={onChangeCommonValues}
                        setFieldValue={setFieldValue}
                        setTouched={setTouched}
                        payPeriod={payPeriod}
                        supervisorId={supervisorId}
                        areaId={areaId}
                        entryType={EntryType.EXPENSE}
                        userId={userId}
                        inputs={inputs}
                        disabled={disabled}
                    />

                    <ExpenseDataField
                        name="data"
                        label="Expense"
                        key={`${activity?.data_type ?? props.values.activity?.data_type}.${data?.entry_type}`}
                        activity={activity ?? props.values.activity}
                        className={classes.selectFieldSmall}
                        inputProps={{
                            showOdometerDollarsInput: true,
                        }}
                        disabled={disabled}
                    />

                    {showField(inputs, EntrySlug.Notes) && activity?.data_type !== QuantityType.ODOMETER && (
                        <FormField
                            name="notes"
                            label={inputs.notes.placeholder}
                            className={classes.fixedLarge}
                            disabled={disabled}
                        />
                    )}

                    {showField(inputs, EntrySlug.ZipCode) && activity?.show_zip_code && (
                        <ZipCodeField
                            name="zipCode"
                            label={inputs.zip_code.placeholder}
                            className={classes.fixedMedium}
                            disabled={disabled}
                        />
                    )}
                </Box>

                <UpperButton
                    label={(
                        <>
                            <span>
                           + Add
                            </span>
                            <AttachMoney className={classes.addIcon}/>
                        </>
                    )}
                    className={classes.upperButton}
                    disabled={disabled}
                />
            </Box>
        </form>
    );
}

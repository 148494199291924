import React from 'react';
import clsx from 'clsx';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { IOfferLetterRow, IOfferLetterWithPdf } from 'modules/offerLetter/components/OfferLetterTable/cells';
import OfferLetterStatus from 'modules/offerLetter/components/OfferLetterTable/OfferLetterStatus';
import { useOfferLetterEmployeeStyles } from 'modules/offerLetter/OfferLetterEmployee/OfferLetterEmployeeStyles';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import PlainText from 'shared/components/table/Cells/PlainText';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { Update } from '@material-ui/icons';
import { routes } from 'shared/routes';
import { useTableStyles } from 'shared/styles/tableStyles';
import { formatShortDate } from 'shared/utils/formatters/dateFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatPayRate } from 'shared/utils/formatters/payRate';
import ButtonCell from 'shared/components/table/Cells/ButtonCell';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';

type TableClassesType = ReturnType<typeof useTableStyles>;

export const offerDateCell = {
    key: 'offerDate',
    title: 'offer date',
    render: function OfferDateCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className}
                value={formatShortDate(offerLetter.offer_date)}
            />
        );
    },
};

export const startDateCell = {
    key: 'start date',
    title: 'start date',
    render: function StartDateCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={formatShortDate(offerLetter.start_date)}/>
        );
    },
};

export const payRateDesktopCell = {
    key: 'payRate',
    title: 'pay rate',
    width: '90px',
    render: function PayRate({ className, offerLetter }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={formatPayRate(offerLetter)}/>
        );
    },
};

export const getOfferLetterPdfCell = (
    tableClasses: TableClassesType,
    action: (offerLetter: IOfferLetterWithPdf) => void,
) => ({
    key: 'offerLetterPDF',
    title: 'offer letter',
    width: '96px',
    headerClassName: tableClasses.cellJustifyCenter,
    render: function OfferLetterCell({ className, offerLetter }: IOfferLetterRow) {
        return (
            <div className={clsx(className, tableClasses.iconCell, tableClasses.cellJustifyCenter)}>
                <IconButton
                    onClick={action ? () => action(offerLetter) : undefined}
                    disabled={offerLetter.isPdfLoading}
                >
                    {offerLetter.isPdfLoading ? <CircularProgress color="inherit" size={16}/> : <PdfSVG/>}
                </IconButton>
            </div>
        );
    },
});

export const positionCell = {
    key: 'position',
    title: 'position',
    render: function PositionCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={offerLetter.position?.name || ''}/>
        );
    },
};

export const departmentCell = {
    key: 'department',
    title: 'department',
    render: function DepartmentCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={offerLetter?.department?.name || ''}/>
        );
    },
};

export const locationCell = {
    key: 'location',
    title: 'location',
    render: function LocationCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <PlainText className={className} value={offerLetter.location?.name || ''}/>
        );
    },
};

export const getOfferLetterStatusCell = (tableClasses: TableClassesType) => ({
    key: 'status',
    title: 'status',
    width: '112px',
    headerClassName: tableClasses.cellJustifyCenter,
    render: function StatusCell({ className, offerLetter }: IOfferLetterRow) {
        return (
            <OfferLetterStatus offerLetter={offerLetter} className={className}/>
        );
    },
});

export const ContinueOnboardingButton = ({ className, offerLetter }: IOfferLetterRow) => {
    const classes = useSubmittedTableStyles();
    const [alwaysDisplayContinueBtn] = useFeature(FeatureSwitches.enableAlwaysDisplayContinueOnboarding);
    return (
        <>
            {(offerLetter.userCanContinueOnboarding || alwaysDisplayContinueBtn) && (
                <>
                    {offerLetter.isStatusChecking ? (
                        <Box className={className} mb={-0.5}>
                            <CircularProgress
                                className={clsx(classes.iconCell, classes.continueOnboarding)}
                                color="primary"
                                size="small"
                            />
                        </Box>
                    ) : (
                        <IconButton
                            className={clsx(className, classes.iconCell, classes.continueOnboarding)}
                            component={Link}
                            to={generatePath(routes.EMPLOYEE_OFFER_LETTER.DETAIL, { id: offerLetter.id })}
                            size="small"
                            title="Continue OnBoarding"
                        >
                            <Update/>
                        </IconButton>
                    )}
                </>
            )}
        </>
    );
};

export const getActionCell = (tableClasses: TableClassesType) => ({
    key: 'action',
    title: '',
    width: '56px',
    headerClassName: tableClasses.cellJustifyCenter,
    render: function ActionCell({ className, offerLetter }: IOfferLetterRow) {
        return (
            <Box className={className}>
                <ContinueOnboardingButton offerLetter={offerLetter}/>
            </Box>
        );
    },
});

export const getStatusPayRateMobileCell = (
    classes: ReturnType<typeof useOfferLetterEmployeeStyles>,
    action?: (offerLetter: IOfferLetterWithPdf) => void,
) => ({
    key: 'statusPayRate',
    title: '',
    width: '128px',
    render: function PayRateMobile({ className, offerLetter }: IOfferLetterRow) {
        return (
            <Box
                className={className}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                onClick={action ? () => action(offerLetter) : undefined}
            >
                <OfferLetterStatus className={classes.statusMobile} offerLetter={offerLetter}/>
                <MobileListCell listClassName={clsx(classes.mobileListWrapper, classes.listItemsAlignEnd)}
                    title={formatDollars(offerLetter.pay_rate_value)}
                    items={[`${offerLetter.pay_rate_type}`]}
                />
                <ContinueOnboardingButton className={className} offerLetter={offerLetter}/>
            </Box>
        );
    },
});

export const getOfferEditBtnCell = (clientId: string | null) => ({
    key: 'offerEdit',
    title: '',
    width: '75px',
    render: function EditBtnCell({ offerLetter, className }: IOfferLetterRow) {
        return (
            <ButtonCell
                className={className}
                sheetId={offerLetter.id}
                clientId={clientId}
            />
        );
    },
});

import { Theme } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export interface ITableContainerCssProps {
    gridTemplateColumns: string;
    amount: number;
}

export const getRowHeightLine = (
    paddingTopBottomInPx: number,
    borderSizeBottomInPx: number,
    borderSizeTopInPx: number | undefined = undefined) => {

    const rowHeight = 40;

    if (borderSizeTopInPx === undefined) {
        return rowHeight - 2 * (borderSizeBottomInPx + paddingTopBottomInPx);
    }

    return rowHeight - 2 * paddingTopBottomInPx - borderSizeBottomInPx - borderSizeTopInPx;
};

export const useGridTableStyles = makeHighPriorityStyles((theme: Theme) => {
    const borderSize = 1;
    const headerBorders = `${borderSize}px solid ${colors.darkGray}`;
    const paddingTopBottom = 1;
    const paddingTopBottomInPx = theme.spacing(paddingTopBottom);

    return {
        tableContainer: (props: ITableContainerCssProps) => ({
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: props.gridTemplateColumns,
            backgroundColor: colors.white,
            [`& > :nth-child(${props.amount}n)`]: {
                paddingRight: theme.spacing(2),
            },
            borderRight: `1px solid ${colors.lightGray}`,
            borderLeft: `1px solid ${colors.lightGray}`,
        }),
        rowContainer: (props: ITableContainerCssProps) => ({
            display: 'contents',
            [`& > :nth-child(${props.amount}n)`]: {
                paddingRight: theme.spacing(2),
            },
        }),
        headerCell: {
            fontSize: 10,
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize)}px`,
            letterSpacing: 1,
            color: theme.palette.text.primary,
            paddingLeft: theme.spacing(2),
            textTransform: 'uppercase',
            borderTop: headerBorders,
            borderBottom: headerBorders,
            alignItems: 'flex-end',

        },
        headerCellSticky: {
            backgroundColor: 'inherit',
            zIndex: 1,
            position: 'sticky',
            top: 0,
        },
        bodyCell: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            padding: theme.spacing(paddingTopBottom, 0, paddingTopBottom, 2),
            borderBottom: `${borderSize}px solid ${colors.lightGray}`,
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize, 0)}px`,
            minHeight: `${getRowHeightLine(paddingTopBottomInPx, borderSize, 0)}px`,

            '&:hover': {
                whiteSpace: 'unset',
            },
            '&:hover button': {
                whiteSpace: 'nowrap',
            },
        },
    };
});

export const useSkeletonStyles = makeHighPriorityStyles((theme: Theme) => ({
    skeletonWrapper: {
        borderWidth: `0 2px 2px 2px`,
        borderStyle: 'solid',
        borderColor: colors.lightGray,
        marginBottom: theme.spacing(2),
    },
    skeletonRows: {
        padding: theme.spacing(1, 2),
    },
    skeletonHeader: {
        backgroundColor: colors.lightGray,
    },
    skeletonLine: {
        '& + &': {
            marginTop: theme.spacing(1),
        },
        backgroundColor: colors.lightGray,
    },
}));

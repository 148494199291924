import { combineReducers } from 'redux';
import { editEntry } from 'modules/timeAndExpense/components/EditEntry/store/reducer';
import { addEntry } from 'modules/timeAndExpense/components/AddEntry/store/reducer';
import { workingPage } from 'modules/timeAndExpense/components/WorkingPage/store/reducer';

export const timeAndExpense = combineReducers({
    editEntry,
    addEntry,
    workingPage,
});

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    Box,
    Hidden,
    CircularProgress,
} from '@material-ui/core';
import {
    useOfferLetterStyles,
} from 'modules/offerLetter/OfferLetterStyles';
import { useDispatch, useSelector } from 'react-redux';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import {
    getMyEmployeeProfile,
    getOfferLetter,
    setOnboardingStep, viewOfferLetter,
} from 'modules/offerLetter/store/actions';
import {
    selectGetOfferLetterLoading,
    selectIsEmployeeAlreadyOnboarded,
    selectMyEmployeeProfileByClientId,
    selectOfferLetter,
} from 'modules/offerLetter/store/selectors';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { setSidebarOpenedAction } from 'store/entities/appConfig/actions';
import { OnboardingStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingStep';
import { OnboardingStepper } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/OnboardingStepper';
import { ProfileStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/ProfileStep';
import { ViewOfferStep } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/ViewOfferStep';
import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import {
    selectIsMyEmployeeProfileLoading, selectOnboardingStep,
    selectProfileIsCreated,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import { getStates } from 'store/entities/scaZone/actions';
import { CommonDialog } from '../../../../shared/components/dialog/CommonDialog';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from '../../../../utils/featureSwitches';

export default function OfferLetterEmployeeDetail() {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getStates.init());
        dispatch(setOnboardingStep(OnboardingSteps.ViewPdf));
        dispatch(getOfferLetter.init(id));
        dispatch(setSidebarOpenedAction(false));
        return () => {
            dispatch(setSidebarOpenedAction(true)); // for opening vertical menu after hiding in offer letter page
        };
    }, [id, dispatch]);

    const classes = useOfferLetterStyles();

    const activeStep = useSelector(selectOnboardingStep);
    const isEmployeeProfileLoading = useSelector(selectIsMyEmployeeProfileLoading);
    const isOfferLetterLoading = useSelector(selectGetOfferLetterLoading);
    const isProfileCreated = useSelector(selectProfileIsCreated);
    const offerLetter = useSelector(selectOfferLetter(id));
    const profile = useSelector(selectMyEmployeeProfileByClientId(offerLetter?.client_id));
    const isAlreadyOnboarded = useSelector(selectIsEmployeeAlreadyOnboarded(offerLetter?.client_id));
    const isLoading = isOfferLetterLoading || (isEmployeeProfileLoading && !profile) || !offerLetter;
    const [currentUnlockedStep, setUnlockedStep] = useState(0);
    const [alwaysDisplayContinueBtn] = useFeature(FeatureSwitches.enableAlwaysDisplayContinueOnboarding);

    useEffect(() => {
        dispatch(setSidebarOpenedAction(false));
        if (offerLetter) {
            dispatch(getMyEmployeeProfile.init(offerLetter.client_id));
            dispatch(viewOfferLetter(offerLetter.id));
        }
    }, [dispatch, offerLetter]);

    useEffect(() => {
        const redirectToRoot = () => browserHistory.push(routes.EMPLOYEE_OFFER_LETTER.ROOT);
        let step = OnboardingSteps.ViewPdf;
        if (offerLetter?.offer_letter_terms_accepted_at && isAlreadyOnboarded) {
            if (!alwaysDisplayContinueBtn) {
                redirectToRoot();
            } else {
                step = OnboardingSteps.PrismOnboarding;
            }
        }
        if ([
            OfferLetterStatusSlug.Outstanding,
            OfferLetterStatusSlug.PendingManagerApproval,
        ].includes(offerLetter?.status?.slug)) {
            if (offerLetter?.offer_letter_terms_accepted_at) {
                step = OnboardingSteps.EmployeeProfile;
                if (isProfileCreated) {
                    step = OnboardingSteps.PrismOnboarding;
                }
            }
        } else if (offerLetter) {
            if (!alwaysDisplayContinueBtn) {
                redirectToRoot();
            } else {
                step = OnboardingSteps.PrismOnboarding;
            }
        }
        setUnlockedStep(step);
        dispatch(setOnboardingStep(step));
    }, [alwaysDisplayContinueBtn, dispatch, offerLetter, setUnlockedStep, isProfileCreated, isAlreadyOnboarded]);

    const hasStepper = !isAlreadyOnboarded || [
        OnboardingSteps.EmployeeProfile,
        OnboardingSteps.PrismOnboarding,
    ].includes(activeStep);
    return (
        <CommonDialog
            open
            fullScreen={hasStepper}
            classes={{
                paper: classes.paper,
            }}
        >
            {isLoading ? (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    flexGrow={1}
                    mb={9}
                >
                    <CircularProgress/>
                </Box>
            ) : (
                <Box
                    display="flex"
                    alignItems="flex-start"
                    className={classes.offerLetterViewWrapper}
                >
                    {hasStepper && (
                        <Hidden mdDown>
                            <OnboardingStepper
                                step={activeStep}
                                unlocked={currentUnlockedStep}
                            />
                        </Hidden>
                    )}
                    {activeStep === OnboardingSteps.ViewPdf && (
                        <ViewOfferStep
                            id={id}
                            offerLetter={offerLetter}
                            isAlreadyOnboarded={isAlreadyOnboarded}
                        />
                    )}
                    {activeStep === OnboardingSteps.EmployeeProfile && (
                        <ProfileStep clientId={offerLetter?.client_id}/>
                    )}
                    {activeStep === OnboardingSteps.PrismOnboarding && (
                        <OnboardingStep
                            clientId={offerLetter.client_id}
                            offerLetterId={offerLetter?.id}
                        />
                    )}
                </Box>
            )}
        </CommonDialog>
    );
}

import React from 'react';
import SidebarCommon from 'shared/components/sidebars/Sidebar/SidebarCommon';

export type Anchor = 'top' | 'left' | 'bottom' | 'right' ;

export interface ISidebarProps {
    title: string;
    isOpened: boolean;
    onClose: () => void;
    children: React.ReactNode;
    customClasses?: string;
    titleClasses?: Partial<{
        root: string;
        text: string;
        icon: string;
    }>;
    anchor?: Anchor;
}

export default function Sidebar({
    isOpened,
    onClose,
    anchor = 'right',
    ...props
}: ISidebarProps) {
    return (
        <SidebarCommon
            {...props}
            open={isOpened}
            onBack={anchor !== 'bottom' ? onClose : undefined}
            onClose={anchor === 'bottom' ? onClose : undefined}
        />
    );
}

import { EntryType } from 'shared/models/sheet/Sheet';
import { selectCurrentUser } from 'store/components/auth/selectors';
import { IStore } from 'store/configureStore';
import { ApproversFromFields } from 'store/entities/clients/clientsModel';
import { selectClientTimeAndPayConfiguration } from 'store/entities/clients/clientsSelectors';
import { selectCurrentUserApprovalsLevelByAssignments, selectJobNumbersById } from 'store/entities/configuration/configurationSelectors';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectAllSheets } from 'store/entities/timesheet/selectors';

export const selectActiveSheetTab = (state: IStore) => state.appConfig.sheetGridStatus;
export const selectApprovalFilteredSheets = (sheetType: EntryType) => (state: IStore) => {
    const activeStatus = selectActiveSheetTab(state);
    const allSheets = selectAllSheets(state);
    const approvalLevelsByAssignments = selectCurrentUserApprovalsLevelByAssignments(state);
    const currentUser = selectCurrentUser(state);
    const timeAndPayConfiguration = selectClientTimeAndPayConfiguration(state);
    const approverFrom = timeAndPayConfiguration?.approversFrom || ApproversFromFields.FromAssignment;
    const jobNumbersById = selectJobNumbersById(state);

    return allSheets
        .filter(sheet => {
            if (sheet.entry_type === sheetType && sheet.user_id !== currentUser?.id) {
                const userApprovalLevel = approvalLevelsByAssignments[sheet.assignment_id || ''] || 0;
                switch (activeStatus) {
                    case StatusNames.ALL:
                        return sheet.status.name !== StatusNames.WORKING;
                    case StatusNames.SUBMITTED: {
                        if (approverFrom === ApproversFromFields.FromJobNumber) {
                            return sheet.status.name === activeStatus
                                && currentUser?.id === jobNumbersById[sheet.job_number_id || '']?.manager_id;
                        }
                        return sheet.status.name === activeStatus && sheet.approved_level < userApprovalLevel;
                    }
                    case StatusNames.APPROVED:
                        if (approverFrom === ApproversFromFields.FromJobNumber) {
                            return sheet.status.name === activeStatus
                                && currentUser?.id === jobNumbersById[sheet.job_number_id || '']?.manager_id;
                        }
                        return sheet.status.name === activeStatus || (sheet.status.name === StatusNames.SUBMITTED
                            && sheet.approved_level >= userApprovalLevel);
                    default:
                        return sheet.status.name === activeStatus;
                }
            }
            return false;
        });
};
export const selectTimeAndExpenseState = (state: IStore) => state.modules.timeAndExpense;

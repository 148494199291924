import React from 'react';
import { useField } from 'formik';
import { timeCounter } from 'shared/utils/counters/timeCounter';
import { timeFormatter } from 'shared/components/textField/time/timeFormatter';
import { IFormFieldProps } from 'shared/components/formFields/models';
import NumberInput from 'shared/components/formFields/NumberInput';
import { timeParserToInputValue } from 'shared/components/formFields/utils';
import { QuantityType } from 'shared/models/sheet/Sheet';

interface ITimeFieldProps extends IFormFieldProps {
    minutesField: string;
    hoursField: string;
    entryType: QuantityType;
    hideError: boolean;
}

const TimeField = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    minutesField = 'minutes',
    hoursField = 'hours',
    entryType = QuantityType.TIME,
    hideError = false,
}: ITimeFieldProps) => {
    const [field, meta, helper] = useField(name);

    const initialValue = field.value ? timeCounter(field.value?.[minutesField] || 0, field.value?.[hoursField] || 0) : '';

    const handleChange = (value: string) => {
        const parsedTime = timeParserToInputValue(value);
        if (parsedTime) {
            const { hours, minutes } = parsedTime;
            helper.setValue({
                ...(entryType === QuantityType.TIME_IN_OUT_BREAK && field.value ? field.value : {}),
                entry_type: entryType,
                [hoursField]: hours,
                [minutesField]: minutes,
            });
        }
    };
    return (
        <NumberInput
            key={initialValue}
            id={id}
            name={name}
            label={label}
            initialValue={initialValue}
            className={className}
            inputProps={{
                format: timeFormatter,
            }}
            disabled={disabled}
            error={hideError ? null : meta.error}
            touched={meta.touched}
            setTouched={helper.setTouched}
            onChange={handleChange}
        />
    );
};

export default TimeField;

export interface IUserFormValues {
    first_name: string;
    last_name: string;
    job_title: string;
    email: string;
    cell_phone: string;
    home_phone?: string;
    global_role_id: string | null;
    address: string;
    address2: string;
    city: string;
    state: string;
    county: string;
    zip_code: string;
}

export const defaultUserFormValues: IUserFormValues = {
    first_name: '',
    last_name: '',
    job_title: '',
    email: '',
    cell_phone: '',
    global_role_id: null,

    address: '',
    address2: '',
    city: '',
    state: '',
    county: '',
    zip_code: '',
};

import { selectAllControllingOrgs } from 'store/entities/configuration/configurationSelectors';
import { selectControllingOrgFilter } from 'modules/settings/submodules/clients/controllingOrg/components/Filter/store/selector';
import { createSelector } from 'reselect';

export const selectFilteredControllingOrgs = (
    createSelector(
        selectAllControllingOrgs,
        selectControllingOrgFilter,
        (controllingOrgs, controllingOrgFilter) => {
            if (!controllingOrgFilter){
                return controllingOrgs;
            }
            const controllingOrgFilterToLower = controllingOrgFilter.toLowerCase();
            return controllingOrgs.filter(org => {
                return org.name.toLowerCase().includes(controllingOrgFilterToLower)
                    || org.b_number.toLowerCase().includes(controllingOrgFilterToLower);
            });
        },
    )
);

import { v4 as uuidv4 } from 'uuid';
import {
    createOffer,
    OfferLetterActions,
    RESET_EMPLOYEE_LOOKUP, SET_LOOKUP_SIDEBAR_OPEN,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { lookupUsers, UsersActions } from 'store/entities/users/actions';

const defaultState = {
    isLoading: false,
    employeeIds: null,
    employeeHash: null,
    isLookupSidebarOpen: false,
};

function isLoading(
    state: boolean = defaultState.isLoading,
    action: UsersActions | OfferLetterActions,
): boolean {
    switch (action.type) {
        case lookupUsers.initType:
            return true;
        case lookupUsers.successType:
        case lookupUsers.errorType:
        case RESET_EMPLOYEE_LOOKUP:
            return false;
        default:
            return state;
    }
}

function employeeIds(
    state: string[] | null = defaultState.employeeIds,
    action: UsersActions | OfferLetterActions,
): string[] | null {
    switch (action.type) {
        case lookupUsers.initType:
        case lookupUsers.errorType:
        case RESET_EMPLOYEE_LOOKUP:
            return defaultState.employeeIds;
        case lookupUsers.successType:
            return action.payload.map(item => item.identity_id);
        default:
            return state;
    }
}

function employeeHash(
    state: string | null = defaultState.employeeHash,
    action: UsersActions | OfferLetterActions,
): string | null {
    switch (action.type) {
        case lookupUsers.initType:
        case lookupUsers.errorType:
        case RESET_EMPLOYEE_LOOKUP:
        case lookupUsers.successType:
            return uuidv4();
        default:
            return state;
    }
}

export function isLookupSidebarOpen(state = defaultState.isLookupSidebarOpen, action: OfferLetterActions): boolean {
    switch (action.type) {
        case SET_LOOKUP_SIDEBAR_OPEN:
            return action.payload;
        case RESET_EMPLOYEE_LOOKUP:
        case createOffer.successType:
            return false;
        default:
            return state;
    }
}

export const lookup = combineReducers({
    isLoading,
    employeeIds,
    employeeHash,
    isLookupSidebarOpen,
});

export type LookupEmployeeState = ReturnType<typeof lookup>;

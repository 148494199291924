import React from 'react';
import { EntryType } from 'shared/models/sheet/Sheet';
import FilterButton from 'shared/components/filters/FilterButtonAndForm/FilterButton';
import {
    selectWorkingSheetFiltersConfig,
    selectWorkingSheetsFilters,
} from 'store/components/workingSheets/workingSheetsSelectors';
import { setSheetsGridFilters } from 'store/components/workingSheets/workingSheetsActions';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import FilterButtonMock from 'shared/components/filters/FilterButtonAndForm/FilterButtonMock';

interface IFilterButtonProps {
    activeEntryType?: EntryType;
    className?: string;
}

export function SheetsInProgressFilters({ activeEntryType, className }: IFilterButtonProps) {
    const [filtersFeature] = useFeature(FeatureSwitches.displayFilters);
    return filtersFeature ? (
        <FilterButton className={className} entryType={activeEntryType}
            selectFilters={selectWorkingSheetsFilters} selectFiltersConfig={selectWorkingSheetFiltersConfig}
            submitFilters={setSheetsGridFilters}
        />
    ) : (
        <FilterButtonMock className={className}/>
    );
}

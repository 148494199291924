import { TimeHoursMinutes, TimeRFC3339 } from './sheet/Sheet';
import moment, { Moment } from 'moment';
import { backendDateFormat, DateBackend } from 'shared/models/Dates';
import { padStart } from 'lodash';

export const TIME_FORMAT = 'H:mm';
export const TIME_FORMAT_FULL = 'HH:mm';
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const CLOCK_TIME_FORMAT = 'h:mmA';

interface ITransformTimeToBackendDateTimeProps {
    time: TimeHoursMinutes;
    date: DateBackend;
    timeFormat?: string;
    dateFormat?: string;
}

export const transformTimeToBackendDateTime = ({
    time,
    date,
    timeFormat = TIME_FORMAT,
    dateFormat = backendDateFormat,
}: ITransformTimeToBackendDateTimeProps): TimeRFC3339 => {
    const timeMoment = moment(time, timeFormat);
    const dateMoment = moment(date, dateFormat);
    return dateMoment.hours(timeMoment.hours()).minutes(timeMoment.minutes()).format(BACKEND_DATE_TIME_FORMAT);
};

export const getMomentFromBackendDateTime = (dateTime: TimeRFC3339): Moment => (
    moment(dateTime, BACKEND_DATE_TIME_FORMAT)
);

export const transformBackendDateTimeToTime = (dateTime: TimeRFC3339): string => (
    moment(dateTime, BACKEND_DATE_TIME_FORMAT).format(CLOCK_TIME_FORMAT)
);

export const transformClockTimeToTime = (time: string): string => (
    moment(time, CLOCK_TIME_FORMAT).format(TIME_FORMAT_FULL)
);

export const transformTimeToClock = (time: string): string => (
    moment(time, TIME_FORMAT_FULL).format(CLOCK_TIME_FORMAT)
);

export const transformBackendDateTimeToFormTime = (dateTime: TimeRFC3339): string => (
    moment(dateTime, BACKEND_DATE_TIME_FORMAT).format(TIME_FORMAT_FULL)
);

export const printMinutes = (minutes: number) => moment().minutes(minutes).format('mm');

export const printTimeByHours = (hours: number, minutes: number, format: string = TIME_FORMAT) =>
    moment().hours(hours).minutes(minutes)
        .format(format);

const getDurationParts = (duration: moment.Duration) => {
    // unfortunately duration doesn't have format method besides https://github.com/jsmreese/moment-duration-format
    return {
        hours: Math.floor(duration.asHours()),
        minutes: duration.minutes() + (duration.seconds() >= 30 ? 1 : 0),
    };
};

const printDuration = (duration: moment.Duration) => {
    const { hours, minutes } = getDurationParts(duration);
    return printTimeByHours(hours, minutes);
};

const calculateDurationWithFormat = (
    startValue: TimeRFC3339,
    endValue: TimeRFC3339,
    format: string = BACKEND_DATE_TIME_FORMAT,
) => {
    return moment.duration(moment(endValue, format).diff(moment(startValue, format)));
};

export const getDurationFromDateTimes = (startValue: TimeRFC3339, endValue: TimeRFC3339, breakMinutes = 0) => {
    const duration = calculateDurationWithFormat(startValue, endValue);
    if (breakMinutes) {
        duration.subtract(breakMinutes, 'minutes');
    }
    return getDurationParts(duration);
};

export const getDurationFromTime = (startValue: TimeHoursMinutes, endValue: TimeHoursMinutes) => {
    const duration = calculateDurationWithFormat(startValue, endValue, TIME_FORMAT_FULL);
    return getDurationParts(duration);
};

export const printDurationFromDateTimes = (startValue: TimeRFC3339, endValue: TimeRFC3339, breakMinutes = 0) => {
    const duration = calculateDurationWithFormat(startValue, endValue);
    if (breakMinutes) {
        duration.subtract(breakMinutes, 'minutes');
    }
    return printDuration(duration);
};

export const calculateDurationFromStartEndValue = (startValue: string, endValue: string) => {
    const duration = moment.duration(moment(endValue, TIME_FORMAT).diff(moment(startValue, TIME_FORMAT)));
    return printDuration(duration);
};

export const compareInOutTimeValue = (first: string, second: string, format: string = TIME_FORMAT) => {
    const firstMoment = moment(first, format);
    const secondMoment = moment(second, format);
    switch (true) {
        case firstMoment.isBefore(secondMoment):
            return 1;
        case firstMoment.isAfter(secondMoment):
            return -1;
        default:
            return 0;
    }
};

export interface ITimeByUnits {
    hours: number;
    minutes: number;
}

/**
 * Parse hours & minutes from string 'h:mm'
 * @param {string} time
 * @return {ITimeByUnits}
 */
export const parseTimeUnitsFromString = (time: TimeHoursMinutes): ITimeByUnits => {
    const [hours = 0, minutes = 0] = time
        ?.split(':')
        .map(x => (parseInt(x, 10)));
    return { hours, minutes };
};

export const getMinutesByTimeUnits = ({ hours, minutes }: ITimeByUnits) => hours * 60 + minutes;

export const parseTimeUnitsFromMinutes = (minutes: number): ITimeByUnits => ({
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
});

export function formatDecimalHoursStringAsHoursAndMinutes(hoursDecimal: string): string {
    return formatDecimalHoursAsHoursAndMinutes(parseFloat(hoursDecimal));
}

export function formatDecimalHoursAsHoursAndMinutes(hoursDecimal: number): string {
    const duration = moment.duration(hoursDecimal, 'hours');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes() + (duration.seconds() >= 30 ? 1 : 0);
    return `${hours}:${padStart(minutes.toString(), 2, '0')}`;
}

export const emptyFormattedTime = '0:00';

import React, { useMemo } from 'react';
import { moment, maxMomentDate } from 'utils/momentExtensions';
import FormSelect from 'shared/components/selects/FormSelect';
import { useSelector } from 'react-redux';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { IFormFieldProps } from '../formFields/models';
import { selectJobNumbersById } from 'store/entities/configuration/configurationSelectors';
import { IFormSelect } from './model';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { IJobNumber } from 'shared/models/JobNumber';

interface IJobNumbersProps extends IFormFieldProps, IFormSelect {
    userId?: string;
    payPeriod?: IPayPeriod;
}

interface IJobNumbersStoreProps {
    options: IJobNumber[];
}

export function JobNumbersPure({
    options,
    disabled,
    ...props
}: IJobNumbersProps & IJobNumbersStoreProps) {

    return (
        <FormSelect
            {...props}
            getKey={({ id }) => id}
            getText={({ job_number }) => job_number}
            options={options}
            disabled={disabled || !options.length}
        />
    );
}

export const isDayInJobNumberPeriod = (jobNumber: IJobNumber) =>
    (day: moment.Moment): boolean => day.isSameOrAfter(jobNumber.start_date, 'day')
        && (!jobNumber.end_date || day.isSameOrBefore(jobNumber.end_date, 'day'));

export const useJobNumbersForUser = (userId?: string, payPeriod?: IPayPeriod) => {
    const jobNumbers = useSelector(selectJobNumbersById);
    return useMemo(() => {
        let filteredJobNumbers = Object.values(jobNumbers);
        if (userId) {
            filteredJobNumbers = filteredJobNumbers.filter(jobNumber => jobNumber.user_id === userId);
        }
        if (payPeriod) {
            const momentPayPeriod = moment.range(moment(payPeriod?.period_start), moment(payPeriod?.period_end));
            filteredJobNumbers = filteredJobNumbers.filter(
                jobNumber => {
                    const jobNumberEnd = jobNumber.end_date ? moment(jobNumber.end_date) : maxMomentDate;
                    const jobNumberPeriod = moment.range(moment(jobNumber.start_date), jobNumberEnd);
                    return jobNumberPeriod.intersect(momentPayPeriod);
                },
            );
        }
        return filteredJobNumbers;
    }, [userId, jobNumbers, payPeriod]);
};

export const JobNumbers = separateLogicDecorator<
IJobNumbersProps,
IJobNumbersStoreProps
>(({ userId, payPeriod }) => {
    const jobNumbersForUser = useJobNumbersForUser(userId, payPeriod);
    return { options: jobNumbersForUser };
})(JobNumbersPure);

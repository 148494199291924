import { Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { grey } from '@material-ui/core/colors';
import { colors } from 'shared/styles/constants';

export const useUploadStyles = makeHighPriorityStyles((theme: Theme) => {
    return {
        fileUpload: {
            marginBottom: theme.spacing(2),
        },
        fileDropzone: {
            width: '100%',
            height: theme.spacing(12),
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: grey['500'],
            backgroundColor: grey['100'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '&:hover': {
                backgroundColor: `${fade(theme.palette.primary.main, 0.14)}`,
            },
            cursor: 'pointer',
        },
        error: {
            borderColor: colors.red,
            backgroundColor: fade(colors.red, 0.1),
        },
        uploadHeader: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        uploadHeaderTitle: {
            textTransform: 'uppercase',
            marginLeft: theme.spacing(1),
        },
        uploadError: {
            color: colors.red,
        },
        uploadIcon: {
            color: colors.secondary,
        },
        preview: {
            padding: theme.spacing(0, 11),
        },
        filePreviewZone: {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        imageButton: {
            bottom: theme.spacing(6),
            float: 'right',
            right: theme.spacing(1),
            color: colors.white,
            backgroundColor: colors.darkGray,
            borderRadius: theme.spacing(1),
            '&:hover': {
                backgroundColor: colors.lightGray,
                color: colors.black,
            },
        },
        paper: {
            backgroundColor: 'inherit',
            overflow: 'inherit',
            height: '100%',
            display: 'flex',
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                boxShadow: 'none',
            },
        },
        root: {
            flexGrow: 1,
        },
        header: {
            width: '100%',
            textAlign: 'center',
            boxSizing: 'border-box',
            border: `10px solid ${colors.white}`,
            borderBottom: 0,
            backgroundColor: colors.white,
        },
        imageContainer: {
            maxWidth: '100%',
            height: 'auto',
            minHeight: '50%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            background: colors.white,
        },
        img: {
            overflow: 'hidden',
            display: 'block',
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            boxSizing: 'border-box',
            objectFit: 'fill',
        },
        previewImg: {
            border: `${theme.spacing(1.2)}px solid ${colors.white}`,
        },
        closeButton: {
            position: 'fixed',
            right: theme.spacing(4),
            top: theme.spacing(1),
            color: colors.white,
            [theme.breakpoints.down('sm')]:
             {
                 right: theme.spacing(0),
                 top: theme.spacing(0),
             },
        },
        middleContainer: {
            marginTop: theme.spacing(10),
            width: '50%',
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
            },
        },
        stepContainer: {
            width: '25%',
            color: colors.white,
            marginTop: theme.spacing(40),
            [theme.breakpoints.down('sm')]: {
                width: '15%',
            },
        },
        pullLeft: {
            float: 'left',
            marginLeft: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                marginLeft: theme.spacing(0),
                float: 'left',
            },
        },
        pullRight: {
            float: 'right',
            marginRight: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(0),
                float: 'right',

            },
        },
        dialog: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
        },
        iconButton: {
            color: colors.white,
            display: 'flex',
            justifyContent: 'flex-end',
            flexGrow: 1,
        },
        bottomBar: {
            position: 'absolute',
            bottom: 0,
            padding: theme.spacing(1, 0),
            margin: theme.spacing(1.2),
            boxSizing: 'border-box',
            width: 'calc(100% - 19px)',
            backgroundColor: fade(colors.black, 0.5),
            display: 'flex',
        },
        imageTitle: {
            color: colors.white,
            marginLeft: theme.spacing(2),
            width: '80%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('sm')]: {
                width: '70%',
            },
        },
        stepActions: {
            fontSize: 70,
            [theme.breakpoints.down('sm')]: {
                fontSize: 40,
            },
        },
        closeAction: {
            fontSize: 40,
        },
        buttonRoot: {
            margin: 0,
            padding: 0,
            minWidth: 0,
        },
        bottomActions: {
            cursor: 'pointer',
            marginRight: theme.spacing(1),
            fontSize: 20,
            color: colors.white,
        },
        previewFiles: {
            width: 'inherit',
            height: 'inherit',
        },
        pdfPreview: {
            color: colors.red,
            fontSize: 200,
        },
        note: {
            margin: theme.spacing(0, 8),
            textAlign: 'center',
            fontSize: 11,
        },
        loader: {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        underLoading: {
            opacity: 0.2,
        },
    };
});

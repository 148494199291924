import React, { FC, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import Total, { TotalTypes } from 'shared/components/toolbar/total/Total';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { EntryType } from 'shared/models/sheet/Sheet';
import useTotalInfoTabStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/totalInfoTab/TotalInfoTabStyles';

interface ITotalInfoTabProps {
    time: string;
    timeBreak?: string;
    amount: string;
    completes: number;
    activeEntry?: EntryType;
}

const TotalInfoTab: FC<ITotalInfoTabProps> = ({ time, timeBreak, amount, activeEntry }: ITotalInfoTabProps) => {
    const [chosenTab, setChosenTab] = useState<null | EntryType>(EntryType.TIME);
    const onChange = (type: EntryType | null) => {
        if (!activeEntry) {
            setChosenTab(type);
        }
    };
    const classes = useTotalInfoTabStyles();

    useEffect(() => {
        setChosenTab(activeEntry ? activeEntry : EntryType.TIME);
    }, [activeEntry]);

    return (
        <Box className={classes.container}>
            {(chosenTab === EntryType.TIME) && (
                <Box onClick={() => onChange(EntryType.EXPENSE)} className={classes.totalItem}>
                    <Total value={time} type={TotalTypes.Time}/>
                    {typeof timeBreak === 'string' && (
                        <Total
                            value={timeBreak}
                            type={TotalTypes.Break}
                            classes={{ root: classes.totalItemSecondary }}
                        />
                    )}
                </Box>
            )}
            {chosenTab === EntryType.EXPENSE && (
                <Box onClick={() => onChange(EntryType.TIME)} className={classes.totalItem}>
                    <Total value={amount} type={TotalTypes.Expense}/>
                </Box>
            )}
            <Box className={classes.containerFiber}>
                {!activeEntry && chosenTab === EntryType.TIME && (
                    <>
                        <FiberManualRecordRoundedIcon className={classes.fiber}/>
                        <FiberManualRecordOutlinedIcon className={classes.fiber}/>
                    </>
                )}
                {!activeEntry && chosenTab === EntryType.EXPENSE && (
                    <>
                        <FiberManualRecordOutlinedIcon className={classes.fiber}/>
                        <FiberManualRecordRoundedIcon className={classes.fiber}/>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default TotalInfoTab;

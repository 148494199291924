import { makeStyles } from '@material-ui/core';

export const usePaginationCounterStyles = makeStyles(() => ({
    paginationCounter: {
        fontSize: '10pt',
        color: 'gray',
    },
    paginationCounterShown: {
        marginRight: '15px',
    },
}));

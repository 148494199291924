import { createSelector } from 'reselect';
import { selectCostCenterFilter } from 'modules/settings/submodules/clients/costCenters/components/Filter/store/selector';
import { selectCostCenters } from 'store/entities/configuration/configurationSelectors';

export const selectFilteredCostCenters = (
    createSelector(
        selectCostCenters,
        selectCostCenterFilter,
        (costCenters, costCenterFilter) => {
            if (!costCenterFilter){
                return costCenters;
            }
            const costCenterFilterToLower = costCenterFilter.toLowerCase();
            return costCenters.filter(costCenter => {
                return costCenter.number.toString().includes(costCenterFilterToLower)
                    || costCenter.description.toLowerCase().includes(costCenterFilterToLower);
            });
        },
    )
);

import { OptimizelyFeature } from '@optimizely/react-sdk';
import React from 'react';
import { FeatureSwitches } from 'utils/featureSwitches';

export interface IFeatureABTestProps {
    aComponent: React.ReactNode;
    bComponent: React.ReactNode;
    feature: FeatureSwitches;
}

export default function FeatureABTest({ aComponent, feature, bComponent }: IFeatureABTestProps) {
    return (
        <OptimizelyFeature feature={feature}>
            {isEnabled => isEnabled ? aComponent : bComponent}
        </OptimizelyFeature>
    );
}

import ReactGA from 'react-ga';
import { browserHistory } from 'shared/utils/browserHistory';

const analyticsKey: string = process.env.REACT_APP_GA_TRACKER as string;

class Analytics {
    initialized = false;

    constructor() {
        if (analyticsKey) {
            ReactGA.initialize(analyticsKey, {
                titleCase: false,
            });
            this.initialized = true;
        }
    }

    listen() {
        if (this.initialized) {
            this.view(browserHistory.location.pathname);
            browserHistory.listen(location => this.view(location.pathname));
        }
    }

    view(page: string) {
        if (this.initialized) {
            ReactGA.pageview(page);
        }
    }
}

export default new Analytics();

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { browserHistory } from 'shared/utils/browserHistory';
import { ILogger } from 'shared/utils/logging/logger';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APP_INSIGHT_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
appInsights.loadAppInsights();

const trackErrorEvent = (severity: SeverityLevel) => (error: any, errorInfo?: any) => {
    appInsights.trackException({
        error: error,
        exception: error,
        severityLevel: severity,
        properties: errorInfo,
    });
};

export const appInsightLogger: ILogger = {
    critical: trackErrorEvent(SeverityLevel.Critical),
    error: trackErrorEvent(SeverityLevel.Error),
    warning: trackErrorEvent(SeverityLevel.Warning),
    info: trackErrorEvent(SeverityLevel.Information),
};

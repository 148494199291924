import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { sumBy } from 'lodash';
import {
    IPayrollTimeExpenseRow,
    IPayrollExpenseRowGroup,
} from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/SheetEntryTable';
import {
    IExpenseCalculationEntry,
    IGroupedSheetCalculation,
    ITimeCalculationEntry,
} from 'modules/payrollProcessorHub/store/model';
import { selectPayCodesById } from 'modules/settings/submodules/clients/payCodes/store/selectors';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getProjectAssignmentByProjectAndAssignmentIds } from 'shared/utils/helpers/entries';
import { getTotalHours } from 'shared/utils/helpers/hoursCalculations';
import { selectActivitiesById, selectProjectsWithAssignments, selectTasksById } from 'store/entities/configuration/configurationSelectors';

const usePayrollExpenseRowGroupedByDay = (
    entries: ITimeCalculationEntry[] | IExpenseCalculationEntry[],
    type: EntryType,
): Record<string, IPayrollTimeExpenseRow[]> => {
    const paycodesById = useSelector(selectPayCodesById);
    const activitiesById = useSelector(selectActivitiesById);
    const projectAssignments = useSelector(selectProjectsWithAssignments);
    const tasksById = useSelector(selectTasksById);

    return useMemo(() => {
        const entriesByDay: Record<string, IPayrollTimeExpenseRow[]> = {};
        entries.forEach((entry: ITimeCalculationEntry | IExpenseCalculationEntry) => {
            const activity = activitiesById[entry?.activity_id];
            const projectAssignment = getProjectAssignmentByProjectAndAssignmentIds(
                projectAssignments,
                entry.project_id,
                entry.assignment_id,
            );
            (entriesByDay[entry.entry_date] = (entriesByDay[entry.entry_date] || [])).push({
                ...entry,
                activity,
                payCode: paycodesById[entry?.client_pay_code_id],
                hours: type === EntryType.TIME ? (entry as ITimeCalculationEntry).hours?.toString() : '',
                amount: type === EntryType.EXPENSE ? (entry as IExpenseCalculationEntry).total_dollars?.toString() : '',
                projectAssignment,
                task: tasksById[entry?.task_id],
            });
        });
        return entriesByDay;
    }, [type, entries, paycodesById, activitiesById]);
};

const calculateTotalValuesByDays = (
    entriesByDay: Record<string, IPayrollTimeExpenseRow[]>,
): IPayrollExpenseRowGroup[] => {
    const result: IPayrollExpenseRowGroup[] = [];
    Object.keys(entriesByDay).sort().forEach(date => {
        const items = entriesByDay[date];
        const entryDate = moment(date);
        result.push({
            items,
            entryDate,
            totalHours: getTotalHours(items.map(item => item.hours)),
            totalAmount: sumBy(items, (item: IPayrollTimeExpenseRow) => parseFloat(item.amount)).toFixed(2).toString(),
        });
    });
    return result;
};

export const useGroupedPayrollEntries = (
    sheetGroup: IGroupedSheetCalculation,
    type: EntryType,
): IPayrollExpenseRowGroup[] => {
    const entriesByDay = usePayrollExpenseRowGroupedByDay(
        type === EntryType.TIME ? sheetGroup.time_entries : sheetGroup.expense_entries,
        type,
    );

    return useMemo(() => {
        return calculateTotalValuesByDays(entriesByDay);
    }, [entriesByDay]);
};

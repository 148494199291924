import { ItemsById } from 'shared/models/ItemsById';
import { IClientRole, IGlobalRole } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import { PrismUserType, UserFeature } from 'store/entities/users/model';
import { IStore } from '../../configureStore';
import { IUsersById } from './reducers';

export const selectUserById = (id?: string) => (state: IStore) => id ? state.users.usersById[id] : undefined;
export const selectUsersById = (state: IStore): IUsersById => state.users.usersById;
export const selectUsers = (state: IStore) => Object.values(state.users.usersById);
export const selectUsersByFeature = (feature: UserFeature, value: Permission | PrismUserType) =>
    (state: IStore) => {
        const userIds: string[] = (() => {
            switch (feature) {
                case 'purpose':
                    return state.users.userIdsByPurpose?.[value as Permission] || [];
                case 'permission':
                    return state.users.userIdsByPermission?.[value as Permission] || [];
                case 'prism_user_type':
                    return state.users.userIdsByPrismUserType?.[value as PrismUserType] || [];
            }
        })();
        return userIds.map((id: string) => selectUserById(id)(state));
    };
export const selectSearchResultUsers = (searchKey: string) => (state: IStore) => {
    return state.users.userSearchResultIds[searchKey]?.map(id => selectUserById(id)(state)) || [];
};
export const selectIsUserSearching = (searchKey: string) => (state: IStore) => {
    return state.users.isSearching[searchKey] || false;
};
export const selectGlobalRoles = (state: IStore): IGlobalRole[] => {
    return Object.values(state.users.globalRolesById);
};
export const selectClientRolesById = (state: IStore): ItemsById<IClientRole> => {
    return state.users.clientRolesById;
};
export const selectClientRoles = (state: IStore): IClientRole[] => {
    return Object.values(selectClientRolesById(state));
};

import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import { IDepartmentFormValues } from 'modules/settings/submodules/clients/departments/components/DepartmentForm/model';
import { createDepartment, setCreateDepartmentModalState } from 'modules/settings/submodules/clients/departments/store/actions';
import { selectIsCreateDepartmentModalOpened, selectIsDepartmentCreating } from 'modules/settings/submodules/clients/departments/store/selectors';
import DepartmentFormWrapper from 'modules/settings/submodules/clients/departments/components/DepartmentForm/DepartmentFormWrapper';
import { FormMode } from 'shared/models/FormMode';

export default function CreateDepartmentModal() {
    const dispatch = useDispatch();
    const modalClasses = useSettingsFormModalStyles();
    const isOpen = useSelector(selectIsCreateDepartmentModalOpened);
    const isLoading = useSelector(selectIsDepartmentCreating);

    const onSave = useCallback((values: IDepartmentFormValues) => {
        dispatch(createDepartment.init(values));
    }, [dispatch]);
    const onClose = useCallback(() => {
        dispatch(setCreateDepartmentModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Create New Department"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: modalClasses,
                showCloseIcon: true,
            }}
        >
            <DepartmentFormWrapper
                submitTitle={'Add department'}
                onSubmit={onSave}
                onCancel={onClose}
                isLoading={isLoading}
                mode={FormMode.New}
            />
        </ModalDialog>
    );
}

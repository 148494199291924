import { createSingleAction } from 'store/utils';
import {
    IEmployeeSubmittedSheets,
    ISheetDetail,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsModel';

export const SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS = 'SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS';

export const setEmployeeSubmittedSheetsFilters = createSingleAction<
Partial<IEmployeeSubmittedSheets>,
    typeof SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS
>(
    SET_EMPLOYEE_SUBMITTED_SHEETS_FILTERS,
);

export const SET_RESUBMIT_SHEET = 'SET_RESUBMIT_SHEET';

export const setResubmitSheet = createSingleAction<
ISheetDetail | null,
typeof SET_RESUBMIT_SHEET
>(
    SET_RESUBMIT_SHEET,
);

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setHeaderTitle } from 'store/entities/appConfig/actions';

const useTitleUpdate = (desktopHeader: string, mobileHeader: string = desktopHeader) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setHeaderTitle({ mobileHeader, desktopHeader }));
    }, [dispatch, mobileHeader, desktopHeader]);
};

export default useTitleUpdate;

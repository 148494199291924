import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import { BackgroundCheckPage } from 'modules/settings/submodules/clients/backgroundCheckTemplates/BackgroundCheckPage';
import { DepartmentsPage } from 'modules/settings/submodules/clients/departments/DepartmentsPage';
import React, { useEffect } from 'react';
import { Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { ClientActivitiesList } from 'modules/settings/submodules/clients/activities/ClientActivitiesList';
import { ClientDetailSideMenu } from 'modules/settings/submodules/clients/ClientDetailSideMenu';
import { ClientPayCodesList } from 'modules/settings/submodules/clients/payCodes/ClientPayCodesList';
import { ClientPositionsList } from 'modules/settings/submodules/clients/positions/ClientPositionsList';
import { PositionWorkLocationAssociationList } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/PositionWorkLocationAssociationList';
import { ClientWorkLocationList } from 'modules/settings/submodules/clients/workLocation/ClientWorkLocationList';
import { settingsRoutes } from 'modules/settings/routes';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { ClientSettingsDashboard } from 'modules/settings/submodules/clients/ClientSettingsDashboard';
import { ClientEditPage } from 'modules/settings/submodules/clients/components/EditClient/ClientEditPage';
import { PhysicalDemandsPage } from 'modules/settings/submodules/clients/physicalDemands/PhysicalDemandsPage';
import MainContent from 'shared/components/common/MainContent';
import { useIsMobile } from 'shared/utils/hooks/media';
import { setClientId } from 'store/entities/clients/clientsAction';
import { useStyles } from './styles';
import { WorkingConditionsPage } from 'modules/settings/submodules/clients/workingConditions/WorkingConditionsPage';
import { PaySettingsPage } from 'modules/settings/submodules/clients/paySettings/PaySettingsPage';
import { AppConfigurationPage } from '../appConfiguration/AppConfigurationPage';
import { ControllingOrgPage } from 'modules/settings/submodules/clients/controllingOrg/ControllingOrgPage';
import { SubmittingOrgPage } from 'modules/settings/submodules/clients/submittingOrg/SubmittingOrgPage';
import { CostCentersPage } from 'modules/settings/submodules/clients/costCenters/CostCentersPage';
import { DealsPage } from 'modules/settings/submodules/clients/deals/DealsPage';
import { JobNumberPage } from 'modules/settings/submodules/clients/jobNumber/JobNumberPage';
import { EditClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/EditClientLogo';

export const ClientsSettingsDetailsRoutes = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { clientId } = useParams<{ clientId: string }>();

    const isDashboard = useRouteMatch({
        path: settingsRoutes.CLIENTS.DASHBOARD,
        exact: true,
    });
    const isMobile = useIsMobile();

    useEffect(() => {
        dispatch(setClientId(clientId));
    }, [dispatch, clientId]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            className={classes.container}
        >
            {!isMobile && (isDashboard ? (<CommonSettingsSideMenu/>) : (<ClientDetailSideMenu/>))}
            <Box className={classes.content}>
                <MainContent whiteBackground>
                    <Switch>
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.GENERAL}
                            component={ClientEditPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.CONTROLLING_ORG}
                            component={ControllingOrgPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.SUBMITTING_ORG}
                            component={SubmittingOrgPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.COST_CENTERS}
                            component={CostCentersPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.DEALS}
                            component={DealsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.JOB_NUMBER}
                            component={JobNumberPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.APP_CONFIGURATION}
                            component={AppConfigurationPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.LOCATION}
                            component={ClientWorkLocationList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.POSITION}
                            component={ClientPositionsList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.POSITION_LOCATION_ASSOCIATIONS}
                            component={PositionWorkLocationAssociationList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PAYCODES}
                            component={ClientPayCodesList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PAY_SETTINGS}
                            component={PaySettingsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.ACTIVITIES}
                            component={ClientActivitiesList}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.PHYSICAL_DEMANDS}
                            component={PhysicalDemandsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.WORKING_CONDITIONS}
                            component={WorkingConditionsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.BACKGROUND_CHECKS}
                            component={BackgroundCheckPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.DEPARTMENTS}
                            component={DepartmentsPage}
                        />
                        <PrivateRoute
                            path={settingsRoutes.CLIENTS.DASHBOARD}
                            component={ClientSettingsDashboard}
                        />
                    </Switch>
                </MainContent>
                <EditClientLogo/>
            </Box>
        </Box>
    );
};

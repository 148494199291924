import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IClientUserAssociationFilter } from 'modules/settings/submodules/platformUsers/store/models';
import { selectClientUserAssociationFilter } from 'modules/settings/submodules/platformUsers/store/selectors';
import { useFiltersChipsStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChipsStyles';
import { setClientUserAssociationFilter } from 'modules/settings/submodules/platformUsers/store/actions';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import FilterChip
    from 'modules/settings/submodules/components/FilterChips/FilterChip';

export default function ClientUserAssociationFilterChips() {
    const dispatch = useDispatch();
    const classes = useFiltersChipsStyles();
    const filter = useSelector(selectClientUserAssociationFilter);

    const onDelete = useCallback((name: keyof IClientUserAssociationFilter) => {
        dispatch(setClientUserAssociationFilter({
            ...filter,
            [name]: undefined,
        }));
    }, [dispatch, filter]);

    const hasFilters = filter.global_role || filter.client || filter.client_role;

    return hasFilters ? (
        <Box
            mb={2}
            data-testid={FiltersTestIds.ChipsWrapper}
        >
            {filter.global_role && (
                <FilterChip
                    name="global_role"
                    label={filter.global_role.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}

            {filter.client && (
                <FilterChip
                    name="client"
                    label={filter.client.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}

            {filter.client_role && (
                <FilterChip
                    name="client_role"
                    label={filter.client_role.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}
        </Box>
    ) : null;
}

import { routes } from 'shared/routes';
import { IMenuCategory, TMenuCategoryChild } from 'shared/models/Global';
import { Permission } from 'store/components/auth/authModels';
import { FeatureSwitches } from 'utils/featureSwitches';
import { DescriptionOutlined, DnsRounded, AccessTime, GetAppOutlined } from '@material-ui/icons';
import { IGetHomeMenuParams } from './typings';
import { MenuItemEnum } from '../../../shared/models/MenuItem';
import { orderBy } from 'lodash';

export function getHomeMenu({
    clients,
    clientsThemes,
}: IGetHomeMenuParams): Array<IMenuCategory> {
    const menuItems: Array<TMenuCategoryChild> = [
        {
            title: MenuItemEnum.OfferLetter,
            Icon: DescriptionOutlined,
            to: routes.EMPLOYEE_OFFER_LETTER.ROOT,
            feature: FeatureSwitches.enableOfferLetters,
            permission: Permission.ViewMyOffers,
        },
    ];
    const clientsMenu = clients
        ? orderBy(clients, client => client.name).map(client => ({
            id: client.id,
            title: client.name,
            iconUrl: clientsThemes?.[client.id]?.theme?.logo_url,
            Icon: DnsRounded,
            to: `/client/${client.id}`,
        }))
        : [];

    return [
        {
            id: 'Common',
            children: menuItems,
        },
        {
            id: 'Clients',
            title: 'Clients',
            children: clientsMenu,
        },
        {
            id: 'Payroll',
            title: 'Payroll',
            permission: Permission.PPHReadAccess,
            children: [
                {
                    title: MenuItemEnum.PayrollDashboard,
                    Icon: GetAppOutlined,
                    to: routes.PAYROLL_PROCESSOR_HUB.ROOT,
                    isExact: true,
                },
                {
                    title: MenuItemEnum.TimeExpensesManagement,
                    Icon: AccessTime,
                    to: routes.PAYROLL_PROCESSOR_HUB.SHEETS,
                },
            ],
        },
    ];
}

import { IDepartment } from 'modules/employmentInfo/models/Department';
import { IActivity, IProjectWithAssignment } from 'store/entities/configuration/configurationModel';
import { IPosition } from 'shared/models/Position';
import { ILocation } from 'shared/models/Location';
import { IJobNumber } from '../../../models/JobNumber';

export interface ICommonEntryFormValues {
    projectAssignment: IProjectWithAssignment | null;
    taskId: string;
    jobNumber: IJobNumber | null;
    activity: IActivity | null;
    entry_date: string;
    position: IPosition | null;
    location: ILocation | null;
    department: IDepartment | null;
    notes?: string;
}

export const defaultValues: ICommonEntryFormValues = {
    projectAssignment: null,
    taskId: '',
    jobNumber: null,
    activity: null,
    entry_date: '',
    position: null,
    location: null,
    department: null,
};

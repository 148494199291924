import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { fade, Theme } from '@material-ui/core';
import { colors, drawerWidth, verticalMenuItemHeight } from 'shared/styles/constants';

const formBasicSpacing = 2.5;

export const useMenuStyles = makeHighPriorityStyles((theme: Theme) => ({
    logoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        overflowX: 'hidden',
    },
    logoImage: {
        width: theme.spacing(16),
        minWidth: theme.spacing(16),
        alignSelf: 'center',
    },
    chevronMargin: {
        marginRight: theme.spacing(-0.5),
    },
    list: {
        '& > *': {
            overflow: 'hidden',
        },
    },
    itemPrimary: {
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: 1.2,
        minWidth: drawerWidth - theme.spacing(4 + 3.5 + 3), // Outer spacing, logo icon 3.5 with spacing
    },
    itemPrimaryRoot: {
        margin: 0,
    },
    itemIcon: {
        color: colors.gray,
        minWidth: 'auto',
        marginRight: theme.spacing(2),
        alignItems: 'center',
        width: theme.spacing(3.5),
        height: 24,

        '& svg': {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },

    },
    item: {
        cursor: 'pointer',
        padding: theme.spacing(1, 2),
        minHeight: theme.spacing(verticalMenuItemHeight),
        color: colors.whiteSecondary,
        transition: theme.transitions.create('background-color', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.short,
        }),
        '&:hover, &:focus, &.active': {
            backgroundColor: fade(colors.gray, 0.3),
        },
        '&.active': {
            paddingLeft: `${theme.spacing(2) - 7}px`,
            borderLeft: `7px solid ${colors.red}`,

            '& $itemPrimary': {
                fontWeight: 500,
            },
        },

        '&:hover, &.active': {
            '& $itemPrimary': {
                color: colors.white,
            },
            '& $itemIcon': {
                color: colors.lightGray,
            },
        },
    },
    itemCategory: {
        borderBottom: `1px solid ${colors.darkGray}`,
    },
    tenantItem: {
        height: 'unset',
        flexDirection: 'column',
    },
    subtitle: {
        color: colors.gray,
        fontSize: 12,
        padding: theme.spacing(2, 2, 1),
    },
    expandButton: {
        height: 20,
        width: 20,
    },
    tenantMinimized: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    tenantMinimizedOpened: {
        '&&, &&:hover': {
            overflow: 'visible',
            width: theme.spacing(29),
            backgroundColor: colors.darkerGray,
        },
        '& svg': {
            position: 'absolute',
            right: theme.spacing(1),
        },
    },
    tenantNameText: {
        margin: 0,
        '& > span': {
            width: 152,
        },
    },
    tenantNameTextMinimizedOpened: {
        margin: theme.spacing(-7.5, 1, 4.5, 0),
    },
    logoItem: {
        display: 'flex',
        alignItems: 'flex-start',
        height: 64,
    },
    paper: {
        width: theme.spacing(31),
        backgroundColor: colors.blackSecondary,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    tenantNameItem: {
        '& > img': {
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
    },
    sidebarMinimized: {
        width: theme.spacing(7),
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: theme.spacing(0.5),
        },
        '&::-webkit-scrollbar-track': {
            display: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            minHeight: theme.spacing(5),
            borderRadius: theme.spacing(0.8),
            backgroundClip: 'padding-box',
            backgroundColor: fade(colors.gray, 0.5),
        },
    },
    bottomMenu: {
        marginTop: 'auto',
        width: '100%',
        bottom: 0,
        left: 0,
    },
    minimizedLogoWrapper: {
        padding: theme.spacing(2, 1, 2, 1),
    },
    hideMenuButton: {
        padding: 0,
    },
    pickerContainer: {
        display: 'flex',
        margin: theme.spacing(2, 0, 0, 10),
        flexDirection: 'column',
        width: '100%',

        '& > a, & > a:visited': {
            color: colors.white,
            textDecoration: 'none',
            outline: 0,
            fontSize: 12,

            '& + *': {
                marginTop: theme.spacing(1),
            },
        },
    },
    pickerContainerMinimized: {
        border: `1px solid ${colors.darkGray}`,
        width: `${theme.spacing(18) - 2}px`,
        backgroundColor: colors.darkerGray,
        position: 'absolute',
        margin: theme.spacing(6, 0, 0, 7),
        padding: theme.spacing(2),
        left: 0,
    },
    payrollButton: {
        margin: theme.spacing(2, 2),
    },
    payrollButtonMinimized: {
        margin: 0,
        minWidth: theme.spacing(7),
    },
    payrollButtonIcon: {
        margin: theme.spacing(0, 1, 0, 0),
    },
    payrollButtonIconMinimized: {
        margin: 0,
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white !important',
    },
    input: {
        margin: `${theme.spacing(formBasicSpacing, formBasicSpacing, 0)} !important`,
        minHeight: theme.spacing(5),
    },
    inputMinimized: {
        margin: `${theme.spacing(1, 0.5)} !important`,
        width: theme.spacing(6),
    },
    avatar: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
        fontSize: 'large',
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    expandMenuButton: {
        color: colors.gray,
        padding: theme.spacing(1, 2),
        marginBottom: theme.spacing(1),
        fontSize: 12,
    },
}));

import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { IPosition } from 'shared/models/Position';
import { IFormFieldProps } from '../formFields/models';
import {
    selectPositions,
} from 'store/entities/configuration/configurationSelectors';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';

import { IFormSelect } from './model';

interface IPositionProps extends IFormFieldProps, IFormSelect {}

export const Position = (props: IPositionProps) => {
    const allPositions = useSelector(selectPositions);
    const selectedClientId = useSelector(selectCurrentClientId);
    const positions = allPositions.filter(position => position.client_id === selectedClientId);

    return (
        <FormSelect
            getKey={(position: IPosition) => position.id}
            getText={(position: IPosition) => position.name}
            options={positions}
            {...props}
        />
    );
};

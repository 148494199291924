import { takeLatest, select } from 'typed-redux-saga';
import { getOwnConfiguration } from 'store/components/addEntry/addEntryActions';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/clientsSelectors';
import { EntrySlug } from 'store/entities/clients/clientsModel';
import { getOwnConfigurationGenworthSaga } from './sagas/addEntryGenworthSagas';
import { getOwnConfigurationBaseSaga } from './sagas/addEntryBaseSagas';
import { optimizely } from 'utils/optimizely';
import { FeatureSwitches } from 'utils/featureSwitches';
import { showField } from '../../../shared/components/forms/utils';

export function* getOwnConfigurationSaga() {
    const clientId = yield* select(selectCurrentClientId);
    const configuration = yield* select(selectFieldConfiguration(clientId || ''));
    if (configuration && showField(configuration.inputs.time, EntrySlug.JobNumber)) {
        if (optimizely.isFeatureEnabled(FeatureSwitches.enableGenworthSheets)) {
            yield* getOwnConfigurationGenworthSaga();
        }
    } else {
        yield* getOwnConfigurationBaseSaga();
    }
}

function* getOwnConfigurationWatcher() {
    yield* takeLatest(getOwnConfiguration.initType, getOwnConfigurationSaga);
}

export default [
    getOwnConfigurationWatcher,
];

/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import MobileListCell from 'shared/components/table/MobileListCell/MobileListCell';
import { useSelector } from 'react-redux';
import { useIsMobile } from 'shared/utils/hooks/media';
import PlainText from 'shared/components/table/Cells/PlainText';
import GridTable from 'shared/components/table/GridTable/GridTable';
import {
    selectIsPositionLocationAssociationFilter,
    selectIsPositionLocationAssociationLoading,
    selectPositionLocationAssociations,
} from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/selector';
import { IPositionWorkLocationAssociation } from 'modules/settings/submodules/clients/store/models';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { deletePositionWorkLocationAssociation } from 'modules/settings/submodules/clients/store/actions';

interface IAssociationRow extends IPositionWorkLocationAssociation {
    className?: string;
}

export const AssociationsTable = () => {
    const tableClasses = useDefaultTableStyles();
    const associations = useSelector(selectPositionLocationAssociations);
    const isLoading = useSelector(selectIsPositionLocationAssociationLoading);
    const filter = useSelector(selectIsPositionLocationAssociationFilter);
    const isMobile = useIsMobile();

    const filteredAssociations = useMemo(() => {
        return associations.filter(association => {
            let result = true;
            if (filter.position) {
                result = association.position.id === filter.position.id;
            }
            if (filter.location) {
                result = result && association.location.id === filter.location.id;
            }
            if (filter.unassociated) {
                result = result && (!association.location || !association.position);
            }
            return result;
        });
    }, [filter, associations]);

    const actionsCell = {
        key: 'actions',
        title: '',
        width: '100px',
        render: function DetailsCell({ className, id }: IAssociationRow) {
            return (
                <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                    <DeleteItemButton
                        confirmation="Are you sure you want to delete Position Work Location association?"
                        id={id}
                        deleteAction={deletePositionWorkLocationAssociation.init}
                    />
                </div>
            );
        },
    };

    const locationsCells = isMobile ? [
        {
            key: 'FullInfo',
            title: '',
            render: function FullInfoCell({ className, location, position }: IAssociationRow) {
                const items = [
                    `Work Location: ${location.name}`,
                    `Work Location: ${position.name}`,
                ];

                return (
                    <MobileListCell
                        className={className}
                        items={items}
                    />
                );
            },
        },
        actionsCell,
    ] : [
        {
            key: 'location',
            title: 'Work Location',
            render: ({ className, location }: IAssociationRow) => (
                <PlainText className={className} value={location.name}/>
            ),
        },
        {
            key: 'positions',
            title: 'Positions',
            render: ({ className, position }: IAssociationRow) => (
                <PlainText className={className} value={position.name}/>
            ),
        },
        actionsCell,
    ];

    return (
        <GridTable
            rowData={filteredAssociations}
            isLoading={isLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            className={tableClasses.tableBorder}
            getKey={client => client.id}
            cells={locationsCells}
            stickyHeader
            hideHeader={isMobile}
        />
    );
};

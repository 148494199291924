import React, { useCallback, useEffect, useMemo } from 'react';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { useTableStyles } from 'shared/styles/tableStyles';
import { useIsMobile } from 'shared/utils/hooks/media';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectEmployeeProfileStatusCheckingByClientId,
    selectMyEmployeeProfilesByClientIds,
    selectMyOfferLettersLoading,
    selectOfferLetterPdfIsAvailable,
    selectOfferLetterPdfIsLoading,
    selectOfferLetters,
} from 'modules/offerLetter/store/selectors';
import { Box, Typography } from '@material-ui/core';
import { useOfferLetterEmployeeStyles } from 'modules/offerLetter/OfferLetterEmployee/OfferLetterEmployeeStyles';
import { useEmployeeOfferCells } from 'modules/offerLetter/components/OfferLetterTable/employeeCells';
import { selectAllClientsById } from 'store/entities/clients/clientsSelectors';
import { checkOfferLetterPdf, getMyOfferLetters } from 'modules/offerLetter/store/actions';
import { setHeaderTitle } from 'store/entities/appConfig/actions';
import { OfferLetterStatusSlug } from '../store/model';
import { browserHistory } from 'shared/utils/browserHistory';
import { generatePath } from 'react-router';
import { routes } from 'shared/routes';
import { IOfferLetterWithPdf } from '../components/OfferLetterTable/cells';
import { openOfferLetterPdf, useNeedToBeOpenPdf } from '../store/utils';

export default function OfferLetterEmployee() {
    const classes = useOfferLetterEmployeeStyles();
    const dispatch = useDispatch();
    const tableClasses = useTableStyles();
    const isMobile = useIsMobile();

    const isLoading = useSelector(selectMyOfferLettersLoading);
    const isPdfCheckingById = useSelector(selectOfferLetterPdfIsLoading);
    const isPdfAvailableById = useSelector(selectOfferLetterPdfIsAvailable);
    const offerLetters = Object.values(useSelector(selectOfferLetters));
    const clientsById = useSelector(selectAllClientsById);
    const employeeProfilesByClientIds = useSelector(selectMyEmployeeProfilesByClientIds);
    const employeeProfileStatusCheckingByClientId = useSelector(selectEmployeeProfileStatusCheckingByClientId);

    useEffect(() => {
        dispatch(setHeaderTitle('Offer Letters'));
        dispatch(getMyOfferLetters.init());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const openDetail = useCallback((offerLetter: IOfferLetterWithPdf) => {
        if (OfferLetterStatusSlug.Outstanding === offerLetter.status.slug) {
            browserHistory.push(generatePath(routes.EMPLOYEE_OFFER_LETTER.DETAIL, { id: offerLetter.id }));
        } else {
            if (offerLetter.isPdfAvailable) {
                openOfferLetterPdf(offerLetter.id);
            } else {
                dispatch(checkOfferLetterPdf.init({ id: offerLetter.id, hasGlobalToaster: true }));
            }
        }
    }, [dispatch]);
    useNeedToBeOpenPdf(openOfferLetterPdf);

    const cells = useEmployeeOfferCells(openDetail);

    const rows = useMemo(() => (
        offerLetters
            .map(offerLetter => ({
                offerLetter: {
                    ...offerLetter,
                    isPdfLoading: isPdfCheckingById[offerLetter.id],
                    isPdfAvailable: isPdfAvailableById[offerLetter.id],
                    isStatusChecking: employeeProfileStatusCheckingByClientId[offerLetter.client_id] || false,
                    userCanContinueOnboarding: (
                        [
                            OfferLetterStatusSlug.Outstanding,
                            OfferLetterStatusSlug.PendingManagerApproval,
                        ].includes(offerLetter?.status?.slug)
                        && !employeeProfilesByClientIds[offerLetter.client_id]?.prism_onboarding_completed
                    ),
                },
                client: clientsById[offerLetter.client_id],
            }))
    ), [
        offerLetters,
        isPdfCheckingById,
        isPdfAvailableById,
        clientsById,
        employeeProfilesByClientIds,
        employeeProfileStatusCheckingByClientId,
    ]);

    return (
        <Box className={classes.wrapper}>
            <Typography className={classes.title} variant="h4">
                My Offers ({rows.length})
            </Typography>
            <GridTable
                className={classes.tableWrapper}
                bodyCellClassName={classes.bodyCell}
                headerCellClassName={tableClasses.headerLightGrayCell}
                rowData={rows}
                isLoading={isLoading}
                getKey={row => row.offerLetter.id}
                hideHeader={isMobile}
                cells={cells}
            />
        </Box>

    );
}

import React, { useContext, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Grid, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { showField, useEffectWithSkipInitialChange } from 'shared/components/forms/utils';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IgnoredAreasByType } from 'shared/utils/context/ignoredAreas';
import { ActivitySelect } from 'shared/components/selects/ActivitySelect/ActivitySelect';
import { ProjectWithAssignment } from 'shared/components/selects/ProjectWithAssignment';
import { Task } from 'shared/components/selects/Task';
import DayPickerField from 'shared/components/formSpecialFields/dayPickerField/DayPickerField';
import SheetInfo from 'shared/components/sidebars/EntryEdit/SheetInfo';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import { useSidebarStyles } from 'shared/components/sidebars/Sidebar/styles';
import { isMobileBreakpoint } from 'shared/styles/constants';
import { selectDefaultDepartment, selectUserDepartmentsList } from 'modules/employmentInfo/store/department/selectors';
import { defaultValues, ICommonEntryFormValues } from './EntryCommonFields';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { PositionByAssignments } from 'shared/components/selects/PositionByAssignments';
import { LocationByAssignments } from 'shared/components/selects/LocationByAssignments';
import { DepartmentsByAssignmentSelect } from 'modules/timeAndExpense/components/Selects/DepartmentsByAssignmentSelect';
import { JobNumbers, useJobNumbersForUser } from 'shared/components/selects/JobNumbers';
import { FeatureSwitches } from 'utils/featureSwitches';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { isActivityFieldIsVisibleInForm } from './helpers/commonEntryFormHelpers';

export interface ICommonEntryFieldsProps {
    supervisorId?: string;
    areaId?: number;
    setFieldValue: (fieldName: string, value: any) => void;
    values: ICommonEntryFormValues;
    sheetId?: string;
    entryType: EntryType;
    inputs: InputFields;
    userId?: string;
}

export function CommonEntrySidebarFields({
    supervisorId,
    areaId,
    setFieldValue,
    values,
    sheetId,
    entryType,
    inputs,
    userId,
}: ICommonEntryFieldsProps) {
    const {
        projectAssignment,
        taskId,
        position,
        location,
        department,
        jobNumber,
        entry_date,
    } = values;
    const assignmentId = projectAssignment?.assignment?.id;
    const projectId = projectAssignment?.project_id;
    const classes = useEntryEditStyles();
    const sidebarClasses = useSidebarStyles();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMobileBreakpoint));
    const departments = useSelector(selectUserDepartmentsList(userId));

    useEffectWithSkipInitialChange(() => {
        setFieldValue('taskId', defaultValues.taskId);
    }, [assignmentId, projectId, setFieldValue]);

    const defaultDepartment = useSelector(selectDefaultDepartment(userId));
    useEffect(() => {
        if (!department && defaultDepartment) {
            setFieldValue('department', defaultDepartment);
        }
    }, [setFieldValue, defaultDepartment, department]);

    const ignoredAreasByType = useContext(IgnoredAreasByType);
    const unavailableAreasIds = ignoredAreasByType ? ignoredAreasByType[EntryType.TIME] : [];

    const useTask = showField(inputs, EntrySlug.Task);
    const isActivityVisible = isActivityFieldIsVisibleInForm(inputs, position, location, projectAssignment,
        taskId, jobNumber);

    const selectedDate = moment(entry_date);

    const daysForFilters = useMemo(() => {
        return [selectedDate];
    }, [selectedDate]);
    const jobNumbers = useJobNumbersForUser(userId, daysForFilters);
    useEffect(() => {
        if (jobNumber && !jobNumbers.some(jn => jn.id === jobNumber?.id)){
            setFieldValue('jobNumber', null);
        }
    }, [jobNumbers, jobNumber, setFieldValue]);

    return (
        <>
            {showField(inputs, EntrySlug.Day) && (
                <DayPickerField
                    name="entry_date"
                    label={inputs.day.placeholder}
                />
            )}

            {sheetId && (
                <Hidden xsDown>
                    <SheetInfo
                        sheetId={sheetId}
                        entryType={entryType}
                    />
                </Hidden>
            )}

            <section className={clsx(sidebarClasses.sidebarElement, classes.inputsWrapper)}>
                <Grid container spacing={isMobile ? 0 : 2}>
                    {showField(inputs, EntrySlug.Position) && (
                        <Grid item xs={12}>
                            <PositionByAssignments
                                name="position"
                                label={inputs.position.placeholder}
                                className={classes.formInput}
                                userId={userId}
                            />
                        </Grid>
                    )}

                    {showField(inputs, EntrySlug.Location) && (
                        <Grid item xs={12}>
                            <LocationByAssignments
                                name="location"
                                label={inputs.location.placeholder}
                                className={classes.formInput}
                                positionId={position?.id}
                                userId={userId}
                            />
                        </Grid>
                    )}
                    {showField(inputs, EntrySlug.Assignment) && (
                        <Grid item xs={12}>
                            <ProjectWithAssignment
                                name="projectAssignment"
                                label={inputs.assignment_project.placeholder}
                                className={classes.formInput}
                                areaId={areaId}
                                supervisorId={supervisorId}
                                unavailableAreasIds={unavailableAreasIds}
                            />
                        </Grid>
                    )}
                    {showField(inputs, EntrySlug.JobNumber) && (
                        <FeatureSwitch feature={FeatureSwitches.enableGenworthSheets}>
                            <Grid item xs={12}>
                                <JobNumbers
                                    name="jobNumber"
                                    label={inputs[EntrySlug.JobNumber].placeholder}
                                    className={classes.formInput}
                                    userId={userId}
                                    payPeriodDays={daysForFilters}
                                />
                            </Grid>
                        </FeatureSwitch>
                    )}
                    {showField(inputs, EntrySlug.Department) && departments.length > 1 && (
                        <Grid item xs={12}>
                            <DepartmentsByAssignmentSelect
                                name="department"
                                label={inputs[EntrySlug.Department].placeholder}
                                className={classes.formInput}
                                userId={userId}
                            />
                        </Grid>
                    )}
                    {useTask && (
                        <Grid item xs={12}>
                            <Task
                                name="taskId"
                                label={inputs.task.placeholder}
                                className={classes.formInput}
                                assignmentId={assignmentId}
                                projectId={projectId}
                                disabled={!projectId}
                                title={!assignmentId ? 'Select Proj + Assignment first' : ''}
                                useIdValue
                            />
                        </Grid>
                    )}
                    {isActivityVisible && (
                        <Grid item xs={12}>
                            <ActivitySelect
                                name="activity"
                                label={inputs.activity.placeholder}
                                disabled={useTask && !taskId}
                                withAssignment={useTask}
                                taskId={useTask ? taskId : undefined}
                                className={classes.formInput}
                                entryType={entryType}
                                title={useTask && !assignmentId ? 'Select task first' : ''}
                                jobNumber={jobNumber}
                            />
                        </Grid>
                    )}
                </Grid>
            </section>
        </>
    );
}

import React, { useState } from 'react';
import { useSheetsData } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import SheetsRecalledView from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsRecalledView';
import { ISheetClickInfo } from 'shared/models/sheet/Sheet';

export default function SheetsRecalledPage() {
    const [sheetClickInfo, setSheetClickInfo] = useState<ISheetClickInfo | null>(null);
    const { timeRows, expenseRows, isLoading } = useSheetsData();

    return !sheetClickInfo ? (
        <SheetsSubmittedView timeRows={timeRows} expenseRows={expenseRows}
            onDetailsClick={setSheetClickInfo}
            isLoading={isLoading}
        />
    ) : (
        <SheetsRecalledView sheetId={sheetClickInfo.sheetId} entryType={sheetClickInfo.entryType}
            resetSheet={setSheetClickInfo}
        />
    );
}

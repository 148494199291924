import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';
import { passwordValidationSchema } from 'shared/models/validationSchemes/passwordSchema';

export const registrationValidationSchema = yup.object().shape({
    firstName: yup.string().trim().required(ValidationMessages.REQUIRED),
    lastName: yup.string().trim().required(ValidationMessages.REQUIRED),
    email: yup.string().required(ValidationMessages.REQUIRED).email('Please enter valid email'),
    username: yup.string().required(ValidationMessages.REQUIRED),
    password: passwordValidationSchema,
    passwordConfirmation: yup.string().required(ValidationMessages.REQUIRED)
        .test({
            name: 'confirmationShouldBeEqualToPassword',
            test: function (value: string): boolean {
                return value === this.parent.password;
            },
            message: 'Password and password confirmation should be equal',
        }),
    termsAnsConditionsAgreement: yup.boolean().oneOf([true], 'The terms and conditions should be accepted'),
});

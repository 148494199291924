import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'ts-debounce';
import { TextFilter } from 'shared/components/filters/TextFilter';
import { setDepartmentFilter } from 'modules/settings/submodules/clients/departments/store/actions';

export const DepartmentNameFilter = () => {
    const dispatch = useDispatch();
    const debounceTimeout = 300;
    const changeFilterName = useCallback(
        () => debounce(
            (name: string) => {
                dispatch(setDepartmentFilter({
                    name,
                }));
            },
            debounceTimeout,
        ),
        [dispatch],
    );
    return (
        <TextFilter onFilterValueChanged={changeFilterName}/>
    );
};

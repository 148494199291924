import React from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Menu, MenuItem, Divider, Box } from '@material-ui/core';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { routes } from 'shared/routes';
import { IStore } from 'store/configureStore';
import { logout } from 'store/components/auth/authActions';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { useUserHeaderStyles } from './headerUserStyles';
import { selectCurrentUser, selectIsManager } from 'store/components/auth/selectors';

interface IHeaderUserMenu {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}

const HeaderUserMenu = ({ anchorEl, handleClose }: IHeaderUserMenu) => {
    const { isAuthenticated } = useSelector((state: IStore) => state.auth);
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const isManager = useSelector(selectIsManager);
    const handleLogout = () => {
        dispatch(logout());
        handleClose();
    };
    const headerClasses = useUserHeaderStyles();
    const currentUser = useSelector(selectCurrentUser);

    return (
        <Menu
            id="header-user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            classes={{ paper: headerClasses.profileMenu }}
        >
            {isAuthenticated
                && (
                    <Box>
                        <section className={headerClasses.Title}>
                            {currentUser?.firstName} {currentUser?.lastName}
                        </section>
                        <Divider
                            classes={{ root: `${headerClasses.divider}` }}
                            orientation="horizontal"
                        />
                        <MenuItem
                            component={NavLink}
                            to={routes.USER_PROFILE}
                            onClick={handleClose}>
                            User Profile
                        </MenuItem>
                        {!isManager && clientId && (
                            <FeatureSwitch feature={FeatureSwitches.enableEmployeePortal} noPlaceholder>
                                <MenuItem
                                    component={NavLink}
                                    to={generatePath(routes.CLIENT.EMPLOYEE_PORTAL, { client_id: clientId })}
                                    onClick={handleClose}
                                >
                                    Employee Portal
                                </MenuItem>
                            </FeatureSwitch>
                        )}
                        <MenuItem>Notification Settings</MenuItem>
                        <MenuItem onClick={handleLogout}>Log out</MenuItem>
                    </Box>
                )
            }
            {!isAuthenticated && <MenuItem><Link href={routes.AUTH.LOGIN}>Login</Link></MenuItem>}
        </Menu>
    );
};

export default HeaderUserMenu;

import { combineReducers } from 'redux';
import { ITimesheetCalculation } from 'store/entities/timesheet/models/Calculation';
import { itemsByKey } from 'store/reducerUtils';
import { ActionsReturnTypes } from 'store/utils';
import { getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';

function byTimesheetId(
    state: Record<string, ITimesheetCalculation> = {},
    action: ActionsReturnTypes<typeof getTimesheetCalculations>,
): Record<string, ITimesheetCalculation> {
    switch (action.type) {
        case getTimesheetCalculations.successType: {
            return {
                ...state,
                ...itemsByKey(
                    action.payload,
                    (calculation: ITimesheetCalculation) => calculation.sheet_id,
                ),
            };
        }
        default:
            return state;
    }
}

export const calculations = combineReducers({
    byTimesheetId,
});

import React from 'react';

import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import MainContent from 'shared/components/common/MainContent';

import { FeatureSwitches } from 'utils/featureSwitches';

import OfferLetterTemplatesPageContent from './OfferLetterTemplatesPageContent';

function OfferLetterTemplatesPage() {
    return (
        <FeatureSwitch feature={FeatureSwitches.enableOfferLetterTemplates} noPlaceholder>
            <MainContent whiteBackground>
                <OfferLetterTemplatesPageContent/>
            </MainContent>
        </FeatureSwitch>
    );
}

export default OfferLetterTemplatesPage;

import { SvgIcon } from '@material-ui/core';
import React, { FC } from 'react';

const CalendarSVG: FC = () => (
    <SvgIcon fontSize="small">
        <path id="calendar"
            d="M4.67,17h3v3h-3V17z M8.33,17h3.33v3H8.34v-3H8.33z M4.67,13h3v3.33h-3V13z M8.33,13h3.33v3.33H8.34V13H8.33z
                M4.67,9.33h3v3h-3V9.33z M12.33,17h3.33v3h-3.33V17z M8.33,9.34h3.33v3H8.34v-3H8.33z M16.33,17h3v3h-3V17z
                M12.33,13h3.33v3.33  h-3.33V13z M8.57,7.57c-0.06,0.06-0.14,0.1-0.23,0.1H7.67C7.49,7.66,7.35,7.52,7.34,7.34v-3c0-0.18,0.15-0.33,0.33-0.34h0.67
                c0.18,0.01,0.32,0.15,0.33,0.33v3C8.67,7.42,8.64,7.51,8.57,7.57z M16.33,13h3v3.33h-3V13z M12.33,9.34h3.33v3h-3.33V9.34z
                M16.33,9.34h3v3h-3V9.34z M16.56,7.58c-0.06,0.06-0.14,0.1-0.23,0.1h-0.67c-0.18-0.01-0.32-0.15-0.33-0.33v-3
                C15.32,4.16,15.47,4.01,15.66,4c0,0,0,0,0,0h0.67c0.18,0.01,0.32,0.15,0.33,0.33v3c0,0.09-0.03,0.18-0.09,0.24L16.56,7.58z
                M20.66,6.68c-0.01-0.73-0.6-1.32-1.33-1.33H18v-1c0-0.92-0.75-1.67-1.67-1.67l0,0h-0.67C14.75,2.69,14.01,3.43,14,4.34v1h-4v-1
                c0-0.92-0.74-1.66-1.66-1.67H7.67C6.75,2.67,6,3.42,6,4.34l0,0v1H4.67c-0.73,0.01-1.32,0.6-1.33,1.33V20
                c0.01,0.73,0.6,1.32,1.33,1.33h14.66c0.73-0.01,1.32-0.6,1.33-1.33V6.68L20.66,6.68L20.66,6.68z"
        />
    </SvgIcon>
);

export default CalendarSVG;

import baseApi from 'shared/utils/baseApi';
import {

    dealEntity,
    gwClientExtensionsService,
} from 'store/entities/configuration/configurationApi';
import { ICreateDealTypeRequest } from 'modules/settings/submodules/clients/deals/components/DealForm/components/DealTypeCreatableSelect/store/models';
import { IDealType } from 'shared/models/DealType';

export const api = {
    async createDealType(request: ICreateDealTypeRequest): Promise<IDealType> {

        const url = `/${gwClientExtensionsService}/${dealEntity}/types`;

        const { data } = await baseApi.post<ICreateDealTypeRequest, IDealType>(url, request);

        return data;
    },
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@material-ui/core';
import { IModalSeverity } from 'shared/components/toasts/modal';
import { logout } from 'store/components/auth/authActions';
import { selectIsAuthenticated } from 'store/components/auth/selectors';
import { setGlobalToast } from 'store/entities/appConfig/actions';

const secondsInMinute = 60;
const millisecondsInSecond = 1000;
const millisecondsInMinute = secondsInMinute * millisecondsInSecond;
const defaultInactiveTimeoutMinutes = 10;
const defaultLogoutTimeoutMinutes = 2;

/**
 * Detect user inactivity, show popup warning and auto logout
 */
export const InactivityListener = () => {
    const dispatch = useDispatch();
    const enableListener = useSelector(selectIsAuthenticated);
    const [isShowPopup, setPopupShow] = useState(false);
    const [countDown, setCountDown] = useState(0);
    const warningTimeout = defaultInactiveTimeoutMinutes * millisecondsInMinute;
    const logoutTimeout = defaultLogoutTimeoutMinutes * millisecondsInMinute;
    const warningTimer = useRef<NodeJS.Timeout>();

    const showWarningCallback = useCallback(() => {
        setCountDown(logoutTimeout);
        setPopupShow(true);
    }, [setPopupShow, logoutTimeout, setCountDown]);
    const clearWarningTimer = useCallback(() => {
        if (warningTimer.current) {
            clearTimeout(warningTimer.current);
        }
    }, [warningTimer]);
    const resetWarningTimer = useCallback(() => {
        // clear and schedule warning
        clearWarningTimer();
        if (enableListener) {
            warningTimer.current = setTimeout(showWarningCallback, warningTimeout);
        }
    }, [clearWarningTimer, showWarningCallback, warningTimeout, enableListener]);

    const closePopup = useCallback(() => {
        setPopupShow(false);
    }, [setPopupShow]);
    const logoutCallback = useCallback(() => {
        closePopup();
        dispatch(logout());
    }, [dispatch, closePopup]);
    const logoutWithToast = useCallback(() => {
        clearWarningTimer();
        logoutCallback();
        dispatch(setGlobalToast({
            title: 'For your safety, we logged you out automatically after 10 minutes of inactivity.',
            severity: IModalSeverity.Info,
            autoHideDuration: null,
            ignoreSidebar: true,
        }));
    }, [dispatch, logoutCallback, clearWarningTimer]);

    useEffect(() => {
        /**
         * Count down timer
         */
        let logoutCountdown: NodeJS.Timeout | undefined;
        if (isShowPopup && enableListener) {
            if (countDown > 0) {
                logoutCountdown = setInterval(() => {
                    setCountDown(countDown - millisecondsInSecond);
                }, millisecondsInSecond);
            } else {
                logoutWithToast();
            }
        }

        return () => {
            if (logoutCountdown) {
                clearInterval(logoutCountdown);
            }
        };
    }, [countDown, setCountDown, logoutWithToast, isShowPopup, enableListener]);

    useEffect(() => {
        /**
         * Set timer for show warning popup
         */
        const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

        if (enableListener) {
            resetWarningTimer();
            events.forEach(eventName => document.addEventListener(eventName, resetWarningTimer, true));
        } else {
            clearWarningTimer();
        }

        return () => {
            clearWarningTimer();
            events.forEach(eventName => document.removeEventListener(eventName, resetWarningTimer));
        };
    }, [resetWarningTimer, enableListener, clearWarningTimer]);

    useEffect(() => {
        if (isShowPopup && !enableListener) {
            setPopupShow(false);
        }
    }, [setPopupShow, isShowPopup, enableListener]);

    const formattedCountDown = useMemo(() => {
        const countDownDuration = moment.duration(countDown);
        return `${countDownDuration.minutes()}:${countDownDuration.seconds().toString().padStart(2, '0')}`;
    }, [countDown]);

    return (
        <>
            {isShowPopup && (
                <Dialog
                    open={isShowPopup}
                    onClose={closePopup}
                    disableBackdropClick
                >
                    <DialogTitle>
                        Your session is about to expire.
                    </DialogTitle>
                    <DialogContent>
                        <Typography gutterBottom>
                            For your security, this session will expire in {formattedCountDown} due to inactivity.
                        </Typography>
                        <Typography gutterBottom>
                            If you want to extend your session, please select the &apos;Continue&apos; button.
                            If you select the &apos;Log Out&apos; button or do not respond,
                            your session will automatically close.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={closePopup}
                            variant="contained"
                            color="primary"
                        >
                            Continue
                        </Button>
                        <Button
                            onClick={logoutCallback}
                            variant="contained"
                            color="primary"
                        >
                            Log Out
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

import AssociationFilterChips
    from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/AssociationFilterChips/AssociationFilterChips';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { Box } from '@material-ui/core';
import CreateAssociation
    from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/CreateAssociation/CreateAssociation';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getPositionWorkLocationAssociation } from 'modules/settings/submodules/clients/store/actions';
import AssociationFilter
    from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/AssociationFilter/AssociationFilter';
import { AssociationsTable } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/AssociationsTable/AssociationsTable';
import { FeatureSwitches } from 'utils/featureSwitches';

export const PositionWorkLocationAssociationList = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (clientId) {
            dispatch(getPositionWorkLocationAssociation.init());
        }
    }, [dispatch, clientId]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enablePositionWorkLocationAssociationManagement}>
            <Box
                display="flex"
                justifyContent="space-between"
                mb={2}
            >
                <Box>
                    <CreateAssociation/>
                </Box>
                <AssociationFilter/>
            </Box>
            <AssociationFilterChips/>
            <AssociationsTable/>
        </FeatureSwitch>
    );
};

import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { settingsRoutes } from 'modules/settings/routes';
import { CommonSettingsSideMenu } from 'modules/settings/submodules/components/CommonSettingsSideMenu/CommonSettingsSideMenu';
import { CustomFieldsListPage } from 'modules/settings/submodules/customFields/components/CustomFieldsListPage/CustomFieldsListPage';

export const CustomFieldsRoutes = () => {
    return (
        <>
            <CommonSettingsSideMenu/>
            <Switch>
                <PrivateRoute
                    path={settingsRoutes.CUSTOM_FIELDS.ROOT}
                    component={CustomFieldsListPage}
                />
            </Switch>
        </>
    );
};

import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { PdfView } from 'shared/components/PdfViewer/PdfView';
import { baseUrl } from 'shared/utils/baseApi';
import { useModal } from 'shared/utils/hooks/useModal';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import { useStyles } from './styles';
import { useCellIconStyles } from 'modules/settings/submodules/components/sharedStyles/tableCellStyles';

export interface IPreviewOfferLetterTemplateProps {
    id: string;
}

export default function PreviewOfferLetterTemplate({ id }: IPreviewOfferLetterTemplateProps) {
    const templatePreviewUrl = `${baseUrl}/documents/offer_letters/template/${id}/download`;

    const pdfClasses = useStyles();
    const cellIconClasses = useCellIconStyles();
    const classes = useSettingsFormModalStyles();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    return (
        <>
            <IconButton onClick={onModalOpen} classes={cellIconClasses}>
                <PdfSVG/>
            </IconButton>
            <ModalDialog
                title="Offer Letter Template"
                isOpened={isModalOpened}
                onClose={onModalClose}
                modalProps={{
                    customClasses: classes,
                    showCloseIcon: true,
                }}
            >
                <Box justifyContent="center">
                    <PdfView
                        filePath={templatePreviewUrl}
                        title="Offer Letter Template"
                        className={pdfClasses.pdfWrapper}
                    />
                </Box>
            </ModalDialog>
        </>
    );
}

import React from 'react';
import { capitalize } from 'lodash';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { Status } from 'shared/models/Status';

export interface IStatusSelectProps extends IFormFieldProps, IFormSelect {
    onChanged?: (status: Status) => void;
}

export const StatusSelect = (props: IStatusSelectProps) => {
    const values = Object.keys(Status);
    return (
        <FormSelect
            getKey={status => status}
            getText={status => capitalize(status)}
            options={values}
            {...props}
        />
    );
};

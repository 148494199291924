import { IEntity } from 'shared/models/Entity';

export enum PayCodeType {
    Regular = 'regular',
    Vacation = 'vacation',
    Supplemental = 'supplemental'
}

export const PayCodeTypeDisplayValue: Record<PayCodeType, string> = {
    [PayCodeType.Regular]: 'Regular',
    [PayCodeType.Vacation]: 'Vacation',
    [PayCodeType.Supplemental]: 'Supplemental',
};

export enum PayCodeCalcMethod {
    Standard = 'standard',
    Fixed = 'fixed',
    Calculated = 'calculated',
    PerDiem = 'per_diem',
    PercentageOfGrossEarnings = 'percentage_of_gross_earnings',
    RateHoursWorked = 'rate_hours_worked',
    percentageOf401kEarnings = 'percentage_of_401k_earnings',
    rateHoursPaid = 'rate_hours_paid',
    rateHoursPaidSelected = 'rate_hours_paid_selected',
    rateFullHoursWorked = 'rate_full_hours_worked',
    rateStraightHoursWorked = 'rate_straight_hours_worked',
    rateUnionJobHoursWorked = 'rate_union_job_hours_worked',
    rateUnionProjectHoursWorked = 'rate_union_project_hours_worked'
}

export enum PayCodeReason {
    Hours = 'hours',
    Units = 'units'
}

export enum PayCodeClass {
    Earnings = 'earnings',
    ExpenseReimbursement = 'expense_reimbursement',
    NonCashFringeBenefit = 'non_cash_fringe_benefit',
    ReportedTips = 'reported_tips',
    Memo = 'memo'
}

export const PayCodeClassDisplayValue: Record<PayCodeClass, string> = {
    [PayCodeClass.Earnings]: 'Earnings',
    [PayCodeClass.ExpenseReimbursement]: 'Expense Reimbursement',
    [PayCodeClass.NonCashFringeBenefit]: 'Non Cash Fringe Benefit',
    [PayCodeClass.ReportedTips]: 'Reported Tips',
    [PayCodeClass.Memo]: 'Memo',
};

export interface IGetClientPayCodesRequest {
    client_id?: string;
}

// TODO: move PayCode models, reduces & actions to shared
export interface IPayCode extends IEntity {
    client_id: string;
    deleted_at: string | null;
    pay_type: PayCodeType;
    calc_method: PayCodeCalcMethod;
    pay_reason: PayCodeReason;
    pay_class: PayCodeClass;
    description: string;
    prism_paycode_id: string;
}

import { ISheetsStats, IStats } from 'modules/home/store/model';
import { selectUserStats } from 'modules/home/store/selectors';
import { OfferLetterStatusSlug } from 'modules/offerLetter/store/model';
import { useSelector } from 'react-redux';
import { BlockSVG, ClockSVG, DescriptionSVG, MoneySVG, WarningSVG } from 'shared/components/dashboard/DashboardIcons';
import { IDashboardCardData } from 'shared/models/Dashboard';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { selectIsManager } from 'store/components/auth/selectors';
import { AppClient } from 'utils/constants';

export const useHomeTitle = () => {
    const isManager = useSelector(selectIsManager);
    const isRTI = process.env.REACT_APP_CLIENT === AppClient.RTI;

    let title = 'Manager Dashboard';
    if (!isManager) {
        if (isRTI) {
            title = 'Welcome DCSnet Employee';
        } else {
            title = 'Welcome Headway Employee';
        }
    }
    useTitleUpdate(title);
};

const getStatsByStatus = (allStats: IStats[], status: string): IStats | undefined => {
    return allStats.find(stats => stats.status === status);
};

export const useUserDashboardData = (isManager: boolean): IDashboardCardData[] => {
    const userStats = useSelector(selectUserStats);

    const outstandingOffers = getStatsByStatus(userStats.offer_letters, OfferLetterStatusSlug.Outstanding);
    const submittedSheets = getStatsByStatus(userStats.sheets, 'submitted') as ISheetsStats;
    const workingSheets = getStatsByStatus(userStats.sheets, 'working') as ISheetsStats;
    const rejectedSheets = getStatsByStatus(userStats.sheets, 'rejected') as ISheetsStats;
    const recalledSheets = getStatsByStatus(userStats.sheets, 'recalled') as ISheetsStats;
    const missingSheets = getStatsByStatus(userStats.sheets, 'missing') as ISheetsStats;
    return isManager ? [
        {
            color: `#813772`,
            backgroundColor: `rgba(129, 55, 114, 0.3)`,
            count: outstandingOffers?.total || 0,
            title: `outstanding offer letters`,
            Icon: DescriptionSVG,
        },
        {
            color: `#2db67d`,
            backgroundColor: `rgba(45, 182, 125, 0.3)`,
            count: submittedSheets?.expense_total || 0,
            title: `Expense sheets waiting for approval`,
            Icon: MoneySVG,
        },
        {
            color: `#009be5`,
            backgroundColor: `rgba(0, 155, 229, 0.3)`,
            count: submittedSheets?.time_total || 0,
            title: `Timesheets waiting for approval`,
            Icon: ClockSVG,
        }, {
            color: `#e89c30`,
            backgroundColor: `rgba(184, 38, 1, 0.3)`,
            count: missingSheets?.total || 0,
            title: `missing time & expense sheets`,
            Icon: WarningSVG,
        },
    ] : [
        {
            color: `#009be5`,
            backgroundColor: `rgba(0, 155, 229, 0.3)`,
            count: outstandingOffers?.total || 0,
            title: `outstanding offer letters`,
            Icon: DescriptionSVG,
        },
        {
            color: `#813772`,
            backgroundColor: `rgba(129, 55, 114, 0.3)`,
            count: workingSheets?.time_total || 0,
            title: `Working Timesheets`,
            Icon: ClockSVG,
        },
        {
            color: `#2db67d`,
            backgroundColor: `rgba(45, 182, 125, 0.3)`,
            count: workingSheets?.expense_total || 0,
            title: `Working expense sheets`,
            Icon: MoneySVG,
        },
        {
            color: `#b82601`,
            backgroundColor: `rgba(184, 38, 1, 0.3)`,
            count: rejectedSheets?.total || 0,
            title: `rejected time & expense sheets`,
            Icon: BlockSVG,
        },
        {
            color: `#e89c30`,
            backgroundColor: `rgba(184, 38, 1, 0.3)`,
            count: recalledSheets?.total || 0,
            title: `recalled time & expense sheets`,
            Icon: WarningSVG,
        },
    ];
};

import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Box, ListItem } from '@material-ui/core';
import { ISideMenuItem } from 'modules/settings/submodules/components/SideMenu/model';
import { matchPath } from 'react-router';
import { browserHistory } from 'shared/utils/browserHistory';
import { CheckPermissionAndFeature } from 'modules/settings/common/components/CheckPermissionAndFeature/CheckPermissionAndFeature';
import { useStyles } from './styles';

export const SideMenuItem = ({ title, link, childs, ...checkAccessProps }: ISideMenuItem) => {
    const classes = useStyles();
    const { pathname } = browserHistory.location;
    const expand = matchPath(
        pathname,
        {
            path: link,
            exact: false,
            strict: false,
        },
    );

    return (
        <CheckPermissionAndFeature {...checkAccessProps}>
            <NavLink
                to={link}
                className={clsx(classes.menuItem, { [classes.activeLink]: expand })}
                exact={true}
            >
                <ListItem>
                    {title}
                </ListItem>
            </NavLink>
            {expand && childs && (
                <Box className={classes.childContainer}>
                    {childs.map(item => (
                        <NavLink
                            key={item.title}
                            to={item.link}
                            activeClassName={classes.childItemActive}
                            className={classes.childItem}
                            exact={true}
                        >
                            {item.title}
                        </NavLink>
                    ))}
                </Box>
            )}
        </CheckPermissionAndFeature>
    );
};

import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { usersApi } from 'store/entities/users/api';
import { UserType } from 'store/entities/users/model';
import { ActionsReturnTypes } from 'store/utils';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga';
import { getSpecialUsersAction, getUsers, loadClientRoles, loadGlobalRoles, searchUsers } from 'store/entities/users/actions';

const getUsersWatcher = getLoadEntitiesByRequestSagaWatcher(
    getUsers,
    usersApi.getUsersWithoutPagination,
    'users',
);

function* getSpecialUsersSaga(action: ActionsReturnTypes<typeof getSpecialUsersAction>) {
    const request = {
        ...action.payload,
        user_type: UserType.Manager,
    };
    const getUsersResponse = yield* call(usersApi.getUsers, request);
    yield put(getSpecialUsersAction.success({
        request,
        result: getUsersResponse.users,
    }));
}

export function* getSpecialUsersWatcher() {
    yield takeEvery(getSpecialUsersAction.initType, withBackendErrorHandler(getSpecialUsersSaga, getSpecialUsersAction.error, 'Unable to load users'));
}

function* searchUsersSaga(action: ReturnType<typeof searchUsers.init>) {
    const { searchKey, request } = action.payload;
    try {
        const getUsersResponse = yield* call(usersApi.getUsers, request);

        const userIds = getUsersResponse.users.map(user => user.id);
        yield put(getUsers.success(getUsersResponse.users));
        yield put(searchUsers.success({
            searchKey,
            userIds,
        }));
    } catch (error) {
        yield put(searchUsers.error({
            searchKey,
            error,
        }));
        const message = 'Unable to find users';
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: message,
            autoHideDuration: autoHideDefaultDuration * 2,
        }));
        logErrorWithCustomMessage(error, message);
    }
}

export function* searchUsersWatcher() {
    yield takeLatest(searchUsers.initType, searchUsersSaga);
}

function* getGlobalRolesSaga() {
    const result = yield* call(usersApi.getGlobalRoles);
    yield put(loadGlobalRoles.success(result));
}

export function* getGlobalRolesWatcher() {
    yield takeLatest(loadGlobalRoles.initType, withBackendErrorHandler(getGlobalRolesSaga, loadGlobalRoles.error, 'Unable to load global roles'));
}

function* getClientRolesSaga() {
    const result = yield* call(usersApi.getClientRoles);
    yield put(loadClientRoles.success(result));
}

export function* getClientRolesWatcher() {
    yield takeLatest(loadClientRoles.initType, withBackendErrorHandler(getClientRolesSaga, loadClientRoles.error, 'Unable to load clients roles'));
}

export default [
    getSpecialUsersWatcher,
    searchUsersWatcher,
    getGlobalRolesWatcher,
    getClientRolesWatcher,
    getUsersWatcher,
];

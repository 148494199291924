import { IStore } from 'store/configureStore';
import { IPaySettings, PayPeriodType } from 'store/entities/clients/clientsModel';
import { selectPaySettings } from 'store/entities/clients/clientsSelectors';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

function getDayOfWeek(dayNumber: number){
    return daysOfWeek[dayNumber % 7];
}

export const selectPaySettingsFormatted = (state: IStore): IPaySettings | undefined => {
    const paySettings = selectPaySettings(state);
    if (!paySettings) {
        return undefined; 
    }

    const formattedSettings: Partial<IPaySettings> = paySettings.pay_period_type === PayPeriodType.weekly
        ? {
            pay_period_start_day: getDayOfWeek(Number(paySettings.pay_period_start_day)),
            pay_period_end_day: getDayOfWeek(Number(paySettings.pay_period_end_day)),
            week_end_on_day: getDayOfWeek(Number(paySettings.week_end_on_day)),
            pay_day: getDayOfWeek(Number(paySettings.pay_day)),
        }
        : {};

    return {
        ...paySettings,
        ...formattedSettings,
    };
};

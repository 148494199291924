import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { EntryType } from 'shared/models/sheet/Sheet';
import { routes } from 'shared/routes';
import { ITitleHeader } from 'shared/utils/hooks/ITitleHeader';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { Permission } from 'store/components/auth/authModels';
import { IStore } from 'store/configureStore';
import { loadClientAssignmentsWithLinked } from 'store/entities/configuration/configurationAction';
import { getSimpleSheets } from 'store/entities/timesheet/actions/sheetState';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import EmployeeSheetDetailPage from 'modules/clients/content/TimeAndExpensePage/EmployeeSheetDetailPage';
import SheetsRejectedPage from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetsRejectedPage';
import SheetsInfoPage from 'modules/clients/content/TimeAndExpensePage/SheetsInfoPage';
import SheetsRecalledPage from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsRecalledPage';
import SheetsWorkingPage from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/SheetsWorkingPage';
import SheetsSubmittedAllPage from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsAllPage';
import SheetsSubmittedPage from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedPage';
import { selectWorkingEntryTypeFilter } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';

export default function TimeAndExpensePageContent() {
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    const entryTypeFilter = useSelector(selectWorkingEntryTypeFilter);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSimpleSheets.init());
        dispatch(loadClientAssignmentsWithLinked.init({ purpose: Permission.SubmitSheets }));
    }, [dispatch]);

    const [title, setTitle] = useState<ITitleHeader>({
        header: 'Time & Expense Entry',
        headerMobile: 'Time & Expense',
    });

    useEffect(() => {
        if (entryTypeFilter === undefined || activeStatus === StatusNames.WORKING) {
            setTitle({
                header: 'Time & Expense Entry',
                headerMobile: 'Time & Expense',
            });
            return;
        }
        if (entryTypeFilter === EntryType.TIME) {
            setTitle({
                header: 'Time Entry',
                headerMobile: 'Time Entry',
            });
        } else if (entryTypeFilter === EntryType.EXPENSE) {
            setTitle({
                header: 'Expense Entry',
                headerMobile: 'Expense Entry',
            });
        }
    }, [entryTypeFilter, activeStatus, setTitle]);

    useTitleUpdate(title.header, title.headerMobile);

    const getPageByStatus = () => {
        switch (activeStatus) {
            case StatusNames.WORKING:
                return (
                    <SheetsWorkingPage/>
                );
            case StatusNames.SUBMITTED:
                return (
                    <SheetsSubmittedPage/>
                );
            case StatusNames.RECALLED:
                return (
                    <SheetsRecalledPage/>
                );
            case StatusNames.REJECTED:
                return (
                    <SheetsRejectedPage/>
                );
            case StatusNames.ALL:
                return (
                    <SheetsSubmittedAllPage/>
                );
            default:
                return (
                    <SheetsInfoPage/>
                );
        }
    };

    return (
        <>
            {getPageByStatus()}
            <Route
                path={routes.CLIENT.TIME_AND_EXPENSE.SHEET_DETAIL}
                component={EmployeeSheetDetailPage}
            />
        </>
    );
}

import { IDeal } from 'shared/models/Deal';
import { IDealType } from 'shared/models/DealType';
import { IEntity } from 'shared/models/Entity';
import {
    FeeType,
    ReasonForClose,
    TimesheetSettings,
    UWSystem,
} from 'shared/models/JobNumber';

export interface IJobNumberFormValues {
    jobNumber?: number;
    deal?: IDeal | null;
    dealType: IDealType | null;
    jobDescription: string;
    startDate: string;
    endDate?: string;
    managerId?: string;
    userTypeId?: string;

    uwSystem: UWSystem;
    fte: string;
    timesheetSettings: TimesheetSettings;
    timeActivities: IEntity[];

    billingInfo?: FeeType;
    max: string | null;
    min: string | null;
    miCredit: string | null;
    blendedAmount: string | null;

    isPerDiem: boolean;
    hourlyRate: string | null;
    fileRate: string | null;
    perDiemRate: string | null;

    reasonForClose?: ReasonForClose;
    closeOnDate?: string;
}

export const defaultJobNumberFormValues: IJobNumberFormValues = {
    deal: null,
    dealType: null,
    billingInfo: undefined,
    fte: '',
    isPerDiem: false,
    jobDescription: '',
    managerId: '',
    userTypeId: '',
    startDate: '',
    endDate: '',
    timeActivities: [],
    uwSystem: UWSystem.Genworth,
    max: null,
    min: null,
    miCredit: null,
    blendedAmount: null,
    perDiemRate: null,
    fileRate: null,
    hourlyRate: null,
    timesheetSettings: TimesheetSettings.Hourly,
};

import React from 'react';
import { useSelector } from 'react-redux';
import FormSelect from 'shared/components/selects/FormSelect';
import { selectStateCodes } from 'store/entities/scaZone/selectors';

import { IFormSelect } from './model';
import { IFormFieldProps } from '../formFields/models';

export interface IStateCodeSelectProps extends IFormFieldProps, IFormSelect {}

export function StateCodeSelect({
    ...props
}: IStateCodeSelectProps) {
    const usStateCodes = useSelector(selectStateCodes);

    return (
        <FormSelect
            {...props}
            useIdValue
            getKey={(code: string) => code}
            getText={(code: string) => code}
            options={usStateCodes}
        />
    );
}

import { createActions } from 'store/utils';
import {
    ICreateWorkLocationRequest,
    IUpdateWorkLocationRequest,
} from 'modules/settings/submodules/clients/store/models';

export const CREATE_CLIENT_WORK_LOCATION = 'CREATE_CLIENT_WORK_LOCATION';
export const CREATE_CLIENT_WORK_LOCATION_SUCCESS = 'CREATE_CLIENT_WORK_LOCATION_SUCCESS';
export const CREATE_CLIENT_WORK_LOCATION_ERROR = 'CREATE_CLIENT_WORK_LOCATION_ERROR';

export const createClientWorkLocation = createActions<ICreateWorkLocationRequest, any, any,
    typeof CREATE_CLIENT_WORK_LOCATION,
    typeof CREATE_CLIENT_WORK_LOCATION_SUCCESS, typeof CREATE_CLIENT_WORK_LOCATION_ERROR
>(
    CREATE_CLIENT_WORK_LOCATION, CREATE_CLIENT_WORK_LOCATION_SUCCESS, CREATE_CLIENT_WORK_LOCATION_ERROR,
);

export const UPDATE_CLIENT_WORK_LOCATION = 'UPDATE_CLIENT_WORK_LOCATION';
export const UPDATE_CLIENT_WORK_LOCATION_SUCCESS = 'UPDATE_CLIENT_WORK_LOCATION_SUCCESS';
export const UPDATE_CLIENT_WORK_LOCATION_ERROR = 'UPDATE_CLIENT_WORK_LOCATION_ERROR';

export const updateClientWorkLocation = createActions<{
    id: string;
    request: IUpdateWorkLocationRequest;
}, any, any,
    typeof UPDATE_CLIENT_WORK_LOCATION,
    typeof UPDATE_CLIENT_WORK_LOCATION_SUCCESS,
    typeof UPDATE_CLIENT_WORK_LOCATION_ERROR
>(
    UPDATE_CLIENT_WORK_LOCATION,
    UPDATE_CLIENT_WORK_LOCATION_SUCCESS,
    UPDATE_CLIENT_WORK_LOCATION_ERROR,
);

export const DELETE_CLIENT_WORK_LOCATION = 'DELETE_CLIENT_WORK_LOCATION';
export const DELETE_CLIENT_WORK_LOCATION_SUCCESS = 'DELETE_CLIENT_WORK_LOCATION_SUCCESS';
export const DELETE_CLIENT_WORK_LOCATION_ERROR = 'DELETE_CLIENT_WORK_LOCATION_ERROR';

export const deleteClientWorkLocation = createActions<string, any, any,
    typeof DELETE_CLIENT_WORK_LOCATION,
    typeof DELETE_CLIENT_WORK_LOCATION_SUCCESS,
    typeof DELETE_CLIENT_WORK_LOCATION_ERROR
>(
    DELETE_CLIENT_WORK_LOCATION,
    DELETE_CLIENT_WORK_LOCATION_SUCCESS,
    DELETE_CLIENT_WORK_LOCATION_ERROR,
);

export const SYNC_CLIENT_WORK_LOCATION = 'SYNC_CLIENT_WORK_LOCATION';
export const SYNC_CLIENT_WORK_LOCATION_SUCCESS = 'SYNC_CLIENT_WORK_LOCATION_SUCCESS';
export const SYNC_CLIENT_WORK_LOCATION_ERROR = 'SYNC_CLIENT_WORK_LOCATION_ERROR';

export const syncClientWorkLocation = createActions<
void,
void,
any,
    typeof SYNC_CLIENT_WORK_LOCATION,
    typeof SYNC_CLIENT_WORK_LOCATION_SUCCESS,
    typeof SYNC_CLIENT_WORK_LOCATION_ERROR
>(
    SYNC_CLIENT_WORK_LOCATION,
    SYNC_CLIENT_WORK_LOCATION_SUCCESS,
    SYNC_CLIENT_WORK_LOCATION_ERROR,
);

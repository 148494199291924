import {
    SET_POSITION_LOCATION_ASSOCIATION_FILTER,
    setPositionWorkLocationAssociationFilter,
} from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/actions';
import { IPositionWorkLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/model';
import {
    ClientSettingsAction,
    createPositionWorkLocationAssociation, deletePositionWorkLocationAssociation,
    getPositionWorkLocationAssociation,
} from 'modules/settings/submodules/clients/store/actions';
import { IPositionWorkLocationAssociation } from 'modules/settings/submodules/clients/store/models';
import { combineReducers } from 'redux';
import { ItemsById } from 'shared/models/ItemsById';
import { isLoadingReducer, itemsByIds } from 'store/reducerUtils';

const isLoading = isLoadingReducer(getPositionWorkLocationAssociation);
const isCreating = isLoadingReducer(createPositionWorkLocationAssociation);

const associationsById = (
    state: ItemsById<IPositionWorkLocationAssociation> = {},
    action: ClientSettingsAction,
) => {
    switch (action.type) {
        case getPositionWorkLocationAssociation.successType:
            return {
                ...state,
                ...itemsByIds(action.payload),
            };
        case createPositionWorkLocationAssociation.successType:
            return {
                ...state,
                [action.payload.id]: action.payload,
            };
        case deletePositionWorkLocationAssociation.successType: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { [action.payload]: removedEntry, ...newState } = state;
            return newState;
        }
        default:
            return state;
    }
};

const filter = (
    state: IPositionWorkLocationAssociationFilter = {
        position: null,
        location: null,
        unassociated: false,
    },
    action: ReturnType<typeof setPositionWorkLocationAssociationFilter>,
) => {
    switch (action.type) {
        case SET_POSITION_LOCATION_ASSOCIATION_FILTER:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const positionLocationAssociation = combineReducers({
    isLoading,
    isCreating,
    associationsById,
    filter,
});

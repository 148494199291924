import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';

import { FeatureSwitches } from 'utils/featureSwitches';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import { CreateDepartmentButton } from 'modules/settings/submodules/clients/departments/components/CreateDepartment/CreateDepartmentButton';
import { DepartmentsTable } from 'modules/settings/submodules/clients/departments/components/DepartmentsTable/DepartmentsTable';
import EditDepartmentModal from 'modules/settings/submodules/clients/departments/components/EditDepartment/EditDepartmentModal';
import { DepartmentNameFilter } from 'modules/settings/submodules/clients/departments/components/Filter/DepartmentNameFilter';

export const DepartmentsPage = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (!clientId) {
            dispatch(getDepartments.init());
        }
    }, [dispatch, clientId]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableClientDepartmentManagement}>
            <Box
                display="flex"
                justifyContent="space-between"
                mb={2}
            >
                <CreateDepartmentButton/>
                <DepartmentNameFilter/>
            </Box>
            <DepartmentsTable/>
            <EditDepartmentModal/>
        </FeatureSwitch>
    );
};

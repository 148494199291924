import { ActionsReturnTypes, createActions, createSingleAction } from 'store/utils';
import { IFileUpload, IEntryAttachment, IUpdateEntryAttach } from 'shared/models/Attachments';

const ADD_NEW_ATTACHMENT = 'ADD_NEW_ATTACHMENT';
const ADD_NEW_ATTACHMENT_SUCCESS = 'ADD_NEW_ATTACHMENT_SUCCESS';
const ADD_NEW_ATTACHMENT_ERROR = 'ADD_NEW_ATTACHMENT_ERROR';

export const addNewAttachment = createActions<IFileUpload, IEntryAttachment, any,
    typeof ADD_NEW_ATTACHMENT, typeof ADD_NEW_ATTACHMENT_SUCCESS, typeof ADD_NEW_ATTACHMENT_ERROR
>(
    ADD_NEW_ATTACHMENT, ADD_NEW_ATTACHMENT_SUCCESS, ADD_NEW_ATTACHMENT_ERROR,
);

export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const REMOVE_ATTACHMENT_SUCCESS = 'REMOVE_ATTACHMENT_SUCCESS';
export const REMOVE_ATTACHMENT_ERROR = 'REMOVE_ATTACHMENT_ERROR';

export const removeAttachment = createActions<IEntryAttachment, string, any,
    typeof REMOVE_ATTACHMENT, typeof REMOVE_ATTACHMENT_SUCCESS, typeof REMOVE_ATTACHMENT_ERROR
>(
    REMOVE_ATTACHMENT, REMOVE_ATTACHMENT_SUCCESS, REMOVE_ATTACHMENT_ERROR,
);

export const SET_FILE_READ = 'SET_FILE_READ';
export const setFileRead = createSingleAction<boolean, typeof SET_FILE_READ>(SET_FILE_READ);

export const UPDATE_ENTRY_ATTACHMENTS = 'SET_UPDATE_ATTACH';
export const updateEntryAttachments = createSingleAction<IUpdateEntryAttach,
 typeof UPDATE_ENTRY_ATTACHMENTS>(UPDATE_ENTRY_ATTACHMENTS);

export type AttachmentActions = ActionsReturnTypes<typeof addNewAttachment>
| ActionsReturnTypes<typeof removeAttachment>
| ReturnType<typeof setFileRead>
| ReturnType<typeof updateEntryAttachments>;

/* eslint-disable react/display-name,react/prop-types */

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { DeleteItemButton } from 'modules/settings/common/components/DeleteItemButton/DeleteItemButton';
import { removeClientUserAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import { IUserClientAssociationRow } from 'modules/settings/submodules/platformUsers/components/ClientUserAssociationTable/model';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { userFullName } from 'shared/utils/converters/user';
import { AppClient } from 'utils/constants';

enum CellTypes {
    Id,
    User,
    GlobalRole,
    Client,
    Role,
    Actions,
}

const getCellsDictionary = (tableClasses: ReturnType<typeof useDefaultTableStyles>) => ({
    [CellTypes.Id]: {
        key: 'id',
        title: 'id',
        render: ({ className, id }: IUserClientAssociationRow) => (
            <PlainText className={className} value={id}/>
        ),
    },
    [CellTypes.User]: {
        key: 'user',
        title: 'name',
        render: ({ className, user }: IUserClientAssociationRow) => (
            <PlainText className={className} value={userFullName(user)}/>
        ),
    },
    [CellTypes.GlobalRole]: {
        key: 'global_role',
        title: 'Global Role',
        render: function RoleCell({ user, className }: IUserClientAssociationRow) {
            return (
                <PlainText className={className} value={`${user?.global_roles.map(r => r.name).join(', ')}`}/>
            );
        },
    },
    [CellTypes.Client]: {
        key: 'client',
        title: 'client',
        render: function Client({ className, client }: IUserClientAssociationRow) {
            return (
                <PlainText className={className} value={client?.name}/>
            );
        },
    },
    [CellTypes.Role]: {
        key: 'role',
        title: 'role',
        render: ({ className, role }: IUserClientAssociationRow) => (
            <PlainText className={className} value={role?.name}/>
        ),
    },
    [CellTypes.Actions]: {
        key: 'actions',
        title: '',
        width: '54px',
        render: ({ className, id }: IUserClientAssociationRow) => (
            <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                <DeleteItemButton
                    confirmation="Are you sure you want to delete client role from user?"
                    id={id}
                    deleteAction={removeClientUserAssociation.init}
                />
            </div>
        ),
    },
});

export const useClientUsersAssociationCells = (): ICellInfo<IUserClientAssociationRow>[] => {
    const cellsDictionary = getCellsDictionary(useDefaultTableStyles());

    return useMemo(() => {
        if (process.env.REACT_APP_CLIENT === AppClient.RTI) {
            return [
                cellsDictionary[CellTypes.Id],
                cellsDictionary[CellTypes.User],
                cellsDictionary[CellTypes.GlobalRole],
                cellsDictionary[CellTypes.Role],
                cellsDictionary[CellTypes.Actions],
            ];
        }
        return [
            cellsDictionary[CellTypes.Id],
            cellsDictionary[CellTypes.User],
            cellsDictionary[CellTypes.GlobalRole],
            cellsDictionary[CellTypes.Client],
            cellsDictionary[CellTypes.Role],
            cellsDictionary[CellTypes.Actions],
        ];
    }, [cellsDictionary]);
};

import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CreateWorkLocationModal
    from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/CreateWorkLocationModal';
import { setCreateClientWorkLocationModalState } from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/state/actions';

export const CreateWorkLocationButton = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setCreateClientWorkLocationModalState(true));
    }, [dispatch]);
    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + add work location
            </Button>
            <CreateWorkLocationModal/>
        </>
    );
};

import { IDepartment } from 'modules/employmentInfo/models/Department';
import { IEmployeeProfile } from 'shared/models/EmployeeProfile';
import { IEntity } from 'shared/models/Entity';
import { ILocationBackend } from 'shared/models/Location';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { Permission } from 'store/components/auth/authModels';
import { IPosition, PayRateType } from 'shared/models/Position';

export interface IConfigurationByAssignments {
    assignment_ids?: string; // comma separated
}

export interface IConfigurationByAssignment {
    assignment_id?: string;
}

export interface IConfigurationByPurpose {
    purpose?: Permission;
}

export interface IConfigurationByClient {
    client_id?: string;
}

export interface IConfigurationByIds {
    ids?: string[];
}

export interface IAssignmentsRequest extends IConfigurationByPurpose, IConfigurationByClient, IConfigurationByIds {}

export interface IProjectWithAssignmentRequest extends IConfigurationByPurpose, IConfigurationByAssignments {}

export interface IProjectsRequest extends IConfigurationByAssignments, IConfigurationByAssignment,
    IConfigurationByPurpose, IConfigurationByClient, IConfigurationByIds {}

export interface IArea extends IEntity {
    state_id: string;
    description: string;
}

export interface IProject extends IEntity {
    description: string;
    sca_applies: boolean;
}

export interface IManagerInfo {
    user_id: string;
    manager_level: number;
}

export interface IManagerInfoWithUser extends IManagerInfo {
    user: IUserInfo;
}

export interface IAssignmentBackend extends IEntity {
    description: string;
    sca_applies: boolean;
    user_id: string;
    managers: IManagerInfoWithUser[];
    position_id?: string;
    location_id?: string;
    department_id?: string;
    pay_rate_type: PayRateType;
    pay_rate_value: string;
}

export interface IAssignment extends Omit<IAssignmentBackend, 'area' | 'managers'>{
    managers: IManagerInfo[];
}

export interface IProjectWithAssignmentBackend {
    project: IProject;
    project_id: string;
    assignment: IAssignmentBackend;
    area_id: number | null;
    area?: IArea;
    description: string;
    sca_zone_id: number | null;
}

export interface IProjectWithAssignment extends IProjectWithAssignmentBackend {}

export interface IActivityRequest extends IConfigurationByClient, IConfigurationByAssignments {}

export enum ActivityTag {
    ShowZipCode = 'show_zip_code',
    NonProduction = 'non_production',
    Default='is_default',
}

export interface IActivity extends IEntity {
    description: string;
    short_description: string;
    sheet_type: EntryType;
    data_type: QuantityType;
    pay_code_id: string;
    tasks: IEntity[];
    deleted_at: string | null;
    show_zip_code: boolean;
    is_active: boolean;
    tags?: ActivityTag[];
}

export interface ITask extends IEntity {
    description: string;
    charge_code: string;
    project_id: string;
}

export function renderTaskInfo(task?: ITask): string {
    if (!task) {
        return '';
    }
    return `${task?.description} - ${task?.charge_code}`;
}

export interface ILocationRequest extends IConfigurationByAssignment, IConfigurationByClient {
    filters?: string;
}

export interface ITemplate extends IEntity {
    name: string;
    template: string;
    created_at?: string;
    updated_at?: string;
}

export interface IPhysicalDemandsTemplate extends ITemplate {
}

export interface IWorkingConditionsTemplate extends ITemplate {
}

export interface IBackgroundCheckTemplate extends ITemplate {
}

export interface IAssignmentWithLinkedRequest extends IConfigurationByAssignment, IConfigurationByClient {
    purpose?: Permission.ApproveSheets | Permission.SubmitSheets;
}

export interface IAssignmentWithLinked extends IAssignment {
    user: IUserInfo;
    employee_profile: IEmployeeProfile;
    position: IPosition;
    location: ILocationBackend;
    department: IDepartment;
    area: IArea;
    managers: IManagerInfoWithUser[];
}

export interface IJobNumberRequest extends IConfigurationByClient {
    filters?: string;
    sort?: string;
    range?: string;
    ids?: string[];
    username?: string;
    user_id?: string[];
    client_id?: string;
}

export interface IJobNumberPostRequest extends Omit<IJobNumberRequest, 'ids' | 'user_id'> {
    filters?: string;
    sort?: string;
    range?: string;
    ids?: string; // Comma-delimited list
    username?: string;
    user_id?: string; // Comma-delimited list
}

import { Action } from 'redux';
import { Permission } from 'store/components/auth/authModels';
import { loadExpenseSheetsWithEntries } from 'store/entities/timesheet/actions/expenseActions';
import { loadTimeSheetsWithEntries } from 'store/entities/timesheet/actions/timeActions';
import { getPayPeriodWorkingSheets } from 'modules/timeAndExpense/components/WorkingPage/store/actions';
import { ILoadSheetsPayload } from 'store/entities/timesheet/models/SheetApi';
import { all, put, take, takeLatest } from 'typed-redux-saga';

export function* getPayPeriodSheetsWithEntries(action: ReturnType<typeof getPayPeriodWorkingSheets.init>) {
    const payPeriod = action.payload;
    const weekend = payPeriod.period_end;

    const sheetRequestPayload: ILoadSheetsPayload = {
        purpose: Permission.SubmitSheets,
        request: {
            weekend,
        },
    };
    yield all([
        put(loadTimeSheetsWithEntries.init(sheetRequestPayload)),
        put(loadExpenseSheetsWithEntries.init(sheetRequestPayload)),
    ]);

    const [time, expense]: [Action, Action] = (yield* all([
        take([loadTimeSheetsWithEntries.successType, loadTimeSheetsWithEntries.errorType]),
        take([loadExpenseSheetsWithEntries.successType, loadExpenseSheetsWithEntries.errorType]),
    ])) as [Action, Action];

    if (time.type === loadTimeSheetsWithEntries.successType
        && expense.type === loadExpenseSheetsWithEntries.successType) {
        yield put(getPayPeriodWorkingSheets.success(payPeriod));
    } else {
        yield put(getPayPeriodWorkingSheets.error(payPeriod));
    }
}

function* getSheetsGridDataWatcher() {
    yield* takeLatest(getPayPeriodWorkingSheets.initType, getPayPeriodSheetsWithEntries);
}

export default [
    getSheetsGridDataWatcher,
];

import { useField } from 'formik';
import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import DayPicker from 'shared/components/sidebars/EntryEdit/DayPicker';

export default function DayPickerField({
    name,
}: IFormFieldProps) {
    const [field,, helper] = useField(name);

    return (
        <DayPicker
            onChange={helper.setValue}
            value={field.value}
        />
    );
}

import moment from 'moment';
import { DATE_FORMAT } from 'shared/models/Dates';

export const getPreviousPayPeriod = () => {
    const previousPeriod = moment().subtract(7, 'days');
    return ['isoWeek'].map(
        end => previousPeriod
            .clone()
            .endOf(end as moment.unitOfTime.StartOf)
            .format(DATE_FORMAT.YYYY_MM_DD),
    ).join(',');
};

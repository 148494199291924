import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ITableContainerCssProps, useGridTableStyles } from 'shared/components/table/GridTable/GridTableStyles';
import { GetRowId, ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import GridTableRow from 'shared/components/table/GridTable/GridTableRow';
import GridHeader from 'shared/components/table/GridTable/GridHeader';
import GridTableSkeleton from './GridTableSkeleton';

export interface IGridTableProps<RowData> {
    className?: string;
    headerCellClassName?: string;
    bodyCellClassName?: string;
    rowData: Array<RowData>;
    getKey: (row: RowData) => string;
    cells: Array<ICellInfo<RowData>>;
    isLoading?: boolean;
    getRowId?: GetRowId<RowData>;
    hideHeader?: boolean;
    stickyHeader?: boolean;
}

export default function GridTable<RowData>({
    cells,
    rowData,
    className = '',
    isLoading,
    headerCellClassName,
    bodyCellClassName,
    getKey,
    getRowId,
    hideHeader,
    stickyHeader,
}: IGridTableProps<RowData>) {
    const classesProps: ITableContainerCssProps = useMemo(() => ({
        gridTemplateColumns: cells.map(cell => cell.width ?? '1fr').join(' '),
        amount: cells.length,
    }), [cells]);

    const classes = useGridTableStyles(classesProps);

    return isLoading ? (
        <GridTableSkeleton hasHeader/>
    ) : (
        <div
            className={clsx(className, classes.tableContainer)}
            role="table"
            data-test="table"
        >
            {!hideHeader && (
                <GridHeader
                    cells={cells}
                    classes={classes}
                    headerCellClassName={headerCellClassName}
                    rowsData={rowData}
                    stickyHeader={stickyHeader}
                />
            )}
            {rowData.map(row => (
                <GridTableRow key={getKey(row)}
                    row={row}
                    getRowId={getRowId}
                    cells={cells}
                    classes={classes}
                    bodyCellClassName={bodyCellClassName}
                />
            ))}
        </div>
    );
}

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';

import { addTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import { ITimeEntryCreate, transformTimeDataToBackend } from 'store/entities/timesheet/models/Entry';
import { selectSheet } from 'store/entities/timesheet/selectors';
import { useTimeEntryValidationSchema } from 'shared/models/validationSchemes/timeEntry';
import { selectAssignmentProjectIds, selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { ITimeEntryFormValues } from 'shared/components/forms/entries/TimeEntryModel';
import { TimeEntrySidebarForm } from 'shared/components/forms/entries/TimeEntrySidebarForm';
import { useEntryEditStyles } from '../EntryEdit/styles';
import { IEntryAddSidebar } from './interfaces';
import { getCommonEntryModel, useTimeEntryDefaultValues } from 'shared/utils/helpers/entries';

interface IAddTimeEntryProps extends IEntryAddSidebar {
    totalMinutesByDay: Record<string, number>;
}

export default function AddTimeEntry({
    supervisorId,
    userId,
    SubmitBlock,
    onClose,
    sheetId,
    defaultEntryDate,
    inputs,
    payPeriod,
}: IAddTimeEntryProps) {
    const classes = useEntryEditStyles();
    const dispatch = useDispatch();
    const areaId = useSelector(selectSheet(sheetId))?.area_id;
    const assignmentProjectId = useSelector(selectAssignmentProjectIds);
    const assignmentsById = useSelector(selectAssignmentsById);

    const schema = useTimeEntryValidationSchema(userId);
    const initialValues = useTimeEntryDefaultValues(userId);
    const defaultTask = inputs.task && inputs.task.default_value;

    const onSubmit = useCallback((values: ITimeEntryFormValues, formikActions: FormikHelpers<ITimeEntryFormValues>) => {
        const baseEntryModel = getCommonEntryModel(values, defaultTask, assignmentsById, assignmentProjectId);
        if (baseEntryModel && values.data) {
            const entry: ITimeEntryCreate = {
                ...baseEntryModel,
                data: transformTimeDataToBackend(values.data, values.entry_date),
                sca_zone: values.scaZone,
                user_id: userId,
                period_start: payPeriod.period_start,
                period_end: payPeriod.period_end,
            };
            dispatch(addTimeEntry.init(entry));
            onClose();
        }
        formikActions.setSubmitting(false);
    }, [dispatch, assignmentProjectId, onClose, defaultTask, assignmentsById, userId, payPeriod]);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                entry_date: defaultEntryDate,
            }}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {props => (
                <>
                    <TimeEntrySidebarForm
                        {...props}
                        supervisorId={supervisorId}
                        userId={userId}
                        areaId={areaId}
                        sheetId={sheetId}
                        inputs={inputs}
                    />
                    <footer className={classes.footer}>
                        <SubmitBlock onSubmit={props.submitForm}/>
                    </footer>
                </>
            )}
        </Formik>
    );
}

/* eslint-disable react/display-name */
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { calculateMiles } from 'shared/components/formSpecialFields/expenseData/utils';
import PlainText from 'shared/components/table/Cells/PlainText';
import { desktopCells } from 'shared/components/table/EntriesTable/cells';
import { QuickEditBreak } from 'shared/components/table/EntriesTable/cellsComponents/quickEditBreak/QuickEditBreak';
import QuickEditEntry from 'shared/components/table/EntriesTable/cellsComponents/QuickEditEntry';
import { useEntriesTableStyles } from 'shared/components/table/EntriesTable/EntriesTableStyles';
import { IEntryRow } from 'shared/components/table/EntriesTable/model';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import {
    EntryType,
    IBreakEntryDataBackend, IInOutBreakDataBackend,
    IInOutEntryDataBackend,
    ITimeEntry,
    QuantityType,
} from 'shared/models/sheet/Sheet';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import { totalBreakTime, totalFileTimePayment, totalTime } from 'shared/utils/counters/timeCounter';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { EntryColumnSlug } from 'store/entities/clients/clientsModel';
import { dollarsPerMile } from 'shared/models/Miles';
import { printDurationFromDateTimes, transformBackendDateTimeToTime } from 'shared/models/DateTime';
import { isEntryAllowsTimeInTimeOut } from 'store/entities/timesheet/models/Entry';
import TimeInOutQuickEdit from 'shared/components/table/EntriesTable/cellsComponents/quickEdit/TimeInOutQuickEdit';
import { Box, ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';
import PdfSVG from 'shared/components/icons/PdfSVG';

const isRowsWithoutTimeEntries = (rows: IEntryRow[]): boolean => !rows.find(({ entry }) => (
    entry.entry_type === EntryType.TIME && entry.data.entry_type !== QuantityType.FILE
));

const getRowsWithCompletedInterviews = (rows: IEntryRow[]): number => rows.filter(
    row => activityHasCompletes(row.activity) && completesByNotes(row.entry?.notes || ''),
).length;

export const entryCellDictionary: Record<EntryColumnSlug, (
    placeholder: string, classes: ReturnType<typeof useEntriesTableStyles>,
) => ICellInfo<IEntryRow>> = {
    [EntryColumnSlug.Assignment]: placeholder => ({
        ...desktopCells.assignmentCell,
        title: placeholder,
    }),
    [EntryColumnSlug.AssignmentProject]: placeholder => ({
        ...desktopCells.assignmentCell,
        title: placeholder,
    }),
    [EntryColumnSlug.Task]: placeholder => ({
        ...desktopCells.taskCell,
        title: placeholder,
    }),
    [EntryColumnSlug.Activity]: (placeholder: string) => ({
        ...desktopCells.activityCell,
        width: '15%',
        title: placeholder,
    }),
    [EntryColumnSlug.ZipCode]: (placeholder: string) => desktopCells.getScaZipCell(placeholder),
    [EntryColumnSlug.Receipt]: placeholder => ({
        ...desktopCells.receiptCell,
        title: placeholder,
    }),
    [EntryColumnSlug.Amount]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: placeholder,
        title: placeholder,
        width: '112px',
        headerClassName: classes.hoursAmountHeader,
        render: ({ entry, className, userId, jobNumber }: IEntryRow) => {
            if (isEntryAllowsTimeInTimeOut(entry)) {
                const data = entry.data as IInOutEntryDataBackend | IBreakEntryDataBackend | IInOutBreakDataBackend;
                return (
                    <PlainText
                        className={clsx(className, classes.amountBodyCell)}
                        value={printDurationFromDateTimes(data.time_in, data.time_out, data?.break_minutes || 0)}
                    />
                );
            }
            if (entry.data?.entry_type === QuantityType.FILE) {
                if (jobNumber) {
                    const totalForFiles = totalFileTimePayment([entry], { [jobNumber?.id]: jobNumber });
                    if (totalForFiles) {
                        return (
                            <PlainText
                                className={clsx(className, classes.amountBodyCell)}
                                value={formatDollars(totalForFiles)}
                            />
                        );
                    }
                }
                return (
                    <PlainText className={clsx(className, classes.amountBodyCell)}/>
                );
            }

            return (
                <QuickEditEntry
                    customClasses={clsx(
                        className,
                        classes.bodyCell,
                        classes.amountBodyCell,
                    )}
                    entry={entry}
                    userId={userId}
                />
            );
        },
    }),
    [EntryColumnSlug.Time]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: EntryColumnSlug.Time,
        renderTitle: function TimeCellTitle({ rows }) {
            return isRowsWithoutTimeEntries(rows) ? '' : placeholder;
        },
        width: '1.8fr',
        render: function TimeCell({ entry, className, error }: IEntryRow) {
            if (!isEntryAllowsTimeInTimeOut(entry)) {
                return (
                    <PlainText className={className}/>
                );
            }
            return (
                <Box className={clsx(className, classes.bodyCell, classes.bodyCellWithWarning, classes.pullLeft)}>
                    <TimeInOutQuickEdit
                        entry={entry as ITimeEntry}
                        value={entry.data}
                    />
                    {Boolean(error) && (
                        <p className={classes.bodyCellWarning}>{error}</p>
                    )}
                </Box>
            );
        },
    }),
    [EntryColumnSlug.TimeReadOnly]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function TimeReadOnlyCell({ entry, className }: IEntryRow) {
            if (entry.data?.entry_type === QuantityType.FILE && entry.data?.files) {
                return (
                    <PlainText className={className} value={formatFiles(entry.data.files)}/>
                );
            }
            if (!isEntryAllowsTimeInTimeOut(entry)) {
                return (
                    <PlainText className={className}/>
                );
            }
            const data = entry.data as IInOutEntryDataBackend | IBreakEntryDataBackend;
            const startTime = transformBackendDateTimeToTime(data.time_in);
            const endTime = transformBackendDateTimeToTime(data.time_out);
            const value = `${startTime} - ${endTime}`;

            return (
                <PlainText className={className} value={value}/>
            );
        },
    }),
    [EntryColumnSlug.Break]: placeholder => ({
        key: EntryColumnSlug.Break,
        renderTitle: function TimeCellTitle({ rows }) {
            return isRowsWithoutTimeEntries(rows) ? '' : placeholder;
        },
        width: '120px',
        render: function BreakCell({ entry, className }: IEntryRow) {
            if (entry.data.entry_type === QuantityType.TIME_IN_OUT_BREAK) {
                return (
                    <Box className={className}>
                        <QuickEditBreak entry={entry}/>
                    </Box>
                );
            }
            const totalBreak = QuantityType.TIME_BREAK === entry.data.entry_type
                ? formatMinutes(totalBreakTime([entry]))
                : '';
            return (
                <PlainText
                    className={className}
                    value={totalBreak}
                />
            );
        },
    }),
    [EntryColumnSlug.Location]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function LocationCell({ location, className }: IEntryRow) {
            return (
                <PlainText className={className} value={location?.name}/>
            );
        },
    }),
    [EntryColumnSlug.Position]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function PositionCell({ position, className }: IEntryRow) {
            return (
                <PlainText className={className} value={position?.name}/>
            );
        },
    }),
    [EntryColumnSlug.Department]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function DepartmentCell({ department, className }: IEntryRow) {
            return (
                <PlainText className={className} value={department?.name}/>
            );
        },
    }),
    [EntryColumnSlug.JobNumber]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function JobNumberCell({ jobNumber, className }: IEntryRow) {
            return (
                <PlainText className={className} value={`${jobNumber?.job_number || ''}`}/>
            );
        },
    }),
    [EntryColumnSlug.PayRate]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function PayRateCell({ assignment, className }: IEntryRow) {
            return (
                <PlainText className={className} value={assignment ? `${formatDollars(assignment.pay_rate_value)}/${assignment.pay_rate_type}` : ''}/>
            );
        },
    }),
    [EntryColumnSlug.Hours]: placeholder => ({
        key: placeholder,
        title: placeholder,
        render: function HoursCell({ entry, className }: IEntryRow) {
            return (
                <PlainText className={className} value={formatMinutes(totalTime([entry]))}/>
            );
        },
    }),
    [EntryColumnSlug.Notes]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: EntryColumnSlug.Notes,
        renderTitle: function TimeCellTitle({ rows }) {
            const interviewingTitle = 'Case Numbers';
            const rowsWithInterviews = getRowsWithCompletedInterviews(rows);
            if (rowsWithInterviews === rows.length) {
                return interviewingTitle;
            }
            if (rowsWithInterviews > 0) {
                return `${placeholder}/${interviewingTitle}`;
            }
            return placeholder;
        },
        render: function NotesCell({ entry, className }: IEntryRow){
            const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
            const closeTooltip = useCallback(() => {
                setTooltipIsOpen(false);
            }, [setTooltipIsOpen]);

            const openTooltip = useCallback(() => {
                setTooltipIsOpen(true);
            }, [setTooltipIsOpen]);
            return (
                <Box className={ clsx(className, classes.iconCell) }>
                    {
                        entry.notes && (
                            <ClickAwayListener onClickAway={closeTooltip}>
                                <Tooltip title={entry.notes ?? ''}
                                    open={tooltipIsOpen}>
                                    <IconButton onClick={openTooltip}
                                        onMouseEnter={openTooltip}
                                        onMouseLeave={closeTooltip}>
                                        <PdfSVG/>
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        )
                    }
                </Box>
            );
        },
    }),
    [EntryColumnSlug.Quantity]: placeholder => ({
        key: EntryColumnSlug.Quantity,
        title: placeholder,
        render: function QuantityCell({ entry, className }: IEntryRow){
            const getValue = (): string => {
                switch (entry.data.entry_type) {
                    case QuantityType.ODOMETER:
                        return calculateMiles(entry.data).toString();
                    case QuantityType.MILES:
                        return entry.data.miles.toString();
                    case QuantityType.MONEY:
                        return '1';
                    default:
                        return '';
                }
            };
            return (
                <PlainText className={className} value={getValue()}/>
            );
        },
    }),
    [EntryColumnSlug.Rate]: placeholder => ({
        key: EntryColumnSlug.Rate,
        title: placeholder,
        render: function QuantityCell({ entry, className }: IEntryRow){
            const getValue = (): string => {
                switch (entry.data.entry_type) {
                    case QuantityType.ODOMETER:
                    case QuantityType.MILES:
                        return `${formatDollars(dollarsPerMile.toNumber())}/mile`;
                    case QuantityType.MONEY:
                        return formatDollars(totalDollars([entry]));
                    default:
                        return '';
                }
            };
            return (
                <PlainText className={className} value={getValue()}/>
            );
        },
    }),
    [EntryColumnSlug.AmountReadonly]: (placeholder, classes: ReturnType<typeof useEntriesTableStyles>) => ({
        key: EntryColumnSlug.Rate,
        title: placeholder,
        width: '120px',
        headerClassName: classes.hoursAmountHeader,
        render: function AmountCell({ entry, className, jobNumber }: IEntryRow) {
            switch (entry.data.entry_type) {
                case QuantityType.TIME:
                case QuantityType.TIME_IN_OUT:
                case QuantityType.TIME_IN_OUT_BREAK:
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatMinutes(totalTime([entry]))}
                        />
                    );
                case QuantityType.FILE:
                    if (jobNumber) {
                        const totalForFiles = totalFileTimePayment([entry], { [jobNumber?.id]: jobNumber });
                        if (totalForFiles) {
                            return (
                                <PlainText
                                    className={clsx(className, classes.amountBodyCell)}
                                    value={formatDollars(totalForFiles)}
                                />
                            );
                        }
                    }
                    return (
                        <PlainText className={clsx(className, classes.amountBodyCell)}/>
                    );
                case QuantityType.TIME_BREAK:
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatMinutes(totalBreakTime([entry]))}
                        />
                    );
                default:
                    return (
                        <PlainText
                            className={clsx(className, classes.amountBodyCell)}
                            value={formatDollars(totalDollars([entry]))}
                        />
                    );
            }
        },
    }),
};

export const entryCellDictionaryReadOnly = {
    ...entryCellDictionary,
    [EntryColumnSlug.Time]: entryCellDictionary[EntryColumnSlug.TimeReadOnly],
};

import React from 'react';
import Sidebar, { ISidebarProps } from 'shared/components/sidebars/Sidebar/Sidebar';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import SheetRejectContent from './SheetRejectContent';
import { IUserInfo } from 'shared/models/User';
import { useSheetRejectStyles } from 'shared/components/sidebars/SheetReject/styles';

interface ISheetRejectWrapperProps extends Omit<ISidebarProps, 'title' | 'children'> {
    sheetType: EntryType;
    sheets: Array<ISheet>;
    users: IUserInfo[];
    onAction?: () => void;
}

export default function SheetReject(
    { sheets, users, sheetType, onAction, ...sidebarProps }: ISheetRejectWrapperProps,
) {
    const classes = useSheetRejectStyles();

    return (
        <Sidebar anchor={'bottom'} customClasses={classes.paper}
            title={'REJECT ' + sheetType + (sheetType === EntryType.TIME ? 'SHEETS' : 'S')}
            {...sidebarProps}
        >
            <SheetRejectContent sheets={sheets} users={users}
                sheetType={sheetType} onClose={sidebarProps.onClose}
                onAction={onAction}
            />
        </Sidebar>
    );
}

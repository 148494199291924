import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IPlatformUsersFilter } from 'modules/settings/submodules/platformUsers/store/models';
import {
    selectPlatformUserFilter,
} from 'modules/settings/submodules/platformUsers/store/selectors';
import { useFiltersChipsStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChipsStyles';
import {
    setPlatformUsersFilter,
} from 'modules/settings/submodules/platformUsers/store/actions';
import { FiltersTestIds } from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';
import FilterChip
    from 'modules/settings/submodules/components/FilterChips/FilterChip';

export default function PlatformUserFilterChips() {
    const dispatch = useDispatch();
    const classes = useFiltersChipsStyles();
    const filter = useSelector(selectPlatformUserFilter);

    const onDelete = useCallback((name: keyof IPlatformUsersFilter) => {
        dispatch(setPlatformUsersFilter({
            ...filter,
            [name]: undefined,
        }));
    }, [dispatch, filter]);

    const hasFilters = filter.global_role || filter.client || filter.status;

    return hasFilters ? (
        <Box
            mb={2}
            data-testid={FiltersTestIds.ChipsWrapper}
        >
            {filter.global_role && (
                <FilterChip
                    name="global_role"
                    label={filter.global_role.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}

            {filter.client && (
                <FilterChip
                    name="client"
                    label={filter.client.name}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}

            {filter.status && (
                <FilterChip
                    name="status"
                    label={filter.status}
                    onDelete={onDelete}
                    classes={classes}
                />
            )}
        </Box>
    ) : null;
}

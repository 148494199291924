import React, { useMemo } from 'react';
import { useMediaQuery, Box, Theme } from '@material-ui/core';
import { selectAvailableClients } from 'store/entities/clients/clientsSelectors';
import VerticalMenu from 'shared/components/verticalMenu/VerticalMenu';
import Header from './header/Header';
import clsx from 'clsx';
import { IStore } from 'store/configureStore';
import { getHomeMenu } from './menu';
import { useSidebarState } from 'store/entities/appConfig/utils';
import { isMediumScreenBreakpoint } from 'shared/styles/constants';
import { useHomeStyles } from 'modules/home/HomeStyles';
import { useSelector } from 'react-redux';
import { IClientInfo } from '../../store/entities/clients/clientsModel';
import { Maintaince } from '../../shared/components/alerts/Maintaince';

interface IHomeProps {
    content: JSX.Element;
    sectionTitle?: string;
}

export default function Home({ content, sectionTitle }: IHomeProps) {
    const classes = useHomeStyles();
    const { isSidebarOpened } = useSidebarState();
    const isMenuTemporary = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMediumScreenBreakpoint));
    const clients = Object.values<IClientInfo>(useSelector(selectAvailableClients));
    const clientsThemes = useSelector((state: IStore) => state.clients.configurationThemeByClientId);
    const menu = useMemo(() => getHomeMenu({
        clients,
        clientsThemes,
    }), [clients, clientsThemes]);

    const contentClass = !isMenuTemporary ? isSidebarOpened ? classes.largeShift : classes.smallShift : '';

    return (
        <div
            data-testid="headway-ahead"
            className={clsx('headway-ahead', classes.root)}
        >
            <VerticalMenu
                variant={isMenuTemporary ? 'temporary' : 'persistent'}
                menu={menu}
            />
            <div className={clsx(classes.app, classes.appShifted, contentClass )}>
                <Box>
                    <Header title={sectionTitle}/>
                </Box>
                <main className={classes.main}>
                    <Maintaince/>
                    {content}
                </main>
            </div>
        </div>
    );
}

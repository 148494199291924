import React from 'react';

import { EditItemButton } from 'modules/settings/common/components/EditItemButton/EditItemButton';
import { setEditDealId } from 'modules/settings/submodules/clients/deals/components/EditDeal/store/actions';

export interface IEditDealButtonProps {
    dealId: string;
}

export const EditDealButton = ({ dealId }: IEditDealButtonProps) => {
    return (
        <EditItemButton id={dealId} editAction={setEditDealId}/>
    );
};

import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import NumberInput from 'shared/components/formFields/NumberInput';
import { IModifiers } from './model';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { dollarCounter } from 'shared/utils/counters/dollarCounter';
import { convertToCents } from 'shared/utils/converters/convertToCents';

interface IDollarQuantityProps {
    modifiers: IModifiers;
    value: string;
    onBlur?: any;
    customClasses?: Record<'input' | 'inputRoot', string>;
    disabled?: boolean;
}

const useDollarQuantityStyles = makeHighPriorityStyles({
    wrapper: {
        padding: 0,
        '& fieldset, & fieldset:hover': {
            border: 'none',
        },
        '& input': {
            textAlign: 'right',
        },
    },
});

const DollarQuantity: FC<IDollarQuantityProps> = ({
    customClasses,
    onBlur,
    modifiers,
    value,
    disabled,
}: IDollarQuantityProps) => {
    const classes = useDollarQuantityStyles();
    const [innerValue, setInnerValue] = useState('');
    const [focused, setFocused] = useState(false);

    const onChange = useCallback((newValue: string) => {
        onBlur({ target: { value: newValue ? newValue.toString() : '' } });
        setFocused(false);
        setInnerValue('');
    }, [setFocused, onBlur, setInnerValue]);

    const handleClick = useCallback(() => {
        setFocused(true);
    }, [setFocused]);

    return focused ? (
        <NumberInput
            initialValue={innerValue}
            onChange={onChange}
            className={clsx(customClasses?.inputRoot, classes.wrapper)}
            inputProps={{
                prefix: '$',
                autoFocus: true,
                decimalScale: modifiers.fractional,
                thousandSeparator: true,
                fixedDecimalScale: true,
                allowNegative: false,
            }}
            min={modifiers.min}
            max={modifiers.max}
        />
    ) : (
        <div className={customClasses?.inputRoot} onClick={disabled ? undefined : handleClick}>
            ${dollarCounter(convertToCents(value))}
        </div>
    );
};

export default DollarQuantity;

import React, { useEffect } from 'react';
import TimeAndExpensePageContent from 'modules/clients/content/TimeAndExpensePage/TimeAndExpensePageContent';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getActivities } from 'store/entities/configuration/configurationAction';

export default function TimeAndExpensePage() {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (clientId) {
            dispatch(getActivities.init());
        }
    }, [dispatch, clientId]);
    return (
        <TimeAndExpensePageContent/>
    );
}

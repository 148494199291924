import React from 'react';
import { useParams } from 'react-router';
import { baseUrl } from 'shared/utils/baseApi';
import { Box } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import PdfFileError from 'shared/components/PdfViewer/PdfFileError';
import OfferLetterPdf from '../components/OfferLetterPdf';
import { routes } from 'shared/routes';
import { useSelector } from 'react-redux';
import { selectIsManager } from 'store/components/auth/selectors';

const basePath = `${baseUrl}/documents/offer_letters`;

const useOfferPdfOnlyStyles = makeHighPriorityStyles({
    wrapper: {
        height: '100vh',

        '& > *': {
            height: '100%',
        },
    },
});

export default function OfferPdfOnly() {
    const { id } = useParams<{ id: string }>();
    const classes = useOfferPdfOnlyStyles();
    const isManager = useSelector(selectIsManager);

    return (
        <Box className={classes.wrapper}>
            <OfferLetterPdf
                id={id}
                filePath={`${basePath}/${id}`}
                title="Offer Letter"
                errorRender={(
                    <PdfFileError
                        title="Document generation is in progress."
                        message="Please try again later."
                        // We don't have clintId so we could not transfer the manager to Offer Letters
                        linkLabel={isManager ? 'Go to Home Page' : 'Go to Offer Letters List'}
                        link={isManager ? routes.HOME : routes.EMPLOYEE_OFFER_LETTER.ROOT}
                    />
                )}
            />
        </Box>
    );
}

import React from 'react';
import clsx from 'clsx';
import {
    Theme,
    useMediaQuery,
    Button,
    CircularProgress,
} from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { useMenuStyles } from 'shared/components/verticalMenu/styles';
import { useSidebarState } from 'store/entities/appConfig/utils';
import { isMediumScreenBreakpoint } from 'shared/styles/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId, selectPayrollProcessing } from 'store/entities/clients/clientsSelectors';
import { setPayrollProcessing } from 'store/entities/clients/clientsAction';
import DayPickerField from 'shared/components/formFields/DayPickerField';
import { monthTextDateFormat } from 'shared/models/Dates';
import { Formik } from 'formik';

interface IPayrollData {
    pay_date: string;
}

const PayrollForm: React.FC = () => {
    const classes = useMenuStyles();
    const dispatch = useDispatch();

    const clientId = useSelector(selectCurrentClientId);
    const isPayrollProcessing = useSelector(selectPayrollProcessing);
    const { isSidebarOpened } = useSidebarState();
    const isMenuTemporary = useMediaQuery((theme: Theme) => theme.breakpoints.down(isMediumScreenBreakpoint));
    const isMinimized = !isMenuTemporary && !isSidebarOpened;
    const initialValues = {
        pay_date: '',
    };

    const handlePayroll = ({ pay_date }: IPayrollData) => {

        dispatch(setPayrollProcessing.init({
            client_id: clientId ?? '',
            pay_date,
        }));
    };

    return (
        <Formik
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={handlePayroll}
        >{
                props => (
                    <form {...props}
                        className={classes.form}
                        onSubmit={props.handleSubmit}
                    >
                        <DayPickerField
                            name="pay_date"
                            label="Pay Date"
                            className={!isMinimized ? classes.input : classes.inputMinimized}
                            format={monthTextDateFormat}
                            disableToolbar
                            disablePast
                        />
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size={!isMinimized ? 'medium' : 'small'}
                            className={clsx(!isMinimized ? classes.payrollButton : classes.payrollButtonMinimized)}
                        >
                            {isPayrollProcessing ? (
                                <CircularProgress
                                    color="inherit"
                                    size={16}
                                    className={
                                        clsx(!isMinimized
                                            ? classes.payrollButtonIcon
                                            : classes.payrollButtonIconMinimized)
                                    }
                                />
                            ) : (
                                <SystemUpdateAltIcon
                                    className={
                                        clsx(!isMinimized
                                            ? classes.payrollButtonIcon
                                            : classes.payrollButtonIconMinimized)
                                    }
                                />
                            )}
                            {!isMinimized ? 'Payroll' : ''}
                        </Button>
                    </form>
                )
            }
        </Formik>
    );
};

export default PayrollForm;

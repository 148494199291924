import EditAndResubmitButton from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/EditAndResubmitButton';
import React from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';

export default function SheetsRejectedActions({ typedSheets, variant }: IAvailableActionsProps) {
    const selectedSheets = Object.keys(typedSheets);
    const sheetId = selectedSheets[0];
    const entryType = sheetId ? typedSheets[sheetId] : undefined;

    return (
        <EditAndResubmitButton
            sheetId={sheetId}
            entryType={entryType}
            variant={variant}
        />
    );
}

import { createActivity } from 'modules/settings/submodules/clients/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';
import { isActivityStatusUpdating } from 'modules/settings/submodules/clients/activities/components/ClientActivitiesTable/components/UpdateStatusSelect/store/reducer';

const isCreating = isLoadingReducer(createActivity);

export const activities = combineReducers({
    isCreating,
    isActivityStatusUpdating,
});

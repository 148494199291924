import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CreateDepartmentModal from 'modules/settings/submodules/clients/departments/components/CreateDepartment/CreateDepartmentModal';
import { setCreateDepartmentModalState } from 'modules/settings/submodules/clients/departments/store/actions';

export const CreateDepartmentButton = () => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setCreateDepartmentModalState(true));
    }, [dispatch]);
    return (
        <>
            <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={openModal}>
                + add department
            </Button>
            <CreateDepartmentModal/>
        </>
    );
};

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { useIsMobile } from 'shared/utils/hooks/media';
import { IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { selectIsGroupedSheetsCalculationsLoading, selectPayrollProcessorSheetsHasNextPage } from 'modules/payrollProcessorHub/store/selectors';
import { nextPayrollGroupedSheetPage } from 'modules/payrollProcessorHub/store/actions';
import { useFilteredPayrollSheetRows } from 'modules/payrollProcessorHub/store/helpers';
import InfinityGridTable from 'shared/components/table/GridTable/InfinityGridTable';

export interface IPayrollSheetTableProps {
    cells: ICellInfo<IPayrollSheetRow>[];
}

export const PayrollSheetTable = ({ cells }: IPayrollSheetTableProps) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const tableClasses = useDefaultTableStyles();
    const filteredPayrollSheetRows = useFilteredPayrollSheetRows();
    const hasMoreRows = useSelector(selectPayrollProcessorSheetsHasNextPage);
    const isLoading = useSelector(selectIsGroupedSheetsCalculationsLoading);

    const onLoadMore = useCallback(() => {
        if (!isLoading && hasMoreRows) {
            dispatch(nextPayrollGroupedSheetPage());
        }
    }, [dispatch, hasMoreRows, isLoading]);

    const isFullTableLoading = isLoading && filteredPayrollSheetRows.length === 0;

    return (
        <InfinityGridTable
            rowData={filteredPayrollSheetRows}
            getKey={row => row.id}
            cells={cells}
            isLoading={isFullTableLoading}
            headerCellClassName={tableClasses.headCell}
            bodyCellClassName={tableClasses.bodyCell}
            hideHeader={isMobile}
            stickyHeader
            onLoadMore={onLoadMore}
            hasMore={hasMoreRows}
        />
    );
};

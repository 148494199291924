import { getDepartments } from 'modules/employmentInfo/store/department/actions';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import Sidebar from 'shared/components/sidebars/Sidebar/Sidebar';
import { useSheetDetailStyles } from 'shared/components/sidebars/SheetDetail/styles';
import { selectSheetsGroupById } from 'modules/payrollProcessorHub/store/selectors';
import { browserHistory } from 'shared/utils/browserHistory';
import { nullOrValue } from 'shared/utils/converters/urlParams';
import { userFullName } from 'shared/utils/converters/user';
import { getActivities, getLocations } from 'store/entities/configuration/configurationAction';
import { selectUserById } from 'store/entities/users/selectors';
import { getClientPayCodes } from 'modules/settings/submodules/clients/store/actions';
import { SheetGroupDetail } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/SheetGroupDetail';
import { AppClient } from 'utils/constants';

export default function PayrollSheetDetailSidebar() {
    const dispatch = useDispatch();
    const { timeSheetId, expenseSheetId } = useParams<{
        timeSheetId: string;
        expenseSheetId: string;
    }>();
    const classes = useSheetDetailStyles();
    const sheetGroup = useSelector(selectSheetsGroupById({
        timeSheetId: nullOrValue(timeSheetId),
        expenseSheetId: nullOrValue(expenseSheetId),
    }));
    const user = useSelector(selectUserById(sheetGroup?.user_id || ''));

    const onClose = () => {
        browserHistory.push('../..');
    };
    useEffect(() => {
        if (sheetGroup) {
            if (sheetGroup.assignment_id) {
                dispatch(getLocations.init({
                    assignment_id: sheetGroup.assignment_id,
                }));
            }
            if (sheetGroup.client_id) {
                dispatch(getClientPayCodes.init({
                    client_id: sheetGroup.client_id,
                }));
                dispatch(getActivities.init({
                    client_id: sheetGroup.client_id,
                }));
                dispatch(getDepartments.init({
                    client_id: sheetGroup.client_id,
                }));
            }
        }
    }, [dispatch, sheetGroup]);

    return (
        <Sidebar
            isOpened
            title={userFullName(user)}
            customClasses={process.env.REACT_APP_CLIENT === AppClient.RTI ? classes.paper : undefined}
            titleClasses={{
                root: classes.paperTitle,
                text: classes.paperTitleText,
                icon: classes.paperTitleIcon,
            }}
            onClose={onClose}
        >
            {sheetGroup ? (
                <SheetGroupDetail sheetGroup={sheetGroup}/>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    pt={5}
                >
                    <CircularProgress color="primary"/>
                </Box>
            )}
        </Sidebar>
    );
}

import { Permission } from 'store/components/auth/authModels';
import { lookupUsers } from 'store/entities/users/actions';
import { usersApi } from 'store/entities/users/api';
import { IGetUserRequest } from 'store/entities/users/model';
import { getWildcardMask } from 'store/entities/users/utils';
import { call, put, takeLatest } from 'typed-redux-saga';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';

function* lookupEmployeesSaga(action: ReturnType<typeof lookupUsers.init>) {
    try {
        const { firstName, lastName, phone, email } = action.payload;
        const getUsersResponse = yield* call(usersApi.getUsers, {
            first_name: firstName ? getWildcardMask(firstName) : undefined,
            last_name: lastName ? getWildcardMask(lastName) : undefined,
            email: email ? getWildcardMask(email) : undefined,
            phone: phone ? getWildcardMask(phone) : undefined,
            purpose: Permission.SubmitSheets,
        } as IGetUserRequest);

        yield* put(lookupUsers.success(getUsersResponse.users));
    } catch (e) {
        logErrorWithCustomMessage(e, `Employee lookup failed`);
        yield* put(lookupUsers.error(e));
    }
}

export function* lookupEmployeesWatcher() {
    yield* takeLatest(lookupUsers.initType, lookupEmployeesSaga);
}

export default [
    lookupEmployeesWatcher,
];

import { UPDATE_MENU_ITEMS, updateMenu } from '../actions/menu';
import { IMenuCategory } from 'shared/models/Global';

const defaultState = {
    items: [],
};

export const menuReducer = (
    state: Array<IMenuCategory> = defaultState.items, action: ReturnType<typeof updateMenu>,
): Array<IMenuCategory> => {
    switch (action.type) {
        case UPDATE_MENU_ITEMS:
            return action.payload;
        default:
            return state;
    }
};

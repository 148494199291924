import { SET_CREATE_CLIENT_WORK_LOCATION_MODAL_STATE } from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/state/actions';
import { AnyAction, combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { createClientWorkLocation } from 'modules/settings/submodules/clients/workLocation/store/actions';

const isOpenModal = extendReducer(
    isOpenModalReducer(SET_CREATE_CLIENT_WORK_LOCATION_MODAL_STATE),
    (state = false, action: AnyAction): boolean => {
        switch (action.type) {
            case createClientWorkLocation.successType:
                return false;
            default:
                return state;
        }
    },
);
const isCreating = isLoadingReducer(createClientWorkLocation);

export const createClientLocation = combineReducers({
    isOpenModal,
    isCreating,
});

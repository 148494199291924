import ManagerSheetDetailPage from 'modules/clients/content/timeApproval/ManagerSheetDetailPage';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { routes } from 'shared/routes';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { EntryType } from 'shared/models/sheet/Sheet';
import SheetApproval from 'shared/components/sheetApproval/SheetApproval';
import { useSheetApprovalTabs } from 'modules/clients/header/useSheetApprovalTabs';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getActivities } from 'store/entities/configuration/configurationAction';

const ExpenseApproval: React.FC<{}> = () => {
    useTitleUpdate('Expense Approval', 'Expense Approval');
    useSheetApprovalTabs();

    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        if (clientId) {
            dispatch(getActivities.init());
        }
    }, [dispatch, clientId]);

    return (
        <>
            <SheetApproval sheetType={EntryType.EXPENSE}/>
            <Route
                path={routes.CLIENT.EXPENSE_APPROVAL.SHEET_DETAIL}
                component={() => <ManagerSheetDetailPage entryType={EntryType.EXPENSE}/>}
            />
        </>
    );
};
export default ExpenseApproval;

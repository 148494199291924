import React from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { Box } from '@material-ui/core';
import { DealsTable } from 'modules/settings/submodules/clients/deals/components/DealsTable/DealsTable';
import {
    getControllingOrgs, getDeals, getDealTypes,
    getCostCenters, getSubmittingOrgs, getSubmittingOrgLocations, getUserTypesAction, getActivities,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';
import { DealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/DealFilter';
import { CreateDealButton } from 'modules/settings/submodules/clients/deals/components/CreateDeal/CreateDealButton';
import { DealsStatusFilter } from 'modules/settings/submodules/clients/deals/components/StatusFilter/DealsStatusFilter';
import { getSpecialUsersAction } from 'store/entities/users/actions';
import { Permission } from 'store/components/auth/authModels';
import CreateDealJobNumberModal
    from 'modules/settings/submodules/clients/deals/components/CreateDealJobNumberModal/CreateDealJobNumberModal';
import { AssignUserModal } from 'modules/settings/submodules/clients/jobNumber/components/AssignUser/AssignUserModal';

export function DealsPage() {
    useWithClientEffect((dispatch, clientId) => {
        dispatch(getDeals.init());
        dispatch(getDealTypes.init());
        dispatch(getControllingOrgs.init());
        dispatch(getSubmittingOrgs.init());
        dispatch(getSubmittingOrgLocations.init());
        dispatch(getCostCenters.init());

        //for Job Number:
        dispatch(getUserTypesAction.init());
        dispatch(getActivities.init());
        dispatch(getSpecialUsersAction.init({
            purpose: Permission.ApproveSheets,
            client_id: clientId,
        }));
    });

    return (
        <FeatureSwitch feature={FeatureSwitches.enableGenworthSheets}>
            <Box display="flex" justifyContent="space-between"
                mb={2}>
                <CreateDealButton/>
                <Box>
                    <DealsStatusFilter/>
                    <DealFilter/>
                </Box>
            </Box>
            <DealsTable/>
            <CreateDealJobNumberModal/>
            <AssignUserModal/>
        </FeatureSwitch>
    );
}

/* eslint-disable react/display-name,react/prop-types */
import { Box, IconButton, Typography } from '@material-ui/core';
import { AccessTime, ArrowForwardIos, AttachMoney, Edit } from '@material-ui/icons';
import { useFeature } from '@optimizely/react-sdk';
import clsx from 'clsx';
import { DownloadSheetButton } from 'modules/payrollProcessorHub/components/DownloadSheet/DownloadSheetButton';
import { IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { useGroupedSheetTableStyles } from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles';
import { PayrollStatus } from 'modules/payrollProcessorHub/components/PayrollStatus/PayrollStatus';
import { SendReminderButton } from 'modules/payrollProcessorHub/components/SendReminder/SendReminderButton';
import { IGroupedSheetCalculation, PayrollSheetStatuses, PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { selectCalculationsHasFiles, selectCalculationsHasJobNumbers } from 'modules/payrollProcessorHub/store/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { getCheckboxCell } from 'shared/components/table/Cells/checkBoxHelper';
import PlainText from 'shared/components/table/Cells/PlainText';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { routes } from 'shared/routes';
import { getUserName, userFullName } from 'shared/utils/converters/user';
import { formatDollars } from 'shared/utils/formatters/dollarFormatter';
import { getPayPeriodByStartEnd } from 'shared/utils/formatters/payPeriod';
import { timePaymentFormatter } from 'shared/utils/formatters/timePaymentFormatter';
import { useIsMobile } from 'shared/utils/hooks/media';
import { CheckBoxCellActions } from 'shared/utils/hooks/useCheckedItems';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { getSheetGroupName } from '../../store/helpers';

export const getApproversCell = (sheetRows: IPayrollSheetRow[]) => {
    const atLeastOneRowHasMultipleApprovers = sheetRows.some(sheet => sheet.approvers.length > 1);
    const columnTitle = atLeastOneRowHasMultipleApprovers
        ? 'approvers'
        : 'approver';
    return (
        {
            key: 'approvers',
            title: columnTitle,
            render: ({ className, approvers }: IPayrollSheetRow) =>
                (
                    <PlainText className={className}
                        value={approvers.map(approver => getUserName(approver)).join(', ')}
                    />
                ),
        }
    );
};

export const usePayrollCellDictionary = (): Record<string, ICellInfo<IPayrollSheetRow>> => {
    const tableClasses = useDefaultTableStyles();
    const cellClasses = useGroupedSheetTableStyles();
    const userHasEditPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));

    return {
        details: {
            key: 'details',
            title: '',
            width: '90px',
            render: function DetailsCell({
                className,
                payroll_status,
                expense_sheet_id,
                time_sheet_id,
            }: IPayrollSheetRow) {
                switch (payroll_status) {
                    case PayrollSheetStatuses.MISSING:
                        return (
                            <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                                {userHasEditPermission && (
                                    <Link to={generatePath(routes.PAYROLL_PROCESSOR_HUB.EDIT_SHEET, {
                                        timeSheetId: time_sheet_id || 'null',
                                        expenseSheetId: expense_sheet_id || 'null',
                                    })}>
                                        <IconButton data-testid="details-button">
                                            <Edit/>
                                        </IconButton>
                                    </Link>
                                )}
                            </div>
                        );
                    default:
                        return (
                            <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                                <Link to={generatePath(routes.PAYROLL_PROCESSOR_HUB.SHEET_DETAIL, {
                                    timeSheetId: time_sheet_id || 'null',
                                    expenseSheetId: expense_sheet_id || 'null',
                                })}>
                                    <IconButton data-testid="details-button">
                                        <ArrowForwardIos/>
                                    </IconButton>
                                </Link>
                            </div>
                        );
                }
            },
        },
        overdueDetails: {
            key: 'details',
            title: '',
            width: '204px',
            render: function DetailsCell({ className, time_sheet_id, expense_sheet_id, ...props }: IPayrollSheetRow) {
                return (
                    <div className={clsx(className, tableClasses.iconCell, tableClasses.detailsIcon)}>
                        <DownloadSheetButton
                            timeSheetId={time_sheet_id}
                            expenseSheetId={expense_sheet_id}
                            fileName={getSheetGroupName(
                                { time_sheet_id, expense_sheet_id, ...props } as IGroupedSheetCalculation,
                            )}
                        />
                        {userHasEditPermission && (
                            <SendReminderButton
                                timeSheetId={time_sheet_id}
                                expenseSheetId={expense_sheet_id}
                            />
                        )}
                    </div>
                );
            },
        },
        type: {
            key: 'type',
            title: '',
            width: '45px',
            render: ({ className, time_sheet_id, expense_sheet_id }) => (
                <Box className={className}>
                    {time_sheet_id && (
                        <AccessTime className={cellClasses.timeIcon}/>
                    )}
                    {expense_sheet_id && (
                        <AttachMoney className={cellClasses.expenseIcon}/>
                    )}
                </Box>
            ),
        },
        employee: {
            key: 'employee',
            title: 'employee',
            width: '3fr',
            render: ({ className, employee, position }) => (
                <Box
                    className={clsx(className, cellClasses.employeeCell)}
                    display="flex"
                    flexDirection="column"
                >
                    <Box display="block">{userFullName(employee)}</Box>
                    <Typography variant="caption" className={cellClasses.capturedText}>{position?.name}</Typography>
                </Box>
            ),
        },
        payPeriod: {
            key: 'payPeriod',
            title: 'pay period',
            width: '2fr',
            render: ({ className, pay_period_starts_at, pay_period_ends_at }) =>
                (<PlainText
                    className={className}
                    value={getPayPeriodByStartEnd(pay_period_starts_at, pay_period_ends_at)}
                />),
        },
        client: {
            key: 'client',
            title: 'client',
            width: '2fr',
            render: ({ className, client }) => <PlainText className={className} value={client?.name}/>,
        },
        approvedLevel: {
            key: 'approvedLevel',
            title: 'Approved',
            render: ({ className, time_approved_level, expense_approved_level }) => {
                return (
                    <PlainText className={ className }
                        value={ (time_approved_level || expense_approved_level || 0).toLocaleString() }
                    />);
            },
        },
        jobNumber: {
            key: 'jobNumber',
            title: 'job #',
            width: '1fr',
            render: ({ className, job_number }) => <PlainText className={className} value={job_number}/>,
        },
        dealNumber: {
            key: 'dealNumber',
            title: 'deal #',
            width: '1fr',
            render: ({ className, deal_number }) => <PlainText className={className} value={deal_number}/>,
        },
        files: {
            key: 'files',
            title: 'files | pay',
            width: '100px',
            render: ({ className, time_files, time_files_dollars }) => {
                let value = '';
                if (time_files) {
                    value = `${time_files}`;
                    if (time_files_dollars) {
                        value += ` | ${formatDollars(time_files_dollars)}`;
                    }
                }
                return (
                    <PlainText className={className} value={value}/>
                );
            },
        },
        regularHours: {
            key: 'regularHours',
            title: 'REG HRS | PAY',
            width: '120px',
            headerClassName: tableClasses.pullRight,
            render: ({ className, time_rt_hours, time_rt_dollars }) => (
                <PlainText
                    className={clsx(className, tableClasses.pullRight)}
                    value={timePaymentFormatter(time_rt_hours, time_rt_dollars)}
                />
            ),
        },
        overtimeHours: {
            key: 'overtimeHours',
            title: 'OT HRS | PAY',
            width: '120px',
            headerClassName: tableClasses.pullRight,
            render: ({ className, time_ot_hours, time_ot_dollars }) => (
                <PlainText
                    className={clsx(className, tableClasses.pullRight)}
                    value={timePaymentFormatter(time_ot_hours, time_ot_dollars)}
                />
            ),
        },
        doubleTimeHours: {
            key: 'doubleTimeHours',
            title: 'DT HRS | PAY',
            width: '120px',
            headerClassName: tableClasses.pullRight,
            render: ({ className, time_dt_hours, time_dt_dollars }) => (
                <PlainText
                    className={clsx(className, tableClasses.pullRight)}
                    value={timePaymentFormatter(time_dt_hours, time_dt_dollars)}
                />
            ),
        },
        expenses: {
            key: 'expenses',
            title: 'expenses',
            headerClassName: tableClasses.pullRight,
            render: ({ className, expense_total_dollars }) => (
                <PlainText
                    className={clsx(className, tableClasses.pullRight)}
                    value={formatDollars(expense_total_dollars)}
                />
            ),
        },
        miles: {
            key: 'miles',
            title: 'miles',
            headerClassName: tableClasses.pullRight,
            render: ({ className, expense_total_miles }) => {
                const miles = parseFloat(expense_total_miles || '');
                return (
                    <PlainText
                        className={clsx(className, tableClasses.pullRight)}
                        value={(miles && miles.toString()) || '0'}
                    />
                );
            },
        },
        totalPay: {
            key: 'totalPay',
            title: 'total pay',
            width: '100px',
            headerClassName: clsx(tableClasses.pullRight, cellClasses.bold),
            render: ({ className, total_dollars }) => (
                <PlainText
                    className={clsx(className, tableClasses.pullRight, cellClasses.bold)}
                    value={formatDollars(total_dollars)}
                />
            ),
        },
        status: {
            key: 'status',
            title: 'status',
            width: 'max-content',
            render: ({ className, payroll_status }) => (
                <PayrollStatus
                    className={className}
                    status={payroll_status}
                />
            ),
        },
        approvedByCell: {
            key: 'approvedBy',
            title: 'approved by',
            width: '2fr',
            render: ({ className, approvedBy }: IPayrollSheetRow) =>
                (
                    <PlainText
                        className={className}
                        value={approvedBy.map(approver => getUserName(approver)).join(', ')}
                    />
                ),
        },
    };
};

export const usePayrollSheetTableCells = (
    activeStatus: PayrollSheetTabs,
    checkedItemsActions: CheckBoxCellActions,
): ICellInfo<IPayrollSheetRow>[] => {
    const tableClasses = useDefaultTableStyles();
    const userHasEditPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));
    const hasBatchActions = userHasEditPermission
        && [PayrollSheetTabs.APPROVED, PayrollSheetTabs.SUBMITTED].includes(activeStatus);
    const calculationsHasJobNumber = useSelector(selectCalculationsHasJobNumbers);
    const calculationsHasFiles = useSelector(selectCalculationsHasFiles);
    const isMobile = useIsMobile();
    const checkbox = hasBatchActions ? [getCheckboxCell(
        checkedItemsActions.checkedItems,
        checkedItemsActions.onCheck,
        checkedItemsActions.checkedAll,
        checkedItemsActions.onCheckAll,
        tableClasses,
        isMobile,
        (row: IPayrollSheetRow) => row.id,
    )] : [];
    const cellDictionary = usePayrollCellDictionary();
    const statusOrMilesCell = activeStatus === PayrollSheetTabs.ALL ? cellDictionary.status : cellDictionary.miles;
    const optionalApprovedLevelCell = [
        PayrollSheetTabs.ALL, PayrollSheetTabs.SUBMITTED, PayrollSheetTabs.APPROVED,
    ].includes(activeStatus)
        ? [cellDictionary.approvedLevel]
        : [];
    const [enableApprovedByCell] = useFeature(FeatureSwitches.enablePPHFilterByManager);

    return [
        ...checkbox,
        cellDictionary.type,
        ...(process.env.REACT_APP_CLIENT_ID ? [] : [cellDictionary.client]),
        cellDictionary.payPeriod,
        cellDictionary.employee,
        ...(calculationsHasJobNumber ? [
            cellDictionary.jobNumber,
            cellDictionary.dealNumber,
        ] : []),
        ...(calculationsHasFiles ? [cellDictionary.files] : []),
        cellDictionary.regularHours,
        cellDictionary.overtimeHours,
        cellDictionary.doubleTimeHours,
        cellDictionary.expenses,
        statusOrMilesCell,
        ...optionalApprovedLevelCell,
        ...(enableApprovedByCell ? [cellDictionary.approvedByCell] : []),
        cellDictionary.totalPay,
        cellDictionary.details,
    ];
};

export const usePayrollMissingSheetTableCells = (sheetRows: IPayrollSheetRow[]): ICellInfo<IPayrollSheetRow>[] => {
    const cellDictionary = usePayrollCellDictionary();
    const userHasEditPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));

    return [
        cellDictionary.type,
        cellDictionary.employee,
        cellDictionary.payPeriod,
        ...(process.env.REACT_APP_CLIENT_ID ? [] : [cellDictionary.client]),
        getApproversCell(sheetRows),
        ...(userHasEditPermission ? [cellDictionary.details] : []),
    ];
};

export const usePayrollOverdueSheetTableCells = (sheetRows: IPayrollSheetRow[]): ICellInfo<IPayrollSheetRow>[] => {
    const cellDictionary = usePayrollCellDictionary();

    return [
        cellDictionary.employee,
        cellDictionary.payPeriod,
        ...(process.env.REACT_APP_CLIENT_ID ? [] : [cellDictionary.client]),
        getApproversCell(sheetRows),
        cellDictionary.totalPay,
        cellDictionary.overdueDetails,
    ];
};

import React from 'react';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { Theme, useMediaQuery } from '@material-ui/core';
import { EntryType, ISheetClickInfo } from 'shared/models/sheet/Sheet';
import { ISheetRow, useCells } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import SheetsSubmittedDesktopView
    from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedDesktopView';
import SheetsSubmittedMobileView
    from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedMobileView';
import { useTimeAndExpensePageContent } from 'modules/clients/content/TimeAndExpensePage/TimeAndExpenseHeaderContent';

interface ISheetsSubmittedViewProps {
    timePrefixCell?: ICellInfo<ISheetRow>;
    expensePrefixCell?: ICellInfo<ISheetRow>;
    timeRows: Array<ISheetRow>;
    expenseRows: Array<ISheetRow>;
    onDetailsClick?: (sheetInfo: ISheetClickInfo) => void;
    showStatus?: boolean;
    showFilters?: boolean;
    isLoading: boolean;
    sheetsEntryTypes?: Record<string, EntryType>;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

export default function SheetsSubmittedView({
    timePrefixCell, expensePrefixCell, timeRows,
    expenseRows, onDetailsClick, showStatus, isLoading, showFilters,
    sheetsEntryTypes, availableActions,
}: ISheetsSubmittedViewProps) {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    useTimeAndExpensePageContent();

    const timeCells = useCells(
        EntryType.TIME, isMobile, timePrefixCell, onDetailsClick, showStatus, timeRows,
    );
    const expenseCells = useCells(
        EntryType.EXPENSE, isMobile, expensePrefixCell, onDetailsClick, showStatus,
    );

    return isMobile ? (
        <SheetsSubmittedMobileView timeRows={timeRows} expenseRows={expenseRows}
            timeCells={timeCells} expenseCells={expenseCells}
            isLoading={isLoading}
            sheetsEntryTypes={sheetsEntryTypes}
            availableActions={availableActions}
        />
    ) : (
        <SheetsSubmittedDesktopView timeRows={timeRows} expenseRows={expenseRows}
            timeCells={timeCells} expenseCells={expenseCells}
            isLoading={isLoading}
            showFilters={showFilters}
            sheetsEntryTypes={sheetsEntryTypes}
            availableActions={availableActions}
        />
    );
}

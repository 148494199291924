import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalDialog } from 'shared/components/modals/ModalDialog';
import { useSettingsFormModalStyles } from 'modules/settings/submodules/components/sharedStyles/modalStyles';
import { IOfferLetterTemplateFormValues } from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/model';
import OfferLetterTemplateFormWrapper from 'modules/settings/submodules/offerLetters/components/OfferLetterTemplateForm/OfferLetterTemplateFormWrapper';
import { createOfferLetterTemplate, setCreateOfferLetterTemplateModalState } from 'modules/settings/submodules/offerLetters/store/action';
import { selectIsCreateOfferLetterTemplateModalOpened, selectIsOfferLetterTemplateCreating } from 'modules/settings/submodules/offerLetters/store/selectors';

export default function CreateOfferLetterTemplateModal() {
    const dispatch = useDispatch();
    const classes = useSettingsFormModalStyles();

    const isLoading = useSelector(selectIsOfferLetterTemplateCreating);
    const onSave = useCallback((values: IOfferLetterTemplateFormValues) => {
        dispatch(createOfferLetterTemplate.init(values));
    }, [dispatch]);
    const isOpen = useSelector(selectIsCreateOfferLetterTemplateModalOpened);
    const onClose = useCallback(() => {
        dispatch(setCreateOfferLetterTemplateModalState(false));
    }, [dispatch]);

    return (
        <ModalDialog
            title="Add New Offer Letter Template"
            isOpened={isOpen}
            onClose={onClose}
            modalProps={{
                customClasses: classes,
                showCloseIcon: true,
            }}
        >
            <OfferLetterTemplateFormWrapper
                onSubmit={onSave}
                submitTitle="Add"
                isLoading={isLoading}
            />
        </ModalDialog>
    );
}

import {
    EntryType,
    IBreakEntryDataBackend,
    IEntry,
    IInOutEntryDataBackend,
    QuantityType,
} from 'shared/models/sheet/Sheet';
import { EntriesGridCellsTitles, IEntryRow } from 'shared/components/table/EntriesTable/model';
import moment from 'moment';
import { getMomentFromBackendDateTime } from 'shared/models/DateTime';

interface ISheetTypeDependentTitles {
    scaZipTitle: string;
    hoursAmountTitle: string;
}

export function getSheetTypeDependentTitles(entryFilter?: EntryType): ISheetTypeDependentTitles {
    const scaZip = [];
    const hoursAmount = [];

    if (entryFilter !== EntryType.EXPENSE) {
        scaZip.push(EntriesGridCellsTitles.ScaZone);
        hoursAmount.push(EntriesGridCellsTitles.Hours);
    }
    if (entryFilter !== EntryType.TIME) {
        scaZip.push(EntriesGridCellsTitles.ZipCode);
        hoursAmount.push(EntriesGridCellsTitles.Amount);
    }

    return {
        scaZipTitle: scaZip.join('/'),
        hoursAmountTitle: hoursAmount.join('/'),
    };
}

export function getEntryRowId(entryId: string) {
    return `entry.row.${entryId}`;
}

export function getEntryRowIdByRow(entryRow: IEntryRow): string {
    return getEntryRowId(entryRow.entry.id);
}

export function getEntryIdByRowId(rowId: string): string {
    return rowId.split('.').pop() as string;
}

export enum EntryErrors {
    BreakEntryIsOutsideBoundary = 'Break time is outside of activity time',
}

const getEntryTimeBreakEntryErrorsById = (entries: Array<IEntry>): Record<string, string> => {
    const breakEntries = entries.filter(entry => (
        entry.entry_type === EntryType.TIME && entry.data.entry_type === QuantityType.TIME_BREAK
    ));
    const timeInOutEntries = entries.filter(entry => (
        entry.entry_type === EntryType.TIME && entry.data.entry_type === QuantityType.TIME_IN_OUT
    )).map(entry => entry.data) as IInOutEntryDataBackend[];
    const shiftTimeIn = moment.min(
        timeInOutEntries.map(entryData => getMomentFromBackendDateTime(entryData.time_in)),
    );
    const shiftTimeOut = moment.max(
        timeInOutEntries.map(entryData => getMomentFromBackendDateTime(entryData.time_out)),
    );

    const errorsById: Record<string, string> = {};

    // Find time boundaries or time_in/time_out rows.
    breakEntries.forEach(breakEntry => {
        const entryData = breakEntry.data as IBreakEntryDataBackend;
        const breakStart = getMomentFromBackendDateTime(entryData.time_in);
        const breakEnd = getMomentFromBackendDateTime(entryData.time_out);
        if (breakStart.isBefore(shiftTimeIn) || breakEnd.isAfter(shiftTimeOut)) {
            errorsById[breakEntry.id] = EntryErrors.BreakEntryIsOutsideBoundary;
        }
    });

    return errorsById;
};

export const getEntryErrorsById = (entryRows: Array<IEntry>): Record<string, string> => {
    const timeBreakErrors = getEntryTimeBreakEntryErrorsById(entryRows);
    return {
        ...timeBreakErrors,
    };
};

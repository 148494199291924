import React from 'react';
import { Box } from '@material-ui/core';
import { EntryType } from 'shared/models/sheet/Sheet';
import FilterAndActionControls from 'shared/components/sheetsSubmitted/filterAndActionControls/FilterAndActionControls';
import { useSheetsSubmittedViewStyles } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedPageStyles';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import GridTable from 'shared/components/table/GridTable/GridTable';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { pluralize } from 'shared/utils/formatters/pluralize';
import { useSelector } from 'react-redux';
import { selectEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';

interface ISheetsSubmittedDesktopViewProps {
    timeCells: Array<ICellInfo<ISheetRow>>;
    expenseCells: Array<ICellInfo<ISheetRow>>;
    timeRows: Array<ISheetRow>;
    expenseRows: Array<ISheetRow>;
    isLoading: boolean;
    showFilters?: boolean;
    sheetsEntryTypes?: Record<string, EntryType>;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

const getTitle = (hasFilters: boolean, amount: number) => hasFilters
    ? `${amount} ${pluralize('result', amount)}.` : undefined;

export default function SheetsSubmittedDesktopView({
    timeCells, expenseCells, timeRows, expenseRows, isLoading, showFilters, sheetsEntryTypes, availableActions,
}: ISheetsSubmittedDesktopViewProps) {
    const classes = useSheetsSubmittedViewStyles();
    const tableClasses = useSubmittedTableStyles();

    const hasFilters: boolean = isNotEmpty(useSelector(selectEmployeeSubmittedSheetsFilters)) && (showFilters ?? true);

    const timeTitle = getTitle(hasFilters, timeRows.length);
    const expenseTitle = getTitle(hasFilters, expenseRows.length);

    return (
        <>
            <Box className={classes.mainContainer}>
                <FilterAndActionControls
                    showFilters={showFilters ?? true}
                    sheetType={EntryType.TIME}
                    title={timeTitle}
                    sheetsEntryTypes={sheetsEntryTypes}
                    availableActions={availableActions}
                />
                <Box className={tableClasses.tableMain}>
                    <GridTable rowData={timeRows}
                        headerCellClassName={tableClasses.headCell}
                        bodyCellClassName={tableClasses.bodyCell}
                        getKey={({ sheet }) => sheet.id}
                        cells={timeCells}
                        isLoading={isLoading}
                        stickyHeader
                    />
                </Box>
            </Box>
            <Box className={classes.mainContainer} mb={4}>
                <FilterAndActionControls
                    showFilters={false}
                    sheetType={EntryType.EXPENSE}
                    title={expenseTitle}
                    sheetsEntryTypes={sheetsEntryTypes}
                    availableActions={availableActions}
                />
                <Box className={tableClasses.tableMain}>
                    <GridTable rowData={expenseRows}
                        headerCellClassName={tableClasses.headCell}
                        bodyCellClassName={tableClasses.bodyCell}
                        getKey={({ sheet }) => sheet.id}
                        cells={expenseCells}
                        isLoading={isLoading}
                        stickyHeader
                    />
                </Box>
            </Box>
        </>
    );
}

import { IClientRole, IGetUsersResponse, IGlobalRole, IUserInfo } from 'shared/models/User';
import baseApi from 'shared/utils/baseApi';
import {
    IEmailConfirmation,
    IGetUserRequest,
    INewPasswordAfterResetingRequest,
    INewPasswordAfterResetingResponse,
    INewPasswordCodeCheckingRequest,
    IPasswordResetingRequest,
    ISignupUserInfo,
    IUserSignupRequest,
} from 'store/entities/users/model';

class UsersApi {
    async getUsers(request: IGetUserRequest): Promise<IGetUsersResponse> {
        const { data } = await baseApi.get<IGetUsersResponse>(
            `/client-users/users`,
            request,
        );
        return data;
    }
    async getUsersWithoutPagination(request: IGetUserRequest): Promise<IUserInfo[]> {
        // POST used for large requests
        const { data } = await baseApi.post<IGetUserRequest, IGetUsersResponse>(
            `/client-users/get_users`,
            request,
        );
        return data.users;
    }
    async registration(request: IUserSignupRequest): Promise<IUserInfo> {
        const { data } = await baseApi.create<IUserSignupRequest, IUserInfo>(
            'client-users', 'users/signup', request,
        );
        return data;
    }
    async getSignupUserInfoByToken(token: string): Promise<ISignupUserInfo> {
        const { data } = await baseApi.get<ISignupUserInfo>(
            `/client-users/signup_tokens/${token}`,
        );
        return data;
    }
    async emailConfirmation(request: IEmailConfirmation): Promise<string> {
        const { data } = await baseApi.create<IEmailConfirmation, { status: string }>(
            'client-users', 'email/confirmation', request,
        );
        return data.status;
    }
    async passwordResetting(request: IPasswordResetingRequest): Promise<string> {
        const { data } = await baseApi.create<IPasswordResetingRequest, { status: string }>(
            'client-users', 'password/reset', request,
        );
        return data.status;
    }
    async newPasswordAfterResetting(
        request: INewPasswordAfterResetingRequest,
    ): Promise<INewPasswordAfterResetingResponse> {
        const { data } = await baseApi.create<INewPasswordAfterResetingRequest, INewPasswordAfterResetingResponse>(
            'client-users', 'password/change', request,
        );
        return data;
    }
    async newPasswordCodeChecking(request: INewPasswordCodeCheckingRequest): Promise<string> {
        const { email, secret_code } = request;
        const { data } = await baseApi.head<string>(
            `client-users/password/reset/${email}/secret/${secret_code}`,
        );
        return data;
    }
    async getGlobalRoles(): Promise<IGlobalRole[]> {
        const { data } = await baseApi.get<{ global_roles: IGlobalRole[]}>(
            'client-users/global_roles',
        );
        return data.global_roles;
    }
    async getClientRoles(): Promise<IClientRole[]> {
        const { data } = await baseApi.get<{ client_roles: IClientRole[]}>(
            'client-users/client_roles',
        );
        return data.client_roles;
    }
}

export const usersApi = new UsersApi();

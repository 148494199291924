import SheetRejectedView from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetRejectedView';
import { useOpenSheetDetail } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/useOpenSheetDetail';
import React from 'react';
import { useSelector } from 'react-redux';

import { Theme, useMediaQuery } from '@material-ui/core';
import { useCheckedSheets } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/checkedSheets';
import {
    useSheetsData,
} from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import SheetsRejectedActions from 'modules/clients/content/TimeAndExpensePage/RejectedSheets/SheetsRejectedActions';
import { selectEmployeeSubmittedEditSheet } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';

export default function SheetsRejectedPage() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const { timeSheets, expenseSheets, timeRows, expenseRows, isLoading } = useSheetsData();
    const handleSheetClick = useOpenSheetDetail();
    const { sheetsEntryTypes, timeCheckbox, expenseCheckbox } = useCheckedSheets(isMobile, timeSheets, expenseSheets);
    const editSheetInfo = useSelector(selectEmployeeSubmittedEditSheet);

    return (
        <>
            {editSheetInfo ? (
                <SheetRejectedView
                    sheetId={editSheetInfo.sheetId}
                    entryType={editSheetInfo.entryType}
                />
            ) : (
                <>
                    <SheetsSubmittedView
                        expenseRows={expenseRows}
                        timeRows={timeRows}
                        expensePrefixCell={expenseCheckbox}
                        timePrefixCell={timeCheckbox}
                        onDetailsClick={handleSheetClick}
                        isLoading={isLoading}
                        sheetsEntryTypes={sheetsEntryTypes}
                        availableActions={SheetsRejectedActions}
                    />
                </>
            )}
        </>
    );
}

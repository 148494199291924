import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';

export interface IPositionWorkLocationAssociationFormValues {
    location: ILocation | null;
    position: IPosition | null;
}

export const defaultPositionWorkLocationAssociationValues: IPositionWorkLocationAssociationFormValues = {
    location: null,
    position: null,
};

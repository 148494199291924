import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

const useAddEntryControlsStyles = makeHighPriorityStyles(() => ({
    paper: {
        borderRadius: 0,
        marginBottom: 25,
        border: `1px solid ${colors.gray}`,
    },
}));

export default useAddEntryControlsStyles;

import { selectDealsState } from 'modules/settings/submodules/clients/deals/store/selectors';
import {
    selectControllingOrgsById,
    selectSubmittingOrgsById,
} from 'store/entities/configuration/configurationSelectors';
import { createSelector } from 'reselect';
import {
    IInvoiceRecipientSelectOption,
} from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/models';
import { ItemsById } from 'shared/models/ItemsById';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { IStore } from 'store/configureStore';
import { IInvoiceRecipient, RecipientType } from 'shared/models/Deal';

export const selectInvoiceRecipients = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.invoiceRecipients;
};

export const selectInvoiceRecipientById = (id: string) => (state: IStore) => {
    const invoiceRecipients = selectInvoiceRecipients(state);
    return invoiceRecipients.find(recipient => recipient.recipient_id === id);
};

export const selectIsInvoiceRecipientsLoading = (state: IStore) => {
    const dealsState = selectDealsState(state);
    return dealsState.invoiceRecipientsIsLoading;
};

export const selectInvoiceRecipientsSelectOptions = (
    createSelector(
        selectInvoiceRecipients,
        selectControllingOrgsById,
        selectSubmittingOrgsById,
        (invoiceRecipients, controllingOrgsById, submittingOrgsById) => {

            return invoiceRecipients.map(recipient => {
                return {
                    id: recipient.recipient_id,
                    name: getRecipientName(recipient, controllingOrgsById, submittingOrgsById),
                } as IInvoiceRecipientSelectOption;
            });
        },
    )
);

function getRecipientName(
    recipient: IInvoiceRecipient,
    controllingOrgsById: ItemsById<IControllingOrg>,
    submittingOrgsById: ItemsById<ISubmittingOrg>){
    if (recipient.recipient_type === RecipientType.ControllingOrg){
        return controllingOrgsById[recipient.recipient_id]?.name || '';
    } else {
        return submittingOrgsById[recipient.recipient_id]?.client_site_name || '';
    }
}

import { OnboardingSteps } from 'modules/offerLetter/components/OfferLetterEmployeeDetail/model';
import {
    getMyEmployeeProfile, getPrismSSOLink,
    OfferLetterActions, SET_ONBOARDING_STEP,
    updateEmployeeProfile,
    updateOfferLetter,
} from 'modules/offerLetter/store/actions';
import { combineReducers } from 'redux';
import { isLoadingReducer } from 'store/reducerUtils';

export const isOfferUpdating = isLoadingReducer(updateOfferLetter);
export const isProfileUpdating = isLoadingReducer(updateEmployeeProfile);
export const isSSOLinkLoading = isLoadingReducer(getPrismSSOLink);
export const isEmployeeProfileLoading = isLoadingReducer(getMyEmployeeProfile);

function isProfileCreated(
    state = false,
    action: OfferLetterActions,
): boolean {
    switch (action.type) {
        case updateEmployeeProfile.successType:
        case getMyEmployeeProfile.successType:
            return true;
        case updateEmployeeProfile.errorType:
        case getMyEmployeeProfile.errorType:
            return false;
        default:
            return state;
    }
}

function SSOLink(
    state = '',
    action: OfferLetterActions,
): string {
    switch (action.type) {
        case getPrismSSOLink.successType:
            return action.payload;
        case getPrismSSOLink.initType:
        case getPrismSSOLink.errorType:
            return '';
        default:
            return state;
    }
}

function onboardingStep(
    state: OnboardingSteps = OnboardingSteps.ViewPdf,
    action: OfferLetterActions,
): OnboardingSteps {
    switch (action.type) {
        case SET_ONBOARDING_STEP:
            return action.payload;
        default:
            return state;
    }
}

export const employeeOfferDetail = combineReducers({
    onboardingStep,
    isOfferUpdating,
    isProfileUpdating,
    isProfileCreated,
    isSSOLinkLoading,
    isEmployeeProfileLoading,
    SSOLink,
});

import { isLoadingReducer } from 'store/reducerUtils';
import { combineReducers } from 'redux';
import { getTimeReviewerSheets, TimeApprovalActions } from 'store/components/timeApproval/timeApprovalActions';

const initialState = {
    isLoaded: false,
};

const isLoading = isLoadingReducer(getTimeReviewerSheets);

function isLoaded(state = initialState.isLoaded, action: TimeApprovalActions): boolean {
    switch (action.type) {
        case getTimeReviewerSheets.successType:
            return true;
        case getTimeReviewerSheets.initType:
            return false;
        default:
            return state;
    }
}

export const timeApproval = combineReducers({
    isLoading,
    isLoaded,
});

import { IStore } from '../configureStore';
import { AnyAction, combineReducers, ReducersMapObject, StateFromReducersMapObject } from 'redux';
import { isLoadingReducer } from '../reducerUtils';
import { IActionsCreatorCommon } from '../utils';

export interface ILoadingListItemReduxState extends Record<string, boolean> {

}

interface ILoadingListItemReduxConstructor<ActionCreator, ActionType> {
    actionCreator: ActionCreator;
    moduleName: string;
    getUniqKey: (action: ActionType) => string;
    submoduleName?: string;
}

export class LoadingListItemRedux<
    ActionCreator extends IActionsCreatorCommon,
    ActionType extends AnyAction
> {
    private moduleName: string;
    private submoduleName: string;
    private actionCreator: ActionCreator;
    private getUniqKey: (action: ActionType) => string;

    constructor({
        actionCreator,
        moduleName,
        getUniqKey,
        submoduleName = 'isLoadingForListItem',
    }: ILoadingListItemReduxConstructor<ActionCreator, ActionType>) {
        this.moduleName = moduleName;
        this.submoduleName = submoduleName;
        this.actionCreator = actionCreator;
        this.getUniqKey = getUniqKey;
    }

    /**
     * Reducer for working with states of loading for array of entity
     * Use isLoadingReducer helper inside
     * @param state
     * @param action
     * @private
     */
    private reducer(
        state: ILoadingListItemReduxState = LoadingListItemRedux.initialValue(),
        action: ActionType,
    ): ILoadingListItemReduxState {
        if (![
            this.actionCreator.initType,
            this.actionCreator.successType,
            this.actionCreator.errorType,
        ].includes(action.type)) {
            return state;
        }
        const uniqKey = this.getUniqKey(action);
        const isLoading = isLoadingReducer(this.actionCreator)(state[uniqKey], action);
        return {
            ...state,
            [uniqKey]: isLoading,
        };
    }

    /**
     * Patch input reducers map with specific reducer for loading items
     * @param {object} reducers
     */
    withReducer(reducers: ReducersMapObject<any, any>): ReducersMapObject<any, any> {
        return {
            ...reducers,
            [this.moduleName]: combineReducers({
                [this.submoduleName]: this.reducer.bind(this),
            }),
        };
    }

    /**
     * Predefined state for loading - just empty object
     * @private
     */
    private static initialValue(): ILoadingListItemReduxState {
        return {};
    }

    /**
     * Add loading object to input initial state object
     * @param {object} state
     */
    withInitialValue(state: StateFromReducersMapObject<ReducersMapObject>):
    StateFromReducersMapObject<ReducersMapObject> {
        return {
            ...state,
            [this.moduleName]: {
                [this.submoduleName]: LoadingListItemRedux.initialValue(),
            },
        };
    }

    /**
     * Select isLoading flag for exact sheetGroup for loading action
     */
    getSelector = (id: string, getState: (state: IStore) => StateFromReducersMapObject<ReducersMapObject>) =>
        (state: IStore) => getState(state)[this.moduleName][this.submoduleName][id] || false;
}

import { DealNumberSelect } from 'shared/components/selects/DealNumberSelect';
import React, { useEffect } from 'react';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { Permission } from 'store/components/auth/authModels';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import { IJobNumberFilter } from 'modules/settings/submodules/clients/jobNumber/store/models';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';

export interface IJobNumberFilterFormProps extends FormikProps<IJobNumberFilter> {
    onChange: (value: Partial<IJobNumberFilter>) => void;
    actualFilterValues: IJobNumberFilter;
}

export const JobNumberFilterForm = ({
    actualFilterValues,
    handleSubmit,
    onChange,
    values,
}: IJobNumberFilterFormProps) => {
    const classes = useInlineFormStyles();
    const clientId = useSelector(selectCurrentClientId);

    useEffect(() => {
        if (!isEqual(values, actualFilterValues)) {
            onChange({
                userId: values.userId || null,
                dealId: values.dealId || null,
            });
        }
    }, [actualFilterValues, values, onChange]);

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <UserAutocompleteWithSearch
                additionalFilter={{
                    purpose: Permission.SubmitSheets,
                    client_id: clientId || undefined,
                }}
                name="userId"
                label="Employee"
                useIdValue
                className={classes.input}
            />
            <DealNumberSelect
                name="dealId"
                label="Deal"
                useIdValue
                className={classes.input}
            />
        </form>
    );
};

import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { AppSettingsForm } from './AppSettingsForm';
import {
    selectClientTimeAndPayConfiguration,
    selectCurrentClientId,
    selectIsLoadingFieldsConfiguration,
} from 'store/entities/clients/clientsSelectors';
import { updateAppSettings } from './store/actions';
import { selectIsAppSettingsUpdating } from './store/selectors';
import { ITimeAndPayClientConfiguration } from 'store/entities/clients/clientsModel';
import { appSettingsValidationsSchema } from './validationScheme';

export const ApplicationSettingsFormWrapper = () => {
    const clientId = useSelector(selectCurrentClientId);
    const appSettings = useSelector(selectClientTimeAndPayConfiguration);
    const isLoading = useSelector(selectIsLoadingFieldsConfiguration);
    const isUpdating = useSelector(selectIsAppSettingsUpdating);

    const dispatch = useDispatch();
    const onSubmit = useCallback( (formValues: Partial<ITimeAndPayClientConfiguration>) => {
        if (clientId) {
            dispatch(updateAppSettings.init({
                clientId,
                data: formValues,
            }));
        }
    }, [dispatch, clientId]);

    if (isLoading || !appSettings) {
        return (
            <Box ml={3}>
                <CircularProgress/>
            </Box>
        );
    }

    const initialValues = {
        approvalLevels: appSettings?.approvalLevels || 1,
    };

    return (

        <Formik
            key="PaySettingsForm"
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validationSchema={appSettingsValidationsSchema}
        >
            {props => (
                <AppSettingsForm
                    isLoading={isUpdating}
                    {...props}
                />
            )}
        </Formik>
    );
};

import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import FormField from 'shared/components/formFields/FormField';
import { formBasicSpacing, useSettingsFormStyles } from 'modules/settings/submodules/components/sharedStyles/formStyles';
import { AddressFormBlock } from 'modules/settings/submodules/components/AddressFormBlock/AddressFormBlock';
import { IUserFormValues } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/model';
import { GlobalRoleSelect } from 'modules/settings/submodules/platformUsers/components/PlatformUserForm/GlobalRoleSelect';
import PhoneField from 'shared/components/formSpecialFields/phoneField/PhoneField';

export interface IEditFormProps extends FormikProps<IUserFormValues> {
    isLoading?: boolean;
    isDisabled?: boolean;
    submitTitle: string;
    onCancel?: () => void;
}

export function UserForm({
    handleSubmit,
    setFieldValue,
    submitTitle,
    isLoading = false,
    isDisabled = false,
}: IEditFormProps) {
    const classes = useSettingsFormStyles();

    return (
        <form onSubmit={handleSubmit} className={clsx(classes.form, classes.smallPadding)}>
            <Box className={classes.formBlock}>
                <Typography variant="subtitle2" className={clsx(classes.formBlockTitle)}>Client Details</Typography>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="first_name"
                            label="First Name"
                            className={classes.input}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="last_name"
                            label="Last Name"
                            className={classes.input}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="job_title"
                            label="Job Title"
                            className={classes.input}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box width="60%">
                        <FormField
                            name="email"
                            label="Email"
                            type="email"
                            className={classes.input}
                        />
                    </Box>
                    <Box width="40%">
                        <PhoneField
                            name="cell_phone"
                            label="Phone Number"
                            className={classes.input}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box width="60%">
                        <GlobalRoleSelect
                            useIdValue
                            name="global_role_id"
                            label="Global Role"
                            className={classes.input}
                        />
                    </Box>
                </Box>
            </Box>

            <AddressFormBlock
                title={'User Address'}
                setFieldValue={setFieldValue}
            />

            <Box m={formBasicSpacing} mt={2}>
                <ButtonWithLoader
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    disabled={isDisabled}
                    isLoading={isLoading}
                    data-testid="submit_update_button"
                >
                    {submitTitle}
                </ButtonWithLoader>
            </Box>
        </form>
    );
}

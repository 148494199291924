import React from 'react';
import { Formik } from 'formik';

import { DealForm } from 'modules/settings/submodules/clients/deals/components/DealForm/DealForm';
import { IDealFormValues } from 'modules/settings/submodules/clients/deals/components/DealForm/model';
import { IDeal } from 'shared/models/Deal';
import { DealValidationSchema } from 'modules/settings/submodules/clients/deals/components/DealForm/ValidationsSchema';

export interface IDealFormWrapperProps {
    onSubmit: (values: IDealFormValues) => void;
    onCancel?: () => void;
    submitTitle: string;
    submitAndGoToJobNumberTitle: string;
    isLoading: boolean;
    deal?: IDeal;
}

const DealFormWrapper = ({
    onSubmit,
    onCancel,
    submitTitle,
    submitAndGoToJobNumberTitle,
    isLoading,
    deal,
}: IDealFormWrapperProps) => {

    const dealNumber = deal?.deal_number;

    const initialFormValues = {
        deal_type_id: deal?.type_id || '',
        controlling_org_id: deal?.controlling_org_id || '',
        submitting_org_id: deal?.submitting_org_id || '',
        invoice_recipient_id: deal?.recipient.recipient_id || '',
        cost_center_id: deal?.cost_center_id || '',
        continueJobNumberCreation: false,
    } as IDealFormValues;

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={DealValidationSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
        >
            {props => (
                <DealForm
                    {...props}
                    isLoading={isLoading}
                    onCancel={onCancel}
                    submitTitle={submitTitle}
                    submitAndGoToJobNumberTitle={submitAndGoToJobNumberTitle}
                    dealNumber={dealNumber}
                />
            )}
        </Formik>
    );
};

export default DealFormWrapper;

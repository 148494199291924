import { usePaginationCounterStyles } from 'modules/settings/submodules/components/PaginationCounter/styles';
import React from 'react';

export interface IPaginationCounter {
    shown?: number;
    total?: number;
}

export const PaginationCounter = ({ shown, total }: IPaginationCounter) => {
    const classes = usePaginationCounterStyles();
    return (
        <div className={classes.paginationCounter}>
            {
                total
                    ? (
                        <span className={classes.paginationCounterShown}>
                            Shown: {shown}
                        </span>
                    )
                    : null
            }
            {
                total !== undefined
                    ? (
                        <span>
                                Total: {total}
                        </span>
                    )
                    : null
            }
        </div>
    );
};

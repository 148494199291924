import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getOfferLetterTemplates } from 'modules/offerLetter/store/templates/actions';
import {
    CreateOfferLetterTemplateButton,
} from 'modules/settings/submodules/offerLetters/components/CreateOfferLetterTemplate/CreateOfferLetterTemplateButton';
import { OfferLetterTemplateList } from './components/OfferLetterTemplateList/OfferLetterTemplateList';
import { getOfferLetterTemplateParams } from './store/action';

function OfferLetterTemplatesPageContent() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOfferLetterTemplates.init());
        dispatch(getOfferLetterTemplateParams.init());
    }, [dispatch]);

    return (
        <>
            <Box mb={2}>
                <CreateOfferLetterTemplateButton/>
            </Box>
            <OfferLetterTemplateList/>
        </>
    );
}

export default OfferLetterTemplatesPageContent;

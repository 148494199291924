import React from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from '../formFields/models';
import { getAreaText } from 'shared/components/selects/utils';
import { selectAreasById } from 'store/entities/configuration/configurationSelectors';
import { IArea } from 'store/entities/configuration/configurationModel';
import { IFormSelect } from './model';

interface IAreaProps extends IFormFieldProps, IFormSelect {}

export default function Area(props: IAreaProps) {
    const areasById = useSelector(selectAreasById);

    const assignmentsMenu = Object.values(areasById);

    return (
        <FormSelect
            getKey={(area: IArea) => area.id}
            getText={(area: IArea) => getAreaText(area)}
            options={assignmentsMenu}
            {...props}
        />
    );
}

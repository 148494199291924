import React from 'react';
import { Box, Hidden, Typography } from '@material-ui/core';
import { EntryType } from 'shared/models/sheet/Sheet';
import { SheetsEntryTypes, StatusNames } from 'store/entities/timesheet/models/Status';
import { useSelector } from 'react-redux';
import { IStore } from 'store/configureStore';
import useFilterAndActionControlsStyles from 'shared/components/sheetApproval/filterAndActionControls/FilterAndActionControlsStyles';
import Time from 'shared/components/sheetApproval/filterAndActionControls/buttons/TimeIcon';
import Expense from 'shared/components/sheetApproval/filterAndActionControls/buttons/ExpenseIcon';
import PrintButton from 'shared/components/sheetApproval/filterAndActionControls/buttons/PrintButton';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import {
    selectEmployeeSubmittedSheetsFilters,
    selectEmployeeSubmittedSheetsFiltersConfiguration,
} from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsSelectors';
import { setEmployeeSubmittedSheetsFilters } from 'store/components/employeeSubmittedSheets/employeeSubmittedSheetsActions';
import FilterButton from 'shared/components/filters/FilterButtonAndForm/FilterButton';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import FilterButtonMock from 'shared/components/filters/FilterButtonAndForm/FilterButtonMock';
import { IAvailableActionsProps } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';

interface IFilterAndActionControlsProps {
    sheetType: EntryType;
    showFilters: boolean;
    title?: string;
    sheetsEntryTypes?: SheetsEntryTypes;
    availableActions?: (props: IAvailableActionsProps) => JSX.Element;
}

export default function FilterAndActionControls({
    sheetType,
    showFilters,
    title,
    sheetsEntryTypes,
    availableActions: AvailableActions,
}: IFilterAndActionControlsProps,
) {
    const classes = useFilterAndActionControlsStyles();
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    const [filtersFeature] = useFeature(FeatureSwitches.displayFilters);

    return (
        <>
            <Box className={classes.rootContainer}>
                <Box className={classes.leftContainer}>
                    {sheetType === EntryType.TIME ? <Time/> : <Expense/>}
                    <Box>
                        <Typography variant="h4">
                            {title ? title : (
                                <>
                                    All {(activeStatus === StatusNames.ALL) ? '' : activeStatus} {
                                        sheetType === EntryType.TIME ? 'Timesheets' : 'Expenses'
                                    }
                                </>
                            )}
                        </Typography>
                    </Box>
                </Box>
                {showFilters && (
                    <FiltersChips
                        action={setEmployeeSubmittedSheetsFilters}
                        className={classes.filtersChips}
                        selector={selectEmployeeSubmittedSheetsFilters}
                    />
                )}
                {showFilters && (
                    <Box className={classes.rightContainer}>
                        {AvailableActions && sheetsEntryTypes && Object.values(sheetsEntryTypes).length > 0 && (
                            <AvailableActions typedSheets={sheetsEntryTypes}/>
                        )}
                        <Hidden xsDown>
                            <PrintButton/>
                        </Hidden>
                        {filtersFeature ? (
                            <FilterButton overrideClassName={classes.actionButton}
                                selectFilters={selectEmployeeSubmittedSheetsFilters}
                                selectFiltersConfig={selectEmployeeSubmittedSheetsFiltersConfiguration}
                                submitFilters={setEmployeeSubmittedSheetsFilters}
                            />
                        ) : (
                            <FilterButtonMock overrideClassName={classes.actionButton}/>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
}

import React from 'react';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import { FeatureSwitches } from 'utils/featureSwitches';
import { Box } from '@material-ui/core';
import { SubmittingOrgTable } from 'modules/settings/submodules/clients/submittingOrg/components/SubmittingOrgTable/SubmittingOrgTable';
import { CreateSubmittingOrgButton } from 'modules/settings/submodules/clients/submittingOrg/components/CreateSubmittingOrg/CreateSubmittingOrgButton';
import { SubmittingOrgFilter } from 'modules/settings/submodules/clients/submittingOrg/components/Filter/SubmittingOrgFilter';

import {
    getControllingOrgs,
    getSubmittingOrgLocations,
} from 'store/entities/configuration/configurationAction';
import { useWithClientEffect } from 'modules/settings/common/hooks/useWithClientEffect';

export function SubmittingOrgPage() {
    useWithClientEffect(dispatch => {
        dispatch(getControllingOrgs.init());
        dispatch(getSubmittingOrgLocations.init());
    });
    return (
        <FeatureSwitch feature={FeatureSwitches.enableGenworthSheets}>
            <Box display="flex" justifyContent="space-between"
                mb={2}>
                <CreateSubmittingOrgButton/>
                <SubmittingOrgFilter/>
            </Box>
            <SubmittingOrgTable/>
        </FeatureSwitch>
    );
}

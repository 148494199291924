import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'ts-debounce';
import { setEmployeesFilter } from 'modules/settings/submodules/employees/store/actions';
import { selectEmployeeFilter } from 'modules/settings/submodules/employees/store/selectors';
import { TextFilter } from 'shared/components/filters/TextFilter';

export const EmployeeNameFilter = () => {
    const dispatch = useDispatch();
    const filter = useSelector(selectEmployeeFilter);
    const debounceTimeout = 300;
    const changeFilterName = useMemo(() =>
        debounce(
            (name: string) => {
                dispatch(setEmployeesFilter({
                    ...filter,
                    name,
                }));
            },
            debounceTimeout),
    [dispatch, filter],
    );
    return (
        <TextFilter onFilterValueChanged={changeFilterName}/>
    );
};

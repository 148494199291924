import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Theme, createStyles } from '@material-ui/core/styles';
import { colors } from 'shared/styles/constants';

export const useModalStyles = makeHighPriorityStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '100%',
        },
        paper: {
            maxWidth: 'unset',
            backgroundColor: colors.whiteSecondary,
            boxShadow: theme.shadows[5],
        },
        title: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
        },
        closeIcon: {
            position: 'absolute',
            top: '50%',
            right: theme.spacing(0.5),
            transform: 'translateY(-50%)',
        },
        content: {
            padding: 0,
            margin: 0,
        },
    }),
);

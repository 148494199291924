import React from 'react';
import { useSelector } from 'react-redux';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { DepartmentPure } from 'shared/components/selects/Department';
import { IFormSelect } from 'shared/components/selects/model';
import { selectUserDepartmentsList } from 'modules/employmentInfo/store/department/selectors';

interface IDepartmentsByAssignmentSelectProps extends IFormFieldProps, IFormSelect {
    userId?: string | null;
}

/**
 * Component for department selector on T&E form
 * Departments list defined by assignments. Components showed only if several departments available.
 */
export const DepartmentsByAssignmentSelect = ({ userId, ...props }: IDepartmentsByAssignmentSelectProps) => {
    const departments = useSelector(selectUserDepartmentsList(userId));

    return (
        <>
            {departments.length > 1 && (
                <DepartmentPure
                    {...props}
                    departments={departments}
                />
            )}
        </>
    );
};

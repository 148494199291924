import React from 'react';
import { Box } from '@material-ui/core';
import ApproveButton from './buttons/ApproveButton';
import RejectButton from './buttons/RejectButton';
import { ISheet, EntryType } from 'shared/models/sheet/Sheet';
import Time from './buttons/TimeIcon';
import Expense from './buttons/ExpenseIcon';
import useFilterAndActionControlStyles from './FilterAndActionControlsStyles';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { IStore } from 'store/configureStore';
import { useSelector } from 'react-redux';
import { IUserInfo } from 'shared/models/User';

import FilterButton from 'shared/components/filters/FilterButtonAndForm/FilterButton';
import { setManagerSubmittedSheetsFilters } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsActions';
import {
    selectManagerSubmittedSheetsFilters,
    selectManagerSubmittedSheetsFiltersConfiguration,
} from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import FiltersChips
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/FiltersChips';
import { useFeature } from '@optimizely/react-sdk';
import { FeatureSwitches } from 'utils/featureSwitches';
import FilterButtonMock from 'shared/components/filters/FilterButtonAndForm/FilterButtonMock';

interface IFilterAndActionControlsProps {
    sheetType: EntryType;
    showActionControls: boolean;
    sheets: ISheet[];
    linkedUsers: IUserInfo[];
    showFilters: boolean;
    title?: string;
}

export default function FilterAndActionMobile({
    sheetType, showActionControls, sheets, linkedUsers, showFilters, title,
}: IFilterAndActionControlsProps) {
    const classes = useFilterAndActionControlStyles();
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    const [filtersFeature] = useFeature(FeatureSwitches.displayFilters);

    return (
        <>
            <Box className={classes.rootContainer}>
                {showActionControls && activeStatus === StatusNames.SUBMITTED ? (
                    <Box className={classes.centerContainer}>
                        <RejectButton sheets={sheets} users={linkedUsers}
                            sheetType={sheetType}/>
                        <ApproveButton sheets = {sheets} sheetType={sheetType}/>
                    </Box>
                ) : (
                    <>
                        <Box className={classes.leftContainer}>
                            {sheetType === EntryType.TIME ? <><Time/></> : <><Expense/></>}
                            <Box>
                                <h3>
                                    {title ? title : `All ${(activeStatus === StatusNames.ALL) ? '' : activeStatus } ${sheetType === EntryType.TIME ? 'Timesheets' : 'Expenses'}`}
                                </h3>
                            </Box>
                        </Box>
                        {showFilters && (
                            <Box className={classes.rightContainer}>
                                {filtersFeature ? (
                                    <FilterButton overrideClassName={classes.actionButton}
                                        submitFilters={setManagerSubmittedSheetsFilters}
                                        selectFiltersConfig={selectManagerSubmittedSheetsFiltersConfiguration}
                                        selectFilters={selectManagerSubmittedSheetsFilters}
                                    />
                                ) : (
                                    <FilterButtonMock overrideClassName={classes.actionButton}/>
                                )}
                            </Box>
                        )}
                    </>
                )
                }
            </Box>
            {showFilters && (
                <FiltersChips className={classes.filtersChipsMobile} selector={selectManagerSubmittedSheetsFilters}
                    action={setManagerSubmittedSheetsFilters}
                />
            )}
        </>
    );
}

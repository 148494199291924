import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import ActionButton from 'shared/components/buttons/ActionButton';
import { routes } from 'shared/routes';
import { Box } from '@material-ui/core';
import {
    IGroupedSheetCalculation,
    ISheetGroupIdWithClient,
    PayrollSheetStatuses,
} from 'modules/payrollProcessorHub/store/model';
import { PayrollButton } from 'modules/payrollProcessorHub/components/PayrollAction/PayrollButton';
import { ApproveButton } from 'modules/payrollProcessorHub/components/SheetStatusAction/ApproveButton';
import { RejectButton } from 'modules/payrollProcessorHub/components/SheetStatusAction/RejectButton';
import { DownloadSheetButton } from 'modules/payrollProcessorHub/components/DownloadSheet/DownloadSheetButton';
import { SendReminderButton } from 'modules/payrollProcessorHub/components/SendReminder/SendReminderButton';
import { useGroupedSheetDetailStyles } from 'modules/payrollProcessorHub/components/PayrollSheetDetailSidebar/styles';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { getSheetGroupName } from '../../store/helpers';

export interface ISheetGroupActionsProp {
    sheetGroup: IGroupedSheetCalculation;
}

export const SheetGroupActions = ({ sheetGroup }: ISheetGroupActionsProp) => {
    const classes = useGroupedSheetDetailStyles();
    const groupIds: ISheetGroupIdWithClient[] = [{
        timeSheetId: sheetGroup.time_sheet_id,
        expenseSheetId: sheetGroup.expense_sheet_id,
        clientId: sheetGroup.client_id,
    }];
    const userHasEditPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));

    const editButton = (
        <Link
            to={generatePath(routes.PAYROLL_PROCESSOR_HUB.EDIT_SHEET, {
                timeSheetId: sheetGroup.time_sheet_id || 'null',
                expenseSheetId: sheetGroup.expense_sheet_id || 'null',
            })}
            className={classes.editButton}
        >
            <ActionButton
                variant="outlined"
                color="primary"
                customType="danger"
            >
                Edit
            </ActionButton>
        </Link>
    );

    if (!userHasEditPermission) {
        return null;
    }
    switch (sheetGroup.payroll_status) {
        case PayrollSheetStatuses.APPROVED: {
            return (
                <Box className={classes.actions}>
                    {editButton}
                    <PayrollButton groupIds={groupIds}/>
                </Box>
            );
        }
        case PayrollSheetStatuses.SUBMITTED: {
            return (
                <Box className={classes.actions}>
                    <ApproveButton
                        groupIds={groupIds}
                    />
                    <RejectButton
                        groupIds={groupIds}
                    />
                </Box>
            );
        }
        case PayrollSheetStatuses.MISSING: {
            return editButton;
        }
        case PayrollSheetStatuses.OVERDUE: {
            return (
                <Box className={classes.actions}>
                    <SendReminderButton
                        timeSheetId={sheetGroup.time_sheet_id}
                        expenseSheetId={sheetGroup.expense_sheet_id}
                    />
                    <DownloadSheetButton
                        timeSheetId={sheetGroup.time_sheet_id}
                        expenseSheetId={sheetGroup.expense_sheet_id}
                        fileName={getSheetGroupName(sheetGroup)}
                    />
                </Box>
            );
        }
        default:
            return null;
    }
};

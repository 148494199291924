import { combineReducers } from 'redux';
import { clearSelectorValue, setCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/actions';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';

const commonEntryFormValues = (
    state = {
        taskId: '',
        projectAssignment: null,
        position: null,
        notes: '',
        location: null,
        department: null,
        activity: null,
        entry_date: '',
        jobNumber: null,
    } as ICommonEntryFormValues,
    action: ReturnType<typeof setCommonEntryFormValues> | ReturnType<typeof clearSelectorValue>) => {

    switch (action.type) {
        case setCommonEntryFormValues.action:
        {
            const { payload } = action as ReturnType<typeof setCommonEntryFormValues>;
            return payload;
        }
        case clearSelectorValue.action: {
            const { payload } = action as ReturnType<typeof clearSelectorValue>;
            return {
                ...state,
                [payload]: null,
            } as ICommonEntryFormValues;
        }
        default:
            return state;
    }
};

export const addEntry = combineReducers({
    commonEntryFormValues,
});

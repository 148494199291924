import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { useApproveStyles } from 'modules/payrollProcessorHub/components/SheetStatusAction/approveStyles';
import { changeSheetGroupStatus } from 'modules/payrollProcessorHub/store/actions';
import { ISheetGroupId } from 'modules/payrollProcessorHub/store/model';
import ActionButton from 'shared/components/buttons/ActionButton';
import ConfirmationDialog from 'shared/components/modals/ConfirmationDialog';
import { useModal } from 'shared/utils/hooks/useModal';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { useOptionalTimeExpenseReportTemplate } from 'modules/payrollProcessorHub/components/SheetStatusAction/utils';

export interface IApproveButtonProps {
    groupIds: ISheetGroupId[];
}

export const ApproveButton = ({ groupIds }: IApproveButtonProps) => {
    const classes = useApproveStyles();
    const { isModalOpened, onModalOpen, onModalClose } = useModal();
    const dispatch = useDispatch();
    const optionalTemplateFunc = useOptionalTimeExpenseReportTemplate(groupIds);

    const onConfirm = () => {
        dispatch(changeSheetGroupStatus.init({
            status: StatusNames.APPROVED,
            groups: groupIds.map(groupId => ({ groupId })),
        }));
        onModalClose();
    };
    return (
        <>
            <ActionButton
                variant="contained"
                color="primary"
                customType="success"
                onClick={onModalOpen}
            >
                Accept
            </ActionButton>
            <ConfirmationDialog
                open={isModalOpened}
                onConfirm={onConfirm}
                onCancel={onModalClose}
                confirmText="Accept"
                customClasses={{
                    paper: classes.confirmationDialog,
                    actions: classes.actions,
                    cancelButton: classes.cancelButton,
                }}
            >
                <Box className={classes.content}>
                    <Typography variant="h3" className={classes.heading}>
                        Approve Submitted
                        {optionalTemplateFunc('Timesheet', 'Expense', 'Timesheet and Expense')}
                        report
                    </Typography>
                    <Typography gutterBottom>
                        You are not on the approver list of this
                        {optionalTemplateFunc('timesheet', 'expense', 'timesheet and expense')}
                        report.
                    </Typography>
                    <Typography gutterBottom>
                        Are you sure you want to bypass the regular approval flow and accept
                        {optionalTemplateFunc('the timesheet', 'the expense', 'both timesheet and expense')}
                        report?
                    </Typography>
                </Box>
            </ConfirmationDialog>
        </>
    );
};

import { combineReducers } from 'redux';
import { ICostCenter } from 'shared/models/CostCenters';
import { IDeal } from 'shared/models/Deal';
import { IJobNumber, IJobNumberUserType } from 'shared/models/JobNumber';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import {
    ConfigurationAction,
    deleteActivity,
    getActivities,
    getAreas,
    getAssignments,
    getLocations,
    getPhysicalDemands,
    getPositions,
    getProjectsAssignments,
    getTasks,
    getWorkingConditions,
    getBackgroundCheckTemplates,
    removeBackgroundCheckTemplate,
    removePhysicalDemandsTemplate,
    removeWorkingConditionsTemplate,
    getProjects,
    getControllingOrgs,
    getSubmittingOrgs,
    getSubmittingOrgLocations,
    getCostCenters,
    getJobNumbers,
    getDeals,
    getDealTypes,
    getUserTypesAction,
} from 'store/entities/configuration/configurationAction';
import {
    deleteItemByIdReducer,
    isLoadingReducer,
    itemsByIdReducer,
    singleValueReducer,
} from 'store/reducerUtils';
import {
    IActivity,
    IArea,
    IAssignment,
    IPhysicalDemandsTemplate,
    IProject,
    ITask,
    IWorkingConditionsTemplate,
    IBackgroundCheckTemplate,
} from 'store/entities/configuration/configurationModel';
import { extendReducer } from 'store/utils/reducers/extendReducer';
import { ActivitiesByTaskId, activitiesByTaskIdReducer } from './helpers/activitiesByTaskIdReducer';
import { IControllingOrg } from 'shared/models/ControllingOrg';
import { ISubmittingOrg } from 'shared/models/SubmittingOrg';
import { ISubmittingOrgLocation } from 'shared/models/SubmittingOrgLocation';
import { IDealType } from 'shared/models/DealType';

export type ProjectIdsByAssignmentId = Record<string, string[]>
function projectIdsByAssignmentId(
    state: ProjectIdsByAssignmentId = {},
    action: ConfigurationAction,
): ProjectIdsByAssignmentId {
    switch (action.type) {
        case getProjectsAssignments.successType:
            return {
                ...state,
                ...action.payload.reduce((acc, { project, assignment }) => {
                    const projectsByAssignment = acc as ProjectIdsByAssignmentId;
                    const projects = projectsByAssignment[assignment.id] ? projectsByAssignment[assignment.id] : [];
                    projects.push(project.id);
                    return {
                        ...acc,
                        [assignment.id]: projects,
                    };
                }, {} as ProjectIdsByAssignmentId),
            };
        default:
            return state;
    }
}

const projectWithAssignments = singleValueReducer(getProjectsAssignments.successType, []);
const assignmentsById = itemsByIdReducer<IAssignment, IAssignment>(getAssignments);
const projectsById = itemsByIdReducer<IProject, IProject>(getProjects);
const areasById = itemsByIdReducer<IArea, IArea>(getAreas);

export function activitiesByTaskId(
    state: ActivitiesByTaskId = {},
    action: ConfigurationAction,
): ActivitiesByTaskId {
    switch (action.type) {
        case getActivities.successType:
            return activitiesByTaskIdReducer(action.payload, state);
        default:
            return state;
    }
}

const activitiesById = extendReducer(
    itemsByIdReducer<IActivity, IActivity>(getActivities),
    deleteItemByIdReducer<IActivity>(deleteActivity),
);
const isActivitiesLoading = isLoadingReducer(getActivities);

const tasksById = itemsByIdReducer<ITask, ITask>(getTasks);
const positionsById = itemsByIdReducer<IPosition, IPosition>(getPositions);
const physicalDemandsById = extendReducer(
    itemsByIdReducer<IPhysicalDemandsTemplate, IPhysicalDemandsTemplate>(getPhysicalDemands),
    deleteItemByIdReducer<IPhysicalDemandsTemplate>(removePhysicalDemandsTemplate),
);
const isPhysicalDemandsTemplatesLoading = isLoadingReducer(getPhysicalDemands);

const workingConditionsById = extendReducer(
    itemsByIdReducer<IWorkingConditionsTemplate, IWorkingConditionsTemplate>(getWorkingConditions),
    deleteItemByIdReducer<IWorkingConditionsTemplate>(removeWorkingConditionsTemplate),
);
const isWorkingConditionsLoading = isLoadingReducer(getWorkingConditions);

const backgroundCheckTemplatesById = extendReducer(
    itemsByIdReducer<IBackgroundCheckTemplate, IBackgroundCheckTemplate>(getBackgroundCheckTemplates),
    deleteItemByIdReducer<IBackgroundCheckTemplate>(removeBackgroundCheckTemplate),
);
const isBackgroundCheckTemplatesLoading = isLoadingReducer(getBackgroundCheckTemplates);

const locationsById = itemsByIdReducer<ILocation, ILocation>(getLocations);

const controllingOrgsById = itemsByIdReducer<IControllingOrg, IControllingOrg>(getControllingOrgs);
const controllingOrgsIsLoading = isLoadingReducer(getControllingOrgs);

const submittingOrgsById = itemsByIdReducer<ISubmittingOrg, ISubmittingOrg>(getSubmittingOrgs);
const submittingOrgsIsLoading = isLoadingReducer(getSubmittingOrgs);

const submittingOrgLocationsById = itemsByIdReducer<ISubmittingOrgLocation,
ISubmittingOrgLocation>(getSubmittingOrgLocations);
const submittingOrgLocationsIsLoading = isLoadingReducer(getSubmittingOrgLocations);

const costCentersById = itemsByIdReducer<ICostCenter, ICostCenter>(getCostCenters);
const costCentersIsLoading = isLoadingReducer(getCostCenters);

const jobNumbersById = itemsByIdReducer<IJobNumber, IJobNumber>(getJobNumbers);
const jobNumbersUserTypesById = itemsByIdReducer<IJobNumberUserType, IJobNumberUserType>(getUserTypesAction);

const dealsById = itemsByIdReducer<IDeal, IDeal>(getDeals);
const dealsIsLoading = isLoadingReducer(getDeals);

const dealTypesById = itemsByIdReducer<IDealType, IDealType>(getDealTypes);

export const configuration = combineReducers({
    assignmentsById,
    projectsById,
    areasById,
    projectWithAssignments,
    projectIdsByAssignmentId,
    activitiesByTaskId,
    activitiesById,
    isActivitiesLoading,
    tasksById,
    positionsById,
    locationsById,
    controllingOrgsById,
    controllingOrgsIsLoading,
    submittingOrgsById,
    submittingOrgsIsLoading,
    submittingOrgLocationsById,
    submittingOrgLocationsIsLoading,
    physicalDemandsById,
    isPhysicalDemandsTemplatesLoading,
    workingConditionsById,
    isWorkingConditionsLoading,
    backgroundCheckTemplatesById,
    isBackgroundCheckTemplatesLoading,
    costCentersById,
    costCentersIsLoading,
    jobNumbersById,
    jobNumbersUserTypesById,
    dealsById,
    dealsIsLoading,
    dealTypesById,
});

import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Create } from '@material-ui/icons';
import { setEditClientWorkLocationId } from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/state/actions';

export interface IEditWorkLocationButtonProps {
    locationId: string;
}

export const EditWorkLocationButton = ({ locationId }: IEditWorkLocationButtonProps) => {
    const dispatch = useDispatch();

    const openModal = useCallback(() => {
        dispatch(setEditClientWorkLocationId(locationId));
    }, [dispatch, locationId]);
    return (
        <IconButton
            onClick={openModal}
            color="primary"
            data-testid="details-button"
        >
            <Create/>
        </IconButton>
    );
};

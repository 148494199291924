import clsx from 'clsx';
import React from 'react';
import { colors } from 'shared/styles/constants';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { Box, Theme } from '@material-ui/core';

export interface IMainContentProps {
    children: React.ReactNode;
    whiteBackground?: boolean;
}

const useStyles = makeHighPriorityStyles((theme: Theme) => ({
    mainPadding: {
        padding: theme.spacing(6, 4),
        width: '100%',
        boxSizing: 'border-box',

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6, 0),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
        },
    },
    whiteBackground: {
        backgroundColor: colors.white,
    },
}));

export default function MainContent({ children, whiteBackground = false }: IMainContentProps) {
    const classes = useStyles();
    return (
        <Box className={clsx(classes.mainPadding, whiteBackground && classes.whiteBackground)}>
            {children}
        </Box>
    );
}

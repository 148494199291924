import { Theme } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';

export const useSidebarStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        overflowX: 'hidden',
    },
    wrapper: {
        width: theme.spacing(70),

        [theme.breakpoints.down('xs')]: {
            top: 0,
            left: 0,
            width: '100%',
        },
    },
    header: {
        minHeight: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        color: colors.white,
        textTransform: 'uppercase',
    },
    iconNoPadding: {
        padding: 0,
    },
    chevronIcon: {
        position: 'absolute',
        left: theme.spacing(2),
    },
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(2),
    },
    largeIcon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    sidebarElement: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    titleText: {
        padding: theme.spacing(0, 4),
        textAlign: 'center',
    },
    edgeStart: {
        marginLeft: theme.spacing(-1),
    },
    whiteTitle: {
        borderBottom: `1px solid ${colors.darkGray}`,
        backgroundColor: `${colors.white} !important`,
        color: `${colors.black} !important`,
        height: theme.spacing(8),
        justifyContent: 'flex-start !important',
        '& h5': {
            width: '100%',
        },
    },
}));

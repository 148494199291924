import { Control, FieldError, ValidationOptions } from 'react-hook-form';

export interface IWithValidation {
    control: Control;
    name: string;
    error?: FieldError | undefined;
    rules?: ValidationOptions;
}

export enum ValidationMessages {
    REQUIRED = 'Required',
    REQUIRED_EXPENSE = 'An expense amount must be greater than zero.',
    OVERFLOW_EXPENSE = 'An expense amount must be less than 10,000.',
    ODOMETER_INVALID = 'End ODO should be greater than Start ODO',
    REQUIRED_MILES = 'Miles amount must be greater than zero.',
    OVERFLOW_MILES = 'Miles amount must be less than 10.000.',
    TIME_IN_LESS_THAN_TIME_OUT = 'Time In must be less than Time Out',
    BREAK_LESS_THAN_TIME_IN_OUT = 'Break time must be less than reg hrs',
    NO_INTERSECTIONS = 'The time must not intersect with other entries',
}

export enum CustomValidationMessages {
    MAX_LENGTH,
}

export const CustomizedValidationMessages = {
    [CustomValidationMessages.MAX_LENGTH]: (length: number) => `Length should be less than ${ length } characters`,
};

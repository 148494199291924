import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEntryEditStyles } from 'shared/components/sidebars/EntryEdit/styles';
import Sidebar, { Anchor, ISidebarProps } from 'shared/components/sidebars/Sidebar/Sidebar';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IStore } from 'store/configureStore';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectTimeEntries } from 'store/entities/timesheet/selectors';
import { useTotalTimeEntriesByDay } from 'shared/models/validationSchemes/utils/totalTimeEntriesByDay';
import AddEntrySave from './AddEntrySave';
import { removeAttachment } from 'store/entities/timesheet/actions/entryAttachments';
import AddExpenseEntry from './AddExpenseEntry';
import AddTimeEntry from './AddTimeEntry';
import EntryTypeSelect from 'shared/components/selects/EntryTypeSelect';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/clientsSelectors';
import { useEntryDate } from 'shared/utils/hooks/useEntryDate';

interface IAddEntryProps extends Omit<ISidebarProps, 'title' | 'children' | 'anchor'> {
    anchor: Anchor;
    sheetId?: string;
    timeSheetId?: string;
    expenseSheetId?: string;
    userId?: string;
    initialValue?: EntryType;
    entryTypeAllowed?: EntryType[];
    payPeriod: IPayPeriod;
}

export default function AddEntry({
    onClose,
    timeSheetId,
    expenseSheetId,
    userId,
    payPeriod,
    entryTypeAllowed = [EntryType.TIME, EntryType.EXPENSE],
    initialValue = EntryType.TIME,
    ...sidebarProps
}: IAddEntryProps) {
    const dispatch = useDispatch();
    const [entryType, setEntryType] = useState(initialValue ?? EntryType.TIME);
    const editEntryClasses = useEntryEditStyles();
    const { attachments } = useSelector((state: IStore) => state.sheetEntryAttachments);
    const clientId = useSelector(selectCurrentClientId);
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));

    const title = useMemo(
        () => `+ Add ${entryType === EntryType.TIME ? 'Time' : 'Expense'} Entry`,
        [entryType],
    );
    const timeEntries = useSelector(selectTimeEntries);
    const totalEntryMinutesByDay = useTotalTimeEntriesByDay(timeEntries);

    const onSidebarClose = useCallback(() => {
        attachments.forEach(file => dispatch(removeAttachment.init(file)));
        onClose();
    }, [dispatch, attachments, onClose]);

    const defaultEntryDate = useEntryDate(payPeriod, true);

    return (
        <Sidebar title={title}
            anchor={'right'}
            onClose={onSidebarClose}
            customClasses={editEntryClasses.paper}
            {...sidebarProps}>
            <>
                <EntryTypeSelect
                    value={entryType}
                    onChange={setEntryType}
                    allowedOptions={entryTypeAllowed}
                />
                {configuration && (
                    <>
                        {entryType === EntryType.TIME && (
                            <AddTimeEntry
                                defaultEntryDate={defaultEntryDate}
                                totalMinutesByDay={totalEntryMinutesByDay}
                                SubmitBlock={AddEntrySave}
                                sheetId={timeSheetId}
                                onClose={onClose}
                                inputs={configuration.inputs.time}
                                userId={userId}
                                payPeriod={payPeriod}
                            />
                        )}
                        {entryType === EntryType.EXPENSE && (
                            <AddExpenseEntry
                                defaultEntryDate={defaultEntryDate}
                                SubmitBlock={AddEntrySave}
                                sheetId={expenseSheetId}
                                onClose={onClose}
                                inputs={configuration.inputs.expense}
                                userId={userId}
                                payPeriod={payPeriod}
                            />
                        )}
                    </>
                )}

            </>
        </Sidebar>
    );
}

import React, { FC, useEffect } from 'react';
import { Permission } from 'store/components/auth/authModels';
import { IStore } from 'store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { loadClientAssignmentsWithLinked } from 'store/entities/configuration/configurationAction';
import { selectUsers } from 'store/entities/users/selectors';
import { useApprovalTableStyles } from 'shared/components/table/TableApprovalStyles';
import { Box } from '@material-ui/core';
import { EntryType } from 'shared/models/sheet/Sheet';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import SubmittedSheetsView from 'modules/clients/content/supervisor/SheetsView/SubmittedSheetsView';
import { ISheetViewProps } from 'modules/clients/content/supervisor/SheetsView/SheetsViewModel';
import SheetApprovalTable from 'shared/components/sheetApproval/filterAndActionControls/SheetApprovalTable';
import { getExpenseReviewerSheets } from 'store/components/expensesApproval/expensesApprovalActions';
import { getTimeReviewerSheets } from 'store/components/timeApproval/timeApprovalActions';
import { selectManagerSubmittedSheetsFilters } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsSelectors';
import { IManagerSubmittedSheets } from 'store/components/managerSubmittedSheets/managerSubmittedSheetsModel';
import { isNotEmpty } from 'shared/utils/helpers/isNotEmpty';
import { selectAssignmentsById } from 'store/entities/configuration/configurationSelectors';
import { selectApprovalFilteredSheets } from 'modules/timeAndExpense/store/selectors';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';

interface ISheetApprovalProps {
    sheetType: EntryType;
}

export default function SheetApproval({ sheetType }: ISheetApprovalProps) {
    const classes = useApprovalTableStyles();
    const dispatch = useDispatch();

    const linked = useSelector(selectUsers);
    const assignmentsById = useSelector(selectAssignmentsById);
    const activeStatus = useSelector((state: IStore) => state.appConfig.sheetGridStatus);
    const clientId = useSelector(selectCurrentClientId);

    const showFilters = activeStatus !== StatusNames.ALL;
    let filters: Partial<IManagerSubmittedSheets> = useSelector(selectManagerSubmittedSheetsFilters);
    if (!showFilters) {
        filters = {};
    }

    useEffect(() => {
        dispatch(loadClientAssignmentsWithLinked.init({ purpose: Permission.ApproveSheets }));
    }, [dispatch]);

    useEffect(() => {
        if (clientId) {
            if (sheetType === EntryType.TIME) {
                dispatch(getTimeReviewerSheets.init(activeStatus));
            } else if (sheetType === EntryType.EXPENSE) {
                dispatch(getExpenseReviewerSheets.init(activeStatus));
            }
        }
    }, [dispatch, sheetType, activeStatus, clientId]);

    const isLoading = useSelector((state: IStore) => sheetType === EntryType.EXPENSE
        ? state.components.expensesApproval.isLoading
        : state.components.timeApproval.isLoading,
    );

    let sheets = useSelector(selectApprovalFilteredSheets(sheetType));

    if (isNotEmpty(filters)) {
        sheets = sheets.filter(sheet => {
            const assignment = assignmentsById[sheet.assignment_id ?? ''];

            if (assignment) {
                if (filters.location_id && filters.location_id !== assignment.location_id) {
                    return false;
                }

                if (filters.position_id && filters.position_id !== assignment.position_id) {
                    return false;
                }
            }

            if (filters.user_id && sheet.user_id !== filters.user_id) {
                return false;
            }

            if (filters.pay_period && sheet.period_end !== filters.pay_period.period_end) {
                return false;
            }

            return true;
        });
    }

    const Component: FC<ISheetViewProps> = activeStatus === StatusNames.SUBMITTED
        ? SubmittedSheetsView : SheetApprovalTable;

    return (
        <Box className={classes.mainContainer}>
            <Component sheets={sheets} linked={linked}
                sheetType={sheetType}
                sheetStatus={activeStatus}
                isLoading={isLoading}
            />
        </Box>
    );
}

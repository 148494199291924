import { IPayrollSheetRow } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import {
    IGroupedSheetCalculation,
    ISheetGroupId,
    PayrollSheetTabs,
} from 'modules/payrollProcessorHub/store/model';
import {
    selectGroupedSheetsCalculations,
    selectPayrollActiveTab,
    selectSheetsGroupsByIds,
} from 'modules/payrollProcessorHub/store/selectors';
import { useSelector } from 'react-redux';
import { selectAllClientsById } from 'store/entities/clients/clientsSelectors';
import { IManagerInfo } from 'store/entities/configuration/configurationModel';
import {
    selectAssignmentsById,
    selectLocationsById,
    selectPositionsById,
} from 'store/entities/configuration/configurationSelectors';
import { selectUsersById } from 'store/entities/users/selectors';
import { nullOrValue } from 'shared/utils/converters/urlParams';

export const useActivePayrollSheetStatus = (): PayrollSheetTabs => {
    return useSelector(selectPayrollActiveTab);
};

export const generateGroupRowId = (group: IGroupedSheetCalculation) => {
    return `time-${group.time_sheet_id}_expense-${group.expense_sheet_id}`;
};

export const parseSheetGroupId = (rowId: string): ISheetGroupId => {
    const match = rowId.match(/^time-(.*)_expense-(.*)$/);
    return {
        timeSheetId: match ? nullOrValue(match[1]) : null,
        expenseSheetId: match ? nullOrValue(match[2]) : null,
    };
};

export const getSheetGroupId = (group: IGroupedSheetCalculation): ISheetGroupId => {
    return {
        timeSheetId: group.time_sheet_id,
        expenseSheetId: group.expense_sheet_id,
    };
};

export const useSheetRowsWithLinkedItems = (calculations: IGroupedSheetCalculation[]): IPayrollSheetRow[] => {
    const usersByIds = useSelector(selectUsersById);
    const positionsById = useSelector(selectPositionsById);
    const clientsById = useSelector(selectAllClientsById);
    const assignmentsById = useSelector(selectAssignmentsById);
    const locationsById = useSelector(selectLocationsById);

    return calculations.map(calculation => {
        const assignment = assignmentsById[calculation.assignment_id || ''];
        const managerIds = assignment?.managers?.map((manager: IManagerInfo) => manager.user_id) || [];
        const approvedByIds = [...new Set([
            calculation.time_latest_approver_user_id,
            calculation.expense_latest_approver_user_id,
        ].filter(id => id))] as string[];
        return {
            id: generateGroupRowId(calculation),
            ...calculation,
            client: clientsById[calculation.client_id || ''],
            employee: usersByIds[calculation.user_id || ''],
            position: positionsById[calculation.position_id || ''],
            assignment,
            approvers: managerIds?.map(id => usersByIds[id]) || [],
            approvedBy: approvedByIds.map(id => usersByIds[id]),
            location: locationsById[assignment?.location_id || ''],
        };
    });
};

export const useFilteredPayrollSheetRows = (): IPayrollSheetRow[] => {
    const groups = useSelector(selectGroupedSheetsCalculations);
    return useSheetRowsWithLinkedItems(groups);
};

export const useGroupSheetRowsByIds = (groupsIds: ISheetGroupId[]): IPayrollSheetRow[] => {
    const groups = useSelector(selectSheetsGroupsByIds(groupsIds));
    return useSheetRowsWithLinkedItems(groups);
};

export const getSheetGroupName = (sheet: IGroupedSheetCalculation): string => {
    return generateGroupRowId(sheet);
};

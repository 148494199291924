import { createSelector } from 'reselect';
import { selectDeals } from 'store/entities/configuration/configurationSelectors';
import { selectDealFilter } from 'modules/settings/submodules/clients/deals/components/Filter/store/selector';
import { selectDealStatusFilter } from 'modules/settings/submodules/clients/deals/components/StatusFilter/store/selectors';
import { IDeal } from 'shared/models/Deal';

export const selectFilteredDeals = (
    createSelector(
        selectDeals,
        selectDealFilter,
        selectDealStatusFilter,
        (deals, dealFilter, status) => {
            const filterToLower = dealFilter.toLowerCase();
            return deals.filter((deal: IDeal) => {
                return String(deal.deal_number).includes(filterToLower)
                    && (deal.status === status || !status);
            }).sort((d1, d2) => d2.deal_number - d1.deal_number);
        },
    )
);

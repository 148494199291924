import { settingsRoutes } from 'modules/settings/routes';
import { ClientsSettingsDetailsRoutes } from 'modules/settings/submodules/clients/clientDetails/ClientsSettingsDetailsRoutes';
import { ClientListPage } from 'modules/settings/submodules/clients/ClientListPage';
import { getClientPayCodes, setClientStatuses } from 'modules/settings/submodules/clients/store/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'shared/components/auth/PrivateRoute';
import { Permission } from 'store/components/auth/authModels';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { getSpecialUsersAction } from 'store/entities/users/actions';
import { PrismUserType } from 'store/entities/users/model';

export const ClientsSettingsRoutes = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    useEffect(() => {
        dispatch(setClientStatuses.init());
        //get Client Managers
        dispatch(getSpecialUsersAction.init({
            permission: Permission.toBeAssignedAsClientManager,
        }));
        //get Payroll Rep
        dispatch(getSpecialUsersAction.init({
            prism_user_type: PrismUserType.I,
        }));
        dispatch(getClientPayCodes.init());
    }, [dispatch, clientId]);

    return (
        <Switch>
            <PrivateRoute
                path={settingsRoutes.CLIENTS.DASHBOARD}
                component={ClientsSettingsDetailsRoutes}
            />
            <PrivateRoute
                path={settingsRoutes.CLIENTS.ROOT}
                component={ClientListPage}
            />
        </Switch>
    );
};

import { useFilterStyles } from 'modules/settings/submodules/components/sharedStyles/filterStyles';
import React from 'react';
import clsx from 'clsx';
import { Box, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { LocationAutocomplete } from 'shared/components/autocomplete/LocationAutocomplete';
import { PositionAutocomplete } from 'shared/components/autocomplete/PositionAutocomplete';
import { Formik, FormikProps } from 'formik';
import {
    IPositionWorkLocationAssociationFormValues,
} from 'modules/settings/submodules/clients/positionWorkLocationAssociation/components/CreateAssociation/model';
import { selectIsPositionLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/selector';
import CheckboxField from 'shared/components/formFields/CheckboxField';
import FilterSVG from 'shared/components/icons/FilterSVG';
import { useModal } from 'shared/utils/hooks/useModal';
import PopOver from 'shared/components/popover/PopOver';
import { setPositionWorkLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/actions';
import { IPositionWorkLocationAssociationFilter } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/model';
import useActionButtonStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/FilterAndActionControls/buttons/ActionButtonStyles';
import {
    FiltersTestIds,
} from 'shared/components/filters/FilterButtonAndForm/FilterButtonAndFormModel';

export default function AssociationFilter() {
    const buttonClasses = useActionButtonStyles();
    const classes = useFilterStyles();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { isModalOpened: isOpened, onModalOpen, onModalClose: onClose } = useModal();
    const filter = useSelector(selectIsPositionLocationAssociationFilter);

    const onFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onModalOpen();
    };

    const onApplyFilter = (
        values: IPositionWorkLocationAssociationFilter,
    ) => {
        dispatch(setPositionWorkLocationAssociationFilter(values));
        onClose();
    };

    return (
        <Box
            display="flex"
            justifyContent="flex-end"
            mt={1.5}
        >
            <Button
                onClick={onFilterClick}
                classes={{ root: buttonClasses.default }}
                data-testid={FiltersTestIds.Button}
            >
                <FilterSVG/>
            </Button>

            <PopOver
                isOpened={isOpened}
                anchorEl={anchorEl}
                onClose={onClose}
                data-testid={FiltersTestIds.Wrapper}
            >
                <Formik
                    initialValues={filter}
                    onSubmit={onApplyFilter}
                >
                    {(props: FormikProps<IPositionWorkLocationAssociationFormValues>) => (
                        <form
                            onSubmit={props.handleSubmit}
                            className={classes.form}
                        >
                            <PositionAutocomplete
                                name="position"
                                label="Position"
                                className={classes.input}
                            />
                            <LocationAutocomplete
                                name="location"
                                label="Work location"
                                className={classes.input}
                            />
                            <CheckboxField
                                name="unassociated"
                                className={clsx(classes.input, classes.checkbox)}
                            >
                                Unassociated Fields
                            </CheckboxField>
                            <Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    data-testid="submit_button"
                                >
                                    Apply
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </PopOver>
        </Box>
    );
}

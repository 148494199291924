import { activities } from 'modules/settings/submodules/clients/activities/store/reducer';
import { editClientLogo } from 'modules/settings/submodules/clients/components/EditClientLogo/store/reducer';
import { costCenters } from 'modules/settings/submodules/clients/costCenters/store/reducer';
import { departments } from 'modules/settings/submodules/clients/departments/store/reducers';
import { jobNumbers } from 'modules/settings/submodules/clients/jobNumber/store/reducer';
import { payCodes } from 'modules/settings/submodules/clients/payCodes/store/reducer';
import { physicalDemandsTemplates } from 'modules/settings/submodules/clients/physicalDemands/store/reducers';
import { positionLocationAssociation } from 'modules/settings/submodules/clients/positionWorkLocationAssociation/state/reducer';
import { setIsNoGeocodeWarningShown, updateClient } from 'modules/settings/submodules/clients/store/actions';
import {
    createClientModal,
    isClientCreating,
} from 'modules/settings/submodules/clients/components/NewClientButton/state/reducer';
import { createClientLocation } from 'modules/settings/submodules/clients/workLocation/components/CreateWorkLocation/state/reducer';
import { editWorkLocation } from 'modules/settings/submodules/clients/workLocation/components/EditWorkLocation/state/reducer';
import { isSyncWorkLocationsInProgress } from 'modules/settings/submodules/clients/workLocation/components/SyncWorkLocations/state/reducer';
import { combineReducers } from 'redux';
import { isLoadingReducer, isOpenModalReducer } from 'store/reducerUtils';
import { workingConditionsTemplates } from 'modules/settings/submodules/clients/workingConditions/store/reducers';
import { backgroundChecksTemplates } from 'modules/settings/submodules/clients/backgroundCheckTemplates/store/reducers';
import { appConfiguration } from 'modules/settings/submodules/clients/appConfiguration/store/reducers';
import { controllingOrg } from 'modules/settings/submodules/clients/controllingOrg/store/reducer';
import { submittingOrg } from 'modules/settings/submodules/clients/submittingOrg/store/reducer';
import { deals } from 'modules/settings/submodules/clients/deals/store/reducer';
import { paySettings } from 'modules/settings/submodules/clients/paySettings/store/reducer';

export const isClientUpdating = isLoadingReducer(updateClient);
const isNoGeocodeWarningShown = isOpenModalReducer(setIsNoGeocodeWarningShown.action);

export const clients = combineReducers({
    createClientModal,
    isClientCreating,
    isClientUpdating,
    editClientLogo,
    createClientLocation,
    editWorkLocation,
    controllingOrg,
    submittingOrg,
    deals,
    isSyncWorkLocationsInProgress,
    positionLocationAssociation,
    payCodes,
    activities,
    departments,
    physicalDemandsTemplates,
    workingConditionsTemplates,
    backgroundChecksTemplates,
    appConfiguration,
    costCenters,
    jobNumbers,
    paySettings,
    isNoGeocodeWarningShown,
});

import React, { useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Box, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useUploadStyles } from './styles';
import { IAttachment } from 'shared/models/Attachments';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import PdfSVG from '../icons/PdfSVG';
import axios from 'axios';

interface IAttachmentsPreviewProps {
    open: boolean;
    onClose: () => void;
    onDelete?: (file: IAttachment) => void;
    files: IAttachment[];
}

export default function AttachmentsPreview({
    open,
    onClose,
    onDelete,
    files,
}: IAttachmentsPreviewProps) {
    const classes = useUploadStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps, setMaxStep] = React.useState(files.length);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep >= maxSteps - 1 ? 0 : prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1);
    };

    const onDownload = () => {
        const url = files[activeStep].url;
        axios.request({
            url: url,
            responseType: 'blob',
        }).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', files[activeStep].filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };

    const onFileDelete = useCallback(() => {
        files.length === 1 && onClose();
        if (onDelete) {
            onDelete(files[activeStep]);
        }
        activeStep === (maxSteps - 1) && setActiveStep(activeStep - 1);
        setMaxStep(files.length - 1);
    }, [activeStep, files, maxSteps, onClose, onDelete]);

    const onPdfPreview = () => {
        window.open(files[activeStep].url);
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={onClose}
                fullScreen disableBackdropClick
                classes={{ paper: classes.paper }}
            >
                <div className={classes.dialog}>
                    <div className={classes.stepContainer}>
                        <Button className = {clsx(classes.iconButton, classes.pullLeft)}
                            onClick={handleBack}
                            color="inherit"
                        >
                            <KeyboardArrowLeft className={classes.stepActions}/>
                        </Button>
                    </div>
                    <div className={classes.middleContainer}>
                        <Paper square elevation={0}
                            className={classes.header}>
                            <Typography>
                                {activeStep + 1} / {maxSteps}
                            </Typography>
                        </Paper>
                        <Box className={classes.imageContainer}>
                            {files[activeStep] !== undefined
                            && files[activeStep].mimetype !== 'application/pdf'
                                ? (
                                    <img
                                        className={clsx(classes.img, classes.previewImg)}
                                        src={files[activeStep].url}
                                        alt={files[activeStep].filename}
                                    />
                                )
                                : (
                                    <div className={classes.previewImg}>
                                        <Button
                                            aria-label="view"
                                            onClick={onPdfPreview}
                                            className={classes.pdfPreview}
                                        >
                                            <PdfSVG customClasses={classes.pdfPreview}/>
                                        </Button>
                                    </div>
                                )}
                            <div className={classes.bottomBar}>
                                <Typography className={classes.imageTitle}>
                                    {files[activeStep] !== undefined && files[activeStep].filename}
                                </Typography>
                                <div className={classes.iconButton}>
                                    {onDelete && (
                                        <Button
                                            aria-label="delete"
                                            onClick={onFileDelete}
                                            classes={{ root: classes.buttonRoot }}
                                        >
                                            <DeleteIcon className={classes.bottomActions}/>
                                        </Button>
                                    )}
                                    <Button
                                        aria-label="download"
                                        onClick={onDownload}
                                        classes={{ root: classes.buttonRoot }}
                                    >
                                        <GetAppIcon className={classes.bottomActions}/>
                                    </Button>
                                </div>
                            </div>
                        </Box>
                        <Button
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            <CloseIcon className={classes.closeAction}/>
                        </Button>
                    </div>
                    <div className={classes.stepContainer}>
                        <Button className = {clsx(classes.iconButton, classes.pullRight)}
                            onClick={handleNext}
                            color="inherit"
                        >
                            <KeyboardArrowRight className={classes.stepActions}/>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

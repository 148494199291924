import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    TextField,
} from '@material-ui/core';
import { IFormFieldProps } from './models';
import { inputParams } from 'shared/styles/constants';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

export interface IFormNumberInputProps extends Partial<IFormFieldProps> {
    name?: string;
    initialValue: any;
    inputProps?: Partial<NumberFormatProps>;
    error?: string | null;
    touched?: boolean;
    onChange: (value: any) => void;
    setTouched?: (value: boolean) => void;
    type?: 'text' | 'tel' | 'password';
    min?: number;
    max?: number;
}

interface INumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

function NumberFormatCustom({
    inputRef,
    onChange,
    ...props
}: INumberFormatCustomProps) {
    return (
        <NumberFormat
            {...props}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
}

const NumberInput = ({
    name,
    label,
    id = String(name),
    className,
    disabled = false,
    inputProps = {},
    initialValue,
    touched,
    error,
    setTouched,
    onChange,
    min,
    max,
    type = 'text',
}: IFormNumberInputProps) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue, setValue]);

    const errorMessage = typeof error === 'string' ? touched && error : null;
    const handleBlur = () => {
        let normalizedValue = value;
        if (max !== undefined && min !== undefined && value) {
            normalizedValue = value < min ? min : value > max ? max : value;
            setValue(normalizedValue);
        }
        onChange(normalizedValue);
        if (setTouched) {
            setTouched(false);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };
    const onFocus = () => {
        if (parseFloat(value) === 0) {
            setValue('');
        }
    };

    return (
        <TextField
            id={id}
            name={name}
            className={className}
            disabled={disabled}
            label={label}
            variant="outlined"
            autoComplete="off"
            onBlur={handleBlur}
            onFocus={onFocus}
            value={value}
            type={type}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            onChange={handleChange}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                    ...inputParams,
                    ...inputProps,
                    onValueChange: handleChange,
                },
            }}
        />
    );
};

export default NumberInput;

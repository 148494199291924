import { IStore } from 'store/configureStore';
import { selectInvoiceRecipientById } from 'modules/settings/submodules/clients/deals/components/DealForm/components/InvoiceRecipientSelect/store/selectors';
import { RecipientType } from 'shared/models/Deal';
import {
    selectControllingOrgById, selectSubmittingOrgById,
} from 'store/entities/configuration/configurationSelectors';

export const selectInvoiceRecipientNumber = (invoice_recipient_id: string) => (state: IStore) => {
    const recipient = selectInvoiceRecipientById(invoice_recipient_id)(state);
    if (recipient?.recipient_type === RecipientType.ControllingOrg){
        const controllingOrg = selectControllingOrgById(recipient.recipient_id)(state);
        return controllingOrg.b_number;
    }
    if (recipient?.recipient_type === RecipientType.SubmittingOrg){
        const submittingOrg = selectSubmittingOrgById(recipient.recipient_id)(state);
        return submittingOrg.submitting_org_number;
    }
    return '';
};

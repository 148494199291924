import * as yup from 'yup';
import { ValidationMessages } from 'shared/models/Validation';

export const employeeProfileValidationsSchema = yup.object().shape({
    first_name: yup.string().trim().required(ValidationMessages.REQUIRED),
    last_name: yup.string().trim().required(ValidationMessages.REQUIRED),
    birthday: yup.string().required(ValidationMessages.REQUIRED),
    ssn: yup.string().required(ValidationMessages.REQUIRED)
        .length(9, 'Social Security Number must be exactly 9 characters')
        .matches(/^(?!\b(\d)1+\b)(?!123456789|219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/, 'Invalid Social Security Number'),
    ssn_confirmation: yup.string().required(ValidationMessages.REQUIRED)
        .test({
            name: 'confirmationShouldBeEqual',
            test: function (value: string): boolean {
                return value === this.parent.ssn;
            },
            message: 'Confirmation should be equal to SSN',
        }),
    county: yup.string().trim().required(ValidationMessages.REQUIRED),
    state: yup.string().trim().required(ValidationMessages.REQUIRED),
    address: yup.string().trim().required(ValidationMessages.REQUIRED),
    address2: yup.string().trim().matches(/^((?!#).)*$/, 'Please remove special characters'),
    city: yup.string().trim().required(ValidationMessages.REQUIRED),
    zip_code: yup.string().trim().required(ValidationMessages.REQUIRED),
});

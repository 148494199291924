import React, { useContext, useEffect, useRef } from 'react';
import HeaderContextTabs from 'shared/components/common/headerTabs/HeaderContextTabs';
import { MenuContext } from 'shared/models/menuContext';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { setSheetGridStatus } from 'store/entities/appConfig/actions';
import { useActions } from 'store/utils';

const SheetApprovalTabs: React.FC = () => {
    const actions = useActions( { setSheetGridStatus });
    const actionsRef = useRef(actions);

    const { menuState } = useContext(MenuContext);

    useEffect(() => {
        actionsRef.current.setSheetGridStatus(menuState.tabs.sheetApprovalPage);
    }, [menuState.tabs.sheetApprovalPage, actionsRef]);

    const tabs = [
        {
            label: 'Submitted',
            value: StatusNames.SUBMITTED,
        },
        {
            label: 'Working',
            value: StatusNames.WORKING,
        },
        {
            label: 'Approved',
            value: StatusNames.APPROVED,
        },
        {
            label: 'Rejected',
            value: StatusNames.REJECTED,
        },
        {
            label: 'All',
            value: StatusNames.ALL,
        },
    ];
    return (
        <HeaderContextTabs
            menuKey={'sheetApprovalPage'}
            tabs={tabs}
            mobileHeader="Approval Status"
        />
    );
};

export default SheetApprovalTabs;

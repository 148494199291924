import React from 'react';
import SheetsSubmittedActions
    from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedActions';
import SheetsSubmittedView from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/SheetsSubmittedView/SheetsSubmittedView';
import { useCheckedSheets } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/checkedSheets';
import { Theme, useMediaQuery } from '@material-ui/core';
import {
    useSheetsData,
} from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/sheetsData';
import { useOpenSheetDetail } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/useOpenSheetDetail';

export default function SheetsSubmittedPage() {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
    const { timeSheets, expenseSheets, timeRows, expenseRows, isLoading } = useSheetsData();
    const { sheetsEntryTypes, timeCheckbox, expenseCheckbox } = useCheckedSheets(isMobile, timeSheets, expenseSheets);
    const handleSheetClick = useOpenSheetDetail();

    return (
        <>
            <SheetsSubmittedView
                expenseRows={expenseRows}
                timeRows={timeRows}
                expensePrefixCell={expenseCheckbox}
                timePrefixCell={timeCheckbox}
                onDetailsClick={handleSheetClick}
                isLoading={isLoading}
                sheetsEntryTypes={sheetsEntryTypes}
                availableActions={SheetsSubmittedActions}
            />
        </>
    );
}

import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EmployeeSheetDetails } from 'modules/clients/content/TimeAndExpensePage/SheetsSubmitted/EmployeeSheetDetails';
import { MenuContext } from 'shared/models/menuContext';
import { EntryType } from 'shared/models/sheet/Sheet';
import { browserHistory } from 'shared/utils/browserHistory';
import { selectTypedSheet } from 'store/entities/timesheet/selectors';

export default function EmployeeSheetDetailPage() {
    const { sheetId, sheetType } = useParams<{ sheetId: string; sheetType: EntryType }>();
    const { menuState, setMenuState } = useContext(MenuContext);
    const sheet = useSelector(selectTypedSheet(sheetId, sheetType as EntryType));

    const onModalClose = () => {
        browserHistory.push('../..');
    };

    useEffect(() => {
        if (sheet?.status?.name && sheet?.status?.name !== menuState.tabs.timeEntryPage) {
            setMenuState({
                ...menuState,
                tabs: {
                    ...menuState.tabs,
                    timeEntryPage: sheet?.status?.name,
                },
            });
        }
    }, [sheet, setMenuState, menuState]);

    return (
        <EmployeeSheetDetails
            isOpened
            onClose={onModalClose}
            sheetId={sheetId}
            entryType={sheetType as EntryType}
        />
    );
}

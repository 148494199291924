/* eslint-disable react/display-name */
import clsx from 'clsx';
import React from 'react';
import PdfSVG from 'shared/components/icons/PdfSVG';
import { IconButton } from '@material-ui/core';
import { areaCell, getHoursAmountCell, payPeriodCell } from 'shared/components/sheetApproval/bodyCells';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { EntryType } from 'shared/models/sheet/Sheet';
import { getUserName, userFullName } from 'shared/utils/converters/user';
import { formatFiles } from 'shared/utils/formatters/timePaymentFormatter';
import { SheetColumnSlug } from 'store/entities/clients/clientsModel';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';

export const useSheetColumnDictionary = (isMobile: boolean):
Record<SheetColumnSlug, (placeholder: string) => ICellInfo<ISheetRow>> => {
    const classes = useSubmittedTableStyles();

    return {
        [SheetColumnSlug.Employee]: (placeholder: string) => ({
            key: SheetColumnSlug.Employee,
            title: placeholder,
            width: '2fr',
            render: ({ className, user }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={userFullName(user)}
                />
            ),
        }),
        [SheetColumnSlug.PayPeriod]: payPeriodCell,
        [SheetColumnSlug.Area]: areaCell,
        [SheetColumnSlug.Hours]: getHoursAmountCell(classes, EntryType.TIME, isMobile),
        [SheetColumnSlug.Amount]: getHoursAmountCell(classes, EntryType.EXPENSE, isMobile),
        [SheetColumnSlug.Receipt]: (placeholder: string) => ({
            key: SheetColumnSlug.Receipt,
            title: placeholder,
            width: '80px',
            headerClassName: classes.displayCenterCell,
            render: ({ className, sheet }: ISheetRow) => {
                return (
                    <div className={ clsx(className, classes.iconCell, classes.pdfIcon) }>
                        {
                            sheet.has_receipts && (
                                <IconButton>
                                    <PdfSVG/>
                                </IconButton>
                            )
                        }
                    </div>
                );
            },
        }),
        [SheetColumnSlug.Id]: (placeholder: string) => ({
            key: 'id',
            width: '3fr',
            title: placeholder,
            render: ({ sheet, className }: ISheetRow) => (
                <PlainText className={className} value={sheet.id}/>
            ),
        }),
        [SheetColumnSlug.Position]: (placeholder: string) => ({
            key: 'position',
            width: '3fr',
            title: placeholder,
            render: ({ className, position }: ISheetRow) => (
                <PlainText className={className} value={position?.name}/>
            ),
        }),
        [SheetColumnSlug.Location]: (placeholder: string) => ({
            key: 'location',
            title: placeholder,
            render: ({ className, location }: ISheetRow) => (
                <PlainText className={className} value={location?.name}/>
            ),
        }),
        [SheetColumnSlug.Department]: (placeholder: string) => ({
            key: 'department',
            title: placeholder,
            render: ({ className, department }: ISheetRow) => (
                <PlainText className={className} value={department?.name}/>
            ),
        }),
        [SheetColumnSlug.Approver]: (placeholder: string) => ({
            key: 'approver',
            width: '3fr',
            title: placeholder,
            render: ({ className, approvers }: ISheetRow) => {
                return (
                    <PlainText className={className}
                        value={approvers.map(approver => getUserName(approver)).join(', ')}/>
                );
            },
        }),
        [SheetColumnSlug.JobNumber]: (placeholder: string) => ({
            key: 'jobNumber',
            title: placeholder,
            render: ({ jobNumber, className }: ISheetRow) => (
                <PlainText className={className} value={jobNumber?.job_number?.toString() || ''}/>
            ),
        }),
        [SheetColumnSlug.RegularHours]: (placeholder: string) => ({
            key: 'regularHours',
            title: placeholder,
            width: '2fr',
            render: ({ calculation, className }: ISheetRow) => {
                let value = '';
                if (calculation?.files) {
                    value = formatFiles(calculation?.files);
                } else if (calculation?.rt_hours) {
                    value = formatDecimalHoursStringAsHoursAndMinutes(calculation.rt_hours);
                }
                return (
                    <PlainText className={className} value={value}/>
                );
            },
        }),
        [SheetColumnSlug.Overtime1Hours]: (placeholder: string) => ({
            key: 'overtime1Hours',
            title: placeholder,
            width: '2fr',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={calculation?.ot_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.ot_hours) : ''}
                />
            ),
        }),
        [SheetColumnSlug.Overtime2Hours]: (placeholder: string) => ({
            key: 'overtime2Hours',
            title: placeholder,
            width: '2fr',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={calculation?.dt_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.dt_hours) : ''}
                />
            ),
        }),
        [SheetColumnSlug.HolidayHours]: (placeholder: string) => ({
            key: 'holidayHours',
            title: placeholder,
            width: '2fr',
            render: ({ calculation, className }: ISheetRow) => (
                <PlainText
                    className={className}
                    value={calculation?.holiday_hours ? formatDecimalHoursStringAsHoursAndMinutes(calculation.holiday_hours) : ''}
                />
            ),
        }),
    };
};

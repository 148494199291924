import React, { useMemo } from 'react';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormFieldProps } from 'shared/components/formFields/models';
import { IFormSelect } from 'shared/components/selects/model';

interface IApprovalLevelsFieldProps extends IFormFieldProps, IFormSelect {}

interface IApprovalLevel {
    value: number;
    description: string;
}

const APPROVAL_LEVEL_MAX = 20;

export const ApprovalLevelsField = (props: IApprovalLevelsFieldProps) => {
    const approvalLevels = useMemo(() => (
        Array.from(Array(APPROVAL_LEVEL_MAX).keys()).map(index => ({
            value: index + 1,
            description: index + 1,
        }))
    ), []);
    return (
        <FormSelect
            getKey={(level: IApprovalLevel) => `${level.value}`}
            getText={(level: IApprovalLevel) => level.description}
            options={approvalLevels}
            useIdValue
            {...props}
        />
    );
};

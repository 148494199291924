/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { PayrollFilterForm } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilterForm';
import { setPayrollProcessorFilter } from 'modules/payrollProcessorHub/store/actions';
import { IPayrollProcessorFilters } from 'modules/payrollProcessorHub/store/model';
import { selectPayrollFilter, selectPPHFilterClientHasJobNumberConfiguration } from 'modules/payrollProcessorHub/store/selectors';

interface IPayrollFilterProps {
    isDashboard?: boolean;
}

export const PayrollFilter = ({
    isDashboard,
}: IPayrollFilterProps) => {
    const dispatch = useDispatch();
    const initialValues = useSelector(selectPayrollFilter);
    const clientHasJobNumberConfiguration = useSelector(selectPPHFilterClientHasJobNumberConfiguration);

    const onChange = useCallback((values: Partial<IPayrollProcessorFilters>) => {
        dispatch(setPayrollProcessorFilter(values));
    }, [dispatch]);

    return (
        <Box>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnBlur={false}
            >
                {props => (
                    <PayrollFilterForm
                        {...props}
                        onChange={onChange}
                        actualFilterValues={initialValues}
                        isDashboard={isDashboard}
                        clientHasJobNumberConfiguration={clientHasJobNumberConfiguration}
                    />
                )}
            </Formik>
        </Box>
    );
};

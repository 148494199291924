import React, { useCallback, useEffect, useState } from 'react';
import { IntervalInputProps } from '../intervalField/IntervalDataField';
import {
    Time12HoursKeyboardPicker,
    Time12HoursKeyboardPickerStyles,
} from '../../inputs/Time12HoursKeyboardInput/Time12HoursKeyboardPicker';
import InOutTimeFieldInput from './InOutTimeFieldInput';

interface IInOutTimeFieldPickerClasses {
    picker: Time12HoursKeyboardPickerStyles
}

interface IInOutTimeFieldPickerProps {
    classes?: Partial<IInOutTimeFieldPickerClasses>;
}

function InOutTimeFieldPickerPure({
    label,
    name,
    value,
    className,
    helpers,
    meta,
    inputProps,
    onChange,
    classes,
}: IntervalInputProps<string> & IInOutTimeFieldPickerProps) {
    const { setTouched } = helpers || {};
    const { touched, error, initialValue } = meta || {};

    // Hack to prevent showing browser placeholders & icons before entering
    const [isFocussed, setFocussed] = useState(Boolean(initialValue || value));
    const [localValue, setValue] = useState(initialValue || value);
    useEffect(() => {
        setValue(!value ? '' : value);
        if (!value) {
            setFocussed(false);
        }
    }, [value]);

    const focusHandler = useCallback(() => {
        setFocussed(true);
    }, []);

    const blurHandler = useCallback(() => {
        if (setTouched) {
            setTouched(true);
        }
        // Hack! Making delay provide availability to click on input adornment
        !localValue && setTimeout(() => {
            setFocussed(false);
        }, 200);
    }, [setTouched, localValue]);

    const changeHandler = useCallback((changedValue: string) => {
        setValue(changedValue);
        // Set focussed state after selection time with wizard
        changedValue && setFocussed(true);
        if (onChange) {
            onChange(changedValue);
        }
    }, [onChange]);
    const errorMessage = typeof error === 'string' && touched ? error : undefined;

    return (
        <Time12HoursKeyboardPicker
            name={name}
            label={label}
            className={className}
            inputProps={inputProps}
            value={localValue ? `${localValue}` : null}
            InputFieldComponent={InOutTimeFieldInput}
            isFocussed={isFocussed}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
            onChange={changeHandler}
            onBlur={blurHandler}
            onFocus={focusHandler}
            classes={{ iconButton: classes?.picker?.iconButton }}
        />
    );
}

export function withInOutClassesComponent(classes?: Partial<IInOutTimeFieldPickerClasses>) {
    function InOutTimeFieldPickerClassed(props: IntervalInputProps<string>) {
        return (
            <InOutTimeFieldPickerPure classes={classes} {...props}/>
        );
    }
    return InOutTimeFieldPickerClassed;
}

export const InOutTimeFieldPicker = withInOutClassesComponent();

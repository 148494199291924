import { combineReducers } from 'redux';
import { IScaZone, IStateUS } from 'store/entities/scaZone/models';
import { getScaZones, getStates, ScaZoneActions } from 'store/entities/scaZone/actions';
import { isLoadingReducer, itemsByIdReducer } from 'store/reducerUtils';

const defaultValues = {
    scaZonesById: {},
    statesLoading: false,
    scaZonesByAssignmentState: {},
    scaZonesLoading: false,
};

const statesById = itemsByIdReducer<IStateUS, IStateUS>(getStates);
const statesLoading = isLoadingReducer(getStates);

function scaZonesById(
    state: Record<number, IScaZone> = defaultValues.scaZonesById, action: ScaZoneActions,
): Record<number, IScaZone> {
    switch (action.type) {
        case getScaZones.successType:
            return {
                ...state,
                ...action.payload.scaZones.reduce((acc, zone) => ({
                    ...acc,
                    [zone.id]: zone,
                }), {}),
            };
        default:
            return state;
    }
}

const scaZonesLoading = isLoadingReducer(getScaZones);

export const scaZonesByAssignmentStateKey = (assignmentId: string, stateId: string) => `${assignmentId}_${stateId}`;

function scaZonesByAssignmentState(
    state: Record<string, Array<number>> = defaultValues.scaZonesByAssignmentState, action: ScaZoneActions,
): Record<string, Array<number>> {
    switch (action.type) {
        case getScaZones.successType:{
            const { assignmentId, stateId, scaZones } = action.payload;
            return {
                ...state,
                [scaZonesByAssignmentStateKey(assignmentId, stateId)]: scaZones.map(zone => zone.id),
            };
        }
        default:
            return state;
    }
}

export const scaZone = combineReducers({
    statesById,
    scaZonesById,
    statesLoading,
    scaZonesLoading,
    scaZonesByAssignmentState,
});

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getPrismSSOLink } from 'modules/offerLetter/store/actions';
import {
    selectIsSSOLikLoading,
    selectSSOLink,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/selectors';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import useTitleUpdate from 'shared/utils/hooks/useTitleUpdate';
import { selectCurrentClientId } from 'store/entities/clients/clientsSelectors';
import { FeatureSwitches } from 'utils/featureSwitches';
import { thirdPartyCookiesCheck } from 'utils/thirdPartyCookiesCheck';
import { navigateIframedHost } from '../offerLetter/helpers/navigateIframedHost';
import { routes } from '../../shared/routes';
import { useFeature } from '@optimizely/react-sdk';

export const EmployeePortal = () => {
    const dispatch = useDispatch();
    const clientId = useSelector(selectCurrentClientId);
    const [iframeLoading, setIframeLoading] = useState(true);
    const [cookiesEnabled, setCookiesEnabled] = useState(true);
    const isSSOLinkLoading = useSelector(selectIsSSOLikLoading);
    const [disableThirdPartyCookiesChecking] = useFeature(FeatureSwitches.disableThirdPartyCookiesChecking);
    const isLoading = iframeLoading || isSSOLinkLoading;
    const link = useSelector(selectSSOLink);
    useTitleUpdate('Employee Portal');

    useEffect(() => {
        dispatch(getPrismSSOLink.init(clientId || ''));
    }, [dispatch, clientId]);

    useEffect(() => {
        if (disableThirdPartyCookiesChecking) {
            setCookiesEnabled(true);
        } else {
            thirdPartyCookiesCheck().then(({ supported }) => {
                setCookiesEnabled(supported);
            });
        }
    }, [setCookiesEnabled, disableThirdPartyCookiesChecking]);

    const onLoad = (evt: React.SyntheticEvent) => {
        navigateIframedHost(routes.HOME)(evt);
        setIframeLoading(false);
    };

    return (
        <FeatureSwitch feature={FeatureSwitches.enableEmployeePortal}>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                width="100%"
            >
                {cookiesEnabled ? (
                    <>
                        {isLoading && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                                height="100%"
                                flexGrow={1}
                            >
                                <CircularProgress/>
                            </Box>
                        )}
                        {link && (
                            <iframe
                                src={link}
                                title="Employee Portal"
                                frameBorder="0"
                                height={isLoading ? 0 : '100%'}
                                onLoad={onLoad}
                            />
                        )}
                    </>
                ) : (
                    <Box m={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Alert severity="error">
                            Cookies are required for view Employee Portal. <br/>
                            Please enable the third party cookie in your browser and reload the page.
                        </Alert>
                    </Box>
                )}
            </Box>
        </FeatureSwitch>
    );
};

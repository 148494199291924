import { Theme } from '@material-ui/core';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors, drawerWidth } from 'shared/styles/constants';

export const useHomeStyles = makeHighPriorityStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    appShifted: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    largeShift: {
        marginLeft: drawerWidth,
    },
    smallShift: {
        marginLeft: theme.spacing(6),
    },
    main: {
        flex: 1,
        background: colors.whiteSecondary,

        [theme.breakpoints.down('xs')]: {
            background: colors.white,
        },
    },
    footer: {
        padding: theme.spacing(2),
        background: colors.whiteSecondary,
    },
}));

import React from 'react';
import { IFormFieldProps } from 'shared/components/formFields/models';
import FormSelect from 'shared/components/selects/FormSelect';
import { IFormSelect } from 'shared/components/selects/model';
import { useSelector } from 'react-redux';
import {
    selectCostCenters,
} from 'store/entities/configuration/configurationSelectors';

import { ICostCenter } from 'shared/models/CostCenters';

interface ICostCenterSelectProps extends IFormFieldProps, IFormSelect {
}

export const CostCenterSelect = (props: ICostCenterSelectProps) => {
    const costCenters = useSelector(selectCostCenters);
    return (
        <FormSelect
            getKey={(costCenter: ICostCenter) => costCenter?.id || ''}
            getText={(costCenter: ICostCenter) => costCenter ? `${costCenter.number} - ${costCenter.description}` : ''}
            options={costCenters}
            useIdValue
            {...props}
        />
    );
};

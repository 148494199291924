import { Theme, fade } from '@material-ui/core/styles';
import { makeHighPriorityStyles } from 'utils/stylesWrapper';
import { colors } from 'shared/styles/constants';
import { getRowHeightLine } from 'shared/components/table/GridTable/GridTableStyles';

const smallCellWidth = 48;
const mediumCellWidth = 88;

export const useDefaultTableStyles = makeHighPriorityStyles((theme: Theme) => {
    const paddingTopBottom = 1;
    const paddingTopBottomInPx = theme.spacing(paddingTopBottom);
    return ({
        paper: {
            borderRadius: 0,
        },
        headCell: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(paddingTopBottom, 0, paddingTopBottom, 2),
            border: 'none !important',
            lineHeight: `${getRowHeightLine(paddingTopBottomInPx, 0)}px !important`,
            backgroundColor: `${ colors.lightGray } !important`,
        },
        checkbox: {
            justifyContent: 'flex-start',
            '& svg': {
                fontSize: 21,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: `${theme.spacing(1)}px !important`,
            },
        },
        checkboxChecked: {
            color: colors.green,
        },
        iconCell: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
        detailsIcon: {
            justifyContent: 'center',
            '& svg': {
                fill: theme.palette.primary.main,
                fontSize: 16,
            },
        },
        actionsIcon: {
            '& svg': {
                fontSize: 18,
            },
        },
        bodyCell: {
            fontSize: '12px !important',
        },
        displayCenterCell: {
            justifyContent: 'center',
        },
        smallHeadCell: {
            width: smallCellWidth,
        },
        mediumHeadCell: {
            width: mediumCellWidth,
        },
        toolbar: {
            backgroundColor: colors.white,
            padding: theme.spacing(1.6, 0),
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `2px solid ${ colors.darkGray }`,
        },
        headerStyle: {
            backgroundColor: theme.palette.primary.main,
        },
        header: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        mainContainer: {
            display: 'flex',
            flexFlow: 'column',
            maxHeight: theme.spacing(50),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3, 10),
            },
            [theme.breakpoints.down('sm')]: {
                padding: 0,
            },
        },
        tableMain: {
            position: 'relative',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',

            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(0.8),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
        },
        tableContainer: {
            height: '100%',
            width: '100%',
            overflow: 'unset',

            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },
            '&::-webkit-scrollbar': {
                width: theme.spacing(0.8),
                [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(0.5),
                },
            },
            '&::-webkit-scrollbar-track': {
                display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
                minHeight: theme.spacing(8),
                borderRadius: theme.spacing(1),
                backgroundClip: 'padding-box',
                border: `1px solid ${ fade(colors.white, 0.5) }`,
                backgroundColor: fade(colors.black, 0.5),
            },
        },
        alignCenter: {
            textAlign: 'center',
        },
        lastCell: {
            paddingRight: theme.spacing(2),
        },
        tableBorder: {
            border: `1px solid ${ colors.lightGray }`,
        },
        pullRight: {
            justifyContent: 'flex-end !important',
        },
        statusSelect: {
            '& .MuiOutlinedInput-input': {
                minHeight: 22,
            },
        },
    });
});

import { useSelector } from 'react-redux';
import { INamedEntitySelectProps, NamedEntitySelect } from 'shared/components/selects/NamedEntitySelect';
import { IJobNumberUserType } from 'shared/models/JobNumber';
import { separateLogicDecorator } from 'shared/utils/separateLogicDecorator';
import { selectJobNumbersUserTypes } from 'store/entities/configuration/configurationSelectors';

interface IUserTypeSelectProps extends Omit<INamedEntitySelectProps<IJobNumberUserType>, 'options'> {}
interface IUserTypeSelectStoreProps {
    options: IJobNumberUserType[];
}

export const UserTypeSelect = separateLogicDecorator<IUserTypeSelectProps, IUserTypeSelectStoreProps>(
    () => ({
        options: useSelector(selectJobNumbersUserTypes),
    }),
)(NamedEntitySelect);

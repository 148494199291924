/* eslint-disable react/display-name */
import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Total, { TotalColor, TotalTypes } from 'shared/components/toolbar/total/Total';
import { EntryType, IEntry, ISheet } from 'shared/models/sheet/Sheet';
import { completesByNotes } from 'shared/utils/counters/completesCounter';
import { totalDollars } from 'shared/utils/counters/dollarCounter';
import {
    timeCounter,
    totalBreakTime,
    totalFiles,
    totalTime,
} from 'shared/utils/counters/timeCounter';
import { activityHasCompletes } from 'shared/utils/formatters/activityFormatter';
import { formatMinutes } from 'shared/utils/formatters/formatMinutesAndHours';
import { AvailableTotalConfiguration, ITotalConfiguration, TotalSlug } from 'store/entities/clients/clientsModel';
import { selectTotalConfiguration } from 'store/entities/clients/clientsSelectors';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { selectCalculationByTimesheetId } from 'store/entities/timesheet/selectors';
import { useSheetDetailStyles } from './styles';
import { optimizely } from 'utils/optimizely';
import { FeatureSwitches } from 'utils/featureSwitches';
import { formatDecimalHoursStringAsHoursAndMinutes } from 'shared/models/DateTime';

interface ITotalBlockProps {
    sheet: ISheet;
    entries: IEntry[];
    entryType: EntryType;
}

interface ITotalElement {
    slug: TotalSlug;
    type: TotalTypes;
    formattedValue: string;
    color?: TotalColor;
    label?: string;
    hide?: boolean;
}

const defaultTimeConfig: ITotalConfiguration[] = [
    {
        slug: TotalSlug.Time,
    },
    {
        slug: TotalSlug.Break,
    },
    {
        slug: TotalSlug.Completes,
        unitLabel: 'completes',
    },
];

const defaultExpenseConfig: ITotalConfiguration[] = [
    {
        slug: TotalSlug.Expense,
    },
];

export function TotalBlock({
    sheet,
    entries,
    entryType,
}: ITotalBlockProps) {
    const classes = useSheetDetailStyles();
    const defaultConfiguration = entryType === EntryType.TIME ? defaultTimeConfig : defaultExpenseConfig;
    const clientConfiguration = useSelector(entryType === EntryType.TIME
        ? selectTotalConfiguration(AvailableTotalConfiguration.TimeSheetDetail) : () => []) as ITotalConfiguration[];
    const hasConfiguration = clientConfiguration.length > 0
        && optimizely.isFeatureEnabled(FeatureSwitches.enableTotalClientConfiguration);
    const configuration = hasConfiguration ? clientConfiguration : defaultConfiguration;

    const activitiesById = useSelector(selectActivitiesById);
    const formattedValue = EntryType.TIME === entryType ? timeCounter(totalTime(entries)) : totalDollars(entries);
    const timeBreak = EntryType.TIME === entryType ? totalBreakTime(entries) : 0;
    const totalFilesValue = EntryType.TIME === entryType ? totalFiles(entries) : 0;
    const calculation = useSelector(selectCalculationByTimesheetId(sheet?.id));
    const completesAmount = useMemo(() => {
        return entries.filter(
            entry => activityHasCompletes(activitiesById[entry.activity_id ?? '']),
        ).reduce(
            (acc: number, entry) => acc + completesByNotes(entry.notes || ''), 0,
        );
    }, [activitiesById, entries]);

    const totalBlocksDictionary: Record<string, ITotalElement> = {
        [TotalSlug.Time]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            formattedValue,
            hide: totalFilesValue > 0,
        },
        [TotalSlug.Break]: {
            slug: TotalSlug.Break,
            type: TotalTypes.Break,
            formattedValue: formatMinutes(timeBreak),
            hide: timeBreak === 0 || totalFilesValue > 0,
        },
        [TotalSlug.Expense]: {
            slug: TotalSlug.Expense,
            type: TotalTypes.Expense,
            formattedValue,
            label: 'total amount',
        },
        [TotalSlug.TotalReg]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            formattedValue: formatDecimalHoursStringAsHoursAndMinutes(calculation?.rt_hours || '0'),
            hide: totalFilesValue > 0,
        },
        [TotalSlug.OverTime]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Green,
            formattedValue: formatDecimalHoursStringAsHoursAndMinutes(calculation?.ot_hours || '0'),
            hide: totalFilesValue > 0,
        },
        [TotalSlug.DoubleTime]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Green,
            formattedValue: formatDecimalHoursStringAsHoursAndMinutes(calculation?.dt_hours || '0'),
            hide: totalFilesValue > 0,
        },
        [TotalSlug.Holidays]: {
            slug: TotalSlug.Time,
            type: TotalTypes.Time,
            color: TotalColor.Orange,
            formattedValue: formatDecimalHoursStringAsHoursAndMinutes(calculation?.holiday_hours || '0'),
            hide: totalFilesValue > 0,
        },
        [TotalSlug.Files]: {
            slug: TotalSlug.Files,
            type: TotalTypes.Break,
            color: TotalColor.Orange,
            hide: totalFilesValue === 0,
            formattedValue: `${ totalFilesValue }`,
        },
        [TotalSlug.Completes]: {
            slug: TotalSlug.Completes,
            type: TotalTypes.Time,
            color: TotalColor.Orange,
            hide: completesAmount === 0,
            formattedValue: `${ completesAmount }`,
        },
    };

    const totalBlocks = configuration.map(config => ({
        ...totalBlocksDictionary[config.slug],
        label: config.unitLabel,
    }));

    return (
        <Box display="flex" className={classes.totalsBlock}>
            {totalBlocks.map((config: ITotalElement) => (
                <>
                    {!config.hide && (
                        <Total
                            {...config}
                            key={config.slug}
                            value={config.formattedValue}
                            classes={{ value: classes.totalNum }}
                        />
                    )}
                </>
            ))}
        </Box>
    );
}

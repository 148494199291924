import { IDepartment } from 'modules/employmentInfo/models/Department';
import * as yup from 'yup';
import { EntrySlug, InputFields } from 'store/entities/clients/clientsModel';
import { ValidationMessages } from 'shared/models/Validation';
import { ICommonEntryFormValues } from '../../components/forms/entries/EntryCommonFields';
import { showField } from '../../components/forms/utils';

export type DefaultShapeType = yup.ObjectSchema<any> | yup.StringSchema

export type CommonEntryShapeType = Partial<Record<keyof ICommonEntryFormValues, DefaultShapeType>>

export function addCommonFields(
    shape: CommonEntryShapeType,
    fields: InputFields,
    userDepartments: IDepartment[],
): CommonEntryShapeType {
    shape.entry_date = yup.string().required(ValidationMessages.REQUIRED);

    if (showField(fields, EntrySlug.Assignment)) {
        shape.projectAssignment = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Task)) {
        shape.taskId = yup.string().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.JobNumber)) {
        shape.jobNumber = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Activity)) {
        shape.activity = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Position)) {
        shape.position = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Location)) {
        shape.location = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Department) && userDepartments.length > 1) {
        shape.department = yup.object().nullable().required(ValidationMessages.REQUIRED);
    }

    if (showField(fields, EntrySlug.Notes)) {
        shape.notes = yup.string();
    }
    return shape;
}

import React from 'react';
import { ISheetRow } from 'shared/components/sheetsSubmitted/sheetSubmittedTable/body';
import clsx from 'clsx';
import CheckBox from 'shared/components/table/tableApprovalCells/CheckBox/CheckBox';
import { useSubmittedTableStyles } from 'shared/components/sheetsSubmitted/sheetsSubmittedStyles';
import { CheckedItems, OnCheck, useCheckedItems } from 'shared/utils/hooks/useCheckedItems';
import { EntryType, ISheet } from 'shared/models/sheet/Sheet';
import { SheetsEntryTypes } from 'store/entities/timesheet/models/Status';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';

export function getCheckboxCell(
    checkedItems: CheckedItems,
    onCheck: OnCheck,
    checkedAll: boolean,
    onCheckAll: (checked: boolean) => void,
    classes: {
        iconCell: string;
        checkbox: string;
    },
    isMobile: boolean,
): ICellInfo<ISheetRow> {
    return {
        key: 'checkbox',
        title: '',
        width: isMobile ? '40px' : '72px',
        headerClassName: clsx(classes.iconCell, classes.checkbox),
        renderTitle: function CheckboxCellHeader() {
            return (
                <CheckBox isChecked={checkedAll}
                    sheetId={''}
                    onChange={onCheckAll}
                />
            );
        },
        render: function CheckboxCell({ sheet, className }: ISheetRow) {
            return (
                <div className={clsx(className, classes.iconCell, classes.checkbox)}>
                    <CheckBox isChecked={checkedItems[sheet.id] ?? false}
                        sheetId={sheet.id}
                        onChange={onCheck}
                    />
                </div>
            );
        },
    };
}

function addSheetsEntryType(sheetsEntryTypes: SheetsEntryTypes, checkedItems: CheckedItems, entryType: EntryType) {
    Object.entries(checkedItems)
        .filter(([_, isChecked]) => isChecked)
        .forEach(([sheetId]) => {
            sheetsEntryTypes[sheetId] = entryType;
        });
}

export function useCheckedSheets(
    isMobile: boolean,
    timeSheets: Array<ISheet>,
    expenseSheets: Array<ISheet>,
) {
    const classes = useSubmittedTableStyles();

    const {
        checkedItems: checkedTimeSheets,
        checkedAll: timeCheckedAll,
        onCheck: onCheckTimeSheet,
        onCheckAll: onCheckAllTimeSheet,
    } = useCheckedItems<ISheet>(timeSheets);

    const {
        checkedItems: checkedExpenseSheets,
        checkedAll: expenseCheckedAll,
        onCheck: onCheckExpenseSheet,
        onCheckAll: onCheckAllExpenseSheet,
    } = useCheckedItems<ISheet>(expenseSheets);

    const timeCheckbox = getCheckboxCell(
        checkedTimeSheets,
        onCheckTimeSheet,
        timeCheckedAll,
        onCheckAllTimeSheet,
        classes,
        isMobile,
    );

    const expenseCheckbox = getCheckboxCell(
        checkedExpenseSheets,
        onCheckExpenseSheet,
        expenseCheckedAll,
        onCheckAllExpenseSheet,
        classes,
        isMobile,
    );

    const sheetsEntryTypes: SheetsEntryTypes = {};

    addSheetsEntryType(sheetsEntryTypes, checkedTimeSheets, EntryType.TIME);
    addSheetsEntryType(sheetsEntryTypes, checkedExpenseSheets, EntryType.EXPENSE);

    return {
        timeCheckbox,
        expenseCheckbox,
        sheetsEntryTypes,
    };
}

export enum IModalSeverity {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
}

export interface IGlobalModal {
    severity?: IModalSeverity;
    title: string;
    autoHideDuration?: number | null;
    ignoreSidebar?: boolean;
}

export const autoHideDefaultDuration = 4000;

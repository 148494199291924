import React from 'react';
import { Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik/dist/types';
import { UserAutocompleteWithSearch } from 'shared/components/autocomplete/UserAutocompleteWithSearch/UserAutocompleteWithSearch';
import ClientSelect from 'shared/components/selects/ClientSelect';
import ButtonWithLoader from 'shared/components/buttons/ButtonWithLoader';
import { createClientUsersAssociation } from 'modules/settings/submodules/platformUsers/store/actions';
import ClientRoleSelect from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ClientRoleSelect';
import { selectIsClientUsersAssociationsCreating } from 'modules/settings/submodules/platformUsers/store/selectors';
import { userClientAssociationValidationsSchema } from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/ValidationsSchema';
import { defaultUserClientAssociationValues, IUserClientAssociationFormValues } from 'modules/settings/submodules/platformUsers/components/CreateUserClientAssociation/model';
import { useInlineFormStyles } from 'modules/settings/submodules/components/sharedStyles/inlineFormStyles';
import { UserType } from 'store/entities/users/model';
import { AppClient } from 'utils/constants';

const CreateUserClientAssociation = () => {
    const classes = useInlineFormStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsClientUsersAssociationsCreating);
    const onSubmitForm = (
        values: IUserClientAssociationFormValues,
        { resetForm }: FormikHelpers<IUserClientAssociationFormValues>,
    ) => {
        dispatch(createClientUsersAssociation.init({
            user_id: values.user?.id || '',
            client_id: values.client?.id || process.env.REACT_APP_CLIENT_ID || '',
            client_role_id: values.role?.id || '',
        }));
        resetForm();
    };

    return (
        <Formik
            initialValues={defaultUserClientAssociationValues}
            validationSchema={userClientAssociationValidationsSchema}
            validateOnBlur={false}
            onSubmit={onSubmitForm}
        >
            {(props: FormikProps<IUserClientAssociationFormValues>) => {
                const selectedClient = props.values?.client?.id || process.env.REACT_APP_CLIENT_ID;
                return (
                    <form
                        onSubmit={props.handleSubmit}
                        className={classes.form}
                    >
                        <UserAutocompleteWithSearch
                            additionalFilter={{
                                user_type: UserType.Manager,
                            }}
                            name="user"
                            label="Search user"
                            className={classes.input}
                        />
                        {process.env.REACT_APP_CLIENT !== AppClient.RTI && (
                            <ClientSelect
                                name="client"
                                label="Client"
                                className={classes.input}
                            />
                        )}
                        <ClientRoleSelect
                            name="role"
                            label="Role"
                            title={selectedClient ? '' : 'Select Client first'}
                            disabled={!selectedClient}
                            clientId={selectedClient}
                            blockNameList={['Employee']}
                            className={classes.input}
                        />
                        <ButtonWithLoader
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            isLoading={isLoading}
                            data-testid="submit_button"
                        >
                            Associate
                        </ButtonWithLoader>
                    </form>
                );
            }}
        </Formik>
    );
};

export default CreateUserClientAssociation;

import { OptimizelyFeature } from '@optimizely/react-sdk';
import React from 'react';
import { FeatureSwitches } from 'utils/featureSwitches';

export interface IFeatureSwitchProps {
    children: React.ReactNode;
    feature: FeatureSwitches;
    noPlaceholder?: boolean;
}

export default function FeatureSwitch({ children, feature }: IFeatureSwitchProps) {
    return (
        <OptimizelyFeature feature={feature}>
            {isEnabled => (
                <>
                    {isEnabled && children}
                </>
            )}
        </OptimizelyFeature>
    );
}

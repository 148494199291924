import { getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';
import { takeEvery, takeLatest, call, put, select } from 'typed-redux-saga';
import {
    addTimeEntry,
    removeTimeEntry,
    updateTimeEntry,
    loadTimeSheets,
    loadTimeSheetsWithEntries,
    patchEntriesDateSpecificBySheetId,
    setTimeSheetPatchingById,
} from 'store/entities/timesheet/actions/timeActions';
import { ITimeEntryPatchEntriesDateSpecificBySheetIdRequest, timeApi } from 'store/entities/timesheet/api/timeApi';
import { createAddEntrySaga, createDeleteEntrySaga, createUpdateEntrySaga } from 'store/entities/timesheet/sagas/utils';
import { IStore } from 'store/configureStore';
import { withErrorHandler } from 'store/utils/sagas/withErrorHandler';
import { IStatus, ITimeSheetBackend } from 'shared/models/sheet/Sheet';
import { IUpdateSheetsStatus, IUpdateSheetStatus, StatusNames } from '../models/Status';
import { selectTimeSheetsByIds, selectTimeSheetStatusByName } from 'store/entities/timesheet/selectors';
import {
    selectClientApproversFrom,
    selectCurrentClientId,
} from 'store/entities/clients/clientsSelectors';
import {
    selectApproversCountBySheets,
    selectCurrentUserApprovalsLevelByAssignments,
} from 'store/entities/configuration/configurationSelectors';
import { setGlobalToast } from '../../appConfig/actions';
import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { isEmpty } from 'lodash';

import { tryApproveSheet } from 'store/entities/timesheet/helpers';

const selectSheetByEntryId = (entryId: string) => (state: IStore) => state.sheets.time.entriesById[entryId];

const addTimeEntrySaga = createAddEntrySaga(timeApi.createEntry, addTimeEntry.success);

function* addTimeEntryWatcher() {
    yield takeEvery(addTimeEntry.initType, addTimeEntrySaga);
}

const updateTimeEntrySaga = createUpdateEntrySaga(
    timeApi.updateEntry,
    updateTimeEntry.success,
    selectSheetByEntryId,
);

function* updateTimeEntryWatcher() {
    yield* takeEvery(updateTimeEntry.initType, updateTimeEntrySaga);
}

const removeTimeEntrySaga = createDeleteEntrySaga(
    timeApi.deleteEntry,
    removeTimeEntry.success,
    selectSheetByEntryId,
);

function* removeTimeEntryWatcher() {
    yield* takeEvery(removeTimeEntry.initType, removeTimeEntrySaga);
}

function* loadTimeSheetsSaga({ payload }: ReturnType<typeof loadTimeSheetsWithEntries.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(timeApi.getSheetListByPurpose, purpose, request || {});
    yield* put(loadTimeSheetsWithEntries.success(sheets));
}

function* loadTimeSheetsWatcher() {
    yield takeLatest(loadTimeSheetsWithEntries.initType, withErrorHandler(
        loadTimeSheetsSaga,
        loadTimeSheetsWithEntries.error,
        'Time sheets were not loaded',
    ));
}

function* loadSimplifiedTimeSheetsSaga({ payload }: ReturnType<typeof loadTimeSheets.init>) {
    const { purpose, request } = payload;
    const sheets = yield* call(timeApi.getSimplifiedSheetListByPurpose, purpose, request || {});
    yield* put(loadTimeSheets.success(sheets));
}

function* loadSimplifiedTimeSheetsWatcher() {
    yield takeLatest(loadTimeSheets.initType, withErrorHandler(
        loadSimplifiedTimeSheetsSaga,
        loadTimeSheets.error,
        'Time sheets were not loaded',
    ));
}

function* patchEntriesDateSpecificBySheetIdSaga({
    payload: {
        sheetId,
        date,
        isPerDiem,
        isHoliday,
    },
}: ReturnType<typeof patchEntriesDateSpecificBySheetId.init>) {
    try {
        yield* put(setTimeSheetPatchingById({ sheetId, state: true }));
        const params: ITimeEntryPatchEntriesDateSpecificBySheetIdRequest = {
            is_holiday_time: isHoliday,
            is_per_diem: isPerDiem,
            entry_date: date,
        };
        const sheet = yield* call(timeApi.patchEntriesDateSpecificBySheetId, sheetId, params);
        yield* put(patchEntriesDateSpecificBySheetId.success([sheet]));
        yield* put(setTimeSheetPatchingById({ sheetId, state: false }));
    } catch (error) {
        yield put(setTimeSheetPatchingById({ sheetId, state: false }));
        const message = 'Unable to find users';
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: message,
            autoHideDuration: autoHideDefaultDuration * 2,
        }));
        logErrorWithCustomMessage(error, message);
    }
}

function* patchEntriesDateSpecificBySheetIdSagaWatcher() {
    yield* takeLatest(patchEntriesDateSpecificBySheetId.initType, patchEntriesDateSpecificBySheetIdSaga);
}

//the following function  is used to reject only since november 2020, approve is made with create approval api.
export function* updateTimeSheetsStatusesSaga(sheetsIds: string[], status: IStatus, notes?: Record<string, string>) {
    const payload: IUpdateSheetsStatus = {
        sheets: sheetsIds.map(id => {
            const sheet: IUpdateSheetStatus = {
                id,
                status_id: status.id,
            };

            if (notes){
                sheet.notes = notes[id];
            }

            return sheet;
        }),
    };
    const updatedSheets = yield* call(
        timeApi.updateSheetsStatuses,
        payload,
    );
    yield* put(loadTimeSheetsWithEntries.success(updatedSheets));
}

export function* createTimeSheetApprovalSaga(sheetsIds: string[]) {
    yield* call(
        timeApi.createSheetApprovals,
        sheetsIds,
    );

    const sheetsById = yield* select(selectTimeSheetsByIds);
    const clientId = yield* select(selectCurrentClientId);
    const currentUserApprovalLevelsByAssignments = yield* select(selectCurrentUserApprovalsLevelByAssignments);
    const approversFrom = yield* select(selectClientApproversFrom);
    const approversCountBySheets = yield* select(selectApproversCountBySheets(sheetsIds));
    const approvedStatus = yield* select(selectTimeSheetStatusByName(StatusNames.APPROVED));
    const updatedSheets = sheetsIds.map(sheetId => {
        const sheet: ITimeSheetBackend = {
            ...sheetsById[sheetId],
            entries: [],
        };
        const possiblyUpdatedSheet = tryApproveSheet(
            sheet,
            approversFrom,
            currentUserApprovalLevelsByAssignments,
            approversCountBySheets,
            clientId,
            approvedStatus,
        );
        return possiblyUpdatedSheet;
    });
    yield* put(loadTimeSheetsWithEntries.success(updatedSheets));
}

function* loadSheetsPayrollEntitiesForLinkedSaga({ payload }: ReturnType<typeof loadTimeSheets.success>) {
    const sheetIds = payload.map(sheet => sheet.id).filter(id => id);
    if (!isEmpty(sheetIds)) {
        yield put(getTimesheetCalculations.init({
            sheet_ids: sheetIds,
        }));
    }
}

function* loadSheetsPayrollEntitiesForLinkedSagaWatcher() {
    yield takeEvery(
        [
            loadTimeSheets.successType,
            loadTimeSheetsWithEntries.successType,
        ],
        loadSheetsPayrollEntitiesForLinkedSaga,
    );
}

export default [
    addTimeEntryWatcher,
    updateTimeEntryWatcher,
    removeTimeEntryWatcher,
    loadTimeSheetsWatcher,
    loadSimplifiedTimeSheetsWatcher,
    patchEntriesDateSpecificBySheetIdSagaWatcher,
    loadSheetsPayrollEntitiesForLinkedSagaWatcher,
];

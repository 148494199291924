import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimesheetCalculations } from 'store/entities/timesheet/actions/calculations';
import { selectCalculationsByTimesheetId, selectTimeSheetsByIds } from 'store/entities/timesheet/selectors';
import { isEmpty } from 'lodash';

/**
 * Define timesheets ids that waiting for calculations update
 * e.g. timesheet updated but calculations still pending
 * or timesheet created but calculation not
 *
 * @param timesheetsIds list of timesheet ids for checking
 */
export const useTimesheetIdsCalculationsToUpdate = (timesheetsIds: string[]): string[] => {
    const timeSheetCalculationsById = useSelector(selectCalculationsByTimesheetId);
    const timeSheetsByIds = useSelector(selectTimeSheetsByIds);
    return useMemo(() => {
        return timesheetsIds.filter(id => {
            const timeSheet = timeSheetsByIds[id];
            const timeCalculation = timeSheetCalculationsById[id];
            return timeSheet && (
                (timeCalculation && moment(timeSheet.updated_at).isAfter(timeCalculation.updated_at, 's'))
                || (!timeCalculation && parseFloat(timeSheet.total_hours))
            );
        });
    }, [timeSheetCalculationsById, timeSheetsByIds, timesheetsIds]);
};

/**
 * Polling API for calculations update
 * @param timesheetsIds
 */
export const useSheetCalculationUpdate = (timesheetsIds: string[]) => {
    const dispatch = useDispatch();
    const timesheetIdsToUpdate = useTimesheetIdsCalculationsToUpdate(timesheetsIds);
    useEffect(() => {
        const pollingTimeout = 3_000;
        let polling: NodeJS.Timeout;
        if (!isEmpty(timesheetIdsToUpdate)) {
            polling = setInterval(() => {
                dispatch(getTimesheetCalculations.init({
                    sheet_ids: timesheetIdsToUpdate,
                }));
            }, pollingTimeout);
        }

        return () => {
            if (polling) {
                clearInterval(polling);
            }
        };
    }, [dispatch, timesheetIdsToUpdate]);
};

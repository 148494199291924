import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useAddControlsStyles
    from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/AddControlsStyles';
import { IBaseCommonEntryFieldsProps } from 'shared/components/forms/entries/CommonEntryInlineFields';
import { ICommonEntryFormValues } from 'shared/components/forms/entries/EntryCommonFields';
import { AddEntryControls } from 'shared/components/table/addEntryControls';
import { EntryType } from 'shared/models/sheet/Sheet';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { StatusNames } from 'store/entities/timesheet/models/Status';
import { selectSheetStatusIdsByName } from 'store/entities/timesheet/selectors';
import AddExpenseEntry from './AddExpenseEntry';
import AddTimeEntry from './AddTimeEntry';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentClientId, selectFieldConfiguration } from 'store/entities/clients/clientsSelectors';
import EntryTypeButton from './components/EntryTypeButton';
import { setCommonEntryFormValues } from 'modules/timeAndExpense/components/AddEntry/store/actions';

interface IAddControlsProps extends IBaseCommonEntryFieldsProps {
    customClasses?: Record<'paper', string>;
    timeSheetId?: string;
    expenseSheetId?: string;
    userId?: string;
    entryTypeAllowed?: EntryType[];
    payPeriod: IPayPeriod;
    entryTypeFilter?: EntryType;
    statusName: StatusNames;
}

export default function AddNewEntry({
    entryTypeAllowed = [EntryType.TIME, EntryType.EXPENSE],
    customClasses,
    timeSheetId,
    expenseSheetId,
    userId,
    payPeriod,
    entryTypeFilter,
    statusName,
    ...props
}: IAddControlsProps) {
    const dispatch = useDispatch();
    const classes = useAddControlsStyles();
    const [entryType, setEntryType] = useState(EntryType.TIME);
    const clientId = useSelector(selectCurrentClientId);
    const currentSheetStatusesId = useSelector(selectSheetStatusIdsByName(statusName));
    const configuration = useSelector(selectFieldConfiguration(clientId || ''));

    useEffect(() => {
        if (entryTypeFilter) {
            setEntryType(entryTypeFilter);
        }
    }, [entryTypeFilter, setEntryType]);

    const onFocus = () => {
        const selectHtmlElements = document.querySelectorAll('form select, form input') as NodeListOf<HTMLElement>;
        const selectHtmlElementsArray = Array.from(selectHtmlElements);
        const visibleElement = selectHtmlElementsArray.find(element => {
            return Boolean(element.offsetParent);
        });
        if (visibleElement) {
            visibleElement.focus();
        }
    };
    const timeAllowed = entryTypeAllowed.includes(EntryType.TIME);
    const expenseAllowed = entryTypeAllowed.includes(EntryType.EXPENSE);
    if (!timeAllowed && entryType === EntryType.TIME && expenseAllowed) {
        setEntryType(EntryType.EXPENSE);
    }
    if (!expenseAllowed && entryType === EntryType.EXPENSE && timeAllowed) {
        setEntryType(EntryType.TIME);
    }
    const disabled = !expenseAllowed && !timeAllowed;

    const onChangeCommonValues = useCallback((values: ICommonEntryFormValues) => {
        dispatch(setCommonEntryFormValues(values));
    }, [dispatch]);

    return (
        <AddEntryControls customClasses={`${classes.paper} ${customClasses?.paper}`}>
            <Box
                className={clsx(classes.typeQuantityIconContainer, { [classes.notDisplayed]: entryTypeFilter })}
                data-testid="entry-type-switchers-wrapper"
            >
                <EntryTypeButton
                    onClick={() => setEntryType(EntryType.TIME)}
                    disabled={!timeAllowed}
                    active={entryType === EntryType.TIME}
                    IconComponent={AccessTimeIcon}
                />
                <EntryTypeButton
                    onClick={() => setEntryType(EntryType.EXPENSE)}
                    disabled={!expenseAllowed}
                    active={entryType === EntryType.EXPENSE}
                    IconComponent={AttachMoneyIcon}
                />
            </Box>
            {configuration && (
                <Box className={classes.controlsContainer}>
                    {entryType === EntryType.TIME && (
                        <AddTimeEntry
                            {...props}
                            payPeriod={payPeriod}
                            sheetId={timeSheetId}
                            statusId={currentSheetStatusesId?.timeStatusId}
                            inputs={configuration.inputs.time}
                            userId={userId}
                            disabled={disabled}
                            onChangeCommonValues={onChangeCommonValues}
                        />
                    )}
                    {entryType === EntryType.EXPENSE && (
                        <AddExpenseEntry
                            {...props}
                            payPeriod={payPeriod}
                            sheetId={expenseSheetId}
                            statusId={currentSheetStatusesId?.expenseStatusId}
                            inputs={configuration.inputs.expense}
                            userId={userId}
                            disabled={disabled}
                            onChangeCommonValues={onChangeCommonValues}
                        />
                    )}
                </Box>
            )}
            <div tabIndex={0} onFocus={onFocus}/>
        </AddEntryControls>
    );
}

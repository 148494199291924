import { dollarsFormatter, moneyToFormattedString } from 'shared/models/Money';

export const formatDollars = (dollars?: string | number | null): string => {
    const value = parseFloat((dollars && dollars.toString()) || '0');
    return value ? dollarsFormatter.format(value) : '0';
};

export const dollarFormatter = (dollars: number, cents: number) => {
    return moneyToFormattedString(dollars + cents * 0.01);
};

import React, {
    FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { IError } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/validations/IError';
import { EntryType, IEntry, QuantityType } from 'shared/models/sheet/Sheet';
import QuickEdit from 'shared/components/table/tableEntryCells/QuickEdit';
import { timeCounter, totalTime } from 'shared/utils/counters/timeCounter';
import { timeEntryValidation } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/validations/timeEntry';
import { expenseEntryValidationString } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/AddEntryControls/validations/expenseEntry';
import { IExpenseEntryUpdate, ITimeEntryUpdate } from 'store/entities/timesheet/models/Entry';
import { useSelector } from 'react-redux';

import { useActions } from 'store/utils';
import { parseData } from 'modules/clients/content/TimeAndExpensePage/SheetsInProgress/EntriesGrid/utils/parseData';
import { timeParser } from 'shared/components/textField/time/timeParser';
import { updateExpenseEntry } from 'store/entities/timesheet/actions/expenseActions';
import { updateTimeEntry } from 'store/entities/timesheet/actions/timeActions';
import { getFormattedMiles, getFormattedOdometer } from 'shared/components/formSpecialFields/expenseData/utils';
import { selectActivitiesById } from 'store/entities/configuration/configurationSelectors';
import { TotalMinutesByDay } from 'shared/utils/context/totalMinutesByDay';
import { parseTimeUnitsFromString } from 'shared/models/DateTime';

interface IQuickEditEntryProps {
    entry: IEntry;
    userId?: string;
    customClasses?: string;
    disabled?: boolean;
}

/**
 * @deprecated It needs to move login of differrent entry edit forms to withQuickEditEntry HOC
 */
const QuickEditEntry: FC<IQuickEditEntryProps> = (
    { entry, customClasses, disabled, userId }: IQuickEditEntryProps,
) => {
    const activitiesById = useSelector(selectActivitiesById);

    const totalMinutesByDays = useContext(TotalMinutesByDay) || {};
    const totalMinutesByDay = totalMinutesByDays[entry.entry_date];

    const actions = useActions({
        updateTimeEntry: updateTimeEntry.init,
        updateExpenseEntry: updateExpenseEntry.init,
    });
    const currentTotalMinutes = useMemo(() => totalTime([entry]), [entry]);
    const timeEntryParser = useCallback((value: string) => timeParser(value), []);
    const expenseEntryParser = useCallback((value: string) => value.replace(/[,$]/g, ''), []);

    const isMilesExpenseEntry = entry.entry_type === EntryType.EXPENSE
        && [QuantityType.ODOMETER, QuantityType.MILES].includes(activitiesById[entry.activity_id]?.data_type);

    const quickEditTimeEntry = useCallback((time: string): IError | null => {
        const { hours, minutes } = parseTimeUnitsFromString(time);
        const totalMinutesWithoutCurrentDay = totalMinutesByDay - currentTotalMinutes;

        return timeEntryValidation(
            hours,
            minutes,
            entry.entry_date,
            time,
            totalMinutesWithoutCurrentDay,
        );
    }, [totalMinutesByDay, entry.entry_date, currentTotalMinutes]);

    const getEntryValue = () => {
        switch (entry.data.entry_type) {
            case QuantityType.TIME:
                return timeCounter(entry.data.minutes, entry.data.hours);
            case QuantityType.ODOMETER:
                return getFormattedOdometer(entry.data);
            case QuantityType.MILES:
                return getFormattedMiles(entry.data.miles);
            case QuantityType.MONEY:
                return entry.data.dollars;
            default:
                return '';
        }
    };

    const quickEditExpenseEntry = useCallback((dollars: string): IError | null => {
        return expenseEntryValidationString(dollars);
    }, []);

    const entryValue = getEntryValue();

    const [storedValue, setStoredValue] = useState(entryValue);
    const [newValue, setNewValue] = useState(entryValue);
    const handleOnFocus = useCallback(() => {
        setStoredValue(entryValue);
        setNewValue('');
    }, [setStoredValue, setNewValue, entryValue]);

    const handleOnRestore = useCallback(() => {
        setNewValue(storedValue);
    }, [storedValue]);

    const handleOnSubmit = useCallback((value: string) => {
        if (entryValue === value) {
            handleOnRestore();
            return;
        }
        const data = parseData(value, entry.data.entry_type);
        if (entry.entry_type === EntryType.TIME) {
            const updatedEntry: ITimeEntryUpdate = {
                id: entry.id,
                assignment_id: entry.assignment_id,
                project_id: entry.project_id,
                activity_id: entry.activity_id,
                entry_date: entry.entry_date,
                task_id: entry.task_id,
                notes: entry.notes,
                position_id: entry.position_id || null,
                location_id: entry.location_id || null,
                department_id: entry.department_id || undefined,
                data,
                sca_zone: entry.sca_zone,
            };
            if (userId) {
                updatedEntry.user_id = userId;
            }
            actions.updateTimeEntry(updatedEntry);
        }
        if (entry.entry_type === EntryType.EXPENSE) {
            const updatedEntry: IExpenseEntryUpdate = {
                id: entry.id,
                assignment_id: entry.assignment_id,
                project_id: entry.project_id,
                activity_id: entry.activity_id,
                entry_date: entry.entry_date,
                task_id: entry.task_id,
                notes: entry.notes,
                data,
                zip_code: entry.zip_code, //zip code will not be always populated. e.g. it will be populated for RTI
                sheet_entry_attachments: [],
                position_id: entry.position_id || null,
                location_id: entry.location_id || null,
                department_id: entry.department_id || undefined,
                user_id: userId,
            };
            if (userId) {
                updatedEntry.user_id = userId;
            }
            actions.updateExpenseEntry(updatedEntry);
        }
        setNewValue(value);

    }, [actions, entry, entryValue, handleOnRestore, userId]);

    useEffect(() => {
        setNewValue(entryValue);
    }, [entryValue]);

    return (
        <QuickEdit
            onSubmit={handleOnSubmit}
            value={newValue}
            entryDataType={entry.data}
            onFocus={handleOnFocus}
            handleOnRestore={handleOnRestore}
            customClasses={customClasses}
            quickEdit={
                entry.data.entry_type === QuantityType.TIME
                    ? quickEditTimeEntry
                    : quickEditExpenseEntry
            }
            parserEntry={
                entry.data.entry_type === QuantityType.TIME
                    ? timeEntryParser
                    : expenseEntryParser
            }
            disabled={isMilesExpenseEntry || disabled}
        />
    );
};

export default QuickEditEntry;

import { IEntity } from 'shared/models/Entity';
import { ILocation } from 'shared/models/Location';
import { IPosition } from 'shared/models/Position';
import { EntryType, QuantityType } from 'shared/models/sheet/Sheet';
import { IUserInfo } from 'shared/models/User';
import { ActivityTag } from 'store/entities/configuration/configurationModel';
import { IGetUserRequest } from 'store/entities/users/model';

export interface ICreateClientRequest {
    name: string;
    short_name: string;
    status_id: string;
    status_date: string;
    manager_user_id: string;
    payroll_rep_user_id: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    county: string;
    zip_code: string;
    contact_name: string;
    contact_title: string;
    contact_email: string;
    contact_phone: string;
    employer_id: string;
    cost_center: string;
    service_type: string;
}

export interface IUpdateClientRequest {
    status_id: string;
    status_date: string;
    manager_user_id: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    county: string;
    zip_code: string;
    contact_name: string;
    contact_title: string;
    contact_email: string;
    contact_phone: string;
}

export interface IClientData {
    name: string;
    employer_id: string;
    cost_center: string;
    status: IClientStatuses | null;
    status_date: string;
    service_type: string;
    manager: IUserInfo | null;
    payroll_rep_user: IUserInfo | null;
    address: string;
    address2: string;
    city: string;
    state: string;
    county: string;
    zip_code: string;
    contact_name: string | null;
    contact_title: string | null;
    contact_email: string | null;
    contact_phone: string | null;
    prism_corporation_type: string;
    client_id?: string;
}

export interface IClientStatuses{
    id: string;
    name: string;
    prism_status_code: string;
}

export interface IClientStatusesBackend{
    client_statuses: IClientStatuses[];
}

export interface IGetUserResponse{
    users: IGetUserRequest[];
}

export enum OverrideWorkGeoCode {
    OverrideWithResidentGeoCode = 'Override with Resident GeoCode',
    OverrideWithHomeLocationGeoCode = 'Override with Home Location GeoCode',
    UserWorkLocationGeoCode = 'Use Work Location GeoCode',
    OverrideTSGeoCodeWithResidentGeoCode = 'Override T/S GeoCode with Resident GeoCode',
}

export interface ICreateWorkLocationRequest extends Omit<ILocation, 'id' | 'positions' | 'deleted' | 'deleted_at' | 'geo_code' | 'prism_location_id'>{}

export interface IUpdateWorkLocationRequest extends Omit<ICreateWorkLocationRequest, 'client_id'> {}

export interface IPositionWorkLocationAssociation extends IEntity {
    location: ILocation;
    position: IPosition;
}

export interface ICreatePositionWorkLocationAssociationRequest {
    position_id: string;
    location_id: string;
}

export interface ICreateActivityRequest {
    client_id: string;
    sheet_type: EntryType;
    data_type: QuantityType;
    description: string;
    short_description?: string;
    pay_code_id: string | null;
    task_id?: string;
    show_zip_code: boolean;
    tags: ActivityTag[];
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FeatureSwitch from 'shared/components/common/FeatureSwitch';
import MainContent from 'shared/components/common/MainContent';
import { getCustomFields } from 'store/entities/customFields/actions';
import { FeatureSwitches } from 'utils/featureSwitches';

export const CustomFieldsListPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCustomFields.init());
    }, [dispatch]);

    return (
        <FeatureSwitch feature={FeatureSwitches.enableEmployeeManagement}>
            <MainContent whiteBackground>
                Custom fields module
            </MainContent>
        </FeatureSwitch>
    );
};

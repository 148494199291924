import { IOfferLetterRow } from 'modules/offerLetter/components/OfferLetterTable/cells';
import { offerLettersTableStateSelectors, selectOfferLetterPdfIsAvailable, selectOfferLetterPdfIsLoading, selectOfferLetters } from 'modules/offerLetter/store/selectors';
import { createSelector } from 'reselect';
import { selectCurrentUser } from 'store/components/auth/selectors';

export const selectOfferLetterRows = createSelector(
    offerLettersTableStateSelectors.selectItems,
    selectOfferLetters,
    selectCurrentUser,
    selectOfferLetterPdfIsLoading,
    selectOfferLetterPdfIsAvailable,
    (itemIds, offerLettersById, currentUser, isPdfCheckingById, isPdfAvailableById) => {
        return itemIds.map(id => ({
            offerLetter: offerLettersById[id],
            isPdfLoading: isPdfCheckingById[id],
            isPdfAvailable: isPdfAvailableById[id],
        })).filter((row: IOfferLetterRow) => {
            return row?.offerLetter && row.offerLetter.email.toLowerCase() !== (currentUser?.email ?? '').toLowerCase();
        });
    },
);

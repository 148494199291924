import { makeStyles } from '@material-ui/core';
import { colors } from 'shared/styles/constants';

export const useStyles = makeStyles(theme => ({
    block: {
        padding: theme.spacing(4),
        background: colors.white,
        border: `1px solid ${colors.lightGray}`,
        minHeight: theme.spacing(21),
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    heading: {
        fontSize: theme.typography?.pxToRem(24),
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'uppercase',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

import React, { ChangeEvent, Ref, useCallback, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { useField } from 'formik';
import { CircularProgress, FormControl, FormHelperText, TextField, Tooltip } from '@material-ui/core';
import { inputParams } from 'shared/styles/constants';
import { IFormFieldProps } from '../formFields/models';
import { useFormHelperTextStyles } from 'shared/styles/formHelperText';

interface ICustomAutocompleteProps<T> extends IFormFieldProps, Omit<AutocompleteProps<T>, 'renderInput'> {
    options: Array<T>;
    getKey?: (item: T) => string;
    getText: (item: T) => string;
    inputRef?: Ref<HTMLDivElement>;
    tooltip?: string;
    Label?: React.ReactNode;
    isLoading?: boolean;
    endAdornment?: React.ReactNode;
}

export default function FormAutocomplete({
    name,
    label,
    Label,
    options,
    className,
    getKey,
    getText,
    disabled = false,
    id = name,
    tooltip = '',
    isLoading = false,
    endAdornment,
    ...props
}: ICustomAutocompleteProps<any>,
) {
    const formHelperTextClasses = useFormHelperTextStyles();
    const [field, meta, helpers] = useField(name);
    const [innerValue, setInnerValue] = useState(null);
    const hasError = Boolean(meta.error && meta.touched);

    const getValue = useCallback((newValue: any) => {
        return getKey ? options.find(i => getKey(i) === newValue) || null : newValue;
    }, [options, getKey]);

    useEffect(() => {
        const value = getValue(field.value);
        setInnerValue(value);
    },
    [field.value, setInnerValue, getValue]);

    const onChange = (event: ChangeEvent<{}>, newAutocompleteValue: any) => {
        const newValue = getKey && newAutocompleteValue ? getKey(newAutocompleteValue) : newAutocompleteValue;
        helpers.setValue(newValue);
        helpers.setTouched(false);
    };

    const onBlur = () => {
        helpers.setTouched(false);
    };

    return (
        <FormControl
            variant="outlined"
            classes={{ root: className }}
            error={hasError}
        >
            {Boolean(Label) && Label}
            <Tooltip title={tooltip}>
                <Autocomplete
                    {...props}
                    {...field}
                    options={options}
                    value={innerValue}
                    disabled={disabled}
                    id={id}
                    onChange={onChange}
                    onBlur={onBlur}
                    multiple={false}
                    getOptionLabel={(item: any) => getText(item)}
                    renderInput={(inputProps: any) => (
                        <TextField
                            error={hasError && meta.error}
                            {...inputProps}
                            label={label}
                            variant="outlined"
                            inputProps={{
                                ...inputParams,
                                ...inputProps.inputProps,
                            }}
                            InputProps={{
                                ...inputProps.InputProps,
                                endAdornment: (
                                    <>
                                        {isLoading ? <CircularProgress color="primary" size={16}/> : null}
                                        {inputProps.InputProps.endAdornment}
                                        {endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            </Tooltip>
            {hasError && (
                <FormHelperText classes={formHelperTextClasses}>
                    {meta.error}
                </FormHelperText>
            )}
        </FormControl>
    );
}

import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { IPayPeriod } from 'store/entities/timesheet/models/PayPeriod';
import { selectOrderedPayPeriods } from 'store/entities/timesheet/selectors';
import useWeekFilterStyles from './WeekFilterStyles';
import { selectCurrentPayPeriod } from 'modules/timeAndExpense/components/WorkingPage/store/selectors';
import { setCurrentPayPeriod } from 'modules/timeAndExpense/components/WorkingPage/store/actions';
import CalendarSVG from '../icons/CalendarSVG';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';

export default function PayPeriodSelector() {
    const dispatch = useDispatch();
    const classes = useWeekFilterStyles();

    const payPeriod = useSelector(selectCurrentPayPeriod);
    const payPeriods = useSelector(selectOrderedPayPeriods);

    const [previousPayPeriod, nextPayPeriod] = useMemo(() => {
        const periodIndex = payPeriods.findIndex(period => period.period_start === payPeriod?.period_start
            && period.period_end === payPeriod?.period_end);
        return [payPeriods[periodIndex - 1], payPeriods[periodIndex + 1]];
    }, [payPeriods, payPeriod]);

    const labelWeek = () => {
        const periodStart = moment(payPeriod?.period_start);
        const periodEnd = moment(payPeriod?.period_end);
        return `${periodStart.format('D MMM')} - ${periodEnd.format('D MMM')}`;
    };
    const setNewPayPeriod = useCallback((period?: IPayPeriod) => {
        if (period) {
            dispatch(setCurrentPayPeriod(period));
        }
    }, [dispatch]);

    return (
        <>
            {payPeriod && (
                <Box className={classes.container}>
                    <Button
                        classes={{ root: `${classes.arrowButton} ${classes.arrowButtonLeft}` }}
                        onClick={() => setNewPayPeriod(previousPayPeriod)}
                        disabled={!previousPayPeriod}
                    >
                        <ArrowBackIosSharpIcon fontSize="inherit"/>
                    </Button>
                    <Box className={classes.calendarContainer}>
                        <CalendarSVG/>
                        <Typography className={classes.label}>
                            {labelWeek()}
                        </Typography>
                    </Box>
                    <Button
                        classes={{ root: `${classes.arrowButton} ${classes.arrowButtonRight}` }}
                        onClick={() => setNewPayPeriod(nextPayPeriod)}
                        disabled={!nextPayPeriod}
                    >
                        <ArrowForwardIosSharpIcon fontSize="inherit"/>
                    </Button>
                </Box>
            )}
        </>
    );
}
